import React, { Component } from 'react';

import * as JSFUNC from './JSFUNC.js';


//========================================================================================================================================
//Text Wrapping
//========================================================================================================================================
export function Nowrap(props) { //props: p_fontClass, p_styleObj, p_ellipsisTF, children
  const p_ellipsisTF = JSFUNC.prop_value(props.p_ellipsisTF, true); //have the textOverflow ellipsis by default

  var ellipsisClass = "";
  if(p_ellipsisTF) {
    ellipsisClass = "textOverflowEllipsis";
  }

  return(
    <div className="nowrapOuter">
      <div className="nowrapMiddle">
        <div className={"nowrapInner " + ellipsisClass + " " + props.p_fontClass} style={props.p_styleObj}>
          {props.children}
        </div>
      </div>
    </div>
  );
}

export function MaxHeightWrap(props) { //props: p_maxHeight, p_fontClass, children
  if(JSFUNC.is_string(props.p_fontClass)) {
    return(
      <div className="flex11a maxHeightWrap" style={{maxHeight:props.p_maxHeight}}>
        <font className={props.p_fontClass}>
          {props.children}
        </font>
      </div>
    );
  }

  return(
    <div className="flex11a maxHeightWrap" style={{maxHeight:props.p_maxHeight}}>
      {props.children}
    </div>
  );
}


export function DivFont(props) { //props: p_class, p_fontClass, children
  return(
    <div className={props.p_class}>
      <font className={props.p_fontClass}>
        {props.children}
      </font>
    </div>
  );
}



//========================================================================================================================================
//Flex Box Layout
//========================================================================================================================================
export function TileComponents(props) { //props: p_componentsArray, p_componentLabelsArray, p_numTilesPerRow, p_tileMarginClass, p_tileClass, p_tileStyleObj, p_labelContainerClass, p_labelFontClass
  //components in componentsArray are the interiors of each of the tiles
  const componentsArray = props.p_componentsArray;
  const componentLabelsArray = props.p_componentLabelsArray;
  const numTilesPerRow = JSFUNC.prop_value(props.p_numTilesPerRow, 1);
  const tileMarginClass = JSFUNC.prop_value(props.p_tileMarginClass, "");
  const tileClass = JSFUNC.prop_value(props.p_tileClass, "");

  const usingLabelsTF = JSFUNC.is_array(componentLabelsArray);

  const numComponents = componentsArray.length;
  const componentIndicesArray = JSFUNC.array_fill_incrementing_0_to_nm1(numComponents);
  const tileIndicesRCMatrix = JSFUNC.get_rc_matrix_from_id_array_and_num_columns(componentIndicesArray, numTilesPerRow);

  return(
    tileIndicesRCMatrix.map((m_rowTileIndicesArray, rowIndex) =>
      <div key={JSFUNC.rc_unique_row_key(numTilesPerRow, rowIndex)} className="displayFlexRow">
        {m_rowTileIndicesArray.map((m_tileIndex) =>
          <Tile
            key={m_tileIndex}
            p_tileIndex={m_tileIndex}
            p_tileMarginClass={tileMarginClass}
            p_tileClass={tileClass}
            p_tileStyleObj={props.p_tileStyleObj}
            p_labelContainerClass={props.p_labelContainerClass}
            p_labelFontClass={props.p_labelFontClass}
            p_label={((usingLabelsTF) ? (componentLabelsArray[m_tileIndex]) : (undefined))}>
            {(m_tileIndex >= 0) &&
              componentsArray[m_tileIndex]
            }
          </Tile>
        )}
      </div>
    )
  );
}

function Tile(props) { //props: p_tileIndex, p_tileMarginClass, p_tileClass, p_tileStyleObj, p_labelContainerClass, p_labelFontClass, p_label, children
  const tileStyleObj = JSFUNC.merge_objs({flexBasis:"100em"}, props.p_tileStyleObj);

  if(props.p_tileIndex < 0) {
    return(
      <div className={"flex11a " + props.p_tileMarginClass} style={tileStyleObj} />
    );
  }

  return(
    <div className={"flex11a " + props.p_tileMarginClass + " " + props.p_tileClass} style={tileStyleObj}>
      {(props.p_label !== undefined) &&
        <div className={props.p_labelContainerClass}>
          <font className={props.p_labelFontClass}>
            {props.p_label}
          </font>
        </div>
      }
      {props.children}
    </div>
  );
}




//========================================================================================================================================
//Special Divs
//========================================================================================================================================
export class DivWithOffClick extends Component { //props: p_offClickIncludesParentTF, p_preventClickDefaultAndPropagationTF, p_class, p_styleObj, f_offClick, f_onKeyDownEsc, children
  constructor(props) {
    super(props);
    this.elementRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.offclick_outside_div);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.offclick_outside_div);
  }

  onclick_div = (event) => { //stops a click from bubbling up to the parent where there is usually an onClick to close itself
    if(this.props.p_preventClickDefaultAndPropagationTF) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  offclick_outside_div = (event) => {
    if(this.props.f_offClick) {
      const offClickIncludesParentTF = JSFUNC.prop_value(this.props.p_offClickIncludesParentTF, false);
      if(offClickIncludesParentTF) {
        if(!this.elementRef.current.parentNode.contains(event.target) && !this.elementRef.current.contains(event.target)) {
          this.props.f_offClick();
        }
      }
      else {
        if(!this.elementRef.current.contains(event.target)) {
          this.props.f_offClick();
        }
      }
    }
  }

  onkeydown_div = (event) => {
    if((event.keyCode === 27) && this.props.f_onKeyDownEsc) { //esc key
      event.preventDefault();
      event.stopPropagation();
      this.props.f_onKeyDownEsc();
    }
  }

  render() {
    return(
      <div
        ref={this.elementRef}
        className={this.props.p_class}
        style={this.props.p_styleObj}
        onClick={this.onclick_div}
        onKeyDown={this.onkeydown_div}>
        {this.props.children}
      </div>
    );
  }
}







export function FloatingBox(props) { //props: p_trbl, p_tb, p_lr, p_bgColor, p_shadowColor, p_outsideBgOpacity0to1, f_onKeyDownEsc, children
  const trbl = props.p_trbl;
  const tb = props.p_tb;
  const lr = props.p_lr;
  const bgColor = JSFUNC.prop_value(props.p_bgColor, "ffffff");
  const shadowColor = JSFUNC.prop_value(props.p_shadowColor, "666666");
  const outsideBgOpacity0to1 = JSFUNC.prop_value(props.p_outsideBgOpacity0to1, 0.1); //number 0 (transparent) to 1 (black)

  var styleObj = {boxShadow:"0em 0em 1.25em 0.5em #" + shadowColor, border:"solid 1px #888", background:"#" + bgColor};

  if(trbl !== undefined) {
    styleObj.top = trbl;
    styleObj.right = trbl;
    styleObj.bottom = trbl;
    styleObj.left = trbl;
  }
  else if(tb !== undefined && lr !== undefined) {
    styleObj.top = tb;
    styleObj.right = lr;
    styleObj.bottom = tb;
    styleObj.left = lr;
  }
  else {
    styleObj.top = "10%";
    styleObj.right = "10%";
    styleObj.bottom = "10%";
    styleObj.left = "10%";
  }


  return(
    <InteractiveDiv
      p_class="positionFixedFullScreen"
      p_styleObj={{zIndex:"999"}}
      p_focusTF={true}
      f_onKeyDownEsc={props.f_onKeyDownEsc}>
      <InteractiveDiv
        p_class="positionFixedFullScreen"
        p_focusTF={true}
        p_styleObj={{background:"#000000", opacity:outsideBgOpacity0to1}}
      />
      <DivWithOffClick
        p_offClickIncludesParentTF={undefined}
        p_preventClickDefaultAndPropagationTF={true}
        p_class="positionFixed displayFlexColumn"
        p_styleObj={styleObj}
        f_offClick={props.f_onKeyDownEsc}
        f_onKeyDownEsc={props.f_onKeyDownEsc}>
        {props.children}
      </DivWithOffClick>
    </InteractiveDiv>
  );
}





//========================================================================================================================================
//Drag/Drop
//========================================================================================================================================
export class Drag2Drop2Shell2 extends Component { //props: 
  //p_uniqueString, p_itemID, p_userCurrentlyDraggingItemUniqueStringOrUndefined, p_draggableTF, p_droppableTF, p_dropZoneIsInvisibleOverlayTF, p_dropZoneOversizeWidthEm, p_class, p_styleObj, p_title, p_dragOverClass, p_dragOverStyleObj, p_fileUploadIsProcessingTF, 
  //f_onDragStart(i_uniqueString, i_itemID, event), 
  //f_onDragEnd(i_uniqueString, i_itemID, event), 
  //f_onDragEnterDropZone(i_uniqueString, i_itemID, event), 
  //f_isDragOverTF(i_isDragOverTF), 
  //f_onDropMatchingItem(i_droppedItemID, event), 
  //f_onDropForeignItem(i_droppedItemUniqueString, i_droppedItemID, event), 
  //f_onDropFiles(i_dataTransferFilesArray, event), 
  //f_onDrop(),
  //f_onClick(event), 
  //children

  //p_uniqueString:                                     a unique string that pairs together draggable html elements with separate html drop zones so that the drop zone can determine what to do with the dragged/dropped item
  //p_itemID:                                           unique integer id for a dragged html item to use when dropped on a drop zone
  //p_userCurrentlyDraggingItemUniqueStringOrUndefined: 
  //    whether any html item in the entire website is currently being dragged, the React App must keep track at a single highest level for all drag/drop html items 
  //    when f_onDragStart() is triggered (set this var), and when f_onDragEnd() is triggered (unset this var), this set/unset true/false is passed as this TF input
  //p_draggableTF:                                      if this item can be dragged
  //p_droppableTF:                                      if this item can act as a drop zone for dragged html items or computer files, an html dropzone can also be a draggable item to resort among a list of items
  //p_dropZoneIsInvisibleOverlayTF:                     [default false]
  //    false - dropzone is directly part of item (leads to behavior when drag hover cuts out over a child within the dropzone parent)
  //    true - the item is drawn, then an absolute positioned invisible layer for drop is drawn over top of the item while any item in the system is dragging, can be nonfunctional if the drag items are superimposed over this drop zone (like table headers resizing))
  //p_dropZoneOversizeWidthEm:                          [default undefined] if set (and p_dropZoneIsInvisibleOverlayTF is true), the invisible drop zone above the item will be larger on all sides by the width int/decimal number provided in units of 'em'
  //p_class/p_styleObj/p_title:                         styling/title of the item (underneath the invisible drop zone that is drawn when p_userCurrentlyDraggingItemUniqueStringOrUndefined is true)
  //p_dragOverClass/p_dragOverStyleObj:                 styling of the invisible drop zone when p_userCurrentlyDraggingItemUniqueStringOrUndefined is true (common example to draw a thick color border around the invisible zone)

  constructor(props) {
    super(props);

    this.inputRef = React.createRef();

    this.state = {
      s_isDragOverLayerCount: 0, //initialize dragOver layer count to 0, count increments as children are crossed inside the drop item
      s_isDragOverTF: false
    };
  }

  ondragstart_shell = (event) => {
    const p_uniqueString = this.props.p_uniqueString;
    const p_itemID = this.props.p_itemID;
    const p_draggableTF = this.props.p_draggableTF;

    if(p_draggableTF) {
      event.stopPropagation();

      const draggerUniqueStringStarItemID = p_uniqueString + "*" + JSFUNC.num2str(p_itemID); //verification string is in format "uniqueString*43"
      event.dataTransfer.setData("text/plain", draggerUniqueStringStarItemID);

      if(JSFUNC.is_function(this.props.f_onDragStart)) {
        this.props.f_onDragStart(p_uniqueString, p_itemID, event);
      }
    }
    else {
      event.preventDefault();
    }
  }

  ondragend_shell = (event) => {
    const p_uniqueString = this.props.p_uniqueString;
    const p_itemID = this.props.p_itemID;

    event.stopPropagation();

    if(JSFUNC.is_function(this.props.f_onDragEnd)) {
      this.props.f_onDragEnd(p_uniqueString, p_itemID, event);
    }
  }

  ondragenter_shell = (event) => {
    const p_uniqueString = this.props.p_uniqueString;
    const p_itemID = this.props.p_itemID;
    const p_droppableTF = this.props.p_droppableTF;

    event.preventDefault();

    if(p_droppableTF) { //do not increment dragOver layer counts if an item is not droppable to prevent f_isDragOverTF from firing
      if(JSFUNC.is_function(this.props.f_onDragEnterDropZone)) {
        this.props.f_onDragEnterDropZone(p_uniqueString, p_itemID, event);
      }

      if(this.state.s_isDragOverLayerCount === 0) { //if crossing into the object from 0 layer count to 1, execute the f_isDragOverTF as true
        this.call_is_drag_over_tf(true);
      }

      this.setState((i_state, i_props) => ({
        s_isDragOverLayerCount: (i_state.s_isDragOverLayerCount + 1)
      }));
    }
  }

  ondragleave_shell = (event) => {
    const s_isDragOverLayerCount = this.state.s_isDragOverLayerCount;

    const p_droppableTF = this.props.p_droppableTF;

    event.preventDefault();
    if(p_droppableTF) { //do not increment dragOver layer counts if an item is not droppable to prevent f_isDragOverTF from firing
      if(s_isDragOverLayerCount === 1) { //if crossing out of the object from 1 layer count to 0, execute the f_isDragOverTF as false
        this.call_is_drag_over_tf(false);
      }

      this.setState((i_state, i_props) => ({
        s_isDragOverLayerCount: (i_state.s_isDragOverLayerCount - 1)
      }));
    }
  }

  ondrop_shell = (event) => {
    const p_uniqueString = this.props.p_uniqueString;
    const p_itemID = this.props.p_itemID;
    const p_droppableTF = this.props.p_droppableTF;

    //don't allow things like file uploads onto regular items, this function fully controls how drops work (html and files) instead of the html default behavior for either
    event.preventDefault();

    //if this item is droppable and something was dropped on it, don't allow further propagation of drops to elements behind this one
    if(p_droppableTF) {
      event.stopPropagation();
    }

    //reset the dragOver layer count
    this.setState({s_isDragOverLayerCount:0});

    //turn off the dragOver after a drop
    this.call_is_drag_over_tf(false);

    //dropping any website item onto this item also triggers f_onDragEnd to signal an end to the global item drag
    if(JSFUNC.is_function(this.props.f_onDragEnd)) {
      this.props.f_onDragEnd(p_uniqueString, p_itemID, event);
    }

    //to allow a drop, p_droppableTF must be true and either f_onDropMatchingItem/f_onDropForeignItem or f_onDropFiles must be defined
    if(p_droppableTF) {
      const fOnDropMatchingItemIsFunctionTF = JSFUNC.is_function(this.props.f_onDropMatchingItem);
      const fOnDropForeignItemIsFunctionTF = JSFUNC.is_function(this.props.f_onDropForeignItem);
      const fOnDropFilesIsFunctionTF = JSFUNC.is_function(this.props.f_onDropFiles);
      const fOnDropIsFunctionTF = JSFUNC.is_function(this.props.f_onDrop);

      const droppedItemObjOrFalse = this.extract_unique_string_and_item_id_obj_or_false_from_event(event);

      if(fOnDropMatchingItemIsFunctionTF || fOnDropForeignItemIsFunctionTF) { //f_onDropMatchingItem/f_onDropForeignItem for dragged draggable website html items
        if(droppedItemObjOrFalse !== false) {
          const droppedItemUniqueString = droppedItemObjOrFalse.uniqueString;
          const droppedItemID = droppedItemObjOrFalse.itemID;
          if(droppedItemUniqueString === p_uniqueString) { //if the uniqueStrings match to signify that the dragged item is within the same drag/drop set of items as this drop item
            if(droppedItemID !== p_itemID) { //does nothing if you drop the item into the same spot over itself, ids have to be different
              if(fOnDropMatchingItemIsFunctionTF) {
                this.props.f_onDropMatchingItem(droppedItemID, event);
              }
            }
          }
          else { //uniqueStrings do not match
            if(fOnDropForeignItemIsFunctionTF) {
              this.props.f_onDropForeignItem(droppedItemUniqueString, droppedItemID, event);
            }
          }
        }
      }
      else if(fOnDropFilesIsFunctionTF) { //f_onDropFiles for external file uploads
        if(droppedItemObjOrFalse === false) { //only call f_onDropFiles if the dropped item is not an html dragged item from the website (uploaded file from computer will not have getData("text/plain") filled out)
          const dataTransferFilesArray = event.dataTransfer.files;
          this.props.f_onDropFiles(dataTransferFilesArray, event);
        }
      }

      //if anything is dropped, regardless of whether it matches the intended input, call this function with no inputs (used to reset global variables tracking if items are currently being dragged)
      if(fOnDropIsFunctionTF) {
        this.props.f_onDrop();
      }
    }
  }

  call_is_drag_over_tf = (i_isDragOverTF) => {
    this.setState({s_isDragOverTF:i_isDragOverTF});

    if(JSFUNC.is_function(this.props.f_isDragOverTF)) {
      this.props.f_isDragOverTF(i_isDragOverTF);
    }
  }

  onclick_shell = (event) => {
    if(JSFUNC.is_function(this.props.f_onClick)) {
      this.props.f_onClick(event);
    }
  }

  onclick_file_upload_zone = () => {
    this.inputRef.current.click();
  }

  onchange_input_type_file_user_selects_computer_files = (event) => {
    if(JSFUNC.is_function(this.props.f_onDropFiles)) {
      const eventTargetFilesList = event.target.files
      var fileInputClickEventTargetFileItemsArray = []; //<input type="file" /> handling onClick() user selection of computer files to upload
      for(let f = 0; f < eventTargetFilesList.length; f++) {
        var fileItem = eventTargetFilesList.item(f);
        fileInputClickEventTargetFileItemsArray.push(fileItem);
      }
      this.props.f_onDropFiles(fileInputClickEventTargetFileItemsArray);
    }
  }

  onclick_prevent_click = (event) => {
    event.preventDefault();
    event.stopPropagation();
  }

  extract_unique_string_and_item_id_obj_or_false_from_event(event) {
    //verification string of dropped item is in format "uniqueString*43", break this apart into "uniqueString" and draggerID 43
    const draggerUniqueStringStarItemID = event.dataTransfer.getData("text/plain");
    if(JSFUNC.is_string(draggerUniqueStringStarItemID)) {
      const draggerStarIndex = draggerUniqueStringStarItemID.indexOf("*");
      if(draggerStarIndex >= 0) {
        const draggerUniqueString = draggerUniqueStringStarItemID.substring(0, draggerStarIndex);
        const draggerID = JSFUNC.str2int(draggerUniqueStringStarItemID.substring(draggerStarIndex + 1, draggerUniqueStringStarItemID.length));
        return({
          uniqueString: draggerUniqueString,
          itemID: draggerID
        });
      }
    }
    return(false);
  }

  render() {
    const s_isDragOverLayerCount = this.state.s_isDragOverLayerCount;
    const s_isDragOverTF = this.state.s_isDragOverTF;

    const p_uniqueString = this.props.p_uniqueString;
    const p_itemID = this.props.p_itemID;
    const p_userCurrentlyDraggingItemUniqueStringOrUndefined = this.props.p_userCurrentlyDraggingItemUniqueStringOrUndefined;
    const p_userCurrentlyDraggingItemOverDropZoneObjOrUndefined = this.props.p_userCurrentlyDraggingItemOverDropZoneObjOrUndefined;
    const p_draggableTF = this.props.p_draggableTF;
    const p_droppableTF = this.props.p_droppableTF;
    const p_dropZoneIsInvisibleOverlayTF = JSFUNC.prop_value(this.props.p_dropZoneIsInvisibleOverlayTF, false);
    const p_dropZoneOversizeWidthEm = this.props.p_dropZoneOversizeWidthEm;
    const p_class = this.props.p_class;
    const p_styleObj = this.props.p_styleObj;
    const p_title = this.props.p_title;
    const p_dragOverClass = JSFUNC.prop_value(this.props.p_dragOverClass, "");
    const p_dragOverStyleObj = this.props.p_dragOverStyleObj;
    const p_fileUploadIsProcessingTF = JSFUNC.prop_value(this.props.p_fileUploadIsProcessingTF, false);

    //dropzone <label> to upload dragged files, or <input> click to select files from computer
    if(JSFUNC.is_function(this.props.f_onDropFiles)) {
      //append positionRelative and overflowVisible to the item class string
      var itemClassStringWithPositionRelative = "positionRelative";
      if(JSFUNC.is_string(p_class)) {
        itemClassStringWithPositionRelative = "positionRelative " + p_class;
      }

      //oversized invisible drop zone over the item if specified by a width (in em units) on all 4 sides
      var invisibleDropZoneOverlayInset = "0.01em";
      if(JSFUNC.is_number_not_nan_gt_0(p_dropZoneOversizeWidthEm)) {
        invisibleDropZoneOverlayInset = "-" + p_dropZoneOversizeWidthEm + "em";
      }

      return(
        <div
          className={itemClassStringWithPositionRelative}
          style={p_styleObj}
          onClick={this.onclick_file_upload_zone}>
          {this.props.children}
          <div className="positionAbsolute t0 r0 b0 l0 overflowHidden">
            <input
              ref={this.inputRef}
              type="file"
              data-multiple-caption="{count} Files Selected"
              multiple="multiple"
              className="positionAbsolute textRight"
              style={{top:"0", left:"0", height:"100em", width:"100em", filter:"alpha(opacity=0)", opacity:0, fontSize:"999px"}}
              value=""
              title={p_title}
              onChange={((p_fileUploadIsProcessingTF) ? (undefined) : (this.onchange_input_type_file_user_selects_computer_files))}
              onClick={((p_fileUploadIsProcessingTF) ? (this.onclick_prevent_click) : (undefined))}
            />
          </div>
          <div
            className={"positionAbsolute " + ((p_fileUploadIsProcessingTF) ? ("cursorNotAllowed") : ("cursorPointer"))}
            style={{inset:invisibleDropZoneOverlayInset}}
            draggable={true}
            onDragEnter={this.ondragenter_shell}
            onDragLeave={this.ondragleave_shell}
            onDrop={((p_fileUploadIsProcessingTF) ? (undefined) : (this.ondrop_shell))}
          />
        </div>
      );
    }

    //superimpose an invisible <div> the same size as the item if this item is droppable (and invisible drop layer is requested) AND there is any item on the website that the user is currently dragging
    if(p_droppableTF && p_dropZoneIsInvisibleOverlayTF && (p_userCurrentlyDraggingItemUniqueStringOrUndefined !== undefined)) {
      //append positionRelative to the input item class string
      var itemClassStringWithPositionRelative = "positionRelative";
      if(JSFUNC.is_string(p_class)) {
        itemClassStringWithPositionRelative = "positionRelative " + p_class;
      }

      var showDragOverStylingTF = false;
      if(s_isDragOverTF) { //if any dragged item is currently over this particular drop zone item
        if((p_dragOverClass !== undefined) || (p_dragOverStyleObj !== undefined)) { //only need to make this check if there will be anything to draw in the input dragover class/style
          const userCurrentlyDraggingItemUniqueStringMatchesDropZoneUniqueStringTF = (p_userCurrentlyDraggingItemUniqueStringOrUndefined === p_uniqueString);

          if(userCurrentlyDraggingItemUniqueStringMatchesDropZoneUniqueStringTF && JSFUNC.is_function(this.props.f_onDropMatchingItem)) {
            showDragOverStylingTF = true; //if the user dragged item's uniqueString matches this drop zone uniqueString and there is a function call for matching drops
          }

          if(userCurrentlyDraggingItemUniqueStringMatchesDropZoneUniqueStringTF && JSFUNC.is_function(this.props.f_onDropForeignItem)) {
            showDragOverStylingTF = true; //if the unique strings do not match and there's a function call for foreign drops
          }
        }
      }

      //if about to draw the dragover styling, do one last check against the input if the user is currently dragging an item over any particular dropzone, and if that dropzone is this drop item drawn right now
      if(showDragOverStylingTF) {
        if(p_userCurrentlyDraggingItemOverDropZoneObjOrUndefined !== undefined) {
          showDragOverStylingTF = ((p_uniqueString === p_userCurrentlyDraggingItemOverDropZoneObjOrUndefined.uniqueString) && (p_itemID === p_userCurrentlyDraggingItemOverDropZoneObjOrUndefined.itemID));
        }
      }

      

      var invisibleDropZoneOverlayStyleObj = undefined;
      if(showDragOverStylingTF) {
        var invisibleDropZoneOverlayInsetStyleObj = undefined;
        if(JSFUNC.is_number_not_nan_gt_0(p_dropZoneOversizeWidthEm)) {
          invisibleDropZoneOverlayInsetStyleObj = {inset:"-" + p_dropZoneOversizeWidthEm + "em"};
        }

        if((p_dragOverStyleObj !== undefined) || (invisibleDropZoneOverlayInsetStyleObj !== undefined)) {
          invisibleDropZoneOverlayStyleObj = JSFUNC.merge_objs(p_dragOverStyleObj, invisibleDropZoneOverlayInsetStyleObj);
        }
      }

      return(
        <>
          <div
            className={itemClassStringWithPositionRelative}
            style={p_styleObj}
            title={p_title}
            draggable={(p_draggableTF || p_droppableTF)}
            onDragStart={((p_draggableTF) ? (this.ondragstart_shell) : (undefined))}
            onDragEnd={((p_draggableTF) ? (this.ondragend_shell) : (undefined))}
            onDragEnter={((p_droppableTF && !p_dropZoneIsInvisibleOverlayTF) ? (this.ondragenter_shell) : (undefined))}
            onDragLeave={((p_droppableTF && !p_dropZoneIsInvisibleOverlayTF) ? (this.ondragleave_shell) : (undefined))}
            onDrop={((p_droppableTF && !p_dropZoneIsInvisibleOverlayTF) ? (this.ondrop_shell) : (undefined))}
            onClick={this.onclick_shell}>
            {this.props.children}
            <div
              className={"positionAbsolute l0 t0 r0 b0 " + ((showDragOverStylingTF) ? (p_dragOverClass) : (""))}
              style={invisibleDropZoneOverlayStyleObj}
              draggable={true}
              onDragEnter={this.ondragenter_shell}
              onDragLeave={this.ondragleave_shell}
              onDrop={this.ondrop_shell}
            />
          </div>
        </>
      );
    }

    //draw the item without a drop zone on top so that all click actions (click, title, cursor, hover, etc) still work on the item
    //[having the superimposed drop layer prevents these actions, while the 'pointer-events:none' css solution doesn't work because it prevents drag/drop for that invisible top layer]
    //item can be set 3 ways (need 'draggable' <div> parameter to be true if either draggable or droppable)
    //  - draggable only
    //  - draggable while also being droppable
    //  - droppable only 
    return(
      <div
        className={p_class}
        style={p_styleObj}
        title={p_title}
        draggable={(p_draggableTF || p_droppableTF)}
        onDragStart={((p_draggableTF) ? (this.ondragstart_shell) : (undefined))}
        onDragEnd={((p_draggableTF) ? (this.ondragend_shell) : (undefined))}
        onDragEnter={((p_droppableTF && !p_dropZoneIsInvisibleOverlayTF) ? (this.ondragenter_shell) : (undefined))}
        onDragLeave={((p_droppableTF && !p_dropZoneIsInvisibleOverlayTF) ? (this.ondragleave_shell) : (undefined))}
        onDrop={((p_droppableTF && !p_dropZoneIsInvisibleOverlayTF) ? (this.ondrop_shell) : (undefined))}
        onClick={this.onclick_shell}>
        {this.props.children}
      </div>
    );
  }
}












//========================================================================================================================================
//Inputs
//========================================================================================================================================
export class Image extends Component { //props: p_src, p_alt, p_class, p_styleObj, f_onClick
  ondragstart_image = (event) => {
    event.preventDefault();
  }

  render() {
    const p_class = JSFUNC.prop_value(this.props.p_class, "");
    return(
      <img
        className={"noSelect " + p_class}
        style={this.props.p_styleObj}
        src={this.props.p_src}
        alt={this.props.p_alt}
        draggable={true}
        onDragStart={this.ondragstart_image}
        onClick={this.props.f_onClick}
      />
    );
  }
}

export function ButtonNowrap(props) { //props: p_value, p_class, p_fontClass, p_tabIndex, p_title, p_errorTF, f_onClick
  //single line of text full length, button fits to width of the text
  return(
    <InteractiveDiv
      p_class={"buttonNowrap " + props.p_class}
      p_tabIndex={props.p_tabIndex}
      p_title={props.p_title}
      p_errorTF={props.p_errorTF}
      f_onClick={props.f_onClick}
      f_onKeyDownEnter={((props.p_tabIndex > 0) ? (props.f_onClick) : (undefined))}>
      <font className={props.p_fontClass}>
        {props.p_value}
      </font>
    </InteractiveDiv>
  );
}

export function ButtonMaxHeight(props) { //props: p_value, p_height, p_maxHeight, p_class, p_fontClass, p_tabIndex, p_title, p_errorTF, f_onClick
  //button with fixed height of p_height and width of 100% of its container, multiple wrapped lines of text that cut off at the max height
  return(
    <InteractiveDiv
      p_class={"flex11a displayFlexRowVc textCenter " + props.p_class}
      p_styleObj={{height:props.p_height}}
      p_tabIndex={props.p_tabIndex}
      p_title={props.p_title}
      p_errorTF={props.p_errorTF}
      f_onClick={props.f_onClick}
      f_onKeyDownEnter={((props.p_tabIndex > 0) ? (props.f_onClick) : (undefined))}>
      <MaxHeightWrap p_maxHeight={props.p_maxHeight} p_fontClass={props.p_fontClass}>
        {props.p_value}
      </MaxHeightWrap>
    </InteractiveDiv>
  );
}

export function ButtonSubmit(props) { //props: p_value, p_class, p_tabIndex, p_title, p_errorTF, f_onClick
  return(
    <InteractiveDivOrInput
      p_tagInputTypeString="submit"
      p_class={props.p_class}
      p_tabIndex={props.p_tabIndex}
      p_title={props.p_title}
      p_errorTF={props.p_errorTF}
      f_onClick={props.f_onClick}
      f_onKeyDownEnter={((props.p_tabIndex > 0) ? (props.f_onClick) : (undefined))}>
      {props.p_value}
    </InteractiveDivOrInput>
  );
}


export function Switch(props) { //props: p_isOnTF, p_sizeEm, p_onColor, p_offColor, p_tabIndex, p_title, p_errorTF, f_onClick
  const sizeEm = JSFUNC.prop_value(props.p_sizeEm, 4);
  const defaultOnColor = "005da3";
  const defaultOffColor = "999999";
  const widthEm = sizeEm;
  const heightEm = sizeEm * (1.75 / 4);
  const switchButtonMarginEm = 0.3;

  const onColor = JSFUNC.prop_value(props.p_onColor, defaultOnColor);
  const offColor = JSFUNC.prop_value(props.p_offColor, defaultOffColor);

  const borderRadiusEm = (heightEm / 2);
  const distanceToOppositeLREdge = (widthEm - heightEm + switchButtonMarginEm);
  const leftEm = ((props.p_isOnTF) ? (distanceToOppositeLREdge) : (switchButtonMarginEm));
  const rightEm = ((props.p_isOnTF) ? (switchButtonMarginEm) : (distanceToOppositeLREdge));
  const bgColor = ((props.p_isOnTF) ? (onColor) : (offColor));

  const canClickTF = JSFUNC.is_function(props.f_onClick);

  return(
    <InteractiveDiv
      p_class={"positionRelative inlineBlock " + ((canClickTF) ? ("cursorPointer") : (""))}
      p_styleObj={{width:widthEm + "em", height:heightEm + "em", background:"#" + bgColor, borderRadius:borderRadiusEm + "em"}}
      p_tabIndex={props.p_tabIndex}
      p_title={props.p_title}
      p_errorTF={props.p_errorTF}
      f_onClick={((canClickTF) ? (props.f_onClick) : (undefined))}
      f_onKeyDownEnter={((canClickTF && props.p_tabIndex > 0) ? (props.f_onClick) : (undefined))}>
      <div
        style={{position:"absolute", top:switchButtonMarginEm + "em", right:rightEm + "em", bottom:switchButtonMarginEm + "em", left:leftEm + "em", borderRadius:borderRadiusEm + "em", background:"#fefefe", transition:"0.1s"}}
      />
    </InteractiveDiv>
  );
}


export function ThreeWaySwitch(props) { //props: p_stateC0L1R2, p_sizeEm, p_leftColor, p_rightColor, p_tabIndex, p_focusTF, p_leftTitle, p_centerTitle, p_rightTitle, p_errorTF, f_onSelectCenter, f_onSelectLeft, f_onSelectRight, f_onClick, f_onKeyDownEnter, f_onKeyDownUpArrow, f_onKeyDownDownArrow
  const p_stateC0L1R2 = props.p_stateC0L1R2;
  const p_sizeEm = JSFUNC.prop_value(props.p_sizeEm, 4);
  const p_leftColor = JSFUNC.prop_value(props.p_leftColor, "005da3");
  const p_rightColor = JSFUNC.prop_value(props.p_rightColor, "bd2326");
  const p_tabIndex = props.p_tabIndex;
  const p_focusTF = props.p_focusTF;
  const p_leftTitle = props.p_leftTitle;
  const p_centerTitle = props.p_centerTitle;
  const p_rightTitle = props.p_rightTitle;
  const p_errorTF = props.p_errorTF;

  //if input p_stateC0L1R2 is not 0, 1, or 2, force it to be 0
  var inputStateC0L1R2ForcedInt = p_stateC0L1R2;
  var currentStateIsCenterTF = false;
  var currentStateIsLeftTF = false;
  var currentStateIsRightTF = false;
  if(p_stateC0L1R2 === 0) { currentStateIsCenterTF = true; }
  else if(p_stateC0L1R2 === 1) { currentStateIsLeftTF = true; }
  else if(p_stateC0L1R2 === 2) { currentStateIsRightTF = true; }
  else { inputStateC0L1R2ForcedInt = 0; }

  const widthEm = p_sizeEm;
  const heightEm = (p_sizeEm / 2.2);
  const bgColor = "aaaaaa";
  const sliderColor = "dddddd";
  const sliderWidth0to1 = 0.6;
  const sliderMarginEm = 0.2;
  const borderLightColor = "ddd";
  const borderDarkColor = "777";
  const bgBorderString = "#" + borderDarkColor + " #" + borderLightColor + " #" + borderLightColor + " #" + borderDarkColor;
  const sliderBorderString = "#" + borderLightColor + " #" + borderDarkColor + " #" + borderDarkColor + " #" + borderLightColor;

  var sliderLeftEm = 0;
  var sliderRightEm = 0;
  var bgHashColor = undefined;
  if(currentStateIsLeftTF) { //left (slider on left, left color on right side)
    sliderLeftEm = sliderMarginEm;
    sliderRightEm = (widthEm * (1 - sliderWidth0to1));
    bgHashColor = "linear-gradient(90deg, #" + bgColor + ", #" + p_leftColor + ")";
  }
  else if(currentStateIsRightTF) { //right (slider on right, right color on the left side)
    sliderLeftEm = (widthEm * (1 - sliderWidth0to1));
    sliderRightEm = sliderMarginEm;
    bgHashColor = "linear-gradient(90deg, #" + p_rightColor + ", #" + bgColor + ")";
  }
  else { //center
    sliderLeftEm = ((widthEm * ((1 - sliderWidth0to1) / 2)) + (sliderMarginEm / 2));
    sliderRightEm = sliderLeftEm;
    bgHashColor = "#" + bgColor;
  }

  const leftRightClickZoneWidth0to1 = 0.4;

  var cursorClass = "";
  var keyDownSpaceFunction = undefined; //pushing space bar selects the center choice (if not already center)
  var keyDownLeftArrowFunction = undefined; //pushing left arrow key selects the left choice (if not already left)
  var keyDownRightArrowFunction = undefined; //pushing right arrow key selects the right choice (if not already right)
  if(JSFUNC.is_function(props.f_onSelectCenter) && JSFUNC.is_function(props.f_onSelectLeft) && JSFUNC.is_function(props.f_onSelectRight)) {
    cursorClass = "cursorPointer";
    if(currentStateIsCenterTF) {
      keyDownLeftArrowFunction = props.f_onSelectLeft;
      keyDownRightArrowFunction = props.f_onSelectRight;
    }
    else if(currentStateIsLeftTF) {
      keyDownSpaceFunction = props.f_onSelectCenter;
      keyDownRightArrowFunction = props.f_onSelectRight;
    }
    else if(currentStateIsRightTF) {
      keyDownSpaceFunction = props.f_onSelectCenter;
      keyDownLeftArrowFunction = props.f_onSelectLeft;
    }
  }

  return(
    <InteractiveDiv
      p_class={"positionRelative inlineBlock border1 " + cursorClass}
      p_styleObj={{width:widthEm + "em", height:heightEm + "em", borderColor:bgBorderString, background:bgHashColor}}
      p_tabIndex={p_tabIndex}
      p_focusTF={p_focusTF}
      p_errorTF={p_errorTF}
      f_onClick={props.f_onClick}
      f_onKeyDownEnter={props.f_onKeyDownEnter}
      f_onKeyDownSpace={keyDownSpaceFunction}
      f_onKeyDownUpArrow={props.f_onKeyDownUpArrow}
      f_onKeyDownDownArrow={props.f_onKeyDownDownArrow}
      f_onKeyDownLeftArrow={keyDownLeftArrowFunction}
      f_onKeyDownRightArrow={keyDownRightArrowFunction}>
      <div
        className="positionAbsolute displayFlexRow border1 textCenter"
        style={{top:sliderMarginEm + "em", right:sliderRightEm + "em", bottom:sliderMarginEm + "em", left:sliderLeftEm + "em", borderColor:sliderBorderString, background:"#" + sliderColor, padding:"0.2em 0", transition:"0.1s"}}>
        <div className="flex11a" style={{flexBasis:"100em"}} />
        <div className="flex11a" style={{flexBasis:"100em", borderLeft:"solid 1px #bbb", borderRight:"solid 1px #bbb"}} />
        <div className="flex11a" style={{flexBasis:"100em"}} />
      </div>
      <div
        className="positionAbsolute"
        style={{top:0, right:(leftRightClickZoneWidth0to1 * widthEm) + "em", bottom:0, left:0}}
        title={p_leftTitle}
        onClick={((!currentStateIsLeftTF) ? (props.f_onSelectLeft) : (undefined))}
      />
      <div
        className="positionAbsolute"
        style={{top:0, right:(leftRightClickZoneWidth0to1 * widthEm) + "em", bottom:0, left:(leftRightClickZoneWidth0to1 * widthEm) + "em"}}
        title={p_centerTitle}
        onClick={((!currentStateIsCenterTF) ? (props.f_onSelectCenter) : (undefined))}
      />
      <div
        className="positionAbsolute"
        style={{top:0, right:0, bottom:0, left:((1 - leftRightClickZoneWidth0to1) * widthEm) + "em"}}
        title={p_rightTitle}
        onClick={((!currentStateIsRightTF) ? (props.f_onSelectRight) : (undefined))}
      />
    </InteractiveDiv>
  );
}


export function CheckBox(props) { //props: p_u0_s1_p2_du3_ds4, p_sizeEm, p_tabIndex, p_title, p_errorTF, f_onClick
  //p_u0_s1_p2_du3_ds4:
  //  0 - unselected (empty white box)
  //  1 - selected (checked white box)
  //  2 - partial (white box with centered dot)
  //  3 - disabled unselected (empty unclickable gray box)
  //  4 - disabled selected (checked unclickable gray box)

  const u0_s1_p2_du3_ds4 = JSFUNC.prop_value(props.p_u0_s1_p2_du3_ds4, 0);
  const sizeEm = JSFUNC.prop_value(props.p_sizeEm, 1);

  const onClickIsFunctionTF = JSFUNC.is_function(props.f_onClick);

  var symbol = ""; //no mark for unselected
  if(u0_s1_p2_du3_ds4 === 1 || u0_s1_p2_du3_ds4 === 4) { //check mark
    symbol = "\u2713";
  }
  else if(u0_s1_p2_du3_ds4 === 2) { //centered dot
    symbol = "\u00b7";
  }

  const fontSizeMultiplier = ((u0_s1_p2_du3_ds4 === 2) ? (1.5) : (1));
  const isClickableTF = JSFUNC.in_array(u0_s1_p2_du3_ds4, [0, 1, 2]);
  const boxBgColor = ((isClickableTF) ? ("#fff") : ("#bbb"));
  const symbolColorClass = ((isClickableTF) ? ("fontBlue") : ("fontTextLighter"));

  const heightWidth = (sizeEm * 1.1) + "em";
  const fontSize = (sizeEm * fontSizeMultiplier) + "em";
  return(
    <InteractiveDiv
      p_class={"displayFlexColumnHcVc border bevelBorderColors fontBold  " + symbolColorClass + " " + ((onClickIsFunctionTF) ? ("cursorPointer") : (""))}
      p_styleObj={{overflow:"hidden", height:heightWidth, width:heightWidth, backgroundColor:boxBgColor, borderRadius:"0.2em"}}
      p_tabIndex={props.p_tabIndex}
      p_title={props.p_title}
      p_errorTF={props.p_errorTF}
      f_onClick={((isClickableTF) ? (props.f_onClick) : (undefined))}
      f_onKeyDownEnter={((isClickableTF && (props.p_tabIndex > 0)) ? (props.f_onClick) : (undefined))}>
      <font style={{fontSize:fontSize}}>{symbol}</font>
    </InteractiveDiv>
  );
}


export class Color extends Component { //props: p_value, p_class, p_styleObj, p_tabIndex, p_title, p_errorTF, f_onChange, f_onKeyDownEnter
  onchange_color = (i_newColorPoundHex6) => {
    if(this.props.f_onChange) {
      this.props.f_onChange(JSFUNC.convert_any_hex_to_hex6(i_newColorPoundHex6));
    }
  }

  render() {
    const hex6Value = JSFUNC.convert_any_hex_to_hex6(this.props.p_value);
    return(
      <InteractiveDivOrInput
        p_tagInputTypeString="color"
        p_class={"cursorPointer " + this.props.p_class}
        p_styleObj={this.props.p_styleObj}
        p_tabIndex={this.props.p_tabIndex}
        p_title={this.props.p_title}
        p_errorTF={this.props.p_errorTF}
        f_onChange={this.onchange_color}
        f_onKeyDownEnter={this.props.f_onKeyDownEnter}>
        {"#" + hex6Value}
      </InteractiveDivOrInput>
    );
  }
}


export function Date(props) { //props: p_value, p_class, p_styleObj, p_tabIndex, p_title, p_errorTF, f_onChange, f_onKeyDownEnter
  return(
    <InteractiveDivOrInput
      p_tagInputTypeString="date"
      p_class={props.p_class}
      p_styleObj={props.p_styleObj}
      p_tabIndex={props.p_tabIndex}
      p_title={props.p_title}
      p_errorTF={props.p_errorTF}
      f_onChange={props.f_onChange}
      f_onKeyDownEnter={props.f_onKeyDownEnter}>
      {props.p_value}
    </InteractiveDivOrInput>
  );
}


export class DateTime extends Component { //props: p_valueDateTimeUTC, p_isSingleLineTF, p_tabIndex, p_title, p_errorTF, f_onChange, f_onKeyDownEnter
  //converts and presents the given UTC time as local, then onchange (only when the entire date and time inputs are all valid) converts it back to UTC and sends the UTC value as the callback value
  //input p_valueDateTimeUTC can be either a datetime or a date (which fills the time in as 00:00:58 and keeps the date the same)
  //flags for seconds values:
  //  HH:MM:58  - this date/time has never had a time set, when a time is set, use LOCAL 11:00:00AM and convert that to UTC for the raw value
  //  HH:MM:59  - this date/time is holding the time HH:MM (UTC) in its raw value, but showing the user that time is not set, when set, the 59 seconds is set to 00 and the time unchanged is shown (this way the user can toggle back and forth between set/unset time with the value being held)
  //when the date portion is cleared, the raw dateTimeUtc value is reset fully to 0000-00-00 00:00:00
  //when a date is set from 0000-00-00 00:00:00, it is set to the selected date with midnight and 58 seconds YYYY-MM-DD 00:00:58
  constructor(props) {
    super(props);

    const p_valueDateTimeUTC = this.props.p_valueDateTimeUTC;

    const localDateTimeObj = this.convert_input_datetimeUTC_to_local_date_hours_minutes_ampm_obj(p_valueDateTimeUTC);

    this.state = {
      s_localDateYmd: localDateTimeObj.localDateYmd,
      s_localHours1to12Int: localDateTimeObj.localHours1to12Int,
      s_localMinutes0to59Int: localDateTimeObj.localMinutes0to59Int,
      s_localSeconds0to59Int: localDateTimeObj.localSeconds0to59Int,
      s_localAMPM: localDateTimeObj.localAMPM
    };
  }

  componentDidUpdate(prevProps) {
    const p_valueDateTimeUTC = this.props.p_valueDateTimeUTC;

    if(p_valueDateTimeUTC !== prevProps.p_valueDateTimeUTC) {
      const localDateTimeObj = this.convert_input_datetimeUTC_to_local_date_hours_minutes_ampm_obj(p_valueDateTimeUTC);
      this.setState({
        s_localDateYmd: localDateTimeObj.localDateYmd,
        s_localHours1to12Int: localDateTimeObj.localHours1to12Int,
        s_localMinutes0to59Int: localDateTimeObj.localMinutes0to59Int,
        s_localSeconds0to59Int: localDateTimeObj.localSeconds0to59Int,
        s_localAMPM: localDateTimeObj.localAMPM
      });
    }
  }

  onchange_date = (i_newDateValueYmd) => {
    const s_localDateYmd = this.state.s_localDateYmd;
    const s_localHours1to12Int = this.state.s_localHours1to12Int;
    const s_localMinutes0to59Int = this.state.s_localMinutes0to59Int;
    const s_localSeconds0to59Int = this.state.s_localSeconds0to59Int;
    const s_localAMPM = this.state.s_localAMPM;

    const p_valueDateTimeUTC = this.props.p_valueDateTimeUTC;

    const updatedDateIsFilledOutTF = JSFUNC.date_is_filled_out_tf(i_newDateValueYmd);
    if(!updatedDateIsFilledOutTF) { //date YYYY-MM-DD has been cleared to 0000-00-00, also clear the time to 00:00:00
      this.setState({
        s_localDateYmd: JSFUNC.blank_date(),
        s_localHours1to12Int: 0,
        s_localMinutes0to59Int: 0,
        s_localSeconds0to59Int: 58, //reset to 58 seconds flag that this time has never been set (not needed as the blank date hides all times in the display below)
        s_localAMPM: "AM"
      });
      const updatedDateTimeUtc = JSFUNC.blank_datetime(); //0000-00-00 00:00:00 is returned for the utc raw value when a date is cleared
      this.call_onchange_with_updated_datetimeutc(updatedDateTimeUtc);
    }
    else {
      const updatedDateYearString = JSFUNC.direct_get_yyyy_string_from_Ymd_date(i_newDateValueYmd);
      const updatedDateYearInt = JSFUNC.str2int(updatedDateYearString);
      if(updatedDateYearInt > 999) { //this allows typing the MMDDYYYY as 6 numbers manually into the google date input (otherwise the instant a "2" is entered to start the year, it is forced to "1902")
        const previousDateWasFilledOutTF = JSFUNC.date_is_filled_out_tf(s_localDateYmd);

        var updatedLocalHours1to12Int = 0;
        var updatedLocalMinutes0to59 = 0;
        var updatedLocalSeconds0to59 = 0;
        var updatedLocalAMPM = "AM";
        var updatedDateTimeUtc = JSFUNC.blank_datetime();
        if(previousDateWasFilledOutTF) { //changing from a filled out date to a different filled out date, keep all time data the same
          updatedLocalHours1to12Int = s_localHours1to12Int;
          updatedLocalMinutes0to59 = s_localMinutes0to59Int;
          updatedLocalSeconds0to59 = s_localSeconds0to59Int;
          updatedLocalAMPM = s_localAMPM;
          updatedDateTimeUtc = this.convert_local_dateYmd_hours1to12_minutes_seconds_ampm_to_mysqldatetimeutc(i_newDateValueYmd, s_localHours1to12Int, s_localMinutes0to59Int, s_localSeconds0to59Int, s_localAMPM);
        }
        else { //if the date was previously not set "0000-00-00" and now it's being set (with a 4 digit year), set the time to 00:00:58 as a flag that the time has never been set and not to convert the date timezone
          updatedLocalHours1to12Int = 0;
          updatedLocalMinutes0to59 = 0;
          updatedLocalSeconds0to59 = 58;
          updatedLocalAMPM = "AM";
          updatedDateTimeUtc = i_newDateValueYmd + " 00:00:58";
        }

        this.setState({
          s_localDateYmd: i_newDateValueYmd,
          s_localHours1to12Int: updatedLocalHours1to12Int,
          s_localMinutes0to59Int: updatedLocalMinutes0to59,
          s_localSeconds0to59Int: updatedLocalSeconds0to59,
          s_localAMPM: updatedLocalAMPM
        });
        this.call_onchange_with_updated_datetimeutc(updatedDateTimeUtc);
      }
      else {
        this.setState({s_localDateYmd:i_newDateValueYmd});
      }
    }
  }

  onchange_hours = (i_newValue) => {
    const s_localDateYmd = this.state.s_localDateYmd;
    const s_localHours1to12Int = this.state.s_localHours1to12Int;
    const s_localMinutes0to59Int = this.state.s_localMinutes0to59Int;
    const s_localSeconds0to59Int = this.state.s_localSeconds0to59Int;
    const s_localAMPM = this.state.s_localAMPM;

    var updatedLocalHours1to12Int = i_newValue;
    var newLocalAMPM = s_localAMPM;
    if(i_newValue === 13) {
      updatedLocalHours1to12Int = 1;
    }
    else if((s_localHours1to12Int === 11) && (i_newValue === 12)) {
      newLocalAMPM = ((s_localAMPM === "AM") ? ("PM") : ("AM"));
    }
    else if((s_localHours1to12Int === 12) && (i_newValue === 11)) {
      newLocalAMPM = ((s_localAMPM === "AM") ? ("PM") : ("AM"));
    }

    this.setState({
      s_localHours1to12Int: updatedLocalHours1to12Int,
      s_localAMPM: newLocalAMPM
    });

    if(((updatedLocalHours1to12Int >= 1) && (updatedLocalHours1to12Int <= 12)) && JSFUNC.date_is_filled_out_tf(s_localDateYmd)) { //only call onChange if the date is filled out
      const updatedDateTimeUtc = this.convert_local_dateYmd_hours1to12_minutes_seconds_ampm_to_mysqldatetimeutc(s_localDateYmd, updatedLocalHours1to12Int, s_localMinutes0to59Int, s_localSeconds0to59Int, newLocalAMPM);
      this.call_onchange_with_updated_datetimeutc(updatedDateTimeUtc);
    }
  }

  onchange_minutes = (i_newValue) => {
    const s_localDateYmd = this.state.s_localDateYmd;
    const s_localHours1to12Int = this.state.s_localHours1to12Int;
    const s_localMinutes0to59Int = this.state.s_localMinutes0to59Int;
    const s_localSeconds0to59Int = this.state.s_localSeconds0to59Int;
    const s_localAMPM = this.state.s_localAMPM;

    var updatedLocalMinutes0to59 = i_newValue;
    if(updatedLocalMinutes0to59 > 59) {
      updatedLocalMinutes0to59 = 0;
    }
    else if(updatedLocalMinutes0to59 < 0) {
      updatedLocalMinutes0to59 = 59;
    }

    this.setState({s_localMinutes0to59Int:updatedLocalMinutes0to59});

    if(JSFUNC.date_is_filled_out_tf(s_localDateYmd)) { //only call onChange if the date is filled out
      const updatedDateTimeUtc = this.convert_local_dateYmd_hours1to12_minutes_seconds_ampm_to_mysqldatetimeutc(s_localDateYmd, s_localHours1to12Int, updatedLocalMinutes0to59, s_localSeconds0to59Int, s_localAMPM);
      this.call_onchange_with_updated_datetimeutc(updatedDateTimeUtc);
    }
  }

  onclick_ampm_toggle = () => {
    const s_localDateYmd = this.state.s_localDateYmd;
    const s_localHours1to12Int = this.state.s_localHours1to12Int;
    const s_localMinutes0to59Int = this.state.s_localMinutes0to59Int;
    const s_localSeconds0to59Int = this.state.s_localSeconds0to59Int;
    const s_localAMPM = this.state.s_localAMPM;

    const updatedLocalAMPM = ((s_localAMPM === "PM") ? ("AM") : ("PM"));

    this.setState({s_localAMPM:updatedLocalAMPM});

    if(JSFUNC.date_is_filled_out_tf(s_localDateYmd)) { //only call onChange if the date is filled out
      const updatedDateTimeUtc = this.convert_local_dateYmd_hours1to12_minutes_seconds_ampm_to_mysqldatetimeutc(s_localDateYmd, s_localHours1to12Int, s_localMinutes0to59Int, s_localSeconds0to59Int, updatedLocalAMPM);
      this.call_onchange_with_updated_datetimeutc(updatedDateTimeUtc);
    }
  }

  onclick_set_time = () => {
    const s_localDateYmd = this.state.s_localDateYmd;
    const s_localHours1to12Int = this.state.s_localHours1to12Int;
    const s_localMinutes0to59Int = this.state.s_localMinutes0to59Int;
    const s_localSeconds0to59Int = this.state.s_localSeconds0to59Int;
    const s_localAMPM = this.state.s_localAMPM;

    //when the previous time has 59 seconds, simply set the seconds to 0 to restore the previously saved time that was hidden by the 59 seconds
    var updatedLocalHours1to12Int = s_localHours1to12Int;
    var updatedLocalMinutes0to59 = s_localMinutes0to59Int;
    var updatedLocalSeconds0to59 = 0; //0 seconds (anything other than 58/59) is a CaptureExec DateTime flag that the Time is set and valid
    var updatedLocalAMPM = s_localAMPM;
    if(s_localSeconds0to59Int === 58) { //when set time is clicked and the previous time seconds are equal to 58, set the local time to 11am so that the date does not wrap into a different day because of the UTC conversion
      updatedLocalHours1to12Int = 11;
      updatedLocalMinutes0to59 = 0;
      updatedLocalSeconds0to59 = 0;
      updatedLocalAMPM = "AM";
    }

    this.setState({
      s_localHours1to12Int: updatedLocalHours1to12Int,
      s_localMinutes0to59Int: updatedLocalMinutes0to59,
      s_localSeconds0to59Int: updatedLocalSeconds0to59,
      s_localAMPM: updatedLocalAMPM
    });

    const updatedDateTimeUtc = this.convert_local_dateYmd_hours1to12_minutes_seconds_ampm_to_mysqldatetimeutc(s_localDateYmd, updatedLocalHours1to12Int, updatedLocalMinutes0to59, updatedLocalSeconds0to59, updatedLocalAMPM);
    this.call_onchange_with_updated_datetimeutc(updatedDateTimeUtc);
  }

  onclick_clear_time = () => {
    const s_localDateYmd = this.state.s_localDateYmd;
    const s_localHours1to12Int = this.state.s_localHours1to12Int;
    const s_localMinutes0to59Int = this.state.s_localMinutes0to59Int;
    const s_localSeconds0to59Int = this.state.s_localSeconds0to59Int;
    const s_localAMPM = this.state.s_localAMPM;

    const updatedLocalSeconds0to59 = 59; //59 seconds is a CaptureExec DateTime flag that the Time is not set

    this.setState({s_localSeconds0to59Int:updatedLocalSeconds0to59});

    const updatedDateTimeUtc = this.convert_local_dateYmd_hours1to12_minutes_seconds_ampm_to_mysqldatetimeutc(s_localDateYmd, s_localHours1to12Int, s_localMinutes0to59Int, updatedLocalSeconds0to59, s_localAMPM);
    this.call_onchange_with_updated_datetimeutc(updatedDateTimeUtc);
  }

  call_onchange_with_updated_datetimeutc = (i_updatedDateTimeUtc) => {
    if(this.props.f_onChange) {
      this.props.f_onChange(i_updatedDateTimeUtc);
    }
  }

  convert_input_datetimeUTC_to_local_date_hours_minutes_ampm_obj = (i_dateTimeUtc) => {
    //convert the dateTimeUTC into local time split into 4 parts all converted to local time: Y-m-d date, hours, minutes, ampm
    var localDateYmd = undefined;
    var localHours1to12Int = undefined;
    var localMinutes0to59Int = undefined;
    var localSeconds0to59Int = undefined;
    var localAMPM = undefined;
    if(JSFUNC.date_is_filled_out_tf(i_dateTimeUtc)) { //input is date "2021-02-18" format, append 00:00:58 as the time to mark this date as time not set
      localDateYmd = i_dateTimeUtc;
      localHours1to12Int = 0;
      localMinutes0to59Int = 0;
      localSeconds0to59Int = 58;
      localAMPM = "AM";
    }
    else if(JSFUNC.datetime_is_filled_out_tf(i_dateTimeUtc)) {
      const dateTimeUtcSecondsString0To59 = JSFUNC.direct_get_ss_00to59_string_from_YmdHis_datetime(i_dateTimeUtc);
      if(dateTimeUtcSecondsString0To59 === "58") { //for 58 seconds, ignore the time and UTC conversion and just ger the raw date
        localDateYmd = JSFUNC.direct_get_Ymd_string_from_YmdHis_datetime(i_dateTimeUtc);
        localHours1to12Int = 0;
        localMinutes0to59Int = 0;
        localSeconds0to59Int = 58;
        localAMPM = "AM";
      }
      else { //otherwise (for 59 seconds and all other filled out date/time values), convert the UTC datetime to local get each local date and time value
        const localJsDateObj = JSFUNC.convert_mysqldatetimeutc_to_jsdateobj(i_dateTimeUtc);
        localDateYmd = JSFUNC.get_Ymd_date_from_jsdateobj_and_utctf(localJsDateObj, false);
        localHours1to12Int = JSFUNC.date_hap(localJsDateObj); //hours int 1-12
        localMinutes0to59Int = JSFUNC.date_i(localJsDateObj); //minutes int 0-59
        localSeconds0to59Int = JSFUNC.date_s(localJsDateObj); //seconds int 0-59
        localAMPM = JSFUNC.date_ampm(localJsDateObj); //string "AM" or "PM"
      }
    }
    else { //default value if none is provided is the empty "0000-00-00 00:00:00"
      localDateYmd = JSFUNC.blank_date();
      localHours1to12Int = 0;
      localMinutes0to59Int = 0;
      localSeconds0to59Int = 0;
      localAMPM = "AM";
    }

    return({
      localDateYmd: localDateYmd,
      localHours1to12Int: localHours1to12Int,
      localMinutes0to59Int: localMinutes0to59Int,
      localSeconds0to59Int: localSeconds0to59Int,
      localAMPM: localAMPM
    });
  }

  convert_local_dateYmd_hours1to12_minutes_seconds_ampm_to_mysqldatetimeutc = (i_localDateYmd, i_localHours1to12Int, i_localMinutes0to59Int, i_localSeconds0to59Int, i_localAMPMString) => {
    //if the date part is not filled out, then the time part is considered blank as well
    if(!JSFUNC.date_is_filled_out_tf(i_localDateYmd)) {
      return(JSFUNC.blank_datetime());
    }

    //if time has 58 seconds, use the local date directly with no UTC conversion and put in 00:00:58 for the UTC time
    if(i_localSeconds0to59Int === 58) {
      return(i_localDateYmd + " 00:00:58");
    }

    //otherwise convert the local date/hours/minutes/seconds/AMPM to a datetime in UTC
    var localHours0to23Int = 0; //default midnight when an invalid value is chosen
    if((i_localHours1to12Int >= 1) && (i_localHours1to12Int <= 12)) {
      localHours0to23Int = JSFUNC.hours0to23_from_hours1to12_with_ampm(i_localHours1to12Int, i_localAMPMString);
    }

    var localMinutes0to59Int = 0;
    if((i_localMinutes0to59Int >= 0) && (i_localMinutes0to59Int <= 59)) {
      localMinutes0to59Int = i_localMinutes0to59Int;
    }

    var localSeconds0to59Int = 0;
    if((i_localSeconds0to59Int >= 0) && (i_localSeconds0to59Int <= 59)) {
      localSeconds0to59Int = i_localSeconds0to59Int;
    }

    return(JSFUNC.convert_local_dateYmd_hours0to23_minutes_seconds_to_mysqldatetimeutc(i_localDateYmd, localHours0to23Int, localMinutes0to59Int, localSeconds0to59Int));
  }

  render() {
    const s_localDateYmd = this.state.s_localDateYmd
    const s_localHours1to12Int = this.state.s_localHours1to12Int;
    const s_localMinutes0to59Int = this.state.s_localMinutes0to59Int;
    const s_localSeconds0to59Int = this.state.s_localSeconds0to59Int;
    const s_localAMPM = this.state.s_localAMPM;

    const p_valueDateTimeUTC = this.props.p_valueDateTimeUTC;
    const p_tabIndex = JSFUNC.prop_value(this.props.p_tabIndex, 1);
    const p_isSingleLineTF = JSFUNC.prop_value(this.props.p_isSingleLineTF, false);
    const p_title = this.props.p_title;
    const p_errorTF = this.props.p_errorTF;

    const localDateYmdIsFilledOutTF = JSFUNC.date_is_filled_out_tf(s_localDateYmd);
    const localTimeIsFilledOutTF = ((s_localSeconds0to59Int !== 58) && (s_localSeconds0to59Int !== 59));
    const localAMTruePMFalse = (s_localAMPM === "AM");

    const dateInputComponent = (
      <Date
        p_value={s_localDateYmd}
        p_tabIndex={p_tabIndex}
        f_onChange={this.onchange_date}
        f_onKeyDownEnter={this.props.f_onKeyDownEnter}
      />
    );

    var timeInputComponents = null;
    if(!localDateYmdIsFilledOutTF || !localTimeIsFilledOutTF) {
      timeInputComponents = (
        <>
          <div className="flex00a" style={{marginRight:"0.4em"}}>
            <font className="font09 fontItalic" style={{color:"#6b6b6b"}}>
              {((localDateYmdIsFilledOutTF) ? ("--Time Not Set--") : ("--Select Date to set Time--"))}
            </font>
          </div>
          {(localDateYmdIsFilledOutTF) &&
            <InteractiveDiv
              p_class="flex00a displayFlexColumnHcVc textCenter cursorPointer"
              p_styleObj={{height:"1.4em", width:"4em"}}
              p_tabIndex={p_tabIndex + 1}
              p_title="Click to clear only the Time portion of this Date/Time"
              f_onClick={this.onclick_set_time}
              f_onKeyDownEnter={this.onclick_set_time}>
              <font className="font09 fontBold" style={{color:"#68b"}}>
                {"Set Time"}
              </font>
            </InteractiveDiv>
          }
          <div className="flex11a" />
        </>
      );
    }
    else {
      const ampmSelectedBg = "#99d6f0";
      const ampmSelectedBorder = "#bdf #79b #79b #bdf";
      const ampmUnselectedBg = "#fafafa";
      const ampmUnselectedBorder = "#f6f6f6 #999999 #999999 #f6f6f6";
      timeInputComponents = (
        <>
          <div className="flex00a">
            <Integer
              p_value={s_localHours1to12Int}
              p_min={1}
              p_max={12}
              p_rolloverTF={true}
              p_numZeroPad={2}
              p_styleObj={{width:"3.3em"}}
              p_tabIndex={p_tabIndex + 1}
              f_onChange={this.onchange_hours}
            />
          </div>
          <div className="flex00a" style={{margin:"0 0.15em"}}>
            <font className="fontBold">
              {":"}
            </font>
          </div>
          <div className="flex00a">
            <Integer
              p_value={s_localMinutes0to59Int}
              p_min={0}
              p_max={59}
              p_rolloverTF={true}
              p_numZeroPad={2}
              p_styleObj={{width:"3.3em"}}
              p_tabIndex={p_tabIndex + 2}
              f_onChange={this.onchange_minutes}
            />
          </div>
          <InteractiveDiv
            p_class={"flex00a displayFlexColumnHcVc textCenter " + ((localAMTruePMFalse) ? ("") : ("cursorPointer"))}
            p_styleObj={{height:"1.6em", width:"1.1em", marginLeft:"0.4em", border:"solid 1px", borderColor:((localAMTruePMFalse) ? (ampmSelectedBorder) : (ampmUnselectedBorder)), background:((localAMTruePMFalse) ? (ampmSelectedBg) : (ampmUnselectedBg))}}
            p_tabIndex={((localAMTruePMFalse) ? (undefined) : (p_tabIndex + 3))}
            p_title={((localAMTruePMFalse) ? (undefined) : ("Change Time to AM"))}
            f_onClick={((localAMTruePMFalse) ? (undefined) : (this.onclick_ampm_toggle))}
            f_onKeyDownEnter={((localAMTruePMFalse) ? (undefined) : (this.onclick_ampm_toggle))}>
            <font className="font09">
              {"A"}
            </font>
          </InteractiveDiv>
          <InteractiveDiv
            p_class={"flex00a displayFlexColumnHcVc textCenter " + ((localAMTruePMFalse) ? ("cursorPointer") : (""))}
            p_styleObj={{height:"1.6em", width:"1.1em", marginLeft:"0.02em", border:"solid 1px", borderColor:((localAMTruePMFalse) ? (ampmUnselectedBorder) : (ampmSelectedBorder)), background:((localAMTruePMFalse) ? (ampmUnselectedBg) : (ampmSelectedBg))}}
            p_tabIndex={((localAMTruePMFalse) ? (p_tabIndex + 3) : (undefined))}
            p_title={((localAMTruePMFalse) ? ("Change Time to PM") : (undefined))}
            f_onClick={((localAMTruePMFalse) ? (this.onclick_ampm_toggle) : (undefined))}
            f_onKeyDownEnter={((localAMTruePMFalse) ? (this.onclick_ampm_toggle) : (undefined))}>
            <font className="font09">
              {"P"}
            </font>
          </InteractiveDiv>
          <InteractiveDiv
            p_class="flex00a displayFlexColumnHcVc textCenter cursorPointer"
            p_styleObj={{height:"1.2em", width:"1.2em", marginLeft:"0.4em"}}
            p_tabIndex={p_tabIndex + 4}
            p_title="Click to clear only the Time portion of this Date/Time"
            f_onClick={this.onclick_clear_time}
            f_onKeyDownEnter={this.onclick_clear_time}>
            <font className="font09 fontBold" style={{color:"#aaa"}}>
              {"\u2715"}
            </font>
          </InteractiveDiv>
          <div className="flex11a" />
        </>
      );
    }


    if(p_isSingleLineTF) {
      return(
        <InteractiveDiv
          p_class="displayFlexRowVc"
          p_title={p_title}
          p_errorTF={p_errorTF}>
          <div className="flex00a" style={{marginRight:"0.6em"}}>
            {dateInputComponent}
          </div>
          {timeInputComponents}
        </InteractiveDiv>
      );
    }

    return(
      <InteractiveDiv
        p_class="inlineBlock"
        p_title={p_title}
        p_errorTF={p_errorTF}>
        <div>
          {dateInputComponent}
        </div>
        <div className="displayFlexRowVc" style={{height:"1.75em", marginTop:"0.15em"}}>
          {timeInputComponents}
        </div>
      </InteractiveDiv>
    );
  }
}


export class Decimal extends Component { //props: p_value, p_min, p_max, p_blankValue, p_class, p_styleObj, p_tabIndex, p_title, p_errorTF, f_onChange, f_onKeyDownEnter
  //p_min and p_max only limit the arrow buttons, a larger number can still by typed in, decimals can always be put in
  constructor(props) {
    super(props);
    this.state = {
      s_displayString: JSFUNC.remove_leading_zeros_from_decimal_string(this.props.p_value),
      s_prevValue: this.props.p_value
    }
  }

  static getDerivedStateFromProps(i_props, i_state) { //update state from an updated prop (React "derived state")
    if(i_props.p_value !== i_state.s_prevValue) {
      return({
        s_displayString: JSFUNC.remove_leading_zeros_from_decimal_string(i_props.p_value),
        s_prevValue: i_props.p_value
      });
    }
    return(null);
  }

  onchange_number = (i_newValueString) => {
    if(this.props.f_onChange) {
      var onChangeValueString = undefined;
      if(i_newValueString === undefined || i_newValueString === "") {
        onChangeValueString = ((this.props.p_blankValue !== undefined) ? (this.props.p_blankValue) : (""));
      }
      else {
        onChangeValueString = i_newValueString;
        if(this.props.p_min !== undefined && this.props.p_max !== undefined) { //if a min/max is provided, force the typed value to be between those values in the onChange result
          if(i_newValueString < this.props.p_min) {
            onChangeValueString = this.props.p_min;
          }
          else if(i_newValueString > this.props.p_max) {
            onChangeValueString = this.props.p_max;
          }
        }
      }

      const onChangeValue = JSFUNC.str2int_or_decimal(onChangeValueString);
      this.props.f_onChange(onChangeValue);

      this.setState({
        s_displayString: JSFUNC.remove_leading_zeros_from_decimal_string(onChangeValueString),
        s_prevValue: onChangeValue
      });
    }
  }

  render() {
    return(
      <InteractiveDivOrInput
        p_tagInputTypeString="number"
        p_class={this.props.p_class}
        p_styleObj={this.props.p_styleObj}
        p_tabIndex={this.props.p_tabIndex}
        p_title={this.props.p_title}
        p_min={this.props.p_min}
        p_max={this.props.p_max}
        p_errorTF={this.props.p_errorTF}
        f_onChange={this.onchange_number}
        f_onKeyDownEnter={this.props.f_onKeyDownEnter}>
        {this.state.s_displayString}
      </InteractiveDivOrInput>
    );
  }
}


export class Integer extends Component { //props: p_value, p_min, p_max, p_rolloverTF, p_numZeroPad, p_class, p_styleObj, p_tabIndex, p_title, p_placeholder, p_errorTF, f_onChange, f_onKeyDownEnter
  constructor(props) {
    super(props);
    this.state = {
      s_localValueString: JSFUNC.num2str(this.props.p_value)
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.p_value !== prevProps.p_value) {
      this.setState({s_localValueString:JSFUNC.num2str(this.props.p_value)});
    }
  }

  onchange_local_value = (i_newValueString) => {
    const min = this.props.p_min;
    const max = this.props.p_max;
    var newValueInt = JSFUNC.str2int(i_newValueString);
    if(i_newValueString === "") {
      this.setState({s_localValueString:""});
      this.call_onchange_external("");
    }
    else if(min > 0 && newValueInt === 0) {
      this.setState({s_localValueString:"0"});
      this.call_onchange_external(0);
    }
    else {
      //verify that the new value is between the specified min/max
      var newLocalValueString = i_newValueString; //keep the local string to exactly what was typed in onchange, meaning typing a "0" in front of "67" results in "067" being displayed, but 67 is sent externally as a number
      if(min !== undefined && max !== undefined) {
        if(newValueInt < min) {
          const rolloverTF = JSFUNC.prop_value(this.props.p_rolloverTF, false);
          const prevValueInt = JSFUNC.str2int(this.state.s_localValueString);
          if(rolloverTF && newValueInt === (min - 1) && prevValueInt === min) {
            newValueInt = max;
          }
          else if(min > 0 && prevValueInt === 0) {
            newValueInt = 0;
          }
          else {
            newValueInt = min;
          }
          newLocalValueString = JSFUNC.num2str(newValueInt);
        }
        else if(newValueInt > max) {
          const rolloverTF = JSFUNC.prop_value(this.props.p_rolloverTF, false);
          const prevValueInt = JSFUNC.str2int(this.state.s_localValueString);
          if(rolloverTF && newValueInt === (max + 1) && prevValueInt === max) {
            newValueInt = min;
          }
          else {
            newValueInt = max;
          }
          newLocalValueString = JSFUNC.num2str(newValueInt);
        }
      }

      this.setState({s_localValueString:newLocalValueString});

      this.call_onchange_external(newValueInt);
    }
  }

  onkeydown_int_input = (event) => {
    if(event.keyCode === 190) { //keyCode for "."
      event.preventDefault(); //prevent typing a "." character into the box for any reason
      event.stopPropagation();
    }
    else if(event.keyCode === 189) { //keyCode for "-"
      if(this.props.p_min !== undefined && this.props.p_min >= 0) { //if a minimum >= 0 (only positive numbers allowed) is requested, do not allow "-" to be typed anywhere
        event.preventDefault();
        event.stopPropagation();
      }
      else {
        const localValueString = this.state.s_localValueString;
        if(localValueString === "" || localValueString === "0") { //if the local value is currently empty, allow the "-" to be typed (manually do this and prevent default actions)
          this.setState({s_localValueString:"-"}); //do not externally record the value if only a "-" has been typed so far
        }
        else { //otherwise, typing "-" anywhere in the string (even if there already is one) negates the integer value
          event.preventDefault();
          event.stopPropagation();
          const localValueInt = JSFUNC.str2int(localValueString);
          const localValueIntNegated = (localValueInt * -1);
          const localValueStringNegated = JSFUNC.num2str(localValueIntNegated);
          this.setState({s_localValueString:localValueStringNegated});
          this.call_onchange_external(localValueIntNegated);
        }
      }
    }
  }

  call_onchange_external = (i_newValueInt) => {
    if(this.props.f_onChange) {
      this.props.f_onChange(i_newValueInt);
    }
  }

  render() {
    const numZeroPad = this.props.p_numZeroPad;
    var localValueString = this.state.s_localValueString;
    if(numZeroPad !== undefined && numZeroPad > 0) {
      localValueString = JSFUNC.num2str(JSFUNC.str2int(localValueString)); //cast the value as an int, then back to a string to remove any previous leading zeros to get the accurate number length
      if(JSFUNC.is_string(localValueString)) {
        const localValueStringLength = localValueString.length;
        if(localValueStringLength < numZeroPad) {
          var addedZerosString = "";
          for(let z = 0; z < (numZeroPad - localValueStringLength); z++) {
            addedZerosString += "0";
          }
          localValueString = addedZerosString + localValueString;
        }
      }
    }

    return(
      <InteractiveDivOrInput
        p_tagInputTypeString="number"
        p_class={this.props.p_class}
        p_styleObj={this.props.p_styleObj}
        p_tabIndex={this.props.p_tabIndex}
        p_title={this.props.p_title}
        p_min={undefined}
        p_max={undefined}
        p_errorTF={this.props.p_errorTF}
        f_onChange={this.onchange_local_value}
        f_onKeyDownEnter={this.props.f_onKeyDownEnter}
        f_onKeyDown={this.onkeydown_int_input}>
        {localValueString}
      </InteractiveDivOrInput>
    );
  }
}


export class Money extends Component { //props: p_value, p_centsTF, p_class, p_styleObj, p_tabIndex, p_title, p_placeholder, p_errorTF, f_onChange, f_onKeyDownEnter
  constructor(props) {
    super(props);
    this.state = {
      s_displayString: JSFUNC.convert_money_value_to_display(this.props.p_value, this.props.p_centsTF),
      s_prevValue: this.props.p_value
    }
  }

  static getDerivedStateFromProps(i_props, i_state) { //update state from an updated prop (React "derived state")
    if(i_props.p_value !== i_state.s_prevValue) {
      return({
        s_displayString: JSFUNC.convert_money_value_to_display(i_props.p_value, i_props.p_centsTF),
        s_prevValue: i_props.p_value
      });
    }
    return(null);
  }

  onchange_value = (i_newDisplayString) => {
    if(this.props.f_onChange) {
      var newDisplayString = undefined;
      var newOnChangeValueString = undefined;
      if(i_newDisplayString === "") {
        newDisplayString = "";
        newOnChangeValueString = "";
      }
      else if(i_newDisplayString === "-") {
        newDisplayString = "-";
        newOnChangeValueString = 0;
      }
      else if(i_newDisplayString === "0") {
        newDisplayString = "0";
        newOnChangeValueString = 0;
      }
      else {
        //remove every character except numbers and "."
        var newValueString = i_newDisplayString.replace(/[^0-9.]/g, "");

        const min = JSFUNC.sort_max_mysqli_bigint() * -1;
        const max = JSFUNC.sort_max_mysqli_bigint();
        if(JSFUNC.is_number(min) && JSFUNC.is_number(max)) {
          if(newValueString > max) {
            newValueString = max; //hold at max
          }
          else if(newValueString < min) {
            newValueString = min; //hold at min
          }
        }

        const frontNegativeSign = ((JSFUNC.string_is_negative_from_dashes(i_newDisplayString)) ? ("-") : (""));
        newDisplayString = frontNegativeSign + JSFUNC.convert_money_value_to_display(newValueString, this.props.p_centsTF);
        newOnChangeValueString = frontNegativeSign + newValueString;
        newOnChangeValueString = JSFUNC.str2int_or_decimal(newOnChangeValueString);
      }

      this.setState({
        s_displayString: newDisplayString
      });
      this.props.f_onChange(newOnChangeValueString);
    }
  }

  render() {
    var containerWidth = "5em"; //take the width from the provided styleObj, if no width was specified, default to 5em
    var styleObj = {};
    if(!JSFUNC.is_obj(this.props.p_styleObj)) {
      styleObj = {width:"100%"};
    }
    else {
      styleObj = JSFUNC.copy_obj(this.props.p_styleObj);
      if(styleObj.width !== undefined) {
        containerWidth = styleObj.width;
      }
      styleObj.width = "100%"; //inner Number component is always 100% to fit inside of the flex container around it
    }

    const moneyTextComponent = (
      <InteractiveDivOrInput
        p_tagInputTypeString="text"
        p_class={this.props.p_class}
        p_styleObj={this.props.p_styleObj}
        p_tabIndex={this.props.p_tabIndex}
        p_title={this.props.p_title}
        p_errorTF={this.props.p_errorTF}
        f_onChange={this.onchange_value}
        f_onKeyDownEnter={this.props.f_onKeyDownEnter}>
        {this.state.s_displayString}
      </InteractiveDivOrInput>
    );

    const currencyInFlex00Component = (
      <div className={"flex00a " + this.props.p_class} style={{flexBasis:"1.1em"}}>
        {JSFUNC.currency_symbol()}
      </div>
    );

    if(containerWidth === "100%") {
      return(
        <div className="displayFlexRowVc">
          {currencyInFlex00Component}
          <div className="flex11a">
            {moneyTextComponent}
          </div>
        </div>
      );
    }

    return(
      <div className="inlineBlock">
        <div className="displayFlexRowVc">
          {currencyInFlex00Component}
          <div className="flex00a" style={{flexBasis:containerWidth}}>
            {moneyTextComponent}
          </div>
        </div>
      </div>
    );
  }
}

export class Percent extends Component { //props: p_value, p_min, p_max, p_decimalsTF, p_blankValue, p_class, p_styleObj, p_tabIndex, p_title, p_errorTF, f_onChange, f_onKeyDownEnter
  render() {
    var containerWidth = "5em"; //take the width from the provided styleObj, if no width was specified, default to 5em
    var styleObj = {};
    if(!JSFUNC.is_obj(this.props.p_styleObj)) {
      styleObj = {width:"100%"};
    }
    else {
      styleObj = JSFUNC.copy_obj(this.props.p_styleObj);
      if(styleObj.width !== undefined) {
        containerWidth = styleObj.width;
      }
      styleObj.width = "100%"; //inner Number component is always 100% to fit inside of the flex container around it
    }

    const decimalsTF = JSFUNC.prop_value(this.props.p_decimalsTF, false);
    const numberComponent = ((decimalsTF) ? (
      <Decimal
        p_value={this.props.p_value}
        p_min={this.props.p_min}
        p_max={this.props.p_max}
        p_blankValue={this.props.p_blankValue}
        p_class={this.props.p_class}
        p_styleObj={styleObj}
        p_tabIndex={this.props.p_tabIndex}
        p_title={this.props.p_title}
        p_errorTF={this.props.p_errorTF}
        f_onChange={this.props.f_onChange}
        f_onKeyDownEnter={this.props.f_onKeyDownEnter}
      />
    ) : (
      <Integer
        p_value={this.props.p_value}
        p_min={this.props.p_min}
        p_max={this.props.p_max}
        p_numZeroPad={undefined}
        p_class={this.props.p_class}
        p_styleObj={styleObj}
        p_tabIndex={this.props.p_tabIndex}
        p_title={this.props.p_title}
        p_placeholder={undefined}
        p_errorTF={this.props.p_errorTF}
        f_onChange={this.props.f_onChange}
        f_onKeyDownEnter={this.props.f_onKeyDownEnter}
      />
    ));

    const percentInFlex00Component = (
      <div className={"flex00a textRight " + this.props.p_class} style={{flexBasis:"1.3em"}}>
        {"%"}
      </div>
    );

    if(containerWidth === "100%") {
      return(
        <div className="displayFlexRowVc">
          <div className="flex11a">
            {numberComponent}
          </div>
          {percentInFlex00Component}
        </div>
      );
    }

    return(
      <div className="inlineBlock">
        <div className="displayFlexRowVc">
          <div className="flex00a" style={{flexBasis:containerWidth}}>
            {numberComponent}
          </div>
          {percentInFlex00Component}
        </div>
      </div>
    );
  }
}


export function Range(props) { //props: p_value, p_min, p_max, p_class, p_styleObj, p_tabIndex, p_title, p_errorTF, f_onChange, f_onKeyDownEnter
  const min = JSFUNC.prop_value(props.p_min, 0);
  const max = JSFUNC.prop_value(props.p_max, 100);
  return(
    <InteractiveDivOrInput
      p_tagInputTypeString="range"
      p_class={props.p_class}
      p_styleObj={props.p_styleObj}
      p_tabIndex={props.p_tabIndex}
      p_title={props.p_title}
      p_min={min}
      p_max={max}
      p_errorTF={props.p_errorTF}
      f_onChange={props.f_onChange}
      f_onKeyDownEnter={props.f_onKeyDownEnter}>
      {props.p_value}
    </InteractiveDivOrInput>
  );
}

export function RangeWithPercentInt0to100(props) { //props: p_value, p_tabIndex, p_errorTF, f_onChange, f_onKeyDownEnter
  const min = 0;
  const max = 100;
  return(
    <div className="displayFlexRowVc">
      <div className="flex00a" style={{flexBasis:"8em"}}>
        <Percent
          p_value={props.p_value}
          p_min={min}
          p_max={max}
          p_decimalsTF={false}
          p_blankValue={0}
          p_class=""
          p_styleObj={{width:"4em"}}
          p_tabIndex={props.p_tabIndex}
          p_title={undefined}
          p_errorTF={props.p_errorTF}
          f_onChange={props.f_onChange}
          f_onKeyDownEnter={props.f_onKeyDownEnter}
        />
      </div>
      <div className="flex11a">
        <Range
          p_value={props.p_value}
          p_min={min}
          p_max={max}
          p_class=""
          p_styleObj={{width:"100%"}}
          p_tabIndex={undefined}
          p_title={undefined}
          p_errorTF={props.p_errorTF}
          f_onChange={props.f_onChange}
          f_onClick={undefined}
        />
      </div>
    </div>
  );
}


export function Text(props) { //props: p_value, p_isPasswordTF, p_class, p_styleObj, p_tabIndex, p_title, p_placeholder, p_id, p_autocompleteName, p_errorTF, p_focusTF, f_onChange, f_onClick, f_onKeyDownEnter, f_onKeyDownEsc
  return(
    <InteractiveDivOrInput
      p_tagInputTypeString={((props.p_isPasswordTF) ? ("password") : ("text"))}
      p_class={props.p_class}
      p_styleObj={props.p_styleObj}
      p_tabIndex={props.p_tabIndex}
      p_title={props.p_title}
      p_placeholder={props.p_placeholder}
      p_id={props.p_id}
      p_autocompleteName={props.p_autocompleteName}
      p_errorTF={props.p_errorTF}
      p_focusTF={props.p_focusTF}
      f_onChange={props.f_onChange}
      f_onClick={props.f_onClick}
      f_onKeyDownEnter={props.f_onKeyDownEnter}
      f_onKeyDownEsc={props.f_onKeyDownEsc}>
      {props.p_value}
    </InteractiveDivOrInput>
  );
}


export function Textarea(props) { //props: p_value, p_class, p_styleObj, p_tabIndex, p_title, p_placeholder, p_autocompleteName, p_errorTF, p_focusTF, f_onChange, f_onFocus
  return(
    <InteractiveDivOrInput
      p_tagTextareaTF={true}
      p_class={props.p_class}
      p_styleObj={props.p_styleObj}
      p_tabIndex={props.p_tabIndex}
      p_title={props.p_title}
      p_placeholder={props.p_placeholder}
      p_autocompleteName={props.p_autocompleteName}
      p_errorTF={props.p_errorTF}
      p_focusTF={props.p_focusTF}
      f_onChange={props.f_onChange}
      f_onFocus={props.f_onFocus}>
      {props.p_value}
    </InteractiveDivOrInput>
  );
}


export class Website extends Component { //props: p_value, p_class, p_styleObj, p_tabIndex, p_title, p_errorTF, f_onChange, f_onKeyDownEnter
  //f_onChange will have 1 input present that is the "[[mask]]website" string put together from the 2 inputs
  //f_onKeyDownEnter has 0 inputs and simply fires when enter is pushed if the focus is on either text input
  constructor(props) {
    super(props);
    const [mask, website] = JSFUNC.website_determine_mask_website(this.props.p_value);
    this.state = {
      s_website: website,
      s_mask: mask
    }
  }

  onchange_website_url = (i_newValue) => {
    this.setState({s_website: i_newValue});
    this.call_onchange_with_combined_brackets_string(this.state.s_mask, i_newValue);
  }

  onchange_mask_text = (i_newValue) => {
    this.setState({s_mask: i_newValue});
    this.call_onchange_with_combined_brackets_string(i_newValue, this.state.s_website);
  }

  call_onchange_with_combined_brackets_string(i_mask, i_website) {
    if(this.props.f_onChange) {
      var maskWebsiteWithBrackets = "";

      if(JSFUNC.text_or_number_is_filled_out_tf(i_mask)) {
        maskWebsiteWithBrackets += "[[" + i_mask + "]]";
      }

      if(JSFUNC.text_or_number_is_filled_out_tf(i_website)) {
        maskWebsiteWithBrackets += i_website;
      }

      this.props.f_onChange(maskWebsiteWithBrackets);
    }
  }

  render() {
    return(
      <div title={this.props.p_title}>
        <div className="smallTopMargin microBottomMargin fontTextLight fontItalic">{"Full web address (starting with http:// or https://)"}</div>
        <div>
          <Text
            p_value={this.state.s_website}
            p_class={this.props.p_class}
            p_styleObj={this.props.p_styleObj}
            p_tabIndex={this.props.p_tabIndex}
            p_placeholder="Website URL (http://www.example.com)"
            p_errorTF={this.props.p_errorTF}
            f_onChange={this.onchange_website_url}
            f_onKeyDownEnter={this.props.f_onKeyDownEnter}
          />
        </div>
        <div className="smallTopMargin microBottomMargin fontTextLight fontItalic">{"Display in CaptureExec (optional)"}</div>
        <div className="smallBottomMargin">
          <Text
            p_value={this.state.s_mask}
            p_class={this.props.p_class}
            p_styleObj={this.props.p_styleObj}
            p_tabIndex={this.props.p_tabIndex + 1}
            p_placeholder="Display Masking"
            p_errorTF={this.props.p_errorTF}
            f_onChange={this.onchange_mask_text}
            f_onKeyDownEnter={this.props.f_onKeyDownEnter}
          />
        </div>
      </div>
    );
  }
}




//========================================================================================================================================
//Generic Interactive Divs for Inputs
//========================================================================================================================================
export function InteractiveDiv(props) { //props: p_class, p_styleObj, p_drawFocusBorderTF, p_tabIndex, p_title, p_errorTF, p_focusTF, f_onChange, f_onClick, f_onKeyDownEnter, f_onKeyDownEsc, f_onKeyDownSpace, f_onKeyDownTabKeepDefaultAndPropogation, f_onKeyDownUpArrow, f_onKeyDownDownArrow, f_onKeyDownLeftArrow, f_onKeyDownRightArrow, f_onKeyDown, f_onHover, children
  return(
    <InteractiveDivOrInput
      p_tagDivTF={true}
      p_class={props.p_class}
      p_styleObj={props.p_styleObj}
      p_drawFocusBorderTF={props.p_drawFocusBorderTF}
      p_tabIndex={props.p_tabIndex}
      p_title={props.p_title}
      p_errorTF={props.p_errorTF}
      p_focusTF={props.p_focusTF}
      f_onChange={props.f_onChange}
      f_onClick={props.f_onClick}
      f_onKeyDownEnter={props.f_onKeyDownEnter}
      f_onKeyDownEsc={props.f_onKeyDownEsc}
      f_onKeyDownSpace={props.f_onKeyDownSpace}
      f_onKeyDownTabKeepDefaultAndPropogation={props.f_onKeyDownTabKeepDefaultAndPropogation}
      f_onKeyDownUpArrow={props.f_onKeyDownUpArrow}
      f_onKeyDownDownArrow={props.f_onKeyDownDownArrow}
      f_onKeyDownLeftArrow={props.f_onKeyDownLeftArrow}
      f_onKeyDownRightArrow={props.f_onKeyDownRightArrow}
      f_onKeyDown={props.f_onKeyDown}
      f_onHover={props.f_onHover}>
      {props.children}
    </InteractiveDivOrInput>
  );
}


class InteractiveDivOrInput extends Component {
  //props:
  //  - p_tagDivTF, p_tagTextareaTF, p_tagInputTypeString, p_class, p_styleObj, p_drawFocusBorderTF, p_tabIndex, p_title, p_placeholder, p_id, p_autocompleteName, p_min, p_max, p_errorTF, p_focusTF
  //  - f_onChange, f_onClick, f_onKeyDownEnter, f_onKeyDownEsc, f_onKeyDownSpace, f_onKeyDownTabKeepDefaultAndPropogation, f_onKeyDownUpArrow, f_onKeyDownDownArrow, f_onKeyDown, f_onFocus [textarea], f_onHover
  //  - children
  //
  //p_tagInputTypeString: "date", "number", "password", "range", "submit", "text", "color"  (<input type="">)
  //
  //children: used as the <input> value for inputs, passed as children into the <div> type
  constructor(props) {
    super(props);
    this.elementRef = React.createRef();
  }

  componentDidMount() {
    if((this.props.p_tabIndex === 1) || this.props.p_focusTF) { //if 1 (as a number, not a string) is provided for the tabIndex (or it is forced to focus using p_focusTF), put focus on this element
      this.elementRef.current.focus();
    }

    if(this.props.p_tagTextareaTF) { //start textarea cursor at the beginning always
      this.elementRef.current.setSelectionRange(0,0);
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.p_focusTF) {
      this.elementRef.current.focus();
    }
  }

  onchange_input = (event) => {
    if(JSFUNC.is_function(this.props.f_onChange)) {
      const dbMaxCharLength = 4000000; //4 million is the max packet size to be transfered through PHP to the database (which fits in a MYSQL TEXT type)
      var newValue = event.target.value;
      const newValueLength = newValue.length;
      if(newValueLength > dbMaxCharLength) {
        newValue = newValue.substring(0, dbMaxCharLength);
        alert("Input (" + newValueLength + " characters) exceeds maximum length of 4 million characters. Text has been truncated to 4 million characters.");
      }
      this.props.f_onChange(newValue);
    }
  }

  onclick_input = (event) => {
    if(JSFUNC.is_function(this.props.f_onClick)) {
      event.preventDefault();
      event.stopPropagation();
      this.props.f_onClick();
    }
  }

  onkeydown_input = (event) => {
    if((event.keyCode === 13) && JSFUNC.is_function(this.props.f_onKeyDownEnter)) { //enter key
      event.preventDefault();
      event.stopPropagation();
      this.props.f_onKeyDownEnter();
    }
    else if((event.keyCode === 27) && JSFUNC.is_function(this.props.f_onKeyDownEsc)) { //esc key
      event.preventDefault();
      event.stopPropagation();
      this.props.f_onKeyDownEsc();
    }
    else if((event.keyCode === 32) && JSFUNC.is_function(this.props.f_onKeyDownSpace)) { //space key
      event.preventDefault();
      event.stopPropagation();
      this.props.f_onKeyDownSpace();
    }
    else if((event.keyCode === 9) && JSFUNC.is_function(this.props.f_onKeyDownTabKeepDefaultAndPropogation)) { //tab key
      this.props.f_onKeyDownTabKeepDefaultAndPropogation();
    }
    else if((event.keyCode === 38) && JSFUNC.is_function(this.props.f_onKeyDownUpArrow)) { //up arrow key
      event.preventDefault();
      event.stopPropagation();
      this.props.f_onKeyDownUpArrow();
    }
    else if((event.keyCode === 40) && JSFUNC.is_function(this.props.f_onKeyDownDownArrow)) { //down arrow key
      event.preventDefault();
      event.stopPropagation();
      this.props.f_onKeyDownDownArrow();
    }
    else if((event.keyCode === 37) && JSFUNC.is_function(this.props.f_onKeyDownLeftArrow)) { //left arrow key
      event.preventDefault();
      event.stopPropagation();
      this.props.f_onKeyDownLeftArrow();
    }
    else if((event.keyCode === 39) && JSFUNC.is_function(this.props.f_onKeyDownRightArrow)) { //right arrow key
      event.preventDefault();
      event.stopPropagation();
      this.props.f_onKeyDownRightArrow();
    }
    else if(JSFUNC.is_function(this.props.f_onKeyDown)) {
      this.props.f_onKeyDown(event);
    }
  }

  onfocus_input = (event) => {
    if(JSFUNC.is_function(this.props.f_onFocus)) {
      this.props.f_onFocus();
    }
  }

  onmouseenter_element = () => {
    if(JSFUNC.is_function(this.props.f_onHover)) {
      this.props.f_onHover(true);
    }
  }

  onmouseleave_element = () => {
    if(JSFUNC.is_function(this.props.f_onHover)) {
      this.props.f_onHover(false);
    }
  }

  render() {
    const p_tagDivTF = this.props.p_tagDivTF;
    const p_tagTextareaTF = this.props.p_tagTextareaTF;
    const p_tagInputTypeString = this.props.p_tagInputTypeString;
    const p_class = JSFUNC.prop_value(this.props.p_class, "");
    const p_styleObj = this.props.p_styleObj;
    const p_drawFocusBorderTF = JSFUNC.prop_value(this.props.p_drawFocusBorderTF, true);
    const p_tabIndex = JSFUNC.prop_value(this.props.p_tabIndex, "-1");
    const p_title = this.props.p_title;
    const p_placeholder = this.props.p_placeholder;
    const p_id = this.props.p_id;
    const p_autocompleteName = this.props.p_autocompleteName;
    const p_min = this.props.p_min;
    const p_max = this.props.p_max;
    const p_errorTF = JSFUNC.prop_value(this.props.p_errorTF, false);
    const p_focusTF = this.props.p_focusTF;

    const usingHoverTF = JSFUNC.is_function(this.props.f_onHover);

    var inputClass = p_class;
    if(p_drawFocusBorderTF && (this.props.f_onChange || this.props.f_onClick || this.props.f_onKeyDownEnter || this.props.f_onKeyDownEsc || this.props.f_onKeyDownSpace || this.props.f_onKeyDownTabKeepDefaultAndPropogation || this.props.f_onKeyDownUpArrow || this.props.f_onKeyDownDownArrow)) {
      inputClass += " inputTealBoxShadowFocus"; //focus highlight border
    }

    if(p_errorTF) {
      inputClass += " inputRedBorderError"; //red error border
    }

    const tabIndexString = JSFUNC.num2str(p_tabIndex); //tabIndex property of inputs are strings of the index number

    if(p_tagDivTF) {
      return(
        <div
          ref={this.elementRef}
          className={inputClass}
          style={p_styleObj}
          tabIndex={tabIndexString}
          title={p_title}
          onClick={this.onclick_input}
          onKeyDown={this.onkeydown_input}
          onMouseEnter={((usingHoverTF) ? (this.onmouseenter_element) : (undefined))}
          onMouseLeave={((usingHoverTF) ? (this.onmouseleave_element) : (undefined))}>
          {this.props.children}
        </div>
      );
    }

    if(p_tagTextareaTF) {
      return(
        <textarea
          ref={this.elementRef}
          className={inputClass}
          style={p_styleObj}
          value={this.props.children}
          tabIndex={tabIndexString}
          name={p_autocompleteName}
          autoComplete="off"
          placeholder={p_placeholder}
          onChange={this.onchange_input}
          onFocus={this.onfocus_input}
        />
      );
    }

    var autocompleteOnOffString = "on"; //name property of input tags triggers browser autoComplete keywords
    if(p_autocompleteName === undefined) {
      autocompleteOnOffString = ((p_tagInputTypeString === "password") ? ("new-password") : ("off"));
    }

    return(
      <input
        ref={this.elementRef}
        className={inputClass}
        style={p_styleObj}
        type={p_tagInputTypeString}
        value={this.props.children}
        tabIndex={tabIndexString}
        title={p_title}
        placeholder={p_placeholder}
        id={p_id}
        name={p_autocompleteName}
        autoComplete={autocompleteOnOffString}
        min={p_min} //used for Range
        max={p_max} //used for Range
        step="any"
        onChange={this.onchange_input}
        onClick={this.onclick_input}
        onKeyDown={this.onkeydown_input}
      />
    );
  }
}










//================================================================================================================================================
//Select With Search
//================================================================================================================================================
export class SelectWithSearch extends Component {
  //p_valueArray, p_displayArray, p_treeIDArray, p_colorArray, p_bgColorArray, p_hiddenUnlessCheckedTFArray, p_unableToHighlightOrClickTFArray, p_optionDisplayOverwriteStringsArray, p_multiIDsCheckedWhenCheckedArrayOfArrays, 
  //p_selectedValue, p_selectedDisplay, p_valuesToNotIncludeArray, p_valuesAreStringsTF, p_isMultiSelectTF, p_hasClearSelectionTF, p_searchTF, p_initOptionsBoxOpenTF, p_optionsBoxForMobileTF, 
  //p_width, p_highlightColor, p_title, p_tabIndex, p_errorTF,
  //f_onOptionSelect, f_onSaveNewEntryName, f_onKeyDownEnterWhenSelectIsClosed
  render() {
    const p_valueArray = this.props.p_valueArray;
    const p_displayArray = this.props.p_displayArray;
    const p_treeIDArray = this.props.p_treeIDArray;
    const p_colorArray = this.props.p_colorArray;
    const p_bgColorArray = this.props.p_bgColorArray;
    const p_hiddenUnlessCheckedTFArray = this.props.p_hiddenUnlessCheckedTFArray;
    const p_unableToHighlightOrClickTFArray = this.props.p_unableToHighlightOrClickTFArray;
    const p_optionDisplayOverwriteStringsArray = this.props.p_optionDisplayOverwriteStringsArray;
    const p_multiIDsCheckedWhenCheckedArrayOfArrays = this.props.p_multiIDsCheckedWhenCheckedArrayOfArrays;
    const p_selectedValue = this.props.p_selectedValue;
    const p_selectedDisplay = JSFUNC.prop_value(this.props.p_selectedDisplay, "--No Display Provided--");
    const p_valuesToNotIncludeArray = this.props.p_valuesToNotIncludeArray;
    const p_valuesAreStringsTF = JSFUNC.prop_value(this.props.p_valuesAreStringsTF, false);
    const p_isMultiSelectTF = JSFUNC.prop_value(this.props.p_isMultiSelectTF, false);
    const p_hasClearSelectionTF = JSFUNC.prop_value(this.props.p_hasClearSelectionTF, true);
    const p_searchTF = JSFUNC.prop_value(this.props.p_searchTF, true);
    const p_initOptionsBoxOpenTF = JSFUNC.prop_value(this.props.p_initOptionsBoxOpenTF, false);
    const p_selectedDisplayFocusOnKeyDownEnterOpensSelectTF = JSFUNC.prop_value(this.props.p_selectedDisplayFocusOnKeyDownEnterOpensSelectTF, true);
    const p_optionsBoxForMobileTF = JSFUNC.prop_value(this.props.p_optionsBoxForMobileTF, false);
    const p_width = JSFUNC.prop_value(this.props.p_width, "10em");
    const p_highlightColor = JSFUNC.prop_value(this.props.p_highlightColor, "bd2326");
    const p_title = this.props.p_title;
    const p_tabIndex = this.props.p_tabIndex;
    const p_errorTF = this.props.p_errorTF;

    //input error checking
    const valueArrayIsArrayTF = JSFUNC.is_array(p_valueArray);
    const displayArrayIsArrayTF = JSFUNC.is_array(p_displayArray);
    const treeIDArrayIsArrayTF = JSFUNC.is_array(p_treeIDArray);
    const colorArrayIsArrayTF = JSFUNC.is_array(p_colorArray);
    const bgColorArrayIsArrayTF = JSFUNC.is_array(p_bgColorArray);
    const hiddenUnlessCheckedTFArrayIsArrayTF = JSFUNC.is_array(p_hiddenUnlessCheckedTFArray);
    const unableToHighlightOrClickTFArrayIsArrayTF = JSFUNC.is_array(p_unableToHighlightOrClickTFArray);
    const optionDisplayOverwriteStringsArrayIsArrayTF = JSFUNC.is_array(p_optionDisplayOverwriteStringsArray);
    const multiIDsCheckedWhenCheckedArrayOfArraysIsArrayTF = JSFUNC.is_array(p_multiIDsCheckedWhenCheckedArrayOfArrays);

    if(!valueArrayIsArrayTF) { return(<SelectWithSearchError p_message="--p_valueArray is not an array--" />); }
    if(!displayArrayIsArrayTF) { return(<SelectWithSearchError p_message="--p_displayArray is not an array--" />); }

    const numInitialValues = p_valueArray.length;
    
    if(p_displayArray.length !== numInitialValues) { return(<SelectWithSearchError p_message={"--p_displayArray (" + p_displayArray.length + ") not the same length as p_valueArray (" + numInitialValues + ")--"} />); }
    if(treeIDArrayIsArrayTF && (p_treeIDArray.length !== numInitialValues)) { return(<SelectWithSearchError p_message={"--p_treeIDArray (" + p_treeIDArray.length + ") not the same length as p_valueArray (" + numInitialValues + ")--"} />); }
    if(colorArrayIsArrayTF && (p_colorArray.length !== numInitialValues)) { return(<SelectWithSearchError p_message={"--p_colorArray (" + p_colorArray.length + ") not the same length as p_valueArray (" + numInitialValues + ")--"} />); }
    if(bgColorArrayIsArrayTF && (p_bgColorArray.length !== numInitialValues)) { return(<SelectWithSearchError p_message={"--p_bgColorArray (" + p_bgColorArray.length + ") not the same length as p_valueArray (" + numInitialValues + ")--"} />); }
    if(hiddenUnlessCheckedTFArrayIsArrayTF && (p_hiddenUnlessCheckedTFArray.length !== numInitialValues)) { return(<SelectWithSearchError p_message={"--p_hiddenUnlessCheckedTFArray (" + p_hiddenUnlessCheckedTFArray.length + ") not the same length as p_valueArray (" + numInitialValues + ")--"} />); }
    if(unableToHighlightOrClickTFArrayIsArrayTF && (p_unableToHighlightOrClickTFArray.length !== numInitialValues)) { return(<SelectWithSearchError p_message={"--p_unableToHighlightOrClickTFArray (" + p_unableToHighlightOrClickTFArray.length + ") not the same length as p_valueArray (" + numInitialValues + ")--"} />); }
    if(optionDisplayOverwriteStringsArrayIsArrayTF && (p_optionDisplayOverwriteStringsArray.length !== numInitialValues)) { return(<SelectWithSearchError p_message={"--p_optionDisplayOverwriteStringsArray (" + p_optionDisplayOverwriteStringsArray.length + ") not the same length as p_valueArray (" + numInitialValues + ")--"} />); }
    if(multiIDsCheckedWhenCheckedArrayOfArraysIsArrayTF && (p_multiIDsCheckedWhenCheckedArrayOfArrays.length !== numInitialValues)) { return(<SelectWithSearchError p_message={"--p_multiIDsCheckedWhenCheckedArrayOfArrays (" + p_multiIDsCheckedWhenCheckedArrayOfArrays.length + ") not the same length as p_valueArray (" + numInitialValues + ")--"} />); }

    //add hiddenUnlessCheckedTF values to p_valuesToNotIncludeArray
    var combinedValuesToNotIncludeArray = [];
    if(hiddenUnlessCheckedTFArrayIsArrayTF) {
      //get array of single selected value or multi selected values
      var selectedValuesArray = [];
      if(p_isMultiSelectTF) {
        if(p_valuesAreStringsTF) {
          selectedValuesArray = JSFUNC.convert_comma_list_to_array(p_selectedValue);
        }
        else {
          selectedValuesArray = JSFUNC.convert_comma_list_to_int_array(p_selectedValue);
        }
      }
      else {
        selectedValuesArray = [p_selectedValue]; //single int or string selected value
      }

      //add all options marked as hiddenUnlessCheckedTF true unless they are a selected value
      for(let i = 0; i < numInitialValues; i++) {
        var value = p_valueArray[i];
        var hiddenUnlessCheckedTF = p_hiddenUnlessCheckedTFArray[i];
        if(hiddenUnlessCheckedTF && !JSFUNC.in_array(p_valueArray[i], selectedValuesArray)) {
          combinedValuesToNotIncludeArray.push(value);
        }
      }
    }

    if(JSFUNC.is_array(p_valuesToNotIncludeArray)) {
      combinedValuesToNotIncludeArray = JSFUNC.concat_arrays_or_values_into_new_array(combinedValuesToNotIncludeArray, p_valuesToNotIncludeArray);
    }

    //remove all values if p_valuesToNotIncludeArray has values
    var trimmedValueArray = undefined;
    var trimmedDisplayArray = undefined;
    var trimmedTreeIDArray = undefined;
    var trimmedColorArray = undefined;
    var trimmedBgColorArray = undefined;
    var trimmedHiddenUnlessCheckedTFArray = undefined;
    var trimmedUnableToHighlightOrClickTFArray = undefined;
    var trimmedOptionDisplayOverwriteStringsArray = undefined;
    var trimmedMultiIDsCheckedWhenCheckedArrayOfArrays = undefined;
    if(combinedValuesToNotIncludeArray.length > 0) { //remove values from all 5 inputs arrays matching values within the remove array
      trimmedValueArray = [];
      trimmedDisplayArray = [];
      trimmedTreeIDArray = [];
      trimmedColorArray = [];
      trimmedBgColorArray = [];
      trimmedHiddenUnlessCheckedTFArray = [];
      trimmedUnableToHighlightOrClickTFArray = [];
      trimmedOptionDisplayOverwriteStringsArray = [];
      trimmedMultiIDsCheckedWhenCheckedArrayOfArrays = [];
      for(let i = 0; i < numInitialValues; i++) {
        var value = p_valueArray[i];
        if(!JSFUNC.in_array(value, combinedValuesToNotIncludeArray)) {
          trimmedValueArray.push(value);
          trimmedDisplayArray.push(p_displayArray[i]);
          trimmedTreeIDArray.push((treeIDArrayIsArrayTF) ? (p_treeIDArray[i]) : (undefined));
          trimmedColorArray.push((colorArrayIsArrayTF) ? (p_colorArray[i]) : (undefined));
          trimmedBgColorArray.push((bgColorArrayIsArrayTF) ? (p_bgColorArray[i]) : (undefined));
          trimmedHiddenUnlessCheckedTFArray.push((hiddenUnlessCheckedTFArrayIsArrayTF) ? (p_hiddenUnlessCheckedTFArray[i]) : (undefined));
          trimmedUnableToHighlightOrClickTFArray.push((unableToHighlightOrClickTFArrayIsArrayTF) ? (p_unableToHighlightOrClickTFArray[i]) : (undefined));
          trimmedOptionDisplayOverwriteStringsArray.push((optionDisplayOverwriteStringsArrayIsArrayTF) ? (p_optionDisplayOverwriteStringsArray[i]) : (undefined));
          trimmedMultiIDsCheckedWhenCheckedArrayOfArrays.push((multiIDsCheckedWhenCheckedArrayOfArraysIsArrayTF) ? (p_multiIDsCheckedWhenCheckedArrayOfArrays[i]) : (undefined));
        }
      }
    }
    else { //draw select options with all values
      trimmedValueArray = p_valueArray;
      trimmedDisplayArray = p_displayArray;

      //compute an array of undefined once if needed for any of the optional arrays
      var arrayOfUndefineds = undefined;
      if(!treeIDArrayIsArrayTF || !colorArrayIsArrayTF || !bgColorArrayIsArrayTF || !hiddenUnlessCheckedTFArrayIsArrayTF || !unableToHighlightOrClickTFArrayIsArrayTF || !optionDisplayOverwriteStringsArrayIsArrayTF || !multiIDsCheckedWhenCheckedArrayOfArraysIsArrayTF) {
        arrayOfUndefineds = JSFUNC.array_fill(numInitialValues, undefined);
      }
      trimmedTreeIDArray = ((treeIDArrayIsArrayTF) ? (p_treeIDArray) : (arrayOfUndefineds));
      trimmedColorArray = ((colorArrayIsArrayTF) ? (p_colorArray) : (arrayOfUndefineds));
      trimmedBgColorArray = ((bgColorArrayIsArrayTF) ? (p_bgColorArray) : (arrayOfUndefineds));
      trimmedHiddenUnlessCheckedTFArray = ((hiddenUnlessCheckedTFArrayIsArrayTF) ? (p_hiddenUnlessCheckedTFArray) : (arrayOfUndefineds));
      trimmedUnableToHighlightOrClickTFArray = ((unableToHighlightOrClickTFArrayIsArrayTF) ? (p_unableToHighlightOrClickTFArray) : (arrayOfUndefineds));
      trimmedOptionDisplayOverwriteStringsArray = ((optionDisplayOverwriteStringsArrayIsArrayTF) ? (p_optionDisplayOverwriteStringsArray) : (arrayOfUndefineds));
      trimmedMultiIDsCheckedWhenCheckedArrayOfArrays = ((multiIDsCheckedWhenCheckedArrayOfArraysIsArrayTF) ? (p_multiIDsCheckedWhenCheckedArrayOfArrays) : (arrayOfUndefineds));
    }

    return(
      <SelectWithSearchSelectAndOptionsBox
        p_valueArray={trimmedValueArray}
        p_displayArray={trimmedDisplayArray}
        p_treeIDArray={trimmedTreeIDArray}
        p_colorArray={trimmedColorArray}
        p_bgColorArray={trimmedBgColorArray}
        p_hiddenUnlessCheckedTFArray={trimmedHiddenUnlessCheckedTFArray}
        p_unableToHighlightOrClickTFArray={trimmedUnableToHighlightOrClickTFArray}
        p_optionDisplayOverwriteStringsArray={trimmedOptionDisplayOverwriteStringsArray}
        p_multiIDsCheckedWhenCheckedArrayOfArrays={trimmedMultiIDsCheckedWhenCheckedArrayOfArrays}
        p_selectedValue={p_selectedValue}
        p_selectedDisplay={p_selectedDisplay}
        p_valuesAreStringsTF={p_valuesAreStringsTF}
        p_isMultiSelectTF={p_isMultiSelectTF}
        p_hasClearSelectionTF={p_hasClearSelectionTF}
        p_searchTF={p_searchTF}
        p_initOptionsBoxOpenTF={p_initOptionsBoxOpenTF}
        p_selectedDisplayFocusOnKeyDownEnterOpensSelectTF={p_selectedDisplayFocusOnKeyDownEnterOpensSelectTF}
        p_optionsBoxForMobileTF={p_optionsBoxForMobileTF}
        p_width={p_width}
        p_highlightColor={p_highlightColor}
        p_title={p_title}
        p_tabIndex={p_tabIndex}
        p_errorTF={p_errorTF}
        f_onOptionSelect={this.props.f_onOptionSelect}
        f_onSaveNewEntryName={this.props.f_onSaveNewEntryName}
        f_onKeyDownEnterWhenSelectIsClosed={this.props.f_onKeyDownEnterWhenSelectIsClosed}
      />
    );
  }
}


function SelectWithSearchError(props) { //props: p_message
  return(
    <div className="smallFullPad border bevelBorderColors" style={{background:"#ffd"}}>
      <font className="fontItalic fontBold">
        {props.p_message}
      </font>
    </div>
  );
}


class SelectWithSearchSelectAndOptionsBox extends Component {
  //p_valueArray, p_displayArray, p_treeIDArray, p_colorArray, p_bgColorArray, p_hiddenUnlessCheckedTFArray, p_unableToHighlightOrClickTFArray, p_optionDisplayOverwriteStringsArray, p_multiIDsCheckedWhenCheckedArrayOfArrays, 
  //p_selectedValue, p_selectedDisplay, p_valuesAreStringsTF, p_isMultiSelectTF, p_hasClearSelectionTF, p_searchTF, p_initOptionsBoxOpenTF, p_selectedDisplayFocusOnKeyDownEnterOpensSelectTF, p_optionsBoxForMobileTF, 
  //p_width, p_highlightColor, p_title, p_tabIndex, p_errorTF,
  //f_onOptionSelect, f_onSaveNewEntryName, f_onKeyDownEnterWhenSelectIsClosed
  constructor(props) {
    super(props);

    this.selectSelectedValueDisplayRef = React.createRef();

    this.state = {
      s_selectIsOpenTF: this.props.p_initOptionsBoxOpenTF,
      s_selectIsFocusedTF: false,
      s_rerenderWhenMountedForInitOptionsBoxTF: (this.props.p_initOptionsBoxOpenTF === false || this.props.p_initOptionsBoxOpenTF === undefined)
    }
  }

  componentDidMount() {
    if(this.props.p_initOptionsBoxOpenTF) {
      this.setState({s_rerenderWhenMountedForInitOptionsBoxTF:true});
    }
  }

  open_select_options_box = () => {
    this.setState({s_selectIsOpenTF:true, s_selectIsFocusedTF:false});
  }

  close_select_options_box = () => {
    this.setState({s_selectIsOpenTF:false, s_selectIsFocusedTF:false});
  }

  close_select_options_box_and_focus_on_select = () => {
    this.setState({s_selectIsOpenTF:false, s_selectIsFocusedTF:true});
  }

  toggle_select_options_box = () => {
    if(this.state.s_selectIsOpenTF) {
      this.close_select_options_box_and_focus_on_select();
    }
    else {
      this.open_select_options_box();
    }
  }

  set_select_is_open_tf = (i_newValueTF) => {
    if(!i_newValueTF) {
      this.close_select_options_box_and_focus_on_select();
    }
    else {
      this.open_select_options_box();
    }
  }

  call_external_onkeydownenter = () => {
    if(JSFUNC.is_function(this.props.f_onKeyDownEnterWhenSelectIsClosed)) {
      this.props.f_onKeyDownEnterWhenSelectIsClosed();
    }
  }

  onclick_clear_selection = () => {
    if(JSFUNC.is_function(this.props.f_onOptionSelect)) {
      const clearSelectionReturnValue = ((this.props.p_isMultiSelectTF || this.props.p_valuesAreStringsTF) ? ("") : (-1)); //-1 is a flag in the database for non multi selects that no option has been selected ("" for multiselect comma lists being cleared), all other tbl_cap id numbers that are valid choices are > 0
      this.props.f_onOptionSelect(clearSelectionReturnValue);
    }
  }

  render() {
    const s_selectIsOpenTF = this.state.s_selectIsOpenTF;
    const s_selectIsFocusedTF = this.state.s_selectIsFocusedTF;
    const s_rerenderWhenMountedForInitOptionsBoxTF = this.state.s_rerenderWhenMountedForInitOptionsBoxTF;

    const p_valueArray = this.props.p_valueArray;
    const p_displayArray = this.props.p_displayArray;
    const p_treeIDArray = this.props.p_treeIDArray;
    const p_colorArray = this.props.p_colorArray;
    const p_bgColorArray = this.props.p_bgColorArray;
    const p_hiddenUnlessCheckedTFArray = this.props.p_hiddenUnlessCheckedTFArray;
    const p_unableToHighlightOrClickTFArray = this.props.p_unableToHighlightOrClickTFArray;
    const p_optionDisplayOverwriteStringsArray = this.props.p_optionDisplayOverwriteStringsArray;
    const p_multiIDsCheckedWhenCheckedArrayOfArrays = this.props.p_multiIDsCheckedWhenCheckedArrayOfArrays;
    const p_selectedValue = this.props.p_selectedValue;
    const p_selectedDisplay = this.props.p_selectedDisplay;
    const p_valuesAreStringsTF = this.props.p_valuesAreStringsTF;
    const p_isMultiSelectTF = this.props.p_isMultiSelectTF;
    const p_hasClearSelectionTF = this.props.p_hasClearSelectionTF;
    const p_searchTF = this.props.p_searchTF;
    const p_initOptionsBoxOpenTF = this.props.p_initOptionsBoxOpenTF;
    const p_selectedDisplayFocusOnKeyDownEnterOpensSelectTF = this.props.p_selectedDisplayFocusOnKeyDownEnterOpensSelectTF;
    const p_optionsBoxForMobileTF = this.props.p_optionsBoxForMobileTF;
    const p_width = this.props.p_width;
    const p_highlightColor = this.props.p_highlightColor;
    const p_title = this.props.p_title;
    const p_tabIndex = this.props.p_tabIndex;
    const p_errorTF = this.props.p_errorTF;

    //select positioning
    var selectPosition = undefined;
    var selectTop = undefined;
    var selectRight = undefined;
    var selectBottom = undefined;
    var selectLeft = undefined;
    var selectWidth = undefined;
    if(s_selectIsOpenTF && p_optionsBoxForMobileTF) { //move the select to the top of the screen above the fullscreen options box if this is mobile and the options are open
      selectPosition = "fixed";
      selectTop = "0";
      selectRight = "0";
      selectBottom = undefined;
      selectLeft = "0";
      selectWidth = undefined;
    }
    else {
      selectPosition = "relative";
      selectTop = undefined;
      selectRight = undefined;
      selectBottom = undefined;
      selectLeft = undefined;
      selectWidth = p_width;
    }

    //options box positioning
    var optionsBoxStylePosition = undefined;
    var optionsBoxStyleTop = undefined;
    var optionsBoxStyleRight = undefined;
    var optionsBoxStyleBottom = undefined;
    var optionsBoxStyleLeft = undefined;
    var optionsBoxStyleMinWidth = undefined;
    if(s_selectIsOpenTF) {
      if(p_optionsBoxForMobileTF) { //fullscreen mobile select
        optionsBoxStylePosition = "fixed";
        optionsBoxStyleTop = "1.9em";
        optionsBoxStyleRight = "0";
        optionsBoxStyleBottom = "0";
        optionsBoxStyleLeft = "0";
        optionsBoxStyleMinWidth = undefined;
      }
      else {
        //determine if this select is at the bottom of the screen should open up instead of down
        var openOptionsAboveSelectTF = false;
        const selectSelectedValueDisplayRefCurrent = this.selectSelectedValueDisplayRef.current;
        if((selectSelectedValueDisplayRefCurrent !== null) && s_rerenderWhenMountedForInitOptionsBoxTF) { //null in some cases before the element is drawn
          const windowHeight = window.innerHeight;
          const selectContainerPositionObj = selectSelectedValueDisplayRefCurrent.getBoundingClientRect();
          if((windowHeight > 0) && ((selectContainerPositionObj.top / windowHeight) > 0.7)) { //select is at least 70% of the way towards the bottom of the screen
            openOptionsAboveSelectTF = true;
          }
        }

        optionsBoxStylePosition = "absolute";
        optionsBoxStyleLeft = "-0.05em";
        optionsBoxStyleRight = undefined;
        optionsBoxStyleMinWidth = p_width;
        if(openOptionsAboveSelectTF) { //open options upwards above select
          optionsBoxStyleTop = undefined;
          optionsBoxStyleBottom = "1.75em";
        }
        else { //normal open options box below select
          optionsBoxStyleTop = "1.75em";
          optionsBoxStyleBottom = undefined;
        }
      }
    }

    return(
      <DivWithOffClick
        p_offClickIncludesParentTF={false}
        p_preventClickDefaultAndPropagationTF={false}
        f_offClick={this.close_select_options_box}>
        <InteractiveDiv
          p_class="positionRelative displayFlexRow"
          p_tabIndex={((p_initOptionsBoxOpenTF && p_searchTF) ? (undefined) : (p_tabIndex))}
          p_errorTF={p_errorTF}
          p_focusTF={s_selectIsFocusedTF}
          f_onClick={this.toggle_select_options_box}
          f_onKeyDownTabKeepDefaultAndPropogation={this.close_select_options_box}
          f_onKeyDownEnter={((!s_selectIsOpenTF) ? ((p_selectedDisplayFocusOnKeyDownEnterOpensSelectTF) ? (this.open_select_options_box) : (this.call_external_onkeydownenter)) : (undefined))}
          f_onKeyDownDownArrow={((!s_selectIsOpenTF) ? (this.open_select_options_box) : (undefined))}
          f_onKeyDownEsc={((s_selectIsOpenTF) ? (this.close_select_options_box_and_focus_on_select) : (undefined))}>
          <div
            className="flex11a displayFlexRow bgWhite"
            style={{position:selectPosition, top:selectTop, right:selectRight, bottom:selectBottom, left:selectLeft, width:selectWidth, border:"solid 1px #999"}}>
            <div
              className="flex11a displayFlexRowVc cursorPointer"
              style={{height:"1.8em", padding:"0 0.4em"}}
              title={p_title}
              ref={this.selectSelectedValueDisplayRef}>
              <Nowrap>
                {p_selectedDisplay}
              </Nowrap>
            </div>
            {(p_hasClearSelectionTF) &&
              <SelectClearSelection f_onClick={this.onclick_clear_selection} />
            }
            <SelectOpenOptionsBoxIcon />
          </div>
          {(s_selectIsOpenTF) &&
            <DivWithOffClick
              p_offClickIncludesParentTF={true}
              p_preventClickDefaultAndPropagationTF={true}
              p_class="displayFlexColumn"
              p_styleObj={{position:optionsBoxStylePosition, top:optionsBoxStyleTop, right:optionsBoxStyleRight, bottom:optionsBoxStyleBottom, left:optionsBoxStyleLeft, minWidth:optionsBoxStyleMinWidth, background:"#ccc", border:"solid 1px #888"}}
              f_offClick={this.close_select_options_box}>
              <SelectWithSearchOptionsBox
                p_valueArray={p_valueArray}
                p_displayArray={p_displayArray}
                p_treeIDArray={ p_treeIDArray}
                p_colorArray={p_colorArray}
                p_bgColorArray={p_bgColorArray}
                p_hiddenUnlessCheckedTFArray={p_hiddenUnlessCheckedTFArray}
                p_unableToHighlightOrClickTFArray={p_unableToHighlightOrClickTFArray}
                p_optionDisplayOverwriteStringsArray={p_optionDisplayOverwriteStringsArray}
                p_multiIDsCheckedWhenCheckedArrayOfArrays={p_multiIDsCheckedWhenCheckedArrayOfArrays}
                p_selectedValue={p_selectedValue}
                p_valuesAreStringsTF={p_valuesAreStringsTF}
                p_isMultiSelectTF={p_isMultiSelectTF}
                p_searchTF={p_searchTF}
                p_optionsBoxForMobileTF={p_optionsBoxForMobileTF}
                p_highlightColor={p_highlightColor}
                f_onOptionSelect={this.props.f_onOptionSelect}
                f_onSaveNewEntryName={this.props.f_onSaveNewEntryName}
                f_setSelectIsOpenTF={this.set_select_is_open_tf}
              />
            </DivWithOffClick>
          }
        </InteractiveDiv>
      </DivWithOffClick>
    );
  }
}

function SelectClearSelection(props) { //props: f_onClick
  return(
    <InteractiveDiv
      p_class="flex00a displayFlexColumnHcVc cursorPointer"
      p_styleObj={{flexBasis:"1.5em", borderLeft:"solid 1px #eee"}}
      f_onClick={props.f_onClick}>
      <font className="font09 fontBlueLight">
        {"\u2716"}
      </font>
    </InteractiveDiv>
  );
}

function SelectOpenOptionsBoxIcon(props) {
  return(
    <div className="flex00a displayFlexColumnHcVc cursorPointer" style={{flexBasis:"2em", borderLeft:"solid 1px #eee"}}>
      <font className="fontBold">
        {"\u21AF"}
      </font>
    </div>
  );
}


class SelectWithSearchOptionsBox extends Component { //props: 
  //p_valueArray, p_displayArray, p_treeIDArray, p_colorArray, p_bgColorArray, p_hiddenUnlessCheckedTFArray, p_unableToHighlightOrClickTFArray, p_optionDisplayOverwriteStringsArray, p_multiIDsCheckedWhenCheckedArrayOfArrays, 
  //p_selectedValue, p_valuesAreStringsTF, p_isMultiSelectTF, p_searchTF, p_optionsBoxForMobileTF, p_highlightColor, 
  //f_onOptionSelect, f_onSaveNewEntryName, f_setSelectIsOpenTF
  constructor(props) {
    super(props);

    const initialMultiSelectCurrentSelectionIDsArray = this.compute_multi_select_current_selection_int_ids_or_strings_array(); //initialize the multiselect selections as the selectedValue from the database which is a comma list of the saved selections

    this.state = {
      s_filterSearchTerm: "",
      s_filteredValueArray: [],
      s_filteredDisplayArray: [],
      s_filteredTreeIDArray: [],
      s_filteredColorArray: [],
      s_filteredBgColorArray: [],
      s_filteredHiddenUnlessCheckedTFArray: [],
      s_filteredUnableToHighlightOrClickTFArray: [],
      s_filteredOptionDisplayOverwriteStringsArray: [],
      s_filteredMultiIDsCheckedWhenCheckedArrayOfArrays: [],
      s_numFilteredOptions: 0,
      s_highlightedOptionIndex: -1, //-1 flag that there are no filtered options left to select
      s_multiSelectCurrentSelectionIDsArray: initialMultiSelectCurrentSelectionIDsArray,
      s_multiSelectPrevItemIDPushed: undefined,
      s_multiSelectNumTimesPrevItemIDTurnedOn: 0,
      s_multiSelectOnlyViewCheckedItemsTF: false
    }
  }

  componentDidMount() {
    const p_valueArray = this.props.p_valueArray;
    const p_selectedValue = this.props.p_selectedValue;

    //initialize the highlighted index by finding where the value matches the selectedValue
    this.set_highlighted_option_index(p_valueArray.indexOf(p_selectedValue));

    //initialize the filtered value/display array to their full length (since this component initializes with nothing in the filterSearchTerm)
    this.compute_filtered_arrays("");
  }

  componentDidUpdate(prevProps) {
    const s_filterSearchTerm = this.state.s_filterSearchTerm;

    const p_displayArray = this.props.p_displayArray;
    const p_selectedValue = this.props.p_selectedValue;
    const p_valuesAreStringsTF = this.props.p_valuesAreStringsTF;
    const p_isMultiSelectTF = this.props.p_isMultiSelectTF;

    if(p_isMultiSelectTF) {
      if((p_displayArray !== prevProps.p_displayArray) || (p_selectedValue !== prevProps.p_selectedValue)) {
        //outside of this function a new entry can be added to the database, these local state filter variables need to be updated to reflect that p_displayArray/p_valueArray have been extended
        this.compute_filtered_arrays(s_filterSearchTerm);

        //the new entry inserted also gets added to the p_selectedValue comma list for multiselects outside of this function, which needs this to be reflected in the internal state variables of which multiselect items are currently selected
        const multiSelectCurrentSelectionIntIDsOrStringsArray = this.compute_multi_select_current_selection_int_ids_or_strings_array();
        this.setState({s_multiSelectCurrentSelectionIDsArray:multiSelectCurrentSelectionIntIDsOrStringsArray});
      }
    }
  }

  compute_multi_select_current_selection_int_ids_or_strings_array = () => {
    const p_selectedValue = this.props.p_selectedValue;
    const p_valuesAreStringsTF = this.props.p_valuesAreStringsTF;

    //comma list of strings into array of strings
    if(p_valuesAreStringsTF) {
      return(JSFUNC.convert_comma_list_to_array(p_selectedValue));
    }

    //comma list of int IDs into int array of IDs
    return(JSFUNC.convert_comma_list_to_int_array(p_selectedValue));
  }

  set_highlighted_option_index = (i_indexToHighlight) => {
    this.setState({s_highlightedOptionIndex:i_indexToHighlight});
  }

  compute_filtered_arrays = (i_filterSearchTerm) => {
    const p_valueArray = this.props.p_valueArray;
    const p_displayArray = this.props.p_displayArray;
    const p_treeIDArray = this.props.p_treeIDArray;
    const p_colorArray = this.props.p_colorArray;
    const p_bgColorArray = this.props.p_bgColorArray;
    const p_hiddenUnlessCheckedTFArray = this.props.p_hiddenUnlessCheckedTFArray;
    const p_unableToHighlightOrClickTFArray = this.props.p_unableToHighlightOrClickTFArray;
    const p_optionDisplayOverwriteStringsArray = this.props.p_optionDisplayOverwriteStringsArray;
    const p_multiIDsCheckedWhenCheckedArrayOfArrays = this.props.p_multiIDsCheckedWhenCheckedArrayOfArrays;
    const p_searchTF = this.props.p_searchTF;

    const requiresSearchTermFilteringTF = (p_searchTF && JSFUNC.is_string(i_filterSearchTerm) && (i_filterSearchTerm.length > 0));

    var filteredValueArray = [];
    var filteredDisplayArray = [];
    var filteredTreeIDArray = [];
    var filteredColorArray = [];
    var filteredBgColorArray = [];
    var filteredHiddenUnlessCheckedTFArray = [];
    var filteredUnableToHighlightOrClickTFArray = [];
    var filteredOptionDisplayOverwriteStringsArray = [];
    var filteredMultiIDsCheckedWhenCheckedArrayOfArrays = [];
    if(!requiresSearchTermFilteringTF) { //no search or the filter is blank, use the full value/display arrays
      filteredValueArray = p_valueArray;
      filteredDisplayArray = p_displayArray;
      filteredTreeIDArray = p_treeIDArray;
      filteredColorArray = p_colorArray;
      filteredBgColorArray = p_bgColorArray;
      filteredHiddenUnlessCheckedTFArray = p_hiddenUnlessCheckedTFArray;
      filteredUnableToHighlightOrClickTFArray = p_unableToHighlightOrClickTFArray;
      filteredOptionDisplayOverwriteStringsArray = p_optionDisplayOverwriteStringsArray;
      filteredMultiIDsCheckedWhenCheckedArrayOfArrays = p_multiIDsCheckedWhenCheckedArrayOfArrays;
    }
    else { //filter the value/display arrays based on the search term
      const usingTreeIDAndColorTF = (JSFUNC.is_array(p_treeIDArray) && JSFUNC.is_array(p_colorArray));
      const usingBgColorTF = JSFUNC.is_array(p_bgColorArray);
      const usingHiddenUnlessCheckedTF = JSFUNC.is_array(p_hiddenUnlessCheckedTFArray);
      const usingUnableToHighlightOrClickTF = JSFUNC.is_array(p_unableToHighlightOrClickTFArray);
      const usingOptionDisplayOverwriteStringTF = JSFUNC.is_array(p_optionDisplayOverwriteStringsArray);
      const usingMultiIDsCheckedWhenCheckedArrayTF = JSFUNC.is_array(p_multiIDsCheckedWhenCheckedArrayOfArrays);

      const filterSearchTermLower = i_filterSearchTerm.toLowerCase();
      
      for(let v = 0; v < p_valueArray.length; v++) {
        var display = p_displayArray[v];
        var displayLowercase = "";
        if(JSFUNC.is_string(display)) {
          displayLowercase = display.toLowerCase();
        }

        if(displayLowercase.indexOf(filterSearchTermLower) >= 0) {
          filteredValueArray.push(p_valueArray[v]);
          filteredDisplayArray.push(display);

          if(usingTreeIDAndColorTF) {
            filteredTreeIDArray.push(p_treeIDArray[v]);
            filteredColorArray.push(p_colorArray[v]);
          }

          if(usingBgColorTF) {
            filteredBgColorArray.push(p_bgColorArray[v]);
          }

          if(usingHiddenUnlessCheckedTF) {
            filteredHiddenUnlessCheckedTFArray.push(p_hiddenUnlessCheckedTFArray[v]);
          }

          if(usingUnableToHighlightOrClickTF) {
            filteredUnableToHighlightOrClickTFArray.push(p_unableToHighlightOrClickTFArray[v]);
          }

          if(usingOptionDisplayOverwriteStringTF) {
            filteredOptionDisplayOverwriteStringsArray.push(p_optionDisplayOverwriteStringsArray[v]);
          }

          if(usingMultiIDsCheckedWhenCheckedArrayTF) {
            filteredMultiIDsCheckedWhenCheckedArrayOfArrays.push(p_multiIDsCheckedWhenCheckedArrayOfArrays[v]);
          }
        }
      }
    }

    this.setState({
      s_filteredValueArray: filteredValueArray,
      s_filteredDisplayArray: filteredDisplayArray,
      s_filteredTreeIDArray: filteredTreeIDArray,
      s_filteredColorArray: filteredColorArray,
      s_filteredBgColorArray: filteredBgColorArray,
      s_filteredHiddenUnlessCheckedTFArray: filteredHiddenUnlessCheckedTFArray,
      s_filteredUnableToHighlightOrClickTFArray: filteredUnableToHighlightOrClickTFArray,
      s_filteredOptionDisplayOverwriteStringsArray: filteredOptionDisplayOverwriteStringsArray,
      s_filteredMultiIDsCheckedWhenCheckedArrayOfArrays: filteredMultiIDsCheckedWhenCheckedArrayOfArrays,
      s_numFilteredOptions: filteredDisplayArray.length
    });
  }

  onchange_search_input = (i_newValue) => {
    this.setState({
      s_filterSearchTerm: i_newValue,
      s_highlightedOptionIndex: 0 //if typing into search, snap the highlighted index to the first option always
    });
    this.compute_filtered_arrays(i_newValue);
  }

  select_option_value = (i_selectedValue) => {
    if(JSFUNC.is_function(this.props.f_onOptionSelect) && (this.props.p_selectedValue !== i_selectedValue)) { //don't fire off the selection function if the value selected was the same one that was already selected before, if this command needs to be fired in the future, take this out
      this.props.f_onOptionSelect(i_selectedValue);
    }
    this.props.f_setSelectIsOpenTF(false);
  }

  onkeydownenter_options_container = () => { //enter key, selects the highlighted option, if there are no options, the select closes like a cancel
    const s_filteredValueArray = this.state.s_filteredValueArray;
    const s_numFilteredOptions = this.state.s_numFilteredOptions;
    const s_highlightedOptionIndex = this.state.s_highlightedOptionIndex;

    if(s_numFilteredOptions > 0) { //there is at least 1 option available that is highlighted, pushing enter selects it
      this.select_option_value(s_filteredValueArray[s_highlightedOptionIndex]);
    }
    else { //filter has left 0 options, pressing enter is basically a cancel, closing the select
      this.props.f_setSelectIsOpenTF(false);
    }
  }

  onkeydownesc_options_container = () => {
    this.props.f_setSelectIsOpenTF(false);
  }

  onkeydowntab_options_container = () => {
    this.props.f_setSelectIsOpenTF(false);
  }

  onkeydownuparrow_options_container = () => { //up arrow, wrap to the end (length-1) if you are at the top index
    const s_numFilteredOptions = this.state.s_numFilteredOptions;
    const s_highlightedOptionIndex = this.state.s_highlightedOptionIndex;
    this.set_highlighted_option_index((s_highlightedOptionIndex <= 0) ? (s_numFilteredOptions-1) : (s_highlightedOptionIndex - 1));
  }

  onkeydowndownarrow_options_container = () => { //down arrow, wrap back to the beginning (0) if you are at the bottom index
    const s_numFilteredOptions = this.state.s_numFilteredOptions;
    const s_highlightedOptionIndex = this.state.s_highlightedOptionIndex;
    this.set_highlighted_option_index((s_highlightedOptionIndex >= (s_numFilteredOptions-1)) ? (0) : (s_highlightedOptionIndex + 1));
  }


  //multiselect methods
  onclick_multiselect_option = (i_itemIDSelected, i_turningSelectionOnTF) => {
    const s_filterSearchTerm = this.state.s_filterSearchTerm;
    const s_filteredValueArray = this.state.s_filteredValueArray;
    const s_numFilteredOptions = this.state.s_numFilteredOptions;
    const s_multiSelectCurrentSelectionIDsArray = this.state.s_multiSelectCurrentSelectionIDsArray;
    const s_multiSelectPrevItemIDPushed = this.state.s_multiSelectPrevItemIDPushed;
    const s_multiSelectNumTimesPrevItemIDTurnedOn = this.state.s_multiSelectNumTimesPrevItemIDTurnedOn;

    const p_valueArray = this.props.p_valueArray;

    //extra code for selecting/unselecting/selecting the same option back and forth to make that the only option
    var doubleSelectItemTF = false;
    if(i_itemIDSelected === s_multiSelectPrevItemIDPushed) {
      if(i_turningSelectionOnTF) {
        if(s_multiSelectNumTimesPrevItemIDTurnedOn >= 1) {
          doubleSelectItemTF = true; //unset all items and only set the one selected using the code below
          this.setState({
            s_multiSelectPrevItemIDPushed: undefined,
            s_multiSelectNumTimesPrevItemIDTurnedOn: 0
          });
        }
        else {
          this.setState({
            s_multiSelectNumTimesPrevItemIDTurnedOn: (s_multiSelectNumTimesPrevItemIDTurnedOn + 1)
          });
        }
      }
    }
    else {
      this.setState({
        s_multiSelectPrevItemIDPushed:i_itemIDSelected,
        s_multiSelectNumTimesPrevItemIDTurnedOn: ((i_turningSelectionOnTF) ? (1) : (0))
      });
    }

    //update for a group select (id < 0) or selecting a single stage (id > 0)
    if(i_itemIDSelected < 0) { //special option for selecting or deselecting all options, (otherwise clicked on a single stage with a positive itemID)
      if(s_filterSearchTerm.length === 0) { //nothing typed into the search term, so all options are currently displayed, either check them all or uncheck them all when this is clicked
        if(i_turningSelectionOnTF) { //if none or some items were selected, select all of them
          this.multiselect_set_current_selection_ids_array(p_valueArray);
        }
        else { //if all items were selected, unselect all
          this.multiselect_set_current_selection_ids_array([]);
        }
      }
      else if(s_numFilteredOptions > 0) { //if the filter search term has something filled out AND there is at least 1 option matching that search term, checking this box adds all filtered options to the already selected selections, unchecking removes them, if the filtered options list is empty, this does nothing
        if(i_turningSelectionOnTF) { //if none or some items were selected, select all of them
          this.multiselect_set_current_selection_ids_array(JSFUNC.merge_unique(s_multiSelectCurrentSelectionIDsArray, s_filteredValueArray));
        }
        else { //if all items were selected, unselect all
          this.multiselect_set_current_selection_ids_array(JSFUNC.remove_all_values_from_array(s_filteredValueArray, s_multiSelectCurrentSelectionIDsArray));
        }
      }
    }
    else if(doubleSelectItemTF) { //if the double turn on of a single option has been detected, select only that item
      this.multiselect_set_current_selection_ids_array([i_itemIDSelected]);
    }
    else if(i_turningSelectionOnTF) { //if none or some items were selected, select all of them
      const currentSelectionPlusSelectedItemIDsArray = JSFUNC.merge_unique(s_multiSelectCurrentSelectionIDsArray, [i_itemIDSelected]);
      this.multiselect_set_current_selection_ids_array(currentSelectionPlusSelectedItemIDsArray);
    }
    else { //if all items were selected, unselect all
      this.multiselect_set_current_selection_ids_array(JSFUNC.remove_all_values_from_array([i_itemIDSelected], s_multiSelectCurrentSelectionIDsArray));
    }
  }

  multiselect_set_current_selection_ids_array = (i_updatedMultiSelectCurrentSelectionIDsArray) => {
    const p_valueArray = this.props.p_valueArray;
    const p_unableToHighlightOrClickTFArray = this.props.p_unableToHighlightOrClickTFArray;

    var updatedMultiSelectCurrentSelectionIDsArray = i_updatedMultiSelectCurrentSelectionIDsArray;

    //check if p_unableToHighlightOrClickTFArray is being used, if so, remove all true value ID numbers from this update selection before the update
    if(i_updatedMultiSelectCurrentSelectionIDsArray.length > 0) { //don't need to check an empty array input clearing out all check marks
      const usingUnableToHighlightOrClickTF = JSFUNC.is_array(p_unableToHighlightOrClickTFArray);
      if(usingUnableToHighlightOrClickTF) {
        const valuesUnableToClickArray = JSFUNC.created_filtered_array1_where_array2_index_true(p_valueArray, p_unableToHighlightOrClickTFArray);
        updatedMultiSelectCurrentSelectionIDsArray = JSFUNC.remove_all_values_from_array(valuesUnableToClickArray, i_updatedMultiSelectCurrentSelectionIDsArray);
      }
    }

    //selecting or deselecting a multiselect option passes an updated array of selected ids to store in this state variable
    this.setState({s_multiSelectCurrentSelectionIDsArray:updatedMultiSelectCurrentSelectionIDsArray});

    //instant firing for any change made in a multiselect without using the "Apply Changes" button row
    if(JSFUNC.is_function(this.props.f_onOptionSelect)) { //don't fire off the selection function if the value selected was the same one that was already selected before, if this command needs to be fired in the future, take this out
      const multiSelectCurrentSelectionIDsComma = JSFUNC.convert_array_to_comma_list(updatedMultiSelectCurrentSelectionIDsArray);
      this.props.f_onOptionSelect(multiSelectCurrentSelectionIDsComma);
    }
  }

  onswitch_multi_select_only_view_checked_items = () => {
    this.setState((i_state, i_props) => ({
      s_multiSelectOnlyViewCheckedItemsTF: (!i_state.s_multiSelectOnlyViewCheckedItemsTF)
    }));
  }

  render() {
    const s_filterSearchTerm = this.state.s_filterSearchTerm;
    const s_filteredValueArray = this.state.s_filteredValueArray;
    const s_filteredDisplayArray = this.state.s_filteredDisplayArray;
    const s_filteredTreeIDArray = this.state.s_filteredTreeIDArray;
    const s_filteredColorArray = this.state.s_filteredColorArray;
    const s_filteredBgColorArray = this.state.s_filteredBgColorArray;
    const s_filteredHiddenUnlessCheckedTFArray = this.state.s_filteredHiddenUnlessCheckedTFArray;
    const s_filteredUnableToHighlightOrClickTFArray = this.state.s_filteredUnableToHighlightOrClickTFArray;
    const s_filteredOptionDisplayOverwriteStringsArray = this.state.s_filteredOptionDisplayOverwriteStringsArray;
    const s_filteredMultiIDsCheckedWhenCheckedArrayOfArrays = this.state.s_filteredMultiIDsCheckedWhenCheckedArrayOfArrays;
    const s_numFilteredOptions = this.state.s_numFilteredOptions;
    const s_highlightedOptionIndex = this.state.s_highlightedOptionIndex;
    const s_multiSelectCurrentSelectionIDsArray = this.state.s_multiSelectCurrentSelectionIDsArray;
    const s_multiSelectPrevItemIDPushed = this.state.s_multiSelectPrevItemIDPushed;
    const s_multiSelectNumTimesPrevItemIDTurnedOn = this.state.s_multiSelectNumTimesPrevItemIDTurnedOn;
    const s_multiSelectOnlyViewCheckedItemsTF = this.state.s_multiSelectOnlyViewCheckedItemsTF;

    const p_valueArray = this.props.p_valueArray;
    const p_displayArray = this.props.p_displayArray;
    const p_treeIDArray = this.props.p_treeIDArray;
    const p_colorArray = this.props.p_colorArray;
    const p_bgColorArray = this.props.p_bgColorArray;
    const p_hiddenUnlessCheckedTFArray = this.props.p_hiddenUnlessCheckedTFArray;
    const p_unableToHighlightOrClickTFArray = this.props.p_unableToHighlightOrClickTFArray;
    const p_optionDisplayOverwriteStringsArray = this.props.p_optionDisplayOverwriteStringsArray;
    const p_multiIDsCheckedWhenCheckedArrayOfArrays = this.props.p_multiIDsCheckedWhenCheckedArrayOfArrays;
    const p_selectedValue = this.props.p_selectedValue;
    const p_valuesAreStringsTF = this.props.p_valuesAreStringsTF;
    const p_isMultiSelectTF = this.props.p_isMultiSelectTF;
    const p_searchTF = this.props.p_searchTF;
    const p_optionsBoxForMobileTF = this.props.p_optionsBoxForMobileTF;
    const p_highlightColor = this.props.p_highlightColor;

    const totalNumOptions = p_valueArray.length;

    const usingTreeIDAndColorTF = (JSFUNC.is_array(p_treeIDArray) && JSFUNC.is_array(p_colorArray));
    const usingBgColorTF = JSFUNC.is_array(p_bgColorArray);
    const usingHiddenUnlessCheckedTF = JSFUNC.is_array(p_hiddenUnlessCheckedTFArray);
    const usingUnableToHighlightOrClickTF = JSFUNC.is_array(p_unableToHighlightOrClickTFArray);
    const usingOptionDisplayOverwriteStringTF = JSFUNC.is_array(p_optionDisplayOverwriteStringsArray);
    const usingMultiIDsCheckedWhenCheckedArrayTF = JSFUNC.is_array(p_multiIDsCheckedWhenCheckedArrayOfArrays);

    var filteredSelectableValuesArray = s_filteredValueArray;
    if(usingUnableToHighlightOrClickTF) {
      const valuesUnableToClickArray = JSFUNC.created_filtered_array1_where_array2_index_true(p_valueArray, p_unableToHighlightOrClickTFArray);
      filteredSelectableValuesArray = JSFUNC.remove_all_values_from_array(valuesUnableToClickArray, s_filteredValueArray);
    }

    return(
      <InteractiveDiv
        p_class="flex11a displayFlexColumn"
        p_styleObj={{zIndex:"889"}}
        p_focusTF={!p_searchTF}
        f_onKeyDownEnter={this.onkeydownenter_options_container}
        f_onKeyDownEsc={this.onkeydownesc_options_container}
        f_onKeyDownTabKeepDefaultAndPropogation={this.onkeydowntab_options_container}
        f_onKeyDownUpArrow={this.onkeydownuparrow_options_container}
        f_onKeyDownDownArrow={this.onkeydowndownarrow_options_container}>
        {JSFUNC.is_function(this.props.f_onSaveNewEntryName) &&
          <SelectWithSearchAddNewEntry
            p_displayArray={p_displayArray}
            p_filterSearchTerm={s_filterSearchTerm}
            f_onSaveNewEntryName={this.props.f_onSaveNewEntryName}
          />
        }
        {(p_isMultiSelectTF && (totalNumOptions >= 25)) &&
          <div className="displayFlexRowVc" style={{paddingTop:"0.2em", paddingLeft:"0.5em", paddingRight:"0.5em", background:"#eee"}}>
            <div className="flex11a textRight" style={{paddingRight:"0.5em"}}>
              <font className="fontItalic">
                {"Only Show Selected?"}
              </font>
            </div>
            <div className="flex00a">
              <Switch
                p_isOnTF={s_multiSelectOnlyViewCheckedItemsTF}
                p_sizeEm={2.5}
                p_title="Turn on to only view the checked items in the Multi-Select list"
                f_onClick={this.onswitch_multi_select_only_view_checked_items}
              />
            </div>
          </div>
        }
        {(p_searchTF) &&
          <div
            className="flex00a bgPanelLightGray displayFlexColumnHc"
            style={{padding:"0.4em 0.5em", minWidth:"10em", borderBottom:"solid 1px #ddd"}}>
            <Text
              p_value={s_filterSearchTerm}
              p_styleObj={{width:"100%"}}
              p_tabIndex={1}
              p_focusTF={true}
              p_placeholder="&#x1f50d; Filter Results..."
              f_onChange={this.onchange_search_input}
            />
          </div>
        }
        {(p_isMultiSelectTF && (totalNumOptions > 0)) &&
          <SelectWithSearchOption
            p_isMultiSelectTF={true}
            p_isMultiSelectSelectAllOptionTF={true}
            p_value={-1}
            p_display={((s_filterSearchTerm.length === 0) ? ("Select All Items") : ("Select All Filtered Items"))}
            p_index={-1}
            p_highlightedOptionIndex={s_highlightedOptionIndex}
            p_multiSelectIsSelectedTF={JSFUNC.all_of_array1_is_in_array2(filteredSelectableValuesArray, s_multiSelectCurrentSelectionIDsArray)} //if all of the ids are selected
            p_multiSelectIsPartialTF={(s_multiSelectCurrentSelectionIDsArray.length > 0)} //if any of the ids are selected
            p_optionsBoxForMobileTF={p_optionsBoxForMobileTF}
            p_highlightColor={p_highlightColor}
            f_setHighlightedOptionIndex={this.set_highlighted_option_index}
            f_multiSelectSetCurrentSelectionIDsArray={this.onclick_multiselect_option}
          />
        }
        {(s_numFilteredOptions > 0) ? (
          <div className="flex11a yScroll" style={{maxHeight:((p_optionsBoxForMobileTF) ? (undefined) : ("15em"))}}>
            {(p_isMultiSelectTF) &&
              s_multiSelectCurrentSelectionIDsArray.map((m_multiSelectCurrentSelectionID) =>
                (!JSFUNC.in_array(m_multiSelectCurrentSelectionID, p_valueArray)) &&
                <SelectWithSearchOption
                  key={m_multiSelectCurrentSelectionID}
                  p_isMultiSelectTF={p_isMultiSelectTF}
                  p_isMultiSelectSelectAllOptionTF={false}
                  p_value={m_multiSelectCurrentSelectionID}
                  p_display={"--Option Does Not Exist (ID: "  + m_multiSelectCurrentSelectionID + ")--"}
                  p_treeID={undefined}
                  p_color={undefined}
                  p_bgColor={undefined}
                  p_hiddenUnlessCheckedTF={undefined}
                  p_unableToHighlightOrClickTF={undefined}
                  p_optionDisplayOverwriteString={undefined}
                  p_multiIDsCheckedWhenCheckedArray={undefined}
                  p_index={(-1 * m_multiSelectCurrentSelectionID) - 10}
                  p_highlightedOptionIndex={s_highlightedOptionIndex}
                  p_multiSelectIsSelectedTF={true}
                  p_multiSelectIsPartialTF={false}
                  p_optionsBoxForMobileTF={p_optionsBoxForMobileTF}
                  p_highlightColor={p_highlightColor}
                  f_setHighlightedOptionIndex={this.set_highlighted_option_index}
                  f_selectOptionValue={this.select_option_value}
                  f_multiSelectSetCurrentSelectionIDsArray={this.onclick_multiselect_option}
                />
              )
            }
            {s_filteredValueArray.map((m_filteredValue, m_index) =>
              ((!s_multiSelectOnlyViewCheckedItemsTF) || JSFUNC.in_array(m_filteredValue, s_multiSelectCurrentSelectionIDsArray)) &&
              <SelectWithSearchOption
                key={m_filteredValue}
                p_isMultiSelectTF={p_isMultiSelectTF}
                p_isMultiSelectSelectAllOptionTF={false}
                p_value={m_filteredValue}
                p_display={s_filteredDisplayArray[m_index]}
                p_treeID={((usingTreeIDAndColorTF) ? (s_filteredTreeIDArray[m_index]) : (undefined))}
                p_color={((usingTreeIDAndColorTF) ? (s_filteredColorArray[m_index]) : (undefined))}
                p_bgColor={((usingBgColorTF) ? (s_filteredBgColorArray[m_index]) : (undefined))}
                p_hiddenUnlessCheckedTF={((usingHiddenUnlessCheckedTF) ? (s_filteredHiddenUnlessCheckedTFArray[m_index]) : (undefined))}
                p_unableToHighlightOrClickTF={((usingUnableToHighlightOrClickTF) ? (s_filteredUnableToHighlightOrClickTFArray[m_index]) : (undefined))}
                p_optionDisplayOverwriteString={((usingOptionDisplayOverwriteStringTF) ? (s_filteredOptionDisplayOverwriteStringsArray[m_index]) : (undefined))}
                p_multiIDsCheckedWhenCheckedArray={((usingMultiIDsCheckedWhenCheckedArrayTF) ? (s_filteredMultiIDsCheckedWhenCheckedArrayOfArrays[m_index]) : (undefined))}
                p_index={m_index}
                p_highlightedOptionIndex={s_highlightedOptionIndex}
                p_multiSelectIsSelectedTF={JSFUNC.in_array(m_filteredValue, s_multiSelectCurrentSelectionIDsArray)} //if the id value is in the selection array it is selected
                p_multiSelectIsPartialTF={false}
                p_optionsBoxForMobileTF={p_optionsBoxForMobileTF}
                p_highlightColor={p_highlightColor}
                f_setHighlightedOptionIndex={this.set_highlighted_option_index}
                f_selectOptionValue={this.select_option_value}
                f_multiSelectSetCurrentSelectionIDsArray={this.onclick_multiselect_option}
              />
            )}
          </div>
        ) : (
          <div>
            <font className="fontItalic fontTextLighter">
              {((totalNumOptions > 0) ? ("No options match '" + s_filterSearchTerm + "'") : ("No options available to select"))}
            </font>
          </div>
        )}
      </InteractiveDiv>
    );
  }
}

class SelectWithSearchAddNewEntry extends Component { //props: p_displayArray, p_filterSearchTerm, f_onSaveNewEntryName
  constructor(props) {
    super(props);
    this.state = {
      s_addingNewEntryNameTF: false, //user has pushed "Add New Entry" button at the top of the options box (if it is there) and can enter the name of the new entry they want to add to the list (database work to update the list is handled outside of this library component)
      s_addNewEntryName: ""
    }
  }

  onclick_add_new_entry_button = () => {
    this.setState({
      s_addingNewEntryNameTF: true,
      s_addNewEntryName: this.props.p_filterSearchTerm
    });
  }

  onchange_new_entry_name = (i_newEntryName) => {
    this.setState({s_addNewEntryName:i_newEntryName});
  }

  onclick_save_add_new_entry = () => {
    if(JSFUNC.is_function(this.props.f_onSaveNewEntryName)) {
      this.props.f_onSaveNewEntryName(this.state.s_addNewEntryName);
    }
    this.onclick_cancel_add_new_entry();
  }

  onclick_cancel_add_new_entry = () => {
    this.setState({
      s_addingNewEntryNameTF:false,
      s_addNewEntryName: ""
    });
  }

  render() {
    const addingNewEntryNameTF = this.state.s_addingNewEntryNameTF;

    if(!addingNewEntryNameTF) {
      return(
        <div className="displayFlexColumnHcVc" style={{padding:"0.3em 0", background:"#eee"}}>
          <ButtonNowrap
            p_value="Add New Entry"
            p_class="selectAddNewEntryButton"
            p_tabIndex={-1}
            p_title={undefined}
            f_onClick={this.onclick_add_new_entry_button}
          />
        </div>
      );
    }

    const p_displayArray = this.props.p_displayArray;
    const addNewEntryName = this.state.s_addNewEntryName;

    var newEntryNameErrorMessage = "";
    var errorTF = false;
    if(addNewEntryName === "") {
      newEntryNameErrorMessage = "New entry cannot be blank";
      errorTF = true;
    }
    else if(JSFUNC.in_array(addNewEntryName, p_displayArray)) {
      newEntryNameErrorMessage = "Entry '" + addNewEntryName + "' already exists as an option";
      errorTF = true;
    }

    return(
      <div style={{padding:"0.7em 0", background:"#cceef5"}}>
        <div style={{padding:"0 1em"}}>
          <Text
            p_value={addNewEntryName}
            p_class=""
            p_styleObj={{width:"100%"}}
            p_tabIndex={1}
            p_placeholder="Enter text for new entry..."
            p_errorTF={errorTF}
            f_onChange={this.onchange_new_entry_name}
            f_onKeyDownEnter={this.onclick_save_add_new_entry}
            f_onKeyDownEsc={this.onclick_cancel_add_new_entry}
          />
        </div>
        <div className="displayFlexRowVc" style={{marginTop:"0.5em"}}>
          <div className="flex11a" style={{flexBasis:"100em"}} />
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"7em"}}>
            <ButtonNowrap
              p_value="Add"
              p_class="selectAddNewEntrySaveButton"
              p_tabIndex={2}
              f_onClick={((errorTF) ? (undefined) : (this.onclick_save_add_new_entry))}
            />
          </div>
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"7em"}}>
            <ButtonNowrap
              p_value="Cancel"
              p_class="selectAddNewEntryCancelButton"
              p_tabIndex={3}
              f_onClick={this.onclick_cancel_add_new_entry}
            />
          </div>
          <div className="flex11a" style={{flexBasis:"100em"}} />
        </div>
        {(errorTF) &&
          <div className="textCenter" style={{marginTop:"0.5em"}}>
            <font className="fontItalic" style={{color:"#c33"}}>
              {newEntryNameErrorMessage}
            </font>
          </div>
        }
      </div>
    );
  }
}

class SelectWithSearchOption extends Component { //props:
  //p_isMultiSelectTF, p_isMultiSelectSelectAllOptionTF, p_value, p_display, p_treeID, p_color, p_bgColor, p_hiddenUnlessCheckedTF, p_unableToHighlightOrClickTF, p_optionDisplayOverwriteString, p_multiIDsCheckedWhenCheckedArray, 
  //p_index, p_highlightedOptionIndex, p_multiSelectIsSelectedTF, p_multiSelectIsPartialTF, p_optionsBoxForMobileTF, p_highlightColor,
  //f_setHighlightedOptionIndex, f_selectOptionValue, f_multiSelectSetCurrentSelectionIDsArray
  componentDidMount() {
    this.scroll_selected_option_into_view();
  }

  componentDidUpdate() {
    this.scroll_selected_option_into_view();
  }

  scroll_selected_option_into_view = () => {
    if(this.swsSelectedOptionElement) {
      this.swsSelectedOptionElement.scrollIntoViewIfNeeded(2);
    }
  }

  ref_selected_option_element = (element) => {
    this.swsSelectedOptionElement = element;
  }

  onmouseover_option = () => {
    this.props.f_setHighlightedOptionIndex(this.props.p_index);
  }

  onclick_option = () => {
    if(this.props.p_isMultiSelectTF) { //multiselect, clicking an option checks it or unchecks it locally, but does not call the outro selecting function
      this.props.f_multiSelectSetCurrentSelectionIDsArray(this.props.p_value, !this.props.p_multiSelectIsSelectedTF);
    }
    else { //standard select, clicking an option selects it, calls the outro selecting function, closes the select
      this.props.f_selectOptionValue(this.props.p_value);
    }
  }

  render() {
    const p_isMultiSelectTF = this.props.p_isMultiSelectTF;
    const p_isMultiSelectSelectAllOptionTF = this.props.p_isMultiSelectSelectAllOptionTF;
    const p_value = this.props.p_value;
    const p_display = this.props.p_display;
    const p_treeID = this.props.p_treeID;
    const p_color = this.props.p_color; //color rectangle bgColor to left of option display text
    const p_bgColor = this.props.p_bgColor;
    const p_hiddenUnlessCheckedTF = this.props.p_hiddenUnlessCheckedTF;
    const p_unableToHighlightOrClickTF = this.props.p_unableToHighlightOrClickTF;
    const p_optionDisplayOverwriteString = this.props.p_optionDisplayOverwriteString;
    const p_multiIDsCheckedWhenCheckedArray = this.props.p_multiIDsCheckedWhenCheckedArray;
    const p_index = this.props.p_index;
    const p_highlightedOptionIndex = this.props.p_highlightedOptionIndex;
    const p_multiSelectIsSelectedTF = this.props.p_multiSelectIsSelectedTF;
    const p_multiSelectIsPartialTF = this.props.p_multiSelectIsPartialTF;
    const p_optionsBoxForMobileTF = this.props.p_optionsBoxForMobileTF;
    const p_highlightColor = this.props.p_highlightColor;

    const displayIsBlankTF = (!JSFUNC.text_or_number_is_filled_out_tf(p_display));
    const hasTreeIDIndentTF = JSFUNC.is_string(p_treeID);
    const hasLeftColorRectTF = JSFUNC.is_string(p_color);
    const isHighlightedTF = (p_index === p_highlightedOptionIndex);
    const hasOptionDisplayOverwriteTF = JSFUNC.string_is_filled_out_tf(p_optionDisplayOverwriteString);
    const hasMultiSelectOrTreeOrColorRectTF = (p_isMultiSelectTF || hasTreeIDIndentTF || hasLeftColorRectTF);

    //option padding
    var optionTextVerticalPadding = "0.075em";
    var optionContainerLRPadding = "0.3em";
    if(p_optionsBoxForMobileTF) { //large fullscreen option for mobile
      optionTextVerticalPadding = "1em";
      optionContainerLRPadding = "1em";
    }

    var optionContainerPadding = optionTextVerticalPadding + " " + optionContainerLRPadding;
    var optionTextContainerPadding = undefined; //there is no text <div> container when there is no multi/tree/color
    if(hasMultiSelectOrTreeOrColorRectTF) { //for multi/tree/color, container has horiz padding while text has vertical padding (keeps treeID vertical lines connected)
      optionContainerPadding = "0 " + optionContainerLRPadding;
      optionTextContainerPadding = optionTextVerticalPadding + " 0";
    }

    //option background color and font
    var backgroundColor = "ffffff";
    var fontHashColor = undefined;
    if(isHighlightedTF) {
      backgroundColor = p_highlightColor;
      fontHashColor = "#ffffff";
    }
    else {
      if(p_hiddenUnlessCheckedTF) {
        backgroundColor = "cccccc";
      }
      else if(p_bgColor !== undefined) {
        backgroundColor = p_bgColor;
      }
    }

    var leftColorBlockBgColorString = undefined;
    if(hasLeftColorRectTF) {
      var leftColorBlockBgColorNumChars = p_color.length;
      if((leftColorBlockBgColorNumChars === 6) || (leftColorBlockBgColorNumChars === 3)) {
        leftColorBlockBgColorString = "#" + p_color; //"#ff0000" or "#f00"
      }
      else {
        leftColorBlockBgColorString = p_color; //"linear-gradient()", etc
      }
    }

    var optionDisplay = p_display;
    if(hasOptionDisplayOverwriteTF) {
      optionDisplay = p_optionDisplayOverwriteString;
    }

    const optionDisplayFontComponent = (
      <font
        className={"" + ((displayIsBlankTF || p_unableToHighlightOrClickTF) ? (" fontItalic") : (""))}
        style={{fontSize:((p_optionsBoxForMobileTF) ? ("1.2em") : (undefined)), color:fontHashColor}}>
        {((displayIsBlankTF) ? ("--Blank Option [ID: " + p_value + "]--") : (optionDisplay))}
      </font>
    );

    return(
      <div
        className={((hasMultiSelectOrTreeOrColorRectTF) ? ("displayFlexRow") : ("displayFlexRowVc")) + " " + ((p_unableToHighlightOrClickTF) ? (undefined) : ("cursorPointer"))}
        style={{border:((p_optionsBoxForMobileTF) ? ("solid 1px #ddd") : (undefined)), background:"#" + backgroundColor, padding:optionContainerPadding}}
        ref={((isHighlightedTF) ? (this.ref_selected_option_element) : (null))}
        onMouseOver={((p_unableToHighlightOrClickTF) ? (undefined) : (this.onmouseover_option))}
        onClick={((p_unableToHighlightOrClickTF) ? (undefined) : (this.onclick_option))}>
        {(hasMultiSelectOrTreeOrColorRectTF) ? (
          <>
            {(p_isMultiSelectTF) &&
              <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"1em", marginLeft:((p_isMultiSelectSelectAllOptionTF) ? ("0") : ("0.6em")), marginRight:"0.4em"}}>
                {(!p_unableToHighlightOrClickTF) &&
                  <CheckBox
                    p_u0_s1_p2_du3_ds4={((p_multiSelectIsSelectedTF) ? (1) : ((p_multiSelectIsPartialTF) ? (2) : (0)))}
                    p_sizeEm={1}
                  />
                }
              </div>
            }
            {(hasTreeIDIndentTF) &&
              <DivisionTreeIDIndents p_treeID={p_treeID} />
            }
            {(hasLeftColorRectTF) &&
              <div className="flex00a displayFlexColumnHcVc" style={{marginRight:"0.4em"}}>
                <div
                  className="flex00a border bevelBorderColors"
                  style={{width:"0.75em", height:"1em", background:leftColorBlockBgColorString}}
                />
              </div>
            }
            <div className="flex11a displayFlexRowVc" style={{padding:optionTextContainerPadding}}>
              {optionDisplayFontComponent}
            </div>
          </>
        ) : (
          optionDisplayFontComponent
        )}
      </div>
    );
  }
}


function DivisionTreeIDIndents(props) { //props: p_treeID
  const p_treeID = props.p_treeID; //"00" or "0001" or "0001020003" etc

  const divisionDepth = (Math.round(p_treeID.length / 2) - 1);
  if(divisionDepth === 0) { //treeID "00" at top level has a depth of 0 and no indentation
    return(null);
  }

  const lrMarginWidth = "0.4em";
  const indentNumArray = JSFUNC.array_fill_incrementing_x_to_y(1, divisionDepth);
  return(
    indentNumArray.map((m_indentNum) =>
      <div key={m_indentNum} className="flex00a" style={{flexBasis:lrMarginWidth, marginLeft:lrMarginWidth, borderLeft:"solid 1px #999"}} />
    )
  );
}














//========================================================================================================================================
//Graphical
//========================================================================================================================================

export function SvgHLine(props) { //p_x1p, p_x2p, p_ycp, p_widthP, p_widthEm, p_color
  //p_x1p               percent (decimal) position of line left start (from left edge of <svg> 0 left to 100 right)
  //p_x2p               percent (decimal) position of line right end (from left edge of <svg> 0 left to 100 right)
  //p_ycp               percent (decimal) position of line y centerline (from top edge of <svg> 0 top to 100 bottom)
  //p_widthP/p_widthEm  line thickness in percent or em (rect height)
  //p_color             color of the line (rect fill)
  const widthPercentTrueEmFalse = (props.p_widthP !== undefined);

  var x1p = props.p_x1p;
  var x2p = props.p_x2p;
  if(x2p < x1p) {
    x1p = props.p_x2p;
    x2p = props.p_x1p;
  }

  return(
    <rect
      x={x1p + "%"}
      y={(widthPercentTrueEmFalse) ? ((props.p_ycp - (props.p_widthP / 2)) + "%") : ("calc(" + props.p_ycp + "% - " + (props.p_widthEm / 2) + "em)")}
      width={x2p - x1p + "%"}
      height={(widthPercentTrueEmFalse) ? (props.p_widthP + "%") : (props.p_widthEm + "em")}
      fill={props.p_color}
    />
  );
}

export function SvgVLine(props) { //p_y1p, p_y2p, p_xcp, p_widthP, p_widthEm, p_color
  //p_y1p               percent (decimal) position of line top start (from top edge of <svg> 0 top to 100 bottom)
  //p_y2p               percent (decimal) position of line bottom end (from top edge of <svg> 0 top to 100 bottom)
  //p_xcp               percent (decimal) position of line x centerline (from left edge of <svg> 0 left to 100 right)
  //p_widthP/p_widthEm  line thickness in percent or em (rect height)
  //p_color             color of the line (rect fill)
  const widthPercentTrueEmFalse = (props.p_widthP !== undefined);

  var y1p = props.p_y1p;
  var y2p = props.p_y2p;
  if(y2p < y1p) {
    y1p = props.p_y2p;
    y2p = props.p_y1p;
  }

  return(
    <rect
      x={(widthPercentTrueEmFalse) ? ((props.p_xcp - (props.p_widthP / 2)) + "%") : ("calc(" + props.p_xcp + "% - " + (props.p_widthEm / 2) + "em)")}
      y={y1p + "%"}
      width={(widthPercentTrueEmFalse) ? (props.p_widthP + "%") : (props.p_widthEm + "em")}
      height={y2p - y1p + "%"}
      fill={props.p_color}
    />
  );
}

export function TwoColorDiv(props) { //props: p_bgColor, p_color2, p_color2Percent, p_color2Direction, p_class, p_styleObj, children (text centered in middle
  const bgColor = JSFUNC.prop_value(props.p_bgColor, "#eeeeee");
  const color2 = JSFUNC.prop_value(props.p_color2, "#005da3");
  const color2Percent = JSFUNC.prop_value(props.p_color2Percent, 50);
  const color2Direction = JSFUNC.prop_value(props.p_color2Direction, 90); //0 from top, 90 from left, 180 from bottom, 270 from right
  const classString = JSFUNC.prop_value(props.p_class, "");

  var backgroundString = undefined;
  if(color2Percent <= 0) {
    backgroundString = bgColor;
  }
  else if(color2Percent < 100) {
    backgroundString = "linear-gradient(" + color2Direction + "deg, " + color2 + " " + color2Percent + "%, " + bgColor + " " + color2Percent + "%)";
  }
  else {
    backgroundString = color2;
  }

  const styleObj = JSFUNC.merge_objs({background:backgroundString}, props.p_styleObj);

  return(
    <div className={classString} style={styleObj}>
      {props.children}
    </div>
  );
}
















//--------------------------------------------------------------------------------------------------------------------------------

export function CircleGraph(props) { //props:
  //p_categoriesArrayOfObjs, p_heightPx, p_titlesTF, p_legendTF, p_legendTitle, p_legendTotalValueLabel, p_legendTotalClickReturnValue, p_legendHideZeroCategoriesTF, p_fontSizePx, p_vacantLabel, p_vacantColor, p_maxNumDecimals, p_innerCircleSize0to1, p_innerCircleColor, p_innerCircleTotalLabelTF,
  //f_onClickSegment
  //
  //p_categoriesArrayOfObjs:
  //  - numItems
  //  - percent0to100     if percent0to100 is used instead of valueRaw, it is possible to have vacant space or to have >100%
  //  - valueRaw          if valueRaw is used instead of percent0to100, all 100% of the circle graph is split based on the each category's value divided by the total for all categories (impossible to have vacant space)
  //  - valueMaskPlainText
  //  - color
  //  - label
  //  - clickReturnValue

  const p_categoriesArrayOfObjs = JSFUNC.prop_value(props.p_categoriesArrayOfObjs, []);
  const p_heightPx = JSFUNC.prop_value(props.p_heightPx, 150);
  const p_titlesTF = JSFUNC.prop_value(props.p_titlesTF, true);
  const p_legendTF = JSFUNC.prop_value(props.p_legendTF, true);
  const p_legendTitle = JSFUNC.prop_value(props.p_legendTitle, true);
  const p_legendTotalValueLabel = props.p_legendTotalValueLabel;
  const p_legendTotalClickReturnValue = props.p_legendTotalClickReturnValue;
  const p_legendHideZeroCategoriesTF = JSFUNC.prop_value(props.p_legendHideZeroCategoriesTF, false);
  const p_fontSizePx = JSFUNC.prop_value(props.p_fontSizePx, 12);
  const vacantLabel = JSFUNC.prop_value(props.p_vacantLabel, "Vacant"); //label for any remaining circle graph if the individual pieces do not add up to 100% or more
  const vacantColor = JSFUNC.prop_value(props.p_vacantColor, "333333");
  const p_maxNumDecimals = JSFUNC.input_number_invalid_default_with_min_max_bounds(props.p_maxNumDecimals, 2, 0, 3); //bound the maximum number of decimal places shown for percents to between 0 and 3 to prevent overflow
  var innerCircleSize0to1 = props.p_innerCircleSize0to1;
  const innerCircleColor = JSFUNC.prop_value(props.p_innerCircleColor, "dddddd");
  const innerCircleTotalLabelTF = JSFUNC.prop_value(props.p_innerCircleTotalLabelTF, false);

  //determine the radius of the circle graph (diameter is equal to the given height of the entire graph + legend)
  const circleRadius0to100 = 50;

  //compute total sum of all category values, also determine if categories are using the valueMaskPlainText field
  var totalValueAllCategories = 0;
  var includeValueLabelsTF = false; //true if any of the categoryObjs have the valueMaskPlainText field
  for(let categoryObj of p_categoriesArrayOfObjs) {
    if(JSFUNC.is_number_not_nan(categoryObj.valueRaw)) {
      totalValueAllCategories += categoryObj.valueRaw;
    }

    if(categoryObj.valueMaskPlainText !== undefined) {
      includeValueLabelsTF = true;
    }
  }

  //create a copy of the p_categoriesArrayOfObjs to use internally, compute start and end percent values for each given segment
  var internalCategoriesArrayOfObjs = [];
  var startPercent = 0; //start and end percent values determine the wedges to draw on the graph
  var endPercent = 0;
  var totalPercent = 0; //compute sum of all input percentage values to see if they add up to 100% or if the sum is < or > 100, trim decimals, create the title strings
  for(let categoryObj of p_categoriesArrayOfObjs) {
    //give default values if the categoryObj had required fields undefined
    var numItems = JSFUNC.prop_value(categoryObj.numItems, 0);
    var percent0to100 = categoryObj.percent0to100; //optional to be left undefined which will instead use valueRaw to compute the percents
    var valueRaw = JSFUNC.prop_value(categoryObj.valueRaw, 0); //default value of 0 if none was specified
    var valueMaskPlainText = categoryObj.valueMaskPlainText;
    var color = JSFUNC.prop_value(categoryObj.color, "999999"); //default color if none was specified for this category
    var label = JSFUNC.prop_value(categoryObj.label, "No Category Label Specified"); //default label
    var clickReturnValue = categoryObj.clickReturnValue;

    var computedPercent0to100 = 0;
    if(JSFUNC.is_number(percent0to100)) { //using percent0to100 input
      computedPercent0to100 = percent0to100;
    }
    else { //using valueRaw input
      if(totalValueAllCategories > 0) { //avoid divide by 0, when all categories are 0, then each percent is 0
        computedPercent0to100 = ((valueRaw / totalValueAllCategories) * 100);
      }
    }

    //compute other fields used internally
    var trimmedDecimalPercentString = JSFUNC.round_number_to_num_decimals_if_needed(computedPercent0to100, p_maxNumDecimals) + "%";
    var title = undefined;
    if(p_titlesTF) {
      title = "";
      if(includeValueLabelsTF) {
        title = valueMaskPlainText + " ";
      }
      title += "(" + trimmedDecimalPercentString + ") '" + label + "'";
    }

    //compute the start and end percent
    endPercent += computedPercent0to100; //this percent extends the length of the given input percent from start to end
    if(endPercent > 100) { //if the end percent is over 100%, cap it at 100
      endPercent = 100;
    }

    //compute the total percent
    totalPercent += computedPercent0to100;

    //create the internal p_categoriesArrayOfObjs
    internalCategoriesArrayOfObjs.push({
      numItems: numItems,
      percent0to100: computedPercent0to100,
      color: color,
      label: label,
      valueMaskPlainText: valueMaskPlainText,
      clickReturnValue: clickReturnValue,
      trimmedDecimalPercentString: trimmedDecimalPercentString,
      title: title,
      isVacantTF: false,
      startPercent: startPercent,
      endPercent: endPercent
    });

    startPercent = endPercent; //next percent starts at the end of the previous one
  }

  //adjust the total sum against a decimal tolerance for 100%
  const decimalTolerance = 0.001;
  if((totalPercent > (100 - decimalTolerance)) && (totalPercent < (100 + decimalTolerance))) {
    totalPercent = 100;
  }

  const trimmedDecimalTotalPercentString = JSFUNC.round_number_to_num_decimals_if_needed(totalPercent, p_maxNumDecimals) + "%";

  //compute the vacant percent against the total percent being 100%, if the vacant is nonzero, create a new vacant category
  const vacantPercent = (100 - totalPercent);
  if(vacantPercent > 0) {
    var vacantTrimmedDecimalPercentString = JSFUNC.round_number_to_num_decimals_if_needed(vacantPercent, p_maxNumDecimals) + "%";

    internalCategoriesArrayOfObjs.push({
      numItems: 1,
      percent0to100: vacantPercent,
      color: vacantColor,
      label: vacantLabel,
      valueMaskPlainText: "",
      clickReturnValue: undefined,
      trimmedDecimalPercentString: vacantTrimmedDecimalPercentString,
      title: ((p_titlesTF) ? ("(" + vacantTrimmedDecimalPercentString + ") '" + vacantLabel + "'") : (undefined)),
      isVacantTF: true,
      startPercent: totalPercent,
      endPercent: 100
    });
  }

  //bound the inner circle size to 0 to 1 if specified
  const drawInnerCircleTF = (JSFUNC.is_number(innerCircleSize0to1) && (innerCircleSize0to1 > 0) && (innerCircleSize0to1 < 1));
  var innerCircleRadius0to100 = undefined;
  var totalFontColor = undefined; //regular font color for 100% total
  var totalFontWeight = undefined;
  if(drawInnerCircleTF) { //total label inside of the inner circle
    innerCircleRadius0to100 = innerCircleSize0to1 * circleRadius0to100;
    if(totalPercent < 100) {
      totalFontColor = "#005da3"; //blue for <100%
    }
    else if(totalPercent > 100) {
      totalFontColor = "#bd2326"; //red for >100%
      totalFontWeight = "bold";
    }
  }

  //div positioning for having the legend
  var graphDivFlexClass = undefined;
  var graphDivFlexBasis = undefined;
  var graphDivMaxWidth = undefined;
  if(p_legendTF) {
    graphDivFlexClass = "flex00a";
    graphDivFlexBasis = p_heightPx + "px";
    graphDivMaxWidth = "50%"; //circle graph takes up at max 50% of the total container width on the left side, legend takes up rest of the space on the right with its text left aligned
  }
  else {
    graphDivFlexClass = "flex11a";
  }

  return(
    <div className="displayFlexRow" style={{height:p_heightPx + "px"}}>
      <div className={"positionRelative " + graphDivFlexClass} style={{flexBasis:graphDivFlexBasis, maxWidth:graphDivMaxWidth}}>
        <svg width="100%" height="100%" viewBox="0 0 100 100">
          {internalCategoriesArrayOfObjs.map((m_internalCategoryObj) =>
            <CircleGraphSegment
              p_internalCategoryObj={m_internalCategoryObj}
              p_radius0to100={circleRadius0to100}
              f_onClickSegment={props.f_onClickSegment}
            />
          )}
          <circle r={circleRadius0to100 - 0.25} cx={circleRadius0to100} cy={circleRadius0to100} stroke="#bbb" strokeWidth="0.5" style={{fill:"none"}} />
          <circle r={circleRadius0to100 - 0.125} cx={circleRadius0to100} cy={circleRadius0to100} stroke="#888" strokeWidth="0.25" style={{fill:"none"}} />
          {(drawInnerCircleTF) &&
            <circle r={innerCircleRadius0to100} cx={circleRadius0to100} cy={circleRadius0to100} style={{fill:"#" + innerCircleColor}} />
          }
        </svg>
        {(drawInnerCircleTF && innerCircleTotalLabelTF) &&
          <div className="positionAbsolute displayFlexColumnVc textCenter pointerEventsNone" style={{top:"0", left:"0", height:"100%", width:"100%"}}>
            <div className="textCenter">
              <font className="fontItalic" style={{fontSize:"1.1em"}}>
                {"Total:"}
              </font>
            </div>
            <div>
              <Nowrap p_styleObj={{fontSize:"1.4em", fontWeight:totalFontWeight, color:totalFontColor}}>
                {trimmedDecimalTotalPercentString}
              </Nowrap>
            </div>
          </div>
        }
      </div>
      {(p_legendTF) &&
        <div className="flex11a displayFlexColumn" style={{marginLeft:"0.3em"}}>
          <BarGraphLegend
            p_internalCategoriesArrayOfObjs={internalCategoriesArrayOfObjs}
            p_includeValueLabelsTF={includeValueLabelsTF}
            p_legendTitle={p_legendTitle}
            p_legendTotalValueLabel={p_legendTotalValueLabel}
            p_totalTrimmedDecimalPercentString={trimmedDecimalTotalPercentString}
            p_totalClickReturnValue={p_legendTotalClickReturnValue}
            p_hideZeroCategoriesTF={p_legendHideZeroCategoriesTF}
            p_fontSizePx={p_fontSizePx}
            f_onClickSegment={props.f_onClickSegment}
          />
        </div>
      }
    </div>
  );
}


class CircleGraphSegment extends Component { //props: p_internalCategoryObj, p_radius0to100, f_onClickSegment
  onclick_segment = () => {
    if(this.props.f_onClickSegment) {
      const internalCategoryObj = this.props.p_internalCategoryObj;
      if(internalCategoryObj.clickReturnValue !== undefined) {
        this.props.f_onClickSegment(internalCategoryObj.clickReturnValue, internalCategoryObj.label);
      }
    }
  }

  render() {
    const internalCategoryObj = this.props.p_internalCategoryObj;
    const radius0to100 = this.props.p_radius0to100;

    const startPercent0to100 = internalCategoryObj.startPercent;
    const endPercent0to100 = internalCategoryObj.endPercent;
    const color = internalCategoryObj.color;
    const title = internalCategoryObj.title;
    const isVacantTF = internalCategoryObj.isVacantTF;

    //do not draw a segment with 0 or negative width
    if(startPercent0to100 >= endPercent0to100) {
      return(null);
    }

    const startAngleDeg = (startPercent0to100 * 3.6); //(percent/100) * 360deg
    const endAngleDeg = (endPercent0to100 * 3.6);
    const totalAngleDeg = (endAngleDeg - startAngleDeg);

    const functionClickSegment = ((this.props.f_onClickSegment && (!isVacantTF)) ? (this.onclick_segment) : (undefined));

    if(totalAngleDeg >= 360) { //full circle for 360
      return(
        <g>
          <title>{title}</title>
          <circle r={radius0to100} cx={radius0to100} cy={radius0to100} style={{fill:"#" + color, cursor:((functionClickSegment) ? ("pointer") : (undefined))}} onClick={functionClickSegment} />
        </g>
      );
    }

    if(totalAngleDeg > 270) { //3 segments overlapping to avoid gap between segments
      return(
        <>
          <CircleGraphSegment0to180deg p_radius0to100={radius0to100} p_angleDeg={180} p_rotationDeg={startAngleDeg} p_color={color} p_title={title} f_onClickSegment={functionClickSegment} />
          <CircleGraphSegment0to180deg p_radius0to100={radius0to100} p_angleDeg={180} p_rotationDeg={(startAngleDeg + 90)} p_color={color} p_title={title} f_onClickSegment={functionClickSegment} />
          <CircleGraphSegment0to180deg p_radius0to100={radius0to100} p_angleDeg={(totalAngleDeg - 180)} p_rotationDeg={(startAngleDeg + 180)} p_color={color} p_title={title} f_onClickSegment={functionClickSegment} />
        </>
      );
    }

    if(totalAngleDeg > 180) {
      return(
        <>
          <CircleGraphSegment0to180deg p_radius0to100={radius0to100} p_angleDeg={180} p_rotationDeg={startAngleDeg} p_color={color} p_title={title} f_onClickSegment={functionClickSegment} />
          <CircleGraphSegment0to180deg p_radius0to100={radius0to100} p_angleDeg={(totalAngleDeg - 90)} p_rotationDeg={(startAngleDeg + 90)} p_color={color} p_title={title} f_onClickSegment={functionClickSegment} />
        </>
      );
    }

    return(
      <CircleGraphSegment0to180deg p_radius0to100={radius0to100} p_angleDeg={totalAngleDeg} p_rotationDeg={startAngleDeg} p_color={color} p_title={title} f_onClickSegment={functionClickSegment} />
    );
  }
}


export function CircleGraphSegment0to180deg(props) { //props: p_radius0to100, p_angleDeg, p_rotationDeg, p_color, p_title, f_onClickSegment
  const radius0to100 = props.p_radius0to100;
  const angleDeg = props.p_angleDeg;
  const rotationDeg = props.p_rotationDeg;
  const color = props.p_color;
  const title = props.p_title;

  const xPx = radius0to100 + (Math.sin(angleDeg * 0.0174532925) * radius0to100); //0.0174532925 is pi/180 to convert deg to rad
  const yPx = radius0to100 - (Math.cos(angleDeg * 0.0174532925) * radius0to100);
  const dString = "M" + radius0to100 + "," + radius0to100 + " L" + radius0to100 + ",0 A" + radius0to100 + "," + radius0to100 + " 1 0,1 " + xPx + ", " + yPx + " z";

  var transformString = undefined;
  if(rotationDeg > 0) {
    transformString = "rotate(" + rotationDeg + ", " + radius0to100 + ", " + radius0to100 + ")";
  }

  const pathSvgComponent = (
    <path
      d={dString}
      transform={transformString}
      fill={"#" + color}
      style={{cursor:((props.f_onClickSegment) ? ("pointer") : (undefined))}}
      onClick={props.f_onClickSegment}
    />
  );

  if(title !== undefined) {
    return(
      <g>
        <title>{title}</title>
        {pathSvgComponent}
      </g>
    );
  }

  return(
    pathSvgComponent
  );
}







//--------------------------------------------------------------------------------------------------------------------------------

export function BarGraph(props) { //props:
  //p_categoriesArrayOfObjs, p_yLogScaleTF, p_yAxisWidthEm, p_horizontalTF, p_mirroredTF, p_valueFormat, p_heightPx, p_titlesTF, p_legendTF, p_legendTitle, p_legendTotalValueLabel, p_legendTotalClickReturnValue, p_legendHideZeroCategoriesTF, p_fontSizePx, p_maxNumDecimals,
  //f_onClickSegment
  //
  //p_categoriesArrayOfObjs:
  //  - numItems
  //  - valueRaw
  //  - valueMaskPlainText
  //  - color
  //  - label
  //  - clickReturnValue

  const p_categoriesArrayOfObjs = JSFUNC.prop_value(props.p_categoriesArrayOfObjs, []);
  const p_yLogScaleTF = JSFUNC.prop_value(props.p_yLogScaleTF, false);
  const p_yAxisWidthEm = props.p_yAxisWidthEm;
  const p_horizontalTF = JSFUNC.prop_value(props.p_horizontalTF, false); //false - vertical bars from the bottom, true - horizontal bars from the left edge
  const p_mirroredTF = JSFUNC.prop_value(props.p_mirroredTF, false); //false - bars extend from edge to value, true - bars extend both directions from center line out to value (creating mirrored funnel graph)
  const p_valueFormat = JSFUNC.prop_value(props.p_valueFormat, "number"); //"number", "money", "moneyShort"
  const p_heightPx = JSFUNC.prop_value(props.p_heightPx, 150);
  const p_titlesTF = JSFUNC.prop_value(props.p_titlesTF, true);
  const p_legendTF = JSFUNC.prop_value(props.p_legendTF, true);
  const p_legendTitle = props.p_legendTitle;
  const p_legendTotalValueLabel = props.p_legendTotalValueLabel;
  const p_legendTotalClickReturnValue = props.p_legendTotalClickReturnValue;
  const p_legendHideZeroCategoriesTF = JSFUNC.prop_value(props.p_legendHideZeroCategoriesTF, false);
  const p_fontSizePx = JSFUNC.prop_value(props.p_fontSizePx, 12);
  const p_maxNumDecimals = JSFUNC.input_number_invalid_default_with_min_max_bounds(props.p_maxNumDecimals, 2, 0, 3); //bound the maximum number of decimal places shown for percents to between 0 and 3 to prevent overflow

  var yAxisWidthEm = p_yAxisWidthEm;
  if(p_yAxisWidthEm === undefined) {
    yAxisWidthEm = ((p_horizontalTF) ? (0.5) : (5));
  }

  const xAxisHeight = ((p_horizontalTF) ? ("1.5em") : ("0.5em"));
  const gridLineWidthPx = 1;
  const yHorizontalGridLineHashColor = "#ccc"

  //compute the number of categories
  const numCategories = p_categoriesArrayOfObjs.length;

  //compute max data y value and total sum of all category values, also determine if categories are using the valueMaskPlainText field
  var dataMaxYValue = 0;
  var dataMinValueAbove0 = 0;
  var totalValueAllCategories = 0;
  var includeValueLabelsTF = false; //true if any of the categoryObjs have the valueMaskPlainText field
  for(let categoryObj of p_categoriesArrayOfObjs) {
    var valueRaw = categoryObj.valueRaw;

    if(valueRaw > dataMaxYValue) {
      dataMaxYValue = valueRaw;
    }

    if((valueRaw > 0) && ((dataMinValueAbove0 === 0) || (valueRaw < dataMinValueAbove0))) {
      dataMinValueAbove0 = valueRaw;
    }

    totalValueAllCategories += valueRaw;

    if(categoryObj.valueMaskPlainText !== undefined) {
      includeValueLabelsTF = true;
    }
  }

  //compute the y axis ticks
  var yxAxisFakeHeightPx = p_heightPx;
  if(p_horizontalTF) {
    if(p_mirroredTF) {
      yxAxisFakeHeightPx = (p_heightPx / 4);
    }
    else {
      yxAxisFakeHeightPx = (p_heightPx / 2);
    }
  }
  const reversePos100to0TF = (!p_horizontalTF); //reverse pos values for y axis ticks
  const yAxisObj = JSFUNC.create_axis_obj_from_max_value_and_height_px(0, dataMaxYValue, yxAxisFakeHeightPx, reversePos100to0TF, p_valueFormat, p_yLogScaleTF, 1.09, dataMinValueAbove0);

  //compute a copy of p_categoriesArrayOfObjs with more data for this graph
  var internalCategoriesArrayOfObjs = [];
  var totalPercent = 0; //compute sum of all input percentage values to see if they add up to 100% or if the sum is < or > 100, trim decimals, create the title strings
  for(let c = 0; c < numCategories; c++) {
    var categoryObj = p_categoriesArrayOfObjs[c];

    //give default values if the categoryObj had required fields undefined
    var numItems = JSFUNC.prop_value(categoryObj.numItems, 0); //default value of 0 if none was specified
    var valueRaw = JSFUNC.prop_value(categoryObj.valueRaw, 0); //default value of 0 if none was specified
    var valueMaskPlainText = categoryObj.valueMaskPlainText;
    var color = JSFUNC.prop_value(categoryObj.color, "999999"); //default color if none was specified for this category
    var label = JSFUNC.prop_value(categoryObj.label, "No Category Label Specified"); //default label
    var clickReturnValue = categoryObj.clickReturnValue;

    var percent0to100 = 0;
    if(totalValueAllCategories > 0) { //avoid divide by 0, when all categories are 0, then each percent is 0
      percent0to100 = ((valueRaw / totalValueAllCategories) * 100);
    }

    //compute other fields used internally
    var trimmedDecimalPercentString = JSFUNC.round_number_to_num_decimals_if_needed(percent0to100, p_maxNumDecimals) + "%";
    var title = ((p_titlesTF) ? (((includeValueLabelsTF) ? (valueMaskPlainText + " ") : ("")) + "(" + trimmedDecimalPercentString + ") '" + label + "'") : (undefined));

    //compute the total percent
    totalPercent += percent0to100;

    //compute the bar x/y start/end positions in the svg
    var xWidthPercent = undefined;
    var xStartPos = undefined;
    var yStartPos = undefined;
    var yHeightPercent = undefined;
    if(p_horizontalTF) {
      yHeightPercent = (100 / numCategories);
      yStartPos = (c * yHeightPercent);
      xWidthPercent = JSFUNC.compute_svg_pos_0to100_from_value_and_axis_obj(valueRaw, yAxisObj);
      xStartPos = ((p_mirroredTF) ? ((100 - xWidthPercent) / 2) : (0));
    }
    else {
      xWidthPercent = (100 / numCategories);
      xStartPos = (c * xWidthPercent);
      yStartPos = JSFUNC.compute_svg_pos_0to100_from_value_and_axis_obj(valueRaw, yAxisObj);
      yHeightPercent = (100 - yStartPos);
    }


    //create the internal p_categoriesArrayOfObjs
    internalCategoriesArrayOfObjs.push({
      numItems: numItems,
      valueMaskPlainText: valueMaskPlainText,
      color: color,
      label: label,
      clickReturnValue: clickReturnValue,
      trimmedDecimalPercentString: trimmedDecimalPercentString,
      title: title,
      xStartPos: xStartPos,
      xWidthPercent: xWidthPercent,
      yStartPos: yStartPos,
      yHeightPercent: yHeightPercent
    });
  }

  var graphComponent = null;
  if(!p_horizontalTF) {
    graphComponent = (
      <>
        <div className="flex11a">
          <svg width="100%" height="100%">
            <YAxisTicksHorizontalLines p_yAxisTicksArrayOfObjs={yAxisObj.axisTicksArrayOfObjs} p_gridLineWidthPx={gridLineWidthPx} p_gridLineColor={yHorizontalGridLineHashColor} />
            {internalCategoriesArrayOfObjs.map((m_internalCategoryObj) =>
              <BarGraphBar p_internalCategoryObj={m_internalCategoryObj} f_onClick={props.f_onClickSegment} />
            )}
          </svg>
        </div>
        <div className="flex00a" style={{flexBasis:xAxisHeight}} />
      </>
    );
  }
  else {
    if(!p_mirroredTF) {
      graphComponent = (
        <>
          <div className="flex11a">
            <svg width="100%" height="100%">
              <XAxisTicksVerticalLines p_yAxisTicksArrayOfObjs={yAxisObj.axisTicksArrayOfObjs} p_gridLineWidthPx={gridLineWidthPx} p_gridLineColor={yHorizontalGridLineHashColor} />
              {internalCategoriesArrayOfObjs.map((m_internalCategoryObj) =>
                <BarGraphBar p_internalCategoryObj={m_internalCategoryObj} f_onClick={props.f_onClickSegment} />
              )}
            </svg>
          </div>
          <div className="flex00a displayFlexColumn" style={{flexBasis:xAxisHeight}}>
            <XAxisWithLabelsBottomRow p_xAxisTicksArrayOfObjs={yAxisObj.axisTicksArrayOfObjs} />
          </div>
        </>
      );
    }
    else {
      graphComponent = (
        <>
          <div className="flex11a">
            <svg width="100%" height="100%">
              {yAxisObj.axisTicksArrayOfObjs.map((m_xTickObj) =>
                <line x1={(50 + (m_xTickObj.svgPos0to100 / 2)) + "%"} y1="0%" x2={(50 + (m_xTickObj.svgPos0to100 / 2)) + "%"} y2="100%" style={{stroke:yHorizontalGridLineHashColor, strokeWidth:gridLineWidthPx + "px"}} />
              )}
              {internalCategoriesArrayOfObjs.map((m_internalCategoryObj) =>
                <BarGraphBar p_internalCategoryObj={m_internalCategoryObj} f_onClick={props.f_onClickSegment} />
              )}
            </svg>
          </div>
          <div className="flex00a displayFlexRow" style={{flexBasis:xAxisHeight}}>
            <div className="flex11a" style={{flexBasis:"100em"}} />
            <div className="flex11a displayFlexColumn" style={{flexBasis:"100em"}}>
              <XAxisWithLabelsBottomRow p_xAxisTicksArrayOfObjs={yAxisObj.axisTicksArrayOfObjs} />
            </div>
          </div>
        </>
      );
    }
  }

  return(
    <div className="displayFlexRow" style={{height:p_heightPx + "px"}}>
      <div className="flex00a displayFlexColumn" style={{flexBasis:yAxisWidthEm + "em"}}>
        {(!p_horizontalTF) &&
          <YAxisWithLabelsLeftColumn
            p_yAxisTicksArrayOfObjs={yAxisObj.axisTicksArrayOfObjs}
            p_yAxisWidthEm={yAxisWidthEm}
          />
        }
        <div className="flex00a" style={{flexBasis:xAxisHeight}} />
      </div>
      <div className="flex11a displayFlexColumn" style={{flexBasis:"150em"}}>
        {graphComponent}
      </div>
      {(p_legendTF) &&
        <div className="flex11a displayFlexColumn smallFullPad" style={{flexBasis:"100em", minWidth:"10em", maxWidth:"40em", marginLeft:"0.3em"}}>
          <BarGraphLegend
            p_internalCategoriesArrayOfObjs={internalCategoriesArrayOfObjs}
            p_includeValueLabelsTF={includeValueLabelsTF}
            p_legendTitle={p_legendTitle}
            p_legendTotalValueLabel={p_legendTotalValueLabel}
            p_totalTrimmedDecimalPercentString="100%"
            p_totalClickReturnValue={p_legendTotalClickReturnValue}
            p_hideZeroCategoriesTF={p_legendHideZeroCategoriesTF}
            p_fontSizePx={p_fontSizePx}
            f_onClickSegment={props.f_onClickSegment}
          />
        </div>
      }
    </div>
  );
}



class BarGraphBar extends Component { //props: p_internalCategoryObj, f_onClick
  onclick_bar_graph_bar = () => {
    const internalCategoryObj = this.props.p_internalCategoryObj;
    this.props.f_onClick(internalCategoryObj.clickReturnValue, internalCategoryObj.label);
  }

  render() {
    const internalCategoryObj = this.props.p_internalCategoryObj;
    const canClickTF = JSFUNC.is_function(this.props.f_onClick);

    const barSvgComponent = (
      <rect
        x={internalCategoryObj.xStartPos + "%"}
        y={internalCategoryObj.yStartPos + "%"}
        width={internalCategoryObj.xWidthPercent + "%"}
        height={internalCategoryObj.yHeightPercent + "%"}
        fill={"#" + internalCategoryObj.color}
        stroke="#aaa"
        strokeWidth="1px"
        style={{cursor:((canClickTF) ? ("pointer") : (undefined))}}
        onClick={((canClickTF) ? (this.onclick_bar_graph_bar) : (undefined))}
      />
    );

    if(internalCategoryObj.title !== undefined) {
      return(
        <g>
          <title>{internalCategoryObj.title}</title>
          {barSvgComponent}
        </g>
      );
    }

    return(
      barSvgComponent
    );
  }
}








function BarGraphLegend(props) { //props: p_internalCategoriesArrayOfObjs, p_includeValueLabelsTF, p_legendTitle, p_legendTotalValueLabel, p_totalTrimmedDecimalPercentString, p_totalClickReturnValue, p_hideZeroCategoriesTF, p_fontSizePx, f_onClickSegment
  //p_internalCategoriesArrayOfObjs
  //  - numItems                    (used for determining if p_hideZeroCategoriesTF should hide the category)
  //  - valueMaskPlainText
  //  - label
  //  - color
  //  - clickReturnValue
  //  - trimmedDecimalPercentString
  //  - title
  //  - xStartPos, xWidthPercent, yStartPos, yHeightPercent

  const p_internalCategoriesArrayOfObjs = props.p_internalCategoriesArrayOfObjs;
  const p_includeValueLabelsTF = JSFUNC.prop_value(props.p_includeValueLabelsTF, true);
  const p_legendTitle = props.p_legendTitle;
  const p_legendTotalValueLabel = props.p_legendTotalValueLabel;
  const p_totalTrimmedDecimalPercentString = props.p_totalTrimmedDecimalPercentString;
  const p_totalClickReturnValue = props.p_totalClickReturnValue;
  const p_hideZeroCategoriesTF = JSFUNC.prop_value(props.p_hideZeroCategoriesTF, false);
  const p_fontSizePx = JSFUNC.prop_value(props.p_fontSizePx, 12);

  const numCategories = p_internalCategoriesArrayOfObjs.length;
  const legendTotalRowTF = ((p_legendTotalValueLabel !== undefined) && p_includeValueLabelsTF && (numCategories > 1));
  const totalRowHeightPx = Math.round(1.4 * p_fontSizePx);
  const categoryRowHeightPx = Math.round(1.15 * p_fontSizePx);

  var categoriesWithTotalArrayOfObjs = [];
  if(legendTotalRowTF) {
    categoriesWithTotalArrayOfObjs.push({
      numItems: 1,
      valueMaskPlainText: p_legendTotalValueLabel,
      label: "Total",
      color: "666666",
      clickReturnValue: p_totalClickReturnValue,
      trimmedDecimalPercentString: p_totalTrimmedDecimalPercentString,
      title: p_legendTotalValueLabel + " (" + p_totalTrimmedDecimalPercentString + ") 'Total'",
      totalRowTF: true
    });
  }
  for(let internalCategoryObj of p_internalCategoriesArrayOfObjs) {
    var categoryWithTotalObj = JSFUNC.copy_obj(internalCategoryObj);
    categoryWithTotalObj.totalRowTF = false;
    categoriesWithTotalArrayOfObjs.push(categoryWithTotalObj);
  }

  var valueLabelsColumnWidthPx = 0;
  if(p_includeValueLabelsTF) {
    const valueLabelsMaxNumChars = JSFUNC.max_string_num_chars_from_arrayOfObjs_column(categoriesWithTotalArrayOfObjs, "valueMaskPlainText", 2);
    valueLabelsColumnWidthPx = Math.round(valueLabelsMaxNumChars * 0.7 * p_fontSizePx); //char widths estimated 0.7 char height
  }

  const percentsMaxNumChars = JSFUNC.max_string_num_chars_from_arrayOfObjs_column(categoriesWithTotalArrayOfObjs, "trimmedDecimalPercentString", 2);
  const percentsColumnWidthPx = Math.round(percentsMaxNumChars * 0.7 * p_fontSizePx); //char widths estimated 0.7 char height

  const colorBlockWidthPx  = Math.round(0.85 * p_fontSizePx);
  const colorBlockHeightPx  = Math.round(1.1 * p_fontSizePx);
  const colorBlockContainerWidthPx = Math.round(colorBlockWidthPx + (0.4 * p_fontSizePx)); //0.2em padding on each side of the color block

  return(
    <div className="flex11a yScroll" style={{fontSize:p_fontSizePx + "px"}}>
      {(p_legendTitle !== undefined) &&
        <div style={{marginBottom:"0.1em"}} title={p_legendTitle}>
          <Nowrap p_fontClass="fontBold">
            {p_legendTitle}
          </Nowrap>
        </div>
      }
      {categoriesWithTotalArrayOfObjs.map((m_categoryWithTotalObj) =>
        (!p_hideZeroCategoriesTF || m_categoryWithTotalObj.totalRowTF || (m_categoryWithTotalObj.numItems > 0)) &&
        <BarGraphLegendCategoryRow
          p_internalCategoryObj={m_categoryWithTotalObj}
          p_heightPx={((m_categoryWithTotalObj.totalRowTF) ? (totalRowHeightPx) : (categoryRowHeightPx))}
          p_totalRowTF={m_categoryWithTotalObj.totalRowTF}
          f_onClick={props.f_onClickSegment}>
          {(p_includeValueLabelsTF) &&
            <div className="flex00a displayFlexColumnVc" style={{flexBasis:valueLabelsColumnWidthPx + "px", padding:"0 0.2em"}}>
              <font className="fontItalic">
                {m_categoryWithTotalObj.valueMaskPlainText}
              </font>
            </div>
          }
          <div className="flex00a displayFlexColumnVc" style={{flexBasis:percentsColumnWidthPx + "px", padding:"0 0.2em"}}>
            <font className="">
              {m_categoryWithTotalObj.trimmedDecimalPercentString}
            </font>
          </div>
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:colorBlockContainerWidthPx + "px"}}>
            {(!m_categoryWithTotalObj.totalRowTF) &&
              <div className="flex00a border bevelBorderColors" style={{width:colorBlockWidthPx + "px", height:colorBlockHeightPx + "px", background:"#" + m_categoryWithTotalObj.color}} />
            }
          </div>
          <div className="flex11a displayFlexColumnVc" style={{padding:"0 0.2em"}}>
            <Nowrap p_fontClass="">
              {m_categoryWithTotalObj.label}
            </Nowrap>
          </div>
        </BarGraphLegendCategoryRow>
      )}
    </div>
  );
}

class BarGraphLegendCategoryRow extends Component { //props: p_internalCategoryObj, p_heightPx, p_totalRowTF, f_onClick, children
  onclick_legend_piece = () => {
    const p_internalCategoryObj = this.props.p_internalCategoryObj;
    if(JSFUNC.is_function(this.props.f_onClick)) {
      this.props.f_onClick(p_internalCategoryObj.clickReturnValue, p_internalCategoryObj.label);
    }
  }

  render() {
    const p_internalCategoryObj = this.props.p_internalCategoryObj;
    const p_heightPx = this.props.p_heightPx;
    const p_totalRowTF = this.props.p_totalRowTF;

    const canClickTF = (JSFUNC.is_function(this.props.f_onClick) && (p_internalCategoryObj.clickReturnValue !== undefined));

    var borderTB = undefined;
    var bgHashColor = undefined;
    var bgFontHashColor = undefined;
    if(p_totalRowTF) {
      borderTB = "solid 1px #ddd";
      bgHashColor = "linear-gradient(#4abd8b, #00a35d)";
      bgFontHashColor = "#fff";
    }

    return(
      <div
        className={"displayFlexRow hoverLighterBlueGradient " + ((canClickTF) ? ("cursorPointer") : (""))}
        style={{height:p_heightPx + "px", borderTop:borderTB, borderBottom:borderTB, background:bgHashColor, color:bgFontHashColor}}
        title={p_internalCategoryObj.title}
        onClick={((canClickTF) ? (this.onclick_legend_piece) : (undefined))}>
        {this.props.children}
      </div>
    );
  }
}







//--------------------------------------------------------------------------------------------------------------------------------


export function ScatterPlot(props) {
  //props:
  //  p_dataObjOrDataArrayOfObjs, p_xMin, p_xMax, p_xValueFormat, p_xLabel, p_yMin, p_yMax, p_yValueFormat, p_yLabel, p_yLogScaleTF, p_zSizePercentOfHeight, p_zMin, p_zMax, p_dataAverageVerticalLinesTF,
  //  p_title, p_heightPx, p_fontSizePx, p_legendTF, f_onClickDot
  //
  //dataObj:
  //  - xyPointsArrayOfObjs
  //    - x
  //    - y
  //    - title (optional)
  //    - clickReturnValue (optional)
  //  - label
  //  - color

  const dataArrayOfObjs = JSFUNC.convert_single_or_array_to_array(props.p_dataObjOrDataArrayOfObjs);
  var xMin = props.p_xMin;
  var xMax = props.p_xMax;
  const xValueFormat = JSFUNC.prop_value(props.p_xValueFormat, "number"); //"number", "percent", "moneyShort"
  const xLabel = JSFUNC.prop_value(props.p_xLabel, "");
  var yMin = props.p_yMin;
  var yMax = props.p_yMax;
  const yValueFormat = JSFUNC.prop_value(props.p_yValueFormat, "number"); //"number", "percent", "moneyShort"
  const yLabel = JSFUNC.prop_value(props.p_yLabel, "");
  const p_yLogScaleTF = JSFUNC.prop_value(props.p_yLogScaleTF, false);
  const zSizePercentOfHeight = JSFUNC.prop_value(props.p_zSizePercentOfHeight, 3); //each xyPointObj contains a field 'z' that scales the relative size of each dot
  const zMin = props.p_zMin; //if defined, each xyPointObj contains a field 'z' that scales the relative size of each dot, this being the minimum size as a percent (0-100) relative to total graph height of the smallest z in the dataset
  const zMax = props.p_zMax; //if defined, each xyPointObj contains a field 'z' that scales the relative size of each dot, this being the maximum size as a percent (0-100) relative to total graph height of the largest z in the dataset
  const dataAverageVerticalLinesTF = JSFUNC.prop_value(props.p_dataAverageVerticalLinesTF, false);
  const title = JSFUNC.prop_value(props.p_title, "");
  const p_heightPx = JSFUNC.prop_value(props.p_heightPx, 500);
  const p_fontSizePx = JSFUNC.prop_value(props.p_fontSizePx, 12);
  const p_legendTF = JSFUNC.prop_value(props.p_legendTF, true);

  const zScalingTF = ((JSFUNC.is_number(zMin) && (zMin > 0) && (zMin <= 100)) && (JSFUNC.is_number(zMax) && (zMax > 0) && (zMax <= 100)) && (zMax > zMin)); //using different size dots based on data z values, otherwise all dots are same size based on p_zSizePercentOfHeight

  //search for the max x/y value within all input data sets
  var dataMinXValue = undefined;
  var dataMaxXValue = undefined;
  var dataMinYValue = undefined;
  var dataMaxYValue = undefined;
  var dataMinYValueAbove0 = 0;
  for(let dataObj of dataArrayOfObjs) {
    var xyPointsArrayOfObjs = dataObj.xyPointsArrayOfObjs;
    for(let xyPointObj of xyPointsArrayOfObjs) {
      if(JSFUNC.is_number(xyPointObj.x)) {
        if((dataMinXValue === undefined) || (xyPointObj.x < dataMinXValue)) {
          dataMinXValue = xyPointObj.x;
        }

        if((dataMaxXValue === undefined) || (xyPointObj.x > dataMaxXValue)) {
          dataMaxXValue = xyPointObj.x;
        }
      }

      if(JSFUNC.is_number(xyPointObj.y)) {
        if((dataMinYValue === undefined) || (xyPointObj.y < dataMinYValue)) {
          dataMinYValue = xyPointObj.y;
        }

        if((dataMaxYValue === undefined) || (xyPointObj.y > dataMaxYValue)) {
          dataMaxYValue = xyPointObj.y;
        }

        if((xyPointObj.y > 0) && ((dataMinYValueAbove0 === 0) || (xyPointObj.y < dataMinYValueAbove0))) {
          dataMinYValueAbove0 = xyPointObj.y;
        }
      }
    }
  }
  if(dataMinXValue === undefined) { dataMinXValue = 0; }
  if(dataMaxXValue === undefined) { dataMaxXValue = 1; }
  if(dataMinYValue === undefined) { dataMinYValue = 0; }
  if(dataMaxYValue === undefined) { dataMaxYValue = 1; }

  //adjust the dataMaxValues if a max input is provided, otherwise scale the max value in the data by a percent
  if(JSFUNC.is_number(xMax) && (xMax > dataMaxXValue)) {
    dataMaxXValue = xMax;
  }

  if(JSFUNC.is_number(yMax) && (yMax > dataMaxYValue)) {
    dataMaxYValue = yMax;
  }

  //compute the y axis ticks (linear or logarithmic scale from input flag)
  const xAxisObj = JSFUNC.create_axis_obj_from_max_value_and_height_px(dataMinXValue, dataMaxXValue, p_heightPx, false, xValueFormat, false, 1, 0); //false - linear scale, false - linear x axis
  const xAxisTicksArrayOfObjs = xAxisObj.axisTicksArrayOfObjs;
  const graphRightXValue = xAxisObj.axisMaxValue; //for the linear x scale, the right side of the graph is the dataMaxXValue provided to the axis calculation function

  //compute the y axis ticks (linear or logarithmic scale from input flag)
  const yAxisObj = JSFUNC.create_axis_obj_from_max_value_and_height_px(0, dataMaxYValue, p_heightPx, true, yValueFormat, p_yLogScaleTF, 1, dataMinYValueAbove0); //true - reverse ticks for y axis svg positions

  //recompute the datasets scaling for the svg x and y positions 0 to 100
  var fakeID = 1; //fakeID used for highlighting data from legend
  var svgComputedDataArrayOfObjs = [];
  for(let dataObj of dataArrayOfObjs) {
    var xyPointsArrayOfObjs = dataObj.xyPointsArrayOfObjs;
    var svgComputedXyPointsArrayOfObjs = [];
    var allXValuesSum = 0;
    for(let xyPointObj of xyPointsArrayOfObjs) {
      if(JSFUNC.is_number(xyPointObj.x) && JSFUNC.is_number(xyPointObj.y)) {
        //computed svg x position
        var svgComputedXPos = JSFUNC.compute_svg_pos_0to100_from_value_and_axis_obj(xyPointObj.x, xAxisObj);//alert("x: " + xyPointObj.x);JSFUNC.alert_obj(xAxisObj);alert("xPos: " + svgComputedXPos)

        //computed svg y position
        var svgComputedYPos = JSFUNC.compute_svg_pos_0to100_from_value_and_axis_obj(xyPointObj.y, yAxisObj);

        svgComputedXyPointsArrayOfObjs.push({
          x: xyPointObj.x,
          y: xyPointObj.y,
          clickReturnValue: xyPointObj.clickReturnValue,
          title: xyPointObj.title,
          xPos: svgComputedXPos,
          yPos: svgComputedYPos
        });

        allXValuesSum += xyPointObj.x;
      }
    }

    var allXAverageValue = (allXValuesSum / xyPointsArrayOfObjs.length);

    var allXAveragePos = 0;
    if(graphRightXValue > 0) { //avoid divide by 0 if all data points have a y value of 0
      allXAveragePos = ((allXAverageValue / graphRightXValue) * 100);
    }

    svgComputedDataArrayOfObjs.push({
      fakeID: fakeID,
      xyPointsArrayOfObjs: svgComputedXyPointsArrayOfObjs,
      label: dataObj.label,
      color: dataObj.color,
      allXAverageValue: allXAverageValue,
      allXAveragePos: allXAveragePos
    });

    fakeID++;
  }

  return(
    <ScatterPlotAxesAndDataComputed
      p_svgComputedDataArrayOfObjs={svgComputedDataArrayOfObjs}
      p_xAxisTicksArrayOfObjs={xAxisObj.axisTicksArrayOfObjs}
      p_xLabel={xLabel}
      p_yAxisTicksArrayOfObjs={yAxisObj.axisTicksArrayOfObjs}
      p_yLabel={yLabel}
      p_zSizePercentOfHeight={zSizePercentOfHeight}
      p_zMin={zMin}
      p_zMax={zMax}
      p_dataAverageVerticalLinesTF={dataAverageVerticalLinesTF}
      p_title={title}
      p_heightPx={p_heightPx}
      p_fontSizePx={p_fontSizePx}
      p_legendTF={p_legendTF}
      f_onClickDot={props.f_onClickDot}
    />
  );
}

class ScatterPlotAxesAndDataComputed extends Component { //props: p_svgComputedDataArrayOfObjs, p_xAxisTicksArrayOfObjs, p_xLabel, p_yAxisTicksArrayOfObjs, p_yLabel, p_zSizePercentOfHeight, p_zMin, p_zMax, p_dataAverageVerticalLinesTF, p_title, p_heightPx, p_fontSizePx, p_legendTF, f_onClickDot
  constructor(props) {
    super(props);
    this.state = {
      s_selectedDatasetIndicesArray: []
    }
  }

  onclick_legend_highlight_dataset_index = (i_datasetFakeID) => {
    var updatedSelectedDatasetIndicesArray = JSFUNC.copy_array(this.state.s_selectedDatasetIndicesArray);
    updatedSelectedDatasetIndicesArray = JSFUNC.remove_value_from_array_if_exists_otherwise_append(i_datasetFakeID, updatedSelectedDatasetIndicesArray);
    this.setState({s_selectedDatasetIndicesArray:updatedSelectedDatasetIndicesArray});
  }

  render() {
    const selectedDatasetIndicesArray = this.state.s_selectedDatasetIndicesArray;

    const svgComputedDataArrayOfObjs = this.props.p_svgComputedDataArrayOfObjs;
    const xAxisTicksArrayOfObjs = this.props.p_xAxisTicksArrayOfObjs;
    const xLabel = this.props.p_xLabel;
    const yAxisTicksArrayOfObjs = this.props.p_yAxisTicksArrayOfObjs;
    const yLabel = this.props.p_yLabel;
    const zSizePercentOfHeight = this.props.p_zSizePercentOfHeight;
    const dataAverageVerticalLinesTF = this.props.p_dataAverageVerticalLinesTF;
    const title = this.props.p_title;
    const p_heightPx = this.props.p_heightPx;
    const p_fontSizePx = this.props.p_fontSizePx;
    const p_legendTF = this.props.p_legendTF;

    const reverseSvgComputedDataArrayOfObjs = [];
    for(let d = (svgComputedDataArrayOfObjs.length - 1); d >= 0; d--) {
      reverseSvgComputedDataArrayOfObjs.push(svgComputedDataArrayOfObjs[d]);
    }

    const yAxisWidthEm = 5;
    const xAxisHeight = "1.5em";
    const gridLineWidthPx = 1;
    const xVerticalGridLineColor = "#ccc";
    const yHorizontalGridLineHashColor = "#ccc"

    const xLabelTF = JSFUNC.text_or_number_is_filled_out_tf(xLabel);
    const yLabelTF = JSFUNC.text_or_number_is_filled_out_tf(yLabel);
    const axisLabelsHeight = "1.6em";
    const axisLabelsFontClass = "font12 fontItalic fontTextLighter";

    const titleTF = JSFUNC.text_or_number_is_filled_out_tf(title);

    const dotRadiusPx = Math.round(p_heightPx * (zSizePercentOfHeight / 100));
    const dotBorderWidthPx= 2;
    const dotBorderColor = "999999";

    return(
      <div className="displayFlexColumn" style={{height:p_heightPx + "px"}}>
        {(titleTF) &&
          <div className="flex00a displayFlexColumnVc textCenter" style={{padding:"0 1em 0.4em 1em"}}>
            <font className="font12 fontBold fontTextLighter">
              {title}
            </font>
          </div>
        }
        <div className="flex11a displayFlexRow">
          {(yLabelTF) &&
            <div className="flex00a displayFlexColumn" style={{flexBasis:axisLabelsHeight}}>
              <div className="flex11a displayFlexColumnHcVc">
                <div className="flex00a verticalUpText textCenter" style={{width:p_heightPx + "px"}}>
                  <Nowrap p_fontClass={axisLabelsFontClass}>
                    {yLabel}
                  </Nowrap>
                </div>
              </div>
              <div className="flex00a" style={{flexBasis:xAxisHeight}} />
              {(xLabelTF) &&
                <div className="flex00a" style={{flexBasis:axisLabelsHeight}} />
              }
            </div>
          }
          <div className="flex00a displayFlexColumn" style={{flexBasis:yAxisWidthEm + "em"}}>
            <YAxisWithLabelsLeftColumn p_yAxisTicksArrayOfObjs={yAxisTicksArrayOfObjs} p_yAxisWidthEm={yAxisWidthEm} />
            <div className="flex00a" style={{flexBasis:xAxisHeight}} />
            {(xLabelTF) &&
              <div className="flex00a" style={{flexBasis:axisLabelsHeight}} />
            }
          </div>
          <div className="flex11a displayFlexColumn" style={{flexBasis:"500em"}}>
            <div className="flex11a">
              <svg width="100%" height="100%">
                <rect x="0%" y="0%" width="100%" height="100%" fill="none" stroke="#aaa" strokeWidth={gridLineWidthPx + "px"} />
                <ScatterPlotXAxisLinesAndLabels
                  p_xAxisTicksArrayOfObjs={xAxisTicksArrayOfObjs}
                  p_gridLineWidthPx={gridLineWidthPx}
                  p_gridLineColor={xVerticalGridLineColor}
                  p_labelsTF={false}
                />
                <YAxisTicksHorizontalLines
                  p_yAxisTicksArrayOfObjs={yAxisTicksArrayOfObjs}
                  p_gridLineWidthPx={gridLineWidthPx}
                  p_gridLineColor={yHorizontalGridLineHashColor}
                />
                {(dataAverageVerticalLinesTF) &&
                  (reverseSvgComputedDataArrayOfObjs.map((m_svgComputedDataObj) =>
                    ((selectedDatasetIndicesArray.length === 0) || (JSFUNC.in_array(m_svgComputedDataObj.fakeID, selectedDatasetIndicesArray))) &&
                    <line x1={m_svgComputedDataObj.allXAveragePos + "%"} y1="0%" x2={m_svgComputedDataObj.allXAveragePos + "%"} y2="100%" style={{stroke:"#" + m_svgComputedDataObj.color, strokeWidth:"3px"}} />
                  ))
                }
                {reverseSvgComputedDataArrayOfObjs.map((m_svgComputedDataObj) =>
                  <ScatterPlotSingleDataSetSvgDots
                    p_svgComputedDataObj={m_svgComputedDataObj}
                    p_selectedDatasetIndicesArray={selectedDatasetIndicesArray}
                    p_dotRadiusPx={dotRadiusPx}
                    p_dotBorderWidthPx={dotBorderWidthPx}
                    p_dotBorderColor={dotBorderColor}
                    f_onClickDot={this.props.f_onClickDot}
                  />
                )}
              </svg>
            </div>
            <div className="flex00a" style={{flexBasis:xAxisHeight}}>
              <svg width="100%" height="100%">
                <rect x="0%" y="0%" width="100%" height="100%" fill="none" stroke="#aaa" strokeWidth={gridLineWidthPx + "px"} />
                <ScatterPlotXAxisLinesAndLabels
                  p_xAxisTicksArrayOfObjs={xAxisTicksArrayOfObjs}
                  p_gridLineWidthPx={gridLineWidthPx}
                  p_gridLineColor="#ccc"
                  p_labelsTF={true}
                />
              </svg>
            </div>
            {(xLabelTF) &&
              <div className="flex00a displayFlexColumnHcVc textCenter" style={{flexBasis:axisLabelsHeight}}>
                <Nowrap p_fontClass={axisLabelsFontClass}>
                  {xLabel}
                </Nowrap>
              </div>
            }
          </div>
          {(p_legendTF) &&
            <div className="flex11a yScroll smallFullPad" style={{flexBasis:"100em", minWidth:"10em"}}>
              {svgComputedDataArrayOfObjs.map((m_svgComputedDataObj) =>
                <ScatterPlotLegendItem
                  p_datasetColorLabelObj={m_svgComputedDataObj}
                  p_selectedDatasetIndicesArray={selectedDatasetIndicesArray}
                  p_dotBorderColor={dotBorderColor}
                  f_onClick={this.onclick_legend_highlight_dataset_index}
                />
              )}
            </div>
          }
        </div>
      </div>
    );
  }
}

function ScatterPlotSingleDataSetSvgDots(props) { //props: p_svgComputedDataObj, p_selectedDatasetIndicesArray, p_dotRadiusPx, p_dotBorderWidthPx, p_dotBorderColor, f_onClickDot
  const svgComputedDataObj = props.p_svgComputedDataObj;
  const selectedDatasetIndicesArray = props.p_selectedDatasetIndicesArray;
  const dotRadiusPx = props.p_dotRadiusPx;
  const dotBorderWidthPx = props.p_dotBorderWidthPx;
  const dotBorderColor = props.p_dotBorderColor;

  const xyPointsArrayOfObjs = svgComputedDataObj.xyPointsArrayOfObjs;
  const label = svgComputedDataObj.label;
  const color = svgComputedDataObj.color;
  const datasetIndex = svgComputedDataObj.fakeID;

  var dotOpacity = undefined;
  if((selectedDatasetIndicesArray.length > 0) && !JSFUNC.in_array(datasetIndex, selectedDatasetIndicesArray)) {
    dotOpacity = 0.1;
  }

  return(
    xyPointsArrayOfObjs.map((m_xyPointObj) =>
      <ScatterPlotSingleDot
        p_xyPointObj={m_xyPointObj}
        p_color={color}
        p_dotRadiusPx={dotRadiusPx}
        p_dotBorderWidthPx={dotBorderWidthPx}
        p_dotBorderColor={dotBorderColor}
        p_dotOpacity={dotOpacity}
        f_onClick={props.f_onClickDot}
      />
    )
  );
}

class ScatterPlotSingleDot extends Component { //props: p_xyPointObj, p_color, p_dotRadiusPx, p_dotBorderWidthPx, p_dotBorderColor, p_dotOpacity, f_onClick
  onclick_scatter_plot_dot = (i_datasetIndex) => {
    if(JSFUNC.is_function(this.props.f_onClick)) {
      const xyPointObj = this.props.p_xyPointObj;
      if(xyPointObj.clickReturnValue !== undefined) {
        this.props.f_onClick(xyPointObj.clickReturnValue);
      }
    }
  }

  render() {
    const xyPointObj = this.props.p_xyPointObj;
    const color = this.props.p_color;
    const dotRadiusPx = this.props.p_dotRadiusPx;
    const dotBorderWidthPx = this.props.p_dotBorderWidthPx;
    const dotBorderColor = this.props.p_dotBorderColor;
    const dotOpacity = this.props.p_dotOpacity;

    const xPos = xyPointObj.xPos;
    const yPos = xyPointObj.yPos;
    const clickReturnValue = xyPointObj.clickReturnValue;
    const title = xyPointObj.title;

    const dotCursor = (((this.props.f_onClick !== undefined) && (clickReturnValue !== undefined)) ? ("pointer") : (undefined));

    const dotSvgComponent = (
      <circle
        r={dotRadiusPx + "px"}
        cx={xPos + "%"}
        cy={yPos + "%"}
        stroke={"#" + dotBorderColor}
        strokeWidth={dotBorderWidthPx + "px"}
        style={{fill:"#" + color, cursor:dotCursor, opacity:dotOpacity}}
        onClick={this.onclick_scatter_plot_dot}
      />
    );

    if(title !== undefined) {
      return(
        <g>
          <title>{title}</title>
          {dotSvgComponent}
        </g>
      );
    }

    return(
      dotSvgComponent
    );
  }
}

function ScatterPlotXAxisLinesAndLabels(props) { //props: p_xAxisTicksArrayOfObjs, p_gridLineWidthPx, p_gridLineColor, p_labelsTF
  const xAxisTicksArrayOfObjs = props.p_xAxisTicksArrayOfObjs;
  const gridLineWidthPx = props.p_gridLineWidthPx;
  const gridLineColor = props.p_gridLineColor;
  const labelsTF = props.p_labelsTF;

  return(
    xAxisTicksArrayOfObjs.map((m_xAxisTickObj) =>
      <>
        <line x1={m_xAxisTickObj.svgPos0to100 + "%"} y1="0%" x2={m_xAxisTickObj.svgPos0to100 + "%"} y2="100%" style={{stroke:gridLineColor, strokeWidth:gridLineWidthPx + "px"}} />
        {(labelsTF) &&
          <text x={(m_xAxisTickObj.svgPos0to100 + 0.5) + "%"} y="70%" fill="#333">
            {m_xAxisTickObj.valueLabel}
          </text>
        }
      </>
    )
  );
}


class ScatterPlotLegendItem extends Component { //props: p_datasetColorLabelObj, p_selectedDatasetIndicesArray, p_dotBorderColor, f_onClick
  onclick_legend_item = () => {
    if(this.props.f_onClick) {
      this.props.f_onClick(this.props.p_datasetColorLabelObj.fakeID);
    }
  }

  render() {
    const datasetColorLabelObj = this.props.p_datasetColorLabelObj;
    const selectedDatasetIndicesArray = this.props.p_selectedDatasetIndicesArray;
    const dotBorderColor = this.props.p_dotBorderColor;

    const isSelectedLegendDatasetTF = JSFUNC.in_array(datasetColorLabelObj.fakeID, selectedDatasetIndicesArray);

    return(
      <div
        className={"displayFlexRowVc tbMicroPad cursorPointer " + ((isSelectedLegendDatasetTF) ? ("bgBlueGradient fontWhite") : ("hoverLighterBlueGradient"))}
        title={datasetColorLabelObj.label}
        onClick={this.onclick_legend_item}>
        <div className="flex00a lrMargin">
          <CheckBox
            p_u0_s1_p2_du3_ds4={((isSelectedLegendDatasetTF) ? (1) : (0))}
            p_sizeEm={1}
            f_onClick={undefined}
          />
        </div>
        <div className="flex00a" style={{height:"1.8em", width:"1.8em"}}>
          <svg width="100%" height="100%">
            <circle
              r="45%"
              cx="50%"
              cy="50%"
              stroke={"#" + dotBorderColor}
              strokeWidth="1px"
              style={{fill:"#" + datasetColorLabelObj.color}}
            />
          </svg>
        </div>
        <div className="flex11a lrPad">
          <Nowrap p_fontClass="">
            {datasetColorLabelObj.label}
          </Nowrap>
        </div>
      </div>
    );
  }
}








//--------------------------------------------------------------------------------------------------------------------------------

export function TimeGraph(props) { //props: p_categoriesArrayOfObjs, p_timeGraphType, p_startDate, p_endDate, p_timeBins, p_yLogScaleTF, p_valueFormat, p_heightPx, p_yAxisWidthEm, p_fontSizePx, p_legendTF, p_legendHideZeroCategoriesTF, f_onClickSegment
  //--- external inputs ---
  //p_categoriesArrayOfObjs:
  //  - idDateValueArrayOfObjs
  //      - id
  //      - date
  //      - value
  //  - color
  //  - label
  //p_timeGraphType: "line", "lineCumulative", "bar", "barPercentOf100"
  //p_timeBins: "weekly", "monthly", "quarterly", "yearly"
  //p_yLogScaleTF: true, false
  //p_valueFormat: "number", "percent", "moneyShort"
  //-----------------------
  //
  //
  //--- internal lineDataObj ---
  //  - categoryLineDataArrayOfObjs
  //    + label
  //    + color
  //    + binDotsArrayOfObjs
  //      ^ binObj
  //        * leftDate
  //        * rightDate
  //        * centerPos
  //        * barLeftPos
  //        * barRightPos
  //        * barWidth
  //      ^ idsArray
  //      ^ valueTotal
  //      ^ yPos
  //      ^ barBottomPos
  //      ^ barTopPos
  //      ^ barHeight
  //    + lineIDsArray
  //    + lineTitle
  //    + hideLineAndCategoryTF
  //  - maxValue
  //  - minValueAbove0
  //-----------------------------

  const p_categoriesArrayOfObjs = JSFUNC.prop_value(props.p_categoriesArrayOfObjs, []);
  const p_timeGraphType = JSFUNC.prop_value(props.p_timeGraphType, "line");
  const p_startDate = props.p_startDate;
  const p_endDate = props.p_endDate;
  const p_timeBins = JSFUNC.prop_value(props.p_timeBins, "monthly");
  const p_yLogScaleTF = JSFUNC.prop_value(props.p_yLogScaleTF, false);
  const p_valueFormat = JSFUNC.prop_value(props.p_valueFormat, "number"); //"number", "percent", "money", "moneyShort"
  const p_heightPx = JSFUNC.prop_value(props.p_heightPx, 150);
  const p_yAxisWidthEm = JSFUNC.prop_value(props.p_yAxisWidthEm, 5);
  const p_fontSizePx = JSFUNC.prop_value(props.p_fontSizePx, 12);
  const p_legendHideZeroCategoriesTF = JSFUNC.prop_value(props.p_legendHideZeroCategoriesTF, false);
  const p_legendTF = JSFUNC.prop_value(props.p_legendTF, true);

  //compute tf flag variable of graph type
  var timeGraphTypeObj = {
    lineTF: false,
    lineCumulativeTF: false,
    barTF: false,
    barPercentOf100TF: false
  };
  if(p_timeGraphType === "line") { timeGraphTypeObj.lineTF = true; }
  else if(p_timeGraphType === "lineCumulative") { timeGraphTypeObj.lineCumulativeTF = true; }
  else if(p_timeGraphType === "bar") { timeGraphTypeObj.barTF = true; }
  else if(p_timeGraphType === "barPercentOf100") { timeGraphTypeObj.barPercentOf100TF = true; }

  //record the initial input p_valueFormat
  var formatIsNumberTF = false;
  var formatIsPercentTF = false;
  var formatIsMoneyShortTF = false;
  if(p_valueFormat === "number") { formatIsNumberTF = true; }
  else if(p_valueFormat === "percent") { formatIsPercentTF = true; }
  else if(p_valueFormat === "moneyShort") { formatIsMoneyShortTF = true; }

  //initialize valueFormat from input (overridden to "percent") if type is barPercentOf100TF
  var inputValueFormatOrForcedPercent = p_valueFormat;
  if(timeGraphTypeObj.barPercentOf100TF) {
    inputValueFormatOrForcedPercent = "percent";
  }

  //compute the binsObj based on the start/end dates and the specified p_timeBins spacing
  var binsObj = JSFUNC.bins_obj_from_start_end_date_and_time_bins(p_startDate, p_endDate, p_timeBins);
  
  //if creating a bar graph, compute extra positions in the binObjs for where the bar left and right are on the xAxis
  const barEdgePercentFromLeftEdge0To100 = 5; //a value of 5 puts the bar at the 5% and 95% left/right positions given the bin representing 0% and 100% with its left/right edges
  if(timeGraphTypeObj.barTF || timeGraphTypeObj.barPercentOf100TF) {
    for(let binObj of binsObj.binsArrayOfObjs) {
      var binWidth = (binObj.rightPos - binObj.leftPos);
      binObj.barLeftPos = (binObj.leftPos + ((barEdgePercentFromLeftEdge0To100 / 100) * binWidth)); //5% of the total width to the right of the left bin edge
      binObj.barRightPos = (binObj.leftPos + (((100 - barEdgePercentFromLeftEdge0To100) / 100) * binWidth)); //95% of the total width to the right of the left bin edge
      binObj.barWidth = (binObj.barRightPos - binObj.barLeftPos);
    }
  }

  //if there are 0 bins (start date > end date, etc), display a gray message
  if(binsObj.numBins === 0) {
    return(
      <div className="displayFlexColumnHcVc" style={{height:p_heightPx + "px"}}>
        <font className="fontItalic">
          {"--Invalid Start/End Dates--"}
        </font>
      </div>
    );
  }

  //compute lines data obj
  var categoryLineDataArrayOfObjs = [];
  var maximumTotalValueOfAnyCategory = 1; //smallest window is from 0 to 1, thus 1 is the starting maximum value which is updated with each higher value found within the datasets
  var minValueAbove0 = 0; //the lowest value found that is still above 0
  var earliestDateYmd = p_startDate; //find the earliest date of any datapoint, initialize with the xAxis start date in case that's earlier than all data points, this is used for cumulative data point label date ranges
  for(let categoryObj of p_categoriesArrayOfObjs) {
    var categoryIdDateValueArrayOfObjs = categoryObj.idDateValueArrayOfObjs;

    //initialize a binDotObj per bin for the binDotsArrayOfObjs to keep accumulating totals and id numbers per bin per category
    var binDotsArrayOfObjs = [];
    for(let binObj of binsObj.binsArrayOfObjs) {
      var initializedBinDotObj = {
        binObj: binObj,
        idsArray: [],
        valueTotal: 0
      };
      binDotsArrayOfObjs.push(initializedBinDotObj);
    }

    //loop through all date data points and find which bin it falls in
    for(let idDateValueObj of categoryIdDateValueArrayOfObjs) {
      if(((timeGraphTypeObj.lineCumulativeTF && JSFUNC.date_is_filled_out_tf(idDateValueObj.date)) || (idDateValueObj.date >= binsObj.startDate)) && (idDateValueObj.date <= binsObj.endDate)) { //no need to loop through all bins if this date is outside of the start/end date window
        //loop through each bin, update each corresponding binDotObj with incrementing valueTotal amounts
        for(let b = 0; b < binsObj.numBins; b++) {
          var binObj = binsObj.binsArrayOfObjs[b];

          //normal line/bar graph check if date is between bin left and right dates, for cumulative only need to check if it's less than the right date because it's the sum of all data leading up to that right edge date
          if((timeGraphTypeObj.lineCumulativeTF || (idDateValueObj.date >= binObj.leftDate)) && (idDateValueObj.date < binObj.rightDate)) {
            binDotsArrayOfObjs[b].idsArray.push(idDateValueObj.id);
            binDotsArrayOfObjs[b].valueTotal += idDateValueObj.value;
          }
        }

        //also check for earliest date if this is a cumulative graph
        if(timeGraphTypeObj.lineCumulativeTF) {
          if(idDateValueObj.date < earliestDateYmd) {
            earliestDateYmd = idDateValueObj.date;
          }
        }
      }
    }

    //for line graphs, find the minimum above 0 and maximum values in any bin in this category (bar graphs make stacked computations and find the maximum there)
    if(timeGraphTypeObj.lineTF || timeGraphTypeObj.lineCumulativeTF) {
      for(let binDotObj of binDotsArrayOfObjs) {
        if((binDotObj.valueTotal > 0) && ((minValueAbove0 === 0) || (binDotObj.valueTotal < minValueAbove0))) {
          minValueAbove0 = binDotObj.valueTotal;
        }

        if(binDotObj.valueTotal > maximumTotalValueOfAnyCategory) {
          maximumTotalValueOfAnyCategory = binDotObj.valueTotal;
        }
      }
    }

    categoryLineDataArrayOfObjs.push({
      binDotsArrayOfObjs: binDotsArrayOfObjs,
      label: categoryObj.label,
      color: categoryObj.color
    });
  }

  //for bar graphs, compute new stacked valueTotals of each category bar on top of the last, compute the maximum value seen at the top across all categories
  if(timeGraphTypeObj.barTF || timeGraphTypeObj.barPercentOf100TF) {
    for(let b = 0; b < binsObj.numBins; b++) { //loop over each bin
      var previousCategoryBarTopValue = 0; //first bar starts at the bottom at y=0
      for(let categoryLineDataObj of categoryLineDataArrayOfObjs) { //loop ascending through each category in order
        var binDotObj = categoryLineDataObj.binDotsArrayOfObjs[b];

        var barBottomValue = previousCategoryBarTopValue; //start at top of previous category bar in this bin
        var barTopValue = (previousCategoryBarTopValue + binDotObj.valueTotal); //add height of the bar to get the top

        categoryLineDataObj.binDotsArrayOfObjs[b].barBottomValue = barBottomValue;
        categoryLineDataObj.binDotsArrayOfObjs[b].barTopValue = barTopValue;

        previousCategoryBarTopValue = binDotObj.barTopValue; //update prev top value for next loop
      }

      //(normalized bar percent graph only) once the bottom/top and highest top level are determined, normalize the bottom/top values into percents 0-100% based on that highest top level within this bin
      if(timeGraphTypeObj.barPercentOf100TF && (previousCategoryBarTopValue > 0)) { //check that previousCategoryBarTopValue is >0 to avoid divide by 0, if it is 0 all bottom/top values are also already 0
        for(let categoryLineDataObj of categoryLineDataArrayOfObjs) {
          categoryLineDataObj.binDotsArrayOfObjs[b].barBottomValue = ((categoryLineDataObj.binDotsArrayOfObjs[b].barBottomValue / previousCategoryBarTopValue) * 100);
          categoryLineDataObj.binDotsArrayOfObjs[b].barTopValue = ((categoryLineDataObj.binDotsArrayOfObjs[b].barTopValue / previousCategoryBarTopValue) * 100);
        }
      }

      //(bar graph only) after the last and highest category, update the maximum total value if this highest bar value is higher
      if(timeGraphTypeObj.barTF) {
        if(previousCategoryBarTopValue > maximumTotalValueOfAnyCategory) {
          maximumTotalValueOfAnyCategory = previousCategoryBarTopValue;
        }
      }
    }

    //(normalized bar percent graph only) cap maximum percent level at 105% (since all normalized percents calculated in this loop are from 0-100%)
    if(timeGraphTypeObj.barPercentOf100TF) {
      maximumTotalValueOfAnyCategory = 105;
    }
  }

  //compute y axis obj
  const reversePos100to0TF = true; //reverse pos values for y axis ticks
  const yAxisObj = JSFUNC.create_axis_obj_from_max_value_and_height_px(0, maximumTotalValueOfAnyCategory, p_heightPx, reversePos100to0TF, inputValueFormatOrForcedPercent, p_yLogScaleTF, 1.06, minValueAbove0);

  //go back through all categories and all bins and compute the valueTotalMask and yPos percent, plus 
  for(let categoryLineDataObj of categoryLineDataArrayOfObjs) {
    var lineIDsArray = [];
    var categoryValueTotal = 0;
    for(let binDotObj of categoryLineDataObj.binDotsArrayOfObjs) {
      //computed yPos on yAxis based on valueTotal of dot
      var yPos = 0;
      if(timeGraphTypeObj.lineTF || timeGraphTypeObj.lineCumulativeTF) {
        yPos = JSFUNC.compute_svg_pos_0to100_from_value_and_axis_obj(binDotObj.valueTotal, yAxisObj);
      }
      binDotObj.yPos = yPos;

      //computed bar yPositions on yAxis based on bar bottom/top values
      var barBottomPos = 0;
      var barTopPos = 0;
      var barHeight = 0;
      if(timeGraphTypeObj.barTF || timeGraphTypeObj.barPercentOf100TF) {
        barBottomPos = JSFUNC.compute_svg_pos_0to100_from_value_and_axis_obj(binDotObj.barBottomValue, yAxisObj);
        barTopPos = JSFUNC.compute_svg_pos_0to100_from_value_and_axis_obj(binDotObj.barTopValue, yAxisObj);
        barHeight = (barBottomPos - barTopPos);
      }
      binDotObj.barBottomPos = barBottomPos;
      binDotObj.barTopPos = barTopPos;
      binDotObj.barHeight = barHeight;

      //valueTotalMask (from input p_valueFormat formatting)
      var valueTotalMask = binDotObj.valueTotal; //"number" default format
      if(formatIsMoneyShortTF) {
        valueTotalMask = JSFUNC.money(binDotObj.valueTotal, 0, true);
      }
      else if(formatIsPercentTF) {
        valueTotalMask = JSFUNC.round_percent_to_num_decimals_if_needed_but_show_all_for_less_than_1(binDotObj.valueTotal, 1);
      }

      //title hover text over dot
      var title = categoryLineDataObj.label + "\n";

      if(timeGraphTypeObj.lineCumulativeTF) {
        title += "Cumulative " + earliestDateYmd + " to " + binDotObj.binObj.rightDate;
      }
      else {
        title += binDotObj.binObj.leftDate + " to " + binDotObj.binObj.rightDate;
      }
      title += "\n";

      if(timeGraphTypeObj.barPercentOf100TF) { //normalized bar percent shows formated valueTotal plus the relative percent 0to100 in parens
        title += valueTotalMask + " (" + JSFUNC.round_percent_to_num_decimals_if_needed_but_show_all_for_less_than_1(binDotObj.barHeight, 1) + ")";
      }
      else { //line and bar graphs show valueTotal (either count or money) like "346" or "$2,364,790"
        title += valueTotalMask;
      }
      title += "\n[Click to view Captures]";

      binDotObj.title = title;

      //category itemIDs
      lineIDsArray = JSFUNC.unique(JSFUNC.concat_arrays_or_values_into_new_array(lineIDsArray, binDotObj.idsArray));

      //category total
      categoryValueTotal += binDotObj.valueTotal;
    }

    var lineTitle = categoryLineDataObj.label;
    lineTitle += "\n" + binsObj.startDate + " to " + binsObj.endDate + "\n";
    if(formatIsMoneyShortTF) {
      lineTitle += JSFUNC.money(categoryValueTotal, 0, true);
    }
    else if(formatIsPercentTF) {
      lineTitle += JSFUNC.round_percent_to_num_decimals_if_needed_but_show_all_for_less_than_1(categoryValueTotal, 1);
    }
    else {
      lineTitle += categoryValueTotal;
    }
    lineTitle += "\n[Click to view Captures]";

    categoryLineDataObj.lineIDsArray = lineIDsArray;
    categoryLineDataObj.lineTitle = lineTitle;
    categoryLineDataObj.hideLineAndCategoryTF = (p_legendHideZeroCategoriesTF && (lineIDsArray.length === 0));
  }

  return(
    <TimeGraphBinsAndLinesComputed
      p_categoryLineDataArrayOfObjs={categoryLineDataArrayOfObjs}
      p_timeGraphTypeObj={timeGraphTypeObj}
      p_yAxisObj={yAxisObj}
      p_binsObj={binsObj}
      p_heightPx={p_heightPx}
      p_yAxisWidthEm={p_yAxisWidthEm}
      p_fontSizePx={p_fontSizePx}
      p_legendTF={p_legendTF}
      f_onClickSegment={props.f_onClickSegment}
    />
  );
}


class TimeGraphBinsAndLinesComputed extends Component { //props: p_categoryLineDataArrayOfObjs, p_timeGraphTypeObj, p_yAxisObj, p_binsObj, p_heightPx, p_yAxisWidthEm, p_fontSizePx, p_legendTF, f_onClickSegment
  constructor(props) {
    super(props);
    this.state = {
      s_selectedCategoryIndicesArray: []
    }
  }

  onclick_legend_highlight_category_index = (i_categoryIndex) => {
    var updatedSelectedCategoryIndicesArray = JSFUNC.copy_array(this.state.s_selectedCategoryIndicesArray);
    updatedSelectedCategoryIndicesArray = JSFUNC.remove_value_from_array_if_exists_otherwise_append(i_categoryIndex, updatedSelectedCategoryIndicesArray);
    this.setState({s_selectedCategoryIndicesArray:updatedSelectedCategoryIndicesArray});
  }

  render() {
    const s_selectedCategoryIndicesArray = this.state.s_selectedCategoryIndicesArray;

    const p_categoryLineDataArrayOfObjs = this.props.p_categoryLineDataArrayOfObjs;
    const p_timeGraphTypeObj = this.props.p_timeGraphTypeObj;
    const p_yAxisObj = this.props.p_yAxisObj;
    const p_binsObj = this.props.p_binsObj;
    const p_heightPx = this.props.p_heightPx;
    const p_yAxisWidthEm = this.props.p_yAxisWidthEm;
    const p_fontSizePx = this.props.p_fontSizePx;
    const p_legendTF = this.props.p_legendTF;

    const yAxisTicksArrayOfObjs = p_yAxisObj.axisTicksArrayOfObjs;

    const xAxisHeight = "1.5em";
    const gridLineWidthPx = 1;

    const legendRowHeight = Math.round(1.2 * p_fontSizePx) + "px";
    const legendLrPadding = "0 0.2em";
    const legendColorWidth  = Math.round(0.9 * p_fontSizePx) + "px";
    const legendColorHeight  = Math.round(1.15 * p_fontSizePx) + "px";

    return(
      <div className="displayFlexRow" style={{height:p_heightPx + "px"}}>
        <div className="flex00a displayFlexColumn" style={{flexBasis:p_yAxisWidthEm + "em"}}>
          <YAxisWithLabelsLeftColumn
            p_yAxisTicksArrayOfObjs={yAxisTicksArrayOfObjs}
            p_yAxisWidthEm={p_yAxisWidthEm}
          />
          <div className="flex00a" style={{flexBasis:xAxisHeight}} />
        </div>
        <div className="flex11a displayFlexColumn" style={{flexBasis:"500em"}}>
          <div className="flex11a">
            <TimeGraphLineGraphSvg
              p_categoryLineDataArrayOfObjs={p_categoryLineDataArrayOfObjs}
              p_timeGraphTypeObj={p_timeGraphTypeObj}
              p_binsObj={p_binsObj}
              p_yAxisTicksArrayOfObjs={yAxisTicksArrayOfObjs}
              p_heightPx={p_heightPx}
              p_gridLineWidthPx={gridLineWidthPx}
              p_selectedCategoryIndicesArray={s_selectedCategoryIndicesArray}
              f_onClickSegment={this.props.f_onClickSegment}
            />
          </div>
          <div className="flex00a" style={{flexBasis:xAxisHeight}}>
            <svg width="100%" height="100%">
              <rect x="0%" y="0%" width="100%" height="100%" fill="none" stroke="#aaa" strokeWidth={gridLineWidthPx + "px"} />
              <TimeGraphMonthVerticalLinesAndLabels
                p_binsObj={p_binsObj}
                p_gridLineWidthPx={gridLineWidthPx}
                p_labelsTF={true}
                p_todayLineTF={true}
              />
            </svg>
          </div>
        </div>
        {(p_legendTF) &&
          <div className="flex11a yScroll smallFullPad" style={{flexBasis:"100em", minWidth:"15em", fontSize:p_fontSizePx + "px"}}>
            {p_categoryLineDataArrayOfObjs.map((m_categoryLineDataObj, m_categoryIndex) =>
              (!m_categoryLineDataObj.hideLineAndCategoryTF) &&
              <TimeGraphLegendItem
                p_categoryLineDataObj={m_categoryLineDataObj}
                p_categoryIndex={m_categoryIndex}
                p_selectedCategoryIndicesArray={s_selectedCategoryIndicesArray}
                p_binsObj={p_binsObj}
                p_legendRowHeight={legendRowHeight}
                p_legendLrPadding={legendLrPadding}
                p_legendColorWidth={legendColorWidth}
                p_legendColorHeight={legendColorHeight}
                f_onClickCheckbox={this.onclick_legend_highlight_category_index}
                f_onClickSegment={this.props.f_onClickSegment}
              />
            )}
          </div>
        }
      </div>
    );
  }
}



class TimeGraphLegendItem extends Component { //props: p_categoryLineDataObj, p_categoryIndex, p_selectedCategoryIndicesArray, p_binsObj, p_legendRowHeight, p_legendLrPadding, p_legendColorWidth, p_legendColorHeight, f_onClickCheckbox, f_onClickSegment
  onclick_legend_checkbox_highlight_line = () => {
    const p_categoryIndex = this.props.p_categoryIndex;

    if(this.props.f_onClickCheckbox) {
      this.props.f_onClickCheckbox(p_categoryIndex);
    }
  }

  onclick_legend_category_name = () => {
    const p_categoryLineDataObj = this.props.p_categoryLineDataObj;
    const p_binsObj = this.props.p_binsObj;
    if(JSFUNC.is_function(this.props.f_onClickSegment)) {
      const fakeBinObj = {leftDate:p_binsObj.startDate, rightDate:p_binsObj.endDate};
      this.props.f_onClickSegment(p_categoryLineDataObj.lineIDsArray, p_categoryLineDataObj, fakeBinObj);
    }
  }

  render() {
    const p_categoryLineDataObj = this.props.p_categoryLineDataObj;
    const p_categoryIndex = this.props.p_categoryIndex;
    const p_selectedCategoryIndicesArray = this.props.p_selectedCategoryIndicesArray;
    const p_binsObj = this.props.p_binsObj;
    const p_legendRowHeight = this.props.p_legendRowHeight;
    const p_legendLrPadding = this.props.p_legendLrPadding;
    const p_legendColorWidth = this.props.p_legendColorWidth;
    const p_legendColorHeight = this.props.p_legendColorHeight;

    const isSelectedLegendCategoryTF = JSFUNC.in_array(p_categoryIndex, p_selectedCategoryIndicesArray);

    const checkboxTitle = p_categoryLineDataObj.label + "\n[Check the box to highlight the line on the graph]";

    return(
      <div className={"displayFlexRow cursorPointer " + ((isSelectedLegendCategoryTF) ? ("bgBlueGradient fontWhite") : ("hoverLighterBlueGradient"))}>
        <div
          className="flex00a displayFlexColumnHcVc"
          style={{padding:"0 0.1em"}}
          title={checkboxTitle}
          onClick={this.onclick_legend_checkbox_highlight_line}>
          <CheckBox
            p_u0_s1_p2_du3_ds4={((isSelectedLegendCategoryTF) ? (1) : (0))}
            p_sizeEm={0.9}
            f_onClick={undefined}
          />
        </div>
        <div
          className="flex11a displayFlexRow"
          title={p_categoryLineDataObj.lineTitle}
          onClick={this.onclick_legend_category_name}>
          <div className="flex00a displayFlexColumnHcVc" style={{height:p_legendRowHeight, padding:p_legendLrPadding}}>
            <div className="flex00a border bevelBorderColors" style={{width:p_legendColorWidth, height:p_legendColorHeight, background:"#" + p_categoryLineDataObj.color}} />
          </div>
          <div className="flex11a" style={{height:p_legendRowHeight, padding:p_legendLrPadding}}>
            <Nowrap>
              {p_categoryLineDataObj.label}
            </Nowrap>
          </div>
        </div>
      </div>
    );
  }
}


class TimeGraphLineGraphSvg extends Component { //props: p_categoryLineDataArrayOfObjs, p_timeGraphTypeObj, p_binsObj, p_yAxisTicksArrayOfObjs, p_heightPx, p_gridLineWidthPx, p_selectedCategoryIndicesArray, f_onClickSegment
  //loop over all categories, for each category, plot all line segments first, then all dots so that dots are on top
  constructor(props) {
    super(props);
    this.state = {
      s_overlappingCirclesBinIndex: undefined,
      s_overlappingCircleCategoryIndicesArray: undefined
    }
  }

  onclick_line_graph_dot = (i_categoryIndex, i_binIndex) => {
    const p_categoryLineDataArrayOfObjs = this.props.p_categoryLineDataArrayOfObjs;
    const p_timeGraphTypeObj = this.props.p_timeGraphTypeObj;
    const p_yAxisTicksArrayOfObjs = this.props.p_yAxisTicksArrayOfObjs;
    const p_heightPx = this.props.p_heightPx;

    const numCategories = p_categoryLineDataArrayOfObjs.length;
    const numYTicks = p_yAxisTicksArrayOfObjs.length;

    var overlappingCircleCategoryIndicesArray = []; //category from clicked circle is always included (may be the only category on the graph)
    if((p_timeGraphTypeObj.lineTF || p_timeGraphTypeObj.lineCumulativeTF) && (numCategories > 1) && JSFUNC.is_array(p_yAxisTicksArrayOfObjs) && (numYTicks > 0)) { //line graphs, multiple categories, need to determine if any other circles in this bin are close to the clicked one (requires knowing greatest yTick value)
      //information about the clicked circle
      const clickedCategoryLineDataObj = p_categoryLineDataArrayOfObjs[i_categoryIndex];
      const clickedBinDotsArrayOfObjs = clickedCategoryLineDataObj.binDotsArrayOfObjs;
      const clickedBinDotObj = clickedBinDotsArrayOfObjs[i_binIndex];
      const clickedCaptureIDsArray = clickedBinDotObj.idsArray;
      const clickedValueTotal = clickedBinDotObj.valueTotal;

      //compute the diameter of a circle on the graph converting px to yAxis units
      const maxYTickObj = p_yAxisTicksArrayOfObjs[numYTicks - 1];
      const maxYTickValue = maxYTickObj.value;
      const circleRadiusPx = ((p_heightPx / 150) + 4);
      const circleDiameterPx = (circleRadiusPx * 2);
      const circleDiameterValue = ((circleDiameterPx / p_heightPx) * maxYTickValue);

      for(let c = 0; c < numCategories; c++) {
        if(c === i_categoryIndex) { //always include the clicked category without needing to check its position
          overlappingCircleCategoryIndicesArray.push(c);
        }
        else { //other dots in this vertical time bin from other categories that may/may not be overlapping
          var categoryLineDataObj = p_categoryLineDataArrayOfObjs[c];
          var binDotsArrayOfObjs = categoryLineDataObj.binDotsArrayOfObjs;
          var binDotObj = binDotsArrayOfObjs[i_binIndex];
          var captureIDsArray = binDotObj.idsArray;
          var valueTotal = binDotObj.valueTotal;

          //determine if dots from other categories within the same time bin fall within +/- 1 circle diameter from the center of the clicked circle, if so, add it to the overlapping circles array
          if((valueTotal < (clickedValueTotal + circleDiameterValue)) && (valueTotal > (clickedValueTotal - circleDiameterValue))) {
            overlappingCircleCategoryIndicesArray.push(c);
          }
        }
      }
    }
    else { //only 1 category on the graph
      overlappingCircleCategoryIndicesArray = [i_categoryIndex]; //category from clicked circle is always included (may be the only category on the graph)
    }

    if(overlappingCircleCategoryIndicesArray.length > 1) { //overlapping circles, open the floating box to pick one
      this.setState({
        s_overlappingCirclesBinIndex: i_binIndex,
        s_overlappingCircleCategoryIndicesArray: overlappingCircleCategoryIndicesArray,
      });
    }
    else { //click dot is sufficiently alone, proceed to single click action for the dot
      this.onclick_line_graph_single_dot(i_categoryIndex, i_binIndex);
    }
  }

  onclick_overlapping_circle_selection = (i_categoryIndex, i_binIndex) => {
    this.onclick_close_overlapping_circles_floating_box();
    this.onclick_line_graph_single_dot(i_categoryIndex, i_binIndex);
  }

  onclick_close_overlapping_circles_floating_box = () => {
    this.setState({
      s_overlappingCirclesBinIndex: undefined,
      s_overlappingCircleCategoryIndicesArray: undefined
    });
  }

  onclick_line_graph_single_dot = (i_categoryIndex, i_binIndex) => {
    if(JSFUNC.is_function(this.props.f_onClickSegment)) {
      const p_categoryLineDataArrayOfObjs = this.props.p_categoryLineDataArrayOfObjs;

      const categoryLineDataObj = p_categoryLineDataArrayOfObjs[i_categoryIndex];
      const binDotsArrayOfObjs = categoryLineDataObj.binDotsArrayOfObjs;
      const binDotObj = binDotsArrayOfObjs[i_binIndex];
      const binObj = binDotObj.binObj;
      const captureIDsArray = binDotObj.idsArray;
      this.props.f_onClickSegment(captureIDsArray, categoryLineDataObj, binObj);
    }
  }

  render() {
    const s_overlappingCirclesBinIndex = this.state.s_overlappingCirclesBinIndex;
    const s_overlappingCircleCategoryIndicesArray = this.state.s_overlappingCircleCategoryIndicesArray;

    const p_categoryLineDataArrayOfObjs = this.props.p_categoryLineDataArrayOfObjs;
    const p_timeGraphTypeObj = this.props.p_timeGraphTypeObj;
    const p_binsObj = this.props.p_binsObj;
    const p_yAxisTicksArrayOfObjs = this.props.p_yAxisTicksArrayOfObjs;
    const p_heightPx = this.props.p_heightPx;
    const p_gridLineWidthPx = this.props.p_gridLineWidthPx;
    const p_selectedCategoryIndicesArray = this.props.p_selectedCategoryIndicesArray;

    const onClickSegmentFunctionIsValidTF = JSFUNC.is_function(this.props.f_onClickSegment);

    const lineWidthPx = ((p_heightPx / 200) + 4);
    const circleRadiusPx = ((p_heightPx / 150) + 4);
    const circleBorderWidthPx = ((p_heightPx / 1000) + 0.5);

    var allCircleLineOrBarSvgsComponent = null;
    if(p_timeGraphTypeObj.barTF || p_timeGraphTypeObj.barPercentOf100TF) {
      allCircleLineOrBarSvgsComponent = (
        p_categoryLineDataArrayOfObjs.map((m_categoryLineDataObj, m_categoryIndex) =>
          (!m_categoryLineDataObj.hideLineAndCategoryTF) &&
          m_categoryLineDataObj.binDotsArrayOfObjs.map((m_binDotObj, m_binIndex) =>
            (m_binDotObj.barHeight > 0) && 
            <TimeGraphBarGraphBar
              p_categoryIndex={m_categoryIndex}
              p_binIndex={m_binIndex}
              p_categoryLineDataObj={m_categoryLineDataObj}
              p_binDotObj={m_binDotObj}
              p_barBorderWidthPx={circleBorderWidthPx}
              p_selectedCategoryIndicesArray={p_selectedCategoryIndicesArray}
              f_onClick={((onClickSegmentFunctionIsValidTF) ? (this.onclick_line_graph_dot) : (undefined))}
            />
          )
        )
      );
    }
    else {
      allCircleLineOrBarSvgsComponent = (
        p_categoryLineDataArrayOfObjs.map((m_categoryLineDataObj, m_categoryIndex) =>
          (!m_categoryLineDataObj.hideLineAndCategoryTF) &&
          <>
            {m_categoryLineDataObj.binDotsArrayOfObjs.map((m_binDotObj, m_binIndex) =>
              (m_binIndex > 0) &&
              <line
                x1={m_categoryLineDataObj.binDotsArrayOfObjs[m_binIndex-1].binObj.centerPos + "%"}
                y1={m_categoryLineDataObj.binDotsArrayOfObjs[m_binIndex-1].yPos + "%"}
                x2={m_binDotObj.binObj.centerPos + "%"}
                y2={m_binDotObj.yPos + "%"}
                style={{stroke:"#" + m_categoryLineDataObj.color, strokeWidth:lineWidthPx + "px", opacity:(((p_selectedCategoryIndicesArray.length > 0) && !JSFUNC.in_array(m_categoryIndex, p_selectedCategoryIndicesArray)) ? (0.1) : (undefined))}}
              />
            )}
            {m_categoryLineDataObj.binDotsArrayOfObjs.map((m_binDotObj, m_binIndex) =>
              <TimeGraphLineGraphCircle
                p_categoryIndex={m_categoryIndex}
                p_binIndex={m_binIndex}
                p_categoryLineDataObj={m_categoryLineDataObj}
                p_binDotObj={m_binDotObj}
                p_circleRadiusPx={circleRadiusPx}
                p_circleBorderWidthPx={circleBorderWidthPx}
                p_selectedCategoryIndicesArray={p_selectedCategoryIndicesArray}
                f_onClick={((onClickSegmentFunctionIsValidTF) ? (this.onclick_line_graph_dot) : (undefined))}
              />
            )}
          </>
        )
      );
    }

    return(
      <>
        <svg width="100%" height="100%">
          <rect x="0%" y="0%" width="100%" height="100%" fill="none" stroke="#aaa" strokeWidth={p_gridLineWidthPx + "px"} />
          <YAxisTicksHorizontalLines
            p_yAxisTicksArrayOfObjs={p_yAxisTicksArrayOfObjs}
            p_gridLineWidthPx={p_gridLineWidthPx}
            p_gridLineColor="#ddd"
          />
          <TimeGraphMonthVerticalLinesAndLabels
            p_binsObj={p_binsObj}
            p_gridLineWidthPx={p_gridLineWidthPx}
            p_labelsTF={false}
            p_todayLineTF={false}
          />
          {allCircleLineOrBarSvgsComponent}
        </svg>
        {(s_overlappingCircleCategoryIndicesArray !== undefined) &&
          <FloatingBox
            p_tb="20%"
            p_lr="35%"
            f_onKeyDownEsc={this.onclick_close_overlapping_circles_floating_box}>
            <div className="flex00a" style={{padding:"0.4em 0.4em", borderBottom:"solid 1px #ccc"}}>
              <font className="font11 fontItalic">
                {"Overlapping Datapoint Disambiguation"}
              </font>
            </div>
            <div className="flex11a yScroll yScrollBottomPad">
              {s_overlappingCircleCategoryIndicesArray.map((m_categoryIndex) =>
                <OverlappingCircleFloatingBoxSelectionItem
                  p_categoryLineDataArrayOfObjs={p_categoryLineDataArrayOfObjs}
                  p_categoryIndex={m_categoryIndex}
                  p_binIndex={s_overlappingCirclesBinIndex}
                  f_onClick={((onClickSegmentFunctionIsValidTF) ? (this.onclick_overlapping_circle_selection) : (undefined))}
                />
              )}
            </div>
          </FloatingBox>
        }
      </>
    );
  }
}

class TimeGraphBarGraphBar extends Component { //props: p_categoryIndex, p_binIndex, p_categoryLineDataObj, p_binDotObj, p_barBorderWidthPx, p_selectedCategoryIndicesArray, f_onClick
  onclick_time_graph_bar = () => {
    if(this.props.f_onClick !== undefined) {
      this.props.f_onClick(this.props.p_categoryIndex, this.props.p_binIndex);
    }
  }

  render() {
    const p_categoryIndex = this.props.p_categoryIndex;
    const p_categoryLineDataObj = this.props.p_categoryLineDataObj;
    const p_binDotObj = this.props.p_binDotObj;
    const p_barBorderWidthPx = this.props.p_barBorderWidthPx;
    const p_selectedCategoryIndicesArray = this.props.p_selectedCategoryIndicesArray;

    const categoryLabel = p_categoryLineDataObj.label;
    const categoryColor = p_categoryLineDataObj.color;

    const binObj = p_binDotObj.binObj;
    const idsArray = p_binDotObj.idsArray;
    const valueTotal = p_binDotObj.valueTotal;
    const title = p_binDotObj.title;
    const barTopPos = p_binDotObj.barTopPos;
    const barHeight = p_binDotObj.barHeight;

    const barLeftPos = binObj.barLeftPos;
    const barRightPos = binObj.barRightPos;
    const barWidth = binObj.barWidth;

    const canClickTF = JSFUNC.is_function(this.props.f_onClick);

    const barCursor = ((canClickTF) ? ("pointer") : (undefined));

    var barOpacity = undefined;
    if((p_selectedCategoryIndicesArray.length > 0) && !JSFUNC.in_array(p_categoryIndex, p_selectedCategoryIndicesArray)) {
      barOpacity = 0.1;
    }

    const barSvgComponent = (
      <rect
        x={barLeftPos + "%"}
        y={barTopPos + "%"}
        width={barWidth + "%"}
        height={barHeight + "%"}
        stroke="#999999"
        strokeWidth={p_barBorderWidthPx + "px"}
        style={{fill:"#" + categoryColor, cursor:barCursor, opacity:barOpacity}}
        onClick={((canClickTF) ? (this.onclick_time_graph_bar) : (undefined))}
      />
    );

    if(title !== undefined) {
      return(
        <g>
          <title>{title}</title>
          {barSvgComponent}
        </g>
      );
    }

    return(
      barSvgComponent
    );
  }
}

class TimeGraphLineGraphCircle extends Component { //props: p_categoryIndex, p_binIndex, p_categoryLineDataObj, p_binDotObj, p_circleRadiusPx, p_circleBorderWidthPx, p_selectedCategoryIndicesArray, f_onClick
  onclick_time_graph_circle = () => {
    if(this.props.f_onClick !== undefined) {
      this.props.f_onClick(this.props.p_categoryIndex, this.props.p_binIndex);
    }
  }

  render() {
    const p_categoryIndex = this.props.p_categoryIndex;
    const p_categoryLineDataObj = this.props.p_categoryLineDataObj;
    const p_binDotObj = this.props.p_binDotObj;
    const p_circleRadiusPx = this.props.p_circleRadiusPx;
    const p_circleBorderWidthPx = this.props.p_circleBorderWidthPx;
    const p_selectedCategoryIndicesArray = this.props.p_selectedCategoryIndicesArray;

    const categoryLabel = p_categoryLineDataObj.label;
    const categoryColor = p_categoryLineDataObj.color;

    const binObj = p_binDotObj.binObj;
    const idsArray = p_binDotObj.idsArray;
    const valueTotal = p_binDotObj.valueTotal;
    const title = p_binDotObj.title;
    const yPos = p_binDotObj.yPos;

    const leftDate = binObj.leftDate;
    const rightDate = binObj.rightDate;
    const centerPos = binObj.centerPos;

    const canClickTF = JSFUNC.is_function(this.props.f_onClick);

    const circleCursor = ((canClickTF) ? ("pointer") : (undefined));

    var circleOpacity = undefined;
    if((p_selectedCategoryIndicesArray.length > 0) && !JSFUNC.in_array(p_categoryIndex, p_selectedCategoryIndicesArray)) {
      circleOpacity = 0.1;
    }

    const circleSvgComponent = (
      <circle
        r={p_circleRadiusPx + "px"}
        cx={centerPos + "%"}
        cy={yPos + "%"}
        stroke="#999999"
        strokeWidth={p_circleBorderWidthPx + "px"}
        style={{fill:"#" + categoryColor, cursor:circleCursor, opacity:circleOpacity}}
        onClick={((canClickTF) ? (this.onclick_time_graph_circle) : (undefined))}
      />
    );

    if(title !== undefined) {
      return(
        <g>
          <title>{title}</title>
          {circleSvgComponent}
        </g>
      );
    }

    return(
      circleSvgComponent
    );
  }
}

class OverlappingCircleFloatingBoxSelectionItem extends Component { //props: p_categoryLineDataArrayOfObjs, p_categoryIndex, p_binIndex, f_onClick
  onclick_overlapping_circle_selection_item = () => {
    if(this.props.f_onClick) {
      this.props.f_onClick(this.props.p_categoryIndex, this.props.p_binIndex);
    }
  }

  render() {
    const p_categoryLineDataArrayOfObjs = this.props.p_categoryLineDataArrayOfObjs;
    const p_categoryIndex = this.props.p_categoryIndex;
    const binIndex = this.props.p_binIndex;

    const categoryLineDataObj = p_categoryLineDataArrayOfObjs[p_categoryIndex];
    const binDotsArrayOfObjs = categoryLineDataObj.binDotsArrayOfObjs;
    const categoryLabel = categoryLineDataObj.label;
    const categoryColor = categoryLineDataObj.color;
    const binDotObj = binDotsArrayOfObjs[binIndex];

    return(
      <div
        className="displayFlexRow hoverLighterBlueGradient cursorPointer"
        style={{height:"3.3em", border:"solid 1px #eee", borderBottomColor:"#bbb", borderRightColor:"#bbb"}}
        onClick={this.onclick_overlapping_circle_selection_item}>
        <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"3em"}}>
          <div style={{height:"1.5em", width:"1.5em", border:"solid 1px #999", borderRadius:"1em", background:"#" + categoryColor}} />
        </div>
        <div className="flex11a displayFlexRowVc lrPad">
          <MaxHeightWrap p_maxHeight="3em">
            {categoryLabel}
          </MaxHeightWrap>
        </div>
      </div>
    );
  }
}


function TimeGraphMonthVerticalLinesAndLabels(props) { //props: p_binsObj, p_gridLineWidthPx, p_labelsTF, p_todayLineTF
  const binsObj = props.p_binsObj;
  const gridLineWidthPx = props.p_gridLineWidthPx;
  const labelsTF = props.p_labelsTF;
  const todayLineTF = props.p_todayLineTF;

  const monthBinsArrayOfObjs = binsObj.monthBinsArrayOfObjs;
  const todayPos = binsObj.todayPos;
  const totalNumDays = binsObj.totalNumDays;

  const drawMonthLinesTF = (totalNumDays < 600);
  const shortMonthNamesTF = (totalNumDays > 400);

  var monthLinesComponentsArray = [];
  for(let monthBinObj of monthBinsArrayOfObjs) {
    var monthIsJanuaryTF = (monthBinObj.monthIndex0to11 === 0);
    if(drawMonthLinesTF || monthIsJanuaryTF) {
      monthLinesComponentsArray.push(
        <line x1={monthBinObj.leftPos + "%"} y1="0%" x2={monthBinObj.leftPos + "%"} y2="100%" style={{stroke:"#ccc", strokeWidth:gridLineWidthPx + "px"}} />
      );

      if(labelsTF) {
        var label = undefined;
        if(drawMonthLinesTF) {
          if(shortMonthNamesTF) {
            if(monthIsJanuaryTF) {
              label = monthBinObj.yr;
            }
            else {
              label = monthBinObj.mLabel;
            }
          }
          else {
            label = monthBinObj.mthLabel + " " + monthBinObj.yr;
          }
        }
        else { //year lines only
          label = monthBinObj.year;
        }

        monthLinesComponentsArray.push(
          <text x={(monthBinObj.leftPos + 0.5) + "%"} y="70%" fill="#333">
            {label}
          </text>
        );
      }
    }
  }

  //add the today line if it is not undefined (meaning today is outside of the start/end date window)
  if(todayLineTF && todayPos !== undefined) {
    monthLinesComponentsArray.push(
      <line x1={todayPos + "%"} y1="0%" x2={todayPos + "%"} y2="100%" style={{stroke:"#005da3", strokeWidth:(gridLineWidthPx * 3) + "px"}} />
    );
  }

  return(
    monthLinesComponentsArray
  );
}




export function YAxisWithLabelsLeftColumn(props) { //props: p_yAxisTicksArrayOfObjs, p_yAxisWidthEm, p_fontClass
  return(
    <div className="flex11a positionRelative">
      {props.p_yAxisTicksArrayOfObjs.map((m_yTickObj) =>
        <div className="positionAbsolute textRight" style={{left:"0", top:"calc(" + m_yTickObj.svgPos0to100 + "% - 0.6em)", width:(props.p_yAxisWidthEm - 0.4) + "em", height:"1em"}}>
          <Nowrap p_fontClass={props.p_fontClass}>
            {m_yTickObj.valueLabel}
          </Nowrap>
        </div>
      )}
    </div>
  );
}

function XAxisWithLabelsBottomRow(props) { //props: p_xAxisTicksArrayOfObjs
  const numTicks = props.p_xAxisTicksArrayOfObjs.length;
  return(
    <svg width="100%" height="100%">
      {props.p_xAxisTicksArrayOfObjs.map((m_xTickObj) =>
        <text x={(m_xTickObj.svgPos0to100) + "%"} y="70%" fill="#333">
          {m_xTickObj.valueLabel}
        </text>
      )}
    </svg>
  );
}

export function YAxisTicksHorizontalLines(props) { //props: p_yAxisTicksArrayOfObjs, p_gridLineWidthPx, p_gridLineColor
  return(
    props.p_yAxisTicksArrayOfObjs.map((m_yTickObj) =>
      <line x1="0%" y1={m_yTickObj.svgPos0to100 + "%"} x2="100%" y2={m_yTickObj.svgPos0to100 + "%"} style={{stroke:props.p_gridLineColor, strokeWidth:props.p_gridLineWidthPx + "px"}} />
    )
  );
}

function XAxisTicksVerticalLines(props) { //props: p_xAxisTicksArrayOfObjs, p_gridLineWidthPx, p_gridLineColor
  return(
    props.p_yAxisTicksArrayOfObjs.map((m_yTickObj) =>
      <line x1={m_yTickObj.svgPos0to100 + "%"} y1="0%" x2={m_yTickObj.svgPos0to100 + "%"} y2="100%" style={{stroke:props.p_gridLineColor, strokeWidth:props.p_gridLineWidthPx + "px"}} />
    )
  );
}







//--------------------------------------------------------------------------------------------------------------------------------





export function StackedAreaGraph(props) { //props: 
  //p_stackedAreaCategoriesArrayOfObjs, p_overlayLinesCategoriesArrayOfObjs, p_binLabelsArray, p_binGroupLabelsArray, p_graphTitle, p_graphSubtitle, p_valueFormat, p_yLogScaleTF, p_yAxisWidthEm, p_fontSizePx
  //
  //---inputs---
  //p_stackedAreaCategoriesArrayOfObjs
  //  - valuesPerBinArray
  //  - clickIDsPerBinArrayOfArrays
  //  - label
  //  - color
  //p_overlayLinesCategoriesArrayOfObjs
  //  - valuesPerBinArray
  //  - clickIDsPerBinArrayOfArrays
  //  - label
  //  - color
  //
  //---internal---
  //calcStackedAreaCategoriesArrayOfObjs
  //  - valuesPerBinArray
  //  - clickIDsPerBinArrayOfArrays
  //  - label
  //  - color
  //  - bottomCumulativeValuesArray
  //  - topCumulativeValuesArray
  //  - bottomCumulativeYPosArray
  //  - topCumulativeYPosArray
  //  - darkColor
  //calcOverlayLinesCategoriesArrayOfObjs
  //  - valuesPerBinArray
  //  - clickIDsPerBinArrayOfArrays
  //  - label
  //  - color
  //  - topCumulativeValuesArray
  //  - topCumulativeYPosArray
  //  - darkColor
  //calcBinsArrayOfObjs
  //  - binLabel
  //  - xPos

  const p_stackedAreaCategoriesArrayOfObjs = props.p_stackedAreaCategoriesArrayOfObjs;
  const p_overlayLinesCategoriesArrayOfObjs = props.p_overlayLinesCategoriesArrayOfObjs;
  const p_binLabelsArray = props.p_binLabelsArray;
  const p_binGroupLabelsArray = props.p_binGroupLabelsArray;
  const p_graphTitle = props.p_graphTitle;
  const p_graphSubtitle = props.p_graphSubtitle;
  const p_valueFormat = JSFUNC.prop_value(props.p_valueFormat, "number"); //"number", "percent", "money", "moneyShort"
  const p_yLogScaleTF = JSFUNC.prop_value(props.p_yLogScaleTF, false);
  const p_yAxisWidthEm = JSFUNC.prop_value(props.p_yAxisWidthEm, 5);
  const p_heightPx = JSFUNC.prop_value(props.p_heightPx, 400);
  const p_fontSizePx = JSFUNC.prop_value(props.p_fontSizePx, 12);

  const includeGraphTitleTF = JSFUNC.is_string(p_graphTitle);
  const includeGraphSubtitleTF = JSFUNC.is_string(p_graphSubtitle);
  const legendTF = true;

  const darkColorDecToAdd = -40;

  const numBins = p_binLabelsArray.length;

  //find maximum value of any stacked area or overlay line while calculating through the raw data
  var maximumTotalValueOfAnyCategory = 1;

  //calculated versions of stacked areas raw input data
  var calcStackedAreaCategoriesArrayOfObjs = [];
  var bottomCumulativeValuesArray = JSFUNC.array_fill(numBins, 0); //first area runs along bottom x-axis at y=0, all subsequent stacked areas use previous values as bottom
  var topCumulativeValuesArray = JSFUNC.array_fill(numBins, 0);
  for(let stackedAreaCategoryObj of p_stackedAreaCategoriesArrayOfObjs) {
    var numValues = 0;
    if(JSFUNC.is_array_not_empty(stackedAreaCategoryObj.valuesPerBinArray)) {
      numValues = stackedAreaCategoryObj.valuesPerBinArray.length;
    }

    //initialize calc obj by copying all fields in obj
    var calcStackedAreaCategoryObj = JSFUNC.copy_obj(stackedAreaCategoryObj);

    for(let b = 0; b < numBins; b++) {
      var calcValue = 0; //initialize the value as 0, if the valuesPerBinArray is not an array or has less values than the number of bins, use 0
      if(numValues > 0) { //if valuesPerBinArray is an array with at least 1 value
        if(b < numValues) { //if this bin index is not beyond the total number of values in valuesPerBinArray
          calcValue = stackedAreaCategoryObj.valuesPerBinArray[b];
          if(!JSFUNC.is_number_not_nan_gte_0(calcValue)) { //don't allow negative values or non-numbers, force those values to 0
            calcValue = 0;
          }

          if(calcValue > maximumTotalValueOfAnyCategory) {
            maximumTotalValueOfAnyCategory = calcValue;
          }
        }
      }

      //calculate the top values stack on top of the previously calculated bottom values
      topCumulativeValuesArray[b] = (bottomCumulativeValuesArray[b] + calcValue);
    }

    calcStackedAreaCategoryObj.bottomCumulativeValuesArray = JSFUNC.copy_array(bottomCumulativeValuesArray);
    calcStackedAreaCategoryObj.topCumulativeValuesArray = JSFUNC.copy_array(topCumulativeValuesArray);
    calcStackedAreaCategoryObj.darkColor = JSFUNC.color_add_decimal_rgb_values(calcStackedAreaCategoryObj.color, darkColorDecToAdd, darkColorDecToAdd, darkColorDecToAdd, false);

    calcStackedAreaCategoriesArrayOfObjs.push(calcStackedAreaCategoryObj);

    //copy top cumulative values into bottom for next category
    bottomCumulativeValuesArray = JSFUNC.copy_array(topCumulativeValuesArray);
  }

  //calculated versions of overlay lines
  var calcOverlayLinesCategoriesArrayOfObjs = [];
  for(let overlayLineCategoryObj of p_overlayLinesCategoriesArrayOfObjs) {
    var numValues = 0;
    if(JSFUNC.is_array_not_empty(overlayLineCategoryObj.valuesPerBinArray)) {
      numValues = overlayLineCategoryObj.valuesPerBinArray.length;
    }

    //initialize calc obj by copying all fields in obj
    var calcOverlayLineCategoryObj = JSFUNC.copy_obj(overlayLineCategoryObj);

    var topCumulativeValuesArray = [];
    for(let b = 0; b < numBins; b++) {
      var calcValue = 0; //initialize the value as 0, if the valuesPerBinArray is not an array or has less values than the number of bins, use 0
      if(numValues > 0) { //if valuesPerBinArray is an array with at least 1 value
        if(b < numValues) { //if this bin index is not beyond the total number of values in valuesPerBinArray
          calcValue = overlayLineCategoryObj.valuesPerBinArray[b];
          if(!JSFUNC.is_number_not_nan_gte_0(calcValue)) { //don't allow negative values or non-numbers, force those values to 0
            calcValue = 0;
          }

          if(calcValue > maximumTotalValueOfAnyCategory) {
            maximumTotalValueOfAnyCategory = calcValue;
          }
        }
      }

      //calculate the top values stack on top of the previously calculated bottom values
      topCumulativeValuesArray.push(calcValue);
    }

    calcOverlayLineCategoryObj.topCumulativeValuesArray = topCumulativeValuesArray;
    calcOverlayLineCategoryObj.darkColor = JSFUNC.color_add_decimal_rgb_values(calcOverlayLineCategoryObj.color, darkColorDecToAdd, darkColorDecToAdd, darkColorDecToAdd, false);

    calcOverlayLinesCategoriesArrayOfObjs.push(calcOverlayLineCategoryObj);
  }

  //compute y axis obj
  const reversePos100to0TF = true; //reverse pos values for y axis ticks
  const axisMaxValueMultiplier = 1.06;
  const minValueAbove0 = 0;
  const yAxisObj = JSFUNC.create_axis_obj_from_max_value_and_height_px(0, maximumTotalValueOfAnyCategory, p_heightPx, reversePos100to0TF, p_valueFormat, p_yLogScaleTF, axisMaxValueMultiplier, minValueAbove0);

  //compute svg graph positions from yAxisObj and calcValues
  for(let calcStackedAreaCategoryObj of calcStackedAreaCategoriesArrayOfObjs) {
    var bottomCumulativeYPosArray = [];
    for(let bottomCumulativeValue of calcStackedAreaCategoryObj.bottomCumulativeValuesArray) {
      var yPosFullDecimals = JSFUNC.compute_svg_pos_0to100_from_value_and_axis_obj(bottomCumulativeValue, yAxisObj);
      var yPosRounded = JSFUNC.round_number_to_num_decimals_if_needed(yPosFullDecimals, 2);
      bottomCumulativeYPosArray.push(yPosRounded);
    }

    var topCumulativeYPosArray = [];
    for(let topCumulativeValue of calcStackedAreaCategoryObj.topCumulativeValuesArray) {
      var yPosFullDecimals = JSFUNC.compute_svg_pos_0to100_from_value_and_axis_obj(topCumulativeValue, yAxisObj);
      var yPosRounded = JSFUNC.round_number_to_num_decimals_if_needed(yPosFullDecimals, 2);
      topCumulativeYPosArray.push(yPosRounded);
    }

    calcStackedAreaCategoryObj.bottomCumulativeYPosArray = bottomCumulativeYPosArray;
    calcStackedAreaCategoryObj.topCumulativeYPosArray = topCumulativeYPosArray;
  }

  for(let calcOverlayLineCategoryObj of calcOverlayLinesCategoriesArrayOfObjs) {
    var topCumulativeYPosArray = [];
    for(let topCumulativeValue of calcOverlayLineCategoryObj.topCumulativeValuesArray) {
      var yPosFullDecimals = JSFUNC.compute_svg_pos_0to100_from_value_and_axis_obj(topCumulativeValue, yAxisObj);
      var yPosRounded = JSFUNC.round_number_to_num_decimals_if_needed(yPosFullDecimals, 2);
      topCumulativeYPosArray.push(yPosRounded);
    }

    calcOverlayLineCategoryObj.topCumulativeYPosArray = topCumulativeYPosArray;
  }

  //compute svg graph positions of center of each bin
  var calcBinsArrayOfObjs = [];
  for(let b = 0; b < numBins; b++) {
    var binWidthPercent = (100 / numBins);
    var xPosFullDecimals = ((b + 0.5) * binWidthPercent); //if there were 4 bins, they would have left/right/center percents of [0/12.5/25],[25/37.5/50],[50/62.5/75],[75/87.5/100] = ((bwp / 2) + (b * bwp)) = ((b + 1/2) * bwp)
    var xPosRounded = JSFUNC.round_number_to_num_decimals_if_needed(xPosFullDecimals, 2);
    calcBinsArrayOfObjs.push({
      binLabel: p_binLabelsArray[b],
      xPos: xPosRounded
    });
  }

  const xAxisHeight = "2.5em";
  const gridLineWidthPx = 1;

  const legendRowHeight = Math.round(1.2 * p_fontSizePx) + "px";
  const legendLrPadding = "0 0.2em";
  const legendColorWidth  = Math.round(0.9 * p_fontSizePx) + "px";
  const legendColorHeight  = Math.round(1.15 * p_fontSizePx) + "px";

  return(
    <div className="displayFlexColumn" style={{height:p_heightPx + "px"}}>
      {(includeGraphTitleTF) &&
        <div className="flex00a textCenter" style={{marginBottom:"0.3em"}}>
          <font className="font11 fontBold" style={{color:"666"}}>
            {p_graphTitle}
          </font>
        </div>
      }
      {(includeGraphSubtitleTF) &&
        <div className="flex00a textCenter" style={{marginBottom:"0.3em"}}>
          <font className="">
            {p_graphSubtitle}
          </font>
        </div>
      }
      <div className="flex11a displayFlexRow">
        <div className="flex11a displayFlexColumn" style={{flexBasis:"300em"}}>
          <div className="flex11a displayFlexRow">
            <div className="flex00a displayFlexColumn" style={{flexBasis:p_yAxisWidthEm + "em"}}>
              <YAxisWithLabelsLeftColumn
                p_yAxisTicksArrayOfObjs={yAxisObj.axisTicksArrayOfObjs}
                p_yAxisWidthEm={p_yAxisWidthEm}
              />
            </div>
            <div className="flex11a displayFlexColumn">
              <StackedAreaGraphSvg
                p_calcStackedAreaCategoriesArrayOfObjs={calcStackedAreaCategoriesArrayOfObjs}
                p_calcOverlayLinesCategoriesArrayOfObjs={calcOverlayLinesCategoriesArrayOfObjs}
                p_yAxisObj={yAxisObj}
                p_calcBinsArrayOfObjs={calcBinsArrayOfObjs}
                p_heightPx={p_heightPx}
                p_gridLineWidthPx={gridLineWidthPx}
              />
            </div>
          </div>
          <div className="flex00a displayFlexRow" style={{flexBasis:xAxisHeight}}>
            <div className="flex00a" style={{flexBasis:p_yAxisWidthEm + "em"}} />
            <div className="flex11a displayFlexColumn">
              <StackedAreaGraphXAxisLabels
                p_binLabelsArray={p_binLabelsArray}
                p_binGroupLabelsArray={p_binGroupLabelsArray}
              />
            </div>
          </div>
        </div>
        {(legendTF) &&
          <div className="flex11a yScroll smallFullPad" style={{flexBasis:"100em", minWidth:"15em", fontSize:p_fontSizePx + "px"}}>
            {calcStackedAreaCategoriesArrayOfObjs.map((m_calcStackedAreaCategoryObj) =>
              <StackedAreaGraphLegendItem
                p_calcStackedAreaCategoryObj={m_calcStackedAreaCategoryObj}
                p_legendRowHeight={legendRowHeight}
                p_legendLrPadding={legendLrPadding}
                p_legendColorWidth={legendColorWidth}
                p_legendColorHeight={legendColorHeight}
              />
            )}
            {calcOverlayLinesCategoriesArrayOfObjs.map((m_calcOverlayLineCategoryObj) =>
              <StackedAreaGraphLegendItem
                p_calcStackedAreaCategoryObj={m_calcOverlayLineCategoryObj}
                p_legendRowHeight={legendRowHeight}
                p_legendLrPadding={legendLrPadding}
                p_legendColorWidth={legendColorWidth}
                p_legendColorHeight={legendColorHeight}
              />
            )}
          </div>
        }
      </div>
    </div>
  );
}

class StackedAreaGraphLegendItem extends Component { //props: p_calcStackedAreaCategoryObj, p_legendRowHeight, p_legendLrPadding, p_legendColorWidth, p_legendColorHeight
  render() {
    const p_calcStackedAreaCategoryObj = this.props.p_calcStackedAreaCategoryObj;
    const p_legendRowHeight = this.props.p_legendRowHeight;
    const p_legendLrPadding = this.props.p_legendLrPadding;
    const p_legendColorWidth = this.props.p_legendColorWidth;
    const p_legendColorHeight = this.props.p_legendColorHeight;

    return(
      <div
        className="displayFlexRow"
        title={p_calcStackedAreaCategoryObj.label}>
        <div className="flex00a displayFlexColumnHcVc" style={{height:p_legendRowHeight, padding:p_legendLrPadding}}>
          <div className="flex00a border bevelBorderColors" style={{width:p_legendColorWidth, height:p_legendColorHeight, background:"#" + p_calcStackedAreaCategoryObj.color}} />
        </div>
        <div className="flex11a" style={{height:p_legendRowHeight, padding:p_legendLrPadding}}>
          <Nowrap>
            {p_calcStackedAreaCategoryObj.label}
          </Nowrap>
        </div>
      </div>
    );
  }
}

function StackedAreaGraphSvg(props) { //p_calcStackedAreaCategoriesArrayOfObjs, p_calcOverlayLinesCategoriesArrayOfObjs, p_yAxisObj, p_calcBinsArrayOfObjs, p_heightPx, p_gridLineWidthPx
  const p_calcStackedAreaCategoriesArrayOfObjs = props.p_calcStackedAreaCategoriesArrayOfObjs;
  const p_calcOverlayLinesCategoriesArrayOfObjs = props.p_calcOverlayLinesCategoriesArrayOfObjs;
  const p_yAxisObj = props.p_yAxisObj;
  const p_calcBinsArrayOfObjs = props.p_calcBinsArrayOfObjs;
  const p_heightPx = props.p_heightPx;
  const p_gridLineWidthPx = props.p_gridLineWidthPx;

  return(
    <div className="positionRelative flex11a displayFlexColumn">
      <svg width="100%" height="100%" style={{position:"absolute"}}>
        <rect x="0%" y="0%" width="100%" height="100%" fill="none" stroke="#aaa" strokeWidth={p_gridLineWidthPx + "px"} />
        <YAxisTicksHorizontalLines
          p_yAxisTicksArrayOfObjs={p_yAxisObj.axisTicksArrayOfObjs}
          p_gridLineWidthPx={p_gridLineWidthPx}
          p_gridLineColor="#ddd"
        />
      </svg>
      <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" style={{position:"absolute"}}>
        {p_calcStackedAreaCategoriesArrayOfObjs.map((m_stackedAreaCategoryObj) =>
          <StackedAreaSingleAreaSvg
            p_stackedAreaCategoryObj={m_stackedAreaCategoryObj}
            p_calcBinsArrayOfObjs={p_calcBinsArrayOfObjs}
          />
        )}
      </svg>
      <svg width="100%" height="100%" style={{position:"absolute"}}>
        {p_calcStackedAreaCategoriesArrayOfObjs.map((m_stackedAreaCategoryObj) =>
          <StackedAreaSingleLineWithDataPointCirclesSvg
            p_stackedAreaCategoryObj={m_stackedAreaCategoryObj}
            p_calcBinsArrayOfObjs={p_calcBinsArrayOfObjs}
            p_heightPx={p_heightPx}
          />
        )}
        {p_calcOverlayLinesCategoriesArrayOfObjs.map((m_calcOverlayLinesCategoryObj) =>
          <StackedAreaSingleLineWithDataPointCirclesSvg
            p_stackedAreaCategoryObj={m_calcOverlayLinesCategoryObj}
            p_calcBinsArrayOfObjs={p_calcBinsArrayOfObjs}
            p_heightPx={p_heightPx}
          />
        )}
      </svg>
    </div>
  );
}

function StackedAreaSingleAreaSvg(props) { //p_stackedAreaCategoryObj, p_calcBinsArrayOfObjs
  const p_stackedAreaCategoryObj = props.p_stackedAreaCategoryObj;
  const p_calcBinsArrayOfObjs = props.p_calcBinsArrayOfObjs;

  const numBins = p_calcBinsArrayOfObjs.length;

  //number of bins in p_calcBinsArrayOfObjs always matches number of values in bottomCumulativeYPosArray/topCumulativeYPosArray
  if((numBins === 0)) {
    return(null);
  }

  var polygonPointsString = "";

  //top from left to right
  for(let b = 0; b < numBins; b++) {
    if(polygonPointsString !== "") { polygonPointsString += " "; }
    polygonPointsString += p_calcBinsArrayOfObjs[b].xPos + "," + p_stackedAreaCategoryObj.topCumulativeYPosArray[b];
  }

  //bottom from right to left
  for(let b = (numBins - 1); b >= 0; b--) {
    if(polygonPointsString !== "") { polygonPointsString += " "; }
    polygonPointsString += p_calcBinsArrayOfObjs[b].xPos + "," + p_stackedAreaCategoryObj.bottomCumulativeYPosArray[b];
  }
  
  //repeat first point on top line to close polygon shape
  if(polygonPointsString !== "") { polygonPointsString += " "; }
  polygonPointsString += p_calcBinsArrayOfObjs[0].xPos + "," + p_stackedAreaCategoryObj.topCumulativeYPosArray[0];

  return(
    <polygon points={polygonPointsString} fill={"#" + p_stackedAreaCategoryObj.color} />
  );
}

class StackedAreaSingleLineWithDataPointCirclesSvg extends Component { //p_stackedAreaCategoryObj, p_calcBinsArrayOfObjs, p_heightPx
  onclick_stacked_area_line_circle = () => {

  }

  render() {
    const p_stackedAreaCategoryObj = this.props.p_stackedAreaCategoryObj;
    const p_calcBinsArrayOfObjs = this.props.p_calcBinsArrayOfObjs;
    const p_heightPx = this.props.p_heightPx;

    const numBins = p_calcBinsArrayOfObjs.length;
    const title = "hover";
    const canClickTF = true;

    const lineWidthPx = ((p_heightPx / 300) + 2);
    const circleRadiusPx = ((p_heightPx / 200) + 3);
    const circleBorderWidthPx = ((p_heightPx / 1000) + 0.5);
    const circleBorderColor = "999999";

    //number of bins in p_calcBinsArrayOfObjs always matches number of values in bottomCumulativeYPosArray/topCumulativeYPosArray
    if((numBins === 0)) {
      return(null);
    }

    var linesAndCirclesComponentsArray = [];

    //top from left to right
    for(let b = 1; b < numBins; b++) {
      linesAndCirclesComponentsArray.push(
        <line
          x1={p_calcBinsArrayOfObjs[b-1].xPos + "%"}
          y1={p_stackedAreaCategoryObj.topCumulativeYPosArray[b-1] + "%"}
          x2={p_calcBinsArrayOfObjs[b].xPos + "%"}
          y2={p_stackedAreaCategoryObj.topCumulativeYPosArray[b] + "%"}
          style={{strokeLinecap:"round", strokeWidth:lineWidthPx + "px", stroke:"#" + p_stackedAreaCategoryObj.darkColor}}
        />
      );
    }

    //top from left to right
    var circleSvgComponent = null;
    var circleSvgWithTitleComponent = null;
    for(let b = 0; b < numBins; b++) {
      circleSvgComponent = (
        <circle
          r={circleRadiusPx + "px"}
          cx={p_calcBinsArrayOfObjs[b].xPos + "%"}
          cy={p_stackedAreaCategoryObj.topCumulativeYPosArray[b] + "%"}
          stroke={"#" + circleBorderColor}
          strokeWidth={circleBorderWidthPx + "px"}
          style={{fill:"#" + p_stackedAreaCategoryObj.darkColor, cursor:((canClickTF) ? ("pointer") : (undefined))}}
          onClick={((canClickTF) ? (this.onclick_stacked_area_line_circle) : (undefined))}
        />
      );
    
      if(title !== undefined) {
        circleSvgWithTitleComponent = (
          <g>
            <title>{title}</title>
            {circleSvgComponent}
          </g>
        );
      }
      else {
        circleSvgWithTitleComponent = circleSvgComponent;
      }
      
      linesAndCirclesComponentsArray.push(circleSvgWithTitleComponent);
    }
    
    return(
      linesAndCirclesComponentsArray
    );
  }
}

function StackedAreaGraphXAxisLabels(props) { //props: p_binLabelsArray, p_binGroupLabelsArray
  const p_binLabelsArray = props.p_binLabelsArray;
  const p_binGroupLabelsArray = props.p_binGroupLabelsArray;

  const borderStyleString = "solid 1px #777";

  if(!JSFUNC.is_array_not_empty(p_binLabelsArray)) {
    return(null);
  }
  
  if(JSFUNC.is_array_not_empty(p_binGroupLabelsArray)) {
    const numBinLabels = p_binLabelsArray.length;
    const numBinGroupLabels = p_binGroupLabelsArray.length;

    var calcBinGroupsArrayOfObjs = [];
    var prevBinGroupLabel = undefined;
    var accumulatingFlexBasisEm = 0;
    var accumulatingSubBinLabelsArray = [];
    for(let b = 0; b < numBinLabels; b++) {
      var binLabel = p_binLabelsArray[b];
      var binGroupLabel = "";
      if(b < numBinGroupLabels) {
        binGroupLabel = p_binGroupLabelsArray[b];
      }

      if((b > 0) && (binGroupLabel !== prevBinGroupLabel)) { //new group label, cut off old accumulation and initialize new empty one (don't do this on first loop because prev labels will never match to initialized undefined)
        calcBinGroupsArrayOfObjs.push({
          label: prevBinGroupLabel,
          flexBasisEm: accumulatingFlexBasisEm,
          subBinLabelsArray: accumulatingSubBinLabelsArray
        });
        accumulatingFlexBasisEm = 100;
        accumulatingSubBinLabelsArray = [binLabel];
      }
      else {
        accumulatingFlexBasisEm += 100;
        accumulatingSubBinLabelsArray.push(binLabel);
      }

      prevBinGroupLabel = binGroupLabel;
    }

    calcBinGroupsArrayOfObjs.push({
      label: prevBinGroupLabel,
      flexBasisEm: accumulatingFlexBasisEm,
      subBinLabelsArray: accumulatingSubBinLabelsArray
    });

    return(
      <div className="flex11a displayFlexRow">
        {calcBinGroupsArrayOfObjs.map((m_calcBinGroupObj, m_index) =>
          <div className="flex11a displayFlexColum" style={{flexBasis:m_calcBinGroupObj.flexBasisEm + "em", borderLeft:((m_index === 0) ? (borderStyleString) : (undefined)), borderRight:borderStyleString}}>
            <div className="flex00a displayFlexRow">
              {m_calcBinGroupObj.subBinLabelsArray.map((m_subBinLabel, m_index) =>
                <div className="flex11a displayFlexColumnHcVc textCenter" style={{flexBasis:"100em"}}>
                  <font className="">
                    {m_subBinLabel}
                  </font>
                </div>
              )}
            </div>
            <div className="flex00a displayFlexColumnHcVc textCenter">
              <font className="">
                {m_calcBinGroupObj.label}
              </font>
            </div>
          </div>
        )}
      </div>
    );

  }
  
  return(
    <div className="flex11a displayFlexRow">
      {p_binLabelsArray.map((m_binLabel, m_index) =>
        <div className="flex11a displayFlexColumnHcVc textCenter" style={{flexBasis:"100em", borderLeft:((m_index === 0) ? (borderStyleString) : (undefined)), borderRight:borderStyleString}}>
          <font className="">
            {m_binLabel}
          </font>
        </div>
      )}
    </div>
  );
}






//--------------------------------------------------------------------------------------------------------------------------------





export function BoxPlot(props) { //props: p_minQ1MedQ3MaxArray, p_color, p_xAxisMax, p_heightPx, p_fontSizePx
  const minQ1MedQ3MaxArray = props.p_minQ1MedQ3MaxArray;
  var xAxisMax = props.p_xAxisMax;
  const color = JSFUNC.prop_value(props.p_color, "666");
  const p_heightPx = JSFUNC.prop_value(props.p_heightPx, 500);
  const p_fontSizePx = JSFUNC.prop_value(props.p_fontSizePx, 12);

  var plotGraphTF = false;
  var min = 0;
  var q1 = 0;
  var median = 0;
  var q3 = 0;
  var max = 0;
  var minPos = 0;
  var q1Pos = 0;
  var medianPos = 0;
  var q3Pos = 0;
  var maxPos = 0;
  if(JSFUNC.is_array(minQ1MedQ3MaxArray) && minQ1MedQ3MaxArray.length === 5) {
    min = minQ1MedQ3MaxArray[0];
    q1 = minQ1MedQ3MaxArray[1];
    median = minQ1MedQ3MaxArray[2];
    q3 = minQ1MedQ3MaxArray[3];
    max = minQ1MedQ3MaxArray[4];
    plotGraphTF = (max >= min); //only plot if there is variation in the data (not all the same value)
  }

  if(!JSFUNC.is_number(xAxisMax) || xAxisMax <= 0) {
    xAxisMax = max;
  }

  if(plotGraphTF) {
    minPos = ((min / xAxisMax) * 100);
    q1Pos = ((q1 / xAxisMax) * 100);
    medianPos =((median / xAxisMax) * 100);
    q3Pos = ((q3 / xAxisMax) * 100);
    maxPos = ((max / xAxisMax) * 100);
  }

  const widthPx = 800;
  const xAxisObj = JSFUNC.create_axis_obj_from_max_value_and_height_px(0, xAxisMax, widthPx, false, "number", false, 1.06, 0); //false - linear scale, false - linear x axis
  const xAxisTicksArrayOfObjs = xAxisObj.axisTicksArrayOfObjs;

  const hashColor = "#" + color;
  const graphLineWidthPx = 2;
  const minMaxTop = "30%";
  const minMaxBottom = "70%";
  const boxTop = "10%";
  const boxBottom = "90%";
  const gridLineWidthPx = 1;
  const xVerticalGridLineColor = "ddd";

  return(
    <div className="flex11a displayFlexColumn" style={{height:p_heightPx + "px"}}>
      <div className="flex11a">
        <svg width="100%" height="100%">
          <rect x="0%" y="0%" width="100%" height="100%" fill="none" stroke="#aaa" strokeWidth={gridLineWidthPx + "px"} />
          <ScatterPlotXAxisLinesAndLabels
            p_xAxisTicksArrayOfObjs={xAxisTicksArrayOfObjs}
            p_gridLineWidthPx={gridLineWidthPx}
            p_gridLineColor={xVerticalGridLineColor}
            p_labelsTF={false}
          />
          {(plotGraphTF) &&
            <>
              <line x1={minPos + "%"} y1={minMaxBottom} x2={minPos + "%"} y2={minMaxTop} style={{stroke:hashColor, strokeWidth:graphLineWidthPx + "px"}} />
              <line x1={minPos + "%"} y1="50%" x2={q1Pos + "%"} y2="50%" style={{stroke:hashColor, strokeWidth:graphLineWidthPx + "px"}} />
              <line x1={q1Pos + "%"} y1={boxBottom} x2={q1Pos + "%"} y2={boxTop} style={{stroke:hashColor, strokeWidth:graphLineWidthPx + "px"}} />
              <line x1={q1Pos + "%"} y1={boxBottom} x2={q3Pos + "%"} y2={boxBottom} style={{stroke:hashColor, strokeWidth:graphLineWidthPx + "px"}} />
              <line x1={medianPos + "%"} y1={boxBottom} x2={medianPos + "%"} y2={boxTop} style={{stroke:hashColor, strokeWidth:graphLineWidthPx + "px"}} />
              <line x1={q1Pos + "%"} y1={boxTop} x2={q3Pos + "%"} y2={boxTop} style={{stroke:hashColor, strokeWidth:graphLineWidthPx + "px"}} />
              <line x1={q3Pos + "%"} y1={boxBottom} x2={q3Pos + "%"} y2={boxTop} style={{stroke:hashColor, strokeWidth:graphLineWidthPx + "px"}} />
              <line x1={q3Pos + "%"} y1="50%" x2={maxPos + "%"} y2="50%" style={{stroke:hashColor, strokeWidth:graphLineWidthPx + "px"}} />
              <line x1={maxPos + "%"} y1={minMaxBottom} x2={maxPos + "%"} y2={minMaxTop} style={{stroke:hashColor, strokeWidth:graphLineWidthPx + "px"}} />
            </>
          }
        </svg>
      </div>
      <div className="flex00a" style={{flexBasis:"1.5em"}}>
        <svg width="100%" height="100%">
          <rect x="0%" y="0%" width="100%" height="100%" fill="none" stroke="#aaa" strokeWidth={gridLineWidthPx + "px"} />
          <ScatterPlotXAxisLinesAndLabels
            p_xAxisTicksArrayOfObjs={xAxisTicksArrayOfObjs}
            p_gridLineWidthPx={gridLineWidthPx}
            p_gridLineColor="#ccc"
            p_labelsTF={true}
          />
        </svg>
      </div>
    </div>
  );
}
