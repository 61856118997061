import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import * as JSFUNC from "../../Library/JSFUNC.js";
import * as LibraryReact from "../../Library/LibraryReact.js";
import * as CEGeneralReact from "../../CaptureExecGeneral/CEGeneralReact.js";

import * as JSPHP from "../../CaptureExecLocalDatabaseMobx/JSPHP.js";

import * as AdminIntegrationsReact from "../AdminIntegrations/AdminIntegrationsReact.js";
import * as OpenCaptureReact from "../OpenCapture/OpenCaptureReact.js";
import DatabaseMobx from '../../CaptureExecLocalDatabaseMobx/DatabaseMobx.js';


//# Captures Button opens Search Results Captures floating box
export const NumberOfAssignedCapturesCountButton = inject("CaptureExecMobx")(observer(
class NumberOfAssignedCapturesCountButton extends Component { //props: p_partialNumMatchingCaptures, p_matchingCaptureIDsArray, p_floatingBoxTitle
  onclick_num_captures_assigned = () => {
    const p_matchingCaptureIDsArray = this.props.p_matchingCaptureIDsArray;
    const p_floatingBoxTitle = this.props.p_floatingBoxTitle;

    this.props.CaptureExecMobx.a_set_view_specified_search_results_captures_in_floating_box_capture_ids_array_and_floating_box_title(p_matchingCaptureIDsArray, p_floatingBoxTitle);
  }

  render() {
    const p_partialNumMatchingCaptures = this.props.p_partialNumMatchingCaptures;
    const p_matchingCaptureIDsArray = this.props.p_matchingCaptureIDsArray;
    const p_floatingBoxTitle = this.props.p_floatingBoxTitle;

    const partialNumMatchingCapturesDecimalTrim = JSFUNC.round_number_to_num_decimals_if_needed(p_partialNumMatchingCaptures, 1);

    return(
      <LibraryReact.InteractiveDiv
        p_class="textCenter bgBlueGradient hoverLightBlueGradient border bevelBorderColors cursorPointer"
        p_styleObj={{width:"6em", borderRadius:"0.5em"}}
        f_onClick={this.onclick_num_captures_assigned}>
        <LibraryReact.Nowrap p_fontClass="fontBold fontWhite">
          {partialNumMatchingCapturesDecimalTrim}
        </LibraryReact.Nowrap>
      </LibraryReact.InteractiveDiv>
    );
  }
}));





//Search Result Captures
export const SearchResultsCapturesTable = inject("CapturesMobx")(observer(
class SearchResultsCapturesTable extends Component { //props: p_searchResultCapturesArrayOfObjs, p_initialSortIsCustomTF, p_doNotInitializeSortByAdminColumnsTF, p_checkedCaptureIDsArray, f_onClickCapture(i_cpatureID), f_onClickCheckBox(i_cpatureID)
  //p_searchResultCapturesArrayOfObjs - array of created searchResultCaptureObjs, use a copied arrayOfObjs var and not a MobX observable which throws an error when sorted within this function
  //p_checkedCaptureIDsArray (optional) - creates an extra first column with checkboxes controlled by this input when provided

  constructor(props) {
    super(props);

    const p_doNotInitializeSortByAdminColumnsTF = JSFUNC.prop_value(this.props.p_doNotInitializeSortByAdminColumnsTF, false); //if set to true, prevents initializing the table with the data sorted by the admin defined default sort column(s)

    const c_expandedSearchFieldsArrayOfObjs = this.props.CapturesMobx.c_expandedSearchFieldsArrayOfObjs;

    //get the admin specified default sort column and sort direction
    var adminDefaultSortedColumnSortFieldName = undefined;
    var adminDefaultSortedColumnIsAscTF = undefined;
    if(!p_doNotInitializeSortByAdminColumnsTF) {
      if(JSFUNC.is_array(c_expandedSearchFieldsArrayOfObjs)) {
        for(let expandedSearchFieldObj of c_expandedSearchFieldsArrayOfObjs) {
          if(expandedSearchFieldObj.sortColumnTF) {
            adminDefaultSortedColumnSortFieldName = expandedSearchFieldObj.sortFieldName;
            adminDefaultSortedColumnIsAscTF = expandedSearchFieldObj.sortColumnIsAscTF;
            break;
          }
        }
      }
    }

    this.state = {
      s_sortCapturesHeaderSortFieldName: adminDefaultSortedColumnSortFieldName,
      s_sortCapturesHeaderIsAscTF: adminDefaultSortedColumnIsAscTF,
      s_showAllCapturesTF: false
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.p_searchResultCapturesArrayOfObjs.length !== prevProps.p_searchResultCapturesArrayOfObjs.length) {
      this.setState({s_showAllCapturesTF: false});
    }
  }

  onclick_header_column = (i_sortFieldName) => {
    this.setState((i_state, i_props) => ({
      s_sortCapturesHeaderSortFieldName: i_sortFieldName,
      s_sortCapturesHeaderIsAscTF: ((i_state.s_sortCapturesHeaderSortFieldName === i_sortFieldName) ? (!i_state.s_sortCapturesHeaderIsAscTF) : (true))
    }));
  }

  onclick_show_all_captures = () => {
    this.setState({s_showAllCapturesTF: true});
  }

  render() {
    const s_sortCapturesHeaderSortFieldName = this.state.s_sortCapturesHeaderSortFieldName;
    const s_sortCapturesHeaderIsAscTF = this.state.s_sortCapturesHeaderIsAscTF;
    const s_showAllCapturesTF = this.state.s_showAllCapturesTF;

    const p_searchResultCapturesArrayOfObjs = this.props.p_searchResultCapturesArrayOfObjs;
    const p_initialSortIsCustomTF = JSFUNC.prop_value(this.props.p_initialSortIsCustomTF, false);
    const p_doNotInitializeSortByAdminColumnsTF = this.props.p_doNotInitializeSortByAdminColumnsTF;
    const p_checkedCaptureIDsArray = this.props.p_checkedCaptureIDsArray;

    const c_expandedSearchFieldsArrayOfObjs = this.props.CapturesMobx.c_expandedSearchFieldsArrayOfObjs;

    const numCaptures = p_searchResultCapturesArrayOfObjs.length;
    const maxNumCapturesShown = ((s_showAllCapturesTF) ? (JSFUNC.sort_max_mysqli_int()) : (50));
    const includeCheckboxColumnTF = JSFUNC.is_array(p_checkedCaptureIDsArray);
    const checkboxColumnWidthEm = 3;

    //compute the full width of the table by adding each column width
    var tableWidthEm = 0;
    for(let searchFieldObj of c_expandedSearchFieldsArrayOfObjs) {
      tableWidthEm += searchFieldObj.width_em;
    }

    var tableWidthWithCheckboxColumnEm = tableWidthEm;
    if(includeCheckboxColumnTF) {
      tableWidthWithCheckboxColumnEm += checkboxColumnWidthEm;
    }

    if(s_sortCapturesHeaderSortFieldName !== undefined) {
      JSFUNC.sort_arrayOfObjs(p_searchResultCapturesArrayOfObjs, s_sortCapturesHeaderSortFieldName, s_sortCapturesHeaderIsAscTF);
    }

    return(
      <>
        <div className="displayFlexRow bgBlueGradient borderB1ddd" style={{height:"2.3em", width:tableWidthWithCheckboxColumnEm + "em"}}>
          {(includeCheckboxColumnTF) &&
            <div className="flex00a" style={{flexBasis:"3em", borderRight:"solid 1px #eee"}} />
          }
          {c_expandedSearchFieldsArrayOfObjs.map((m_searchFieldObj, m_index) =>
            <SearchResultsCapturesTableHeaderColumn
              key={m_searchFieldObj.id}
              p_searchFieldObj={m_searchFieldObj}
              p_columnIndex={m_index}
              p_sortCapturesHeaderSortFieldName={s_sortCapturesHeaderSortFieldName}
              p_sortCapturesHeaderIsAscTF={s_sortCapturesHeaderIsAscTF}
              p_initialSortIsCustomTF={p_initialSortIsCustomTF}
              f_onClick={this.onclick_header_column}
            />
          )}
        </div>
        {p_searchResultCapturesArrayOfObjs.map((m_captureObj, m_index) =>
          (m_index < maxNumCapturesShown) &&
          <SearchResultsCapturesTableSingleCapture
            key={m_captureObj.id}
            p_captureObj={m_captureObj}
            p_tableWidthEm={tableWidthEm}
            p_checkedCaptureIDsArray={p_checkedCaptureIDsArray}
            p_checkboxColumnWidthEm={checkboxColumnWidthEm}
            f_onClick={this.props.f_onClickCapture}
            f_onClickCheckBox={this.props.f_onClickCheckBox}
          />
        )}
        {(numCaptures > maxNumCapturesShown) &&
          <ShowAllCapturesButtonAndMessage
            p_numFilteredCaptures={numCaptures}
            p_maxNumCapturesDrawn={maxNumCapturesShown}
            f_onClickShowAllCaptures={this.onclick_show_all_captures}
          />
        }
      </>
    );
  }
}));

class SearchResultsCapturesTableHeaderColumn extends Component { //props: p_searchFieldObj, p_columnIndex, p_sortCapturesHeaderSortFieldName, p_sortCapturesHeaderIsAscTF, p_initialSortIsCustomTF, f_onClick
  onclick_header_column = () => {
    if(JSFUNC.is_function(this.props.f_onClick)) {
      const p_searchFieldObj = this.props.p_searchFieldObj;
      this.props.f_onClick(p_searchFieldObj.sortFieldName);
    }
  }

  render() {
    const p_searchFieldObj = this.props.p_searchFieldObj;
    const p_columnIndex = this.props.p_columnIndex;
    const p_sortCapturesHeaderSortFieldName = this.props.p_sortCapturesHeaderSortFieldName;
    const p_sortCapturesHeaderIsAscTF = this.props.p_sortCapturesHeaderIsAscTF;
    const p_initialSortIsCustomTF = this.props.p_initialSortIsCustomTF;

    var isSortedTF = false;
    var isSortedAscTF = true;
    if(p_searchFieldObj.sortFieldName === p_sortCapturesHeaderSortFieldName) {
      isSortedTF = true;
      isSortedAscTF = p_sortCapturesHeaderIsAscTF;
    }
    else if((p_sortCapturesHeaderSortFieldName === undefined) && !p_initialSortIsCustomTF) {
      isSortedTF = p_searchFieldObj.sortColumnTF;
      isSortedAscTF= p_searchFieldObj.sortColumnIsAscTF;
    }

    return (
      <div
        className="flex00a displayFlexRow lrPad hoverLightBlueGradient cursorPointer"
        style={{flexBasis:p_searchFieldObj.width_em + "em", borderLeft:((p_columnIndex > 0) ? ("solid 1px #eee") : (undefined))}}
        title={p_searchFieldObj.fieldDisplayName}
        onClick={this.onclick_header_column}>
        <div className="flex00a displayFlexColumnHcVc textCenter" style={{flexBasis:"1em"}}>
          <CEGeneralReact.HeaderSortArrow p_isSortedTF={isSortedTF} p_sortedAscTF={isSortedAscTF} />
        </div>
        <div className="flex11a displayFlexColumnVc lrPad">
          <LibraryReact.Nowrap p_fontClass="fontItalic fontWhite">
            {p_searchFieldObj.fieldDisplayName}
          </LibraryReact.Nowrap>
        </div>
      </div>
    );
  }
}


const SearchResultsCapturesTableSingleCapture = inject("CapturesMobx", "OpenCaptureMobx")(observer(
class SearchResultsCapturesTableSingleCapture extends Component { //props: p_captureObj, p_tableWidthEm, p_checkedCaptureIDsArray, p_checkboxColumnWidthEm, f_onClick(i_captureID), f_onClickCheckBox(i_cpatureID)
  onclick_capture_result = () => {
    if(JSFUNC.is_function(this.props.f_onClick)) {
      this.props.f_onClick(this.props.p_captureObj.id);
    }
  }

  onclick_checkbox = () => {
    if(JSFUNC.is_function(this.props.f_onClickCheckBox)) {
      this.props.f_onClickCheckBox(this.props.p_captureObj.id);
    }
  }

  render() {
    const p_captureObj = this.props.p_captureObj;
    const p_tableWidthEm = this.props.p_tableWidthEm;
    const p_checkedCaptureIDsArray = this.props.p_checkedCaptureIDsArray;
    const p_checkboxColumnWidthEm = JSFUNC.prop_value(this.props.p_checkboxColumnWidthEm, 3);

    const c_expandedSearchFieldsArrayOfObjs = this.props.CapturesMobx.c_expandedSearchFieldsArrayOfObjs;
    const o_openCaptureID = this.props.OpenCaptureMobx.o_openCaptureID;
    const c_singleCaptureIsOpenTF = this.props.OpenCaptureMobx.c_singleCaptureIsOpenTF;

    const singleCaptureIsCurrentlyOpenTF = (c_singleCaptureIsOpenTF && (p_captureObj.id === o_openCaptureID));
    const canClickTF = (JSFUNC.is_function(this.props.f_onClick) && !singleCaptureIsCurrentlyOpenTF);

    const searchResultCaptureItemRow = (
      <div
        className={"displayFlexRowVc border bevelBorderColors " + ((singleCaptureIsCurrentlyOpenTF) ? ("bgPanelLightGray") : ("bgWhite hoverLighterBlueGradient")) + " " + ((canClickTF) ? ("cursorPointer") : (""))}
        style={{height:"2em", width:p_tableWidthEm + "em"}}
        onClick={((canClickTF) ? (this.onclick_capture_result) : (undefined))}>
        {c_expandedSearchFieldsArrayOfObjs.map((m_searchFieldObj) =>
          <div
            key={m_searchFieldObj.id}
            className={"flex00a lrPad " + m_searchFieldObj.cstCellAlignClass} style={{flexBasis:m_searchFieldObj.width_em + "em"}}
            title={p_captureObj[m_searchFieldObj.maskPlainTextFieldName]}>
            <LibraryReact.Nowrap>
              {p_captureObj[m_searchFieldObj.maskFieldName]}
            </LibraryReact.Nowrap>
          </div>
        )}
      </div>
    );

    if(JSFUNC.is_array(p_checkedCaptureIDsArray)) {
      return(
        <div className="displayFlexRow">
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:p_checkboxColumnWidthEm + "em"}}>
            <LibraryReact.CheckBox
              p_u0_s1_p2_du3_ds4={((JSFUNC.in_array(p_captureObj.id, p_checkedCaptureIDsArray)) ? (1) : (0))}
              p_sizeEm={1.3}
              f_onClick={this.onclick_checkbox}
            />
          </div>
          <div className="flex11a">
            {searchResultCaptureItemRow}
          </div>
        </div>
      );
    }

    return(searchResultCaptureItemRow);
  }
}));






//Capture Summary Table/Gantt Chart/Progress Chart
export const CapturesTab = inject("CaptureExecMobx")(observer(
class CapturesTab extends Component { //props: p_capturesViewFlag
  render() {
    const p_capturesViewFlag = this.props.p_capturesViewFlag; //"captureTable", "stage", "priority", "ganttChart", "progressChart"

    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;

    const controlsIncludeMassEditDeleteDotsTF = (p_capturesViewFlag === "captureTable");

    return(
      <div className={"flex11a displayFlexColumn " + c_productStylingObj.capturesTabEmptyBgClass}>
        <CapturesTabControls p_includeMassEditDeleteDotsTF={controlsIncludeMassEditDeleteDotsTF} />
        <CapturesView p_capturesViewFlag={p_capturesViewFlag} />
        <CreateNewCaptureFloatingBox />
      </div>
    );
  }
}));


const CapturesView = inject("CaptureExecMobx", "CapturesMobx")(observer(
class CapturesView extends Component { //props: p_capturesViewFlag
  render() {
    const p_capturesViewFlag = this.props.p_capturesViewFlag;

    const o_loadingThroughPhase1CompleteTF = this.props.CaptureExecMobx.o_loadingThroughPhase1CompleteTF;
    const c_selectedCaptureTableMasterPresetObj = this.props.CapturesMobx.c_selectedCaptureTableMasterPresetObj;
    const c_cstColumnPresetObj = this.props.CapturesMobx.c_cstColumnPresetObj;
    const c_filterPresetObj = this.props.CapturesMobx.c_filterPresetObj;
    const c_cstColumnsArrayOfObjs = this.props.CapturesMobx.c_cstColumnsArrayOfObjs;
    const c_captureTableCurrentlyProcessingCapturesTF = this.props.CapturesMobx.c_captureTableCurrentlyProcessingCapturesTF;

    if(!o_loadingThroughPhase1CompleteTF) {
      return(
        <CEGeneralReact.EmptyScreenGray>
          <div>{"Loading CaptureExec..."}</div>
          <div>
            <CEGeneralReact.LoadingAnimation
              p_frameDelay={0}
              p_class=""
              p_styleObj={{}}
            />
          </div>
        </CEGeneralReact.EmptyScreenGray>
      );
    }

    //selected master preset does not exist
    if(!c_selectedCaptureTableMasterPresetObj.existsTF) {
      return(
        <CEGeneralReact.EmptyScreenGray>
          <div className="microBottomMargin">{"Selected Master Preset does not exist."}</div>
          <div className="microBottomMargin">{"Select a different preset, or create a new one using the Preset Editor tool above."}</div>
          <div>{"In the Preset Editor tool, you can remove this invalid preset from your quick access bar to stop seeing this message."}</div>
        </CEGeneralReact.EmptyScreenGray>
      );
    }

    //if cstColumns or filter presets for the master do not exist, show a fullscreen error (ok to not have a sort preset defined)
    if(!c_cstColumnPresetObj.existsTF || !c_filterPresetObj.existsTF) {
      const presetObjsArray = [c_cstColumnPresetObj, c_filterPresetObj];
      const labelsArray = ["Table Columns", "Capture Filter"];
      return(
        <CEGeneralReact.EmptyScreenGray>
          <div className="medBottomMargin">
            <div className="microBottomMargin">
              {"Master Preset '"}<font className="fontBold">{c_selectedCaptureTableMasterPresetObj.name}</font>{"' has preset components that are invalid."}
            </div>
            <div>
              {"Open the Preset Editor tool above to fix the following issues:"}
            </div>
          </div>
          {presetObjsArray.map((m_presetObj, m_index) =>
            (!m_presetObj.existsTF) &&
            <div className="medBottomMargin">
              <font className="fontBold">
                {labelsArray[m_index] + " Preset was removed from the system and needs a replacement."}
              </font>
            </div>
          )}
        </CEGeneralReact.EmptyScreenGray>
      );
    }

    //if a mass edit is running, don't draw the capture summary table to avoid all the time rendering after each change/delete
    if(c_captureTableCurrentlyProcessingCapturesTF) {
      return(
        <CEGeneralReact.EmptyScreenGray>
          <div>{"Processing Captures..."}</div>
          <CEGeneralReact.LoadingAnimation />
        </CEGeneralReact.EmptyScreenGray>
      );
    }

    if(p_capturesViewFlag === "captureTable") {
      if(c_cstColumnsArrayOfObjs.length === 0) { //no columns in the cstColumns preset part of the View Button is an error specific to the captures table
        return(
          <CEGeneralReact.EmptyScreenGray>
            <div>{"The selected View Button"}</div>
            <div className="microTopMargin fontBold">{"'" + c_selectedCaptureTableMasterPresetObj.name + "'"}</div>
            <div className="microTopMargin">{((c_cstColumnPresetObj.id > 0) ? ("is using the Columns Preset '" + c_cstColumnPresetObj.name + "', which contains 0 columns.") : ("does not have a Column Preset assigned, leaving 0 columns for this table."))}</div>
            <div className="medTopMargin">{"Select another Capture Table filter, or use the Edit Filters interface to create one."}</div>
          </CEGeneralReact.EmptyScreenGray>
        );
      }

      return(<CaptureTable />);
    }
    
    if((p_capturesViewFlag === "stage") || (p_capturesViewFlag === "priority")) {
      return(<StageOrPriorityView p_capturesViewFlag={p_capturesViewFlag} />);
    }

    if((p_capturesViewFlag === "ganttChart") || (p_capturesViewFlag === "progressChart")) {
      const ganttTrueProgressFalse = (p_capturesViewFlag !== "progressChart");
      return(<TimelineChart p_ganttTrueProgressFalse={ganttTrueProgressFalse} />);
    }

    return(
      <CEGeneralReact.EmptyScreenGray>
        {"Unknown View Flag '" + p_capturesViewFlag + "'"}
      </CEGeneralReact.EmptyScreenGray>
    );
  }
}));









//======================================================================================================================================
//======================================================================================================================================
//Main Controls (View, Master Presets)
//======================================================================================================================================
//======================================================================================================================================

const CapturesTabControls = inject("CaptureExecMobx", "CapturesMobx", "UserMobx")(observer(
class CapturesTabControls extends Component { //props: p_includeMassEditDeleteDotsTF
  render() {
    const p_includeMassEditDeleteDotsTF = this.props.p_includeMassEditDeleteDotsTF;

    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_selectedLeftNavIsCaptureTableViewTF = this.props.CaptureExecMobx.c_selectedLeftNavIsCaptureTableViewTF;
    const c_selectedLeftNavIsStageViewTF = this.props.CaptureExecMobx.c_selectedLeftNavIsStageViewTF;
    const c_selectedLeftNavIsGanttChartViewTF = this.props.CaptureExecMobx.c_selectedLeftNavIsGanttChartViewTF;
    const c_selectedLeftNavIsProgressChartViewTF = this.props.CaptureExecMobx.c_selectedLeftNavIsProgressChartViewTF;
    const c_quickAccessCaptureTablePresetsArrayOfObjs = this.props.CapturesMobx.c_quickAccessCaptureTablePresetsArrayOfObjs;
    const c_selectedCaptureTableMasterPresetObj = this.props.CapturesMobx.c_selectedCaptureTableMasterPresetObj;
    const c_cstSortedFilteredMaskedCaptureValuesArrayOfObjs = this.props.CapturesMobx.c_cstSortedFilteredMaskedCaptureValuesArrayOfObjs;
    const c_numFilteredCaptures = this.props.CapturesMobx.c_numFilteredCaptures;
    const c_userCanSwitchToCSTCellEditModeTF = this.props.UserMobx.c_userCanSwitchToCSTCellEditModeTF;

    var inlineEditHoverText = "Turning on 'Inline Edit' will enable direct edit of Capture field values by clicking on individual cells.\nEach Capture can still be opened using the red icon on the left.";

    var rowSizeHoverText = "Use these sizes to adjust the row height of the ";
    if(c_selectedLeftNavIsCaptureTableViewTF) { rowSizeHoverText += "Capture Table"; }
    else if(c_selectedLeftNavIsStageViewTF) { rowSizeHoverText += "Stage View Capture boxes"; }
    else if(c_selectedLeftNavIsGanttChartViewTF) { rowSizeHoverText += "Gantt Chart"; }
    else if(c_selectedLeftNavIsProgressChartViewTF) { rowSizeHoverText += "Progress Chart"; }

    var numCapturesWidthEm = 8;
    var numCapturesFontSizeClass = "font15";
    var capturesStringFontSizeClass = "font12";
    if(c_isMobileOrTabletTF) {
      numCapturesWidthEm = 5;
      numCapturesFontSizeClass = "font11";
      capturesStringFontSizeClass = "font09";
    }
    const captureCapturesString = JSFUNC.plural(c_numFilteredCaptures, "Capture", "Captures");
    const inMasterButtonNameLabel = "in '" + c_selectedCaptureTableMasterPresetObj.name + "'";

    return(
      <div className="flex00a displayFlexRow bgWhite" style={{height:"5.3em", borderBottom:"solid 1px #ccc"}}>
        <CreateNewCaptureButton />
        {(c_productStylingObj.capturesTabMasterPresetEditorButtonTF) &&
          <div className="flex00a displayFlexRowVc lrMedPad">
            <MasterPresetsEditorButton
              p_selectedPresetType={undefined}
              p_selectedPresetID={undefined}
            />
          </div>
        }
        <div className="flex11a displayFlexRowVc xyScroll">
          {c_quickAccessCaptureTablePresetsArrayOfObjs.map((m_captureTablePresetObj) =>
            <QuickAccessMasterPresetButton
              key={m_captureTablePresetObj.id + JSFUNC.key_rand()}
              p_captureTablePresetObj={m_captureTablePresetObj}
            />
          )}
        </div>
        {(c_selectedLeftNavIsCaptureTableViewTF && c_userCanSwitchToCSTCellEditModeTF) &&
          <div className="flex00a displayFlexColumn borderL1ddd lrPad" title={inlineEditHoverText}>
            <div className="flex00a textCenter" style={{marginTop:"0.8em"}}>
              <font className="font09 fontItalic fontTextLight">
                {"Inline Edit"}
              </font>
            </div>
          <div className="flex00a displayFlexColumnHcVc" style={{marginTop:"0.7em"}}>
            <CaptureTableEditCellsModeSwitch />
          </div>
          </div>
        }
        <div className="flex00a displayFlexColumn borderL1ddd lrPad" title={rowSizeHoverText}>
          <div className="flex00a textCenter" style={{marginTop:"0.8em"}}>
            <font className="font09 fontItalic fontTextLight">
              {"Row Size"}
            </font>
          </div>
          <div className="flex00a displayFlexColumnHcVc" style={{marginTop:"0.4em"}}>
            <CapturesTableOrChartRowHeightControlSelect />
          </div>
        </div>
        <div
          className="flex00a displayFlexColumnHcVc borderL1ddd lrPad textCenter"
          style={{flexBasis:numCapturesWidthEm + "em"}}
          title={c_numFilteredCaptures + " " + captureCapturesString + " " + inMasterButtonNameLabel}>
          <div className="flex00a">
            <font className={numCapturesFontSizeClass + " fontBold fontTextLighter"}>
              {c_numFilteredCaptures}
            </font>
          </div>
          <div className="flex00a">
            <font className={capturesStringFontSizeClass + " fontItalic fontTextLighter"}>
              {captureCapturesString}
            </font>
          </div>
          {(!c_isMobileOrTabletTF) &&
            <>
              <div className="microTopMargin" />
              <LibraryReact.Nowrap p_fontClass="font09 fontItalic fontTextLighter">{inMasterButtonNameLabel}</LibraryReact.Nowrap>
            </>
          }
        </div>
        <div className="flex00a displayFlexColumnHcVc borderL1ddd" style={{flexBasis:"2em"}}>
          {(p_includeMassEditDeleteDotsTF) &&
            <CaptureTableExportCsvMassEditMassDeleteVerticalDotsMenu
              p_capturesArrayOfObjs={c_cstSortedFilteredMaskedCaptureValuesArrayOfObjs}
              p_filterName={"'" + c_selectedCaptureTableMasterPresetObj.name + "'"}
              p_exportToCsvOptionTF={true}
            />
          }
        </div>
      </div>
    );
  }
}));

const CreateNewCaptureButton = inject("CaptureExecMobx", "CapturesMobx", "UserMobx")(observer(
class CreateNewCaptureButton extends Component {
  onclick_create_new_capture = () => {
    this.props.CapturesMobx.a_set_create_new_capture_is_open_tf(true);
  }

  render() {
    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_userCanCreateNewCapturesTF = this.props.UserMobx.c_userCanCreateNewCapturesTF;

    if(!c_userCanCreateNewCapturesTF) {
      return(null);
    }

    const captureSizeEm = ((c_isMobileOrTabletTF) ? (2.8) : (3));
    const sizeEm = ((c_isMobileOrTabletTF) ? (3.5) : (3.8));

    return(
      <div className="flex00a displayFlexColumnHcVc borderR1ddd" style={{flexBasis:(sizeEm + ((c_isMobileOrTabletTF) ? (0.8) : (1.6))) + "em"}}>
        {(c_userCanCreateNewCapturesTF) &&
          <div
            className={"displayFlexColumnHcVc " + c_productStylingObj.capturesTabCreateNewCaptureButtonBgClass + " cursorPointer"}
            style={{width:sizeEm + "em", height:sizeEm + "em"}}
            title={"Create a new capture"}
            onClick={this.onclick_create_new_capture}>
            <CEGeneralReact.SvgCapture
              p_sizeEm={captureSizeEm}
              p_color="eeeeee"
              p_plusTF={true}
            />
          </div>
        }
      </div>
    );
  }
}));


export const MasterPresetsEditorButton = inject("CapturesMobx")(observer(
class MasterPresetsEditorButton extends Component { //props: p_selectedPresetType, p_selectedPresetID
  onclick_editor_button = () => {
    const p_selectedPresetType = this.props.p_selectedPresetType;
    const p_selectedPresetID = this.props.p_selectedPresetID;
    
    this.props.CapturesMobx.a_set_capture_table_preset_editor_open_tf(true);

    if(JSFUNC.string_is_filled_out_tf(p_selectedPresetType) && JSFUNC.select_int_is_filled_out_tf(p_selectedPresetID)) {
      this.props.CapturesMobx.a_set_preset_editor_open_preset_type_and_id(p_selectedPresetType, p_selectedPresetID);
    }
  }

  render() {
    const p_selectedPresetType = this.props.p_selectedPresetType;
    const p_selectedPresetID = this.props.p_selectedPresetID;

    return(
      <ButtonCreateEditFilters
        p_sizeEm={3}
        p_title="Create/Edit Capture Table Presets (Columns/Filters/Sorts)"
        f_onClick={this.onclick_editor_button}
      />
    );
  }
}));


const CapturesTableOrChartRowHeightControlSelect = inject("CaptureExecMobx", "CapturesMobx", "UserMobx")(observer(
class CapturesTableOrChartRowHeightControlSelect extends Component {
  onselect_table_or_chart_row_size = (i_selectedCaptureTableRowHeightEmDecimal) => {
    const c_selectedLeftNavIsStageViewTF = this.props.CaptureExecMobx.c_selectedLeftNavIsStageViewTF;
    const c_selectedLeftNavIsPriorityViewTF = this.props.CaptureExecMobx.c_selectedLeftNavIsPriorityViewTF;
    const c_selectedLeftNavIsGanttChartViewTF = this.props.CaptureExecMobx.c_selectedLeftNavIsGanttChartViewTF;
    const c_selectedLeftNavIsProgressChartViewTF = this.props.CaptureExecMobx.c_selectedLeftNavIsProgressChartViewTF;

    var rowHeightFieldDbName = "cst_row_height_em";
    var rowHeightFieldIdsb = "d";
    if(c_selectedLeftNavIsStageViewTF || c_selectedLeftNavIsPriorityViewTF) {
      rowHeightFieldDbName = "stage_view_box_size";
      rowHeightFieldIdsb = "i";
    }
    else if(c_selectedLeftNavIsGanttChartViewTF) {
      rowHeightFieldDbName = "capture_chart_gantt_size";
      rowHeightFieldIdsb = "i";
    }
    else if(c_selectedLeftNavIsProgressChartViewTF) {
      rowHeightFieldDbName = "capture_chart_progress_size";
      rowHeightFieldIdsb = "i";
    }

    this.props.UserMobx.a_update_user_per_email_field(rowHeightFieldDbName, i_selectedCaptureTableRowHeightEmDecimal, rowHeightFieldIdsb);
  }

  render() {
    const c_selectedLeftNavIsStageViewTF = this.props.CaptureExecMobx.c_selectedLeftNavIsStageViewTF;
    const c_selectedLeftNavIsPriorityViewTF = this.props.CaptureExecMobx.c_selectedLeftNavIsPriorityViewTF;
    const c_selectedLeftNavIsGanttChartViewTF = this.props.CaptureExecMobx.c_selectedLeftNavIsGanttChartViewTF;
    const c_selectedLeftNavIsProgressChartViewTF = this.props.CaptureExecMobx.c_selectedLeftNavIsProgressChartViewTF;
    const c_cstRowHeightFieldTypeObj = this.props.CapturesMobx.c_cstRowHeightFieldTypeObj;
    const c_stageViewBoxSizeFieldTypeObj = this.props.CapturesMobx.c_stageViewBoxSizeFieldTypeObj;
    const c_ganttChartRowHeightFieldTypeObj = this.props.CapturesMobx.c_ganttChartRowHeightFieldTypeObj;
    const c_progressChartRowHeightFieldTypeObj = this.props.CapturesMobx.c_progressChartRowHeightFieldTypeObj;
    const c_combinedUserObj = this.props.UserMobx.c_combinedUserObj;
    const c_userStageViewBoxSize = this.props.UserMobx.c_userStageViewBoxSize;

    var capturesTableOrChartRowHeightFieldTypeObj = c_cstRowHeightFieldTypeObj;
    var capturesTableOrChartUserRowHeightEm = c_combinedUserObj.cst_row_height_em;
    if(c_selectedLeftNavIsStageViewTF || c_selectedLeftNavIsPriorityViewTF) {
      capturesTableOrChartRowHeightFieldTypeObj = c_stageViewBoxSizeFieldTypeObj;
      capturesTableOrChartUserRowHeightEm = c_userStageViewBoxSize;
    }
    else if(c_selectedLeftNavIsGanttChartViewTF) {
      capturesTableOrChartRowHeightFieldTypeObj = c_ganttChartRowHeightFieldTypeObj;
      capturesTableOrChartUserRowHeightEm = c_combinedUserObj.capture_chart_gantt_size;
    }
    else if(c_selectedLeftNavIsProgressChartViewTF) {
      capturesTableOrChartRowHeightFieldTypeObj = c_progressChartRowHeightFieldTypeObj;
      capturesTableOrChartUserRowHeightEm = c_combinedUserObj.capture_chart_progress_size;
    }

    return(
      <div style={{width:"4.5em"}}>
        <CEGeneralReact.GenericInputOrSelectFromInputType
          p_fieldTypeObj={capturesTableOrChartRowHeightFieldTypeObj}
          p_valueRaw={capturesTableOrChartUserRowHeightEm}
          p_selectDisplayMaskOverwrite={JSFUNC.num2str(capturesTableOrChartUserRowHeightEm)}
          f_onChangeOrOnSelect={this.onselect_table_or_chart_row_size}
        />
      </div>
    );
  }
}));


const CaptureTableEditCellsModeSwitch = inject("UserMobx")(observer(
class CaptureTableEditCellsModeSwitch extends Component {
  onswitch_cst_edit_cells_mode = () => {
    const c_userCSTEditCellsModeTF = this.props.UserMobx.c_userCSTEditCellsModeTF;
    const updatedCSTEditCellsMode01 = ((c_userCSTEditCellsModeTF) ? (0) : (1));
    this.props.UserMobx.a_update_user_per_email_field("cst_edit_cells_mode_01", updatedCSTEditCellsMode01, "i");
  }

  render() {
    const c_userCSTEditCellsModeTF = this.props.UserMobx.c_userCSTEditCellsModeTF;

    return(
      <CEGeneralReact.SwitchWithTextAndConfirmBox
        p_isOnTF={c_userCSTEditCellsModeTF}
        p_sizeEm={2.8}
        p_onColor="996066"
        f_onSwitch={this.onswitch_cst_edit_cells_mode}
      />
    );
  }
}));


export const CaptureTableExportCsvMassEditMassDeleteVerticalDotsMenu = inject("CaptureExecMobx", "CapturesMobx", "UserMobx")(observer(
class CaptureTableExportCsvMassEditMassDeleteVerticalDotsMenu extends Component { //props: p_capturesArrayOfObjs, p_filterName, p_exportToCsvOptionTF
  constructor(props) {
    super(props);
    this.state = {
      s_exportToCsvFloatingBoxIsOpenTF: false,
      s_massEditFloatingBoxIsOpenTF: false
    }
  }

  onclick_open_export_to_csv_floating_box = () => {
    this.setState({s_exportToCsvFloatingBoxIsOpenTF:true});
  }

  onclick_close_export_to_csv_floating_box = () => {
    this.setState({s_exportToCsvFloatingBoxIsOpenTF:false});
  }

  onclick_open_mass_edit_floating_box = () => {
    this.setState({s_massEditFloatingBoxIsOpenTF:true});
  }

  onclick_close_mass_edit_floating_box = () => {
    this.setState({s_massEditFloatingBoxIsOpenTF:false});
  }

  onclick_delete_all_captures_in_capture_table = () => {
    const p_capturesArrayOfObjs = this.props.p_capturesArrayOfObjs;
    this.props.CapturesMobx.a_delete_all_captures_in_capture_table(p_capturesArrayOfObjs);
  }

  render() {
    const s_exportToCsvFloatingBoxIsOpenTF = this.state.s_exportToCsvFloatingBoxIsOpenTF;
    const s_massEditFloatingBoxIsOpenTF = this.state.s_massEditFloatingBoxIsOpenTF;

    const p_capturesArrayOfObjs = this.props.p_capturesArrayOfObjs;
    const p_filterName = this.props.p_filterName;
    const p_exportToCsvOptionTF = this.props.p_exportToCsvOptionTF;

    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_userCanMassEditAndMassDeleteCaptureTableTF = this.props.UserMobx.c_userCanMassEditAndMassDeleteCaptureTableTF;

    //if user can't export to CSV or mass edit/delete, don't draw the vertical dots menu
    if(!p_exportToCsvOptionTF && !c_userCanMassEditAndMassDeleteCaptureTableTF) {
      return(null);
    }

    //if p_capturesArrayOfObjs is not an array, also return null
    if(!JSFUNC.is_array(p_capturesArrayOfObjs)) {
      return(null);
    }

    const numCapturesInTable = p_capturesArrayOfObjs.length;

    //set up Capture Table vertical dots menu
    var captureTableMenuItemsArrayOfObjs = [];

    //export to .csv
    if(p_exportToCsvOptionTF) {
      captureTableMenuItemsArrayOfObjs.push({
        displayName: "Export Capture Table to .csv",
        functionOnClickMenuItem: this.onclick_open_export_to_csv_floating_box
      });
    }

    //Capture Table operations only available to Admins (mass edit, mass delete)
    if(c_userCanMassEditAndMassDeleteCaptureTableTF) {
      //mass edit
      captureTableMenuItemsArrayOfObjs.push({
        displayName: "Mass Edit a Field",
        functionOnClickMenuItem: this.onclick_open_mass_edit_floating_box
      });

      //delete all
      if(c_productStylingObj.capturesTabCstCanDeleteAllCapturesInTableTF) { //not available in SAM Gov Tracker
        const captureCapturesString = JSFUNC.plural(numCapturesInTable, "Capture", "Captures");

        var deleteAllCapturesConfirmType = "confirmDelete";
        var deleteAllCapturesConfirmMessage = "Are you sure you want to delete the " + numCapturesInTable + " " + captureCapturesString + " shown in the selected table (" + p_filterName + ")?";
        deleteAllCapturesConfirmMessage += "\n\nThis action will also remove all data and documents associated with each deleted capture."
        if(numCapturesInTable === 0) {
          deleteAllCapturesConfirmType = "cancel";
          deleteAllCapturesConfirmMessage = "No Captures to delete in the selected table (" + p_filterName + ")";
        }

        captureTableMenuItemsArrayOfObjs.push({
          displayName: "Delete All Captures in Table",
          confirmType: deleteAllCapturesConfirmType,
          confirmTitle: "Delete All Captures in Current Capture Table",
          confirmButton1Name: "Delete " + numCapturesInTable + " " + captureCapturesString,
          confirmMessage: deleteAllCapturesConfirmMessage,
          functionOnClickConfirmButton: this.onclick_delete_all_captures_in_capture_table
        });
      }
    }

    return(
      <>
        <CEGeneralReact.VerticalDotsMenu
          p_menuItemsArrayOfObjs={captureTableMenuItemsArrayOfObjs}
        />
        {(s_exportToCsvFloatingBoxIsOpenTF) &&
          <CaptureTableExportToCsvFloatingBox
            f_onClickClose={this.onclick_close_export_to_csv_floating_box}
          />
        }
        {(s_massEditFloatingBoxIsOpenTF) &&
          <CaptureTableMassEditFloatingBox
            p_filterName={p_filterName}
            p_capturesArrayOfObjs={p_capturesArrayOfObjs}
            f_onClickClose={this.onclick_close_mass_edit_floating_box}
          />
        }
      </>
    );
  }
}));

const CaptureTableExportToCsvFloatingBox = inject("CapturesMobx")(observer(
class CaptureTableExportToCsvFloatingBox extends Component { //props: f_onClickClose
  constructor(props) {
    super(props);
    this.state = {
      s_exportToCsvFetchFullTextareasTF: false
    }
  }

  onswitch_fetch_full_textareas_tf = () => {
    this.setState({s_exportToCsvFetchFullTextareasTF:!this.state.s_exportToCsvFetchFullTextareasTF});
  }

  onclick_generate_download_capture_table_report_csv = () => {
    const s_exportToCsvFetchFullTextareasTF = this.state.s_exportToCsvFetchFullTextareasTF;
    this.props.CapturesMobx.a_generate_and_download_capture_table_report_csv(s_exportToCsvFetchFullTextareasTF);
  }

  render() {
    const s_exportToCsvFetchFullTextareasTF = this.state.s_exportToCsvFetchFullTextareasTF;

    const o_captureTableCsvIsGeneratingTF = this.props.CapturesMobx.o_captureTableCsvIsGeneratingTF;
    const c_selectedCaptureTableMasterPresetObj = this.props.CapturesMobx.c_selectedCaptureTableMasterPresetObj;

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="confirmBox"
        p_title="Export Capture Table to .csv"
        f_onClickCancel={this.props.f_onClickClose}>
        <div className="flex11a displayFlexColumn smallFullPad">
          <div className="">
            <font className="">
              {"Clicking 'Export to .csv' will create and offer for download a .csv file of all data cells in the current Capture Table (currently filtered using master button '" + c_selectedCaptureTableMasterPresetObj.name + "')."}
            </font>
          </div>
          <div className="medTopMargin">
            <font className="">
              {"The .csv file can be opened using spreadsheet software external to CaptureExec."}
            </font>
          </div>
          <div className="bigTopMargin hugeBottomMargin lrMargin borderT1bbb borderB1bbb tbMedPad">
            <CEGeneralReact.SwitchWithTextAndConfirmBox
              p_isOnTF={s_exportToCsvFetchFullTextareasTF}
              p_onText="Incorporate the full text from large text data columns (Description of Work, Notepad, etc)"
              p_offText="[default] Keep large text data columns (Description of Work, Notepad, etc) truncated at 100 characters"
              p_tabIndex={1}
              p_title="Switch this setting to select if large text data columns will fetch the full length text, or only use the first 100 characters when writing the .csv file"
              f_onSwitch={this.onswitch_fetch_full_textareas_tf}
            />
          </div>
          <div className="flex00a displayFlexColumn hugeTopMargin" style={{flexBasis:"3em"}}>
            {(o_captureTableCsvIsGeneratingTF) ? (
              <div className="flex11a displayFlexColumnHc textCenter">
                <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLight">
                  {"Generating .csv file of Capture Table Generating .csv file of Capture Table Generating .csv file of Capture Table '" + c_selectedCaptureTableMasterPresetObj.name + "'"}
                </LibraryReact.Nowrap>
                <div className="flex00a displayFlexColumnHcVc">
                  <CEGeneralReact.LoadingAnimation />
                </div>
              </div>
            ) : (
              <div className="flex11a displayFlexRowHcVc">
                <div className="flex00a lrMedMargin">
                  <CEGeneralReact.CEButton
                    p_type="blue"
                    p_text="Export to .csv"
                    p_tabIndex={2}
                    f_onClick={this.onclick_generate_download_capture_table_report_csv}
                  />
                </div>
                <div className="flex00a lrMedMargin">
                  <CEGeneralReact.CEButton
                    p_type="gray"
                    p_text="Cancel"
                    p_tabIndex={3}
                    f_onClick={this.props.f_onClickClose}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));

const CaptureTableMassEditFloatingBox = inject("AdminIntegrationsMobx", "CapturesMobx", "DatabaseMobx")(observer(
class CaptureTableMassEditFloatingBox extends Component { //props: p_filterName, p_capturesArrayOfObjs, f_onClickClose
  constructor(props) {
    super(props);
    this.state = {
      s_selectedCaptureFieldID: undefined,
      s_selectedOrEnteredCaptureFieldValueRaw: undefined,
      s_sendMassEditNotificationsTF: false
    }
  }

  onselect_capture_field = (i_fieldID) => {
    const c_fieldMapOfArchiveDate = this.props.DatabaseMobx.c_fieldMapOfArchiveDate;

    var initialValueAfterFieldSelection = undefined;
    if(i_fieldID === c_fieldMapOfArchiveDate.get("id")) { //archive date field selected, inject today's date
      initialValueAfterFieldSelection = JSFUNC.now_date();
    }
    else { //any other mass editable field selected
      //set the selected/entered value to the blankValue for the given field type
      const selectedExpandedCaptureFieldMap = this.props.DatabaseMobx.c_tbl_captures_fields.get(i_fieldID);
      if(selectedExpandedCaptureFieldMap !== undefined) {
        const selectedCaptureFieldFieldTypeObj = selectedExpandedCaptureFieldMap.get("fieldTypeObj");
        if(selectedCaptureFieldFieldTypeObj !== undefined) {
          initialValueAfterFieldSelection = selectedCaptureFieldFieldTypeObj.blankValue;
        }
      }
    }
    
    this.onchange_capture_field_value_raw(initialValueAfterFieldSelection);

    this.setState({s_selectedCaptureFieldID:i_fieldID});
  }

  onchange_capture_field_value_raw = (i_newValue) => {
    this.setState({s_selectedOrEnteredCaptureFieldValueRaw:i_newValue});
  }

  onswitch_send_mass_edit_notifications_tf = () => {
    this.setState({s_sendMassEditNotificationsTF:!this.state.s_sendMassEditNotificationsTF});
  }

  onclick_mass_edit_field_for_all_captures_in_capture_table = () => {
    const s_selectedCaptureFieldID = this.state.s_selectedCaptureFieldID;
    const s_selectedOrEnteredCaptureFieldValueRaw = this.state.s_selectedOrEnteredCaptureFieldValueRaw;
    const s_sendMassEditNotificationsTF = this.state.s_sendMassEditNotificationsTF;

    const p_capturesArrayOfObjs = this.props.p_capturesArrayOfObjs;

    const selectedExpandedCaptureFieldMap = this.props.DatabaseMobx.c_tbl_captures_fields.get(s_selectedCaptureFieldID);
    if(selectedExpandedCaptureFieldMap !== undefined) {
      //close the mass edit floating box to make way for the mass edit progress floating box
      if(JSFUNC.is_function(this.props.f_onClickClose)) {
        this.props.f_onClickClose();
      }

      //call the recursive mass edit for the currently open master button table of captures
      this.props.CapturesMobx.a_mass_edit_field_for_all_captures_in_capture_table(p_capturesArrayOfObjs, selectedExpandedCaptureFieldMap, s_selectedOrEnteredCaptureFieldValueRaw, s_sendMassEditNotificationsTF);
    }
  }

  render() {
    const s_selectedCaptureFieldID = this.state.s_selectedCaptureFieldID;
    const s_selectedOrEnteredCaptureFieldValueRaw = this.state.s_selectedOrEnteredCaptureFieldValueRaw;
    const s_sendMassEditNotificationsTF = this.state.s_sendMassEditNotificationsTF;

    const p_filterName = this.props.p_filterName;
    const p_capturesArrayOfObjs = this.props.p_capturesArrayOfObjs;


    const o_integrationsUserCreateUpdateIntegrationCaptureFromCEActionTF = this.props.AdminIntegrationsMobx.o_integrationsUserCreateUpdateIntegrationCaptureFromCEActionTF;
    const c_companyIntegrationOnTF = this.props.DatabaseMobx.c_companyIntegrationOnTF;
    const c_fieldMapOfCaptureID = this.props.DatabaseMobx.c_fieldMapOfCaptureID;
    const c_fieldMapOfCaptureManagers = this.props.DatabaseMobx.c_fieldMapOfCaptureManagers;
    const c_fieldMapOfContractEndDate = this.props.DatabaseMobx.c_fieldMapOfContractEndDate;
    const c_fieldMapOfLastChangedDate = this.props.DatabaseMobx.c_fieldMapOfLastChangedDate;
    const c_fieldMapOfArchiveDate = this.props.DatabaseMobx.c_fieldMapOfArchiveDate;

    var selectedCaptureFieldIsCaptureManagersTF = false;
    var selectedCaptureFieldIsContractManagerTF = false;
    var selectedCaptureFieldIsArchiveDateTF = false;
    if(s_selectedCaptureFieldID === c_fieldMapOfCaptureManagers.get("id")) { selectedCaptureFieldIsCaptureManagersTF = true; }
    else if(s_selectedCaptureFieldID === c_fieldMapOfArchiveDate.get("id")) { selectedCaptureFieldIsArchiveDateTF = true; }

    const numCapturesInTable = p_capturesArrayOfObjs.length;

    //if selected Capture Team or Contracts Manager field to mass edit, show switch for sending notifications
    var selectedExpandedCaptureFieldMap = undefined;
    var selectedCaptureFieldIsValidTF = false;
    var button1Type = "blue";
    var button1Name = "Edit " + numCapturesInTable + " " + JSFUNC.plural(numCapturesInTable, "Capture", "Captures");
    var button1Title = undefined;
    if(JSFUNC.select_int_is_filled_out_tf(s_selectedCaptureFieldID)) {
      selectedExpandedCaptureFieldMap = this.props.DatabaseMobx.c_tbl_captures_fields.get(s_selectedCaptureFieldID);
      selectedCaptureFieldIsValidTF = (selectedExpandedCaptureFieldMap !== undefined);
    }

    if(!selectedCaptureFieldIsValidTF) {
      button1Type = "blueDisabled";
      button1Title = "First select a Capture Field to apply this mass edit to";
    }

    //input for value for this selected field to write into each mass edited capture
    var inputNewValueComponent = null;
    if(selectedCaptureFieldIsValidTF) { //archive date forces the value to be today's date and displays a message, all other mass edit fields have their specific value type ready for custom input
      inputNewValueComponent = (
        <>
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={selectedExpandedCaptureFieldMap.get("fieldTypeObj")}
            p_valueRaw={s_selectedOrEnteredCaptureFieldValueRaw}
            p_valuesToNotIncludeArray={undefined}
            p_tabIndex={2}
            p_errorTF={undefined}
            f_onChangeOrOnSelect={this.onchange_capture_field_value_raw}
          />
          {(selectedCaptureFieldIsArchiveDateTF) &&
            <div className="microTopMargin">
              <font className="fontItalic fontTextLighter">
                {"Each Capture will be archived with this date as the " + c_fieldMapOfArchiveDate.get("display_name") + ". These Captures will still be visible in your Capture Table until your next login, where they will then be treated like other archived Captures."}
              </font>
            </div>
          }
        </>
      );
    }
    else {
      inputNewValueComponent = (
        <div className="">
          <font className="fontItalic fontTextLighter">
            {"--Select a Capture Field above--"}
          </font>
        </div>
      );
    }

    //switch for notifications only shows up when Capture Managers or Contracts Manager is selected (only fields so far that when edited using OpenCaptureMobx a_details_update_field_value(), send out notifications, if more are added, add them here)
    var sendMassEditNotificationsSwitchComponent = null;
    if(selectedCaptureFieldIsValidTF) {
      if(selectedCaptureFieldIsCaptureManagersTF || selectedCaptureFieldIsContractManagerTF) {
        const selectedCaptureFieldDisplayName = selectedExpandedCaptureFieldMap.get("display_name");
        sendMassEditNotificationsSwitchComponent = (
          <div className="bigTopMargin hugeBottomMargin lrMargin borderT1bbb borderB1bbb tbMedPad">
            <CEGeneralReact.SwitchWithTextAndConfirmBox
              p_isOnTF={s_sendMassEditNotificationsTF}
              p_onText={"Send notifications/emails of these changes per Capture to affected " + selectedCaptureFieldDisplayName}
              p_offText={"Do not send any notifications/emails to " + selectedCaptureFieldDisplayName + " regarding changed Captures"}
              p_tabIndex={3}
              p_title="Switch this setting to change if the notifications/emails normally sent to Capture Managers or Contract Managers should be sent for each Capture affected by this mass edit"
              f_onSwitch={this.onswitch_send_mass_edit_notifications_tf}
            />
          </div>
        );
      }
    }

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="smallVertical"
        p_title="Mass Edit a Field in Current Capture Table"
        f_onClickCancel={this.props.f_onClickClose}>
        <div className="flex11a displayFlexColumn medFullPad">
          {(c_companyIntegrationOnTF && !o_integrationsUserCreateUpdateIntegrationCaptureFromCEActionTF) &&
            <div className="tbMargin">
              <AdminIntegrationsReact.IntegrationsOptionToTurnOffIntegrationCreateUpdateCapturesMessageBox />
            </div>
          }
          <div className="smallBottomMargin lrBigMargin">
            <font className="fontItalic">
              {"This mass edit feature allows you to choose a particular Capture Field and set a new value. That new value will be overwritten for that field into every Capture seen in the currently selected table (" + p_filterName + ")."}
            </font>
          </div>
          <div className="border1bbb bgLightestGray medFullPad">
            <div className="microBottomMargin">
              <font className="font11 fontBold fontTextLight">
                {"Select a Capture Field"}
              </font>
            </div>
            <div className="microBottomMargin">
              <font className="fontItalic fontTextLight">
                {"If you cannot find a field you are looking for in the list, it is likely that field either cannot be edited (like '" + c_fieldMapOfCaptureID.get("display_name") + "' or '" + c_fieldMapOfLastChangedDate.get("display_name") + "') or is a computed field (like '" + c_fieldMapOfContractEndDate.get("display_name") + "')"}
              </font>
            </div>
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={this.props.DatabaseMobx.c_selectMassEditableCaptureFieldFieldTypeObj}
              p_valueRaw={s_selectedCaptureFieldID}
              p_valuesToNotIncludeArray={undefined}
              p_tabIndex={1}
              p_errorTF={undefined}
              f_onChangeOrOnSelect={this.onselect_capture_field}
            />
            <div className="bigTopMargin microBottomMargin">
              <font className="font11 fontBold fontTextLight">
                {"Choose/Enter a Value"}
              </font>
            </div>
            {inputNewValueComponent}
          </div>
          {sendMassEditNotificationsSwitchComponent}
          <div className="flex00a displayFlexRowHcVc hugeTopMargin" style={{flexBasis:"3em"}}>
            <div className="flex00a lrMedMargin">
              <CEGeneralReact.CEButton
                p_type={button1Type}
                p_text={button1Name}
                p_title={button1Title}
                p_tabIndex={4}
                f_onClick={this.onclick_mass_edit_field_for_all_captures_in_capture_table}
              />
            </div>
            <div className="flex00a lrMedMargin">
              <CEGeneralReact.CEButton
                p_type="gray"
                p_text="Cancel"
                p_tabIndex={5}
                f_onClick={this.props.f_onClickClose}
              />
            </div>
          </div>
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));

export const ProcessMultipleCapturesProgressFloatingBox = inject("CapturesMobx", "DatabaseMobx")(observer(
class ProcessMultipleCapturesProgressFloatingBox extends Component {
  onclick_cancel_capture_table_processing_captures_floating_box = () => {
    this.props.CapturesMobx.a_set_capture_table_processing_captures_obj_or_undefined(undefined);
  }

  render() {
    const o_captureTableProcessingCapturesObjOrUndefined = this.props.CapturesMobx.o_captureTableProcessingCapturesObjOrUndefined;
    const c_selectedCaptureTableMasterPresetObj = this.props.CapturesMobx.c_selectedCaptureTableMasterPresetObj;

    if(o_captureTableProcessingCapturesObjOrUndefined === undefined) {
      return(null);
    }

    const expandedCaptureFieldMap = o_captureTableProcessingCapturesObjOrUndefined.expandedCaptureFieldMap;
    const valueRaw = o_captureTableProcessingCapturesObjOrUndefined.valueRaw;
    const currentlyProcessingCaptureName = o_captureTableProcessingCapturesObjOrUndefined.currentlyProcessingCaptureName;
    const currentlyProcessingCaptureNumber = o_captureTableProcessingCapturesObjOrUndefined.currentlyProcessingCaptureNumber;
    const totalNumCapturesToProcess = o_captureTableProcessingCapturesObjOrUndefined.totalNumCapturesToProcess;
    const successCaptureNamesArray = o_captureTableProcessingCapturesObjOrUndefined.successCaptureNamesArray;
    const failedCaptureNamesArray = o_captureTableProcessingCapturesObjOrUndefined.failedCaptureNamesArray;

    var editingDeletingString = "Deleting";
    var editedDeletedString = "deleted";
    var progressColor = "bd2326";
    if(expandedCaptureFieldMap !== undefined) {
      editingDeletingString = "Editing";
      editedDeletedString = "edited";
      progressColor = "005da3";
    }

    //in progress processing a capture
    if(currentlyProcessingCaptureNumber > 0) {
      const progressTextHtml = (
        <>
          <div className="smallBottomMargin">
            <font className="">
              {editingDeletingString + " Capture " + currentlyProcessingCaptureNumber + " of " + totalNumCapturesToProcess}
            </font>
          </div>
          <div className="">
            <font className="fontItalic">
              {"'" + currentlyProcessingCaptureName + "'"}
            </font>
          </div>
        </>
      );
      
      return(
        <CEGeneralReact.RecursiveProgressBarFloatingBox
          p_floatingBoxTitle={editingDeletingString + " Captures in Capture Table '" + c_selectedCaptureTableMasterPresetObj.name + "'"}
          p_progressTextHtmlOrUndefined={progressTextHtml}
          p_currentItemNumber={currentlyProcessingCaptureNumber}
          p_totalNumItems={totalNumCapturesToProcess}
          p_progressColor={progressColor}
          f_onClickCancel={this.onclick_cancel_capture_table_processing_captures_floating_box}
        />
      );
    }

    //-1 flag that all captures have been processed
    const numSuccessCaptures = successCaptureNamesArray.length;
    const numFailedCaptures = failedCaptureNamesArray.length;

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="smallVertical"
        p_title={editingDeletingString + " Captures in Capture Table '" + c_selectedCaptureTableMasterPresetObj.name + "'"}
        f_onClickCancel={this.onclick_cancel_capture_table_processing_captures_floating_box}>
        <div className="flex11a yScroll medFullPad">
          <div className="smallBottomMargin textCenter">
            <font className="font11 fontBold fontItalic fontGreen">
              {"Successfully " + editedDeletedString + " " + numSuccessCaptures + " " + JSFUNC.plural(numSuccessCaptures, "Capture", "Captures")}
            </font>
            {(expandedCaptureFieldMap !== undefined) &&
              <>
                <div className="">
                  <font className="fontItalic fontTextLighter">
                    {"Capture Field: '" + expandedCaptureFieldMap.get("display_name") + "'"}
                  </font>
                </div>
                <div className="">
                  <font className="fontItalic fontTextLighter">
                    {"New Value: " + this.props.DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(valueRaw, expandedCaptureFieldMap.get("fieldTypeObj"))}
                  </font>
                </div>
              </>
            }
          </div>
          {successCaptureNamesArray.map((m_captureName) =>
            <div className="">
              <font className="">
                {m_captureName}
              </font>
            </div>
          )}
          {(numFailedCaptures > 0) &&
            <>
              <div className="hugeTopMargin smallBottomMargin textCenter">
                <font className="font11 fontBold fontItalic fontRed">
                  {numFailedCaptures + " " + JSFUNC.plural(numFailedCaptures, "Capture was", "Captures were") + " not successfully " + editedDeletedString}
                </font>
              </div>
              {failedCaptureNamesArray.map((m_captureName) =>
                <div className="">
                  <font className="fontRed">
                    {m_captureName}
                  </font>
                </div>
              )}
            </>
          }
        </div>
        <div className="flex00a displayFlexColumnHcVc borderT1bbb tbPad">
          <CEGeneralReact.CEButton
            p_type="gray"
            p_text="Close"
            f_onClick={this.onclick_cancel_capture_table_processing_captures_floating_box}
          />
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));


const QuickAccessMasterPresetButton = inject("CaptureExecMobx", "CapturesMobx")(observer(
class QuickAccessMasterPresetButton extends Component { //props: p_captureTablePresetObj
  onclick_preset = () => {
    this.props.CapturesMobx.a_set_captures_tab_selected_view_button(this.props.p_captureTablePresetObj.id);
  }

  render() {
    const p_captureTablePresetObj = this.props.p_captureTablePresetObj;

    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_selectedCaptureTableMasterPresetObj = this.props.CapturesMobx.c_selectedCaptureTableMasterPresetObj;

    const isSelectedTF = (c_selectedCaptureTableMasterPresetObj.id === p_captureTablePresetObj.id);

    var buttonWidth = "9.5em";
    var buttonHeight = "3.9em";
    var nameMaxHeight = "3.5em"
    if(c_isMobileOrTabletTF) {
      buttonWidth = "7em";
      buttonHeight = "3.5em";
      nameMaxHeight = "2.5em"
    }

    var masterPresetButtonTitle = p_captureTablePresetObj.name;
    if(JSFUNC.string_is_filled_out_tf(p_captureTablePresetObj.description)) {
      masterPresetButtonTitle += "\n\n" + p_captureTablePresetObj.description;
    }

    return(
      <div
        className={"flex00a displayFlexRowVc lrMedPad textCenter " + ((isSelectedTF) ? (c_productStylingObj.capturesTabMasterButtonSelectedBgClass) : (c_productStylingObj.capturesTabMasterButtonUnselectedBgClass + " cursorPointer"))}
        style={{flexBasis:buttonWidth, height:buttonHeight, marginRight:"0.4em"}}
        title={masterPresetButtonTitle}
        onClick={((isSelectedTF) ? (undefined) : (this.onclick_preset))}>
        <LibraryReact.MaxHeightWrap p_maxHeight={nameMaxHeight} p_fontClass={((isSelectedTF) ? (c_productStylingObj.capturesTabMasterButtonSelectedFontClass) : (c_productStylingObj.capturesTabMasterButtonUnselectedFontClass))}>
          {p_captureTablePresetObj.name}
        </LibraryReact.MaxHeightWrap>
      </div>
    );
  }
}));


function ButtonCreateEditFilters(props) { //props: p_sizeEm, p_title, f_onClick
  const lineColor = "#888";
  const lineWidthPercent = 8;
  const plusColor = "#77c";
  const plusWidthPercent = 6;
  return(
    <div
      className="border bevelBorderColors borderRadius05 bgLighterGray hoverLighterBlueGradient cursorPointer"
      style={{width:props.p_sizeEm + "em", height:props.p_sizeEm + "em"}}
      title={props.p_title}
      onClick={props.f_onClick}>
      <svg width="100%" height="100%">
        <LibraryReact.SvgHLine p_x1p={10} p_x2p={90} p_ycp={20} p_widthP={lineWidthPercent} p_color={lineColor} />
        <LibraryReact.SvgHLine p_x1p={20} p_x2p={80} p_ycp={35} p_widthP={lineWidthPercent} p_color={lineColor} />
        <LibraryReact.SvgHLine p_x1p={30} p_x2p={70} p_ycp={50} p_widthP={lineWidthPercent} p_color={lineColor} />
        <LibraryReact.SvgHLine p_x1p={35} p_x2p={65} p_ycp={65} p_widthP={lineWidthPercent} p_color={lineColor} />
        <LibraryReact.SvgHLine p_x1p={35} p_x2p={65} p_ycp={80} p_widthP={lineWidthPercent} p_color={lineColor} />
        <LibraryReact.SvgHLine p_x1p={70} p_x2p={90} p_ycp={75} p_widthP={plusWidthPercent} p_color={plusColor} />
        <LibraryReact.SvgVLine p_y1p={65} p_y2p={85} p_xcp={80} p_widthP={plusWidthPercent} p_color={plusColor} />
      </svg>
    </div>
  );
}










//======================================================================================================================================
//======================================================================================================================================
//Captures Table View
//======================================================================================================================================
//======================================================================================================================================

const CaptureTable = inject("CaptureExecMobx", "CapturesMobx", "DatabaseMobx", "UserMobx")(observer(
class CaptureTable extends Component {
  constructor(props) {
    super(props);
    this.cstHeaderRow = React.createRef();
    this.state = {
      s_cstBodyScrollLeft: 0
    }
  }

  ondrop_column_resize = (i_draggedColumnTblFCstColumnsID, event) => {
    this.props.CapturesMobx.a_cst_column_resize(i_draggedColumnTblFCstColumnsID, event.clientX);
  }

  onscroll_cst_header_row = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.cstHeaderRow.current.scrollLeft = this.state.s_cstBodyScrollLeft;
  }

  onscroll_cst_body = (i_newBodyScrollLeft) => {
    this.cstHeaderRow.current.scrollLeft = i_newBodyScrollLeft;
    this.setState({s_cstBodyScrollLeft:i_newBodyScrollLeft});
  }

  render() {
    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const k_cstEditCellsModeOpenCaptureColumnWidthEm = this.props.CapturesMobx.k_cstEditCellsModeOpenCaptureColumnWidthEm;
    const c_cstColumnsArrayOfObjs = this.props.CapturesMobx.c_cstColumnsArrayOfObjs;
    const c_captureTableTotalWidthEm = this.props.CapturesMobx.c_captureTableTotalWidthEm;
    const c_cstColumnPresetObj = this.props.CapturesMobx.c_cstColumnPresetObj;
    const c_cstSumRowColumnsArrayOfObjsOrUndefined = this.props.CapturesMobx.c_cstSumRowColumnsArrayOfObjsOrUndefined;
    const c_combinedUserObj = this.props.UserMobx.c_combinedUserObj;
    const c_userCaptureTableRowHeightOptionsObj = this.props.UserMobx.c_userCaptureTableRowHeightOptionsObj;
    const c_userCSTEditCellsModeTF = this.props.UserMobx.c_userCSTEditCellsModeTF;

    const canResizeColumnsTF = this.props.UserMobx.user_id_is_one_of_logged_in_user_per_email_multilogin_tf(c_cstColumnPresetObj.user_id);
    
    return(
      <div className="flex11a displayFlexColumn" style={{border:"solid 1px #ccc"}}>
        <div
          ref={this.cstHeaderRow}
          className="flex00a xScrollHidden"
          onScroll={this.onscroll_cst_header_row}>
          <CEGeneralReact.CE3Drag3Drop3Shell3
            p_uniqueString="cstHeadersDragDrop"
            p_itemID={undefined}
            p_draggableTF={false}
            p_droppableTF={canResizeColumnsTF}
            p_dropZoneIsInvisibleOverlayTF={false}
            p_dropZoneOversizeWidthEm={undefined}
            p_class={"displayFlexRow " + c_productStylingObj.cstColumnHeaderRowBgClass + " borderB1ddd " + ((canResizeColumnsTF) ? ("cursorGrab") : (""))}
            p_styleObj={{height:c_userCaptureTableRowHeightOptionsObj.headerHeightEm + "em", width:c_captureTableTotalWidthEm + "em"}}
            p_title={undefined}
            p_dragOverClass={undefined}
            p_dragOverStyleObj={undefined}
            f_onDropMatchingItem={this.ondrop_column_resize}>
            {(c_userCSTEditCellsModeTF) &&
              <div className="flex00a" style={{width:k_cstEditCellsModeOpenCaptureColumnWidthEm + "em"}} />
            }
            {c_cstColumnsArrayOfObjs.map((m_cstColumnObj) =>
              <CaptureTableColumnHeader
                key={m_cstColumnObj.id}
                p_cstColumnObj={m_cstColumnObj}
                p_canResizeColumnsTF={canResizeColumnsTF}
              />
            )}
          </CEGeneralReact.CE3Drag3Drop3Shell3>
          {(JSFUNC.is_array(c_cstSumRowColumnsArrayOfObjsOrUndefined)) &&
            <div className={"displayFlexRow borderB1ddd " + c_productStylingObj.cstSumRowBgClass} style={{height:"1.8em", width:c_captureTableTotalWidthEm + "em"}}>
              {(c_userCSTEditCellsModeTF) &&
                <div className="flex00a" style={{width:"1.5em"}} />
              }
              {c_cstSumRowColumnsArrayOfObjsOrUndefined.map((m_cstSumRowColumnObj) =>
                <div
                  className={"flex00a " + ((m_cstSumRowColumnObj.isCstSumColumnTF) ? ("displayFlexColumnVc " + c_productStylingObj.cstSumRowSumColumnBgClass + " lrMedPad " + m_cstSumRowColumnObj.cstCellAlignClass) : (""))}
                  style={{flexBasis:m_cstSumRowColumnObj.columnWidthEm + "em", borderLeft:"solid 1px transparent"}}
                  title={((m_cstSumRowColumnObj.isCstSumColumnTF) ? ("Sum of column '" + m_cstSumRowColumnObj.columnName + "': " + m_cstSumRowColumnObj.sumValueMaskPlainText) : (undefined))}>
                  {(m_cstSumRowColumnObj.isCstSumColumnTF) &&
                    <LibraryReact.Nowrap p_fontClass="fontAlmostWhite">
                      {m_cstSumRowColumnObj.sumValueMask}
                    </LibraryReact.Nowrap>
                  }
                </div>
              )}
            </div>
          }
        </div>
        <CaptureTableBody f_onScrollCstBody={this.onscroll_cst_body} />
      </div>
    );
  }
}));

const CaptureTableBody = inject("CaptureExecMobx", "CapturesMobx")(observer(
class CaptureTableBody extends Component { //props: f_onScrollCstBody
  constructor(props) {
    super(props);
    this.cstBody = React.createRef();
  }

  onscroll_cst_body = (event) => {
    if(this.props.f_onScrollCstBody) {
      this.props.f_onScrollCstBody(this.cstBody.current.scrollLeft);
    }
  }

  onclick_show_all_captures = () => {
    const c_selectedLeftNavIsProgressChartViewTF = this.props.CaptureExecMobx.c_selectedLeftNavIsProgressChartViewTF;

    const maxTrueResetFalse = true;
    this.props.CapturesMobx.a_set_cst_or_chart_max_num_captures_drawn(maxTrueResetFalse, c_selectedLeftNavIsProgressChartViewTF);
  }

  render() {
    const o_cstOrChartMaxNumCapturesDrawn = this.props.CapturesMobx.o_cstOrChartMaxNumCapturesDrawn;
    const c_selectedCaptureTableMasterPresetObj = this.props.CapturesMobx.c_selectedCaptureTableMasterPresetObj;
    const c_cstSortedFilteredMaskedCaptureValuesArrayOfObjs = this.props.CapturesMobx.c_cstSortedFilteredMaskedCaptureValuesArrayOfObjs;
    const c_numFilteredCaptures = this.props.CapturesMobx.c_numFilteredCaptures;

    if(c_numFilteredCaptures === 0) {
      return(
        <CEGeneralReact.EmptyScreenGray p_fontClass="font12">
          {"0 captures using filter '" + c_selectedCaptureTableMasterPresetObj.name + "'"}
        </CEGeneralReact.EmptyScreenGray>
      );
    }



    return(
      <div
        ref={this.cstBody}
        className="flex11a xyScroll yScrollBottomPad"
        onScroll={this.onscroll_cst_body}>
        {c_cstSortedFilteredMaskedCaptureValuesArrayOfObjs.map((m_captureValueObj, m_index) =>
          (m_index < o_cstOrChartMaxNumCapturesDrawn) &&
          <CaptureTableRow
            key={m_captureValueObj.id}
            p_captureObj={m_captureValueObj}
          />
        )}
        <ShowAllCapturesButtonAndMessage
          p_numFilteredCaptures={c_numFilteredCaptures}
          p_maxNumCapturesDrawn={o_cstOrChartMaxNumCapturesDrawn}
          f_onClickShowAllCaptures={this.onclick_show_all_captures}
        />
      </div>
    );
  }
}));

function ShowAllCapturesButtonAndMessage(props) { //props: p_numFilteredCaptures, p_maxNumCapturesDrawn, f_onClickShowAllCaptures
  if(props.p_numFilteredCaptures <= props.p_maxNumCapturesDrawn) {
    return(null);
  }

  return(
    <div className="displayFlexRow smallFullPad" style={{backgroundColor:"#ccc"}}>
      <div className="flex00a lrMargin">
        <CEGeneralReact.CEButton
          p_type="blue"
          p_text={"Show All " + props.p_numFilteredCaptures + " Captures"}
          f_onClick={props.f_onClickShowAllCaptures}
        />
      </div>
      <div className="flex11a lrMargin">
        <font className="fontItalic">
          <div>{"This is the first " + props.p_maxNumCapturesDrawn + " captures matching your filter/sort. You can click to display all matching captures."}</div>
          <div>{"If displaying too many on the screen freezes your browser, close it and log back in."}</div>
        </font>
      </div>
    </div>
  );
}

const CaptureTableColumnHeader = inject("CaptureExecMobx", "CapturesMobx", "UserMobx")(observer(
class CaptureTableColumnHeader extends Component { //props: p_cstColumnObj, p_canResizeColumnsTF
  onclick_column_header_to_sort = () => {
    const p_cstColumnObj = this.props.p_cstColumnObj;
    this.props.CapturesMobx.a_cst_column_sort_override(p_cstColumnObj.capture_field_id);
  }

  ondrop_column_resize = (i_draggedColumnTblFCstColumnsID, event) => {
    this.props.CapturesMobx.a_cst_column_resize(i_draggedColumnTblFCstColumnsID, event.clientX);
  }

  render() {
    const p_cstColumnObj = this.props.p_cstColumnObj;
    const p_canResizeColumnsTF = this.props.p_canResizeColumnsTF;

    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const c_sortsArrayOfObjs = this.props.CapturesMobx.c_sortsArrayOfObjs;
    const c_userCaptureTableRowHeightOptionsObj = this.props.UserMobx.c_userCaptureTableRowHeightOptionsObj;

    var bgColorClass = c_productStylingObj.cstColumnHeaderBgClass; //column not sorted
    var fontClass = c_productStylingObj.cstColumnHeaderFontClass;
    var sortIsAscTF = undefined; //undefined means the column is not sorted
    for(let s = 0; s < c_sortsArrayOfObjs.length; s++) {
      if(p_cstColumnObj.capture_field_id === c_sortsArrayOfObjs[s].capture_field_id) { //this is one of possibly several sorted columns
        bgColorClass = ((s === 0) ? (c_productStylingObj.cstColumnHeaderSortedPrimaryBgClass) : (c_productStylingObj.cstColumnHeaderSortedSecondaryBgClass)); //primary or secondary sort column
        fontClass = c_productStylingObj.cstColumnHeaderSortedFontClass;
        sortIsAscTF = c_sortsArrayOfObjs[s].is_asc_01; //sort arrow direction
      }
    }

    return (
      <div
        className={"flex00a displayFlexRow borderL1bbb " + bgColorClass}
        style={{flexBasis:p_cstColumnObj.width_em + "em"}}>
        <div
          className="flex11a displayFlexRow cursorPointer"
          title={p_cstColumnObj.fieldDisplayName + "\n[click to sort table by this column]"}
          onClick={this.onclick_column_header_to_sort}>
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"1.2em", marginLeft:"0.15em"}}>
            <CEGeneralReact.HeaderSortArrow
              p_isSortedTF={(sortIsAscTF !== undefined)}
              p_sortedAscTF={sortIsAscTF}
            />
          </div>
          <div className="flex11a displayFlexRowVc textCenter" style={{marginRight:((p_canResizeColumnsTF) ? ("0.15em") : ("0.5em"))}}>
            {(c_userCaptureTableRowHeightOptionsObj.headerTextMaxHeightTrueNowrapFalse) ? (
              <LibraryReact.MaxHeightWrap p_maxHeight={c_userCaptureTableRowHeightOptionsObj.headerTextMaxHeightEm + "em"} p_fontClass={fontClass}>
                {p_cstColumnObj.fieldDisplayName}
              </LibraryReact.MaxHeightWrap>
            ) : (
              <LibraryReact.Nowrap p_fontClass={fontClass}>
                {p_cstColumnObj.fieldDisplayName}
              </LibraryReact.Nowrap>
            )}
          </div>
        </div>
        {(p_canResizeColumnsTF) &&
          <CEGeneralReact.CE3Drag3Drop3Shell3
            p_uniqueString="cstHeadersDragDrop"
            p_itemID={p_cstColumnObj.id}
            p_draggableTF={true}
            p_droppableTF={false}
            p_dropZoneIsInvisibleOverlayTF={false}
            p_dropZoneOversizeWidthEm={undefined}
            p_class="flex00a cursorLRResizeArrows"
            p_styleObj={{flexBasis:"0.75em", borderLeft:"solid 1px #99b"}}
            p_title={"Click, drag left or right, and release to change width of column '" + p_cstColumnObj.fieldDisplayName + "'"}
            p_dragOverClass={undefined}
            p_dragOverStyleObj={undefined}
            f_onDropMatchingItem={this.ondrop_column_resize}
          />
        }
      </div>
    );
  }
}));

const CaptureTableRow = inject("CapturesMobx", "OpenCaptureMobx", "UserMobx")(observer(
class CaptureTableRow extends Component { //props: p_captureObj
  onclick_open_capture = () => {
    this.props.OpenCaptureMobx.a_open_single_capture(this.props.p_captureObj.id);
  }

  render() {
    const p_captureObj = this.props.p_captureObj;

    const k_cstEditCellsModeOpenCaptureColumnWidthEm = this.props.CapturesMobx.k_cstEditCellsModeOpenCaptureColumnWidthEm;
    const c_cstColumnsArrayOfObjs = this.props.CapturesMobx.c_cstColumnsArrayOfObjs;
    const c_captureTableTotalWidthEm = this.props.CapturesMobx.c_captureTableTotalWidthEm;
    const c_combinedUserObj = this.props.UserMobx.c_combinedUserObj;
    const c_userCSTEditCellsModeTF = this.props.UserMobx.c_userCSTEditCellsModeTF;

    const openCaptureTitle = "Click to open Capture '" + p_captureObj.captureFullName + "'";

    return(
      <div
        className="displayFlexRow bgWhite borderB1ddd hoverLighterBlueGradient"
        style={{height:c_combinedUserObj.cst_row_height_em + "em", width:c_captureTableTotalWidthEm + "em"}}>
        {(c_userCSTEditCellsModeTF) &&
          <div
            className="flex00a displayFlexColumn bgRedCapture hoverRedCapture cursorPointer"
            style={{width:k_cstEditCellsModeOpenCaptureColumnWidthEm + "em"}}
            title={openCaptureTitle}
            onClick={this.onclick_open_capture}>
            <div className="flex11a displayFlexColumnHcVc borderR1bbb">
              <CEGeneralReact.SvgOpenCapture
                p_sizeEm={1.6}
                p_color="fff"
              />
            </div>
          </div>
        }
        {c_cstColumnsArrayOfObjs.map((m_cstColumnObj) =>
          <CaptureTableCell
            key={m_cstColumnObj.id}
            p_cstColumnObj={m_cstColumnObj}
            p_captureObj={p_captureObj}
          />
        )}
      </div>
    );
  }
}));


const CaptureTableCell = inject("OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class CaptureTableCell extends Component {  //props: p_cstColumnObj, p_captureObj
  constructor(props) {
    super(props);
    this.state = {
      s_editCellFloatingBoxIsOpenTF: false 
    };
  }

  onclick_open_capture = () => {
    this.props.OpenCaptureMobx.a_open_single_capture(this.props.p_captureObj.id);
  }

  onselect_cst_capture_favorite_or_priority_level = (i_selectedFieldValueRaw) => {
    const p_cstColumnObj = this.props.p_cstColumnObj;
    const p_captureObj = this.props.p_captureObj;

    const columnExpandedCaptureFieldMap = p_captureObj[p_cstColumnObj.fieldDbName + "ECFM"];
    this.props.OpenCaptureMobx.a_details_update_field_value(columnExpandedCaptureFieldMap, i_selectedFieldValueRaw, p_captureObj.id);
  }

  onclick_edit_cell = () => {
    const p_cstColumnObj = this.props.p_cstColumnObj;
    const p_captureObj = this.props.p_captureObj;

    const k_cardIDAdvanceStage = this.props.DatabaseMobx.k_cardIDAdvanceStage;
    const k_cardIDDealShaping = this.props.DatabaseMobx.k_cardIDDealShaping;
    const k_cardIDNotepad = this.props.DatabaseMobx.k_cardIDNotepad;
    const c_companyPwinUsersCanEditTF = this.props.DatabaseMobx.c_companyPwinUsersCanEditTF;
    const c_fieldMapOfStage = this.props.DatabaseMobx.c_fieldMapOfStage;
    const c_fieldMapOfTotalShapingProgress = this.props.DatabaseMobx.c_fieldMapOfTotalShapingProgress;
    const c_fieldMapOfStageShapingProgress = this.props.DatabaseMobx.c_fieldMapOfStageShapingProgress;
    const c_fieldMapOfPwin = this.props.DatabaseMobx.c_fieldMapOfPwin;
    const c_fieldMapOfRecentPinnedNoteStamp = this.props.DatabaseMobx.c_fieldMapOfRecentPinnedNoteStamp;

    //editing a stage/dealshaping cell opens that capture into the Advance Stage/Deal Shaping card
    var cellFieldIsStageTF = false;
    var cellFieldIsTotalShapingProgressTF = false;
    var cellFieldIsStageShapingProgressTF = false;
    var cellFieldIsPwinTF = false;
    var cellFieldIsRecentPinnedNotestampTF = false;
    if(p_cstColumnObj.capture_field_id === c_fieldMapOfStage.get("id")) { cellFieldIsStageTF = true; }
    else if(p_cstColumnObj.capture_field_id === c_fieldMapOfTotalShapingProgress.get("id")) { cellFieldIsTotalShapingProgressTF = true; }
    else if(p_cstColumnObj.capture_field_id === c_fieldMapOfStageShapingProgress.get("id")) { cellFieldIsStageShapingProgressTF = true; }
    else if(p_cstColumnObj.capture_field_id === c_fieldMapOfPwin.get("id")) { cellFieldIsPwinTF = true; }
    else if(p_cstColumnObj.capture_field_id === c_fieldMapOfRecentPinnedNoteStamp.get("id")) { cellFieldIsRecentPinnedNotestampTF = true; }

    if(cellFieldIsStageTF || cellFieldIsTotalShapingProgressTF || cellFieldIsStageShapingProgressTF || (cellFieldIsPwinTF && !c_companyPwinUsersCanEditTF) || cellFieldIsRecentPinnedNotestampTF) {
      const functionOnSuccess = (i_parseResponse) => {
        if(cellFieldIsStageTF) {
          this.props.OpenCaptureMobx.a_launch_card_full(k_cardIDAdvanceStage);
        }
        else if(cellFieldIsTotalShapingProgressTF || cellFieldIsStageShapingProgressTF || cellFieldIsPwinTF) {
          this.props.OpenCaptureMobx.a_launch_card_full(k_cardIDDealShaping);
        }
        else if(cellFieldIsRecentPinnedNotestampTF) {
          this.props.OpenCaptureMobx.a_launch_card_full(k_cardIDNotepad);
        }
      }
      this.props.OpenCaptureMobx.a_open_single_capture(p_captureObj.id, functionOnSuccess);
    }
    else { //otherwise open the edit cell floating box for editing a field
      this.setState({s_editCellFloatingBoxIsOpenTF:true});
    }
  }

  onclick_close_edit_cell_floating_box = () => {
    this.setState({s_editCellFloatingBoxIsOpenTF:false});
  }

  render() {
    const s_editCellFloatingBoxIsOpenTF = this.state.s_editCellFloatingBoxIsOpenTF;

    const p_cstColumnObj = this.props.p_cstColumnObj;
    const p_captureObj = this.props.p_captureObj;

    const c_singleCaptureIsOpenTF = this.props.OpenCaptureMobx.c_singleCaptureIsOpenTF;
    const c_companyPwinIsCalculatedTF = this.props.DatabaseMobx.c_companyPwinIsCalculatedTF;
    const c_cardNameAdvanceStage = this.props.DatabaseMobx.c_cardNameAdvanceStage;
    const c_cardNameDealShaping = this.props.DatabaseMobx.c_cardNameDealShaping;
    const c_cardNameNotepad = this.props.DatabaseMobx.c_cardNameNotepad;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;
    const c_userCaptureTableRowHeightOptionsObj = this.props.UserMobx.c_userCaptureTableRowHeightOptionsObj;
    const c_userCSTEditCellsModeTF = this.props.UserMobx.c_userCSTEditCellsModeTF;

    //if a capture is opened, blank out every cell in every row so that animating the capture opening or closing it and returning to the table is not slow (plus the current scroll down in the capture table is maintained for clicking on multiple captures back to back)
    if(c_singleCaptureIsOpenTF) {
      return(null);
    }

    const columnExpandedCaptureFieldMap = p_captureObj[p_cstColumnObj.fieldDbName + "ECFM"];
    const valueRaw = p_captureObj[p_cstColumnObj.fieldDbName + "RAW"];
    const valueMaskPlainText = p_captureObj[p_cstColumnObj.fieldDbName + "MASKPT"];
    const valueMask = p_captureObj[p_cstColumnObj.fieldDbName + "MASK"];
    const valueSort = p_captureObj[p_cstColumnObj.fieldDbName + "SORT"];
    const valueIsFilledOutTF = p_captureObj[p_cstColumnObj.fieldDbName + "IFO"];
    const directEditCaptureFavoritesTF = p_captureObj[p_cstColumnObj.fieldDbName + "isCF"];
    const directEditCapturePriorityLevelTF = p_captureObj[p_cstColumnObj.fieldDbName + "isCFPL"];

    const captureFieldMapExistsTF = (columnExpandedCaptureFieldMap !== undefined);

    //(favorite/priority) direct edit field within a cell (can't direct edit when in compressed rows view, too small to draw edit in cell)
    if(captureFieldMapExistsTF) {
      if(directEditCaptureFavoritesTF) {
        return(
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:p_cstColumnObj.width_em + "em"}}>
            <CEGeneralReact.InputCaptureFavorite
              p_favoriteTF={valueRaw}
              p_standardSizeTrueCSTCellSizeFalse={(c_userCaptureTableRowHeightOptionsObj.cellCaptureFavoritesDirectEditSizeEm === undefined)}
              f_onClick={((c_userCanEditCaptureCardContentTF) ? (this.onselect_cst_capture_favorite_or_priority_level) : (undefined))}
            />
          </div>
        );
      }
      else if(directEditCapturePriorityLevelTF && c_userCaptureTableRowHeightOptionsObj.cellCapturePriorityDirectEditTF) {
        return(
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:p_cstColumnObj.width_em + "em"}}>
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={columnExpandedCaptureFieldMap.get("fieldTypeObj")}
              p_valueRaw={valueRaw}
              f_onChangeOrOnSelect={((c_userCanEditCaptureCardContentTF) ? (this.onselect_cst_capture_favorite_or_priority_level) : (undefined))}
            />
          </div>
        );
      }
    }

    //cell text or html valueMask within maxheight or nowrap for cell
    var cellContentComponent = null;
    if(c_userCaptureTableRowHeightOptionsObj.cellTextMaxHeightWrapTrueNowrapFalse) {
      cellContentComponent = (
        <LibraryReact.MaxHeightWrap p_maxHeight={c_userCaptureTableRowHeightOptionsObj.cellTextMaxHeightEm + "em"}>
          {valueMask}
        </LibraryReact.MaxHeightWrap>
      );
    }
    else {
      cellContentComponent = (
        <LibraryReact.Nowrap>
          {valueMask}
        </LibraryReact.Nowrap>
      );
    }

    //edit cells mode turned on (and user is not view only), draw cell with transparent border inner cell that highlights on hover
    if(c_userCSTEditCellsModeTF && c_userCanEditCaptureCardContentTF) {
      const cellIsEditableTF = (captureFieldMapExistsTF && columnExpandedCaptureFieldMap.get("canEditFieldInCSTCellTF"));

      var cellTitle = "";
      if(cellIsEditableTF) {
        if(columnExpandedCaptureFieldMap.get("fieldIsStageIDTF")) {
          cellTitle = "[Click to open the Capture " + c_cardNameAdvanceStage + " Card to advance the " + p_cstColumnObj.fieldDisplayName + "]";
        }
        else if(columnExpandedCaptureFieldMap.get("fieldIsShapingTotalProgressTF") || columnExpandedCaptureFieldMap.get("fieldIsShapingStageProgressTF")) {
          cellTitle = "[Click to open the Capture " + c_cardNameDealShaping + " Card to answer shaping questions that affect the Progress score]";
        }
        else if(columnExpandedCaptureFieldMap.get("fieldIsPwinTF") && c_companyPwinIsCalculatedTF) {
          cellTitle = "[Click to open the Capture " + c_cardNameDealShaping + " Card to answer shaping questions that affect the calculated " + p_cstColumnObj.fieldDisplayName + "]";
        }
        else if(columnExpandedCaptureFieldMap.get("fieldIsRecentPinnedNotestampTF")) {
          cellTitle = "[Click to open the Capture " + c_cardNameNotepad + " Card to edit Note Stamps]";
        }
        else {
          cellTitle = "[Click to edit field '" + p_cstColumnObj.fieldDisplayName + "']";
        }
      }
      else {
        cellTitle = "--Field '" + p_cstColumnObj.fieldDisplayName + "' is not editable--";
      }
      cellTitle += "\n\nCapture: '" + p_captureObj.captureFullName + "'";
      cellTitle += "\nField: " + p_cstColumnObj.fieldDisplayName;
      cellTitle += "\nValue: " + valueMaskPlainText;

      return(
        <>
          <div
            className={"flex00a displayFlexColumn " + p_cstColumnObj.cstCellAlignClass + " " + ((cellIsEditableTF) ? ("cursorPointer") : ("cursorNotAllowed"))}
            style={{flexBasis:p_cstColumnObj.width_em + "em"}}
            title={cellTitle}
            onClick={((cellIsEditableTF) ? (this.onclick_edit_cell) : (undefined))}>
            <div className={"flex11a displayFlexRowVc lrMedPad border2transparent " + ((cellIsEditableTF) ? ("hoverBorderColorBlue hoverLightestBlue") : ("hoverBorderColorGray hoverLightGray"))}>
              {cellContentComponent}
            </div>
          </div>
          {(s_editCellFloatingBoxIsOpenTF) &&
            <CaptureTableEditingCellFloatingBox
              p_cstColumnObj={p_cstColumnObj}
              p_captureObj={p_captureObj}
              f_onClickClose={this.onclick_close_edit_cell_floating_box}
            />
          }
        </>
      );
    }

    //normal CST cells (edit cells mode switched off) containing maxHeight or nowrap text
    var normalCstCellHoverText = "[Click to open Capture]";
    normalCstCellHoverText += "\n\nCapture: '" + p_captureObj.captureFullName + "'";
    normalCstCellHoverText += "\nField: " + p_cstColumnObj.fieldDisplayName;
    normalCstCellHoverText += "\nValue: " + valueMaskPlainText;
    return(
      <div
        className={"flex00a displayFlexRowVc lrMedPad " + p_cstColumnObj.cstCellAlignClass + " cursorPointer"}
        style={{flexBasis:p_cstColumnObj.width_em + "em"}}
        title={normalCstCellHoverText}
        onClick={this.onclick_open_capture}>
        {cellContentComponent}
      </div>
    );
  }
}));


const CaptureTableEditingCellFloatingBox = inject("CapturesMobx", "OpenCaptureMobx", "DatabaseMobx")(observer(
class CaptureTableEditingCellFloatingBox extends Component {  //props: p_cstColumnObj, p_captureObj, f_onClickClose
  componentDidMount() {
    const p_cstColumnObj = this.props.p_cstColumnObj;
    const p_captureObj = this.props.p_captureObj;

    const captureID = p_captureObj.id;
    const captureCellFieldInitialValueRaw = p_captureObj[p_cstColumnObj.fieldDbName + "RAW"];
    const columnExpandedCaptureFieldMap = p_captureObj[p_cstColumnObj.fieldDbName + "ECFM"];
    const columnFieldDbName = columnExpandedCaptureFieldMap.get("db_name");
    const columnFieldTypeObj = columnExpandedCaptureFieldMap.get("fieldTypeObj");

    //load the raw value into the temp var for inline edit changes before saving
    this.onchange_cell_value(captureCellFieldInitialValueRaw);

    //if this capture field is a 'textarea' input type, fetch the full text value for this capture/field from the database (display waiting animation while fetching), needed because only slim first 100 chars with "..." are loaded at login for each capture in the capture table
    if(columnFieldTypeObj.textareaTF) {
      if(JSFUNC.string_is_filled_out_tf(captureCellFieldInitialValueRaw)) { //no need to fetch the full text if the slim value is "", the full textarea will also be ""
        this.props.CapturesMobx.a_capture_table_inline_edit_load_single_cell_full_textarea_from_capture_id_and_field_db_name(captureID, columnFieldDbName, captureCellFieldInitialValueRaw);
      }
    }
  }

  onchange_cell_value = (i_newValue) => {
    this.props.CapturesMobx.a_set_capture_table_inline_edit_single_cell_value_raw(i_newValue);
  }

  onclick_cell_details_field_floating_box_save = () => {
    const o_captureTableInlineEditSingleCellValueRaw = this.props.CapturesMobx.o_captureTableInlineEditSingleCellValueRaw;
    this.onclick_save_edited_cell_value_to_capture(o_captureTableInlineEditSingleCellValueRaw);
  }

  onclick_save_edited_cell_value_to_capture = (i_updatedCellValueRaw) => {
    const p_cstColumnObj = this.props.p_cstColumnObj;
    const p_captureObj = this.props.p_captureObj;

    const captureID = p_captureObj.id;
    const valueRaw = p_captureObj[p_cstColumnObj.fieldDbName + "RAW"];

    //check if updated value is different from initial value (handled in CaptureFieldValueEditSaveCancel, but not this details updater)
    if(i_updatedCellValueRaw !== valueRaw) {
      const columnExpandedCaptureFieldMap = p_captureObj[p_cstColumnObj.fieldDbName + "ECFM"];
      if(columnExpandedCaptureFieldMap !== undefined) {
        const functionOnVerifiedError = () => {
          alert("The previous edit was not successfully saved. Please log out and log back in and retry.\n - Capture: " + p_captureObj.captureFullName + "\n - Field: " + p_cstColumnObj.fieldDisplayName);
        }
        this.props.OpenCaptureMobx.a_details_update_field_value(columnExpandedCaptureFieldMap, i_updatedCellValueRaw, captureID, undefined, true, true, undefined, functionOnVerifiedError);
      }
    }

    if(JSFUNC.is_function(this.props.f_onClickClose)) {
      this.props.f_onClickClose();
    }
  }

  render() {
    const p_cstColumnObj = this.props.p_cstColumnObj;
    const p_captureObj = this.props.p_captureObj;
    
    const o_captureTableInlineEditSingleCellValueRaw = this.props.CapturesMobx.o_captureTableInlineEditSingleCellValueRaw;
    const o_captureTableInlineEditSingleCellFullTextareaIsLoadingTF = this.props.CapturesMobx.o_captureTableInlineEditSingleCellFullTextareaIsLoadingTF;

    const columnExpandedCaptureFieldMap = p_captureObj[p_cstColumnObj.fieldDbName + "ECFM"];

    var fieldTypeObj = undefined;
    var trblFlag = "confirmBox";
    if(columnExpandedCaptureFieldMap !== undefined) {
      fieldTypeObj = columnExpandedCaptureFieldMap.get("fieldTypeObj");
      if(fieldTypeObj.dateWithRelativeDateTF || fieldTypeObj.dateWithDurationTF || fieldTypeObj.selectTF || fieldTypeObj.sharedPercentTF || fieldTypeObj.textareaTF) {
        trblFlag = "smallVertical";
      }
    }

    const floatingBoxTitle = "Editing Capture '" + p_captureObj.captureFullName + "'";

    if((fieldTypeObj.selectContactCompanyTF || fieldTypeObj.selectContactPersonTF) && !fieldTypeObj.sharedPercentTF) {
      return(
        <CEGeneralReact.SelectOrSelectMultiContactsFloatingBoxFromFieldTypeObj
          p_fieldTypeObj={fieldTypeObj}
          p_valueRaw={o_captureTableInlineEditSingleCellValueRaw}
          p_valuesToNotIncludeArray={undefined}
          p_contactsZoomedCompanyID={undefined}
          p_floatingBoxTitle={"Editing field " + p_cstColumnObj.fieldDisplayName + " in Capture '" + p_captureObj.captureFullName + "'"}
          f_onSaveChanged={this.onclick_save_edited_cell_value_to_capture}
          f_onCancel={this.props.f_onClickClose}
        />
      );
    }

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag={trblFlag}
        p_title={floatingBoxTitle}
        f_onClickSave={((o_captureTableInlineEditSingleCellFullTextareaIsLoadingTF) ? (undefined) : (this.onclick_cell_details_field_floating_box_save))}
        f_onClickCancel={this.props.f_onClickClose}>
        <LibraryReact.InteractiveDiv
          p_class="flex11a displayFlexColumn tbPad lrMedPad yScroll"
          f_onKeyDownEnter={((o_captureTableInlineEditSingleCellFullTextareaIsLoadingTF || fieldTypeObj.textareaTF) ? (undefined) : (this.onclick_cell_details_field_floating_box_save))}
          f_onKeyDownEsc={this.props.f_onClickClose}>
          <div className="flex00a smallBottomMargin">
            <font className="fontBlue">
              {p_cstColumnObj.fieldDisplayName}
            </font>
          </div>
          <div
            className="flex00a displayFlexColumn border1ddd bgCaptureItemEditing smallFullPad"
            style={{maxWidth:fieldTypeObj.recommendedEditMaxWidthOrUndefined, height:((fieldTypeObj.textareaTF) ? ("90%") : (undefined))}}>
            {(o_captureTableInlineEditSingleCellFullTextareaIsLoadingTF) ? (
              <div className="displayFlexColumnHcVc textCenter">
                <div className="flex00a smallBottomMargin">
                  <font className="fontItalic fontTextLight">
                    {"Loading full text for '" + columnExpandedCaptureFieldMap.get("display_name") + "'"}
                  </font>
                </div>
                <CEGeneralReact.LoadingAnimation />
              </div>
            ) : (
              <CEGeneralReact.GenericInputOrSelectFromInputType
                p_fieldTypeObj={fieldTypeObj}
                p_valueRaw={o_captureTableInlineEditSingleCellValueRaw}
                p_valuesToNotIncludeArray={undefined}
                p_selectInitOptionsBoxOpenTF={true}
                p_selectedDisplayFocusOnKeyDownEnterOpensSelectTF={false}
                p_tabIndex={1}
                f_onChangeOrOnSelect={this.onchange_cell_value}
                f_onKeyDownEnter={this.onclick_cell_details_field_floating_box_save}
              />
            )}
          </div>
          <div className="medTopMargin" />
        </LibraryReact.InteractiveDiv>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));







//======================================================================================================================================
//======================================================================================================================================
//Stage View
//======================================================================================================================================
//======================================================================================================================================
const StageOrPriorityView = inject("CaptureExecMobx", "CapturesMobx", "DatabaseMobx", "UserMobx")(observer(
class StageOrPriorityView extends Component { //props: p_capturesViewFlag
  onclick_stage_view_capture_type_checkbox_tab = (i_selectedCaptureTypeIDsComma) => {
    this.props.UserMobx.a_update_user_per_email_field("stage_view_selected_capture_type_ids_comma", i_selectedCaptureTypeIDsComma, "s");
  }

  onclick_show_all_captures = () => {
    const maxTrueResetFalse = true;
    this.props.CapturesMobx.a_set_cst_or_chart_max_num_captures_drawn(maxTrueResetFalse);
  }

  render() {
    const p_capturesViewFlag = this.props.p_capturesViewFlag;

    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;
    const o_cstOrChartMaxNumCapturesDrawn = this.props.CapturesMobx.o_cstOrChartMaxNumCapturesDrawn;
    const c_selectedCaptureTableMasterPresetObj = this.props.CapturesMobx.c_selectedCaptureTableMasterPresetObj;
    const c_stageViewSelectedCaptureTypeStagesArrayOfObjs = this.props.CapturesMobx.c_stageViewSelectedCaptureTypeStagesArrayOfObjs;
    const c_stageViewAtLeast1CaptureInSelectedCaptureTypeTF = this.props.CapturesMobx.c_stageViewAtLeast1CaptureInSelectedCaptureTypeTF;
    const c_stageViewAtLeast1StageHasMoreCapturesThanLimitTF = this.props.CapturesMobx.c_stageViewAtLeast1StageHasMoreCapturesThanLimitTF;
    const c_stageViewCaptureTypeFieldDisplayNameWithSelectedCaptureTypeName = this.props.CapturesMobx.c_stageViewCaptureTypeFieldDisplayNameWithSelectedCaptureTypeName;
    const c_priorityViewAllPriorityColumnsOfLimitedCapturesArrayOfObjs = this.props.CapturesMobx.c_priorityViewAllPriorityColumnsOfLimitedCapturesArrayOfObjs;
    const c_priorityViewAtLeast1CaptureTF = this.props.CapturesMobx.c_priorityViewAtLeast1CaptureTF;
    const c_priorityViewAtLeast1PriorityHasMoreCapturesThanLimitTF = this.props.CapturesMobx.c_priorityViewAtLeast1PriorityHasMoreCapturesThanLimitTF;
    const c_numFilteredCaptures = this.props.CapturesMobx.c_numFilteredCaptures;
    const c_fieldMapOfCaptureType = this.props.DatabaseMobx.c_fieldMapOfCaptureType;
    const c_fieldMapOfCapturePriorityLevel = this.props.DatabaseMobx.c_fieldMapOfCapturePriorityLevel;
    const c_captureTypeIDsArray = this.props.DatabaseMobx.c_captureTypeIDsArray;
    const c_captureTypeNamesArray = this.props.DatabaseMobx.c_captureTypeNamesArray;
    const c_combinedUserObj = this.props.UserMobx.c_combinedUserObj;
    const c_userStageViewSelectedCaptureTypeIDsArray = this.props.UserMobx.c_userStageViewSelectedCaptureTypeIDsArray;

    //stage vs priority vars
    const stageViewTF = (p_capturesViewFlag === "stage");
    const priorityViewTF = (p_capturesViewFlag === "priority");
    var stageOrPriorityColumnsOfLimitedCapturesArrayOfObjs = [];
    var viewAtLeast1CaptureTF = false;
    var viewAtLeast1StageOrPriorityHasMoreCapturesThanLimitTF = false;
    if(stageViewTF) {
      stageOrPriorityColumnsOfLimitedCapturesArrayOfObjs = c_stageViewSelectedCaptureTypeStagesArrayOfObjs;
      viewAtLeast1CaptureTF = c_stageViewAtLeast1CaptureInSelectedCaptureTypeTF;
      viewAtLeast1StageOrPriorityHasMoreCapturesThanLimitTF = c_stageViewAtLeast1StageHasMoreCapturesThanLimitTF;
    }
    else if(priorityViewTF) {
      stageOrPriorityColumnsOfLimitedCapturesArrayOfObjs = c_priorityViewAllPriorityColumnsOfLimitedCapturesArrayOfObjs;
      viewAtLeast1CaptureTF = c_priorityViewAtLeast1CaptureTF;
      viewAtLeast1StageOrPriorityHasMoreCapturesThanLimitTF = c_priorityViewAtLeast1PriorityHasMoreCapturesThanLimitTF;
    }

    const captureTypeFieldDisplayName = c_fieldMapOfCaptureType.get("display_name");
    const userNumCaptureTypesSelected = c_userStageViewSelectedCaptureTypeIDsArray.length;
    const selectedCaptureTypeHas0StagesTF = (stageOrPriorityColumnsOfLimitedCapturesArrayOfObjs.length === 0);

    var selectCaptureTypeTopBarComponent = null;
    if(stageViewTF) {
      const selectAStageViewLabelTitle = undefined;//"Each different " + captureTypeFieldDisplayName + " set up by your " + c_productStylingObj.c_productStylingObjproductName + " Admin can have a different timeline of Stages";
      selectCaptureTypeTopBarComponent = (
        <div className="flex00a displayFlexRow smallTopMargin lrMargin border bevelBorderDarkColors bgDarkGray" style={{height:"4.2em"}}>
          <div
            className="flex00a displayFlexColumnVc lrPad"
            style={{flexBasis:"11em"}}
            title={selectAStageViewLabelTitle}>
            <font className="fontItalic fontAlmostWhite">
              {"Select a " + captureTypeFieldDisplayName + " for Stage View"}
            </font>
          </div>
          <div className="flex11a displayFlexColumnVc lrMedPad xScroll">
            <CEGeneralReact.TabsList
              p_canMultiSelectTabsFromSelectedDbNamesCommaTF={true}
              p_tabDbNamesArray={c_captureTypeIDsArray}
              p_tabDisplayNamesArray={c_captureTypeNamesArray}
              p_selectedTabDbName={c_combinedUserObj.stage_view_selected_capture_type_ids_comma}
              p_tabHeight="2.7em"
              p_textMaxHeight="2.4em"
              p_tabWidth="10em"
              p_unselectedFontClass="fontBold"
              p_unselectedBgClass="bgLighterGrayGradient hoverLighterBlueGradient fontTextLight"
              p_selectedBgClass="bgOrangeGradient"
              f_onSelect={this.onclick_stage_view_capture_type_checkbox_tab}
            />
          </div>
        </div>
      );
    }

    var stageViewContentComponent = null;
    if(stageViewTF && (userNumCaptureTypesSelected === 0)) {
      stageViewContentComponent = (
        <CEGeneralReact.EmptyScreenGray p_fontClass="font12">
          {"Select at least 1 " + captureTypeFieldDisplayName + " above to view its Captures"}
        </CEGeneralReact.EmptyScreenGray>
      );
    }
    else if(selectedCaptureTypeHas0StagesTF) {
      var zeroCategoriesErrorMessage = "";
      if(stageViewTF) {
        zeroCategoriesErrorMessage = c_stageViewCaptureTypeFieldDisplayNameWithSelectedCaptureTypeName + " has 0 Stages";
      }
      else if(priorityViewTF) {
        zeroCategoriesErrorMessage = "'" + c_fieldMapOfCapturePriorityLevel.get("display_name") + "' field does not have any categories set up by the Admin";
      }

      stageViewContentComponent = (
        <CEGeneralReact.EmptyScreenGray p_fontClass="font12">
          {zeroCategoriesErrorMessage}
        </CEGeneralReact.EmptyScreenGray>
      );
    }
    else {
      const stageColumnsWithCaptureBlocksComponent = (
        stageOrPriorityColumnsOfLimitedCapturesArrayOfObjs.map((m_stageViewExpandedStageObj, m_index) =>
          <StageViewStageColumnWithCaptureBlocks
            key={m_stageViewExpandedStageObj.id}
            p_capturesViewFlag={p_capturesViewFlag}
            p_stageViewExpandedStageObj={m_stageViewExpandedStageObj}
            p_isFirstStageTF={(m_index === 0)}
          />
        )
      );

      if(viewAtLeast1CaptureTF) { //Stages with Captures and 'show all' button at bottom
        stageViewContentComponent = (
          <div className="flex11a xyScroll">
            <div className="displayFlexRow lrPad medBottomPad">
              {stageColumnsWithCaptureBlocksComponent}
            </div>
            {(viewAtLeast1StageOrPriorityHasMoreCapturesThanLimitTF) &&
              <ShowAllCapturesButtonAndMessage
                p_numFilteredCaptures={c_numFilteredCaptures}
                p_maxNumCapturesDrawn={o_cstOrChartMaxNumCapturesDrawn}
                f_onClickShowAllCaptures={this.onclick_show_all_captures}
              />
            }
          </div>
        );
      }
      else { //0 Captures, show Stages across top with no Captures, full blank screen with 0 Captures message
        stageViewContentComponent = (
          <>
            <div className="flex00a displayFlexRow lrPad smallBottomPad xyScroll">
              {stageColumnsWithCaptureBlocksComponent}
            </div>
            <CEGeneralReact.EmptyScreenGray p_fontClass="font12">
              <div>
                {"0 captures using filter '" + c_selectedCaptureTableMasterPresetObj.name + "'"}
              </div>
              {(stageViewTF) &&
                <div>
                  {"(when combined with selected " + c_stageViewCaptureTypeFieldDisplayNameWithSelectedCaptureTypeName + ")"}
                </div>
              }
              {(priorityViewTF) &&
                <div>
                  {"(with field '" + c_fieldMapOfCapturePriorityLevel.get("display_name") + "' filled out)"}
                </div>
              }
            </CEGeneralReact.EmptyScreenGray>
          </>
        );
      }
    }

    return(
      <>
        {selectCaptureTypeTopBarComponent}
        {stageViewContentComponent}
      </>
    );
  }
}));


const StageViewStageColumnWithCaptureBlocks = inject("CapturesMobx", "DatabaseMobx", "UserMobx")(observer(
class StageViewStageColumnWithCaptureBlocks extends Component { //props: p_capturesViewFlag, p_stageViewExpandedStageObj, p_isFirstStageTF
  render() {
    const p_capturesViewFlag = this.props.p_capturesViewFlag;
    const p_stageViewExpandedStageObj = this.props.p_stageViewExpandedStageObj;
    const p_isFirstStageTF = this.props.p_isFirstStageTF;

    const c_cstSortedFilteredMaskedCaptureValuesArrayOfObjs = this.props.CapturesMobx.c_cstSortedFilteredMaskedCaptureValuesArrayOfObjs;
    const c_fieldMapOfStage = this.props.DatabaseMobx.c_fieldMapOfStage;
    const c_fieldMapOfCapturePriorityLevel = this.props.DatabaseMobx.c_fieldMapOfCapturePriorityLevel;
    const c_userStageViewMinimizedStageIDsArray = this.props.UserMobx.c_userStageViewMinimizedStageIDsArray;
    const c_userPriorityViewMinimizedPriorityIDsArray = this.props.UserMobx.c_userPriorityViewMinimizedPriorityIDsArray;

    var viewMinimizedItemIDsCommaUpeFieldDbName = "stage_view_minimized_stage_ids_comma";
    var userViewMinimizedItemIDsArray = c_userStageViewMinimizedStageIDsArray;
    var stageTimelineStageCustomTitle = c_fieldMapOfStage.get("display_name") + " '" + p_stageViewExpandedStageObj.name + "'";
    if(p_capturesViewFlag === "priority") {
      viewMinimizedItemIDsCommaUpeFieldDbName = "priority_view_minimized_priority_ids_comma";
      userViewMinimizedItemIDsArray = c_userPriorityViewMinimizedPriorityIDsArray;
      stageTimelineStageCustomTitle = c_fieldMapOfCapturePriorityLevel.get("display_name") + " '" + p_stageViewExpandedStageObj.name + "'";
    }

    const stageIsMinimizedTF = JSFUNC.in_array(p_stageViewExpandedStageObj.id, userViewMinimizedItemIDsArray);
    
    const stageBorderHashColor = "#30363c";

    var stageWidth = "16em";
    var marginLeft = "0.4em";
    var marginRight = "0.4em";
    if(stageIsMinimizedTF) {
      stageWidth = "1.4em";
      marginLeft = undefined;
      marginRight = undefined;
    }

    if(p_isFirstStageTF) {
      marginLeft = undefined;
    }

    return(
      <div className="flex00a" style={{flexBasis:stageWidth, marginLeft:marginLeft, marginRight:marginRight}}>
        <StageViewMinimizeMaximizeButton
          p_viewMinimizedItemIDsCommaUpeFieldDbName={viewMinimizedItemIDsCommaUpeFieldDbName}
          p_userViewMinimizedItemIDsArray={userViewMinimizedItemIDsArray}
          p_stageViewExpandedStageObj={p_stageViewExpandedStageObj}
          p_stageIsMinimizedTF={stageIsMinimizedTF}
        />
        <div className="displayFlexColumn overflowHidden" style={{width:stageWidth, height:"4em"}}>
          <OpenCaptureReact.StageTimelineStage
            p_stageObj={p_stageViewExpandedStageObj}
            p_minimizedStageTF={stageIsMinimizedTF}
            p_advanceColorIsStageColorTF={true}
            p_customBorderHashColor={stageBorderHashColor}
            p_customTitle={stageTimelineStageCustomTitle}
          />
        </div>
        {(!stageIsMinimizedTF) &&
          <>
            {c_cstSortedFilteredMaskedCaptureValuesArrayOfObjs.map((m_captureObj) =>
              (JSFUNC.in_array(m_captureObj.id, p_stageViewExpandedStageObj.limitedOrAllStageCaptureIDsArray)) &&
              <StageViewSingleCaptureBlock
                p_captureObj={m_captureObj}
              />
            )}
          </>
        }
        <div style={{height:"2em"}} />
      </div>
    );
  }
}));


const StageViewMinimizeMaximizeButton = inject("DatabaseMobx", "UserMobx")(observer(
class StageViewMinimizeMaximizeButton extends Component { //props: p_viewMinimizedItemIDsCommaUpeFieldDbName, p_userViewMinimizedItemIDsArray, p_stageViewExpandedStageObj, p_stageIsMinimizedTF
  onclick_minimize = () => {
    const p_viewMinimizedItemIDsCommaUpeFieldDbName = this.props.p_viewMinimizedItemIDsCommaUpeFieldDbName;
    const p_userViewMinimizedItemIDsArray = this.props.p_userViewMinimizedItemIDsArray;
    const p_stageViewExpandedStageObj = this.props.p_stageViewExpandedStageObj;

    const updatedMinimizedStageIDsArray = JSFUNC.merge_unique(p_stageViewExpandedStageObj.id, p_userViewMinimizedItemIDsArray);
    const updatedMinimizedStageIDsComma = JSFUNC.convert_array_to_comma_list(updatedMinimizedStageIDsArray);
    this.props.UserMobx.a_update_user_per_email_field(p_viewMinimizedItemIDsCommaUpeFieldDbName, updatedMinimizedStageIDsComma, "s");
  }

  onclick_maximize = () => {
    const p_viewMinimizedItemIDsCommaUpeFieldDbName = this.props.p_viewMinimizedItemIDsCommaUpeFieldDbName;
    const p_userViewMinimizedItemIDsArray = this.props.p_userViewMinimizedItemIDsArray;
    const p_stageViewExpandedStageObj = this.props.p_stageViewExpandedStageObj;

    const updatedMinimizedStageIDsArray = JSFUNC.remove_all_values_from_array(p_stageViewExpandedStageObj.id, p_userViewMinimizedItemIDsArray);
    const updatedMinimizedStageIDsComma = JSFUNC.convert_array_to_comma_list(updatedMinimizedStageIDsArray);
    this.props.UserMobx.a_update_user_per_email_field(p_viewMinimizedItemIDsCommaUpeFieldDbName, updatedMinimizedStageIDsComma, "s");
  }

  render() {
    const p_stageViewExpandedStageObj = this.props.p_stageViewExpandedStageObj;
    const p_stageIsMinimizedTF = this.props.p_stageIsMinimizedTF;

    const c_fieldMapOfStage = this.props.DatabaseMobx.c_fieldMapOfStage;

    return(
      <div className="displayFlexRow" style={{padding:"0.1em 0"}}>
        <div
          className="flex00a cursorPointer"
          title={"Click to " + ((p_stageIsMinimizedTF) ? ("unhide") : ("hide")) + " " + c_fieldMapOfStage.get("display_name") + " '" + p_stageViewExpandedStageObj.name + "'"}
          onClick={((p_stageIsMinimizedTF) ? (this.onclick_maximize) : (this.onclick_minimize))}>
          <div
            className="displayFlexColumnHcVc border bevelBorderDarkColors borderRadius10 bgLightesterGrayGradient hoverBgWhite textCenter"
            style={{width:"1.3em", height:"1.2em"}}>
            <font className="">
              {((p_stageIsMinimizedTF) ? ("+") : ("-"))}
            </font>
          </div>
        </div>
        <div className="flex11a" />
      </div>
    );
  }
}));


function StageViewMinimizedStage(props) { //props: p_stageViewExpandedStageObj, p_customBorderHashColor
  const p_stageViewExpandedStageObj = props.p_stageViewExpandedStageObj;
  const p_customBorderHashColor = props.p_customBorderHashColor;

  return(
    <div className="flex11a displayFlexColumn" style={{margin:"0 0.1em", border:"solid 2px " + p_customBorderHashColor}}>
      <div className="flex11a bgWhite" />
      <div className="flex00a" style={{flexBasis:"0.3em", background:"#" + p_stageViewExpandedStageObj.color}} />
    </div>
  );
}


const StageViewSingleCaptureBlock = inject("OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class StageViewSingleCaptureBlock extends Component { //props: p_captureObj
  onclick_stage_view_capture = () => {
    const p_captureObj = this.props.p_captureObj;
    this.props.OpenCaptureMobx.a_open_single_capture(p_captureObj.id);
  }

  render() {
    const p_captureObj = this.props.p_captureObj;

    const c_companyUsingPwinTF = this.props.DatabaseMobx.c_companyUsingPwinTF;
    const c_fieldMapOfCaptureManagers = this.props.DatabaseMobx.c_fieldMapOfCaptureManagers;
    const c_fieldMapOfDivisionOwners = this.props.DatabaseMobx.c_fieldMapOfDivisionOwners;
    const c_fieldMapOfContractRevenueValue = this.props.DatabaseMobx.c_fieldMapOfContractRevenueValue;
    const c_fieldMapOfTotalShapingProgress = this.props.DatabaseMobx.c_fieldMapOfTotalShapingProgress;
    const c_fieldMapOfPwin = this.props.DatabaseMobx.c_fieldMapOfPwin;
    const c_fieldMapOfRFPDate = this.props.DatabaseMobx.c_fieldMapOfRFPDate;
    const c_fieldMapOfProposalDueDateTimeUtc = this.props.DatabaseMobx.c_fieldMapOfProposalDueDateTimeUtc;
    const c_userStageViewBoxSize = this.props.UserMobx.c_userStageViewBoxSize;

    const captureMapOrUndefined = this.props.DatabaseMobx.o_tbl_captures.get(p_captureObj.id);

    const contractRevenueValueRaw = this.props.DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(captureMapOrUndefined, c_fieldMapOfContractRevenueValue);
    var contractRevenueValueShortPlainText = "--";
    if(contractRevenueValueRaw !== undefined) {
      contractRevenueValueShortPlainText = JSFUNC.money_short(contractRevenueValueRaw, true);
    }
    
    const shapingTotalProgressValueRaw = this.props.DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(captureMapOrUndefined, c_fieldMapOfTotalShapingProgress);
    const shapingTotalProgressValueMask = this.props.DatabaseMobx.progress_bar_html_from_percent_and_size_em(shapingTotalProgressValueRaw, 1.3);
    const shapingTotalProgressValueMaskPlainText = this.props.DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(shapingTotalProgressValueRaw, c_fieldMapOfPwin.get("fieldTypeObj"));

    const pwinValueRaw = this.props.DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(captureMapOrUndefined, c_fieldMapOfPwin);
    const pwinValueMask = this.props.DatabaseMobx.pwin_cube_svg_html_from_percent_and_size_em(pwinValueRaw, 2);
    const pwinValueMaskPlainText = this.props.DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(pwinValueRaw, c_fieldMapOfPwin.get("fieldTypeObj"));

    const clickToOpenCaptureTitle = "[Click to open Capture '" + p_captureObj.captureFullName + "']";

    var boxHeightEm = 10;
    var marginTopEm = 0.4;
    if(c_userStageViewBoxSize === 1) {
      boxHeightEm = 5.5;
      marginTopEm = 0.3;
    }
    else if(c_userStageViewBoxSize === 2) {
      boxHeightEm = 8.3;
      marginTopEm = 0.5;
    }
    else if(c_userStageViewBoxSize === 3) {
      boxHeightEm = 11.2;
      marginTopEm = 0.7;
    }
    
    var captureManagersComponent = null;
    var divisionOwnersComponent = null;
    if(c_userStageViewBoxSize >= 2) {
      const captureManagersValueMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(captureMapOrUndefined, c_fieldMapOfCaptureManagers);
      captureManagersComponent = (
        <>
          <div className="" style={{border:"solid 1px #ddd", margin:"0.3em 5% 0 5%"}} />
          <div className="displayFlexRowVc lrPad" style={{marginTop:"0.2em"}} title={c_fieldMapOfCaptureManagers.get("display_name") + ": " + captureManagersValueMaskSortIfoObj.valueMaskPlainText + "\n" + clickToOpenCaptureTitle}>
            <LibraryReact.Nowrap p_fontClass="font09">
              {captureManagersValueMaskSortIfoObj.valueMask}
            </LibraryReact.Nowrap>
          </div>
        </>
      );
      
      const divisionOwnersValueMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(captureMapOrUndefined, c_fieldMapOfDivisionOwners);
      divisionOwnersComponent = (
        <div className="displayFlexRowVc lrPad" style={{marginTop:"0.2em"}} title={c_fieldMapOfDivisionOwners.get("display_name") + ": " + divisionOwnersValueMaskSortIfoObj.valueMaskPlainText + "\n" + clickToOpenCaptureTitle}>
          <LibraryReact.Nowrap p_fontClass="font09">
            {divisionOwnersValueMaskSortIfoObj.valueMask}
          </LibraryReact.Nowrap>
        </div>
      );
    }

    var lbTeammatesComponent = null;
    var sbTeammatesComponent = null;
    if(c_userStageViewBoxSize === 3) {
      const lbTeammateNamesAndAllocsComma = JSPHP.get_team_codeword_replacement_plaintext_from_capture_map("@team_all_selected_teammates_list_names_and_allocation_sort_lb_allocation", captureMapOrUndefined);
      lbTeammatesComponent = (
        <>
          <div className="" style={{border:"solid 1px #ddd", margin:"0.3em 5% 0 5%"}} />
          <div className="displayFlexRow" style={{marginTop:"0.2em"}}>
            <div className="flex00a displayFlexRowVc" style={{flexBasis:"1.4em", marginLeft:"0.4em"}}>
              <font className="font09 fontBold fontItalic" style={{color:"#98a"}}>
                {"LB:"}
              </font>
            </div>
            <div className="flex11a displayFlexRowVc lrPad" style={{flexBasis:"100em"}} title={"LB Teammates: " + lbTeammateNamesAndAllocsComma + "\n" + clickToOpenCaptureTitle}>
              <LibraryReact.Nowrap p_fontClass="font09">
                {lbTeammateNamesAndAllocsComma}
              </LibraryReact.Nowrap>
            </div>
          </div>
        </>
      );
      
      const sbTeammateNamesAndAllocsComma = JSPHP.get_team_codeword_replacement_plaintext_from_capture_map("@team_all_selected_teammates_list_names_and_allocation_sort_sb_allocation", captureMapOrUndefined);
      sbTeammatesComponent = (
        <div className="displayFlexRow" style={{marginTop:"0.2em"}}>
          <div className="flex00a displayFlexRowVc" style={{flexBasis:"1.4em", marginLeft:"0.4em"}}>
          <font className="font09 fontBold fontItalic" style={{color:"#8a9"}}>
              {"SB:"}
            </font>
          </div>
          <div className="flex11a displayFlexRowVc lrPad" style={{flexBasis:"100em"}} title={"SB Teammates: " + sbTeammateNamesAndAllocsComma + "\n" + clickToOpenCaptureTitle}>
            <LibraryReact.Nowrap p_fontClass="font09">
              {sbTeammateNamesAndAllocsComma}
            </LibraryReact.Nowrap>
          </div>
        </div>
      );
    }

    return(
      <div
        className="border1bbb bgWhite hoverLighterBlueGradient cursorPointer"
        style={{height:boxHeightEm + "em", marginTop:marginTopEm + "em", boxShadow:"0.1em 0.1em 0.4em 0.05em #333"}}
        onClick={this.onclick_stage_view_capture}>
        <div className="bgRedCapture tbMicroPad lrPad" title={p_captureObj.captureFullName}>
          <LibraryReact.Nowrap p_fontClass="fontWhite">
            {p_captureObj.captureFullName}
          </LibraryReact.Nowrap>
        </div>
        <div className="displayFlexRow lrPad overflowHidden" style={{height:"2.5em"}}>
          <div className="flex11a displayFlexColumnHcVc textCenter" style={{flexBasis:"100em"}} title={c_fieldMapOfContractRevenueValue.get("display_name") + ": " + contractRevenueValueShortPlainText + "\n" + clickToOpenCaptureTitle}>
            <LibraryReact.Nowrap p_fontClass="fontBold fontBlue">
              {contractRevenueValueShortPlainText}
            </LibraryReact.Nowrap>
          </div>
          <div className="flex00a" style={{flexBasis:"0.8em"}} />
          <div className={"flex11a displayFlexColumnVc " + ((c_companyUsingPwinTF) ? ("") : ("lrPad"))} style={{flexBasis:"100em"}} title={c_fieldMapOfTotalShapingProgress.get("display_name") + ": " + shapingTotalProgressValueMaskPlainText + "\n" + clickToOpenCaptureTitle}>
            {shapingTotalProgressValueMask}
          </div>
          {(c_companyUsingPwinTF) &&
            <>
              <div className="flex00a" style={{flexBasis:"0.8em"}} />
              <div className="flex11a displayFlexColumnHcVc" style={{flexBasis:"100em"}} title={c_fieldMapOfPwin.get("display_name") + ": " + pwinValueMaskPlainText + "\n" + clickToOpenCaptureTitle}>
                {pwinValueMask}
              </div>
            </>
          }
        </div>
        <div className="displayFlexRow">
          <StageViewBlockDate
            p_fieldMap={c_fieldMapOfRFPDate}
            p_captureMap={captureMapOrUndefined}
            p_fieldShortDisplayName="R"
            p_clickToOpenCaptureTitle={clickToOpenCaptureTitle}
          />
          <StageViewBlockDate
            p_fieldMap={c_fieldMapOfProposalDueDateTimeUtc}
            p_captureMap={captureMapOrUndefined}
            p_fieldShortDisplayName="P"
            p_clickToOpenCaptureTitle={clickToOpenCaptureTitle}
          />
        </div>
        {captureManagersComponent}
        {divisionOwnersComponent}
        {lbTeammatesComponent}
        {sbTeammatesComponent}
      </div>
    );
  }
}));


const StageViewBlockDate = inject("DatabaseMobx")(observer(
class StageViewBlockDate extends Component { //props: p_fieldMap, p_captureMap, p_fieldShortDisplayName, p_clickToOpenCaptureTitle
  render() {
    const p_fieldMap = this.props.p_fieldMap;
    const p_captureMap = this.props.p_captureMap;
    const p_fieldShortDisplayName = this.props.p_fieldShortDisplayName;
    const p_clickToOpenCaptureTitle = this.props.p_clickToOpenCaptureTitle;

    const c_genericDateFieldTypeObj = this.props.DatabaseMobx.c_genericDateFieldTypeObj;

    const fieldDisplayName = p_fieldMap.get("display_name");
    const captureValueRaw = this.props.DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(p_captureMap, p_fieldMap);
    var captureValueRawDate = undefined;
    if(JSFUNC.is_string(captureValueRaw)) {
      captureValueRawDate = captureValueRaw.substring(0, 10);
    }
    const valueMask = this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(captureValueRawDate, c_genericDateFieldTypeObj);
    const fullValueMaskPlainText = this.props.DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(captureValueRaw, p_fieldMap.get("fieldTypeObj"));

    return(
      <>
        <div className="flex00a displayFlexRowVc" style={{flexBasis:"1.3em", marginLeft:"0.4em"}}>
          <font className="font09 fontBold fontItalic fontTextLightest">
            {p_fieldShortDisplayName + ":"}
          </font>
        </div>
        <div
          className="flex11a displayFlexRowVc"
          style={{flexBasis:"100em", marginRight:"0.4em"}}
          title={fieldDisplayName + ": " + fullValueMaskPlainText + "\n" + p_clickToOpenCaptureTitle}>
          <LibraryReact.Nowrap p_fontClass="font09">
            {valueMask}
          </LibraryReact.Nowrap>
        </div>
      </>
    );
  }
}));



//======================================================================================================================================
//======================================================================================================================================
//Progress/Gantt Timeline Chart View
//======================================================================================================================================
//======================================================================================================================================
const TimelineChart = inject("CaptureExecMobx", "CapturesMobx", "UserMobx")(observer(
class TimelineChart extends Component { //props: p_ganttTrueProgressFalse
  render() {
    const p_ganttTrueProgressFalse = JSFUNC.prop_value(this.props.p_ganttTrueProgressFalse, true);
    
    const c_isMobileTF = this.props.CaptureExecMobx.c_isMobileTF;
    const c_isTabletTF = this.props.CaptureExecMobx.c_isTabletTF;
    const o_viewIsLoadingTF = this.props.CapturesMobx.o_viewIsLoadingTF;
    const c_ganttHasValidPresetSelectedTF = this.props.CapturesMobx.c_ganttHasValidPresetSelectedTF;
    const c_timelineChartTimeSpanObj = this.props.CapturesMobx.c_timelineChartTimeSpanObj;
    const c_combinedUserObj = this.props.UserMobx.c_combinedUserObj;

    //chart user row height
    var chartUserRowHeightEm = 2;
    if(p_ganttTrueProgressFalse) {
      chartUserRowHeightEm = c_combinedUserObj.capture_chart_gantt_size;
    }
    else {
      chartUserRowHeightEm = c_combinedUserObj.capture_chart_progress_size;
    }

    if(chartUserRowHeightEm <= 1) {
      chartUserRowHeightEm = 1.2;
    }

    //width of the name of the capture column on left side of chart
    var captureNameContainerWidth = "12%";
    if(c_isMobileTF) { captureNameContainerWidth = "20%"; }
    else if(c_isTabletTF) { captureNameContainerWidth = "15%"; }

    //data table or empty screen if there is loading or invalid start/end dates
    var chartCaptureRowsComponent = null;
    if(o_viewIsLoadingTF) {
      const c_numFilteredCaptures = this.props.CapturesMobx.c_numFilteredCaptures;
      chartCaptureRowsComponent = (
        <CEGeneralReact.EmptyScreenGray>
          {"Loading Progress History for " + c_numFilteredCaptures + " " + JSFUNC.plural(c_numFilteredCaptures, "Capture", "Captures") + "..."}
        </CEGeneralReact.EmptyScreenGray>
      );
    }
    else if(!c_timelineChartTimeSpanObj.startDateIsValidTF || !c_timelineChartTimeSpanObj.endDateIsValidTF) {
      chartCaptureRowsComponent = (
        <CEGeneralReact.EmptyScreenGray>
          {"Start/End dates must be set for this chart to display"}
        </CEGeneralReact.EmptyScreenGray>
      );
    }
    else if(p_ganttTrueProgressFalse && !c_ganttHasValidPresetSelectedTF) {
      chartCaptureRowsComponent = (
        <CEGeneralReact.EmptyScreenGray>
          {"Select or create a Gantt Dates Preset to view a Gantt Chart"}
        </CEGeneralReact.EmptyScreenGray>
      );
    }
    else {
      chartCaptureRowsComponent = (
        <TimelineChartCaptureRows p_ganttTrueProgressFalse={p_ganttTrueProgressFalse} p_chartUserRowHeightEm={chartUserRowHeightEm} p_captureNameContainerWidth={captureNameContainerWidth} />
      );
    }

    return(
      <div className="flex11a displayFlexColumn">
        <TimelineControlsAndLegends p_ganttTrueProgressFalse={p_ganttTrueProgressFalse} />
        <MonthLabels p_captureNameContainerWidth={captureNameContainerWidth} />
        {chartCaptureRowsComponent}
      </div>
    );
  }
}));

const TimelineControlsAndLegends = inject("CaptureExecMobx", "CapturesMobx")(observer(
class TimelineControlsAndLegends extends Component { //props: p_ganttTrueProgressFalse
  constructor(props) {
    super(props);
    this.state = {
      s_mobileScreenNum: 1 //1-timeline, 2-gantt dates/progress legends
    }
  }

  onclick_advance_mobile_screen_up = () => {
    const s_mobileScreenNum = this.state.s_mobileScreenNum;
    const updatedMobileScreenNum = ((s_mobileScreenNum <= 1) ? (2) : (s_mobileScreenNum - 1));
    this.setState({s_mobileScreenNum:updatedMobileScreenNum});
  }

  onclick_advance_mobile_screen_down = () => {
    const s_mobileScreenNum = this.state.s_mobileScreenNum;
    const updatedMobileScreenNum = ((s_mobileScreenNum >= 2) ? (1) : (s_mobileScreenNum + 1));
    this.setState({s_mobileScreenNum:updatedMobileScreenNum});
  }

  render() {
    const s_mobileScreenNum = this.state.s_mobileScreenNum;

    const p_ganttTrueProgressFalse = this.props.p_ganttTrueProgressFalse;

    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;

    if(c_isMobileOrTabletTF) {
      var currentScreenComponent = null;
      if(s_mobileScreenNum === 1) {
        currentScreenComponent = (<TimelineControlsDates />);
      }
      else if(p_ganttTrueProgressFalse && (s_mobileScreenNum === 2)) {
        currentScreenComponent = (<GanttPresetSelectionAndDatesLegend />);
      }
      else if(!p_ganttTrueProgressFalse && (s_mobileScreenNum === 2)) {
        currentScreenComponent = (<ProgressChartLegends />);
      }

      return(
        <div className="flex00a displayFlexRow" style={{height:"5.9em", background:"#f5f5f5", borderBottom:"solid 1px #ddd"}}>
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"3em", backgroundColor:"#ddd", borderRight:"solid 1px #aaa"}}>
            <div className="cursorPointer" onClick={this.onclick_advance_mobile_screen_up}>
              <font className="font14 fontTextLighter">{"\u25B2"}</font>
            </div>
            <div className="microTopPad microBottomPad">
              <font className="fontItalic">{s_mobileScreenNum}</font>
            </div>
            <div className="cursorPointer" onClick={this.onclick_advance_mobile_screen_down}>
              <font className="font14 fontTextLighter">{"\u25BC"}</font>
            </div>
          </div>
          <div className="flex11a displayFlexRow">
            {currentScreenComponent}
          </div>
        </div>
      );
    }

    return(
      <div className="flex00a displayFlexRow borderB1ddd smallFullPad" style={{height:"5.9em", background:"#f5f5f5"}}>
        {(p_ganttTrueProgressFalse) ? (
          <GanttPresetSelectionAndDatesLegend />
        ) : (
          <ProgressChartLegends />
        )}
        <div className="flex11a" style={{borderRight:"solid 1px #aaa"}} />
        <TimelineControlsDates />
      </div>
    );
  }
}));


const ProgressChartLegends = inject("DatabaseMobx")(observer(
class ProgressChartLegends extends Component { //props:
  render() {
    const c_fieldMapOfStage = this.props.DatabaseMobx.c_fieldMapOfStage;
    const c_companyUsingPwinTF = this.props.DatabaseMobx.c_companyUsingPwinTF;

    const nameColorDataArray = [this.props.DatabaseMobx.c_shapingProgressColorsArrayOfObjs, this.props.DatabaseMobx.c_pwinColorsArrayOfObjs, this.props.DatabaseMobx.c_allStagesArrayOfObjs];
    const legendTitlesArray = ["Progress Legend", "PWin Legend", c_fieldMapOfStage.get("display_name") + " Legend"];
    const legendEmptyLabelsArray = ["Admin has not defined progress colors yet", "Admin has not defined PWin colors yet", "Admin has not defined any stages yet"];
    const legendWidthsArray = ["11em", "11em", "16em"];

    //do not include PWin legend if company is not using PWin
    const legendIndicesArray = ((c_companyUsingPwinTF) ? ([0, 1, 2]) : ([0, 2]));

    return(
      legendIndicesArray.map((m_legendIndex) =>
        <TimelineChartLegend
          key={legendTitlesArray[m_legendIndex]}
          p_nameColorDataArrayOfObjs={nameColorDataArray[m_legendIndex]}
          p_itemNameFieldName="name"
          p_label={legendTitlesArray[m_legendIndex]}
          p_emptyLabel={legendEmptyLabelsArray[m_legendIndex]}
          p_width={legendWidthsArray[m_legendIndex]}
        />
      )
    );
  }
}));

const GanttPresetSelectionAndDatesLegend = inject("CapturesMobx", "UserMobx")(observer(
class GanttPresetSelectionAndDatesLegend extends Component { //props:
  onclick_gantt_preset_editor_button = () => {
    this.props.CapturesMobx.a_set_gantt_dates_preset_editor_open_tf(true);
  }

  onselect_gantt_dates_preset = (i_selectedGanttDatesPresetID) => {
    this.props.CapturesMobx.a_select_gantt_dates_preset(i_selectedGanttDatesPresetID);
  }

  render() {
    const c_selectGanttPresetsFieldTypeObj = this.props.CapturesMobx.c_selectGanttPresetsFieldTypeObj;
    const c_ganttSelectedPresetObj = this.props.CapturesMobx.c_ganttSelectedPresetObj;
    const c_combinedUserObj = this.props.UserMobx.c_combinedUserObj;

    return(
      <>
        <div className="flex00a displayFlexColumnHcVc lrMedPad" style={{borderRight:"solid 1px #aaa"}}>
          <div className="displayFlexRowHcVc">
            <div className="flex00a" style={{flexBasis:"3.5em"}}>
              <ButtonCreateEditFilters
                p_sizeEm={2.5}
                p_title="Create/Edit Gantt Date Presets"
                f_onClick={this.onclick_gantt_preset_editor_button}
              />
            </div>
            <div className="flex00a" style={{flexBasis:"8em"}}>
              <font className="fontBold fontTextLighter">
                {"Selected Gantt Dates Preset:"}
              </font>
            </div>
          </div>
          <div className="smallTopMargin" style={{width:"17em"}}>
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={c_selectGanttPresetsFieldTypeObj}
              p_valueRaw={c_combinedUserObj.gantt_date_preset_id_selected}
              f_onChangeOrOnSelect={this.onselect_gantt_dates_preset}
            />
          </div>
        </div>
        <TimelineChartLegend
          p_nameColorDataArrayOfObjs={((c_ganttSelectedPresetObj !== undefined) ? (c_ganttSelectedPresetObj.ganttDatesArrayOfObjs) : (undefined))}
          p_itemNameFieldName="fieldDisplayName"
          p_label="Gantt Dates Legend"
          p_emptyLabel={(c_combinedUserObj.gantt_date_preset_id_selected > 0) ? ("Preset has no dates added yet") : ("No preset selected")}
          p_width="18em"
        />
      </>
    );
  }
}));

function TimelineChartLegend(props) { //props: p_nameColorDataArrayOfObjs, p_itemNameFieldName, p_label, p_emptyLabel, p_width
  const p_nameColorDataArrayOfObjs = props.p_nameColorDataArrayOfObjs;
  const p_itemNameFieldName = props.p_itemNameFieldName;
  const p_label = props.p_label;
  const p_emptyLabel = props.p_emptyLabel;
  const p_width = props.p_width;

  var nameColorDataArrayOfObjsHasAtLeast1ColorTF = false;
  if(JSFUNC.is_array(p_nameColorDataArrayOfObjs)) {
    nameColorDataArrayOfObjsHasAtLeast1ColorTF = (p_nameColorDataArrayOfObjs.length > 0);
  }

  return(
    <div className="flex00a yScroll" style={{flexBasis:p_width, borderRight:"solid 1px #aaa", paddingRight:"0.4em", backgroundColor:"#f8f8f8"}}>
      <div className="textCenter microBottomMargin">
        <font className="fontBold fontTextLight">
          {p_label}
        </font>
      </div>
      {(!nameColorDataArrayOfObjsHasAtLeast1ColorTF) ? (
        <div className="lrMargin">
          <font className="fontItalic fontTextLighter">
            {p_emptyLabel}
          </font>
        </div>
      ) : (
        p_nameColorDataArrayOfObjs.map((m_nameColorObj) =>
          <div className="displayFlexRowVc" style={{height:"1.1em"}}>
            <div className="flex00a lrMargin border bevelBorderColors" style={{flexBasis:"1.2em", height:"0.9em", background:"#" + m_nameColorObj.color}} />
            <div className="flex11a">
              <LibraryReact.Nowrap p_fontClass="fontItalic">
                {m_nameColorObj[p_itemNameFieldName]}
              </LibraryReact.Nowrap>
            </div>
          </div>
        )
      )}
    </div>
  );
}

const TimelineControlsDates = inject("CapturesMobx", "DatabaseMobx", "UserMobx")(observer(
class TimelineControlsDates extends Component { //props:
  onchange_start_date = (i_newStartDate) => {
    const oldEndDate = this.props.CapturesMobx.c_timelineChartTimeSpanObj.endDate;
    var newEndDate = undefined; //undefined if the end date does not need to be updated
    if(i_newStartDate > oldEndDate) { //if the start date was set to a date further out than the end date, move the end date 1 month past this new start date
      newEndDate = JSFUNC.date_add_months(i_newStartDate, 1);
    }
    this.props.CapturesMobx.a_timeline_chart_update_start_end_dates(i_newStartDate, newEndDate);
    this.set_zoom_cool_down_timer();
  }

  onchange_end_date = (i_newEndDate) => {
    const oldStartDate = this.props.CapturesMobx.c_timelineChartTimeSpanObj.startDate;
    var newStartDate = undefined; //undefined if the start date does not need to be updated
    if(i_newEndDate < oldStartDate) { //if the end date was set to a date earlier than the start date, move the start date 1 month before this new end date
      newStartDate = JSFUNC.date_add_months(i_newEndDate, -1);
    }
    this.props.CapturesMobx.a_timeline_chart_update_start_end_dates(newStartDate, i_newEndDate);
    this.set_zoom_cool_down_timer();
  }

  onclick_zoom_out = () => {
    this.props.CapturesMobx.a_timeline_zoom_in_or_out("out");
    this.set_zoom_cool_down_timer();
  }

  onclick_zoom_in = () => {
    this.props.CapturesMobx.a_timeline_zoom_in_or_out("in");
    this.set_zoom_cool_down_timer();
  }

  onclick_pan_left = () => {
    this.props.CapturesMobx.a_timeline_zoom_in_or_out("left");
    this.set_zoom_cool_down_timer();
  }

  onclick_pan_right = () => {
    this.props.CapturesMobx.a_timeline_zoom_in_or_out("right");
    this.set_zoom_cool_down_timer();
  }

  set_zoom_cool_down_timer = () => {
    clearTimeout(this.chartZoomCoolDownTimerID);
    this.chartZoomCoolDownTimerID = setTimeout(this.clear_zoom_cool_down_timer, (5000));
    this.props.CapturesMobx.a_timeline_set_zoom_cool_down_is_running_tf(true);
  }

  clear_zoom_cool_down_timer = () => {
    clearTimeout(this.chartZoomCoolDownTimerID);
    this.props.CapturesMobx.a_timeline_set_zoom_cool_down_is_running_tf(false);
  }

  render() {
    const c_timelineChartTimeSpanObj = this.props.CapturesMobx.c_timelineChartTimeSpanObj;
    const startDate = this.props.UserMobx.c_combinedUserObj.capture_chart_start_date;
    const endDate = this.props.UserMobx.c_combinedUserObj.capture_chart_end_date;

    const totalNumDays = c_timelineChartTimeSpanObj.totalNumDays;
    const canZoomOutTF = (totalNumDays < (365 * 100));
    const canZoomInTF = (totalNumDays > 1);

    return([
      <div key="zoomPan" className="flex00a displayFlexRowVc lrMedPad">
        <div className="displayFlexColumnHcVc">
          <div className="displayFlexRowVc">
            <div className="flex00a rMargin">
              <font className="fontBold fontItalic fontTextLight">
                {"Zoom:"}
              </font>
            </div>
            <div className="flex00a displayFlexColumnHcVc hoverLightestGrayGradient cursorPointer" style={{height:"2.7em", width:"2.7em", borderRadius:"2em"}} onClick={((canZoomOutTF) ? (this.onclick_zoom_out) : (undefined))}>
              <svg width="2em" height="2em">
                <circle cx="50%" cy="50%" r="45%" fill="transparent" style={{strokeWidth:"0.07em", stroke:((canZoomOutTF) ? ("#666") : ("#ccc"))}} />
                <LibraryReact.SvgHLine p_x1p={20} p_x2p={80} p_ycp={50} p_widthP={7} p_color={(canZoomOutTF) ? ("#666") : ("#ccc")} />
              </svg>
            </div>
            <div className="flex00a displayFlexColumnHcVc hoverLightestGrayGradient cursorPointer" style={{height:"2.7em", width:"2.7em", borderRadius:"2em"}} onClick={((canZoomInTF) ? (this.onclick_zoom_in) : (undefined))}>
              <svg width="2em" height="2em">
                <circle cx="50%" cy="50%" r="45%" fill="transparent" style={{strokeWidth:"0.07em", stroke:((canZoomInTF) ? ("#666") : ("#ccc"))}} />
                <LibraryReact.SvgHLine p_x1p={20} p_x2p={80} p_ycp={50} p_widthP={7} p_color={(canZoomOutTF) ? ("#666") : ("#ccc")} />
                <LibraryReact.SvgVLine p_y1p={20} p_y2p={80} p_xcp={50} p_widthP={7} p_color={(canZoomOutTF) ? ("#666") : ("#ccc")} />
              </svg>
            </div>
          </div>
          <div className="displayFlexRowVc">
            <div className="flex00a rMargin">
              <font className="fontBold fontItalic fontTextLight">
                {"Pan:"}
              </font>
            </div>
            <div className="flex00a displayFlexColumnHcVc hoverLightestGrayGradient cursorPointer" style={{height:"2.2em", width:"2.9em", borderRadius:"0.2em"}} onClick={this.onclick_pan_left}>
              <svg width="2.2em" height="1.5em">
                <rect x="5%" y="5%" rx="10%" ry="10%" width="90%" height="90%" fill="transparent" style={{strokeWidth:"0.07em", stroke:"#666"}} />
                <line x1="40%" x2="20%" y1="20%" y2="50%" style={{strokeWidth:"0.12em", stroke:"#666"}} />
                <line x1="20%" x2="40%" y1="50%" y2="80%" style={{strokeWidth:"0.12em", stroke:"#666"}} />
                <line x1="70%" x2="50%" y1="20%" y2="50%" style={{strokeWidth:"0.12em", stroke:"#666"}} />
                <line x1="50%" x2="70%" y1="50%" y2="80%" style={{strokeWidth:"0.12em", stroke:"#666"}} />
              </svg>
            </div>
            <div className="flex00a displayFlexColumnHcVc hoverLightestGrayGradient cursorPointer" style={{height:"2.2em", width:"2.9em", borderRadius:"0.2em"}} onClick={this.onclick_pan_right}>
              <svg width="2.2em" height="1.5em">
                <rect x="5%" y="5%" rx="10%" ry="10%" width="90%" height="90%" fill="transparent" style={{strokeWidth:"0.07em", stroke:"#666"}} />
                <line x1="30%" x2="50%" y1="20%" y2="50%" style={{strokeWidth:"0.12em", stroke:"#666"}} />
                <line x1="50%" x2="30%" y1="50%" y2="80%" style={{strokeWidth:"0.12em", stroke:"#666"}} />
                <line x1="60%" x2="80%" y1="20%" y2="50%" style={{strokeWidth:"0.12em", stroke:"#666"}} />
                <line x1="80%" x2="60%" y1="50%" y2="80%" style={{strokeWidth:"0.12em", stroke:"#666"}} />
              </svg>
            </div>
          </div>
        </div>
      </div>,
      <div key="startEnd" className="flex00a displayFlexRowVc lrMedPad" style={{flexBasis:"25em", borderRight:"solid 1px #aaa"}}>
        <div className="flex11a">
          <div className="displayFlexColumnVc" style={{height:"2.3em"}}>
            <CEGeneralReact.CaptureExecFieldEditSaveCancel
              p_ceEditItemString="timelineProgressStartDate"
              p_fieldDisplayName="Start Date:"
              p_fieldTypeObj={this.props.DatabaseMobx.c_genericDateFieldTypeObj}
              p_valueRaw={startDate}
              p_valueIsEditableTFU={true}
              p_fieldClass="fontBold fontItalic fontTextLight"
              p_fieldWidth="5.5em"
              p_fieldNowrapTF={true}
              p_fieldValueVerticallyAlignedTF={true}
              f_onSaveChanged={this.onchange_start_date}
            />
          </div>
          <div className="displayFlexColumnVc" style={{height:"2.3em"}}>
            <CEGeneralReact.CaptureExecFieldEditSaveCancel
              p_ceEditItemString="timelineProgressEndDate"
              p_fieldDisplayName="End Date:"
              p_fieldTypeObj={this.props.DatabaseMobx.c_genericDateFieldTypeObj}
              p_valueRaw={endDate}
              p_valueIsEditableTFU={true}
              p_fieldClass="fontBold fontItalic fontTextLight"
              p_fieldWidth="5.5em"
              p_fieldNowrapTF={true}
              p_fieldValueVerticallyAlignedTF={true}
              f_onSaveChanged={this.onchange_end_date}
            />
          </div>
        </div>
      </div>
    ]);
  }
}));


const MonthLabels = inject("CapturesMobx")(observer(
class MonthLabels extends Component { //props: p_captureNameContainerWidth
  render() {
    const p_captureNameContainerWidth = this.props.p_captureNameContainerWidth;

    const c_timelineChartTimeSpanObj = this.props.CapturesMobx.c_timelineChartTimeSpanObj;

    const startDateIsValidTF = c_timelineChartTimeSpanObj.startDateIsValidTF;
    const endDateIsValidTF = c_timelineChartTimeSpanObj.endDateIsValidTF;
    const showDaysTF = c_timelineChartTimeSpanObj.showDaysTF;
    const monthLinesArrayOfObjs = c_timelineChartTimeSpanObj.monthLinesArrayOfObjs;

    if(!startDateIsValidTF || !endDateIsValidTF) {
      return(null);
    }

    return(
      <div className="flex00a displayFlexRow yScrollAlways borderB1ddd" style={{flexBasis:"2.3em"}}>
        <div className="flex00a borderR1ddd" style={{flexBasis:p_captureNameContainerWidth}} />
        <div className="flex11a overflowHidden bgWhite">
          <svg width="100%" height="100%">
            {monthLinesArrayOfObjs.map((m_monthObj) =>
              <>
                <LibraryReact.SvgVLine p_y1p={0} p_y2p={100} p_xcp={m_monthObj.monthPos} p_widthEm={0.1} p_color="#ccc" />
                <SingleMonthLabel p_monthObj={m_monthObj} />
                {(!showDaysTF && m_monthObj.monthIndex0to11 === 0) &&
                  <text x={(m_monthObj.monthPos + 0.1) + "%"} y="90%" fill="#333">
                    {m_monthObj.shortYear}
                  </text>
                }
                {(showDaysTF) &&
                  m_monthObj.datesArrayOfObjs.map((m_dateObj) => [
                    <LibraryReact.SvgVLine p_y1p={50} p_y2p={100} p_xcp={m_dateObj.datePos} p_widthEm={0.09} p_color="#ccc" />,
                    <text x={(m_dateObj.datePos + 0.1) + "%"} y="100%" fill="#444">{m_dateObj.date}</text>,
                  ])
                }
              </>
            )}
            <SvgTodayLine p_in100SvgTF={false} />
          </svg>
        </div>
      </div>
    );
  }
}));

const SingleMonthLabel = inject("CapturesMobx")(observer(
class SingleMonthLabel extends Component { //props: p_monthObj
  onclick_month_label = () => {
    const p_monthObj = this.props.p_monthObj;
    this.props.CapturesMobx.a_timeline_zoom_to_single_month(p_monthObj.year, p_monthObj.monthIndex0to11);
  }

  render() {
    const p_monthObj = this.props.p_monthObj;

    return(
      <text x={(p_monthObj.monthPos + 0.1) + "%"} y="45%" fill="#333" className="cursorPointer" onClick={this.onclick_month_label}>
        {p_monthObj.monthName}
      </text>
    );
  }
}));


const TimelineChartCaptureRows = inject("CaptureExecMobx", "CapturesMobx")(observer(
class TimelineChartCaptureRows extends Component { //props: p_ganttTrueProgressFalse, p_chartUserRowHeightEm, p_captureNameContainerWidth
  constructor(props) {
    super(props);
    this.state = {
      s_capureIDHover: -1
    }
  }

  onhover_capture_name = (i_captureID) => {
    this.setState({s_capureIDHover:i_captureID});
  }

  onclick_show_all_captures = () => {
    const c_selectedLeftNavIsProgressChartViewTF = this.props.CaptureExecMobx.c_selectedLeftNavIsProgressChartViewTF;

    const maxTrueResetFalse = true;
    this.props.CapturesMobx.a_set_cst_or_chart_max_num_captures_drawn(maxTrueResetFalse, c_selectedLeftNavIsProgressChartViewTF);
  }

  render() {
    const p_ganttTrueProgressFalse = this.props.p_ganttTrueProgressFalse;
    const p_chartUserRowHeightEm = this.props.p_chartUserRowHeightEm;
    const p_captureNameContainerWidth = this.props.p_captureNameContainerWidth;

    const o_cstOrChartMaxNumCapturesDrawn = this.props.CapturesMobx.o_cstOrChartMaxNumCapturesDrawn;
    const c_ganttOrProgressChartSortedFilteredCapturesArrayOfObjs = this.props.CapturesMobx.c_ganttOrProgressChartSortedFilteredCapturesArrayOfObjs;
    const c_numFilteredCaptures = this.props.CapturesMobx.c_numFilteredCaptures;

    const borderBottom = ((p_ganttTrueProgressFalse) ? ("solid 1px #ddd") : ("solid 2px #bbb"));

    return(
      <div className="flex11a yScrollAlways yScrollBottomPad">
        {c_ganttOrProgressChartSortedFilteredCapturesArrayOfObjs.map((m_captureObj, m_index) =>
          (m_index < o_cstOrChartMaxNumCapturesDrawn) &&
          <div
            key={m_captureObj.id}
            className="displayFlexRow bgWhite hoverLighterBlueGradient"
            style={{height:p_chartUserRowHeightEm + "em", borderBottom:borderBottom}}>
            <TimelineChartCaptureName
              p_captureObj={m_captureObj}
              p_chartUserRowHeightEm={p_chartUserRowHeightEm}
              p_captureNameContainerWidth={p_captureNameContainerWidth}
              f_onHover={this.onhover_capture_name}
            />
            <TimelineChartDataRow
              p_ganttTrueProgressFalse={p_ganttTrueProgressFalse}
              p_captureObj={m_captureObj}
              p_chartUserRowHeightEm={p_chartUserRowHeightEm}
              p_isHoverTF={(this.state.s_capureIDHover === m_captureObj.id)}
              f_onHover={this.onhover_capture_name}
            />
          </div>
        )}
        <ShowAllCapturesButtonAndMessage
          p_numFilteredCaptures={c_numFilteredCaptures}
          p_maxNumCapturesDrawn={o_cstOrChartMaxNumCapturesDrawn}
          f_onClickShowAllCaptures={this.onclick_show_all_captures}
        />
      </div>
    );
  }
}));

const TimelineChartCaptureName = inject("CaptureExecMobx", "CapturesMobx", "OpenCaptureMobx", "UserMobx")(observer(
class TimelineChartCaptureName extends Component { //props: p_captureObj, p_chartUserRowHeightEm, p_captureNameContainerWidth, f_onHover
  onhover_capture_name = (i_hoverTF) => {
    if(this.props.f_onHover) {
      this.props.f_onHover((i_hoverTF) ? (this.props.p_captureObj.id) : (-1));
    }
  }

  onclick_capture_name = () => {
    this.props.OpenCaptureMobx.a_open_single_capture(this.props.p_captureObj.id);
  }

  render() {
    const p_captureObj = this.props.p_captureObj;
    const p_chartUserRowHeightEm = this.props.p_chartUserRowHeightEm;
    const p_captureNameContainerWidth = this.props.p_captureNameContainerWidth;

    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;

    const captureNameTitle = p_captureObj.captureFullName + "\n[Click to open this Capture]";

    var captureNameComponent = null;
    if(p_chartUserRowHeightEm < 2.5) {
      captureNameComponent = (
        <LibraryReact.Nowrap p_fontClass="fontWhite">
          {p_captureObj.captureFullName}
        </LibraryReact.Nowrap>
      );
    }
    else {
      captureNameComponent = (
        <LibraryReact.MaxHeightWrap p_maxHeight={(p_chartUserRowHeightEm - 0.5) + "em"} p_fontClass="fontWhite">
          {p_captureObj.captureFullName}
        </LibraryReact.MaxHeightWrap>
      );
    }

    return(
      <LibraryReact.InteractiveDiv
        p_class={"flex00a displayFlexRowVc lrPad " + c_productStylingObj.openCaptureTopBarBgClass + " hoverRed cursorPointer"}
        p_styleObj={{flexBasis:p_captureNameContainerWidth, borderRight:"solid 1px #ccc"}}
        p_title={captureNameTitle}
        f_onClick={this.onclick_capture_name}
        f_onHover={this.onhover_capture_name}>
        {captureNameComponent}
      </LibraryReact.InteractiveDiv>
    );
  }
}));

const TimelineChartDataRow = inject("CapturesMobx", "OpenCaptureMobx")(observer(
class TimelineChartDataRow extends Component { //props: p_ganttTrueProgressFalse, p_captureObj, p_chartUserRowHeightEm, p_isHoverTF, f_onHover
  onhover_data_row = (i_hoverTF) => {
    const p_captureObj = this.props.p_captureObj;

    if(JSFUNC.is_function(this.props.f_onHover)) {
      this.props.f_onHover((i_hoverTF) ? (p_captureObj.id) : (-1));
    }
  }

  render() {
    const p_ganttTrueProgressFalse = this.props.p_ganttTrueProgressFalse;
    const p_captureObj = this.props.p_captureObj;
    const p_chartUserRowHeightEm = this.props.p_chartUserRowHeightEm;
    const p_isHoverTF = this.props.p_isHoverTF;

    const c_singleCaptureIsOpenTF = this.props.OpenCaptureMobx.c_singleCaptureIsOpenTF;

    //if a capture is opened, blank out every cell in every row so that animating the capture opening or closing it and returning to the table is not slow (plus the current scroll down in the capture table is maintained for clicking on multiple captures back to back)
    if(c_singleCaptureIsOpenTF) {
      return(null);
    }

    return(
      <LibraryReact.InteractiveDiv
        p_class="flex11a displayFlexColumn"
        p_styleObj={{backgroundColor:((p_isHoverTF) ? ("#f2f5fb") : (undefined))}}
        f_onHover={this.onhover_data_row}>
        {(p_ganttTrueProgressFalse) ? (
          <GanttRow p_captureObj={p_captureObj} />
        ) : (
          <ProgressRow p_captureObj={p_captureObj} p_chartUserRowHeightEm={p_chartUserRowHeightEm} />
        )}
      </LibraryReact.InteractiveDiv>
    );
  }
}));





//-------------------------------------------------------------------------------------------------------------------------------------
//Progress Chart
//-------------------------------------------------------------------------------------------------------------------------------------
const ProgressRow = inject("CapturesMobx", "DatabaseMobx")(observer(
class ProgressRow extends Component { //props: p_captureObj, p_chartUserRowHeightEm
  render() {
    const p_captureObj = this.props.p_captureObj;
    const p_chartUserRowHeightEm = this.props.p_chartUserRowHeightEm;

    const c_companyUsingPwinTF = this.props.DatabaseMobx.c_companyUsingPwinTF;

    var topProgressFraction = 0.075;
    var progressHeightFraction = 0.25;
    var topPwinFraction = 0.05;
    var pwinHeightFraction = 0.25;
    var topStagesFraction = 0.05;
    var stagesHeightFraction = 0.25;
    var bottomStagesFraction = 0.075;
    if(!c_companyUsingPwinTF) {
      topProgressFraction = 0.1;
      progressHeightFraction = 0.4;
      topStagesFraction = 0.1;
      stagesHeightFraction = 0.3;
      bottomStagesFraction = 0.1;
    }

    return(
      <>
        <div style={{height:(progressHeightFraction * p_chartUserRowHeightEm) + "em", marginTop:(topProgressFraction * p_chartUserRowHeightEm) + "em"}}>
          <ProgressRowShapingOrPwin p_captureObj={p_captureObj} p_shapingTruePwinFalse={true} p_chartUserRowHeightEm={p_chartUserRowHeightEm} />
        </div>
        {(c_companyUsingPwinTF) &&
          <div style={{height:(pwinHeightFraction * p_chartUserRowHeightEm) + "em", marginTop:(topPwinFraction * p_chartUserRowHeightEm) + "em"}}>
            <ProgressRowShapingOrPwin p_captureObj={p_captureObj} p_shapingTruePwinFalse={false} p_chartUserRowHeightEm={p_chartUserRowHeightEm} />
          </div>
        }
        <div style={{height:(stagesHeightFraction * p_chartUserRowHeightEm) + "em", marginTop:(topStagesFraction * p_chartUserRowHeightEm) + "em", marginBottom:(bottomStagesFraction * p_chartUserRowHeightEm) + "em"}}>
          <ProgressRowStage p_captureObj={p_captureObj} />
        </div>
      </>
    );
  }
}));

const ProgressRowShapingOrPwin = inject("CapturesMobx", "DatabaseMobx")(observer(
class ProgressRowShapingOrPwin extends Component { //props: p_captureObj, p_shapingTruePwinFalse, p_chartUserRowHeightEm
  render() {
    const p_captureObj = this.props.p_captureObj;
    const p_shapingTruePwinFalse = JSFUNC.prop_value(this.props.p_shapingTruePwinFalse, true);
    const p_chartUserRowHeightEm = this.props.p_chartUserRowHeightEm;

    const c_timelineChartTimeSpanObj = this.props.CapturesMobx.c_timelineChartTimeSpanObj;

    const logTblName = ((p_shapingTruePwinFalse) ? ("o_progressChartLogShapingMapOfArrayOfObjs") : ("o_progressChartLogPwinMapOfArrayOfObjs"));
    const dvDataArrayOfObjs = this.props.DatabaseMobx[logTblName].get(p_captureObj.id);
    const progressPwinStagesFlag = ((p_shapingTruePwinFalse) ? ("progress") : ("pwin"));

    if((dvDataArrayOfObjs === undefined) || (dvDataArrayOfObjs.length === 0)) {
      return(
        <SvgAllMonthLines p_in100SvgTF={true} />
      );
    }

    const progressBarsDataObj = this.props.CapturesMobx.compute_progress_chart_bars_data_obj(dvDataArrayOfObjs, c_timelineChartTimeSpanObj.startDate, c_timelineChartTimeSpanObj.endDate, c_timelineChartTimeSpanObj.startDateUnixSec, c_timelineChartTimeSpanObj.totalNumSec, c_timelineChartTimeSpanObj.nowDateTimeUTC, progressPwinStagesFlag);

    //progress bar width
    var progressBlackBarThicknessPercent = (15 - Math.round(p_chartUserRowHeightEm / 5));

    return(
      <svg width="100%" height="100%">
        {progressBarsDataObj.bgProgressColorsArrayOfObjs.map((m_bgColorObj) =>
          <rect x={m_bgColorObj.pos1 + "%"} y="0" width={(m_bgColorObj.pos2 - m_bgColorObj.pos1) + "%"} height="100%" style={{fill:m_bgColorObj.value, strokeWidth:"0"}} />
        )}
        {progressBarsDataObj.progressBarsArrayOfObjs.map((m_barObj) =>
          <g>
            <title>{m_barObj.numDays + " days at " + m_barObj.value + "% Total Progress"}</title>
            <LibraryReact.SvgHLine p_x1p={m_barObj.pos1} p_x2p={m_barObj.pos2} p_ycp={(100 - m_barObj.value)} p_widthP={progressBlackBarThicknessPercent} p_color="#333" />
          </g>
        )}
        <SvgAllMonthLines p_in100SvgTF={false} />
      </svg>
    );
  }
}));

const ProgressRowStage = inject("CapturesMobx", "DatabaseMobx")(observer(
class ProgressRowStage extends Component { //props: p_captureObj
  render() {
    const p_captureObj = this.props.p_captureObj;

    const logTblName = "o_progressChartLogStagesMapOfArrayOfObjs";
    const dvDataArrayOfObjs = this.props.DatabaseMobx[logTblName].get(p_captureObj.id);

    if(dvDataArrayOfObjs === undefined || dvDataArrayOfObjs.length === 0) {
      return(
        <SvgAllMonthLines p_in100SvgTF={true} />
      );
    }

    const c_timelineChartTimeSpanObj = this.props.CapturesMobx.c_timelineChartTimeSpanObj;
    const stagesDataObj = this.props.CapturesMobx.compute_progress_chart_bars_data_obj(dvDataArrayOfObjs, c_timelineChartTimeSpanObj.startDate, c_timelineChartTimeSpanObj.endDate, c_timelineChartTimeSpanObj.startDateUnixSec, c_timelineChartTimeSpanObj.totalNumSec, c_timelineChartTimeSpanObj.nowDateTimeUTC, "stages");
    const numProgressBars = stagesDataObj.progressBarsArrayOfObjs.length;

    var stageLogsArrayOfObjs = [];
    for(let s = 0; s < numProgressBars; s++) {
      var singleStageDataObj = stagesDataObj.progressBarsArrayOfObjs[s];
      var stageMap = this.props.DatabaseMobx.tbl_row_map_from_id("tbl_a_stages_pool", singleStageDataObj.value);
      stageLogsArrayOfObjs.push({
        pos1: singleStageDataObj.pos1,
        pos2: singleStageDataObj.pos2,
        color: "#" + stageMap.get("color"),
        lineTF: (!((s === (numProgressBars - 1)) && stageMap.get("isClosedStageTF"))),
        rectTF: (singleStageDataObj.pos1 > 0), //tall box placed on the day the stage was changed to this stage
        title: singleStageDataObj.numDays + " days in stage '" + stageMap.get("name") + "'"
      });
    }

    return(
      <svg width="100%" height="100%">
        {stageLogsArrayOfObjs.map((m_stageObj) =>
          <g>
            <title>{m_stageObj.title}</title>
            {(m_stageObj.lineTF) &&
              <LibraryReact.SvgHLine p_x1p={m_stageObj.pos1} p_x2p={m_stageObj.pos2} p_ycp={50} p_widthP={35} p_color={m_stageObj.color} />
            }
            {(m_stageObj.rectTF) &&
              <rect x={m_stageObj.pos1 + "%"} y="0" width="1em" height="100%" style={{strokeWidth:"1", stroke:"#888", fill:m_stageObj.color}} />
            }
          </g>
        )}
        <SvgAllMonthLines p_in100SvgTF={false} />
      </svg>
    );
  }
}));




//-------------------------------------------------------------------------------------------------------------------------------------
//Gantt Chart
//-------------------------------------------------------------------------------------------------------------------------------------
const GanttRow = inject("CapturesMobx", "DatabaseMobx", "UserMobx")(observer(
class GanttRow extends Component { //props: p_captureObj
  render() {
    const p_captureObj = this.props.p_captureObj;

    const c_ganttSelectedPresetObj = this.props.CapturesMobx.c_ganttSelectedPresetObj;
    const c_timelineChartTimeSpanObj = this.props.CapturesMobx.c_timelineChartTimeSpanObj;
    const userChartSize = this.props.UserMobx.c_combinedUserObj.capture_chart_gantt_size;

    if(c_ganttSelectedPresetObj == undefined) {
      return(null);
    }

    const ganttDatesArrayOfObjs = c_ganttSelectedPresetObj.ganttDatesArrayOfObjs;

    const monthLinesArrayOfObjs = c_timelineChartTimeSpanObj.monthLinesArrayOfObjs;
    const todayPos = c_timelineChartTimeSpanObj.todayPos;

    const dateEllipseWidth = ((userChartSize * 0.07) + 0.23) + "em";
    const dateEllipseYRadius = (45 - (userChartSize * 2.8)) + "%";

    //get each date value from the capture record
    var captureDatesArrayOfObjs = [];
    for(let ganttDateObj of ganttDatesArrayOfObjs) {
      var captureDateOrDateTimeUtcValueRaw = p_captureObj[ganttDateObj.fieldDbName];
      var jsDateObj = undefined;
      var durationInSeconds = 0;
      var dateTimeLocal = "";
      var dateOrDateTimeLocalMaskPlainText = "";
      if(ganttDateObj.fieldIsDateWithDurationTF) {
        var dateWithDurationObj = JSFUNC.convert_date_with_duration_raw_datetime_to_date_with_duration_obj(captureDateOrDateTimeUtcValueRaw);
        if(JSFUNC.date_is_filled_out_tf(dateWithDurationObj.dateYmd)) {
          dateTimeLocal = dateWithDurationObj.dateYmd + " 12:00:00";
          jsDateObj = JSFUNC.convert_mysqldatetimelocal_to_jsdateobj(dateTimeLocal);
          if(dateWithDurationObj.numDaysWeeksMonthsYearsInt > 0) {
            if(dateWithDurationObj.days1Weeks2Months3Years4Int === 2) { durationInSeconds = (dateWithDurationObj.numDaysWeeksMonthsYearsInt * 7 * 86400); } //weeks
            else if(dateWithDurationObj.days1Weeks2Months3Years4Int === 3) { durationInSeconds = (dateWithDurationObj.numDaysWeeksMonthsYearsInt * 30.4375 * 86400); } //months
            else if(dateWithDurationObj.days1Weeks2Months3Years4Int === 4) { durationInSeconds = (dateWithDurationObj.numDaysWeeksMonthsYearsInt * 365.25 * 86400); } //years
            else { durationInSeconds = (dateWithDurationObj.numDaysWeeksMonthsYearsInt * 86400); } //days
          }
          dateOrDateTimeLocalMaskPlainText = this.props.DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(captureDateOrDateTimeUtcValueRaw, this.props.DatabaseMobx.c_genericDateWithDurationFieldTypeObj);
        }
      }
      else if(JSFUNC.datetime_is_filled_out_tf(captureDateOrDateTimeUtcValueRaw)) {
        const dateSecondsString0to59 = JSFUNC.direct_get_ss_00to59_string_from_YmdHis_datetime(captureDateOrDateTimeUtcValueRaw);
        if((dateSecondsString0to59 === "58") || (dateSecondsString0to59 === "59")) { //time is not set, force it to noon same as the regular date field type
          dateTimeLocal = JSFUNC.direct_get_Ymd_string_from_YmdHis_datetime(captureDateOrDateTimeUtcValueRaw) + " 12:00:00";
          jsDateObj = JSFUNC.convert_mysqldatetimelocal_to_jsdateobj(dateTimeLocal);
        }
        else { //convert datetime in UTC to local time
          jsDateObj = JSFUNC.convert_mysqldatetimeutc_to_jsdateobj(captureDateOrDateTimeUtcValueRaw);
          dateTimeLocal = JSFUNC.get_YmdHis_datetime_from_jsdateobj_and_utctf(jsDateObj, false);
        }
        dateOrDateTimeLocalMaskPlainText = this.props.DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(captureDateOrDateTimeUtcValueRaw, this.props.DatabaseMobx.c_genericDateTimeFieldTypeObj);
      }
      else if(JSFUNC.date_is_filled_out_tf(captureDateOrDateTimeUtcValueRaw)) { //use noon as the local time for a date type field
        dateTimeLocal = captureDateOrDateTimeUtcValueRaw + " 12:00:00";
        jsDateObj = JSFUNC.convert_mysqldatetimelocal_to_jsdateobj(dateTimeLocal);
        dateOrDateTimeLocalMaskPlainText = this.props.DatabaseMobx.value_mask_plaintext_from_value_raw_and_field_type_obj(captureDateOrDateTimeUtcValueRaw, this.props.DatabaseMobx.c_genericDateFieldTypeObj);
      }

      if(jsDateObj !== undefined) {
        var captureDateSecFromStartDate = ((jsDateObj.getTime() / 1000) - c_timelineChartTimeSpanObj.startDateUnixSec);
        captureDatesArrayOfObjs.push({
          dateTimeLocal: dateTimeLocal,
          dateOrDateTimeLocalMaskPlainText: dateOrDateTimeLocalMaskPlainText,
          dateFieldDisplayName: ganttDateObj.fieldDisplayName,
          secFromStart: captureDateSecFromStartDate,
          durationInSeconds: durationInSeconds,
          color: ganttDateObj.color
        });
      }
    }

    //sort the dates in ascending order
    captureDatesArrayOfObjs.sort(JSFUNC.sort_by_asc("dateTimeLocal"));
    const numCaptureDates = captureDatesArrayOfObjs.length;

    //only include dates that would have a dot or bar drawn somewhere within the graph start/end dates
    var ganttDataArrayOfObjs = [];
    for(let d = 0; d < numCaptureDates; d++) {
      var captureDateObj = captureDatesArrayOfObjs[d];
      var currentDateTimeLocal = captureDateObj.dateTimeLocal;

      //add a date circle if the date falls within the start/end
      if(captureDateObj.durationInSeconds > 0) {
        var pos1 = ((captureDateObj.secFromStart / c_timelineChartTimeSpanObj.totalNumSec) * 100);
        var pos2 = (((captureDateObj.secFromStart + captureDateObj.durationInSeconds) / c_timelineChartTimeSpanObj.totalNumSec) * 100);
        if(((pos1 >= 0) && (pos1 <= 100)) || ((pos2 >= 0) && (pos2 <= 100))) {
          ganttDataArrayOfObjs.push({
            dateTF: false,
            dateWithDurationTF: true,
            barTF: false,
            pos1: pos1,
            pos2: pos2,
            color: captureDateObj.color,
            title: captureDateObj.dateFieldDisplayName + ": " + captureDateObj.dateOrDateTimeLocalMaskPlainText + "\n" + p_captureObj.captureFullName
          });
        }
      }
      else {
        var pos1 = ((captureDateObj.secFromStart / c_timelineChartTimeSpanObj.totalNumSec) * 100);
        if((pos1 >= 0) && (pos1 <= 100)) {
          ganttDataArrayOfObjs.push({
            dateTF: true,
            dateWithDurationTF: false,
            barTF: false,
            pos1: pos1,
            pos2: undefined,
            color: captureDateObj.color,
            title: captureDateObj.dateFieldDisplayName + ": " + captureDateObj.dateOrDateTimeLocalMaskPlainText + "\n" + p_captureObj.captureFullName
          });
        }
      }

      //add a bar if any part of the bar from this date to the next one falls within the start/end dates
      if(d < (numCaptureDates - 1)) { //last latest date does not have a bar after it
        var nextCaptureDateObj = captureDatesArrayOfObjs[d + 1];
        var nextDateTimeLocal = nextCaptureDateObj.dateTimeLocal;
        var pos1 = undefined;
        var pos2 = undefined;
        if(currentDateTimeLocal <= c_timelineChartTimeSpanObj.startDate) {
          if(nextDateTimeLocal <= c_timelineChartTimeSpanObj.startDate) { //both dates are to the left of the start date, no bar drawn
            pos1 = undefined;
            pos2 = undefined;
          }
          else if(nextDateTimeLocal <= c_timelineChartTimeSpanObj.endDate) { //date is left of start, but next date is in the window
            pos1 = 0;
            pos2 = ((nextCaptureDateObj.secFromStart / c_timelineChartTimeSpanObj.totalNumSec) * 100);
          }
          else { //nextDateTimeLocal > c_timelineChartTimeSpanObj.endDate, bar spans the entire window
            pos1 = 0;
            pos2 = 100;
          }
        }
        else if(currentDateTimeLocal <= c_timelineChartTimeSpanObj.endDate) {
          if(nextDateTimeLocal <= c_timelineChartTimeSpanObj.endDate) { //this date and next date are both in the window
            pos1 = ((captureDateObj.secFromStart / c_timelineChartTimeSpanObj.totalNumSec) * 100);
            pos2 = ((nextCaptureDateObj.secFromStart / c_timelineChartTimeSpanObj.totalNumSec) * 100);
          }
          else { //nextDateTimeLocal > c_timelineChartTimeSpanObj.endDate, this date is in the window, but next date is outside of the window
            pos1 = ((captureDateObj.secFromStart / c_timelineChartTimeSpanObj.totalNumSec) * 100);
            pos2 = 100;
          }
        }
        else { //thisdate > c_timelineChartTimeSpanObj.endDate, meaning that the nextDateTimeLocal is also past the end date, thus neither of the dates are in the window and there is no bar
          pos1 = undefined;
          pos2 = undefined;
        }

        if(pos1 !== undefined && pos2 !== undefined) {
          ganttDataArrayOfObjs.push({
            dateTF: false,
            dateWithDurationTF: false,
            barTF: true,
            pos1: pos1,
            pos2: pos2,
            color: captureDateObj.color,
            title: undefined
          });
        }
      }
    }

    //draw the gantt dates and connecting bars
    return(
      <svg width="100%" height="100%">
        <SvgAllMonthLines p_in100SvgTF={false} />
        {ganttDataArrayOfObjs.map((m_ganttDataObj) =>
          <g>
            {(m_ganttDataObj.title !== undefined) &&
              <title>{m_ganttDataObj.title}</title>
            }
            {(m_ganttDataObj.dateTF) &&
              <ellipse cx={m_ganttDataObj.pos1 + "%"} cy="50%" rx={dateEllipseWidth} ry={dateEllipseYRadius} style={{fill:"#" + m_ganttDataObj.color, strokeWidth:"1", stroke:"#aaa"}} />
            }
            {(m_ganttDataObj.dateWithDurationTF) &&
              <rect x={m_ganttDataObj.pos1 + "%"} y="15%" rx="5px" ry="5px" width={(m_ganttDataObj.pos2 - m_ganttDataObj.pos1) + "%"} height="70%" style={{fill:"#" + m_ganttDataObj.color, strokeWidth:"1", stroke:"#aaa"}} />
            }
            {(m_ganttDataObj.barTF) &&
              <LibraryReact.SvgHLine p_x1p={m_ganttDataObj.pos1} p_x2p={m_ganttDataObj.pos2} p_ycp={50} p_widthP={12} p_color={"#" + m_ganttDataObj.color} />
            }
          </g>
        )}
        <SvgTodayLine p_in100SvgTF={false} />
      </svg>
    );
  }
}));


const SvgAllMonthLines = inject("CapturesMobx")(observer(
class SvgAllMonthLines extends Component { //props: p_in100SvgTF
  render() {
    const p_in100SvgTF = JSFUNC.prop_value(this.props.p_in100SvgTF, true);

    const c_timelineChartTimeSpanObj = this.props.CapturesMobx.c_timelineChartTimeSpanObj;

    const singleLetterMonthsTF = c_timelineChartTimeSpanObj.singleLetterMonthsTF;
    const monthLinesArrayOfObjs = c_timelineChartTimeSpanObj.monthLinesArrayOfObjs;

    //for single letter months (very zoomed out), only show January line, otherwise draw a line for each month
    const monthLinesComponent = monthLinesArrayOfObjs.map((m_monthObj) =>
      (!singleLetterMonthsTF || (m_monthObj.monthIndex0to11 === 0)) &&
      <LibraryReact.SvgVLine p_y1p={0} p_y2p={100} p_xcp={m_monthObj.monthPos} p_widthEm={0.1} p_color={((!singleLetterMonthsTF && m_monthObj.monthIndex0to11 === 0) ? ("#999") : ("#ddd"))} />
    );

    if(p_in100SvgTF) {
      return(
        <svg width="100%" height="100%">
          {monthLinesComponent}
        </svg>
      );
    }

    return(
      monthLinesComponent
    );
  }
}));


const SvgTodayLine = inject("CapturesMobx")(observer(
class SvgTodayLine extends Component { //props: p_in100SvgTF
  render() {
    const p_in100SvgTF = JSFUNC.prop_value(this.props.p_in100SvgTF, true);

    const c_timelineChartTimeSpanObj = this.props.CapturesMobx.c_timelineChartTimeSpanObj;

    if(c_timelineChartTimeSpanObj.todayPos === undefined) {
      return(null);
    }

    const lineComponent = (
      <LibraryReact.SvgVLine p_y1p={0} p_y2p={100} p_xcp={c_timelineChartTimeSpanObj.todayPos} p_widthEm={0.2} p_color={"#005da3"} />
    );

    if(p_in100SvgTF) {
      return(
        <svg width="100%" height="100%">
          {lineComponent}
        </svg>
      );
    }

    return(
      lineComponent
    );
  }
}));












//======================================================================================================================================
//======================================================================================================================================
//Master Presets Editor / Gantt Presets Editor
//======================================================================================================================================
//======================================================================================================================================

export const PresetEditorFloatingBox = inject("CaptureExecMobx", "CapturesMobx")(observer(
class PresetEditorFloatingBox extends Component {
  onclose_editor = () => {
    this.props.CapturesMobx.a_set_capture_table_preset_editor_open_tf(false);
    this.props.CapturesMobx.a_set_gantt_dates_preset_editor_open_tf(false);
  }

  render() {
    const c_isMobileTF = this.props.CaptureExecMobx.c_isMobileTF;
    const o_masterPresetEditorOpenTF = this.props.CapturesMobx.o_masterPresetEditorOpenTF;
    const o_ganttDatesPresetEditorOpenTF = this.props.CapturesMobx.o_ganttDatesPresetEditorOpenTF;

    //neither the master or gantt preset editors are open
    if(!o_masterPresetEditorOpenTF && !o_ganttDatesPresetEditorOpenTF) {
      return(null);
    }

    var floatingBoxTitle = undefined;
    var leftSideComponent = null;
    var rightSideComponent = null;
    var rightSideIsOpenTF = false; //in mobile, only 1 side can be shown at a time, if an item is open, then show the right side, otherwise show the list of items on the left
    if(o_masterPresetEditorOpenTF) {
      floatingBoxTitle = "Create/Edit Capture Table Presets (Columns/Filters/Sorts)";
      leftSideComponent = (<PresetEditorLeftPresetsList />);
      rightSideComponent = (<PresetEditorRightOpenPreset />);
      rightSideIsOpenTF = (this.props.CapturesMobx.o_presetEditorOpenPresetType !== undefined && this.props.CapturesMobx.o_presetEditorOpenPresetID !== undefined);
    }
    else if(o_ganttDatesPresetEditorOpenTF) {
      floatingBoxTitle = "Create/Edit Gantt Date Presets";
      leftSideComponent = (<GanttPresetEditorLeftPresetsList />);
      rightSideComponent = (<GanttPresetEditorRightOpenPreset />);
      rightSideIsOpenTF = (this.props.CapturesMobx.o_ganttDatesOpenPresetID !== undefined);
    }

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="contactsSystemSelect"
        p_title={floatingBoxTitle}
        f_onClickSave={this.onclose_editor}>
        {(o_masterPresetEditorOpenTF) &&
          <div className="flex00a">
            <PresetTopQuickAccessSelections />
          </div>
        }
        <div className="flex11a displayFlexColumn">
          {(c_isMobileTF) ? (
            <div className="flex11a displayFlexColumn">
              {((rightSideIsOpenTF) ? (rightSideComponent) : (leftSideComponent))}
            </div>
          ) : (
            <div className="flex11a displayFlexRow">
              <div className="flex11a displayFlexColumn" style={{flexBasis:"100em", borderRight:"solid 1px #ccc"}}>
                {leftSideComponent}
              </div>
              <div className="flex11a displayFlexColumn" style={{flexBasis:"100em"}}>
                {rightSideComponent}
              </div>
            </div>
          )}
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));






//-------------------------------------------------------------------------------------------------------------------------------------
//Master "Capture Table" Preset Editor
//-------------------------------------------------------------------------------------------------------------------------------------
const PresetTopQuickAccessSelections = inject("CapturesMobx")(observer(
class PresetTopQuickAccessSelections extends Component {
  render() {
    const c_quickAccessCaptureTablePresetsArrayOfObjs = this.props.CapturesMobx.c_quickAccessCaptureTablePresetsArrayOfObjs; //this includes the default -2 "My Captures" if there are no presets designated for quick access, don't include it in the editor here

    const canResortTF = (c_quickAccessCaptureTablePresetsArrayOfObjs.length >= 2); //no need to allow drag/drop if there is only 1 button added so far

    return(
      <div className="displayFlexRowVc flexWrap" style={{backgroundColor:"#ddf", borderBottom:"solid 1px #ddd"}}>
        <div className="flex00a displayFlexColumnHcVc lrMargin" style={{height:"3.9em"}}>
          <font className="fontItalic fontTextLight">
            <div>{"Quick Access"}</div>
            <div>{"Master Presets:"}</div>
          </font>
        </div>
        {c_quickAccessCaptureTablePresetsArrayOfObjs.map((m_masterPresetObj) =>
          <PresetEditorSelectedQAMasterPresetButton
            key={m_masterPresetObj.id}
            p_masterPresetObj={m_masterPresetObj}
            p_canResortTF={canResortTF}
          />
        )}
        {(canResortTF) &&
          <PresetEditorSelectedQAMasterPresetButton
            p_masterPresetObj={undefined}
            p_canResortTF={canResortTF}
          />
        }
      </div>
    );
  }
}));

const PresetEditorSelectedQAMasterPresetButton = inject("CapturesMobx")(observer(
class PresetEditorSelectedQAMasterPresetButton extends Component { //props: p_masterPresetObj, p_canResortTF
  onclick_open_quick_access_selection_item = () => {
    this.props.CapturesMobx.a_set_preset_editor_open_preset_type_and_id("master", this.props.p_masterPresetObj.id);
  }

  onremove_master_preset_quick_access = () => {
    this.props.CapturesMobx.a_remove_master_preset_from_quick_access(this.props.p_masterPresetObj.id);
  }

  ondragover_other_preset = (i_isDragOverTF) => {
    this.setState({s_isDragOverTF:i_isDragOverTF});
  }

  ondrop_other_preset = (i_draggedMasterPresetID) => { //does not trigger when dropped onto itself, which would not sort any differently anyway
    const droppedOnMasterPresetID = this.props.p_masterPresetObj.id;
    this.props.CapturesMobx.a_resort_selected_quick_access_master_presets(i_draggedMasterPresetID, droppedOnMasterPresetID);
  }

  ondrop_last_blank_dropzone = (i_draggedMasterPresetID) => {
    this.props.CapturesMobx.a_resort_selected_quick_access_master_presets(i_draggedMasterPresetID, -1);
  }

  render() {
    const p_masterPresetObj = this.props.p_masterPresetObj;
    const p_canResortTF = this.props.p_canResortTF;

    //if p_masterPresetObj is undefined, it is meant to be a blank dropzone at the end of the sortable master preset buttons
    if(p_masterPresetObj === undefined) {
      return(
        <CEGeneralReact.CE3Drag3Drop3Shell3
          p_uniqueString="presetEditorSelectedQAMasterPreset"
          p_itemID={-1}
          p_draggableTF={p_canResortTF}
          p_droppableTF={p_canResortTF}
          p_dropZoneIsInvisibleOverlayTF={true}
          p_dropZoneOversizeWidthEm={undefined}
          p_class="flex00a smallFullMargin"
          p_styleObj={{flexBasis:"10em", height:"3em"}}
          p_dragOverStyleObj={{border:"solid 2px #333", backgroundColor:"rgba(0,0,0,0.2)"}}
          f_onDropMatchingItem={this.ondrop_last_blank_dropzone}
        />
      );
    }

    //don't draw the special id -2 master preset button that shows up when users have 0 master buttons, only draw real user created buttons
    if(!JSFUNC.is_number_not_nan_gt_0(p_masterPresetObj.id)) {
      return(null);
    }

    var title = "Click to edit the presets for this button";
    var cursorClass = "cursorPointer";
    if(p_canResortTF) {
      title += "\n[Drag/drop this button to reorder]";
      cursorClass = "cursorGrab";
    }

    return(
      <CEGeneralReact.CE3Drag3Drop3Shell3
        p_uniqueString="presetEditorSelectedQAMasterPreset"
        p_itemID={p_masterPresetObj.id}
        p_draggableTF={p_canResortTF}
        p_droppableTF={p_canResortTF}
        p_dropZoneIsInvisibleOverlayTF={true}
        p_dropZoneOversizeWidthEm={undefined}
        p_class={"flex00a displayFlexRow smallFullMargin border bevelBorderColors bgLightGrayGradient " + cursorClass}
        p_styleObj={{flexBasis:"10em", height:"3em"}}
        p_title={title}
        p_dragOverClass={undefined}
        p_dragOverStyleObj={{border:"solid 2px #333", backgroundColor:"rgba(0,0,0,0.2)"}}
        f_onDropMatchingItem={this.ondrop_other_preset}
        f_onClick={this.onclick_open_quick_access_selection_item}>
        <div className="flex11a displayFlexRowVc lrPad">
          <LibraryReact.MaxHeightWrap p_maxHeight="2.4em" p_fontClass="fontBold fontTextLight">
            {p_masterPresetObj.name}
          </LibraryReact.MaxHeightWrap>
        </div>
        <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em"}}>
          <CEGeneralReact.RemoveItemButton
            p_title={"Remove master preset '" + p_masterPresetObj.name + "' from your quick access bar"}
            f_onClick={this.onremove_master_preset_quick_access}
          />
        </div>
      </CEGeneralReact.CE3Drag3Drop3Shell3>
    );
  }
}));

const PresetEditorLeftPresetsList = inject("CapturesMobx", "UserMobx")(observer(
class PresetEditorLeftPresetsList extends Component {
  onswitch_view_presets_from_other_users_switch = () => {
    const o_presetEditorViewPresetsFromOtherUsersTF = this.props.CapturesMobx.o_presetEditorViewPresetsFromOtherUsersTF;
    this.props.CapturesMobx.a_set_preset_editor_view_presets_from_other_users_tf(!o_presetEditorViewPresetsFromOtherUsersTF);
  }

  onclick_create_new_preset_to_enter_name = (i_createPresetType) => {
    this.props.CapturesMobx.a_set_preset_editor_creating_new_preset_type(i_createPresetType);
  }

  onclick_save_new_preset_name = (i_newPresetName) => {
    const o_presetEditorCreatingNewPresetType = this.props.CapturesMobx.o_presetEditorCreatingNewPresetType;
    this.props.CapturesMobx.a_create_new_master_columns_filter_sort_preset(o_presetEditorCreatingNewPresetType, i_newPresetName);
    this.props.CapturesMobx.a_set_preset_editor_creating_new_preset_type(undefined);
  }

  onclick_cancel_create_new_preset = () => {
    this.props.CapturesMobx.a_set_preset_editor_creating_new_preset_type(undefined);
  }

  render() {
    const o_presetEditorViewPresetsFromOtherUsersTF = this.props.CapturesMobx.o_presetEditorViewPresetsFromOtherUsersTF;
    const o_presetEditorCreatingNewPresetType = this.props.CapturesMobx.o_presetEditorCreatingNewPresetType;
    const c_presetMyCaptureTablePresetsArrayOfObjs = this.props.CapturesMobx.c_presetMyCaptureTablePresetsArrayOfObjs;
    const c_presetPublicCaptureTablePresetsArrayOfObjs = this.props.CapturesMobx.c_presetPublicCaptureTablePresetsArrayOfObjs;
    const c_presetMyCstColumnPresetsArrayOfObjs = this.props.CapturesMobx.c_presetMyCstColumnPresetsArrayOfObjs;
    const c_presetPublicCstColumnPresetsArrayOfObjs = this.props.CapturesMobx.c_presetPublicCstColumnPresetsArrayOfObjs;
    const c_presetMyFilterPresetsArrayOfObjs = this.props.CapturesMobx.c_presetMyFilterPresetsArrayOfObjs;
    const c_presetPublicFilterPresetsArrayOfObjs = this.props.CapturesMobx.c_presetPublicFilterPresetsArrayOfObjs;
    const c_presetMySortPresetsArrayOfObjs = this.props.CapturesMobx.c_presetMySortPresetsArrayOfObjs;
    const c_presetPublicSortPresetsArrayOfObjs = this.props.CapturesMobx.c_presetPublicSortPresetsArrayOfObjs;
    const c_anyOfUsersMultiLoginsHaveAdminPowerTF = this.props.UserMobx.c_anyOfUsersMultiLoginsHaveAdminPowerTF;

    const presetTypesArray = ["master", "cstColumns", "filter", "sort"];
    const createNewPresetButtonLabelsArray = ["Master", "Column", "Filter", "Sort"];
    const presetLabelsArray = ["Master Presets (1 Column Preset + 1 Filter Preset + 1 Sort Preset)", "Column Presets", "Filter Presets", "Sort Presets"];
    const myPresetsArrayOfArrayOfObjs = [c_presetMyCaptureTablePresetsArrayOfObjs, c_presetMyCstColumnPresetsArrayOfObjs, c_presetMyFilterPresetsArrayOfObjs, c_presetMySortPresetsArrayOfObjs];
    const publicPresetsArrayOfArrayOfObjs = [c_presetPublicCaptureTablePresetsArrayOfObjs, c_presetPublicCstColumnPresetsArrayOfObjs, c_presetPublicFilterPresetsArrayOfObjs, c_presetPublicSortPresetsArrayOfObjs];

    return(
      <div className="flex11a displayFlexColumn">
        <div className="flex00a" style={{borderBottom:"solid 1px #ccc"}}>
          {(o_presetEditorCreatingNewPresetType === undefined) ? (
            <div className="displayFlexRow">
              {presetTypesArray.map((m_presetType, m_index) =>
                <PresetEditorLeftCreateNewPresetButton
                  key={createNewPresetButtonLabelsArray[m_index]}
                  p_presetType={m_presetType}
                  p_label={createNewPresetButtonLabelsArray[m_index]}
                  f_onClick={this.onclick_create_new_preset_to_enter_name}
                />
              )}
            </div>
          ) : (
            <PresetEditorNewPresetNameInputSaveCancel
              p_instructions={"Enter a name for the new " + createNewPresetButtonLabelsArray[presetTypesArray.indexOf(o_presetEditorCreatingNewPresetType)] + " Preset"}
              f_onClickSave={this.onclick_save_new_preset_name}
              f_onClickCancel={this.onclick_cancel_create_new_preset}
            />
          )}
        </div>
        <div className="flex11a yScroll yScrollBottomPad" style={{backgroundColor:"#f8f8f8"}}>
          {(c_anyOfUsersMultiLoginsHaveAdminPowerTF) &&
            <div className="displayFlexRow" style={{height:"2em"}}>
              <div className="flex11a displayFlexColumnVc textRight">
                <font className="fontItalic fontTextLight">
                  {"Show/Edit Presets created by other Users"}
                </font>
              </div>
              <div className="flex00a displayFlexRowVc lrMedPad">
                <CEGeneralReact.SwitchWithTextAndConfirmBox
                  p_isOnTF={o_presetEditorViewPresetsFromOtherUsersTF}
                  p_sizeEm={3}
                  p_onColor="cc7722"
                  p_title={"Switch on to show all Presets created by all other Users and have the ability to edit/delete them (this switch is only available for Admins)"}
                  f_onSwitch={this.onswitch_view_presets_from_other_users_switch}
                />
              </div>
            </div>
          }
          {presetTypesArray.map((m_presetType, m_index) =>
            <PresetEditorLeftPresetTypeMyPublicLists
              key={m_presetType}
              p_presetType={m_presetType}
              p_presetLabel={presetLabelsArray[m_index]}
              p_myPresetsArrayOfObjs={myPresetsArrayOfArrayOfObjs[m_index]}
              p_publicPresetsArrayOfObjs={publicPresetsArrayOfArrayOfObjs[m_index]}
            />
          )}
        </div>
      </div>
    );
  }
}));

const PresetEditorLeftCreateNewPresetButton = inject("CapturesMobx")(observer(
class PresetEditorLeftCreateNewPresetButton extends Component { //props: p_presetType, p_label
  onclick_create_new_preset = () => {
    this.props.CapturesMobx.a_set_preset_editor_creating_new_preset_type(this.props.p_presetType);
  }

  render() {
    const p_presetType = this.props.p_presetType;
    const p_label = this.props.p_label;

    return(
      <div
        className="flex11a displayFlexColumn smallFullMargin hoverLightestBlueGradient cursorPointer"
        style={{flexBasis:"100em", height:"3.3em", border:"solid 2px #99a", borderRadius:"0.7em"}}
        onClick={this.onclick_create_new_preset}>
        <div className="flex11a displayFlexRowVc textCenter" title={"Create a new " + p_label + " Preset"}>
          <LibraryReact.MaxHeightWrap p_maxHeight="2.4em" p_fontClass="fontTextLight">
            <div>{"Create New"}</div>
            <div><font className="fontBold">{p_label}</font>{" Preset"}</div>
          </LibraryReact.MaxHeightWrap>
        </div>
        <PresetTypeColorBar p_presetType={p_presetType} p_width="0.35em" />
      </div>
    );
  }
}));

class PresetEditorNewPresetNameInputSaveCancel extends Component { //props: p_instructions, f_onClickSave, f_onClickCancel
  constructor(props) {
    super(props);
    this.state = {
      s_newPresetNameText: "",
      s_showErrorsTF: false
    }
  }

  onchange_new_preset_name = (i_newValue) => {
    this.setState({s_newPresetNameText:i_newValue});
  }

  onclick_save = () => {
    if(this.props.f_onClickSave) {
      this.setState({s_showErrorsTF:true});
      if(!this.new_name_error_tf()) {
        this.props.f_onClickSave(this.state.s_newPresetNameText);
      }
    }
  }

  onclick_cancel = () => {
    if(this.props.f_onClickCancel) {
      this.props.f_onClickCancel();
    }
  }

  new_name_error_tf = () => {
    return(this.state.s_newPresetNameText === "");
  }

  render() {
    const s_newPresetNameText = this.state.s_newPresetNameText;
    const s_showErrorsTF = this.state.s_showErrorsTF;

    var nameBlankErrorTF = false;
    if(s_showErrorsTF) {
      nameBlankErrorTF = this.new_name_error_tf();
    }

    return(
      <div className="medFullPad bgCaptureItemEditing">
        <div className="smallBottomMargin textCenter">
          <font className="fontBold fontItalic">
            {this.props.p_instructions}
          </font>
        </div>
        <CEGeneralReact.EditInlineWithSaveCancel
          p_fieldValueVerticallyAlignedTF={true}
          f_onClickSave={this.onclick_save}
          f_onClickCancel={this.onclick_cancel}>
          <LibraryReact.Text
            p_value={s_newPresetNameText}
            p_styleObj={{width:"100%"}}
            p_tabIndex={1}
            p_errorTF={nameBlankErrorTF}
            f_onChange={this.onchange_new_preset_name}
            f_onKeyDownEnter={this.onclick_save}
          />
        </CEGeneralReact.EditInlineWithSaveCancel>
        {(nameBlankErrorTF) &&
          <CEGeneralReact.ErrorText p_text="New Preset Name cannot be blank" />
        }
      </div>
    );
  }
}

function PresetEditorLeftPresetTypeMyPublicLists(props) { //props: p_presetType, p_presetLabel, p_myPresetsArrayOfObjs, p_publicPresetsArrayOfObjs
  const p_presetType = props.p_presetType;
  const p_presetLabel = props.p_presetLabel;
  const p_myPresetsArrayOfObjs = props.p_myPresetsArrayOfObjs;
  const p_publicPresetsArrayOfObjs = props.p_publicPresetsArrayOfObjs;

  return(
    <div className="displayFlexRow" style={{marginBottom:"3em", borderTop:"solid 1px #ddd", borderBottom:"solid 1px #ddd", backgroundColor:"#fafafa"}}>
      <PresetTypeColorBar p_presetType={p_presetType} p_width="1em" />
      <div className="flex11a">
        <div className="lrPad smallTopPad medBottomPad">
          <div>
            <font className="font12 fontBold fontTextLighter">
              {p_presetLabel}
            </font>
          </div>
          <div className="smallTopMargin" style={{marginLeft:"0.4em"}}>
            <PresetEditorPresetTypeSubHeading p_label={"My Presets (" + p_myPresetsArrayOfObjs.length + ")"} />
            {p_myPresetsArrayOfObjs.map((m_myPresetObj) =>
              <LeftPresetItem
                key={m_myPresetObj.id}
                p_presetType={p_presetType}
                p_presetObj={m_myPresetObj}
              />
            )}
            <div className="bigTopPad" />
            <PresetEditorPresetTypeSubHeading p_label={"Public Presets (" + p_publicPresetsArrayOfObjs.length + ")"} />
            {p_publicPresetsArrayOfObjs.map((m_publicPresetObj) =>
              <LeftPresetItem
                key={m_publicPresetObj.id}
                p_presetType={p_presetType}
                p_presetObj={m_publicPresetObj}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const LeftPresetItem = inject("CapturesMobx")(observer(
class LeftPresetItem extends Component { //props: p_presetType, p_presetObj
  onclick_preset_item = () => {
    this.props.CapturesMobx.a_set_preset_editor_open_preset_type_and_id(this.props.p_presetType, this.props.p_presetObj.id);
  }

  onclick_add_master_preset_to_quick_access = () => {
    this.props.CapturesMobx.a_add_master_preset_to_quick_access(this.props.p_presetObj.id);
  }

  ondelete_preset = () => {
    this.props.CapturesMobx.a_delete_master_columns_filter_sort_preset(this.props.p_presetType, this.props.p_presetObj.id);
  }

  render() {
    const p_presetType = this.props.p_presetType;
    const p_presetObj = this.props.p_presetObj;
    
    const o_presetEditorViewPresetsFromOtherUsersTF = this.props.CapturesMobx.o_presetEditorViewPresetsFromOtherUsersTF;
    const c_presetEditorOpenPresetObj = this.props.CapturesMobx.c_presetEditorOpenPresetObj;

    var canAddMasterToQuickAccessTF = false;
    if(p_presetType === "master") {
      canAddMasterToQuickAccessTF = (!JSFUNC.in_array(p_presetObj.id, this.props.CapturesMobx.c_quickAccessMasterPresetIDsArray));
    }

    var rowBgClass = "bgAlmostWhite";
    if((c_presetEditorOpenPresetObj !== undefined) && (c_presetEditorOpenPresetObj.presetType === p_presetType) && (c_presetEditorOpenPresetObj.id === p_presetObj.id)) {
      rowBgClass = "bgCaptureItemEditing";
    }

    return(
      <div
        className={"displayFlexRow microTopMargin microBottomMargin border bevelBorderColors bgWhite hoverLighterBlueGradient cursorPointer " + rowBgClass}
        style={{height:"2.7em"}}
        onClick={this.onclick_preset_item}>
        <PresetTypeColorBar p_presetType={p_presetType} p_width="0.2em" />
        <div className="flex11a displayFlexRowVc lrMargin">
          <LibraryReact.MaxHeightWrap p_maxHeight="2.4em" p_fontClass="">
            {p_presetObj.name}
          </LibraryReact.MaxHeightWrap>
        </div>
        {(p_presetType === "master") &&
          <div className="flex00a displayFlexColumnHcVc">
            {(canAddMasterToQuickAccessTF) ? (
              <div
                className="border bevelBorderColors displayFlexColumnHcVc lrMedPad bgLightGrayGradient hoverLighterGrayGradient"
                style={{height:"1.5em", borderRadius:"1em"}}
                title={"Click to add this Master Preset '" + p_presetObj.name + "' to the Quick Access Bar above the capture summary table"}
                onClick={this.onclick_add_master_preset_to_quick_access}>
                <font classname="fontItalic">
                  {"Add to Quick Access"}
                </font>
              </div>
            ) : (
              <font className="fontItalic fontTextLighter">
                {"On your Quick Access"}
              </font>
            )}
          </div>
        }
        <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"3em"}}>
          {(p_presetObj.isPresetOwnerCanEditTF || o_presetEditorViewPresetsFromOtherUsersTF) &&
            <CEGeneralReact.DeleteMenu
              p_menuItemText="Delete"
              p_message={"Are you sure you want to delete preset '" + p_presetObj.name + "'"}
              f_onDelete={this.ondelete_preset}
            />
          }
        </div>
      </div>
    );
  }
}));

function PresetTypeColorBar(props) { //props: p_presetType, p_width
  var bgColorClass = "bgWhite";
  if(props.p_presetType === "master") {
    bgColorClass = "bgCstPresetsMaster";
  }
  else if(props.p_presetType === "cstColumns") {
    bgColorClass = "bgCstPresetsCstColumns";
  }
  else if(props.p_presetType === "filter") {
    bgColorClass = "bgCstPresetsFilters";
  }
  else if(props.p_presetType === "sort") {
    bgColorClass = "bgCstPresetsSorts";
  }

  return(
    <div className={"flex00a " + bgColorClass} style={{flexBasis:props.p_width}} />
  );
}

const PresetEditorRightOpenPreset = inject("CapturesMobx", "DatabaseMobx", "UserMobx")(observer(
class PresetEditorRightOpenPreset extends Component {
  onclick_close_preset = () => {
    this.props.CapturesMobx.a_set_preset_editor_open_preset_type_and_id(undefined, undefined);
  }

  onclick_confirm_create_copied_preset = (i_newPresetName) => {
    this.props.CapturesMobx.a_copy_preset_with_new_name(this.props.CapturesMobx.c_presetEditorOpenPresetObj, i_newPresetName);
  }

  onsave_new_preset_name = (i_newPresetName) => {
    this.props.CapturesMobx.a_update_preset_field(this.props.CapturesMobx.c_presetEditorOpenPresetObj, "name", i_newPresetName, "s");
  }

  onsave_new_preset_description = (i_newPresetDescription) => {
    this.props.CapturesMobx.a_update_preset_field(this.props.CapturesMobx.c_presetEditorOpenPresetObj, "description", i_newPresetDescription, "s");
  }

  onclick_preset_public_switch = () => {
    const newPublic01Value = ((this.props.CapturesMobx.c_presetEditorOpenPresetObj.public_01 === 1) ? (0) : (1));
    this.props.CapturesMobx.a_update_preset_field(this.props.CapturesMobx.c_presetEditorOpenPresetObj, "public_01", newPublic01Value, "i");
  }

  onsave_new_preset_excel_report_writer_codeword = (i_newExcelReportWriterCodeword) => {
    const newExcelReportWriterCodewordDbName = JSFUNC.db_name_from_display_name(i_newExcelReportWriterCodeword);
    this.props.CapturesMobx.a_update_preset_field(this.props.CapturesMobx.c_presetEditorOpenPresetObj, "excel_report_writer_codeword", newExcelReportWriterCodewordDbName, "s");
  }

  render() {
    const openPresetObj = this.props.CapturesMobx.c_presetEditorOpenPresetObj;
    const userCanCreatePublicItemsTF = this.props.UserMobx.c_userCanCreatePublicItemsTF;

    if(openPresetObj === undefined) {
      return(
        <CEGeneralReact.EmptyScreenGray>
          {"Select a preset to view/edit its components"}
        </CEGeneralReact.EmptyScreenGray>
      );
    }

    const presetMenuItemsArrayOfObjs = [
      {
        displayName: "Copy this as a new " + openPresetObj.presetLabel + " Preset",
        confirmType: "inputText",
        confirmTitle: "Copy " + openPresetObj.presetLabel + " Preset '" + openPresetObj.name + "'",
        confirmTextInitialValue: "Copy of " + openPresetObj.name,
        confirmButton1Name: "Create Copy",
        confirmTextMustBeChangedForSaveTFU: false,
        confirmMessage: "Enter a new name for this copied " + openPresetObj.presetLabel + " Preset",
        functionOnClickConfirmButton: this.onclick_confirm_create_copied_preset
      }
    ];

    const containerClass = "smallTopMargin lrMargin";
    const fieldClass = "fontBlue";
    const fieldWidth = "14em";

    const editSaveCancelOwnerComponent = (
      <CEGeneralReact.CaptureExecFieldEditSaveCancel
        p_ceEditItemString={openPresetObj.presetType + "PresetID" + openPresetObj.id + "owner"}
        p_fieldDisplayName="Owner"
        p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
        p_valueRaw={openPresetObj.user_id}
        p_valueMask={this.props.DatabaseMobx.user_name_mask_from_user_id(openPresetObj.user_id)}
        p_valueIsEditableTFU={false}
        p_containerClass={containerClass}
        p_fieldClass={fieldClass}
        p_fieldWidth={fieldWidth}
        p_fieldValueVerticallyAlignedTF={true}
        f_onSaveChanged={undefined}
      />
    );

    const editSaveCancelPresetNameComponent = (
      <CEGeneralReact.CaptureExecFieldEditSaveCancel
        p_ceEditItemString={openPresetObj.presetType + "PresetID" + openPresetObj.id + "name"}
        p_fieldDisplayName={openPresetObj.presetLabel + " Preset Name"}
        p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
        p_valueRaw={openPresetObj.name}
        p_valueIsEditableTFU={openPresetObj.isPresetOwnerCanEditTF}
        p_containerClass={containerClass}
        p_fieldClass={fieldClass}
        p_fieldWidth={fieldWidth}
        p_fieldValueVerticallyAlignedTF={true}
        f_onSaveChanged={this.onsave_new_preset_name}
      />
    );

    var editSaveCancelFilterSortExcelReportWriterCodewordComponent = null;
    if(openPresetObj.isFilterTF || openPresetObj.isSortTF) {
      editSaveCancelFilterSortExcelReportWriterCodewordComponent = (
        <CEGeneralReact.CaptureExecFieldEditSaveCancel
          p_ceEditItemString={openPresetObj.presetType + "PresetID" + openPresetObj.id + "excelreportwritercodeword"}
          p_fieldDisplayName="Excel Report Writer Codeword"
          p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
          p_valueRaw={openPresetObj.excel_report_writer_codeword}
          p_valueIsEditableTFU={openPresetObj.isPresetOwnerCanEditTF}
          p_containerClass={containerClass}
          p_fieldClass={fieldClass}
          p_fieldWidth={fieldWidth}
          p_fieldValueVerticallyAlignedTF={true}
          f_onSaveChanged={this.onsave_new_preset_excel_report_writer_codeword}
        />
      );
    }

    var editSaveCancelMasterHoverDescriptionComponent = null; //only master has hover text description field to edit for its preset
    var openPresetComponent = null;
    if(openPresetObj.presetType === "master") {
      editSaveCancelMasterHoverDescriptionComponent = (
        <CEGeneralReact.CaptureExecFieldEditSaveCancel
          p_ceEditItemString={openPresetObj.presetType + "PresetID" + openPresetObj.id + "description"}
          p_fieldDisplayName="Hover Description"
          p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
          p_valueRaw={openPresetObj.description}
          p_valueIsEditableTFU={openPresetObj.isPresetOwnerCanEditTF}
          p_containerClass={containerClass}
          p_fieldClass={fieldClass}
          p_fieldWidth={fieldWidth}
          p_fieldValueVerticallyAlignedTF={true}
          f_onSaveChanged={this.onsave_new_preset_description}
        />
      );
      openPresetComponent = (<PresetEditorOpenMasterPreset />);
    }
    else {
      openPresetComponent = (<PresetEditorOpenCFSPreset />);
    }

    return(
      <div className="flex11a displayFlexColumn" style={{backgroundColor:"#f3f6fc"}}>
        <div className="flex00a displayFlexRow borderB1bbb">
          <div className="flex11a displayFlexColumnVc lrPad">
            <LibraryReact.Nowrap p_fontClass="font13 fontBold fontTextLighter">
              {"Preset '" + openPresetObj.name + "'"}
            </LibraryReact.Nowrap>
          </div>
          <div className="flex00a displayFlexColumnHcVc borderR1bbb" style={{flexBasis:"2em"}}>
            <CEGeneralReact.VerticalDotsMenu
              p_menuItemsArrayOfObjs={presetMenuItemsArrayOfObjs}
              p_dotsFontClass=""
            />
          </div>
          <div className="flex00a displayFlexColumnVc lrMedPad">
            <CEGeneralReact.CEButton
              p_type="gray"
              p_text="Close"
              f_onClick={this.onclick_close_preset}
            />
          </div>
        </div>
        <div className="flex11a yScroll yScrollBottomPad lrPad">
          {editSaveCancelOwnerComponent}
          {editSaveCancelPresetNameComponent}
          {editSaveCancelMasterHoverDescriptionComponent}
          {editSaveCancelFilterSortExcelReportWriterCodewordComponent}
          {(openPresetObj.isPresetOwnerCanEditTF && userCanCreatePublicItemsTF) &&
            <div className="displayFlexRowVc smallTopMargin medBottomMargin lrMargin">
              <div className="flex00a" style={{flexBasis:fieldWidth}}>
                <font className={fieldClass}>
                  {"Is Public?"}
                </font>
              </div>
              <div className="flex11a">
                <CEGeneralReact.SwitchWithTextAndConfirmBox
                  p_isOnTF={(openPresetObj.public_01 === 1)}
                  p_sizeEm={3}
                  p_onText="Public to everyone"
                  p_offText="Private to me"
                  p_title={"Turn on to make your created " + openPresetObj.presetLabel + " Preset public to all users in your company"}
                  f_onSwitch={this.onclick_preset_public_switch}
                />
              </div>
            </div>
          }
          {openPresetComponent}
        </div>
      </div>
    );
  }
}));


const PresetEditorOpenMasterPreset = inject("CapturesMobx")(observer(
class PresetEditorOpenMasterPreset extends Component {
  render() {
    const openMasterPresetObj = this.props.CapturesMobx.c_presetEditorOpenPresetObj;
    if(openMasterPresetObj === undefined) {
      return(null);
    }

    const myColumns = this.props.CapturesMobx.c_presetMyCstColumnPresetsArrayOfObjs;
    const pubColumns = this.props.CapturesMobx.c_presetPublicCstColumnPresetsArrayOfObjs;
    const myFilters = this.props.CapturesMobx.c_presetMyFilterPresetsArrayOfObjs;
    const pubFilters = this.props.CapturesMobx.c_presetPublicFilterPresetsArrayOfObjs;
    const mySorts = this.props.CapturesMobx.c_presetMySortPresetsArrayOfObjs;
    const pubSorts = this.props.CapturesMobx.c_presetPublicSortPresetsArrayOfObjs;

    var columnPresets = JSFUNC.concat_arrays_or_values_into_new_array(myColumns, pubColumns);
    var filterPresets = JSFUNC.concat_arrays_or_values_into_new_array(myFilters, pubFilters);
    var sortPresets = JSFUNC.concat_arrays_or_values_into_new_array(mySorts, pubSorts);

    JSFUNC.sort_arrayOfObjs(columnPresets, "nameLowercase", true);
    JSFUNC.sort_arrayOfObjs(filterPresets, "nameLowercase", true);
    JSFUNC.sort_arrayOfObjs(sortPresets, "nameLowercase", true);

    const masterPresetFieldNamesArray = ["cst_column_preset_id", "filter_preset_id", "sort_preset_id"];
    const presetTypesArray = ["cstColumns", "filter", "sort"];
    const presetTblNamesArray = ["tbl_f_cst_column_presets", "tbl_f_filter_presets", "tbl_f_sort_presets"];
    const cfsLabelsArray = ["Column", "Filter", "Sort"];
    const selectValuesArrayOfArrays = [JSFUNC.get_column_vector_from_arrayOfObjs(columnPresets, "id"), JSFUNC.get_column_vector_from_arrayOfObjs(filterPresets, "id"), JSFUNC.get_column_vector_from_arrayOfObjs(sortPresets, "id")];
    const selectDisplayArrayOfArrays = [JSFUNC.get_column_vector_from_arrayOfObjs(columnPresets, "name"), JSFUNC.get_column_vector_from_arrayOfObjs(filterPresets, "name"), JSFUNC.get_column_vector_from_arrayOfObjs(sortPresets, "name")];

    return(
      <div className="">
        {cfsLabelsArray.map((m_cfsLabel, m_index) =>
          <PresetEditorOpenMasterPresetSingleCFSPreset
            p_masterPresetFieldName={masterPresetFieldNamesArray[m_index]}
            p_presetType={presetTypesArray[m_index]}
            p_presetTblName={presetTblNamesArray[m_index]}
            p_label={m_cfsLabel}
            p_selectValuesArray={selectValuesArrayOfArrays[m_index]}
            p_selectDisplayArray={selectDisplayArrayOfArrays[m_index]}
          />
        )}
      </div>
    );
  }
}));

const PresetEditorOpenMasterPresetSingleCFSPreset = inject("CapturesMobx", "DatabaseMobx")(observer(
class PresetEditorOpenMasterPresetSingleCFSPreset extends Component { //props: p_masterPresetFieldName, p_presetType, p_presetTblName, p_label, p_selectValuesArray, p_selectDisplayArray
  onselect_cfs_preset_for_master = (i_selectedCFSPresetID) => {
    this.props.CapturesMobx.a_update_preset_field(this.props.CapturesMobx.c_presetEditorOpenPresetObj, this.props.p_masterPresetFieldName, i_selectedCFSPresetID, "i");
  }

  onclick_open_cfs_preset = () => {
    const openMasterPresetObj = this.props.CapturesMobx.c_presetEditorOpenPresetObj;
    this.props.CapturesMobx.a_set_preset_editor_open_preset_type_and_id(this.props.p_presetType, openMasterPresetObj[this.props.p_masterPresetFieldName]);
  }

  render() {
    const masterPresetFieldName = this.props.p_masterPresetFieldName;
    const presetTblName = this.props.p_presetTblName;
    const label = this.props.p_label;
    const selectValuesArray = this.props.p_selectValuesArray;
    const selectDisplayArray = this.props.p_selectDisplayArray;

    const openMasterPresetObj = this.props.CapturesMobx.c_presetEditorOpenPresetObj;
    const selectedValue = openMasterPresetObj[masterPresetFieldName];

    var cfsPresetSelectComponent = null;
    if(openMasterPresetObj.isPresetOwnerCanEditTF) {
      const itemName = label + " Preset";
      const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
      const selectWithSearchDataObj = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array(itemName, selectValuesArray, false, selectDisplayArray, swsOptionsObj);
      const selectCfsPresetFieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj);

      cfsPresetSelectComponent = (
        <CEGeneralReact.GenericInputOrSelectFromInputType
          p_fieldTypeObj={selectCfsPresetFieldTypeObj}
          p_valueRaw={selectedValue}
          f_onChangeOrOnSelect={this.onselect_cfs_preset_for_master}
        />
      );
    }
    else {
      const selectedPresetMap = this.props.DatabaseMobx.tbl_row_map_from_id(presetTblName, selectedValue);
      const valueExistedTF = (selectedPresetMap.get("id") > 0);
      cfsPresetSelectComponent = (
         <font className={((valueExistedTF) ? ("") : ("fontItalic fontTextLighter"))}>
           {selectedPresetMap.get("name")}
         </font>
       );
    }

    return(
      <div className="hugeTopMargin">
        <div className="displayFlexRowVc">
          <div className="flex11a">
            <font className="font13 fontBold fontItalic fontTextLighter">
              {label + " Preset"}
            </font>
          </div>
          <div className="flex00a lrMargin">
            <CEGeneralReact.CEButton
              p_type="add"
              p_text="Open Preset"
              f_onClick={this.onclick_open_cfs_preset}
            />
          </div>
        </div>
        <div className="microTopMargin">
          {cfsPresetSelectComponent}
        </div>
      </div>
    );
  }
}));


const PresetEditorOpenCFSPreset = inject("CapturesMobx")(observer(
class PresetEditorOpenCFSPreset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_addNewColumnsFirst1Last2: 2
    };
  }

  onadd_selected_new_capture_fields = (i_addedCaptureFieldIDsComma) => {
    const s_addNewColumnsFirst1Last2 = this.state.s_addNewColumnsFirst1Last2;
    const openCFSPresetObj = this.props.CapturesMobx.c_presetEditorOpenPresetObj;
    this.props.CapturesMobx.a_cfs_preset_add_capture_field_items(openCFSPresetObj.presetType, openCFSPresetObj.id, i_addedCaptureFieldIDsComma, openCFSPresetObj.numPresetItems, s_addNewColumnsFirst1Last2);
  }

  onselect_add_columns_first_last = (i_newValue12) => {
    this.setState({s_addNewColumnsFirst1Last2:i_newValue12});
  }

  render() {
    const s_addNewColumnsFirst1Last2 = this.state.s_addNewColumnsFirst1Last2;

    const c_presetEditorOpenPresetObj = this.props.CapturesMobx.c_presetEditorOpenPresetObj;
    const c_viewButtonEditorOpenCFSSelectMultiCaptureFieldsFieldTypeObj = this.props.CapturesMobx.c_viewButtonEditorOpenCFSSelectMultiCaptureFieldsFieldTypeObj;

    if(c_presetEditorOpenPresetObj === undefined) {
      return(null);
    }

    var addPresetItemsTopRowComponent = null;
    if(c_presetEditorOpenPresetObj.isPresetOwnerCanEditTF) {
      const addPresetItemsButtonComponent = (
        <CEGeneralReact.CaptureExecFieldAddNewItemsButton
          p_buttonType="blue"
          p_buttonText={"Add " + c_presetEditorOpenPresetObj.presetLabel + "(s)"}
          p_addInstructions={"Select capture fields to add to this " + c_presetEditorOpenPresetObj.presetLabel + " Preset"}
          p_addInstructionsNowrapTF={true}
          p_fieldTypeObj={c_viewButtonEditorOpenCFSSelectMultiCaptureFieldsFieldTypeObj}
          f_onAddSelectedItems={this.onadd_selected_new_capture_fields}
        />
      );

      addPresetItemsTopRowComponent = (
        <div className="displayFlexRow" style={{height:"5.2em"}}>
          <div className="flex11a displayFlexColumnVc lrMedMargin">
            {addPresetItemsButtonComponent}
          </div>
          {(c_presetEditorOpenPresetObj.isCstColumnsTF) &&
            <div className="flex00a displayFlexColumnVc" style={{flexBasis:"7em", marginRight:"1em"}}>
              <div className="flex00a textCenter" title="Insert newly added Capture Field columns first or last to the existing list of columns below for easier sorting">
                <font className="fontItalic fontTextLighter">
                  {"Add Columns:"}
                </font>
              </div>
              <div className="flex00a">
                <CEGeneralReact.VerticalSwitch
                  p_valueArray={[1, 2]}
                  p_displayArray={["First", "Last"]}
                  p_selectedValue={s_addNewColumnsFirst1Last2}
                  p_rowHeightEm={1.5}
                  p_sizeMultiplier={0.7}
                  f_onSelect={this.onselect_add_columns_first_last}
                />
              </div>
            </div>
          }
        </div>
      );
    }
    else {
      addPresetItemsTopRowComponent = (
        <div className="medTopMargin textCenter">
          <font className="font12 fontBold fontItalic fontTextLighter">
            {c_presetEditorOpenPresetObj.presetLabel + "s in this Preset"}
          </font>
        </div>
      );
    }

    var fieldItemsListComponent = null;
    if(c_presetEditorOpenPresetObj.numPresetItems > 0) {
      fieldItemsListComponent = (
        c_presetEditorOpenPresetObj.presetItemsArrayOfObjs.map((m_fieldItemObj, m_index) =>
          <PresetEditorOpenCFSPresetFieldItem
            key={m_fieldItemObj.id}
            p_fieldItemObj={m_fieldItemObj}
            p_isOnlyItemTF={(c_presetEditorOpenPresetObj.numPresetItems === 1)}
            p_isLastItemTF={(m_index === (c_presetEditorOpenPresetObj.numPresetItems - 1))}
          />
        )
      );
    }
    else {
      fieldItemsListComponent = (
        <div>
          <font className="fontItalic fontTextLighter">
            {"No " + c_presetEditorOpenPresetObj.presetLabel + "s have been added to this preset yet"}
          </font>
        </div>
      );
    }

    return(
      <>
        {addPresetItemsTopRowComponent}
        {fieldItemsListComponent}
      </>
    );
  }
}));

const PresetEditorOpenCFSPresetFieldItem = inject("CaptureExecMobx", "CapturesMobx", "DatabaseMobx")(observer(
class PresetEditorOpenCFSPresetFieldItem extends Component { //props: p_fieldItemObj, p_isOnlyItemTF, p_isLastItemTF
  onswitch_cst_column_cst_sum_row = () => {
    const p_fieldItemObj = this.props.p_fieldItemObj;
    const c_presetEditorOpenPresetObj = this.props.CapturesMobx.c_presetEditorOpenPresetObj;
    const updatedCstSumRowValue01 = ((p_fieldItemObj.cst_sum_row_01 === 1) ? (0) : (1));
    this.props.CapturesMobx.a_update_cfs_field_item_field(c_presetEditorOpenPresetObj.presetType, p_fieldItemObj.id, "cst_sum_row_01", updatedCstSumRowValue01, "i");
  }

  onchange_filter_operator_or_values = (i_updatedOperatorValuesObj) => {
    const p_fieldItemObj = this.props.p_fieldItemObj;
    const c_presetEditorOpenPresetObj = this.props.CapturesMobx.c_presetEditorOpenPresetObj;

    var fieldNameOrFieldNamesArray = [];
    var valueOrValuesArray = [];
    var valueIdsbOrIdsbArray = [];

    if(i_updatedOperatorValuesObj.updatedOperator !== undefined) {
      fieldNameOrFieldNamesArray.push("operator");
      valueOrValuesArray.push(i_updatedOperatorValuesObj.updatedOperator);
      valueIdsbOrIdsbArray.push("s");
    }

    if(i_updatedOperatorValuesObj.updatedValueOrValuesString !== undefined) {
      fieldNameOrFieldNamesArray.push("value");
      valueOrValuesArray.push(i_updatedOperatorValuesObj.updatedValueOrValuesString);
      valueIdsbOrIdsbArray.push("s");
    }

    this.props.CapturesMobx.a_update_cfs_field_item_field(c_presetEditorOpenPresetObj.presetType, p_fieldItemObj.id, fieldNameOrFieldNamesArray, valueOrValuesArray, valueIdsbOrIdsbArray);
  }

  onselect_sort_direction = (i_newValue) => {
    const p_fieldItemObj = this.props.p_fieldItemObj;
    const c_presetEditorOpenPresetObj = this.props.CapturesMobx.c_presetEditorOpenPresetObj;
    this.props.CapturesMobx.a_update_cfs_field_item_field(c_presetEditorOpenPresetObj.presetType, p_fieldItemObj.id, "is_asc_01", i_newValue, "i");
  }

  onremove_field_item = () => {
    const p_fieldItemObj = this.props.p_fieldItemObj;
    const c_presetEditorOpenPresetObj = this.props.CapturesMobx.c_presetEditorOpenPresetObj;
    const presetItemsHaveSortTF = (c_presetEditorOpenPresetObj.isCstColumnsTF || c_presetEditorOpenPresetObj.isSortTF);
    this.props.CapturesMobx.a_remove_cfs_field_item_from_preset(c_presetEditorOpenPresetObj.presetType, p_fieldItemObj.id, presetItemsHaveSortTF, c_presetEditorOpenPresetObj.id);
  }

  render() {
    const p_fieldItemObj = this.props.p_fieldItemObj;
    const p_isOnlyItemTF = this.props.p_isOnlyItemTF;
    const p_isLastItemTF = this.props.p_isLastItemTF;

    const c_presetEditorOpenPresetObj = this.props.CapturesMobx.c_presetEditorOpenPresetObj;
    if(c_presetEditorOpenPresetObj === undefined) {
      return(null);
    }

    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;

    const isCstColumnsTF = c_presetEditorOpenPresetObj.isCstColumnsTF;
    const isFilterTF = c_presetEditorOpenPresetObj.isFilterTF;
    const isSortTF = c_presetEditorOpenPresetObj.isSortTF;
    const isPresetOwnerCanEditTF = c_presetEditorOpenPresetObj.isPresetOwnerCanEditTF;
    const canResortRowsTF = (isPresetOwnerCanEditTF && (isCstColumnsTF || isSortTF));

    const fieldDisplayName = p_fieldItemObj.fieldDisplayName;
    const fieldTypeObj = p_fieldItemObj.fieldTypeObj;
    const canCalculateCstSumRowTF = p_fieldItemObj.canCalculateCstSumRowTF;

    var itemContainerHeight = undefined; //fit height of content (used for fitlers)
    var itemNameTextMaxHeight = "2.5em";
    var lastItemExtraDropZoneHeight = undefined; //no drag to resort for filters
    if(isCstColumnsTF) {
      itemContainerHeight = "2em"; //small for columns because there's usually 30+ and resorting is difficult when scrolling
      itemNameTextMaxHeight = "1.7em";
      lastItemExtraDropZoneHeight = "2em";
    }
    else if(isSortTF) {
      itemContainerHeight = "2.8em"; //sort needs to fit the Asc/Desc select box
      itemNameTextMaxHeight = "2.5em";
      lastItemExtraDropZoneHeight = "2.8em";
    }

    //filter sum row switch edit/view
    var columnSumRowSwitchComponent = null;
    if(isCstColumnsTF && canCalculateCstSumRowTF) {
      columnSumRowSwitchComponent = (
        <div
          className="flex00a displayFlexRow borderL1ddd lrPad"
          style={{flexBasis:"6em"}}
          title="Use this switch to display a calculated sum of this column's data under the column header in the Capture Summary Table">
          <div className="flex00a displayFlexColumnVc">
            <font className="fontItalic fontTextLighter">
              {"sum:"}
            </font>
          </div>
          <div className="flex11a displayFlexColumnVc lrPad">
            <LibraryReact.Switch
              p_isOnTF={(p_fieldItemObj.cst_sum_row_01 === 1)}
              p_sizeEm={2.4}
              p_onColor="090"
              f_onClick={this.onswitch_cst_column_cst_sum_row}
            />
          </div>
        </div>
      );
    }

    //sort direction edit/view
    var sortDirectionComponent = null;
    if(isSortTF) {
      const sortedAscTF = (p_fieldItemObj.is_asc_01 === 1);

      var sortDirectionContainerWidthEm = 10;
      var selectOrDisplaySortDirectionComponent = null;
      if(isPresetOwnerCanEditTF) {
        sortDirectionContainerWidthEm = 12.5;

        const valueArray = [1, 0];
        const displayArray = ["\u25bc Asc", "\u25b2 Desc"];
        const swsOptionsObj = undefined;
        const selectWithSearchDataObj = this.props.DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Asc/Desc", valueArray, false, displayArray, swsOptionsObj);
        const selectAscDescFieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj);

        selectOrDisplaySortDirectionComponent = (
          <div className="lrPad">
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={selectAscDescFieldTypeObj}
              p_valueRaw={p_fieldItemObj.is_asc_01}
              p_title="Choose whether this Capture Summary Table column sorts ascending (Asc) or descending (Desc)"
              f_onChangeOrOnSelect={this.onselect_sort_direction}
            />
          </div>
        );
      }
      else {
        selectOrDisplaySortDirectionComponent = (
          <font>
            {((sortedAscTF) ? ("Asc") : ("Desc"))}
          </font>
        );
      }

      sortDirectionComponent = (
        <div className="flex00a displayFlexRowVc borderL1ddd" style={{flexBasis:sortDirectionContainerWidthEm + "em"}}>
          <div className="flex00a lrPad">
            <font className="fontItalic fontTextLighter">
              {"direction:"}
            </font>
          </div>
          {(!isPresetOwnerCanEditTF) &&
            <div className="flex00a" style={{flexBasis:"1.3em"}}>
              <CEGeneralReact.HeaderSortArrow
                p_isSortedTF={true}
                p_sortedAscTF={sortedAscTF}
              />
            </div>
          }
          <div className="flex11a">
            {selectOrDisplaySortDirectionComponent}
          </div>
        </div>
      );
    }

    return(
      <CEGeneralReact.CEDragToResortItemWithinDbTbl
        p_canResortTF={canResortRowsTF}
        p_uniqueString="presetItem"
        p_itemID={p_fieldItemObj.id}
        p_itemSort={((isCstColumnsTF || isSortTF) ? (p_fieldItemObj.sort) : (undefined))} //filters do not have a sort field, set it to undefined so that the sort is not drawn
        p_itemSortColumnWidth={undefined}
        p_itemSortNumberFontClass={undefined}
        p_isOnlyItemTF={p_isOnlyItemTF}
        p_isLastItemTF={p_isLastItemTF}
        p_outerPadClass="lrPad microTopPad microBottomPad"
        p_itemClass="border bevelBorderColors borderRadius10 bgAlmostWhite hoverLighterBlueGradient"
        p_itemStyleObj={{height:itemContainerHeight}}
        p_lastItemExtraDropZoneHeight={lastItemExtraDropZoneHeight}
        p_tblName={c_presetEditorOpenPresetObj.presetItemsTblName}
        p_tblSortFieldDbName="sort"
        p_filterFieldNamesArray={[c_presetEditorOpenPresetObj.itemsPresetIDColumnName]}
        p_filterValuesArray={[c_presetEditorOpenPresetObj.id]}>
        <div className="flex11a displayFlexRowVc lrMedPad">
          <LibraryReact.MaxHeightWrap p_maxHeight={itemNameTextMaxHeight} p_fontClass="fontBold fontItalic fontTextLight">
            {fieldDisplayName}
          </LibraryReact.MaxHeightWrap>
        </div>
        {columnSumRowSwitchComponent}
        {(isFilterTF) &&
          <div className="flex00a borderL1ddd lrPad" style={{flexBasis:((c_isMobileOrTabletTF) ? ("17em") : ("27em"))}}>
            <SingleCaptureFieldOperatorValuesSelector
              p_fieldTypeObj={fieldTypeObj}
              p_selectedOperator={p_fieldItemObj.operator}
              p_selectedValueOrValuesString={p_fieldItemObj.value}
              p_canEditTF={isPresetOwnerCanEditTF}
              f_onChangeOperatorOrValues={this.onchange_filter_operator_or_values}
            />
          </div>
        }
        {sortDirectionComponent}
        {(isPresetOwnerCanEditTF) &&
          <div className="flex00a borderL1ddd displayFlexColumnHcVc" style={{flexBasis:"2.4em"}}>
            <CEGeneralReact.RemoveItemButton
              p_title={"Remove item '" + fieldDisplayName + "' from this " + c_presetEditorOpenPresetObj.presetLabel + " Preset"}
              f_onClick={this.onremove_field_item}
            />
          </div>
        }
      </CEGeneralReact.CEDragToResortItemWithinDbTbl>
    );
  }
}));


const SingleCaptureFieldOperatorValuesSelector = inject("CapturesMobx", "DatabaseMobx")(observer(
class SingleCaptureFieldOperatorValuesSelector extends Component { //props: p_fieldTypeObj, p_selectedOperator, p_selectedValueOrValuesString, p_canEditTF, f_onChangeOperatorOrValues
  onselect_filter_operator = (i_newValue) => { //when changing the operator, the value(s) are reset to blank so that you can't switch an "equals" with 3 ORs to "greater than", which shouldn't allow more than 1 item (but it does allow switching between all of the lt/gt without reset)
    const p_selectedOperator = this.props.p_selectedOperator;

    var updatedOperatorValuesObj = {};
    if(JSFUNC.in_array(p_selectedOperator, ["gt","gte","lt","lte"]) && JSFUNC.in_array(i_newValue, ["gt","gte","lt","lte"])) { //keep the selected value if switching between lt/gt operators
      updatedOperatorValuesObj = {updatedOperator:i_newValue};
    }
    else { //reset the value when switching operator
      updatedOperatorValuesObj = {updatedOperator:i_newValue, updatedValueOrValuesString:""};
    }

    if(JSFUNC.is_function(this.props.f_onChangeOperatorOrValues)) {
      this.props.f_onChangeOperatorOrValues(updatedOperatorValuesObj);
    }
  }

  onadd_filter_item_single_value = (i_newValuesComma) => {
    const p_selectedValueOrValuesString = this.props.p_selectedValueOrValuesString;

    const combinedValuesComma = JSFUNC.add_value_to_comma_list(i_newValuesComma, p_selectedValueOrValuesString);
    const updatedOperatorValuesObj = {updatedValueOrValuesString:combinedValuesComma};

    if(JSFUNC.is_function(this.props.f_onChangeOperatorOrValues)) {
      this.props.f_onChangeOperatorOrValues(updatedOperatorValuesObj);
    }
  }

  render() {
    const p_fieldTypeObj = this.props.p_fieldTypeObj;
    const p_selectedOperator = this.props.p_selectedOperator;
    const p_selectedValueOrValuesString = this.props.p_selectedValueOrValuesString;
    const p_canEditTF = this.props.p_canEditTF;

    const c_selectOperatorFieldTypeObj = this.props.DatabaseMobx.c_selectOperatorFieldTypeObj; //select from "e", "ne", "gt", "gte", "lt", "lte", "c", "dnc", "is", "ins" values

    //if the field type is a textarea, force it to change to a text so that the edit is not a floating box, if the operator is contains "c" or does not contain "dnc", also force the edit to a text input
    var filterEditingValuesFieldTypeObj = undefined;
    if(JSFUNC.in_array(p_selectedOperator, ["c", "dnc"])) {
      filterEditingValuesFieldTypeObj = this.props.DatabaseMobx.create_field_type_obj("text");
    }
    else {
      filterEditingValuesFieldTypeObj = p_fieldTypeObj;
    }

    //filter item value is a comma list of either int or string values, convert that to an array and mask each raw value
    var valuesArray = undefined;
    if(filterEditingValuesFieldTypeObj.valueRawIsNumericTF || filterEditingValuesFieldTypeObj.selectTF) { //int array
      valuesArray = JSFUNC.convert_comma_list_to_int_array(p_selectedValueOrValuesString);
    }
    else { //string array
      valuesArray = JSFUNC.convert_comma_list_to_array(p_selectedValueOrValuesString);
    }
    const numValues = valuesArray.length;

    //mask the raw values
    var valueMasksArray = [];
    var valueMasksPlainTextArray = [];
    for(let valueRaw of valuesArray) {
      var valueMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(valueRaw, filterEditingValuesFieldTypeObj);
      valueMasksArray.push(valueMaskSortIfoObj.valueMask);
      valueMasksPlainTextArray.push(valueMaskSortIfoObj.valueMaskPlainText);
    }

    //operator select
    var operatorSelectComponent = null;
    if(p_canEditTF) {
      //determine which operators are available to choose based on the selected field type
      const filterPresetFieldOperatorSelectChoicesObj = this.props.CapturesMobx.filter_preset_field_operator_select_choices_obj_from_field_type_obj(p_fieldTypeObj);

      operatorSelectComponent = (
        <div className="smallFullMargin">
          <CEGeneralReact.GenericInputOrSelectFromInputType
            p_fieldTypeObj={c_selectOperatorFieldTypeObj}
            p_valueRaw={p_selectedOperator}
            p_valuesToNotIncludeArray={filterPresetFieldOperatorSelectChoicesObj.operatorValuesToNotIncludeArray}
            p_title="Choose an operator for how this capture field is related to its value(s)"
            f_onChangeOrOnSelect={this.onselect_filter_operator}
          />
        </div>
      );
    }
    else {
      operatorSelectComponent = (
        <div className="microBottomMargin textCenter">
          <font className="font11 fontBold fontItalic">
            {this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(p_selectedOperator, c_selectOperatorFieldTypeObj)}
          </font>
        </div>
       );
    }

    //add values
    var addedValuesListComponent = null;
    if(numValues === 0) {
      var zeroValuesMessage = "--No values have been added to this filter yet--";
      if(p_selectedOperator === "is") {
        zeroValuesMessage = "This filter will find all captures where this field is set";
      }
      else if(p_selectedOperator === "ins") {
        zeroValuesMessage = "This filter will find all captures where this field is not set or has a selected value that does not exist";
      }

      addedValuesListComponent = (
        <div className="textCenter">
          <font className="fontItalic fontTextLighter">
            {zeroValuesMessage}
          </font>
        </div>
      );
    }
    else {
      addedValuesListComponent = (
        valuesArray.map((m_valueRaw, m_index) =>
          <FilterPresetFieldItemSingleValue
            p_canEditTF={p_canEditTF}
            p_valueRaw={m_valueRaw}
            p_valueMask={valueMasksArray[m_index]}
            p_valueMaskPlainText={valueMasksPlainTextArray[m_index]}
            p_isLastValueTF={(m_index === (numValues - 1))}
            p_fieldAllValuesArray={valuesArray}
            f_onChangeValues={this.props.f_onChangeOperatorOrValues}
          />
        )
      );
    }

    //add more values button
    var canAddMoreValuesTF = false;
    if(p_canEditTF) { //can't add values if this cannot be edited
      if(!JSFUNC.in_array(p_selectedOperator, ["is", "ins"])) { //can't add values to Is Set or Is Not Set
        if(JSFUNC.in_array(p_selectedOperator, ["e", "ne", "c", "dnc"])) {
          canAddMoreValuesTF = true; //can always add more values to equals, not equals, and contains that are ORed together
        }
        else { //for gt, gte, lt, lte, only 1 value max can be added, so can only add a value if there are currently 0
          canAddMoreValuesTF = (numValues === 0);
        }
      }
    }

    var addFilterValuesButtonComponent = null;
    if(canAddMoreValuesTF) {
      addFilterValuesButtonComponent = (
        <div className="tbMargin">
          <CEGeneralReact.CaptureExecFieldAddNewItemsButton
            p_buttonType="add"
            p_buttonText="Add Filter Value(s)"
            p_addInstructions="Add value(s) to this filter field"
            p_fieldTypeObj={filterEditingValuesFieldTypeObj}
            p_valuesToNotIncludeArray={valuesArray}
            f_onAddSelectedItems={this.onadd_filter_item_single_value}
          />
        </div>
      );
    }

    return(
      <>
        {operatorSelectComponent}
        {addedValuesListComponent}
        {addFilterValuesButtonComponent}
      </>
    );
  }
}));



class FilterPresetFieldItemSingleValue extends Component { //props: p_canEditTF, p_valueRaw, p_valueMask, p_valueMaskPlainText, p_isLastValueTF, p_fieldAllValuesArray, f_onChangeValues
  onremove_filter_single_value = () => {
    const p_valueRaw = this.props.p_valueRaw;
    const p_fieldAllValuesArray = this.props.p_fieldAllValuesArray;

    const allValuesWithSingleValueRemovedArray = JSFUNC.remove_all_values_from_array(p_valueRaw, p_fieldAllValuesArray);
    const allValuesWithSingleValueRemovedComma = JSFUNC.convert_array_to_comma_list(allValuesWithSingleValueRemovedArray);
    const updatedOperatorValuesObj = {updatedValueOrValuesString:allValuesWithSingleValueRemovedComma};

    if(JSFUNC.is_function(this.props.f_onChangeValues)) {
      this.props.f_onChangeValues(updatedOperatorValuesObj);
    }
  }

  render() {
    const p_canEditTF = JSFUNC.prop_value(this.props.p_canEditTF, false);
    const p_valueRaw = this.props.p_valueRaw;
    const p_valueMask = this.props.p_valueMask;
    const p_valueMaskPlainText = this.props.p_valueMaskPlainText;
    const p_isLastValueTF = JSFUNC.prop_value(this.props.p_isLastValueTF, false);
    const p_fieldAllValuesArray = this.props.p_fieldAllValuesArray;

    return(
      <div className="displayFlexRowVc microTopMargin microBottomMargin">
        {(p_canEditTF) &&
          <div className="flex00a displayFlexRowVc" style={{flexBasis:"2em"}}>
            <CEGeneralReact.RemoveItemButton
              p_title={"Remove value '" + p_valueMaskPlainText + "' from this filter"}
              p_heightEm={0.5}
              p_fontClass="font09"
              f_onClick={this.onremove_filter_single_value}
            />
          </div>
        }
        <div className="flex11a">
          {p_valueMask}
        </div>
        {(!p_isLastValueTF) &&
          <div className="flex00a lrMargin">
            <font className="fontItalic fontTextLighter">
              {"or"}
            </font>
          </div>
        }
      </div>
    );
  }
}







//-------------------------------------------------------------------------------------------------------------------------------------
//Gantt Preset Editor
//-------------------------------------------------------------------------------------------------------------------------------------
const GanttPresetEditorLeftPresetsList = inject("CapturesMobx", "DatabaseMobx")(observer(
class GanttPresetEditorLeftPresetsList extends Component {
  oncreate_new_gantt_preset_with_name = (i_newGanttPresetName) => {
    this.props.CapturesMobx.a_create_new_gantt_preset(i_newGanttPresetName);
  }

  render() {
    const myPresetsArrayOfObjs = this.props.CapturesMobx.c_ganttDatesPresetMyPresetsArrayOfObjs;
    const publicPresetsArrayOfObjs = this.props.CapturesMobx.c_ganttDatesPresetPublicPresetsArrayOfObjs;

    const presetItemsIndent = "0.4em";
    return(
      <div className="flex11a yScroll yScrollBottomPad lrPad" style={{backgroundColor:"#f8f8f8"}}>
        <div className="medTopMargin medBottomMargin">
          <CEGeneralReact.CaptureExecFieldAddNewItemsButton
            p_buttonType="blue"
            p_buttonText="Create New Gantt Preset"
            p_addInstructions="Enter a name for the new preset"
            p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
            f_onAddSelectedItems={this.oncreate_new_gantt_preset_with_name}
          />
        </div>
        <div className="">
          <font className="font12 fontBold fontTextLighter">
            {"Gantt Date Presets"}
          </font>
        </div>
        <div className="smallTopMargin" style={{marginLeft:presetItemsIndent}}>
          <PresetEditorPresetTypeSubHeading p_label={"My Presets (" + myPresetsArrayOfObjs.length + ")"} />
          {myPresetsArrayOfObjs.map((m_ganttPresetObj) =>
            <GanttPresetItem
              key={m_ganttPresetObj.id}
              p_ganttPresetObj={m_ganttPresetObj}
            />
          )}
          <div className="hugeTopMargin" />
          <PresetEditorPresetTypeSubHeading p_label={"Public Presets (" + publicPresetsArrayOfObjs.length + ")"} />
          {publicPresetsArrayOfObjs.map((m_ganttPresetObj) =>
            <GanttPresetItem
              key={m_ganttPresetObj.id}
              p_ganttPresetObj={m_ganttPresetObj}
            />
          )}
        </div>
      </div>
    );
  }
}));

const GanttPresetItem = inject("CapturesMobx")(observer(
class GanttPresetItem extends Component { //props: p_ganttPresetObj
  onclick_open_gantt_preset_in_editor = () => {
    const p_ganttPresetObj = this.props.p_ganttPresetObj;
    this.props.CapturesMobx.a_set_gantt_dates_preset_editor_open_preset_id(p_ganttPresetObj.id);
    this.props.CapturesMobx.a_select_gantt_dates_preset(p_ganttPresetObj.id);
  }

  ondelete_gantt_preset = () => {
    const p_ganttPresetObj = this.props.p_ganttPresetObj;
    this.props.CapturesMobx.a_delete_gantt_preset(p_ganttPresetObj.id);
  }

  render() {
    const p_ganttPresetObj = this.props.p_ganttPresetObj;
    const o_ganttDatesOpenPresetID = this.props.CapturesMobx.o_ganttDatesOpenPresetID;

    var rowBgClass = "bgAlmostWhite";
    if(p_ganttPresetObj.id === o_ganttDatesOpenPresetID) {
      rowBgClass = "bgCaptureItemEditing";
    }

    return(
      <div
        className={"displayFlexRowVc microTopMargin border bevelBorderColors hoverLighterBlueGradient cursorPointer " + rowBgClass}
        style={{height:"3em"}}
        onClick={this.onclick_open_gantt_preset_in_editor}>
        <div className="flex11a lrMargin">
          <LibraryReact.MaxHeightWrap p_maxHeight="2.5em" p_fontClass="font12 fontTextLight">
            {p_ganttPresetObj.name}
          </LibraryReact.MaxHeightWrap>
        </div>
        {(p_ganttPresetObj.isPresetOwnerCanEditTF) &&
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"3em"}}>
            <CEGeneralReact.DeleteMenu
              p_menuItemText="Delete"
              p_message={"Are you sure you want to delete Gantt date preset '" + p_ganttPresetObj.name + "'"}
              f_onDelete={this.ondelete_gantt_preset}
            />
          </div>
        }
      </div>
    );
  }
}));

const GanttPresetEditorRightOpenPreset = inject("CapturesMobx", "DatabaseMobx", "UserMobx")(observer(
class GanttPresetEditorRightOpenPreset extends Component {
  onclick_confirm_create_copied_gantt_dates_preset = (i_newPresetName) => {
    this.props.CapturesMobx.a_copy_gantt_dates_preset_with_new_name(this.props.CapturesMobx.c_ganttPresetEditorOpenPresetObj, i_newPresetName);
  }

  onclick_close_open_gantt_preset = () => {
    this.props.CapturesMobx.a_set_gantt_dates_preset_editor_open_preset_id(undefined);
  }

  onsave_new_preset_name = (i_newPresetName) => {
    const c_ganttPresetEditorOpenPresetObj = this.props.CapturesMobx.c_ganttPresetEditorOpenPresetObj;
    this.props.CapturesMobx.a_update_gantt_preset_field(c_ganttPresetEditorOpenPresetObj.id, "name", i_newPresetName, "s");
  }

  onclick_gantt_preset_public_switch = () => {
    const c_ganttPresetEditorOpenPresetObj = this.props.CapturesMobx.c_ganttPresetEditorOpenPresetObj;
    const newPublic01Value = ((c_ganttPresetEditorOpenPresetObj.public_01 === 1) ? (0) : (1));
    this.props.CapturesMobx.a_update_gantt_preset_field(c_ganttPresetEditorOpenPresetObj.id, "public_01", newPublic01Value, "i");
  }

  onadd_selected_new_date_fields = (i_newDateFieldIDsComma) => {
    const c_ganttPresetEditorOpenPresetObj = this.props.CapturesMobx.c_ganttPresetEditorOpenPresetObj;
    this.props.CapturesMobx.a_add_dates_to_gantt_preset(c_ganttPresetEditorOpenPresetObj.id, i_newDateFieldIDsComma);
  }

  render() {
    const o_ganttDatesOpenPresetID = this.props.CapturesMobx.o_ganttDatesOpenPresetID;
    const c_ganttPresetEditorOpenPresetObj = this.props.CapturesMobx.c_ganttPresetEditorOpenPresetObj;
    const c_ganttPresetEditorSelectMultiCaptureDateFieldsFieldTypeObj = this.props.DatabaseMobx.c_ganttPresetEditorSelectMultiCaptureDateFieldsFieldTypeObj;
    const c_userCanCreatePublicItemsTF = this.props.UserMobx.c_userCanCreatePublicItemsTF;

    if(o_ganttDatesOpenPresetID === undefined) {
      return(
        <CEGeneralReact.EmptyScreenGray>
          {"Select a Gantt Preset to view/edit its components"}
        </CEGeneralReact.EmptyScreenGray>
      );
    }

    if(c_ganttPresetEditorOpenPresetObj === undefined) {
      return(
        <CEGeneralReact.EmptyScreenGray>
          {"--Gantt Preset Does Not Exist (ID: " + o_ganttDatesOpenPresetID + ")--"}
        </CEGeneralReact.EmptyScreenGray>
      );
    }

    const ganttDatesPresetMenuItemsArrayOfObjs = [
      {
        displayName: "Copy this as a new Gantt Dates Preset",
        confirmType: "inputText",
        confirmTitle: "Copy Gantt Dates Preset '" + c_ganttPresetEditorOpenPresetObj.name + "'",
        confirmTextInitialValue: "Copy of " + c_ganttPresetEditorOpenPresetObj.name,
        confirmButton1Name: "Create Copy",
        confirmTextMustBeChangedForSaveTFU: false,
        confirmMessage: "Enter a new name for this copied Gantt Dates Preset",
        functionOnClickConfirmButton: this.onclick_confirm_create_copied_gantt_dates_preset
      }
    ];

    const containerClass = "medTopMargin lrMargin";
    const fieldClass = "fontBlue";
    const fieldWidth = "7em";

    const editSaveCancelOwnerComponent = (
      <CEGeneralReact.CaptureExecFieldEditSaveCancel
        p_ceEditItemString={"ganttPresetID" + c_ganttPresetEditorOpenPresetObj.id + "owner"}
        p_fieldDisplayName="Owner"
        p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
        p_valueRaw={c_ganttPresetEditorOpenPresetObj.user_id}
        p_valueMask={this.props.DatabaseMobx.user_name_mask_from_user_id(c_ganttPresetEditorOpenPresetObj.user_id)}
        p_valueIsEditableTFU={false}
        p_containerClass={containerClass}
        p_fieldClass={fieldClass}
        p_fieldWidth={fieldWidth}
        p_fieldValueVerticallyAlignedTF={true}
        f_onSaveChanged={undefined}
      />
    );

    const editSaveCancelPresetNameComponent = (
      <CEGeneralReact.CaptureExecFieldEditSaveCancel
        p_ceEditItemString={"ganttPresetID" + c_ganttPresetEditorOpenPresetObj.id + "name"}
        p_fieldDisplayName="Preset Name"
        p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
        p_valueRaw={c_ganttPresetEditorOpenPresetObj.name}
        p_valueIsEditableTFU={c_ganttPresetEditorOpenPresetObj.isPresetOwnerCanEditTF}
        p_containerClass={containerClass}
        p_fieldClass={fieldClass}
        p_fieldWidth={fieldWidth}
        p_fieldValueVerticallyAlignedTF={true}
        f_onSaveChanged={this.onsave_new_preset_name}
      />
    );

    return(
      <div className="flex11a displayFlexColumn" style={{backgroundColor:"#f3f6fc"}}>
        <div className="flex00a displayFlexRowVc borderB1bbb">
          <div className="flex11a lrMargin">
            <LibraryReact.Nowrap p_fontClass="font13 fontBold fontTextLighter">
              {"Preset '" + c_ganttPresetEditorOpenPresetObj.name + "'"}
            </LibraryReact.Nowrap>
          </div>
          <div className="flex00a displayFlexColumnHcVc borderR1bbb" style={{flexBasis:"2em"}}>
            <CEGeneralReact.VerticalDotsMenu
              p_menuItemsArrayOfObjs={ganttDatesPresetMenuItemsArrayOfObjs}
            />
          </div>
          <div className="flex00a tbPad lrMedPad">
            <CEGeneralReact.CEButton
              p_type="gray"
              p_text="Close"
              f_onClick={this.onclick_close_open_gantt_preset}
            />
          </div>
        </div>
        <div className="flex11a yScroll yScrollBottomPad lrPad">
          {editSaveCancelOwnerComponent}
          {editSaveCancelPresetNameComponent}
          {(c_ganttPresetEditorOpenPresetObj.isPresetOwnerCanEditTF && c_userCanCreatePublicItemsTF) &&
            <div className="displayFlexRowVc medTopMargin medBottomMargin lrMargin">
              <div className="flex00a" style={{flexBasis:fieldWidth}}>
                <font className={fieldClass}>
                  {"Is Public?"}
                </font>
              </div>
              <div className="flex11a">
                <CEGeneralReact.SwitchWithTextAndConfirmBox
                  p_isOnTF={(c_ganttPresetEditorOpenPresetObj.public_01 === 1)}
                  p_sizeEm={3}
                  p_onText="Public to everyone"
                  p_offText="Private to me"
                  p_title="Turn on to make your created Gantt preset public to all users in your company"
                  f_onSwitch={this.onclick_gantt_preset_public_switch}
                />
              </div>
            </div>
          }
          {(c_ganttPresetEditorOpenPresetObj.isPresetOwnerCanEditTF) ? (
            <div className="medFullMargin">
              <CEGeneralReact.CaptureExecFieldAddNewItemsButton
                p_buttonType="blue"
                p_buttonText="Add Date(s)"
                p_addInstructions="Select capture date fields to add to this Gantt Dates Preset"
                p_fieldTypeObj={c_ganttPresetEditorSelectMultiCaptureDateFieldsFieldTypeObj}
                p_valuesToNotIncludeArray={c_ganttPresetEditorOpenPresetObj.addedDateFieldIDsArray}
                f_onAddSelectedItems={this.onadd_selected_new_date_fields}
              />
            </div>
          ) : (
            <div className="medTopMargin medBottomMargin textCenter">
              <font className="font12 fontBold fontItalic fontTextLighter">
                {"Dates in this Preset"}
              </font>
            </div>
          )}
          {(c_ganttPresetEditorOpenPresetObj.numAddedDates === 0) ? (
            <div>
              <font className="fontItalic fontTextLighter">
                {"No dates have been added to this preset yet"}
              </font>
            </div>
          ) : (
            c_ganttPresetEditorOpenPresetObj.ganttDatesArrayOfObjs.map((m_ganttDateObj) =>
              <GanttPresetEditorDateItem
                key={m_ganttDateObj.id}
                p_ganttDateObj={m_ganttDateObj}
                p_isPresetOwnerCanEditTF={c_ganttPresetEditorOpenPresetObj.isPresetOwnerCanEditTF}
              />
            )
          )}
        </div>
      </div>
    );
  }
}));

const GanttPresetEditorDateItem = inject("CapturesMobx")(observer(
class GanttPresetEditorDateItem extends Component { //props: p_ganttDateObj, p_isPresetOwnerCanEditTF
  onremove_date_item = () => {
    this.props.CapturesMobx.a_delete_gantt_date_from_preset(this.props.p_ganttDateObj.id);
  }

  onchange_color = (i_newColor) => {
    this.props.CapturesMobx.a_update_gantt_date_color(this.props.p_ganttDateObj.id, i_newColor);
  }

  render() {
    const ganttDateObj = this.props.p_ganttDateObj;
    const isPresetOwnerCanEditTF = JSFUNC.prop_value(this.props.p_isPresetOwnerCanEditTF, false);

    return(
      <div className="displayFlexRowVc tbMargin lrMargin border bevelBorderColors" style={{height:"3em", backgroundColor:"#fff", borderRadius:"1em"}}>
        <div className="flex11a lrMedMargin">
          <LibraryReact.MaxHeightWrap p_maxHeight="2.5em" p_fontClass="fontBold fontItalic fontTextLight">
            {ganttDateObj.fieldDisplayName}
          </LibraryReact.MaxHeightWrap>
        </div>
        <div className="flex00a rMargin">
          {(isPresetOwnerCanEditTF) ? (
            <LibraryReact.Color
              p_value={ganttDateObj.color}
              p_styleObj={{width:"7em", height:"2.2em"}}
              p_title={"Change the color for date '" + ganttDateObj.fieldDisplayName + "'"}
              f_onChange={this.onchange_color}
            />
          ) : (
            <div className="rMargin" style={{width:"6em", height:"2em", backgroundColor:"#" + ganttDateObj.color}} />
          )}
        </div>
        {(isPresetOwnerCanEditTF) &&
          <div className="flex00a" style={{flexBasis:"3em"}}>
            <CEGeneralReact.RemoveItemButton
              p_title={"Remove date '" + ganttDateObj.fieldDisplayName + "' from this Gantt preset"}
              f_onClick={this.onremove_date_item}
            />
          </div>
        }
      </div>
    );
  }
}));


function PresetEditorPresetTypeSubHeading(props) { //props: p_label
  return(
    <div className="smallBottomMargin">
      <font className="fontItalic fontTextLight">
        {props.p_label}
      </font>
    </div>
  );
}








//======================================================================================================================================
//======================================================================================================================================
//Create New Capture
//======================================================================================================================================
//======================================================================================================================================

const CreateNewCaptureFloatingBox = inject("CapturesMobx")(observer(
class CreateNewCaptureFloatingBox extends Component {
  oncancel_create_new_capture = () => {
    this.props.CapturesMobx.a_set_create_new_capture_is_open_tf(false);
  }

  render() {
    if(!this.props.CapturesMobx.o_createNewCaptureIsOpenTF) {
      return(null);
    }

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="contactsSystemSelect"
        p_title="Create a New Capture"
        p_zIndex={1}
        f_onClickCancel={this.oncancel_create_new_capture}>
        <CreateNewCapture />
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));

const CreateNewCapture = inject("CaptureExecMobx", "AdminIntegrationsMobx", "CapturesMobx", "OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class CreateNewCapture extends Component {
  constructor(props) {
    super(props);

    const o_userID = this.props.UserMobx.o_userID;
    const c_combinedUserObj = this.props.UserMobx.c_combinedUserObj;

    var newCaptureObj = {
      added_date: JSFUNC.now_date(),
      opportunity_name: "",
      codename: "",
      capture_managers_ids_colon_percent_comma: o_userID + ":100", //yourself as full ownership
      division_owners_ids_colon_percent_comma: c_combinedUserObj.division_id + ":100", //your division as full ownership
      capture_type_id: -1,
      stage_id: -1,
      prime_contact_company_id: -1,
      contract_type_id: -1,
      contract_overall_value: 0,
      idiq_capture_id_TO_link: -1,
      period_of_performance: 0,
      additionalFieldsRawValuesObj: undefined //admin defined additional fields card on Create New Capture form for a specific selected Capture Type, initialized undefined for now, holds each field per capture type, is converted into individual fields in this obj when new capture is being created
    };

    this.state = {
      s_newCaptureObj: newCaptureObj,
      s_showErrorsTF: false, //set to true the first time save is pushed, if there are errors from incomplete data, it will now be shown
      s_showCreateButtonErrorMessageTF: false //true when save is pushed with errors, turned false when any field is edited
    }
  }

  componentDidMount() {
    const c_createNewCaptureAdminDefaultCaptureTypeIDOrM1 = this.props.DatabaseMobx.c_createNewCaptureAdminDefaultCaptureTypeIDOrM1;
    this.onchange_capture_type(c_createNewCaptureAdminDefaultCaptureTypeIDOrM1);
  }

  onchange_opportunity_name = (i_newValue) => {
    this.update_new_capture_field("opportunity_name", i_newValue);

    const updatedOpportunityNameLowercase = i_newValue.toLowerCase();
    this.props.CapturesMobx.a_set_create_new_capture_opportunity_name_lowercase(updatedOpportunityNameLowercase);
  }

  onchange_codename = (i_newValue) => { this.update_new_capture_field("codename", i_newValue); }
  onchange_capture_managers = (i_newValue) => { this.update_new_capture_field("capture_managers_ids_colon_percent_comma", i_newValue); }
  onchange_division_owners = (i_newValue) => { this.update_new_capture_field("division_owners_ids_colon_percent_comma", i_newValue); }
  onchange_capture_type = (i_newValue) => {
    const o_tbl_a_create_new_capture_additional_fields = this.props.DatabaseMobx.o_tbl_a_create_new_capture_additional_fields;
    const c_tbl_a_capture_types_details_fields = this.props.DatabaseMobx.c_tbl_a_capture_types_details_fields;
    const c_fieldMapOfCaptureType = this.props.DatabaseMobx.c_fieldMapOfCaptureType;
    const c_fieldMapOfContractType = this.props.DatabaseMobx.c_fieldMapOfContractType;
    const c_fieldMapOfContractOverallValue = this.props.DatabaseMobx.c_fieldMapOfContractOverallValue;
    const c_fieldMapOfIdiqCaptureIDTOLink = this.props.DatabaseMobx.c_fieldMapOfIdiqCaptureIDTOLink;
    const c_fieldMapOfPeriodOfPerformance = this.props.DatabaseMobx.c_fieldMapOfPeriodOfPerformance;

    this.update_new_capture_field("capture_type_id", i_newValue);

    //updating the capture type resets the list of available stage choices, pick the first stage in this new capture type process, or -1 if there are 0 stages
    const [stageIDsInArray, stageIDsNotInArray] = this.get_stage_ids_array_in_and_not_in_selected_capture_type();
    var setFirstStageID = -1;
    if(stageIDsInArray.length > 0) {
      setFirstStageID = stageIDsInArray[0]; //get first stageID in this capture type process
    }
    this.update_new_capture_field("stage_id", setFirstStageID);
    this.update_new_capture_field("prime_contact_company_id", -1);

    //updating the capture type also looks up the default values for Contract Type and PoP and changes them if they are set
    var updatedContractTypeID = -1; //none selected if default value not found for this capture type
    const contractTypeDetailsFieldMap = JSFUNC.get_first_map_matching_field_value(c_tbl_a_capture_types_details_fields, ["capture_type_id", "field_id"], [i_newValue, c_fieldMapOfContractType.get("id")]);
    if(contractTypeDetailsFieldMap !== undefined) {
      const contractTypeFieldTypeObj = contractTypeDetailsFieldMap.get("fieldTypeObj");
      const contractTypeUseDefaultTF = contractTypeDetailsFieldMap.get("useDefaultTF");
      const contractTypeDefaultValue = contractTypeDetailsFieldMap.get("default_value");
      if(contractTypeUseDefaultTF) {
        updatedContractTypeID = this.props.DatabaseMobx.int_decimal_or_string_value_raw_from_string_value_raw_and_field_type_obj(contractTypeDefaultValue, contractTypeFieldTypeObj);
      }
    }
    this.update_new_capture_field("contract_type_id", updatedContractTypeID);

    var updatedContractOverallValue = 0; //$0 if default value not found for this capture type
    const contractOverallValueDetailsFieldMap = JSFUNC.get_first_map_matching_field_value(c_tbl_a_capture_types_details_fields, ["capture_type_id", "field_id"], [i_newValue, c_fieldMapOfContractOverallValue.get("id")]);
    if(contractOverallValueDetailsFieldMap !== undefined) {
      const contractOverallValueFieldTypeObj = contractOverallValueDetailsFieldMap.get("fieldTypeObj");
      const contractOverallValueUseDefaultTF = contractOverallValueDetailsFieldMap.get("useDefaultTF");
      const contractOverallValueDefaultValue = contractOverallValueDetailsFieldMap.get("default_value");
      if(contractOverallValueUseDefaultTF) {
        updatedContractOverallValue = this.props.DatabaseMobx.int_decimal_or_string_value_raw_from_string_value_raw_and_field_type_obj(contractOverallValueDefaultValue, contractOverallValueFieldTypeObj);
      }
    }
    this.update_new_capture_field("contract_overall_value", updatedContractOverallValue);

    var updatedIDIQCaptureIDTOLink = -1; //no IDIQ link capture selected if default value not found for this capture type
    const idiqCaptureIDTOLinkDetailsFieldMap = JSFUNC.get_first_map_matching_field_value(c_tbl_a_capture_types_details_fields, ["capture_type_id", "field_id"], [i_newValue, c_fieldMapOfIdiqCaptureIDTOLink.get("id")]);
    if(idiqCaptureIDTOLinkDetailsFieldMap !== undefined) {
      const idiqCaptureIDTOLinkFieldTypeObj = idiqCaptureIDTOLinkDetailsFieldMap.get("fieldTypeObj");
      const idiqCaptureIDTOLinkUseDefaultTF = idiqCaptureIDTOLinkDetailsFieldMap.get("useDefaultTF");
      const idiqCaptureIDTOLinkDefaultValue = idiqCaptureIDTOLinkDetailsFieldMap.get("default_value");
      if(idiqCaptureIDTOLinkUseDefaultTF) {
        updatedIDIQCaptureIDTOLink = this.props.DatabaseMobx.int_decimal_or_string_value_raw_from_string_value_raw_and_field_type_obj(idiqCaptureIDTOLinkDefaultValue, idiqCaptureIDTOLinkFieldTypeObj);
      }
    }
    this.update_new_capture_field("idiq_capture_id_TO_link", updatedIDIQCaptureIDTOLink);

    var updatedPeriodOfPerformance = 0; //0 months if default value not found for this capture type
    const periodOfPerformanceDetailsFieldMap = JSFUNC.get_first_map_matching_field_value(c_tbl_a_capture_types_details_fields, ["capture_type_id", "field_id"], [i_newValue, c_fieldMapOfPeriodOfPerformance.get("id")]);
    if(periodOfPerformanceDetailsFieldMap !== undefined) {
      const periodOfPerformanceFieldTypeObj = periodOfPerformanceDetailsFieldMap.get("fieldTypeObj");
      const periodOfPerformanceUseDefaultTF = periodOfPerformanceDetailsFieldMap.get("useDefaultTF");
      const periodOfPerformanceDefaultValue = periodOfPerformanceDetailsFieldMap.get("default_value");
      if(periodOfPerformanceUseDefaultTF) {
        updatedPeriodOfPerformance = this.props.DatabaseMobx.int_decimal_or_string_value_raw_from_string_value_raw_and_field_type_obj(periodOfPerformanceDefaultValue, periodOfPerformanceFieldTypeObj);
      }
    }
    this.update_new_capture_field("period_of_performance", updatedPeriodOfPerformance);

    //add any admin defined Additional Fields for this Create New Capture form, give them initial blank values
    const newCaptureTypeIsFilledOutTF = DatabaseMobx.value_is_filled_out_tf_from_value_raw_and_field_type_obj(i_newValue, c_fieldMapOfCaptureType.get("fieldTypeObj"));
    var updatedAdditionalFieldsRawValuesObj = undefined; //if the new Capture Type is not filled out, erase the additional fields obj
    if(newCaptureTypeIsFilledOutTF) {
      const captureTypeAdditionalFieldsArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(o_tbl_a_create_new_capture_additional_fields, "capture_type_id", i_newValue);
      if(captureTypeAdditionalFieldsArrayOfObjs.length > 0) {
        updatedAdditionalFieldsRawValuesObj = {};
        for(let additionalFieldObj of captureTypeAdditionalFieldsArrayOfObjs) {
          var expandedCaptureFieldMap = DatabaseMobx.fetch_expanded_capture_field_map_from_field_id(additionalFieldObj.capture_field_id);
          updatedAdditionalFieldsRawValuesObj[expandedCaptureFieldMap.get("db_name")] = DatabaseMobx.get_blank_value_or_undefined_from_expanded_capture_field_map(expandedCaptureFieldMap);
        }
      }
    }
    this.update_new_capture_field("additionalFieldsRawValuesObj", updatedAdditionalFieldsRawValuesObj);
  }
  onchange_stage = (i_newValue) => { this.update_new_capture_field("stage_id", i_newValue); }
  onchange_prime_company = (i_newValue) => { this.update_new_capture_field("prime_contact_company_id", i_newValue); }
  onchange_contract_type = (i_newValue) => {
    this.update_new_capture_field("contract_type_id", i_newValue); //set the newly selected contract type
    this.update_new_capture_field("idiq_capture_id_TO_link", -1); //reset IDIQ TO link in case you're switching away from a Task Order
  }
  onchange_contract_overall_value = (i_newValue) => { this.update_new_capture_field("contract_overall_value", i_newValue); }
  onchange_idiq_to_link = (i_newValue) => { this.update_new_capture_field("idiq_capture_id_TO_link", i_newValue); }
  onchange_period_of_performance = (i_newValue) => { this.update_new_capture_field("period_of_performance", i_newValue); }
  onchange_additional_field = (i_additionalFieldDbName, i_newValue) => {
    const s_newCaptureObj = this.state.s_newCaptureObj;

    var updatedAdditionalFieldsRawValuesObj = {};
    if(s_newCaptureObj.additionalFieldsRawValuesObj !== undefined) {
      updatedAdditionalFieldsRawValuesObj = JSFUNC.copy_obj(s_newCaptureObj.additionalFieldsRawValuesObj);
    }

    updatedAdditionalFieldsRawValuesObj[i_additionalFieldDbName] = i_newValue;

    this.update_new_capture_field("additionalFieldsRawValuesObj", updatedAdditionalFieldsRawValuesObj);
  }
  onchange_documents_card_folders_preset = (i_newValue) => {
    this.props.CapturesMobx.a_set_create_new_capture_selected_documents_card_folders_preset_id(i_newValue);
  }

  update_new_capture_field = (i_fieldDbName, i_newValue) => {
    const s_newCaptureObj = this.state.s_newCaptureObj;

    var updatedNewCaptureObj = s_newCaptureObj;
    updatedNewCaptureObj[i_fieldDbName] = i_newValue;

    this.setState({
      s_newCaptureObj: updatedNewCaptureObj,
      s_showCreateButtonErrorMessageTF: false
    });
  }

  onconfirm_create_new_capture = () => {
    const s_newCaptureObj = this.state.s_newCaptureObj;
    
    const o_createNewCaptureSelectedDocumentsCardFoldersPresetID = this.props.CapturesMobx.o_createNewCaptureSelectedDocumentsCardFoldersPresetID;

    if(this.opportunity_name_has_error_tf() || this.capture_managers_has_error_tf() || this.division_owners_has_error_tf() || this.capture_type_has_error_tf() || this.contract_type_has_error_tf()) {
      this.setState({
        s_showErrorsTF: true,
        s_showCreateButtonErrorMessageTF: true
      });
    }
    else {
      //copy and modify the s_newCaptureObj to bring additional field values from additionalFieldsRawValuesObj to the base level of the new capture obj
      var additionalFieldsNewCaptureObj = JSFUNC.copy_obj(s_newCaptureObj);
      if(s_newCaptureObj.additionalFieldsRawValuesObj !== undefined) {
        additionalFieldsNewCaptureObj.additionalFieldsRawValuesObj = undefined; //remove the temporary raw value holding for additional fields
        for(var fieldDbName in s_newCaptureObj.additionalFieldsRawValuesObj) { //copy additional fields with raw values to base level of new capture obj
          if(s_newCaptureObj.additionalFieldsRawValuesObj.hasOwnProperty(fieldDbName)) {
            s_newCaptureObj[fieldDbName] = s_newCaptureObj.additionalFieldsRawValuesObj[fieldDbName];
          }
        }
      }

      //create a new capture from the new capture obj
      this.props.CapturesMobx.a_create_new_capture_from_new_capture_process(s_newCaptureObj, o_createNewCaptureSelectedDocumentsCardFoldersPresetID);
    }
  }

  opportunity_name_has_error_tf = () => { return(!JSFUNC.text_or_number_is_filled_out_tf(this.state.s_newCaptureObj.opportunity_name)); }
  capture_managers_has_error_tf = () => { return(JSFUNC.sum_of_colon_comma_list_int2(this.state.s_newCaptureObj.capture_managers_ids_colon_percent_comma, 100) !== 100); }
  division_owners_has_error_tf = () => { return(JSFUNC.sum_of_colon_comma_list_int2(this.state.s_newCaptureObj.division_owners_ids_colon_percent_comma, 100) !== 100); }
  capture_type_has_error_tf = () => { return(!JSFUNC.select_int_is_filled_out_tf(this.state.s_newCaptureObj.capture_type_id)); }
  contract_type_has_error_tf = () => { return(!JSFUNC.select_int_is_filled_out_tf(this.state.s_newCaptureObj.contract_type_id)); }

  get_stage_ids_array_in_and_not_in_selected_capture_type = () => {
    const s_newCaptureObj = this.state.s_newCaptureObj;

    const captureTypeMap = this.props.DatabaseMobx.tbl_row_map_from_id("tbl_a_capture_types", s_newCaptureObj.capture_type_id);
    const captureTypeStageIDsComma = captureTypeMap.get("stage_ids_comma"); //these are already in the admin's desired order for this capture type, on top of this, the stages select valueDisplayArrays are put in order by stage type 1-7
    const captureTypeStageIDsArray = JSFUNC.convert_comma_list_to_int_array(captureTypeStageIDsComma);

    const stagesTblRef = this.props.DatabaseMobx.tbl_ref_from_tbl_name("tbl_a_stages_pool");
    const stagesMapOfMaps = JSFUNC.filtered_mapOfMaps_from_matching_field_value(stagesTblRef, "!id", captureTypeStageIDsArray);
    const stageIDsNotInThisCaptureTypeArray = JSFUNC.get_column_vector_from_mapOfMaps(stagesMapOfMaps, "id");

    //determine prime/sub of this capture type
    const captureTypePrime1Sub2 = captureTypeMap.get("prime1_sub2");
    const isPrimeCaptureTypeTF = (captureTypePrime1Sub2 === 1);
    const isSubCaptureTypeTF = (captureTypePrime1Sub2 === 2);

    return([captureTypeStageIDsArray, stageIDsNotInThisCaptureTypeArray, isPrimeCaptureTypeTF, isSubCaptureTypeTF]);
  }

  get_contract_type_type_from_selected_contract_type = () => {
    const s_newCaptureObj = this.state.s_newCaptureObj;

    const contractTypeMap = this.props.DatabaseMobx.tbl_row_map_from_id("tbl_a_contract_types", s_newCaptureObj.contract_type_id);
    const contractTypeType123 = contractTypeMap.get("sa1_idiq2_to3");
    const isStandardContractTF = (contractTypeType123 === 1);
    const isIDIQContractTF = (contractTypeType123 === 2);
    const isTaskOrderContractTF = (contractTypeType123 === 3);

    return([isStandardContractTF, isIDIQContractTF, isTaskOrderContractTF]);
  }

  render() {
    const s_newCaptureObj = this.state.s_newCaptureObj;
    const s_showErrorsTF = this.state.s_showErrorsTF;
    const s_showCreateButtonErrorMessageTF = this.state.s_showCreateButtonErrorMessageTF;

    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const o_integrationsUserCreateUpdateIntegrationCaptureFromCEActionTF = this.props.AdminIntegrationsMobx.o_integrationsUserCreateUpdateIntegrationCaptureFromCEActionTF;
    const o_createNewCaptureIsOpenTF = this.props.CapturesMobx.o_createNewCaptureIsOpenTF;
    const o_createNewCaptureSelectedDocumentsCardFoldersPresetID = this.props.CapturesMobx.o_createNewCaptureSelectedDocumentsCardFoldersPresetID;
    const c_createNewCaptureFilteredMatchingCaptureIDsAndNamesLowercaseArrayOfObjs = this.props.CapturesMobx.c_createNewCaptureFilteredMatchingCaptureIDsAndNamesLowercaseArrayOfObjs;
    const c_createNewCaptureDocumentsCardFoldersSelectedPresetName = this.props.CapturesMobx.c_createNewCaptureDocumentsCardFoldersSelectedPresetName;
    const c_createNewCaptureDocumentsCardFoldersSortedTreeWithIndentsArrayOfObjs = this.props.CapturesMobx.c_createNewCaptureDocumentsCardFoldersSortedTreeWithIndentsArrayOfObjs;
    const o_tbl_a_create_new_capture_additional_fields = this.props.DatabaseMobx.o_tbl_a_create_new_capture_additional_fields;
    const c_companyUsingCodenameTF = this.props.DatabaseMobx.c_companyUsingCodenameTF;
    const c_companyShortcutPresetsDocumentsCardFoldersOnCreateNewCaptureTF = this.props.DatabaseMobx.c_companyShortcutPresetsDocumentsCardFoldersOnCreateNewCaptureTF;
    const c_companyIntegrationOnTF = this.props.DatabaseMobx.c_companyIntegrationOnTF;
    const c_cardNameDocuments = this.props.DatabaseMobx.c_cardNameDocuments;
    const c_fieldMapOfCaptureManagers = this.props.DatabaseMobx.c_fieldMapOfCaptureManagers;
    const c_fieldMapOfDivisionOwners = this.props.DatabaseMobx.c_fieldMapOfDivisionOwners;
    const c_fieldMapOfCodename = this.props.DatabaseMobx.c_fieldMapOfCodename;
    const c_fieldMapOfOpportunityName = this.props.DatabaseMobx.c_fieldMapOfOpportunityName;
    const c_fieldMapOfCaptureType = this.props.DatabaseMobx.c_fieldMapOfCaptureType;
    const c_fieldMapOfStage = this.props.DatabaseMobx.c_fieldMapOfStage;
    const c_fieldMapOfPrimeCompany = this.props.DatabaseMobx.c_fieldMapOfPrimeCompany;
    const c_fieldMapOfContractType = this.props.DatabaseMobx.c_fieldMapOfContractType;
    const c_fieldMapOfContractOverallValue = this.props.DatabaseMobx.c_fieldMapOfContractOverallValue;
    const c_fieldMapOfIdiqCaptureIDTOLink = this.props.DatabaseMobx.c_fieldMapOfIdiqCaptureIDTOLink;
    const c_fieldMapOfPeriodOfPerformance = this.props.DatabaseMobx.c_fieldMapOfPeriodOfPerformance;
    const c_selectShortcutPresetsDocumentsCardFoldersPresetFieldTypeObj = this.props.DatabaseMobx.c_selectShortcutPresetsDocumentsCardFoldersPresetFieldTypeObj;
    
    if(!o_createNewCaptureIsOpenTF) {
      return(null);
    }

    const numOppNameMatchingCaptures = c_createNewCaptureFilteredMatchingCaptureIDsAndNamesLowercaseArrayOfObjs.length;

    //get all user input errors to display
    const opportunityNameHasErrorTF = this.opportunity_name_has_error_tf();
    const captureManagersHasErrorTF = this.capture_managers_has_error_tf();
    const divisionOwnersHasErrorTF = this.division_owners_has_error_tf();
    const captureTypeHasErrorTF = this.capture_type_has_error_tf();
    const contractTypeHasErrorTF = this.contract_type_has_error_tf();

    //get all stageIDs not in this capture type so that the select for stages is only left with the ones in this type
    var stageIDsNotInThisCaptureTypeArray = [];
    var isSubCaptureTypeTF = false;
    if(!captureTypeHasErrorTF) {
      const [stageIDsInArray, stageIDsNotInArray, isPrimeTF, isSubTF] = this.get_stage_ids_array_in_and_not_in_selected_capture_type();
      stageIDsNotInThisCaptureTypeArray = stageIDsNotInArray;
      isSubCaptureTypeTF = isSubTF;
    }

    //get contract type type (SA, IDIQ, TO) from the selected contract type (determines if TO Link is valid etc below)
    const [isStandardContractTF, isIDIQContractTF, isTaskOrderContractTF] = this.get_contract_type_type_from_selected_contract_type();
    
    const itemRowHeight = "2.3em";
    const typeMargin = ((c_isMobileOrTabletTF) ? ("0") : ("5%"));

    const oppNameCodeNameLabelsAndInputsComponent = (
      <>
        <div className="displayFlexRowVc" style={{height:itemRowHeight}}>
          <CEGeneralReact.NewCaptureFieldLabel p_widthEm={11} p_label={c_fieldMapOfOpportunityName.get("display_name")} />
          <div className="flex11a">
            <CEGeneralReact.GenericInputOrSelectFromInputType
              p_fieldTypeObj={c_fieldMapOfOpportunityName.get("fieldTypeObj")}
              p_valueRaw={s_newCaptureObj.opportunity_name}
              p_tabIndex={1}
              p_errorTF={(s_showErrorsTF && opportunityNameHasErrorTF)}
              f_onChangeOrOnSelect={this.onchange_opportunity_name}
            />
          </div>
        </div>
        {(c_companyUsingCodenameTF) &&
          <div className="displayFlexRowVc" style={{height:itemRowHeight}}>
            <CEGeneralReact.NewCaptureFieldLabel p_widthEm={11} p_label={c_fieldMapOfCodename.get("display_name")} />
            <div className="flex11a">
              <CEGeneralReact.GenericInputOrSelectFromInputType
                p_fieldTypeObj={c_fieldMapOfCodename.get("fieldTypeObj")}
                p_valueRaw={s_newCaptureObj.codename}
                p_tabIndex={2}
                f_onChangeOrOnSelect={this.onchange_codename}
              />
            </div>
          </div>
        }
      </>
    );

    const oppNameMatchingCaptureNamesBoxComponent = (
      <div className="flex11a displayFlexColumn border1bbb borderRadius05 bgLightesterGray">
        <div className="flex00a bgGray tbMicroPad textCenter">
          <LibraryReact.Nowrap p_fontClass="fontItalic fontWhite">
            {numOppNameMatchingCaptures + " existing " + JSFUNC.plural(numOppNameMatchingCaptures, "Capture", "Captures") + " match '" + s_newCaptureObj.opportunity_name + "'"}
          </LibraryReact.Nowrap>
        </div>
        <div className="flex11a displayFlexColumn yScroll">
          {(s_newCaptureObj.opportunity_name === "") ? (
            <div className="flex11a displayFlexColumnHcVc textCenter">
              <font className="fontItalic fontTextLighter">
                {"This will show potentially duplicate Captures that match the new " + c_fieldMapOfOpportunityName.get("display_name")}
              </font>
            </div>
          ) : (
            c_createNewCaptureFilteredMatchingCaptureIDsAndNamesLowercaseArrayOfObjs.map((m_captureIDAndNameLowercaseObj) =>
              <div
                className="lrPad hoverLighterBlueGradient cursorPointer"
                title={m_captureIDAndNameLowercaseObj.name + "\n[Click to open this Capture]"}
                onClick={() => this.props.OpenCaptureMobx.a_open_single_capture(m_captureIDAndNameLowercaseObj.id)}>
                <font className="">
                  {m_captureIDAndNameLowercaseObj.name}
                </font>
              </div>
            )
          )}
        </div>
      </div>
    );

    //add any admin defined Additional Fields for this Create New Capture form
    const newCaptureTypeIsFilledOutTF = DatabaseMobx.value_is_filled_out_tf_from_value_raw_and_field_type_obj(s_newCaptureObj.capture_type_id, c_fieldMapOfCaptureType.get("fieldTypeObj"));
    var captureTypeAdditionalFieldsArrayOfObjs = []; //initialize with 0 additional fields for the selected Capture Type
    var numCaptureTypeAdditionalFields = 0;
    if(newCaptureTypeIsFilledOutTF) {
      captureTypeAdditionalFieldsArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(o_tbl_a_create_new_capture_additional_fields, "capture_type_id", s_newCaptureObj.capture_type_id, "sort", true);
      numCaptureTypeAdditionalFields = captureTypeAdditionalFieldsArrayOfObjs.length;
      if(numCaptureTypeAdditionalFields > 0) {
        for(let additionalFieldObj of captureTypeAdditionalFieldsArrayOfObjs) {
          var expandedCaptureFieldMap = DatabaseMobx.fetch_expanded_capture_field_map_from_field_id(additionalFieldObj.capture_field_id);
          additionalFieldObj.expandedCaptureFieldMap = expandedCaptureFieldMap;
          additionalFieldObj.fieldDbName = expandedCaptureFieldMap.get("db_name");
          additionalFieldObj.fieldDisplayName = expandedCaptureFieldMap.get("display_name");
        }
      }
    }
    const captureTypeHasAnyAdditionalFieldsTF = (numCaptureTypeAdditionalFields > 0)

    var cncBoxIDsToDrawArray = [2, 3, 4, 5];
    if(captureTypeHasAnyAdditionalFieldsTF) { //additional fields cnc card
      cncBoxIDsToDrawArray.push(6);
    }
    if(c_companyShortcutPresetsDocumentsCardFoldersOnCreateNewCaptureTF) { //documents card folders shortcut presets
      cncBoxIDsToDrawArray.push(7);
    }

    var numCncBoxesPerRow = 2;
    if(c_isMobileOrTabletTF) {
      numCncBoxesPerRow = 1;
    }

    const createNewCaptureRCMatrixOfObjs = JSFUNC.get_rc_matrix_from_id_array_and_num_columns(cncBoxIDsToDrawArray, numCncBoxesPerRow);
  
    return(
      <>
        <div className="flex11a displayFlexColumn yScroll yScrollBottomPad">
          {(c_companyIntegrationOnTF && !o_integrationsUserCreateUpdateIntegrationCaptureFromCEActionTF) &&
            <div className="tbMargin">
              <AdminIntegrationsReact.IntegrationsOptionToTurnOffIntegrationCreateUpdateCapturesMessageBox />
            </div>
          }
          <div className="flex00a displayFlexRow tbMedPad lrMedPad">
            <CEGeneralReact.NewCaptureSectionContainer p_drawBorderTF={true}>
                {(c_isMobileOrTabletTF) ? (
                  <CEGeneralReact.NewCaptureSectionLabel p_label="New Capture Name">
                    <div className="" style={{marginLeft:typeMargin, marginRight:typeMargin}}>
                      {oppNameCodeNameLabelsAndInputsComponent}
                    </div>
                    <div className="displayFlexColumnHcVc smallTopMargin">
                      <div className="flex00a displayFlexColumn" style={{width:"100%", maxWidth:"40em", height:"8em"}}>
                        {oppNameMatchingCaptureNamesBoxComponent}
                      </div>
                    </div>
                  </CEGeneralReact.NewCaptureSectionLabel>
                ) : (
                  <div className="displayFlexRow">
                    <div className="flex11a lrPad" style={{flexBasis:"175em"}}>
                      <CEGeneralReact.NewCaptureSectionLabel p_label="New Capture Name">
                        {oppNameCodeNameLabelsAndInputsComponent}
                      </CEGeneralReact.NewCaptureSectionLabel>
                    </div>
                    <div className="flex11a displayFlexColumn smallFullPad" style={{flexBasis:"100em", height:"9em"}}>
                      {oppNameMatchingCaptureNamesBoxComponent}
                    </div>
                    {(c_companyIntegrationOnTF) &&
                      <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2em"}}>
                        <AdminIntegrationsReact.IntegrationsOptionToTurnOffIntegrationCreateUpdateCapturesVerticalDotsMenu />
                      </div>
                    }
                  </div>
                )}
            </CEGeneralReact.NewCaptureSectionContainer>
          </div>
          {createNewCaptureRCMatrixOfObjs.map((m_rowCncBoxIDsArray, m_rowIndex) =>
            <div
              key={JSFUNC.rc_unique_row_key(numCncBoxesPerRow, m_rowIndex)}
              className="flex00a displayFlexRow medBottomPad lrMedPad">
              {m_rowCncBoxIDsArray.map((m_cncBoxIDOrM1, m_colIndex) =>
                <>
                  {(m_colIndex > 0) &&
                    <div className="flex00a rMedMargin" />
                  }
                  <CEGeneralReact.NewCaptureSectionContainer key={"c" + m_cncBoxIDOrM1 + "i" + m_colIndex} p_drawBorderTF={((m_cncBoxIDOrM1 >= 2) && (m_cncBoxIDOrM1 <= 7))}>
                    {(m_cncBoxIDOrM1 === 2) &&
                      <CEGeneralReact.NewCaptureSectionLabel p_label={c_fieldMapOfCaptureManagers.get("display_name")}>
                        <div className="" style={{marginLeft:typeMargin, marginRight:typeMargin}}>
                          <CEGeneralReact.GenericInputOrSelectFromInputType
                            p_fieldTypeObj={c_fieldMapOfCaptureManagers.get("fieldTypeObj")}
                            p_valueRaw={s_newCaptureObj.capture_managers_ids_colon_percent_comma}
                            f_onChangeOrOnSelect={this.onchange_capture_managers}
                          />
                        </div>
                      </CEGeneralReact.NewCaptureSectionLabel>
                    }
                    {(m_cncBoxIDOrM1 === 3) &&
                      <CEGeneralReact.NewCaptureSectionLabel p_label={c_fieldMapOfDivisionOwners.get("display_name")}>
                        <div className="" style={{marginLeft:typeMargin, marginRight:typeMargin}}>
                          <CEGeneralReact.GenericInputOrSelectFromInputType
                            p_fieldTypeObj={c_fieldMapOfDivisionOwners.get("fieldTypeObj")}
                            p_valueRaw={s_newCaptureObj.division_owners_ids_colon_percent_comma}
                            f_onChangeOrOnSelect={this.onchange_division_owners}
                          />
                        </div>
                      </CEGeneralReact.NewCaptureSectionLabel>
                    }
                    {(m_cncBoxIDOrM1 === 4) &&
                      <CEGeneralReact.NewCaptureSectionLabel p_label={c_fieldMapOfCaptureType.get("display_name")}>
                        <div className="displayFlexRowVc" style={{height:itemRowHeight, marginLeft:typeMargin, marginRight:typeMargin}}>
                          <CEGeneralReact.NewCaptureFieldLabel p_widthEm={10} p_label={c_fieldMapOfCaptureType.get("display_name")} />
                          <div className="flex11a">
                            <CEGeneralReact.GenericInputOrSelectFromInputType
                              p_fieldTypeObj={c_fieldMapOfCaptureType.get("fieldTypeObj")}
                              p_valueRaw={s_newCaptureObj.capture_type_id}
                              p_tabIndex={4}
                              p_errorTF={(s_showErrorsTF && captureTypeHasErrorTF)}
                              f_onChangeOrOnSelect={this.onchange_capture_type}
                            />
                          </div>
                        </div>
                        <div className="displayFlexRowVc" style={{height:itemRowHeight, marginLeft:typeMargin, marginRight:typeMargin}}>
                          <CEGeneralReact.NewCaptureFieldLabel p_widthEm={10} p_label={c_fieldMapOfStage.get("display_name")} />
                          <div className="flex11a">
                            {(captureTypeHasErrorTF) ? (
                              <font className="fontItalic fontTextLighter">
                                {"--Select the " + c_fieldMapOfCaptureType.get("display_name") + " before selecting " + c_fieldMapOfStage.get("display_name") + "--"}
                              </font>
                            ) : (
                              <CEGeneralReact.GenericInputOrSelectFromInputType
                                p_fieldTypeObj={c_fieldMapOfStage.get("fieldTypeObj")}
                                p_valueRaw={s_newCaptureObj.stage_id}
                                p_valuesToNotIncludeArray={stageIDsNotInThisCaptureTypeArray}
                                p_tabIndex={5}
                                f_onChangeOrOnSelect={this.onchange_stage}
                              />
                            )}
                          </div>
                        </div>
                        {(false && !captureTypeHasErrorTF && isSubCaptureTypeTF) &&
                          <div className="displayFlexRowVc" style={{height:itemRowHeight, marginLeft:typeMargin, marginRight:typeMargin}}>
                            <CEGeneralReact.CaptureExecFieldEditSaveCancel
                              p_ceEditItemString="newCapturePrimeCompany"
                              p_fieldDisplayName={c_fieldMapOfPrimeCompany.get("display_name")}
                              p_fieldTypeObj={c_fieldMapOfPrimeCompany.get("fieldTypeObj")}
                              p_valueRaw={s_newCaptureObj.prime_contact_company_id}
                              p_valueMask={(captureTypeHasErrorTF || !isSubCaptureTypeTF) ? ((captureTypeHasErrorTF) ? ("--Select a " + c_fieldMapOfCaptureType.get("display_name") + " before setting " + c_fieldMapOfPrimeCompany.get("display_name") + "--") : ("--Only Applicable for 'Sub' Capture Types--")) : (undefined)}
                              p_valueIsEditableTFU={(captureTypeHasErrorTF || !isSubCaptureTypeTF) ? (undefined) : (true)}
                              p_valuesToNotIncludeArray={undefined}
                              p_containerClass="width100"
                              p_fieldClass="font11 fontBold fontTextLighter"
                              p_fieldWidth="10em"
                              p_fieldNowrapTF={false}
                              p_valueClass={(captureTypeHasErrorTF || !isSubCaptureTypeTF) ? ("fontItalic fontTextLighter") : ("")}
                              p_fieldValueVerticallyAlignedTF={true}
                              p_floatingBoxTitle={"Select " + c_fieldMapOfPrimeCompany.get("display_name")}
                              f_onSaveChanged={this.onchange_prime_company}
                            />
                          </div>
                        }
                      </CEGeneralReact.NewCaptureSectionLabel>
                    }
                    {(m_cncBoxIDOrM1 === 5) &&
                      <CEGeneralReact.NewCaptureSectionLabel p_label={c_fieldMapOfContractType.get("display_name")}>
                        <div className="displayFlexRowVc" style={{height:itemRowHeight, marginLeft:typeMargin, marginRight:typeMargin}}>
                          <CEGeneralReact.NewCaptureFieldLabel p_widthEm={13} p_label={c_fieldMapOfContractType.get("display_name")} />
                          <div className="flex11a">
                            <CEGeneralReact.GenericInputOrSelectFromInputType
                              p_fieldTypeObj={c_fieldMapOfContractType.get("fieldTypeObj")}
                              p_valueRaw={s_newCaptureObj.contract_type_id}
                              p_tabIndex={7}
                              p_errorTF={(s_showErrorsTF && this.contract_type_has_error_tf())}
                              f_onChangeOrOnSelect={this.onchange_contract_type}
                            />
                          </div>
                        </div>
                        <div className="displayFlexRowVc" style={{height:itemRowHeight, marginLeft:typeMargin, marginRight:typeMargin}}>
                          <CEGeneralReact.NewCaptureFieldLabel p_widthEm={13} p_label={c_fieldMapOfContractOverallValue.get("display_name")} />
                          <div className="flex11a">
                            {(contractTypeHasErrorTF) ? (
                              <font className="fontItalic fontTextLighter">
                                {"--Select the " + c_fieldMapOfContractType.get("display_name") + " before setting " + c_fieldMapOfContractOverallValue.get("display_name") + "--"}
                              </font>
                            ) : (
                              <CEGeneralReact.GenericInputOrSelectFromInputType
                                p_fieldTypeObj={c_fieldMapOfContractOverallValue.get("fieldTypeObj")}
                                p_valueRaw={s_newCaptureObj.contract_overall_value}
                                p_tabIndex={8}
                                f_onChangeOrOnSelect={this.onchange_contract_overall_value}
                              />
                            )}
                          </div>
                        </div>
                        {(isTaskOrderContractTF) &&
                          <div className="displayFlexRowVc " style={{height:itemRowHeight, marginLeft:typeMargin, marginRight:typeMargin}}>
                            <CEGeneralReact.NewCaptureFieldLabel p_widthEm={13} p_label={c_fieldMapOfIdiqCaptureIDTOLink.get("display_name")} />
                            <div className="flex11a">
                              {(contractTypeHasErrorTF) ? (
                                <font className="fontItalic fontTextLighter">
                                  {"--Select the " + c_fieldMapOfContractType.get("display_name") + " before setting " + c_fieldMapOfIdiqCaptureIDTOLink.get("display_name") + "--"}
                                </font>
                              ) : (
                                <CEGeneralReact.GenericInputOrSelectFromInputType
                                  p_fieldTypeObj={c_fieldMapOfIdiqCaptureIDTOLink.get("fieldTypeObj")}
                                  p_valueRaw={s_newCaptureObj.idiq_capture_id_TO_link}
                                  p_tabIndex={9}
                                  f_onChangeOrOnSelect={this.onchange_idiq_to_link}
                                />
                              )}
                            </div>
                          </div>
                        }
                        <div className="displayFlexRowVc" style={{height:itemRowHeight, marginLeft:typeMargin, marginRight:typeMargin}}>
                          <CEGeneralReact.NewCaptureFieldLabel p_widthEm={13} p_label={c_fieldMapOfPeriodOfPerformance.get("display_name")} />
                          <div className="flex11a">
                            <CEGeneralReact.GenericInputOrSelectFromInputType
                              p_fieldTypeObj={c_fieldMapOfPeriodOfPerformance.get("fieldTypeObj")}
                              p_valueRaw={s_newCaptureObj.period_of_performance}
                              p_tabIndex={11}
                              f_onChangeOrOnSelect={this.onchange_period_of_performance}
                            />
                          </div>
                        </div>
                      </CEGeneralReact.NewCaptureSectionLabel>
                    }
                    {(m_cncBoxIDOrM1 === 6) &&
                      <CEGeneralReact.NewCaptureSectionLabel p_label={"New Capture Additional Fields"}>
                        {captureTypeAdditionalFieldsArrayOfObjs.map((m_additionalFieldObj, m_additionalFieldIndex) =>
                          <div className="displayFlexRowVc" style={{minHeight:itemRowHeight, marginLeft:typeMargin, marginRight:typeMargin}}>
                            <CEGeneralReact.NewCaptureFieldLabel p_widthEm={13} p_label={m_additionalFieldObj.fieldDisplayName} />
                            <div className="flex11a">
                              <CEGeneralReact.GenericInputOrSelectFromInputType
                                p_fieldTypeObj={m_additionalFieldObj.expandedCaptureFieldMap.get("fieldTypeObj")}
                                p_valueRaw={s_newCaptureObj.additionalFieldsRawValuesObj[m_additionalFieldObj.fieldDbName]}
                                p_tabIndex={12 + m_additionalFieldIndex}
                                p_forceTextareaToTextTF={true}
                                f_onChangeOrOnSelect={(i_newValue) => {this.onchange_additional_field(m_additionalFieldObj.fieldDbName, i_newValue);}}
                              />
                            </div>
                          </div>
                        )}
                      </CEGeneralReact.NewCaptureSectionLabel>
                    }
                    {(m_cncBoxIDOrM1 === 7) &&
                      <CEGeneralReact.NewCaptureSectionLabel p_label={c_cardNameDocuments + " Card Preset Folders"}>
                        <div style={{margin:"0 20%"}}>
                          <CEGeneralReact.GenericInputOrSelectFromInputType
                            p_fieldTypeObj={c_selectShortcutPresetsDocumentsCardFoldersPresetFieldTypeObj}
                            p_valueRaw={o_createNewCaptureSelectedDocumentsCardFoldersPresetID}
                            p_tabIndex={12 + numCaptureTypeAdditionalFields}
                            f_onChangeOrOnSelect={this.onchange_documents_card_folders_preset}
                          />
                        </div>
                        <div className="bigTopMargin displayFlexColumnHcVc">
                          <div className="flex00a border1bbb bgLightesterGray medFullPad" style={{width:"100%", maxWidth:"30em"}}>
                            {(JSFUNC.select_int_is_filled_out_tf(o_createNewCaptureSelectedDocumentsCardFoldersPresetID)) ? (
                              <>
                                <div className="smallBottomMargin textCenter">
                                  <font className="fontItalic fontTextLight">
                                    {"Initial folders in " + c_cardNameDocuments + " Card from preset '" + c_createNewCaptureDocumentsCardFoldersSelectedPresetName + "'"}
                                  </font>
                                </div>
                                {c_createNewCaptureDocumentsCardFoldersSortedTreeWithIndentsArrayOfObjs.map((m_treeWithIndentsObj) =>
                                  <div className="displayFlexRow" style={{height:"1.9em", marginLeft:(m_treeWithIndentsObj.indentLevel * 1.5) + "em", backgroundColor:"#f4eef8", border:"solid 1px", borderColor:"#dbe #dbe #bad #dbe"}}>
                                    <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"2.5em"}}>
                                      <CEGeneralReact.SvgFolder p_widthEm={2.1} p_heightEm={1.2} p_lineColor="777" />
                                    </div>
                                    <div className="flex11a displayFlexRowVc lrPad">
                                      <LibraryReact.Nowrap p_fontClass="">
                                        {m_treeWithIndentsObj.display_name}
                                      </LibraryReact.Nowrap>
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : (
                              <div className="textCenter">
                                <font className="fontItalic fontTextLight">
                                  {"No preset selected, the " + c_cardNameDocuments + " Card will initialize empty with no folders"}
                                </font>
                              </div>
                            )}
                          </div>
                        </div>
                      </CEGeneralReact.NewCaptureSectionLabel>
                    }
                  </CEGeneralReact.NewCaptureSectionContainer>
                </>
              )}
            </div>
          )}
        </div>
        <div className="flex00a displayFlexColumnHcVc border1ddd tbBigPad">
          <div>
            <CEGeneralReact.CEButton
              p_type="captureRed"
              p_text="Create This New Capture"
              p_tabIndex={13 + numCaptureTypeAdditionalFields}
              f_onClick={this.onconfirm_create_new_capture}
            />
          </div>
          {(s_showCreateButtonErrorMessageTF) &&
            <CEGeneralReact.ErrorText
              p_class="medTopMargin"
              p_text="The required fields indicated above must be filled out before creating this new capture"
            />
          }
        </div>
      </>
    );
  }
}));


