import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import * as JSFUNC from "../../Library/JSFUNC.js";
import * as LibraryReact from "../../Library/LibraryReact.js";

import * as JSPHP from "../../CaptureExecLocalDatabaseMobx/JSPHP.js";

import * as CEGeneralReact from "../../CaptureExecGeneral/CEGeneralReact.js";

import * as ContactsReact from "../Contacts/ContactsReact.js";
import DatabaseMobx from '../../CaptureExecLocalDatabaseMobx/DatabaseMobx.js';


export const ContractsExecWorkload = inject("TeammateContractsMobx", "DatabaseMobx")(observer(
class ContractsExecWorkload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      s_reassignErrorMessage: undefined
    };
  }

  onselect_reassign_contracts_user = (i_selectedContractsUserID) => {
    this.setState({s_reassignErrorMessage:undefined});
    this.props.TeammateContractsMobx.a_exec_set_selected_reassign_contracts_user_id(i_selectedContractsUserID);
  }

  onclick_reassign_contracts = () => {
    const o_execSelectedReassignContractsUserID = this.props.TeammateContractsMobx.o_execSelectedReassignContractsUserID;
    const o_execCheckedCaptureIDsArray = this.props.TeammateContractsMobx.o_execCheckedCaptureIDsArray;

    var updatedReassignErrorMessage = undefined;
    if(!JSFUNC.select_int_is_filled_out_tf(o_execSelectedReassignContractsUserID)) {
      updatedReassignErrorMessage = "Select a Contracts Manager to reassign selected contract to";
    }
    else {
      const captureIDsNotAccessibleByNewContractsUserArray = this.props.TeammateContractsMobx.a_exec_reassign_checked_captures_to_selected_contracts_user(o_execCheckedCaptureIDsArray, o_execSelectedReassignContractsUserID);
      const numCaptureIDsNotAccessibleByNewContractsUser = captureIDsNotAccessibleByNewContractsUserArray.length;
      if(numCaptureIDsNotAccessibleByNewContractsUser > 0) {
        const newContractsUserName = this.props.DatabaseMobx.user_name_mask_plaintext_from_user_id(o_execSelectedReassignContractsUserID);
        updatedReassignErrorMessage = newContractsUserName + " does not have the firewalling permissions to access " + numCaptureIDsNotAccessibleByNewContractsUser + " " + JSFUNC.plural(numCaptureIDsNotAccessibleByNewContractsUser, "Capture", "Captures") + " in this reassignment";
      }
    }
    this.setState({s_reassignErrorMessage:updatedReassignErrorMessage});
  }

  render() {
    const s_reassignErrorMessage = this.state.s_reassignErrorMessage;

    const o_execSelectedReassignContractsUserID = this.props.TeammateContractsMobx.o_execSelectedReassignContractsUserID;
    const c_execDataObj = this.props.TeammateContractsMobx.c_execDataObj;

    const contractsUsersArrayOfObjs = c_execDataObj.contractsUsersArrayOfObjs;
    const userIDsToNotIncludeArray = c_execDataObj.userIDsToNotIncludeArray;

    return(
      <div className="flex11a displayFlexColumn">
        <div className="flex00a displayFlexRow flexWrap tbPad" style={{borderBottom:"solid 1px #ccc"}}>
          <div className="flex00a displayFlexRowVc lrMedPad" style={{flexBasis:"40em"}}>
            <font className="font14 fontBold fontTextLighter">
              {"Contracts Executive Workload Management"}
            </font>
          </div>
          <CaptureContractsFilterButtons />
          <div className="flex11a displayFlexRowVc tbMargin lrMedPad">
            <div style={{width:"24em"}}>
              <div className="displayFlexRowVc">
                <div className="flex11a rMargin">
                  <font className="fontItalic fontTextLighter">
                    {"Reassign all checked contracts to:"}
                  </font>
                </div>
                <div className="flex00a">
                  <CEGeneralReact.CEButton
                    p_type="add"
                    p_text="Reassign"
                    f_onClick={this.onclick_reassign_contracts}
                  />
                </div>
              </div>
              <div className="smallTopMargin">
                <CEGeneralReact.GenericInputOrSelectFromInputType
                  p_fieldTypeObj={this.props.DatabaseMobx.c_selectUserWithContractsPowerFieldTypeObj}
                  p_valueRaw={o_execSelectedReassignContractsUserID}
                  p_valuesToNotIncludeArray={userIDsToNotIncludeArray}
                  p_errorTF={(s_reassignErrorMessage !== undefined)}
                  f_onChangeOrOnSelect={this.onselect_reassign_contracts_user}
                />
              </div>
              {(s_reassignErrorMessage !== undefined) &&
                <CEGeneralReact.ErrorText
                  p_class="microTopMargin"
                  p_text={s_reassignErrorMessage}
                />
              }
            </div>
          </div>
        </div>
        <div className="flex11a yScroll yScrollBottomPad">
          {contractsUsersArrayOfObjs.map((m_contractsUserObj) =>
            <ContractsExecWorkloadUserItem
              key={m_contractsUserObj.user_id}
              p_contractsUserObj={m_contractsUserObj}
            />
          )}
        </div>
      </div>
    );
  }
}));


function CaptureContractsFilterButtons(props) {
  const buttonWidth = "15.5em";
  return(
    <div className="displayFlexRow flexWrap">
      <div className="flex00a tbMargin" style={{flexBasis:buttonWidth}}>
        <CaptureContractsFilterSingleButton p_filterFlag="activeWonCapturesInProcessContracts" />
      </div>
      <div className="flex00a tbMargin" style={{flexBasis:buttonWidth}}>
        <CaptureContractsFilterSingleButton p_filterFlag="activeWonCapturesFullyExecutedCancelledContracts" />
      </div>
      <div className="flex00a tbMargin" style={{flexBasis:buttonWidth}}>
        <CaptureContractsFilterSingleButton p_filterFlag="lostNoBidCancelledCapturesAllContracts" />
      </div>
    </div>
  );
}


const CaptureContractsFilterSingleButton = inject("TeammateContractsMobx", "DatabaseMobx")(observer(
class CaptureContractsFilterSingleButton extends Component { //props: p_filterFlag
  onclick_filter_button = () => {
    this.props.TeammateContractsMobx.a_contractsexec_workload_and_contracts_todo_set_capture_contracts_filter_flag(this.props.p_filterFlag);
  }

  render() {
    const filterFlag = this.props.p_filterFlag;

    const captureContractsFilterFlag = this.props.TeammateContractsMobx.o_captureContractsFilterFlag;
    const fieldMapOfStage = this.props.DatabaseMobx.c_fieldMapOfStage;

    const isSelectedTF = (filterFlag === captureContractsFilterFlag);
    const stageFieldDisplayName = fieldMapOfStage.get("display_name");

    var buttonText = undefined;
    var buttonTitle = undefined;
    if(filterFlag === "activeWonCapturesInProcessContracts") {
      buttonText = this.props.TeammateContractsMobx.c_activeWonCapturesInProcessContractsFilterButtonName + " (" + this.props.TeammateContractsMobx.c_todoInProcessNumContracts + ")";
      buttonTitle = "View all In-Process Agreements assigned to Captures where the " + stageFieldDisplayName + " is Active or Won";
    }
    else if(filterFlag === "activeWonCapturesFullyExecutedCancelledContracts") {
      buttonText = this.props.TeammateContractsMobx.c_activeWonCapturesFullyExecutedCancelledContractsFilterButtonName + " (" + this.props.TeammateContractsMobx.c_todoFullyExecutedCancelledNumContracts + ")";
      buttonTitle = "View all Fully Executed or Cancelled Agreements assigned to Captures where the " + stageFieldDisplayName + " is Active or Won";
    }
    else if(filterFlag === "lostNoBidCancelledCapturesAllContracts") {
      buttonText = this.props.TeammateContractsMobx.c_lostNoBidCancelledCapturesAllContractsFilterButtonName + " (" + this.props.TeammateContractsMobx.c_todoLostNoBidCancelledCaptureNumContracts + ")";
      buttonTitle = "View all Agreements (In-Process, Fully Executed, or Cancelled) assigned to Captures where the " + stageFieldDisplayName + " is Lost, No Bid, or Cancelled where no more work is required";
    }

    return(
      <LibraryReact.ButtonMaxHeight
        p_value={buttonText}
        p_height="3.3em"
        p_maxHeight="3em"
        p_class={"flex11a lrMargin lrMedPad border bevelBorderColors borderRadius05 " + ((isSelectedTF) ? ("bgGoldGradient") : ("bgLightGrayGradient hoverLighterBlueGradient cursorPointer"))}
        p_fontClass={"fontBold " + ((isSelectedTF) ? ("fontTextLight") : ("fontTextLight"))}
        p_title={buttonTitle}
        f_onClick={((isSelectedTF) ? (undefined) : (this.onclick_filter_button))}
      />
    );
  }
}));


const ContractsExecWorkloadUserItem = inject("TeammateContractsMobx")(observer(
class ContractsExecWorkloadUserItem extends Component { //props: p_contractsUserObj
  onclick_user_checkbox = () => {
    this.props.TeammateContractsMobx.a_exec_user_check_or_uncheck_all_captures(this.props.p_contractsUserObj.capturesArrayOfObjs);
  }

  onclick_expand_collapse_user = () => {
    this.props.TeammateContractsMobx.a_exec_toggle_expand_collapse_user(this.props.p_contractsUserObj.user_id);
  }

  render() {
    const contractsUserObj = this.props.p_contractsUserObj;
    const checkedCaptureIDsArray = this.props.TeammateContractsMobx.o_execCheckedCaptureIDsArray;
    const expandedUserIDsArray = this.props.TeammateContractsMobx.o_execExpandedUserIDsArray;

    const capturesArrayOfObjs = contractsUserObj.capturesArrayOfObjs;
    const userNumCaptures = capturesArrayOfObjs.length;
    const numContractsArray = [contractsUserObj.numContractsOverdue, contractsUserObj.numContractsDueWithinWeek, contractsUserObj.numContractsDueLonger, contractsUserObj.numContractsFullyExecutedCancelled];
    const numContractsLabelsArray = ["Overdue", "Due Within a Week", "Due Longer than a Week", "Fully Executed/Cancelled"];
    const numContractsFontClassesArray = ["fontRed", "", "", "fontTextLight"];

    const isExpandedTF = JSFUNC.in_array(contractsUserObj.user_id, expandedUserIDsArray);

    var numCheckedCaptures = 0;
    for(let captureObj of capturesArrayOfObjs) {
      if(JSFUNC.in_array(captureObj.id, checkedCaptureIDsArray)) {
        numCheckedCaptures++;
      }
    }

    var userChecked012 = 0; //unchecked (no contracts checked)
    if(numCheckedCaptures === userNumCaptures) {
      userChecked012 = 1; //checked (all contracts checked)
    }
    else if(numCheckedCaptures > 0) {
      userChecked012 = 2; //partial
    }

    return(
      <>
        <div key="user" className="displayFlexRowVc lrMedMargin" style={{height:"4.5em", borderBottom:"solid 1px #d5d5d8"}}>
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"3em"}}>
            {(userNumCaptures > 0) &&
              <LibraryReact.CheckBox
                p_u0_s1_p2_du3_ds4={userChecked012}
                p_sizeEm={1.2}
                p_title={"Check or uncheck all contracts currently assigned to " + contractsUserObj.fullName}
                f_onClick={this.onclick_user_checkbox}
              />
            }
          </div>
          <div className="flex11a lrMargin" style={{flexBasis:"100em"}}>
            <LibraryReact.Nowrap>
              {contractsUserObj.fullName}
            </LibraryReact.Nowrap>
          </div>
          <div className="flex11a lrMedPad textCenter" style={{flexBasis:"100em", borderLeft:"solid 1px #ddd"}}>
            <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLighter">
              {"Assigned to"}
            </LibraryReact.Nowrap>
            <LibraryReact.Nowrap p_fontClass={((userNumCaptures > 0) ? ("font11") : ("fontItalic fontTextLighter"))}>
              {userNumCaptures + " " + JSFUNC.plural(userNumCaptures, "capture", "captures")}
            </LibraryReact.Nowrap>
          </div>
          {numContractsArray.map((m_numContracts, m_index) =>
            <div className="flex11a lrMedPad textCenter" style={{flexBasis:"100em", borderLeft:"solid 1px #ddd"}}>
              <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLighter">
                {numContractsLabelsArray[m_index]}
              </LibraryReact.Nowrap>
              <LibraryReact.Nowrap p_fontClass={"font14 " + ((m_numContracts > 0) ? ("fontBold " + numContractsFontClassesArray[m_index]) : ("fontItalic fontTextLighter"))}>
                {m_numContracts}
              </LibraryReact.Nowrap>
            </div>
          )}
          <div className="flex00a displayFlexRowHcVc lrMedPad" style={{flexBasis:"9em", borderLeft:"solid 1px #eee"}}>
            {(userNumCaptures > 0) ? (
              <div className="displayFlexRowVc">
                <div className="flex00a rMargin textCenter">
                  <font className="fontItalic fontTextLighter">
                    <div>{"View"}</div>
                    <div>{"Contracts"}</div>
                  </font>
                </div>
                <div className="flex00a">
                  <CEGeneralReact.ButtonExpandCollapse
                    p_isExpandedTF={isExpandedTF}
                    p_sizeEm={2.2}
                    p_title={undefined}
                    f_onClick={this.onclick_expand_collapse_user}
                  />
                </div>
              </div>
            ) : (
              <div className="flex00a textCenter">
                <font className="fontItalic fontTextLighter">
                  <div>{"0 Captures"}</div>
                  <div>{"Assigned"}</div>
                </font>
              </div>
            )}
          </div>
        </div>
        {(isExpandedTF) &&
          capturesArrayOfObjs.map((m_contractsExecWorkloadCaptureContainingContractsObj) =>
            <ContractsExecWorkloadCaptureWithContracts
              key={m_contractsExecWorkloadCaptureContainingContractsObj.id}
              p_contractsExecWorkloadCaptureContainingContractsObj={m_contractsExecWorkloadCaptureContainingContractsObj}
            />
          )
        }
      </>
    );
  }
}));

const ContractsExecWorkloadCaptureWithContracts = inject("TeammateContractsMobx")(observer(
class ContractsExecWorkloadCaptureWithContracts extends Component { //props: p_contractsExecWorkloadCaptureContainingContractsObj
  onclick_contract_checkbox = () => {
    const captureID = this.props.p_contractsExecWorkloadCaptureContainingContractsObj.id;
    this.props.TeammateContractsMobx.a_exec_check_or_uncheck_capture(captureID);
  }

  render() {
    const contractsExecWorkloadCaptureContainingContractsObj = this.props.p_contractsExecWorkloadCaptureContainingContractsObj;

    const captureID = this.props.p_contractsExecWorkloadCaptureContainingContractsObj.id;
    const captureFullName = this.props.p_contractsExecWorkloadCaptureContainingContractsObj.captureFullName;
    const expandedTeammateContractsArrayOfObjs = contractsExecWorkloadCaptureContainingContractsObj.expandedTeammateContractsArrayOfObjs;

    const isCheckedTF = JSFUNC.in_array(captureID, this.props.TeammateContractsMobx.o_execCheckedCaptureIDsArray.slice());

    return(
      <div className="displayFlexRow" style={{marginLeft:"1.5em", borderBottom:"solid 1px #aab", backgroundColor:"#f2eeee"}}>
        <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"3em"}}>
          <LibraryReact.CheckBox
            p_u0_s1_p2_du3_ds4={(isCheckedTF) ? (1) : (0)}
            p_sizeEm={1.2}
            p_title="Check this box to reassign this contract to the contracts manager selected above"
            f_onClick={this.onclick_contract_checkbox}
          />
        </div>
        <div className="flex11a">
          <div className="smallFullMargin">
            <font className="font11 fontItalic">{"Capture: "}</font>
            <font className="font11 fontBold">{captureFullName}</font>
          </div>
          <div className="lrMargin tbMedMargin" style={{backgroundColor:"#fff"}}>
            {expandedTeammateContractsArrayOfObjs.map((m_expandedTeammateContractObj) =>
              <TeammateContractItem
                key={m_expandedTeammateContractObj.id}
                p_mode="contractsTodo"
                p_expandedTeammateContractObj={m_expandedTeammateContractObj}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}));






//===================================================================================================================================================================================


export const ContractsTodo = inject("CaptureExecMobx", "TeammateContractsMobx")(observer(
class ContractsTodo extends Component {
  render() {
    return(
      <div className="flex11a displayFlexColumn">
        <div className="flex00a displayFlexRowVc flexWrap tbPad" style={{borderBottom:"solid 1px #ccc"}}>
          <div className="flex00a lrMedPad tbMargin" style={{flexBasis:"40em"}}>
            <font className="font14 fontBold fontTextLighter">
              {"Contracts Manager To Do Table"}
            </font>
          </div>
          <CaptureContractsFilterButtons />
        </div>
        <ContractsTodoSectionsContractsLists />
      </div>
    );
  }
}));


const ContractsTodoSectionsContractsLists = inject("TeammateContractsMobx")(observer(
class ContractsTodoSectionsContractsLists extends Component {
  render() {
    const captureContractsFilterFlag = this.props.TeammateContractsMobx.o_captureContractsFilterFlag;

    var todoContractsObj = undefined;
    if(captureContractsFilterFlag === "activeWonCapturesInProcessContracts") {
      todoContractsObj = this.props.TeammateContractsMobx.c_todoInProcessContractsDataObj;
    }
    else if(captureContractsFilterFlag === "activeWonCapturesFullyExecutedCancelledContracts") {
      todoContractsObj = this.props.TeammateContractsMobx.c_todoFullyExecutedCancelledContractsDataObj;
    }
    else if(captureContractsFilterFlag === "lostNoBidCancelledCapturesAllContracts") {
      todoContractsObj = this.props.TeammateContractsMobx.c_todoLostNoBidCancelledCaptureContractsDataObj;
    }
    else {
      return(
        <CEGeneralReact.EmptyScreenGray>
          {"Invalid capture contracts filter flag '" + captureContractsFilterFlag + "'"}
        </CEGeneralReact.EmptyScreenGray>
      );
    }

    if(todoContractsObj.numContracts === 0) {
      return(
        <CEGeneralReact.EmptyScreenGray>
          {"No Contracts (from filter '" + todoContractsObj.viewFilterName + "' above) assigned to you at this time"}
        </CEGeneralReact.EmptyScreenGray>
      );
    }

    return(
      <div className="flex11a yScroll yScrollBottomPad">
        {todoContractsObj.sectionContractsArrayOfArrayOfObjs.map((m_expandedTeammateContractsArrayOfObjs, m_index) =>
          (m_expandedTeammateContractsArrayOfObjs.length > 0) &&
          <div
            key={todoContractsObj.sectionNamesArray[m_index]}
            className="hugeBottomMargin">
            <div className="lrMedPad tbPad" style={{backgroundColor:"#e3e3e3"}}>
              <LibraryReact.Nowrap p_fontClass="font13 fontBold fontItalic fontTextLight">
                {todoContractsObj.sectionNamesArray[m_index] + " (" + m_expandedTeammateContractsArrayOfObjs.length + ")"}
              </LibraryReact.Nowrap>
            </div>
            {m_expandedTeammateContractsArrayOfObjs.map((m_expandedTeammateContractObj) =>
              <TeammateContractItem
                key={m_expandedTeammateContractObj.id}
                p_mode="contractsTodo"
                p_expandedTeammateContractObj={m_expandedTeammateContractObj}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}));




//===================================================================================================================================================================================


export const TeammateContractItem = inject("CaptureExecMobx", "TeammateContractsMobx", "ContactsMobx", "DatabaseMobx")(observer(
class TeammateContractItem extends Component { //props: p_mode, p_expandedTeammateContractObj, p_teammateObj (optional for collapsed light title teammate name)
  //p_mode
  //  - "captureTeammateCollapsed"  contract type short name with color bar below
  //  - "captureTeammateExpanded"   row with contract type full name, status
  //  - "contractsTodo"             in contracts todo table, gives contracts user privileges in advancing status

  onclick_teammate_contract = () => {
    const p_expandedTeammateContractObj = this.props.p_expandedTeammateContractObj;
    this.props.TeammateContractsMobx.a_open_teammate_contract(p_expandedTeammateContractObj.capture_id, p_expandedTeammateContractObj.teammate_id, p_expandedTeammateContractObj.teammate_contract_type_id);
  }

  render() {
    const p_mode = this.props.p_mode;
    const p_expandedTeammateContractObj = this.props.p_expandedTeammateContractObj;
    const p_teammateObj = this.props.p_teammateObj;

    const c_isMobileTF = this.props.CaptureExecMobx.c_isMobileTF;

    if(p_expandedTeammateContractObj === undefined) {
      return(
        <CEGeneralReact.EmptyScreenGray>
          {"p_expandedTeammateContractObj not passed to TeammateContractItem"}
        </CEGeneralReact.EmptyScreenGray>
      );
    }

    var modeIsTodoTF = false;
    var modeIsCollapsedTF = false;
    var modeIsExpandedTF = false;
    if(p_mode === "contractsTodo") { modeIsTodoTF = true; }
    else if(p_mode === "captureTeammateCollapsed") { modeIsCollapsedTF = true; }
    else if(p_mode === "captureTeammateExpanded") { modeIsExpandedTF = true; }

    //collapsed teammate version
    if(modeIsCollapsedTF) {
      var collapsedTitle = "";
      if(p_teammateObj !== undefined) {
        collapsedTitle += "Teammate: " + p_teammateObj.teammateNamePlainText;
      }
      collapsedTitle += "\nContract Type: " + p_expandedTeammateContractObj.typeNameAndShortName;
      collapsedTitle += "\nStatus: " + p_expandedTeammateContractObj.statusName;
      if(p_expandedTeammateContractObj.statusIsActiveTF) {
        collapsedTitle += "\nNeed By Date: " + p_expandedTeammateContractObj.maskDateNeededPlainText;
      }

      return(
        <div
          className="flex00a displayFlexColumnHcVc lrMargin cursorPointer"
          title={collapsedTitle}
          onClick={this.onclick_teammate_contract}>
          <div className="textCenter">
            <font className="fontItalic">
              {p_expandedTeammateContractObj.typeShortName}
            </font>
          </div>
          <div className="microTopMargin" style={{height:"0.5em", width:"2.5em", backgroundColor:"#" + p_expandedTeammateContractObj.statusColor}} />
        </div>
      );
    }

    const statusComponent = (
      <div
        className="flex11a"
        style={{flexBasis:"100em"}}
        title={p_expandedTeammateContractObj.statusName}>
        <div className="">
          <font className="fontItalic fontTextLight">
            {"Status"}
          </font>
        </div>
        <div className="displayFlexRowVc">
          <div className="flex00a rMargin" style={{flexBasis:"0.6em", height:"1.1em", backgroundColor:"#" + p_expandedTeammateContractObj.statusColor}} />
          <div className="flex11a">
            <LibraryReact.Nowrap>
              {p_expandedTeammateContractObj.statusName}
            </LibraryReact.Nowrap>
          </div>
        </div>
      </div>
    );

    //need by date
    var needByDateMask = undefined;
    if(modeIsTodoTF) {
      if(p_expandedTeammateContractObj.needByDateIncludeOverdueTF) {
        needByDateMask = p_expandedTeammateContractObj.maskDateNeededDayMdyDaysUntil1Overdue;
      }
      else {
        needByDateMask = p_expandedTeammateContractObj.maskDateNeededDayMdyDaysUntil1;
      }
    }
    else {
      needByDateMask = p_expandedTeammateContractObj.maskDateNeeded;
    }

    var needByDateComponent = (
      <div className="flex00a" style={{flexBasis:((modeIsTodoTF) ? ("17em") : ("8em"))}}>
        <div className="">
          <font className="fontItalic fontTextLight">
            {"Need By Date"}
          </font>
        </div>
        <div>
          {needByDateMask}
        </div>
      </div>
    );

    //contracts todo table
    if(modeIsTodoTF) {
      const agreementTypeNameMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(p_expandedTeammateContractObj.agreement_type_id, this.props.DatabaseMobx.c_selectTeammatesContractAgreementTypeFieldTypeObj);
      const captureNamePlainText = this.props.DatabaseMobx.capture_name_plaintext_from_capture_id(p_expandedTeammateContractObj.capture_id);
      const requestedByNameMaskSortIfoObj = this.props.DatabaseMobx.user_name_mask_sort_ifo_obj_from_user_id(p_expandedTeammateContractObj.requested_by_user_id);

      const fieldDisplayNamesArray = ["Contract Type", "Agreement Type", "Teammate", "Capture [ID: " + p_expandedTeammateContractObj.capture_id + "]", "Requested By"];
      const valueMasksArray = [p_expandedTeammateContractObj.typeNameAndShortName, agreementTypeNameMaskSortIfoObj.valueMask, p_expandedTeammateContractObj.teammateObj.teammateNamePlainText, captureNamePlainText, requestedByNameMaskSortIfoObj.valueMask];
      const valueMasksPlaintextArray = [p_expandedTeammateContractObj.typeNameAndShortName, agreementTypeNameMaskSortIfoObj.valueMaskPlainText, p_expandedTeammateContractObj.teammateObj.teammateNamePlainText, captureNamePlainText, requestedByNameMaskSortIfoObj.valueMaskPlainText];

      return(
        <div
          className="displayFlexRowVc smallTopMargin smallFullPad border bevelBorderColors hoverLighterBlueGradient cursorPointer"
          onClick={this.onclick_teammate_contract}>
          {needByDateComponent}
          {statusComponent}
          {fieldDisplayNamesArray.map((m_fieldDisplayName, m_index) =>
            <div
              key={m_fieldDisplayName}
              className="flex11a lrMargin"
              style={{flexBasis:"100em"}}
              title={m_fieldDisplayName + ": " + valueMasksPlaintextArray[m_index]}>
              <div>
                <font className="fontItalic fontTextLight">
                  {m_fieldDisplayName}
                </font>
              </div>
              <div>
                <LibraryReact.Nowrap>
                  {valueMasksArray[m_index]}
                </LibraryReact.Nowrap>
              </div>
            </div>
          )}
        </div>
      );
    }

    //capture teammates card expanded teammate
    if(c_isMobileTF) {
      return(
        <div
          className="smallTopMargin smallFullPad border bevelBorderColors hoverLighterBlueGradient cursorPointer"
          onClick={this.onclick_teammate_contract}>
          <div className="displayFlexRowVc" title={p_expandedTeammateContractObj.typeNameAndShortName}>
            <div className="flex11a lrMargin">
              {p_expandedTeammateContractObj.typeNameAndShortName}
            </div>
          </div>
          <div className="smallTopMargin">{statusComponent}</div>
          <div className="smallTopMargin">{needByDateComponent}</div>
        </div>
      );
    }

    return(
      <div
        className="displayFlexRowVc smallTopMargin smallFullPad border bevelBorderColors hoverLighterBlueGradient cursorPointer"
        onClick={this.onclick_teammate_contract}>
        <div className="flex11a rMargin" style={{flexBasis:"100em"}}>
          {p_expandedTeammateContractObj.typeNameAndShortName}
        </div>
        <div className="flex11a displayFlexRowVc" style={{flexBasis:"140em"}}>
          {statusComponent}
          {needByDateComponent}
        </div>
      </div>
    );
  }
}));





export const TeammateContractFloatingBox = inject("CaptureExecMobx", "TeammateContractsMobx")(observer(
class TeammateContractFloatingBox extends Component { //props:
  onclick_teammate_contract_floating_box_save = () => {
    this.props.TeammateContractsMobx.a_close_teammate_contract();
  }

  render() {
    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const c_openTeammateContractObj = this.props.TeammateContractsMobx.c_openTeammateContractObj;

    if(c_openTeammateContractObj === undefined) {
      return(null);
    }

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="teammateContracts"
        p_title={c_openTeammateContractObj.typeNameAndShortName + " contract process for teammate '" + c_openTeammateContractObj.teammateObj.teammateNamePlainText + "'"}
        f_onClickSave={this.onclick_teammate_contract_floating_box_save}>
        {(c_isMobileOrTabletTF) ? (
          <div className="flex11a yScroll yScrollBottomPad">
            <div className="smallFullMargin border borderRadius05" style={{borderColor:"#ccc"}}>
              <TeammateContractProcessLeft />
            </div>
            <div className="bigTopMargin bigBottomMargin" style={{borderBottom:"solid 1px #999"}} />
            <div className="smallFullMargin border borderRadius05" style={{borderColor:"#ccc"}}>
              <TeammateContractFileFolderSystem />
            </div>
          </div>
        ) : (
          <div className="flex11a displayFlexRow">
            <div className="flex11a yScroll yScrollBottomPad" style={{flexBasis:"125em", borderRight:"solid 2px #bbb"}}>
              <TeammateContractProcessLeft />
            </div>
            <div className="flex11a displayFlexColumn" style={{flexBasis:"100em"}}>
              <TeammateContractFileFolderSystem />
            </div>
          </div>
        )}
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));

const TeammateContractFileFolderSystem = inject("CaptureExecMobx", "TeammateContractsMobx")(observer(
class TeammateContractFileFolderSystem extends Component { //props:
  onperform_ffs_action = (i_actionText, i_descriptionText) => {
    this.props.TeammateContractsMobx.a_send_notification_after_files_uploads(i_actionText, i_descriptionText);
  }

  render() {
    const o_navigateToFolderIDTblCTeammatesContractsFileFolderSystem = this.props.CaptureExecMobx.o_navigateToFolderIDTblCTeammatesContractsFileFolderSystem;
    const c_openTeammateContractObj = this.props.TeammateContractsMobx.c_openTeammateContractObj;
    const c_teammatesContractsFileFolderSystemMapOfMaps = this.props.TeammateContractsMobx.c_teammatesContractsFileFolderSystemMapOfMaps;

    const teammateContractFfsTblFilterValuesObj = {
      capture_id: c_openTeammateContractObj.capture_id,
      teammate_id: c_openTeammateContractObj.teammate_id,
      contract_type_id: c_openTeammateContractObj.teammate_contract_type_id
    };

    return(
      <CEGeneralReact.FileFolderSystem
        p_tblName="tbl_c_teammates_contracts_filefoldersystem"
        p_tblMapOfMaps={c_teammatesContractsFileFolderSystemMapOfMaps}
        p_tblFilterValuesObj={teammateContractFfsTblFilterValuesObj}
        p_title="Uploaded/Generated Contract Document Versions"
        p_defaultXmlType={undefined}
        p_allowedExtsArray={undefined}
        p_disableUploadFilesTF={false}
        p_disableCreateOnlineLinkDocsTF={false}
        p_disableCreateFoldersTF={false}
        p_viewWideTrueNarrowFalse={false}
        p_navigateToFolderID={o_navigateToFolderIDTblCTeammatesContractsFileFolderSystem}
        f_onPerformFFSAction={this.onperform_ffs_action}
        f_onNavigateToFolder={this.props.CaptureExecMobx.a_set_navigate_to_folder_id_tbl_c_teammates_contracts_filefoldersystem}
      />
    );
  }
}));

const TeammateContractProcessLeft = inject("CaptureExecMobx", "TeammateContractsMobx", "OpenCaptureMobx", "DatabaseMobx", "UserMobx")(observer(
class TeammateContractProcessLeft extends Component { //props:
  constructor(props) {
    super(props);
    this.state = {
      s_initialNeedByDate: JSFUNC.blank_date(),
      s_initialAgreementType: -1,
      s_createRequestCommentText: "",
      s_showErrorsTF: false
    };
  }

  onclick_open_contract_capture = () => {
    const c_openTeammateContractObj = this.props.TeammateContractsMobx.c_openTeammateContractObj;
    this.props.OpenCaptureMobx.a_open_single_capture(c_openTeammateContractObj.capture_id);
  }

  onsavechanged_capture_contracts_manager = (i_selectedContractsManagerUserID) => {
    const c_fieldMapOfContractsManager = this.props.DatabaseMobx.c_fieldMapOfContractsManager;
    this.props.OpenCaptureMobx.a_details_update_field_value(c_fieldMapOfContractsManager, i_selectedContractsManagerUserID);
  }

  onchange_initial_need_by_date = (i_newValue) => {
    this.setState({s_initialNeedByDate:i_newValue});
  }

  onsavechanged_teammate_contract_need_by_date = (i_newValue) => {
    const c_openTeammateContractObj = this.props.TeammateContractsMobx.c_openTeammateContractObj;
    this.props.TeammateContractsMobx.a_update_teammate_contract_need_by_date(c_openTeammateContractObj.date_needed, i_newValue);
  }

  onchange_initial_agreement_type = (i_newValue) => {
    this.setState({s_initialAgreementType:i_newValue});
  }

  onsavechanged_teammate_contract_agreement_type = (i_newValue) => {
    const c_openTeammateContractObj = this.props.TeammateContractsMobx.c_openTeammateContractObj;
    this.props.TeammateContractsMobx.a_update_teammate_contract_agreement_type(c_openTeammateContractObj.agreement_type_id, i_newValue);
  }

  onchange_create_request_comment = (i_newValueString) => {
    this.setState({s_createRequestCommentText:i_newValueString});
  }

  onclick_start_request_contract = () => {
    const s_initialNeedByDate = this.state.s_initialNeedByDate;
    const s_initialAgreementType = this.state.s_initialAgreementType;
    const s_createRequestCommentText = this.state.s_createRequestCommentText;
    
    const o_nowDate = this.props.CaptureExecMobx.o_nowDate;
    const c_selectTeammatesContractAgreementTypeFieldTypeObj = this.props.DatabaseMobx.c_selectTeammatesContractAgreementTypeFieldTypeObj;
    const c_captureContractsManagerValueMaskSortIfoCanEditObj = this.props.TeammateContractsMobx.c_captureContractsManagerValueMaskSortIfoCanEditObj;

    const agreementTypeValueMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(s_initialAgreementType, c_selectTeammatesContractAgreementTypeFieldTypeObj);
    if(!JSFUNC.date_is_filled_out_tf(s_initialNeedByDate) || (s_initialNeedByDate < o_nowDate) || !agreementTypeValueMaskSortIfoObj.isFilledOutTF || !c_captureContractsManagerValueMaskSortIfoCanEditObj.isFilledOutTF) {
      this.setState({s_showErrorsTF:true});
    }
    else {
      this.props.TeammateContractsMobx.a_create_or_update_contract_from_pns_or_c_to_gsa(s_initialNeedByDate, s_initialAgreementType, s_createRequestCommentText);
    }
  }

  onclick_process_contract_myself = () => {
    const s_initialNeedByDate = this.state.s_initialNeedByDate;
    const s_initialAgreementType = this.state.s_initialAgreementType;
    const s_createRequestCommentText = this.state.s_createRequestCommentText;

    const c_captureContractsManagerValueMaskSortIfoCanEditObj = this.props.TeammateContractsMobx.c_captureContractsManagerValueMaskSortIfoCanEditObj;
    const c_valueDisplayArraysObjTeammatesContractAgreementTypes = this.props.DatabaseMobx.c_valueDisplayArraysObjTeammatesContractAgreementTypes;
    const c_selectTeammatesContractAgreementTypeFieldTypeObj = this.props.DatabaseMobx.c_selectTeammatesContractAgreementTypeFieldTypeObj;
    const o_userID = this.props.UserMobx.o_userID;

    //change capture contracts manager to myself if currently not set or someone else
    if(c_captureContractsManagerValueMaskSortIfoCanEditObj.valueRaw !== o_userID) {
      this.onsavechanged_capture_contracts_manager(o_userID);
    }

    //if need by date is not filled out, use today's date
    var myselfInitialNeedByDate = s_initialNeedByDate;
    if(!JSFUNC.date_is_filled_out_tf(s_initialNeedByDate)) {
      myselfInitialNeedByDate = JSFUNC.now_date();
      this.onsavechanged_teammate_contract_need_by_date(myselfInitialNeedByDate); //record contracts changelog entry making this today
    }

    //if agreement type is not filled out, pick the first one from the sorted admin tbl
    var myselfInitialAgreementType = s_initialAgreementType;
    const agreementTypeValueMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(s_initialAgreementType, c_selectTeammatesContractAgreementTypeFieldTypeObj);
    if(!agreementTypeValueMaskSortIfoObj.isFilledOutTF) {
      myselfInitialAgreementType = DatabaseMobx.get_first_sorted_value_from_value_display_arrays_obj_or_sws_data_obj(c_valueDisplayArraysObjTeammatesContractAgreementTypes, -1);
      this.onsavechanged_teammate_contract_agreement_type(myselfInitialAgreementType); //record contracts changelog entry of picking first admin agreement type
    }

    this.props.TeammateContractsMobx.a_create_or_update_contract_from_pns_or_c_to_gsa(myselfInitialNeedByDate, myselfInitialAgreementType, s_createRequestCommentText);
  }

  onclick_restart_contract_process_from_cancelled = () => {
    const c_statusGenerateSendAgreementObj = this.props.TeammateContractsMobx.c_statusGenerateSendAgreementObj;
    const statusChangeNotes = undefined;
    this.props.TeammateContractsMobx.a_update_teammate_contract_status(c_statusGenerateSendAgreementObj, statusChangeNotes);
  }

  onclick_contract_process_subtab = (i_selectedTabDbName) => {
    this.props.TeammateContractsMobx.a_set_contract_process_tab_open(i_selectedTabDbName);
  }

  render() {
    const s_initialNeedByDate = this.state.s_initialNeedByDate;
    const s_initialAgreementType = this.state.s_initialAgreementType;
    const s_createRequestCommentText = this.state.s_createRequestCommentText;
    const s_showErrorsTF = this.state.s_showErrorsTF;

    const o_nowDate = this.props.CaptureExecMobx.o_nowDate;
    const o_openContractFromContractsTodoTF = this.props.TeammateContractsMobx.o_openContractFromContractsTodoTF;
    const o_contractProcessTabOpen = this.props.TeammateContractsMobx.o_contractProcessTabOpen;
    const c_openTeammateContractObj = this.props.TeammateContractsMobx.c_openTeammateContractObj;
    const c_captureContractsManagerValueMaskSortIfoCanEditObj = this.props.TeammateContractsMobx.c_captureContractsManagerValueMaskSortIfoCanEditObj;
    const c_captureContractRequestedByUserIDIsCurrentlyLoggedInUserTF = this.props.TeammateContractsMobx.c_captureContractRequestedByUserIDIsCurrentlyLoggedInUserTF;
    const c_openContractCaptureContractsManagerUserIDsToNotIncludeArray = this.props.TeammateContractsMobx.c_openContractCaptureContractsManagerUserIDsToNotIncludeArray;
    const c_fieldMapOfContractsManager = this.props.DatabaseMobx.c_fieldMapOfContractsManager;
    const c_selectTeammatesContractAgreementTypeFieldTypeObj = this.props.DatabaseMobx.c_selectTeammatesContractAgreementTypeFieldTypeObj;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;
    const c_userCanManageTeammateContractsProcessTF = this.props.UserMobx.c_userCanManageTeammateContractsProcessTF;

    const ceItemEditStringStart = "teammateContracts_cID" + c_openTeammateContractObj.capture_id + "tID" + c_openTeammateContractObj.teammate_id + "tctID" + c_openTeammateContractObj.teammate_contract_type_id;
    const editFieldWidth = "9em";

    //contracts manager user select field
    var numContractsOrContractsExecUsers = 0;
    const contractsManagerFieldTypeObj = c_fieldMapOfContractsManager.get("fieldTypeObj");
    if(contractsManagerFieldTypeObj !== undefined) {
      if(contractsManagerFieldTypeObj.selectWithSearchDataObj !== undefined) {
        if(JSFUNC.is_array(contractsManagerFieldTypeObj.selectWithSearchDataObj.valueArray)) {
          numContractsOrContractsExecUsers = contractsManagerFieldTypeObj.selectWithSearchDataObj.valueArray.length;
        }
      }
    }

    const nbdCanBeEditedTF = ((!o_openContractFromContractsTodoTF && !c_userCanManageTeammateContractsProcessTF) || (c_openTeammateContractObj.statusIsProcessNotStartedTF || c_openTeammateContractObj.statusIsCancelledTF) || c_captureContractRequestedByUserIDIsCurrentlyLoggedInUserTF); //can edit if ((open from capture Teammate Card && capture person) || (process not started or cancelled status) || (I requested this contract))

    //need by date
    const nbdFieldDisplayName = "Need By Date";
    const nbdFieldClass = "font12 fontBold fontTextLighter";
    var needByDateComponent = null; //capture manager initially has the need by date inside of the timeline box
    if(nbdCanBeEditedTF) { //can edit need by date
      if(c_openTeammateContractObj.statusIsProcessNotStartedTF) {
        var nbdErrorMessage = undefined;
        if(s_showErrorsTF) {
          if(!JSFUNC.date_is_filled_out_tf(s_initialNeedByDate)) {
            nbdErrorMessage = "A Need By Date of when this contract is to be fully executed must be set";
          }
          else if(s_initialNeedByDate < o_nowDate) {
            nbdErrorMessage = "The selected Need By Date must be today or later";
          }
        }

        needByDateComponent = (
          <>
            <div className="displayFlexRowVc">
              <div className="flex00a" style={{flexBasis:editFieldWidth}}>
                <font className={nbdFieldClass}>
                  {nbdFieldDisplayName}
                </font>
              </div>
              <div className="flex11a">
                {(c_userCanEditCaptureCardContentTF) ? (
                  <LibraryReact.Date
                    p_value={s_initialNeedByDate}
                    p_errorTF={(nbdErrorMessage !== undefined)}
                    f_onChange={this.onchange_initial_need_by_date}
                  />
                ) : (
                  this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(s_initialNeedByDate, this.props.DatabaseMobx.c_genericDateFieldTypeObj)
                )}
              </div>
            </div>
            {(nbdErrorMessage !== undefined) &&
              <CEGeneralReact.ErrorText p_class="microTopMargin" p_text={nbdErrorMessage} />
            }
          </>
        );
      }
      else { //have to edit the need by date to change it from its original value
        needByDateComponent = (
          <CEGeneralReact.CaptureExecFieldEditSaveCancel
            p_ceEditItemString={ceItemEditStringStart + "needdate"}
            p_fieldDisplayName={nbdFieldDisplayName}
            p_fieldTypeObj={this.props.DatabaseMobx.c_genericDateDayMdyDaysUntil1OverdueFieldTypeObj}
            p_valueRaw={c_openTeammateContractObj.date_needed}
            p_valueIsEditableTFU={c_userCanEditCaptureCardContentTF}
            p_fieldClass={nbdFieldClass}
            p_fieldWidth={editFieldWidth}
            p_fieldNowrapTF={true}
            p_fieldValueVerticallyAlignedTF={true}
            f_onSaveChanged={this.onsavechanged_teammate_contract_need_by_date}
          />
        );
      }
    }
    else { //contracts manager cannot edit the need by date
      needByDateComponent = (
        <div className="displayFlexRowVc flexWrap">
          <div className="flex00a" style={{flexBasis:editFieldWidth}}>
            <font className={nbdFieldClass}>
              {nbdFieldDisplayName}
            </font>
          </div>
          <div className="flex00a">
            {c_openTeammateContractObj.maskDateNeededDayMdyDaysUntil1}
          </div>
        </div>
      );
    }

    //agreement type
    const atFieldDisplayName = "Agreement Type";
    const atFieldClass = "fontBlue";
    var agreementTypeComponent = null;
    if(c_openTeammateContractObj.statusIsProcessNotStartedTF) { //for PNS, the edit box of the date is displayed (not behind an edit icon)
      const agreementTypeErrorTF = (s_showErrorsTF && !JSFUNC.select_int_is_filled_out_tf(s_initialAgreementType));

      agreementTypeComponent = (
        <>
          <div className="displayFlexRowVc">
            <div className="flex00a" style={{flexBasis:editFieldWidth}}>
              <font className={atFieldClass}>
                {atFieldDisplayName}
              </font>
            </div>
            <div className="flex11a">
              {(c_userCanEditCaptureCardContentTF) ? (
                <CEGeneralReact.GenericInputOrSelectFromInputType
                  p_fieldTypeObj={c_selectTeammatesContractAgreementTypeFieldTypeObj}
                  p_valueRaw={s_initialAgreementType}
                  p_errorTF={agreementTypeErrorTF}
                  f_onChangeOrOnSelect={this.onchange_initial_agreement_type}
                />
              ) : (
                this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(s_initialAgreementType, c_selectTeammatesContractAgreementTypeFieldTypeObj)
              )}
            </div>
          </div>
          {(agreementTypeErrorTF) &&
            <CEGeneralReact.ErrorText p_class="microTopMargin" p_text="Select an Agreement Type before sending this request" />
          }
        </>
      );
    }
    else { //have to edit the agreement type to change it from its original value
      agreementTypeComponent = (
        <CEGeneralReact.CaptureExecFieldEditSaveCancel
          p_ceEditItemString={ceItemEditStringStart + "agreement"}
          p_fieldDisplayName={atFieldDisplayName}
          p_fieldTypeObj={c_selectTeammatesContractAgreementTypeFieldTypeObj}
          p_valueRaw={c_openTeammateContractObj.agreement_type_id}
          p_valueIsEditableTFU={c_userCanEditCaptureCardContentTF && nbdCanBeEditedTF}
          p_fieldClass={atFieldClass}
          p_fieldWidth={editFieldWidth}
          f_onSaveChanged={this.onsavechanged_teammate_contract_agreement_type}
        />
      );
    }

    //status timeline
    var statusTimelineComponent = null;
    if(numContractsOrContractsExecUsers === 0) { //if there are 0 contracts/contractsexec user licenses in this captureexec system, display a message
      statusTimelineComponent = (
        <div className="displayFlexColumnHcVc medFullPad">
          <font className="fontItalic fontTextLighter">
            {"Your company does not have any Contracts Department licenses registered for this CaptureExec system.  The automated contracts processing feature is not available until at least 1 license is preset to handle the requests from capture managers."}
          </font>
        </div>
      );
    }
    else if(c_openTeammateContractObj.statusIsProcessNotStartedTF) {
      statusTimelineComponent = (
        <>
          <div className="displayFlexColumnHcVc medFullPad">
            <div className="textCenter">
              <font className="font13 fontTextLight">
                {c_openTeammateContractObj.typeShortName + " Contract Request Not Yet Made"}
              </font>
            </div>
            {(c_userCanEditCaptureCardContentTF) &&
              <>
                <div className="medTopMargin smallBottomMargin" style={{width:"80%"}}>
                  <div className="microBottomMargin">
                    <font className="fontItalic fontTextLight">
                      {"Note to the Contracts Manager for this request (optional)"}
                    </font>
                  </div>
                  <LibraryReact.Textarea
                    p_value={s_createRequestCommentText}
                    p_styleObj={{height:"6em"}}
                    f_onChange={this.onchange_create_request_comment}
                  />
                </div>
                <div className="displayFlexRowVc flexWrap">
                  <div className="flex00a tbMicroMargin lrMedMargin">
                    <CEGeneralReact.CEButton
                      p_type="blue"
                      p_text={"Request our Contracts Dept to Process this " + c_openTeammateContractObj.typeShortName}
                      f_onClick={this.onclick_start_request_contract}
                    />
                  </div>
                  {(c_userCanManageTeammateContractsProcessTF) &&
                    <div className="flex00a tbMicroMargin lrMedMargin">
                      <CEGeneralReact.CEButton
                        p_type="green"
                        p_text={"Process this " + c_openTeammateContractObj.typeShortName + " Myself"}
                        f_onClick={this.onclick_process_contract_myself}
                      />
                    </div>
                  }
                </div>
              </>
            }
          </div>
        </>
      );
    }
    else if(c_openTeammateContractObj.statusIsCancelledTF) {
      statusTimelineComponent = (
        <div className="displayFlexColumnHcVc medFullPad">
          <div className="textCenter">
            <font className="font13 fontTextLight">
              {"This " + c_openTeammateContractObj.typeShortName + " Contract Request is currently Cancelled"}
            </font>
          </div>
          {(c_userCanEditCaptureCardContentTF) &&
            <>
              <div className="medTopMargin" />
              <CEGeneralReact.CEButton
                p_type="gray"
                p_text="Reactivate this Contract Process"
                f_onClick={this.onclick_restart_contract_process_from_cancelled}
              />
            </>
          }
        </div>
      );
    }
    else {
      statusTimelineComponent = (
        <>
          <div className="medBottomMargin textCenter">
            <font className="font13 fontTextLight">
              {"Contract Status Timeline: "}<font className="fontBold">{c_openTeammateContractObj.typeShortName}</font>{" for " + c_openTeammateContractObj.teammateObj.teammateNamePlainText}
            </font>
          </div>
          <StatusTimeline />
        </>
      );
    }

    return(
      <>
        <div className="tbPad lrMedPad">
          <div className="tbMicroPad">
            <CEGeneralReact.CaptureExecFieldEditSaveCancel
              p_ceEditItemString={ceItemEditStringStart + "capture"}
              p_fieldDisplayName="Capture"
              p_fieldTypeObj={((o_openContractFromContractsTodoTF) ? (this.props.DatabaseMobx.c_selectAllCapturesDisplayWithClickLinkFieldTypeObj) : (this.props.DatabaseMobx.c_selectAllCapturesNoClearFieldTypeObj))}
              p_valueRaw={c_openTeammateContractObj.capture_id}
              p_valueIsEditableTFU={false}
              p_fieldClass="fontBlue"
              p_fieldWidth={editFieldWidth}
              f_onSaveChanged={undefined}
            />
          </div>
          <div className="tbMicroPad">
            <CEGeneralReact.CaptureExecFieldEditSaveCancel
              p_ceEditItemString={ceItemEditStringStart + "contractsmanager"}
              p_fieldDisplayName={c_fieldMapOfContractsManager.get("display_name")}
              p_fieldTypeObj={contractsManagerFieldTypeObj}
              p_valueRaw={c_captureContractsManagerValueMaskSortIfoCanEditObj.valueRaw}
              p_valuesToNotIncludeArray={c_openContractCaptureContractsManagerUserIDsToNotIncludeArray}
              p_valueIsEditableTFU={(c_userCanEditCaptureCardContentTF && nbdCanBeEditedTF)}
              p_fieldClass="fontBlue"
              p_fieldWidth={editFieldWidth}
              f_onSaveChanged={this.onsavechanged_capture_contracts_manager}
            />
            {(s_showErrorsTF && !o_openContractFromContractsTodoTF && !c_captureContractsManagerValueMaskSortIfoCanEditObj.isFilledOutTF) &&
              <CEGeneralReact.ErrorText p_class="microTopMargin" p_text={"Select a " + c_fieldMapOfContractsManager.get("display_name") + " to handle Teammate Contract requests for this Capture"} />
            }
          </div>
          <div className="displayFlexRowVc flexWrap">
            <div className="flex00a tbMicroPad" style={{flexBasis:"35em"}}>
              {needByDateComponent}
            </div>
            <div className="flex00a tbMicroPad" style={{flexBasis:"35em"}}>
              {agreementTypeComponent}
            </div>
          </div>
        </div>
        <div className="borderT1bbb borderB1bbb bgLighterGrayGradient medFullPad">
          {statusTimelineComponent}
        </div>
        {(o_openContractFromContractsTodoTF) &&
          <div className="displayFlexRow" style={{padding:"0.6em 1.5em"}}>
            <CEGeneralReact.TabsList
              p_tabDbNamesArray={["sendEmail", "history"]}
              p_tabDisplayNamesArray={["Generate / Send Agreement", "Process History"]}
              p_selectedTabDbName={o_contractProcessTabOpen}
              p_tabHeight="2.4em"
              p_textMaxHeight="2.2em"
              p_tabWidth={undefined}
              p_selectedBgClass="bgTeammateContractsProcessSubtabSelected"
              p_selectedFontClass="font11 fontBold"
              p_unselectedBgClass="bgTeammateContractsProcessSubtab hoverLighterBlueGradient"
              p_unselectedFontClass="font11 fontBold"
              f_onSelect={this.onclick_contract_process_subtab}
            />
          </div>
        }
        {(!o_openContractFromContractsTodoTF || (o_contractProcessTabOpen === "history")) ? (
          <TeammateContractProcessHistory />
        ) : (
          <TeammateContractProcessGenerateAgreement />
        )}
      </>
    );
  }
}));


export const StatusTimeline = inject("CaptureExecMobx", "TeammateContractsMobx", "UserMobx")(observer(
class StatusTimeline extends Component { //props:
  onclick_mobile_advance_status_button_toggle = () => {
    const o_mobileAdvancingStatusTF = this.props.TeammateContractsMobx.o_mobileAdvancingStatusTF;
    this.props.TeammateContractsMobx.a_set_mobile_advance_status_tf(!o_mobileAdvancingStatusTF);
  }

  onclick_confirm_cancel_contract_process = (i_cancellationNotes) => {
    const c_statusCancelledObj = this.props.TeammateContractsMobx.c_statusCancelledObj;
    const c_openTeammateContractObj = this.props.TeammateContractsMobx.c_openTeammateContractObj;
    this.props.TeammateContractsMobx.a_update_teammate_contract_status(c_statusCancelledObj, i_cancellationNotes);
    this.props.TeammateContractsMobx.a_update_teammate_contract_need_by_date(c_openTeammateContractObj.date_needed, JSFUNC.blank_date());
    this.props.TeammateContractsMobx.a_update_teammate_contract_agreement_type(c_openTeammateContractObj.agreement_type_id, -1);
  }

  render() {
    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const o_mobileAdvancingStatusTF = this.props.TeammateContractsMobx.o_mobileAdvancingStatusTF;
    const o_openContractFromContractsTodoTF = this.props.TeammateContractsMobx.o_openContractFromContractsTodoTF; //only contracts users opening the contract from a todo table can drag/drop to change the status
    const c_openTeammateContractObj = this.props.TeammateContractsMobx.c_openTeammateContractObj;
    const c_statusGenerateSendAgreementObj = this.props.TeammateContractsMobx.c_statusGenerateSendAgreementObj;
    const c_statusesTeammateIsReviewingArrayOfObjs = this.props.TeammateContractsMobx.c_statusesTeammateIsReviewingArrayOfObjs;
    const c_statusesWeAreReviewingArrayOfObjs = this.props.TeammateContractsMobx.c_statusesWeAreReviewingArrayOfObjs;
    const c_statusFullyExecutedObj = this.props.TeammateContractsMobx.c_statusFullyExecutedObj;
    const c_statusInvalidObj = this.props.TeammateContractsMobx.c_statusInvalidObj;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    var statusTimelineVerticalDotsMenuComponent = null;
    if(c_userCanEditCaptureCardContentTF && (c_openTeammateContractObj !== undefined)) {
      var cancelConfirmMessage = "Are you sure you want to cancel this contract process? (You can always restart the process later without losing any data or documents)";
      cancelConfirmMessage += "\n - Teamate: " + c_openTeammateContractObj.teammateObj.teammateNamePlainText;
      cancelConfirmMessage += "\n - Contract Type: " + c_openTeammateContractObj.typeNameAndShortName;
      cancelConfirmMessage += "\n\n(optional) You can write a message to the Contracts Manager here";

      const statusTimelineMenuItemsArrayOfObjs = [{
        displayName: "Cancel Process",
        confirmType: "inputTextarea",
        confirmTitle: "Cancel Teammate Contracts Process",
        confirmButton1Name: "Cancel Process",
        confirmButton1Type: "red",
        boxShadowColor: "bd2326",
        textareaHeight: "8em",
        confirmTextMustBeFilledOutTF: false,
        confirmTextMustBeChangedForSaveTFU: false,
        confirmMessage: cancelConfirmMessage,
        functionOnClickConfirmButton: this.onclick_confirm_cancel_contract_process
      }];
      
      statusTimelineVerticalDotsMenuComponent = (
        <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"1em", height:"2em"}}>
          <CEGeneralReact.VerticalDotsMenu
            p_menuItemsArrayOfObjs={statusTimelineMenuItemsArrayOfObjs}
          />
        </div>
      );
    }

    return(
      <>
        {(c_userCanEditCaptureCardContentTF && o_openContractFromContractsTodoTF && c_isMobileOrTabletTF) &&
          <div className="displayFlexColumnHcVc tbMedMargin">
            <CEGeneralReact.CEButton
              p_type="gray"
              p_text={((!o_mobileAdvancingStatusTF) ? ("Advance the Status") : ("Select the Status to Advance to"))}
              f_onClick={this.onclick_mobile_advance_status_button_toggle}
            />
          </div>
        }
        <div className="displayFlexRowVc">
          {(c_statusInvalidObj !== undefined) &&
            <div className="flex11a lrPad" style={{flexBasis:"100em"}}>
              <StatusTimelineStatusBlock p_statusObj={c_statusInvalidObj} />
            </div>
          }
          <div className="flex11a lrPad" style={{flexBasis:"100em"}}>
            <StatusTimelineStatusBlock p_statusObj={c_statusGenerateSendAgreementObj} />
          </div>
          {(c_statusesTeammateIsReviewingArrayOfObjs.length > 0) &&
            <div className="flex11a" style={{flexBasis:"100em"}}>
              {c_statusesTeammateIsReviewingArrayOfObjs.map((m_statusObj) =>
                <div className="lrPad microTopPad microBottomPad">
                  <StatusTimelineStatusBlock p_statusObj={m_statusObj} />
                </div>
              )}
            </div>
          }
          {(c_statusesWeAreReviewingArrayOfObjs.length > 0) &&
            <div className="flex11a" style={{flexBasis:"100em"}}>
              {c_statusesWeAreReviewingArrayOfObjs.map((m_statusObj) =>
                <div className="lrPad microTopPad microBottomPad">
                  <StatusTimelineStatusBlock p_statusObj={m_statusObj} />
                </div>
              )}
            </div>
          }
          <div className="flex11a lrPad" style={{flexBasis:"100em"}}>
            <StatusTimelineStatusBlock p_statusObj={c_statusFullyExecutedObj} />
          </div>
          {statusTimelineVerticalDotsMenuComponent}
        </div>
      </>
    );
  }
}));

const StatusTimelineStatusBlock = inject("TeammateContractsMobx", "UserMobx")(observer(
class StatusTimelineStatusBlock extends Component { //props: p_statusObj
  constructor(props) {
    super(props);
    this.state = {
      s_dragoverTF: false,
      s_changeStatusNotesBoxOpenTF: false
    };
  }

  ondragover_status = (i_isDragOverTF) => {
    this.setState({s_dragoverTF:i_isDragOverTF});
  }

  ondrop_status = () => {
    this.setState({s_changeStatusNotesBoxOpenTF:true});
    this.props.TeammateContractsMobx.a_set_mobile_advance_status_tf(false);
  }

  onconfirm_status_change_with_notes = (i_newNotes) => {
    this.props.TeammateContractsMobx.a_update_teammate_contract_status(this.props.p_statusObj, i_newNotes);
    this.setState({s_changeStatusNotesBoxOpenTF:false});
  }

  oncancel_status_change_with_notes = () => {
    this.setState({s_changeStatusNotesBoxOpenTF:false});
  }

  onclick_mobile_status_to_advance_to = () => {
    this.setState({s_changeStatusNotesBoxOpenTF:true});
    this.props.TeammateContractsMobx.a_set_mobile_advance_status_tf(false);
  }

  render() {
    const s_dragoverTF = this.state.s_dragoverTF;
    const s_changeStatusNotesBoxOpenTF = this.state.s_changeStatusNotesBoxOpenTF;

    const p_statusObj = this.props.p_statusObj;

    const contractsTodoTF = this.props.TeammateContractsMobx.o_openContractFromContractsTodoTF; //only contracts users opening the contract from a todo table can drag/drop to change the status
    const mobileAdvancingStatusTF = this.props.TeammateContractsMobx.o_mobileAdvancingStatusTF;
    const c_statusGenerateSendAgreementObj = this.props.TeammateContractsMobx.c_statusGenerateSendAgreementObj;
    const c_statusFullyExecutedObj = this.props.TeammateContractsMobx.c_statusFullyExecutedObj;
    const c_openTeammateContractObj = this.props.TeammateContractsMobx.c_openTeammateContractObj;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    var statusObjOrM98 = p_statusObj;
    if(p_statusObj === undefined) {
      statusObjOrM98 = {id:-98};
    }

    var openTeammateContractObjOrM97 = c_openTeammateContractObj;
    if(c_openTeammateContractObj === undefined) {
      openTeammateContractObjOrM97 = {status_id:-97};
    }

    const isCurrentStatusTF = (statusObjOrM98.id === openTeammateContractObjOrM97.status_id);
    const isPastStatusTF = (!isCurrentStatusTF && ((openTeammateContractObjOrM97.statusIsFullyExecutedTF) || (statusObjOrM98.id === c_statusGenerateSendAgreementObj.id)));
    const isFutureStatusTF = (!isCurrentStatusTF && !isPastStatusTF);

    const isFullyExecutedStatusTF = (statusObjOrM98.id === c_statusFullyExecutedObj.id);
    const isDraggableTF = (c_userCanEditCaptureCardContentTF && contractsTodoTF && isCurrentStatusTF);
    const isDroppableTF = (c_userCanEditCaptureCardContentTF && contractsTodoTF && !isCurrentStatusTF);
    const isClickableTF = (c_userCanEditCaptureCardContentTF && !isCurrentStatusTF && mobileAdvancingStatusTF);

    //title
    var statusTitle = "'" + statusObjOrM98.name + "' Status";
    statusTitle += "\n[Drag/drop current blue status block to change to a new status]";

    //border radius (in process status round vs fully executed square)
    const bgClass = ((isCurrentStatusTF) ? ("bgBlueGradient") : (""));
    const bgDraggableClass = ((isDraggableTF) ? ("bgBlueGradientHover cursorGrab") : (""));
    const bgClickToAdvanceClass = ((isClickableTF) ? ("hoverLighterBlueGradient cursorPointer") : (""));
    const borderRadius = ((isFullyExecutedStatusTF) ? ("0") : ("1em"));

    //border color
    var borderColor = "#333";
    if(s_dragoverTF || isClickableTF) {
      borderColor = "#9d9";
    }
    else if(isPastStatusTF) {
      borderColor = "#ddd";
    }
    else if(isCurrentStatusTF) {
      borderColor = "#4a8bbd";
    }
    else if(isFutureStatusTF) {
      borderColor = "#bbb";
    }

    //font class
    var statusFontClass = "fontBold fontTextLight"; //future and invalid stages have normal font
    if(isPastStatusTF) {
      statusFontClass = "fontItalic fontTextLighter"
    }
    else if(isCurrentStatusTF) {
      statusFontClass = "fontBold fontWhite"
    }

    return(
      <div className="displayFlexRow" style={{borderRadius:borderRadius, backgroundColor:borderColor}}>
        <CEGeneralReact.CE3Drag3Drop3Shell3
          p_uniqueString="teammateContractTimelineStatus"
          p_itemID={statusObjOrM98.id}
          p_draggableTF={isDraggableTF}
          p_droppableTF={isDroppableTF}
          p_dropZoneIsInvisibleOverlayTF={true}
          p_dropZoneOversizeWidthEm={undefined}
          p_class={"flex11a displayFlexRow " + bgClass + " " + bgDraggableClass + " " + bgClickToAdvanceClass}
          p_styleObj={{height:"3.3em", border:"solid 2px " + borderColor, backgroundColor:"#fff"}}
          p_title={statusTitle}
          p_dragOverClass={undefined}
          p_dragOverStyleObj={{backgroundColor:"rgba(0,100,120,0.1)"}}
          f_isDragOverTF={this.ondragover_status}
          f_onDropMatchingItem={this.ondrop_status}
          f_onClick={((mobileAdvancingStatusTF) ? (this.onclick_mobile_status_to_advance_to) : (undefined))}>
          {(isDraggableTF) &&
            <div className="flex00a displayFlexColumnHcVc" style={{marginLeft:"0.2em"}}>
              <CEGeneralReact.SvgDraggableDots />
            </div>
          }
          <div className="flex11a displayFlexRowVc lrPad">
            <div className="rMargin border bevelBorderColors" style={{width:"1em", height:"1.5em", backgroundColor:"#" + statusObjOrM98.color}} />
            <LibraryReact.MaxHeightWrap p_maxHeight="2.4em" p_fontClass={statusFontClass}>
              {statusObjOrM98.name}
            </LibraryReact.MaxHeightWrap>
          </div>
        </CEGeneralReact.CE3Drag3Drop3Shell3>
        <div className="flex00a" style={{flexBasis:"1.5em"}} />
        {(s_changeStatusNotesBoxOpenTF) &&
          <CEGeneralReact.ConfirmBox
            p_type="inputTextarea"
            p_title={"Advance Contract Status Notes"}
            p_button1Name="Advance"
            p_textMustBeFilledOutTF={false}
            f_onClickConfirm={this.onconfirm_status_change_with_notes}
            f_onClickCancel={this.oncancel_status_change_with_notes}>
            <div className="medBottomMargin">{"Advancing contract status to '"}<font className="fontBold">{statusObjOrM98.name}</font>{"'"}</div>
            <div>{"Enter below any notes you have about the current status of this contract and any uploaded documents"}</div>
          </CEGeneralReact.ConfirmBox>
        }
      </div>
    );
  }
}));


const TeammateContractProcessHistory = inject("TeammateContractsMobx", "UserMobx")(observer(
class TeammateContractProcessHistory extends Component { //props:
  onclick_confirm_add_note = (i_noteText) => {
    this.props.TeammateContractsMobx.a_contracts_add_note_to_process_history(i_noteText);
  }

  render() {
    const o_openContractFromContractsTodoTF = this.props.TeammateContractsMobx.o_openContractFromContractsTodoTF;
    const c_openTeammateContractObj = this.props.TeammateContractsMobx.c_openTeammateContractObj;
    const c_teammateContractChangeLogArrayOfObjs = this.props.TeammateContractsMobx.c_teammateContractChangeLogArrayOfObjs;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    var addNoteButtonComponent = null;
    if(c_userCanEditCaptureCardContentTF && !c_openTeammateContractObj.statusIsProcessNotStartedTF) {
      var addNoteButtonText = "Add Note to ";
      var addNoteConfirmMessage = "Enter a note about this contract (" + c_openTeammateContractObj.typeShortName + " for " + c_openTeammateContractObj.teammateObj.teammateNamePlainText + ") for the ";
      if(o_openContractFromContractsTodoTF) { //contracts manager send note to capture manager(s)
        addNoteButtonText += "Capture Manager";
        addNoteConfirmMessage += "Capture Manager.";
      }
      else { //capture manager send note to contracts manager
        addNoteButtonText += "Contracts Manager";
        addNoteConfirmMessage += "Contracts Manager.";
      }
      
      addNoteButtonComponent = (
        <div className="displayFlexColumnHcVc tbMedPad">
          <CEGeneralReact.ButtonWithConfirmBox
            p_buttonType="blue"
            p_buttonText={addNoteButtonText}
            p_confirmType="inputTextarea"
            p_confirmMessage={addNoteConfirmMessage}
            f_onClickConfirm={this.onclick_confirm_add_note}
          />
        </div>
      );
    }
    
    return(
      <div className="medFullMargin">
        {addNoteButtonComponent}
        <div className="smallBottomMargin">
          <font className="font12 fontBold fontTextLight">
            {"Process History"}
          </font>
        </div>
        {(c_teammateContractChangeLogArrayOfObjs.length === 0) ? (
          <div className="microTopPad microBottomPad">
            <font className="fontItalic fontTextLighter">
              {"--Contract process not yet started--"}
            </font>
          </div>
        ) : (
          c_teammateContractChangeLogArrayOfObjs.map((m_changeLogObj) =>
            <TeammateContractChangeLogRow
              key={m_changeLogObj.id}
              p_changeLogObj={m_changeLogObj}
            />
          )
        )}
      </div>
    );
  }
}));


const TeammateContractChangeLogRow = inject("TeammateContractsMobx","DatabaseMobx")(observer(
class TeammateContractChangeLogRow extends Component { //props: p_changeLogObj
  constructor(props) {
    super(props);
    this.state = {
      s_showRowDetailsFloatingBoxTF: false
    }
  }

  onclick_changelog_row = () => {
    this.setState({s_showRowDetailsFloatingBoxTF:true});
  }

  onclick_close_changelog_detail = () => {
    this.setState({s_showRowDetailsFloatingBoxTF:false});
  }

  render() {
    const showRowDetailsFloatingBoxTF = this.state.s_showRowDetailsFloatingBoxTF;

    const changeLogObj = this.props.p_changeLogObj;

    const changeLogFieldNamesArray = ["dateTimeMask", "user", "field", "value"];
    const changeLogFieldDisplayNamesArray = ["Date/Time", "User", "Action", "Notes"];

    return(
      <div
        className="displayFlexRowVc smallTopPad smallBottomPad hoverLighterBlueGradient cursorPointer"
        style={{borderBottom:"solid 1px #eee"}}
        title="Click to read the full text of this Contracts Process History record"
        onClick={this.onclick_changelog_row}>
        <div className="flex00a rMargin" style={{flexBasis:"12em"}}>
          <LibraryReact.Nowrap>{changeLogObj.dateTimeMask}</LibraryReact.Nowrap>
        </div>
        <div className="flex11a lrMargin" style={{flexBasis:"100em"}}>
          <LibraryReact.Nowrap>{changeLogObj.field}</LibraryReact.Nowrap>
        </div>
        <div className="flex11a lrMargin" style={{flexBasis:"200em"}}>
          <LibraryReact.Nowrap>{changeLogObj.value}</LibraryReact.Nowrap>
        </div>
        {(showRowDetailsFloatingBoxTF) &&
          <CEGeneralReact.ConfirmBox
            p_type="ok"
            p_title="Teammate Contract Change Log Entry Detail"
            f_onClickConfirm={this.onclick_close_changelog_detail}>
            {changeLogFieldNamesArray.map((m_fieldName, m_index) =>
              <div className="smallFullPad" style={{borderBottom:"solid 1px #ddd"}}>
                <font className="fontBold">{changeLogFieldDisplayNamesArray[m_index] + ": "}</font>{changeLogObj[m_fieldName]}
              </div>
            )}
          </CEGeneralReact.ConfirmBox>
        }
      </div>
    );
  }
}));


const TeammateContractProcessGenerateAgreement = inject("TeammateContractsMobx", "ContactsMobx", "DatabaseMobx", "UserMobx")(observer(
class TeammateContractProcessGenerateAgreement extends Component { //props:
  constructor(props) {
    super(props);
    this.state = {
      s_showErrorsTF: false
    };
  }

  componentDidMount() {
    const o_selectedContractTemplateID = this.props.TeammateContractsMobx.o_selectedContractTemplateID;
    const c_selectAllContractTemplatesFFSFieldTypeObj = this.props.TeammateContractsMobx.c_selectAllContractTemplatesFFSFieldTypeObj;
    const c_selectContractTemplateFFSFilteredByContractTypeFieldTypeObj = this.props.TeammateContractsMobx.c_selectContractTemplateFFSFilteredByContractTypeFieldTypeObj;

    const selectedContractTemplateValueMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(o_selectedContractTemplateID, c_selectAllContractTemplatesFFSFieldTypeObj);
    if(selectedContractTemplateValueMaskSortIfoObj.isFilledOutTF) { //a contract template ID is selected in memory
      //if a contract template ID is still selected in memory from a previous open floating box, and a new type is now opened, that selected contract template wont be part of this new set, erase the selected ID
      const selectedContractTemplateIDIsValidContractTypeTemplateTF = this.props.DatabaseMobx.value_is_in_value_display_arrays_obj_or_sws_data_obj(o_selectedContractTemplateID, c_selectContractTemplateFFSFilteredByContractTypeFieldTypeObj.selectWithSearchDataObj);
      if(!selectedContractTemplateIDIsValidContractTypeTemplateTF) {
        this.onselect_contract_template(-1);
      }
    }
    else { //no contract template is currently selected
      //if there is only 1 admin upload contract template for this contract type, preselect it, otherwise leave the selection as its initial blank value
      const numAdminUploadedContractTypeTemplates = this.props.DatabaseMobx.get_num_values_from_value_display_arrays_obj_or_sws_data_obj(c_selectContractTemplateFFSFilteredByContractTypeFieldTypeObj.selectWithSearchDataObj);
      if(numAdminUploadedContractTypeTemplates === 1) {
        const initialSelectedContractTemplateID = this.props.DatabaseMobx.get_first_sorted_value_from_value_display_arrays_obj_or_sws_data_obj(c_selectContractTemplateFFSFilteredByContractTypeFieldTypeObj.selectWithSearchDataObj, -1);
        this.onselect_contract_template(initialSelectedContractTemplateID);
      }
    }
  }

  onselect_contract_template = (i_contractTemplateID) => {
    this.props.TeammateContractsMobx.a_set_selected_contract_template_id(i_contractTemplateID);
  }

  onuploadsuccess_generated_contract_record_process_history = (i_generateContractUploadSuccessMessage) => {
    this.props.TeammateContractsMobx.a_contracts_generate_upload_contract_from_template_add_process_history(i_generateContractUploadSuccessMessage);
  }

  onclick_generate_show_errors = () => {
    this.setState({s_showErrorsTF:true});
  }

  onchange_teammate_contracts_manager_contact_person = (i_contactPersonID) => {
    this.props.TeammateContractsMobx.a_set_teammate_contracts_manager_contact_person(i_contactPersonID);
  }

  render() {
    const s_showErrorsTF = this.state.s_showErrorsTF;

    const o_openContractCaptureID = this.props.TeammateContractsMobx.o_openContractCaptureID;
    const o_openContractTeammateID = this.props.TeammateContractsMobx.o_openContractTeammateID;
    const o_openContractTypeID = this.props.TeammateContractsMobx.o_openContractTypeID;
    const o_selectedContractTemplateID = this.props.TeammateContractsMobx.o_selectedContractTemplateID;
    const c_openTeammateContractObj = this.props.TeammateContractsMobx.c_openTeammateContractObj;
    const c_selectContractTemplateFFSFilteredByContractTypeFieldTypeObj = this.props.TeammateContractsMobx.c_selectContractTemplateFFSFilteredByContractTypeFieldTypeObj;
    const c_userCanEditCaptureCardContentTF = this.props.UserMobx.c_userCanEditCaptureCardContentTF;

    const contractTemplateFilledOutTF = JSFUNC.select_int_is_filled_out_tf(o_selectedContractTemplateID);

    var generateUploadContractFromTemplateButtonTitle = "";
    if(!contractTemplateFilledOutTF) {
      generateUploadContractFromTemplateButtonTitle = "[First select a Template from the list on the left]\n\n";
    }
    generateUploadContractFromTemplateButtonTitle += "Click to automatically generate the Contract from the selected Template on the left. When finished, the Contract file will appear in the file system on the right at the top level.";

    const teammateContractsManagerContactPersonID = c_openTeammateContractObj.teammateObj.teammate_contracts_manager_contact_person_id;
    const hasTemplatesForAutomaticTF = (c_selectContractTemplateFFSFilteredByContractTypeFieldTypeObj !== undefined && c_selectContractTemplateFFSFilteredByContractTypeFieldTypeObj.selectWithSearchDataObj !== undefined && JSFUNC.is_array(c_selectContractTemplateFFSFilteredByContractTypeFieldTypeObj.selectWithSearchDataObj.valueArray) && c_selectContractTemplateFFSFilteredByContractTypeFieldTypeObj.selectWithSearchDataObj.valueArray.length > 0); //the automatic button is disabled if there are 0 contract templates for this contract type
    const valueMaskSortIfoObj = this.props.DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(teammateContractsManagerContactPersonID, this.props.DatabaseMobx.c_selectContactPersonFieldTypeObj);

    return(
      <>
        {(c_openTeammateContractObj.statusIsActiveTF) &&
          <div className="tbBigPad lrMedPad" style={{borderBottom:"solid 1px #ccc"}}>
            <div className="displayFlexRowVc">
              <div className="flex00a rMargin">
                <font className="font12 fontBold fontTextLighter">
                  {"Generate Agreement from Template"}
                </font>
              </div>
              <div className="flex11a lrMargin">
                <CEGeneralReact.HelpHoverIconWithFloatingBox>
                  {"The agreement for this contact can be automatically generated from a selected template using this tool using data from this capture and teammate contact info.  Once generated, the completed agreement file will be automatically placed in the File/Folder system for this teammate contract on the right."}
                </CEGeneralReact.HelpHoverIconWithFloatingBox>
              </div>
            </div>
            {(hasTemplatesForAutomaticTF) ? (
              <div className="displayFlexRowVc">
                <div className="flex11a rMargin" style={{maxWidth:"30em"}}>
                    <CEGeneralReact.GenericInputOrSelectFromInputType
                      p_fieldTypeObj={c_selectContractTemplateFFSFilteredByContractTypeFieldTypeObj}
                      p_valueRaw={o_selectedContractTemplateID}
                      p_errorTF={s_showErrorsTF && !contractTemplateFilledOutTF}
                      f_onChangeOrOnSelect={this.onselect_contract_template}
                    />
                  {(s_showErrorsTF && !contractTemplateFilledOutTF) &&
                    <CEGeneralReact.ErrorText p_class="microTopMargin" p_text="Select a contract template to use for automatic generation" />
                  }
                </div>
                {(c_userCanEditCaptureCardContentTF) &&
                  <div className="flex00a lrMedMargin">
                    <CEGeneralReact.GenerateAndUploadTeammateContractTemplateButtonProcess
                      p_captureID={o_openContractCaptureID}
                      p_teammateContractID={c_openTeammateContractObj.id}
                      p_teammateContractTemplateID={o_selectedContractTemplateID}
                      p_buttonText="Generate/Upload Contract from Template"
                      p_buttonTitle={generateUploadContractFromTemplateButtonTitle}
                      p_canClickButtonTF={contractTemplateFilledOutTF}
                      f_onClick={this.onclick_generate_show_errors}
                      f_onUploadSuccess={this.onuploadsuccess_generated_contract_record_process_history}
                    />
                  </div>
                }
                <div className="flex11a" />
              </div>
            ) : (
              <font className="fontItalic fontTextLighter">
                <div className="">{"There are no templates available yet for this teammate contract type (" + c_openTeammateContractObj.typeNameAndShortName + ")"}</div>
                <div className="smallTopMargin">{"Your CaptureExec Admin can create and upload template files to enable automatic agreement generation."}</div>
              </font>
            )}
          </div>
        }
        <div className="tbBigPad lrMedPad">
          <div className="">
            <font className="font12 fontBold fontTextLighter">
              {"Teammate Information"}
            </font>
          </div>
          <div className="displayFlexRowVc smallTopMargin">
            <div className="flex00a" style={{flexBasis:"6em"}}>
              <font className="fontItalic fontTextLighter">
                {"Teammate:"}
              </font>
            </div>
            <div className="flex11a">
              {(c_openTeammateContractObj.teammateObj.contact_company_id === -2) ? (
                c_openTeammateContractObj.teammateObj.teammateNamePlainText
              ) : (
                <ContactsReact.ContactBubble
                  p_isPersonTF={false}
                  p_contactID={c_openTeammateContractObj.teammateObj.contact_company_id}
                  p_iconSizeEm={1.6}
                  p_fontClass=""
                />
              )}
            </div>
          </div>
          <div className="smallTopMargin">
            <CEGeneralReact.CaptureExecFieldEditSaveCancel
              p_ceEditItemString={"cID" + o_openContractCaptureID + "_tID" + o_openContractTeammateID + "_ctID" + o_openContractTypeID + "_tcmcp"}
              p_fieldDisplayName="Teammate Contracts Manager"
              p_fieldTypeObj={this.props.DatabaseMobx.c_selectContactPersonFieldTypeObj}
              p_valueRaw={teammateContractsManagerContactPersonID}
              p_valueIsEditableTFU={c_userCanEditCaptureCardContentTF}
              p_fieldClass="fontBlue"
              p_fieldWidth="15em"
              p_fieldNowrapTF={false}
              p_floatingBoxTitle="Edit Teammate Contracts Manager"
              f_onSaveChanged={this.onchange_teammate_contracts_manager_contact_person}
            />
          </div>
          {(valueMaskSortIfoObj.isFilledOutTF) &&
            <div className="smallTopMargin">
              <font className="font11 fontItalic fontTextLighter">{"Email Address for " + valueMaskSortIfoObj.valueMaskPlainText + ": "}</font>
              {this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(this.props.ContactsMobx.contact_person_obj_from_id(teammateContractsManagerContactPersonID).email, this.props.DatabaseMobx.c_genericEmailFieldTypeObj)}
            </div>
          }
        </div>
      </>
    );
  }
}));






//===================================================================================================================================================================================


export const ContractsMetrics = inject("CaptureExecMobx", "TeammateContractsMobx")(observer(
class ContractsMetrics extends Component {
  render() {
    return(
      <div className="flex11a displayFlexColumn">
        {"Metrics"}
      </div>
    );
  }
}));
