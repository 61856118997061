import { makeObservable, observable, computed, action } from 'mobx';

import * as JSFUNC from "../../Library/JSFUNC.js";

import DatabaseMobx from '../../CaptureExecLocalDatabaseMobx/DatabaseMobx.js';
import UserMobx from '../../CaptureExecLocalDatabaseMobx/UserMobx.js';
import * as JSPHP from '../../CaptureExecLocalDatabaseMobx/JSPHP.js';

import CaptureExecMobx from '../CaptureExec/CaptureExecMobx.js';
import CapturesMobx from '../Captures/CapturesMobx.js';

class DivexecMobx {
  //========================================================================================
  //observable values
  o_performanceSelectedUserTrueDivisionFalse = undefined;
  o_performanceSelectedUserOrDivisionID = undefined;
  o_performanceMobileScreen = "graphs";
  o_performanceEditingFyr = undefined; //undefined - nothing being edited, -1 - choosing which year to edit, id>0 - edit that year

  o_dailySnapshotSelectedPageID = undefined;
  o_trendAnalyzerSelectedPageID = undefined;
  o_graphsCreatingNewGraphTF = false;
  o_graphsEditingGraphID = undefined;

  o_finProjMaxNumCaptures = 25;

  o_hotBitsSelectedCaptureFieldIDOrUndefned = undefined;

  o_criticalThresholdsSelectedFilterCaptureTypeIDOrUndefned = undefined;
  o_criticalThresholdsSelectedGraphString = "criticalProgressThreshold"; //"criticalProgressThreshold", "criticalPwinThreshold", "pwinVsProgress"

  o_stageFlowThinTblCLogStagesArrayOfObjs = undefined; //tbl_c_log_stages  [{ci:1,si:1}]  ci=capture_id, si=stage_id  ordered by datetime, (undefined - flag that this table is not yet loaded from the database)
  o_stageFlowSelectedFilterCaptureTypeIDOrUndefined = undefined;
  o_stageFlowSelectedFilterStageTypeString = "closed"; //"closed", "active", "all"

  constructor() {
    makeObservable(this, {
      o_performanceSelectedUserTrueDivisionFalse: observable,
      o_performanceSelectedUserOrDivisionID: observable,
      o_performanceMobileScreen: observable,
      o_performanceEditingFyr: observable,
      o_dailySnapshotSelectedPageID: observable,
      o_trendAnalyzerSelectedPageID: observable,
      o_graphsCreatingNewGraphTF: observable,
      o_graphsEditingGraphID: observable,
      o_finProjMaxNumCaptures: observable,
      o_hotBitsSelectedCaptureFieldIDOrUndefned: observable,
      o_criticalThresholdsSelectedFilterCaptureTypeIDOrUndefned: observable,
      o_criticalThresholdsSelectedGraphString: observable,
      o_stageFlowThinTblCLogStagesArrayOfObjs: observable,
      o_stageFlowSelectedFilterCaptureTypeIDOrUndefined: observable,
      o_stageFlowSelectedFilterStageTypeString: observable,

      c_divexecFilteredCapturesObj: computed,
      c_performanceDivisionsArrayOfObjs: computed,
      c_performanceUserSelectedMoneyExpandedCaptureFieldMapOrUndefined: computed,
      c_performanceUserSelectedMoneyFieldDisplayName: computed,
      c_performanceAllQuotaYearsArrayOfObjs: computed,
      c_performanceQuotaObj: computed,
      c_performanceGraphsDataArrayOfObjs: computed,
      c_myPerformanceMoneyFieldIDsToNotIncludeArray: computed,
      c_tabDailySnapshotTrueTrendAnalyzerFalse: computed,
      c_dailySnapshotPagesArrayOfObjs: computed,
      c_trendAnalyzerPagesArrayOfObjs: computed,
      c_dailySnapshotGraphsArrayOfObjs: computed,
      c_trendAnalyzerGraphsArrayOfObjs: computed,
      c_graphsSelectedTabName: computed,
      c_graphsSelectedTabPagesTblName: computed,
      c_graphsSelectedTabGraphsTblName: computed,
      c_graphsSelectedTabAllPagesArrayOfObjs: computed,
      c_graphsSelectedTabAllGraphsArrayOfObjs: computed,
      c_graphsSelectedTabUserAllAddedPageIDsArray: computed,
      c_graphsSelectedTabUserAllAddedGraphIDsObjOfArrays: computed,
      c_graphsSelectedTabAllPublicPagesPerUserCreatorArrayOfObjs: computed,
      c_graphsSelectedTabAllPublicGraphsDataPerUserCreatorArrayOfObjs: computed,
      c_graphsSelectedPageID: computed,
      c_graphsSelectedPageObj: computed,
      c_graphsSelectedPageIsReplicaOfPublicPageTF: computed,
      c_graphsSelectedPageNumGraphsPerRow: computed,
      c_graphsSelectedPageGraphHeightEm: computed,
      c_graphsSelectedPageGraphsDataArrayOfObjs: computed,
      c_graphsEditingGraphDataObj: computed,
      c_graphsSelectNumGraphsPerRowFieldTypeObj: computed,
      c_graphsSelectGraphHeightsFieldTypeObj: computed,
      c_graphsSelectFilterPresetFieldTypeObj: computed,
      c_graphsSelectFromAllFilterPresetsFieldTypeObj: computed,
      c_selectCategoryCaptureFieldFieldTypeObj: computed,
      c_graphsSelectAllCategoriesTFFieldTypeObj: computed,
      c_graphsSelectMultiCategoriesOptionsFieldTypeObj: computed,
      c_finProjObj: computed,
      c_finProjGraphStartEndDatesObj: computed,
      c_finProjYearlyGraphObj: computed,
      c_finProjMonthlyGraphObj: computed,
      c_hotBitsObj: computed,
      c_criticalThresholdsFilteredCaptureMapsAndTcvMultipliersArrayOfObjs: computed,
      c_criticalProgressThresholdScatterPlotDataArrayOfObjs: computed,
      c_criticalPwinThresholdScatterPlotDataArrayOfObjs: computed,
      c_pwinVsProgressScatterPlotDataArrayOfObjs: computed,
      c_stageFlowCaptureIDsWithinSelectedFilterCaptureTypeArray: computed,
      c_stageFlowStageIDsPerCaptureIDMapOfArrays: computed,
      c_stageFlowDataPerStageArrayOfObjs: computed,
      c_excelReportTemplatesWithinFoldersObj: computed,

      a_performance_set_selected_user_or_division_id: action,
      a_performance_set_mobile_screen: action,
      a_performance_set_editing_fyr: action,
      a_performance_update_or_insert_fyr_quota: action,
      a_graphs_set_selected_page_id: action,
      a_graphs_initialize_selected_page_id_to_first_option: action,
      a_graphs_create_new_page: action,
      a_graphs_delete_page_and_graphs_from_page_id: action,
      a_graphs_add_public_page_as_replica: action,
      a_graphs_copy_page: action,
      a_graphs_create_new_graph: action,
      a_graphs_add_public_graph_as_replica: action,
      a_graphs_set_creating_new_graph_tf: action,
      a_graphs_set_editing_graph_id: action,
      a_graphs_update_page_field: action,
      a_graphs_update_graph_field: action,
      a_graphs_copy_graph: action,
      a_graphs_delete_graph: action,
      a_finproj_set_max_num_captures: action,
      a_hot_bits_set_selected_capture_field_id: action,
      a_critical_thresholds_set_selected_filter_capture_type_id: action,
      a_critical_thresholds_set_selected_graph_string: action,
      a_stage_flow_load_thin_log_stages: action,
      a_stage_flow_set_thin_tbl_c_log_stages_arrayOfObjs: action,
      a_stage_flow_set_selected_filter_capture_type_id: action,
      a_stage_flow_set_selected_filter_stage_type_string: action
    });
  }


  //========================================================================================
  //computed values
  //divexec capture filtering
  get c_divexecFilteredCapturesObj() {
    //load filter settings from user table fields
    const finProjCustom0Manual1 = UserMobx.c_combinedUserObj.divexec_finproj_custom0_manual1;
    const finProjFilterPresetID = UserMobx.c_combinedUserObj.divexec_finproj_filter_preset_id;
    const finProjDivisionIDsComma = UserMobx.c_combinedUserObj.divexec_finproj_division_ids_comma;
    const finProjStageIDsComma = UserMobx.c_combinedUserObj.divexec_finproj_stage_ids_comma;

    //initialize output
    var filterName = undefined;
    var excelFilterName = undefined; //name displayed in the exported xml excel sheet is more verbose for the manual divisions/stages filter
    var filteredCaptureMapsAndTcvMultipliersArrayOfObjs = [];

    //determine the filter preset being used (manual or custom)
    var finProjExpandedFiltersArrayOfObjs = undefined;
    if(finProjCustom0Manual1 === 0) { //filter from custom filter preset selected
      if(JSFUNC.select_int_is_filled_out_tf(finProjFilterPresetID)) {
        const filterPresetMap = DatabaseMobx.tbl_row_map_from_id("tbl_f_filter_presets", finProjFilterPresetID);
        filterName = filterPresetMap.get("name");
      }
      else {
        filterName = "All Captures (No Filter Selected)";
      }
      excelFilterName = filterName;

      const filtersArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_f_filters, "filter_preset_id", finProjFilterPresetID);
      finProjExpandedFiltersArrayOfObjs = CapturesMobx.create_expanded_filters_arrayOfObjs_from_filters_arrayOfObjs(filtersArrayOfObjs);
    }
    else if(finProjCustom0Manual1 === 1) { //manual filter from divisions/stages
      filterName = "Manual Divisions/Stages Filter";

      const filterDivisionsMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(finProjDivisionIDsComma, DatabaseMobx.c_selectMultiDivisionsFieldTypeObj);
      const filterStagesMaskSortIfoObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(finProjStageIDsComma, DatabaseMobx.c_selectMultiStagesFieldTypeObj);
      excelFilterName = "Division(s): " + filterDivisionsMaskSortIfoObj.valueMaskPlainText + " || Stage(s): " + filterStagesMaskSortIfoObj.valueMaskPlainText;

      const manualFiltersArrayOfObjs = [
        {capture_field_id:DatabaseMobx.c_fieldMapOfDivisionOwners.get("id"), operator:"e", value:finProjDivisionIDsComma},
        {capture_field_id:DatabaseMobx.c_fieldMapOfStage.get("id"), operator:"e", value:finProjStageIDsComma}
      ];
      finProjExpandedFiltersArrayOfObjs = CapturesMobx.create_expanded_filters_arrayOfObjs_from_filters_arrayOfObjs(manualFiltersArrayOfObjs);
    }
    else {
      filterName = "--Unknown Filter " + finProjCustom0Manual1 + "--";
      excelFilterName = filterName;
      finProjExpandedFiltersArrayOfObjs = [];
    }

    //filter captures from full set
    for(let [captureID, captureMap] of DatabaseMobx.o_tbl_captures) {
      var partialMultiplier0to1 = CapturesMobx.capture_matches_all_filters_partialMultiplier0to1_or_false_from_capture_map_and_expanded_filters_arrayOfObjs(captureMap, finProjExpandedFiltersArrayOfObjs);
      if(partialMultiplier0to1 > 0) {
        filteredCaptureMapsAndTcvMultipliersArrayOfObjs.push({
          captureMap: captureMap,
          partialMultiplier0to1: partialMultiplier0to1
        });
      }
    }

    return({
      filterName: filterName,
      excelFilterName: excelFilterName,
      filteredCaptureMapsAndTcvMultipliersArrayOfObjs: filteredCaptureMapsAndTcvMultipliersArrayOfObjs
    });
  }




  //performance
  get c_performanceDivisionsArrayOfObjs() {
    //use the logged in user's division treeID to determine which divisions to show and which to hide (divexec user's can only see their division and its subdivisions, nothing below)
    const userDivisionID = UserMobx.c_combinedUserObj.division_id;
    const userDivisionTreeID = UserMobx.c_combinedUserObj.divisionTreeID;
    var indentsToSubtract = 0;
    if(JSFUNC.is_string(userDivisionTreeID)) {
      indentsToSubtract = ((userDivisionTreeID.length / 2) - 1); //for top level treeID "00", remove 0 indents from the normal indent count, for other lower divisions, remove indents to make them the top level with 0 indents
    }

    const divisionsMapOfMaps = DatabaseMobx.tbl_ref_from_tbl_name("tbl_a_divisions");
    var divisionsArrayOfObjs = JSFUNC.arrayOfObjs_from_mapOfMaps(divisionsMapOfMaps, "tree_id", true);
    var performanceDivisionsArrayOfObjs = [];
    for(let divisionObj of divisionsArrayOfObjs) {
      var divisionIsChildOfUserDivision = JSFUNC.tree_id_is_child_of_parent_tree_id_tf(userDivisionTreeID, divisionObj.tree_id, false);
      if((divisionObj.id === userDivisionID) || divisionIsChildOfUserDivision) { //only include this division if it is the user's division or a child of that division
        var usersArrayOfObjs = [];
        for(let combinedUserMap of DatabaseMobx.c_tbl_a_users.values()) {
          if(!combinedUserMap.get("deactivatedTF") && (combinedUserMap.get("division_id") === divisionObj.id)) {
            usersArrayOfObjs.push(JSFUNC.obj_from_map(combinedUserMap));
          }
        }

        divisionObj.indentsArray = DatabaseMobx.compute_division_indents_array_from_division_obj(divisionObj, indentsToSubtract);
        divisionObj.usersArrayOfObjs = usersArrayOfObjs;

        performanceDivisionsArrayOfObjs.push(divisionObj);
      }
    }
    return(performanceDivisionsArrayOfObjs);
  }

  get c_performanceUserSelectedMoneyExpandedCaptureFieldMapOrUndefined() {
    const c_combinedUserObj = UserMobx.c_combinedUserObj;
    return(DatabaseMobx.c_tbl_captures_fields.get(c_combinedUserObj.divexec_performance_money_field_id));
  }

  get c_performanceUserSelectedMoneyFieldDisplayName() {
    const c_performanceUserSelectedMoneyExpandedCaptureFieldMapOrUndefined = this.c_performanceUserSelectedMoneyExpandedCaptureFieldMapOrUndefined;
    if(c_performanceUserSelectedMoneyExpandedCaptureFieldMapOrUndefined === undefined) {
      return("--No Capture Money Field Selected--");
    }
    return(c_performanceUserSelectedMoneyExpandedCaptureFieldMapOrUndefined.get("display_name"));
  }

  get c_performanceAllQuotaYearsArrayOfObjs() {
    const o_performanceSelectedUserTrueDivisionFalse = this.o_performanceSelectedUserTrueDivisionFalse;
    const o_performanceSelectedUserOrDivisionID = this.o_performanceSelectedUserOrDivisionID;
    const c_performanceDivisionsArrayOfObjs = this.c_performanceDivisionsArrayOfObjs;
    const c_performanceUserSelectedMoneyExpandedCaptureFieldMapOrUndefined = this.c_performanceUserSelectedMoneyExpandedCaptureFieldMapOrUndefined;
    const c_submittedStageIDsArray = DatabaseMobx.c_submittedStageIDsArray;
    const c_closedWonStageIDsArray = DatabaseMobx.c_closedWonStageIDsArray;
    const c_fieldMapOfProposalDueDateTimeUtc = DatabaseMobx.c_fieldMapOfProposalDueDateTimeUtc;
    const c_fieldMapOfAwardDate = DatabaseMobx.c_fieldMapOfAwardDate;

    var allQuotaYearsArrayOfObjs = [];

    if((o_performanceSelectedUserTrueDivisionFalse !== undefined) && (o_performanceSelectedUserOrDivisionID !== undefined)) {
      var quotaUser0Division1 = undefined;
      var ownersCaptureFieldID = undefined;
      var captureFilterUserIDOrDivisionIDsCommaString = undefined;
      if(o_performanceSelectedUserTrueDivisionFalse) { //selected user
        quotaUser0Division1 = 0;
        ownersCaptureFieldID = DatabaseMobx.c_fieldMapOfCaptureManagers.get("id");

        captureFilterUserIDOrDivisionIDsCommaString = JSFUNC.num2str(o_performanceSelectedUserOrDivisionID);
      }
      else { //selected division
        quotaUser0Division1 = 1;
        ownersCaptureFieldID = DatabaseMobx.c_fieldMapOfDivisionOwners.get("id");

        const divisionMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_divisions", o_performanceSelectedUserOrDivisionID);
        const selectedDivisionTreeID = divisionMap.get("tree_id");

        var selectedDivisionChildrenDivisionIDsArray = JSFUNC.get_all_children_ids_array_from_parent_tree_id(c_performanceDivisionsArrayOfObjs, selectedDivisionTreeID);
        selectedDivisionChildrenDivisionIDsArray.push(o_performanceSelectedUserOrDivisionID);
        captureFilterUserIDOrDivisionIDsCommaString = JSFUNC.convert_array_to_comma_list(selectedDivisionChildrenDivisionIDsArray);
      }

      //initialize a first and last FYR that spans both the set quota records and all captures owned (with contract start date and POP set)
      var lowestFyr = undefined;
      var highestFyr = undefined;

      //get all set quota records for this user/division (sorted by fiscal year)
      var userOrDivisionQuotasArrayOfObjs = [];
      if(c_performanceUserSelectedMoneyExpandedCaptureFieldMapOrUndefined !== undefined) {
        const performanceMoneyFieldID = c_performanceUserSelectedMoneyExpandedCaptureFieldMapOrUndefined.get("id");
        userOrDivisionQuotasArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_d_quota, ["user0_division1", "userdiv_id", "money_field_id"], [quotaUser0Division1, o_performanceSelectedUserOrDivisionID, performanceMoneyFieldID], "fyr", true);
      }
      const numQuotaRecords = userOrDivisionQuotasArrayOfObjs.length;

      //set the first/last FYR using the set quotas for this user/division (if there are 0 quota records set, keep first/last FYR undefined)
      if(numQuotaRecords >= 1) {
        lowestFyr = userOrDivisionQuotasArrayOfObjs[0].fyr;
        highestFyr = userOrDivisionQuotasArrayOfObjs[(numQuotaRecords - 1)].fyr;
      }

      //create the capture filter for this single user/division
      const ownersFiltersArrayOfObjs = [{capture_field_id:ownersCaptureFieldID, operator:"e", value:captureFilterUserIDOrDivisionIDsCommaString}];
      const ownersExpandedFiltersArrayOfObjs = CapturesMobx.create_expanded_filters_arrayOfObjs_from_filters_arrayOfObjs(ownersFiltersArrayOfObjs);

      //determine which captures are owned by this user/division and compute their partial TCV multiplier
      var fyrActualTotalsObj = {};
      for(let [captureID, captureMap] of DatabaseMobx.o_tbl_captures) {
        //determine if stage is submitted or won
        var captureStageID = captureMap.get("stage_id");
        var stageIsSubmittedTF = JSFUNC.in_array(captureStageID, c_submittedStageIDsArray);
        var stageIsClosedWonTF = JSFUNC.in_array(captureStageID, c_closedWonStageIDsArray);
        if(stageIsSubmittedTF || stageIsClosedWonTF) {
          //determine if the capture is owned by this user/division
          var partialMultiplier0to1 = CapturesMobx.capture_matches_all_filters_partialMultiplier0to1_or_false_from_capture_map_and_expanded_filters_arrayOfObjs(captureMap, ownersExpandedFiltersArrayOfObjs);
          if(partialMultiplier0to1 > 0) {
            //compute the partial total contract value
            var captureCOV = 0;
            if(c_performanceUserSelectedMoneyExpandedCaptureFieldMapOrUndefined !== undefined) {
              captureCOV = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(captureMap, c_performanceUserSelectedMoneyExpandedCaptureFieldMapOrUndefined);
            }

            var capturePartialCOV = ((partialMultiplier0to1 === 1) ? (captureCOV) : (partialMultiplier0to1 * captureCOV));

            //compute submitted totals using proposal due date (all captures stageIsSubmittedTF or stageIsClosedWonTF are all counted as submitted (closedWon is a subset of submitted))
            var captureProposalDueDateTimeUtc = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(captureMap, c_fieldMapOfProposalDueDateTimeUtc);
            var captureProposalDueDateLocal = JSFUNC.convert_mysqldate_or_mysqldatetimeutc_to_mysqldatelocal(captureProposalDueDateTimeUtc); //handle proposal due date either being a date or dateTimeUtc format
            if(this.performance_date_is_filled_out_and_between_1950_and_3001_tf(captureProposalDueDateLocal)) {
              var proposalDueDateFyrObj = this.performance_fyr_obj_from_date_Ymd(captureProposalDueDateLocal);

              //replace lowest/highest FYR with this capture CSD fyr if it is lower or higher
              if((lowestFyr === undefined) || (proposalDueDateFyrObj.fyrYear < lowestFyr)) {
                lowestFyr = proposalDueDateFyrObj.fyrYear;
              }

              if((highestFyr === undefined) || (proposalDueDateFyrObj.fyrYear > highestFyr)) {
                highestFyr = proposalDueDateFyrObj.fyrYear;
              }

              //if this is the first entry for this fyr, make a new initialized obj for this fyr for both submitted and won
              if(fyrActualTotalsObj[proposalDueDateFyrObj.fyrYearString] === undefined) {
                fyrActualTotalsObj[proposalDueDateFyrObj.fyrYearString] = {
                  submittedCaptureIDsArray: [],
                  submittedPartialNumCaptures: 0,
                  submittedPartialTcvTotal: 0,
                  wonCaptureIDsArray: [],
                  wonPartialNumCaptures: 0,
                  wonPartialTcvTotal: 0
                };
              }

              //append the capture id/TCV to the submitted totals
              fyrActualTotalsObj[proposalDueDateFyrObj.fyrYearString].submittedCaptureIDsArray.push(captureID);
              fyrActualTotalsObj[proposalDueDateFyrObj.fyrYearString].submittedPartialNumCaptures += partialMultiplier0to1;
              fyrActualTotalsObj[proposalDueDateFyrObj.fyrYearString].submittedPartialTcvTotal += capturePartialCOV;
            }

            //if this capture is closedWon, repeat these calculations for won totals using award_date
            if(stageIsClosedWonTF) {
              var captureAwardDate = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(captureMap, c_fieldMapOfAwardDate);
              if(this.performance_date_is_filled_out_and_between_1950_and_3001_tf(captureAwardDate)) {
                var awardDateFyrObj = this.performance_fyr_obj_from_date_Ymd(captureAwardDate);

                //replace lowest/highest FYR with this capture CSD fyr if it is lower or higher
                if((lowestFyr === undefined) || (awardDateFyrObj.fyrYear < lowestFyr)) {
                  lowestFyr = awardDateFyrObj.fyrYear;
                }

                if((highestFyr === undefined) || (awardDateFyrObj.fyrYear > highestFyr)) {
                  highestFyr = awardDateFyrObj.fyrYear;
                }

                //if this is the first entry for this fyr, make a new initialized obj for this fyr for both submitted and won
                if(fyrActualTotalsObj[awardDateFyrObj.fyrYearString] === undefined) {
                  fyrActualTotalsObj[awardDateFyrObj.fyrYearString] = {
                    submittedCaptureIDsArray: [],
                    submittedPartialNumCaptures: 0,
                    submittedPartialTcvTotal: 0,
                    wonCaptureIDsArray: [],
                    wonPartialNumCaptures: 0,
                    wonPartialTcvTotal: 0
                  };
                }

                //append the capture id/TCV to the won totals
                fyrActualTotalsObj[awardDateFyrObj.fyrYearString].wonCaptureIDsArray.push(captureID);
                fyrActualTotalsObj[awardDateFyrObj.fyrYearString].wonPartialNumCaptures += partialMultiplier0to1;
                fyrActualTotalsObj[awardDateFyrObj.fyrYearString].wonPartialTcvTotal += capturePartialCOV;
              }
            }
          }
        }
      }

      //loop through each fyr from lowest to highest filling in the quotas and actual values
      if((lowestFyr !== undefined) && (highestFyr !== undefined)) { //only append fyr objs if lowest/highest fyr were set (undefined means that there are 0 quotas and 0 captures for this user/division)
        for(let fyr = highestFyr; fyr >= lowestFyr; fyr--) {
          //see if a quota has been set for this fyr
          var quotaSubmitted = undefined;
          var quotaWon = undefined;
          var q = 0;
          var quotaFyrFoundTF = false;
          while(!quotaFyrFoundTF && (q < numQuotaRecords)) {
            if(userOrDivisionQuotasArrayOfObjs[q].fyr === fyr) {
              quotaSubmitted = userOrDivisionQuotasArrayOfObjs[q].submitted_quota;
              quotaWon = userOrDivisionQuotasArrayOfObjs[q].won_quota;
              quotaFyrFoundTF = true;
            }
            q++;
          }

          //see if this fyr has any won/submitted captures
          var csdFyrYearString = JSFUNC.num2str(fyr);
          var actualSubmitted = 0;
          var actualWon = 0;
          var submittedPartialNumCaptures = 0;
          var wonPartialNumCaptures = 0;
          var submittedCaptureIDsArray = [];
          var wonCaptureIDsArray = [];
          if(fyrActualTotalsObj[csdFyrYearString] !== undefined) {
            actualSubmitted = fyrActualTotalsObj[csdFyrYearString].submittedPartialTcvTotal;
            actualWon = fyrActualTotalsObj[csdFyrYearString].wonPartialTcvTotal;
            submittedPartialNumCaptures = fyrActualTotalsObj[csdFyrYearString].submittedPartialNumCaptures;
            wonPartialNumCaptures = fyrActualTotalsObj[csdFyrYearString].wonPartialNumCaptures;
            submittedCaptureIDsArray = fyrActualTotalsObj[csdFyrYearString].submittedCaptureIDsArray;
            wonCaptureIDsArray = fyrActualTotalsObj[csdFyrYearString].wonCaptureIDsArray;
          }

          var submittedPercentOfGoal0to100 = ((quotaSubmitted > 0) ? (Math.round((actualSubmitted / quotaSubmitted) * 100)) : (100));
          var wonPercentOfGoal0to100 = ((quotaWon > 0) ? (Math.round((actualWon / quotaWon) * 100)) : (100));

          //create the hover title for each quota fyr on the graph
          var titleSubmittedGoal = ((quotaSubmitted === undefined) ? ("--not set--") : (JSFUNC.money(quotaSubmitted, 0, true)));
          var titleWonGoal = ((quotaWon === undefined) ? ("--not set--") : (JSFUNC.money(quotaWon, 0, true)));
          var titleSubmittedActual = JSFUNC.money(actualSubmitted, 0, true) + " (" + submittedPercentOfGoal0to100 + "% of goal from " + submittedPartialNumCaptures + " " + JSFUNC.plural(submittedPartialNumCaptures, "capture", "captures") + ")";
          var titleWonActual = JSFUNC.money(actualWon, 0, true) + " (" + wonPercentOfGoal0to100 + "% of goal from " + wonPartialNumCaptures + " " + JSFUNC.plural(wonPartialNumCaptures, "capture", "captures") + ")";
          var hoverTitle = "Submitted\n - Goal: " + titleSubmittedGoal + "\n - Actual: " + titleSubmittedActual + "\n\nWon\n - Goal: " + titleWonGoal + "\n - Actual: " + titleWonActual;

          allQuotaYearsArrayOfObjs.push({
            fyr: fyr,
            quotaSubmitted: quotaSubmitted,
            quotaWon: quotaWon,
            actualSubmitted: actualSubmitted,
            actualWon: actualWon,
            submittedPartialNumCaptures: submittedPartialNumCaptures,
            wonPartialNumCaptures: wonPartialNumCaptures,
            submittedCaptureIDsArray: submittedCaptureIDsArray,
            wonCaptureIDsArray: wonCaptureIDsArray,
            hoverTitle: hoverTitle
          });
        }
      }
    }

    return(allQuotaYearsArrayOfObjs);
  }

  get c_performanceQuotaObj() {
    const o_performanceSelectedUserTrueDivisionFalse = this.o_performanceSelectedUserTrueDivisionFalse;
    const o_performanceSelectedUserOrDivisionID = this.o_performanceSelectedUserOrDivisionID;
    const c_performanceAllQuotaYearsArrayOfObjs = this.c_performanceAllQuotaYearsArrayOfObjs;
    const c_userFontSizePx = UserMobx.c_userFontSizePx;
    const c_combinedUserObj = UserMobx.c_combinedUserObj;

    const maxYearsPossibleToShow = 50;
    const graphHeightPx = (30 * c_userFontSizePx);
    const singleYearWidthEm = 10;

    var userOrDivisionString = "";
    var userOrDivisionName = "";
    var filteredQuotaYearsArrayOfObjs = [];
    var highestQuotaOrActualTotal = 0;

    if((o_performanceSelectedUserTrueDivisionFalse === undefined) || (o_performanceSelectedUserOrDivisionID === undefined)) {
      userOrDivisionString = "";
      userOrDivisionName = "--No User/Division Selected--";
    }
    else {
      if(o_performanceSelectedUserTrueDivisionFalse) { //selected user
        userOrDivisionString = "User";

        const combinedUserMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_users", o_performanceSelectedUserOrDivisionID);
        userOrDivisionName = combinedUserMap.get("fullName");

      }
      else { //selected division
        userOrDivisionString = "Division";

        const divisionMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_divisions", o_performanceSelectedUserOrDivisionID);
        userOrDivisionName = divisionMap.get("name");
      }

      const goalsSpecifyYearsTrueShowAllYearsFalse = JSFUNC.is_number_not_nan_gt_0(c_combinedUserObj.divexec_goals_latest_year);

      if(goalsSpecifyYearsTrueShowAllYearsFalse) { //filter years based on specified years
        //ensure the # years drawn is not greater than the max possible to draw
        const minOfNumYearsAndMaxYears = JSFUNC.array_min([c_combinedUserObj.divexec_goals_num_years, maxYearsPossibleToShow]);

        //loop over total number of years requested by the user
        for(let y = 0; y < minOfNumYearsAndMaxYears; y++) {
          var filteredFyr = (c_combinedUserObj.divexec_goals_latest_year - y);

          //try to find the matching fyr in the list of all years
          var matchingFyrQuotaYearObjOrUndefined = JSFUNC.get_first_obj_from_arrayOfObjs_matching_field_value(c_performanceAllQuotaYearsArrayOfObjs, "fyr", filteredFyr);

          if(matchingFyrQuotaYearObjOrUndefined !== undefined) { //if the matching fyr obj is found, use that obj
            filteredQuotaYearsArrayOfObjs.push(matchingFyrQuotaYearObjOrUndefined);
          }
          else { //otherwise, need to create a blank obj for this fyr with all 0s
            filteredQuotaYearsArrayOfObjs.push({
              fyr: filteredFyr,
              quotaSubmitted: 0,
              quotaWon: 0,
              actualSubmitted: 0,
              actualWon: 0,
              submittedPartialNumCaptures: 0,
              wonPartialNumCaptures: 0,
              submittedCaptureIDsArray: [],
              wonCaptureIDsArray: [],
              hoverTitle: "--No Captures or Goals set for FYR " + filteredFyr + "--"
            });
          }
        }
      }
      else { //use all years found in c_performanceAllQuotaYearsArrayOfObjs
        var y = 1;
        for(let quotaYearObj of c_performanceAllQuotaYearsArrayOfObjs) {
          if(y <= maxYearsPossibleToShow) { //don't add more than the max # years allowed (prevents browser crashing)
            filteredQuotaYearsArrayOfObjs.push(quotaYearObj);
          }
          y++;
        }
      }

      //compute max value on filtered graph
      for(let quotaYearObj of filteredQuotaYearsArrayOfObjs) {
        var maxFyrValue = JSFUNC.array_max([quotaYearObj.quotaSubmitted, quotaYearObj.quotaWon, quotaYearObj.actualSubmitted, quotaYearObj.actualWon]);
        if(maxFyrValue > highestQuotaOrActualTotal) {
          highestQuotaOrActualTotal = maxFyrValue;
        }
      }
    }

    const numYears = filteredQuotaYearsArrayOfObjs.length;
    const svgWidthEm = (numYears * singleYearWidthEm);
    
    //using the highest value of any bar for any fyr, compute a yAxis tick scale
    var yAxisObj = JSFUNC.create_axis_obj_from_max_value_and_height_px(0, highestQuotaOrActualTotal, graphHeightPx, true, "moneyShort", false, 1.06, 0); //reverse pos values for y axis ticks

    return({
      userOrDivisionString: userOrDivisionString,
      userOrDivisionName: userOrDivisionName,
      quotaYearsArrayOfObjs: filteredQuotaYearsArrayOfObjs,
      numYears: numYears,
      graphHeightPx: graphHeightPx,
      svgWidth: svgWidthEm + "em",
      graphMaxValue: yAxisObj.axisMaxValue,
      yAxisTicksArrayOfObjs: yAxisObj.axisTicksArrayOfObjs
    });
  }

  performance_date_is_filled_out_and_between_1950_and_3001_tf(i_dateYmd) {
    return(JSFUNC.date_is_filled_out_tf(i_dateYmd) && (i_dateYmd > "1950-01-01") && (i_dateYmd < "3001-01-01"));
  }

  performance_fyr_obj_from_date_Ymd(i_dateYmd) {
    const dateYear = JSFUNC.str2int(i_dateYmd.substring(0,4));
    const dateMonthIndex0to11 = (JSFUNC.str2int(i_dateYmd.substring(5,7)) - 1);
    var fyrObj = DatabaseMobx.convert_year_month0to11_to_fyrYear_fyrMonth0to11_obj(dateYear, dateMonthIndex0to11); //fyrYear
    fyrObj.fyrYearString = JSFUNC.num2str(fyrObj.fyrYear); //add extra field converting the int fyr year into a string of the fyr year
    return(fyrObj);
  }

  get c_performanceGraphsDataArrayOfObjs() {
    if(this.o_performanceSelectedUserTrueDivisionFalse === undefined || this.o_performanceSelectedUserOrDivisionID === undefined) {
      return([]);
    }

    const performanceUserSelectedMoneyFieldDisplayName = this.c_performanceUserSelectedMoneyFieldDisplayName;
    const c_fieldMapOfAwardDate = DatabaseMobx.c_fieldMapOfAwardDate;
    const performanceMoneyFieldID = UserMobx.c_combinedUserObj.divexec_performance_money_field_id;

    const stageFieldID = DatabaseMobx.c_fieldMapOfStage.get("id");
    const activeStageIDsComma = JSFUNC.convert_array_to_comma_list(DatabaseMobx.c_activeStageIDsArray);
    const closedStageIDsComma = JSFUNC.convert_array_to_comma_list(DatabaseMobx.c_closedStageIDsArray);
    const closedWonStageIDsComma = JSFUNC.convert_array_to_comma_list(DatabaseMobx.c_closedWonStageIDsArray);
    const closedLostStageIDsComma = JSFUNC.convert_array_to_comma_list(DatabaseMobx.c_closedLostStageIDsArray);

    const reasonsWonLostFieldID = DatabaseMobx.c_fieldMapOfReasonsWonLost.get("id");
    const reasonsWonIDsComma = JSFUNC.convert_array_to_comma_list(DatabaseMobx.c_valueDisplayArraysObjReasonsWonOrBoth.valueArray);
    const reasonsLostIDsComma = JSFUNC.convert_array_to_comma_list(DatabaseMobx.c_valueDisplayArraysObjReasonsLostOrBoth.valueArray);

    const awardDateFieldID = c_fieldMapOfAwardDate.get("id");

    //create the capture filter for this single user/division
    var userOrDivisionString = "";
    var ownersCaptureFieldID = undefined;
    var userOrDivisionName = "";
    var selectedUserIDOrDivisionAndSubdivisionIDsCommaString = "";
    if(this.o_performanceSelectedUserTrueDivisionFalse) { //selected user
      userOrDivisionString = "User";
      ownersCaptureFieldID = DatabaseMobx.c_fieldMapOfCaptureManagers.get("id");

      const combinedUserMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_users", this.o_performanceSelectedUserOrDivisionID);
      userOrDivisionName = combinedUserMap.get("fullName");

      selectedUserIDOrDivisionAndSubdivisionIDsCommaString = JSFUNC.num2str(this.o_performanceSelectedUserOrDivisionID);
    }
    else { //selected division
      userOrDivisionString = "Division";
      ownersCaptureFieldID = DatabaseMobx.c_fieldMapOfDivisionOwners.get("id");

      const divisionMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_divisions", this.o_performanceSelectedUserOrDivisionID);
      userOrDivisionName = divisionMap.get("name");

      const selectedDivisionTreeID = divisionMap.get("tree_id");
      var subdivisionIDsArray = JSFUNC.get_all_children_ids_array_from_parent_tree_id(DatabaseMobx.c_allDivisionsArrayOfObjs, selectedDivisionTreeID);
      subdivisionIDsArray.push(this.o_performanceSelectedUserOrDivisionID);
      selectedUserIDOrDivisionAndSubdivisionIDsCommaString = JSFUNC.convert_array_to_comma_list(subdivisionIDsArray);
    }

    //only captures owned by this user/division
    const ownerFilterName = "Captures for " + userOrDivisionString + " '" + userOrDivisionName + "'" + ((this.o_performanceSelectedUserTrueDivisionFalse) ? ("") : (" (and all subdivisions)"));
    const ownerFiltersArrayOfObjs = [{capture_field_id:ownersCaptureFieldID, operator:"e", value:selectedUserIDOrDivisionAndSubdivisionIDsCommaString}];
    const ownerMatchingCapturesFilterPresetObj = this.create_matching_captures_filter_preset_obj_from_captures_mapOfMaps_and_filters_arrayOfObjs_and_filter_name(DatabaseMobx.o_tbl_captures, ownerFiltersArrayOfObjs);

    //captures in won stages (can filter for won stages using the previously filtered by owner captures for only this user/division)
    const closedWonStagesFilterName = "Won Captures for " + userOrDivisionString + " '" + userOrDivisionName + "'";
    const closedWonStagesFiltersArrayOfObjs = [{capture_field_id:stageFieldID, operator:"e", value:closedWonStageIDsComma}];
    const closedWonStagesMatchingCapturesFilterPresetObj = this.create_matching_captures_filter_preset_obj_from_captures_mapOfMaps_and_filters_arrayOfObjs_and_filter_name(ownerMatchingCapturesFilterPresetObj.matchingCapturesMapOfMaps, closedWonStagesFiltersArrayOfObjs);

    //captures in lost stages (can filter for lost stages using the previously filtered by owner captures for only this user/division)
    const closedLostStagesFilterName = "Lost Captures for " + userOrDivisionString + " '" + userOrDivisionName + "'";
    const closedLostStagesFiltersArrayOfObjs = [{capture_field_id:stageFieldID, operator:"e", value:closedLostStageIDsComma}];
    const closedLostStagesMatchingCapturesFilterPresetObj = this.create_matching_captures_filter_preset_obj_from_captures_mapOfMaps_and_filters_arrayOfObjs_and_filter_name(ownerMatchingCapturesFilterPresetObj.matchingCapturesMapOfMaps, closedLostStagesFiltersArrayOfObjs);

    const activePipelineNumCapturesGraphObj = {
      sort: 1,
      filter_preset_id: -1,
      categories_field_id: stageFieldID,
      all_categories_01: 0,
      categories_option_ids_comma: activeStageIDsComma,
      categories_include_not_set_01: 0,
      graph_type_p0_vb1_hb2_f3: 3,
      legend_01: 1,
      legend_hide_zero_categories_01: 0,
      num_captures_m2_or_money_field_id: -2,
      graph_title: "Active Stages Pipeline (# Captures)"
    };

    const activePipelineTcvGraphObj = {
      sort: 2,
      filter_preset_id: -1,
      categories_field_id: stageFieldID,
      all_categories_01: 0,
      categories_option_ids_comma: activeStageIDsComma,
      categories_include_not_set_01: 0,
      graph_type_p0_vb1_hb2_f3: 3,
      legend_01: 1,
      legend_hide_zero_categories_01: 0,
      num_captures_m2_or_money_field_id: performanceMoneyFieldID,
      graph_title: "Active Stages Pipeline ($ " + performanceUserSelectedMoneyFieldDisplayName + ")"
    };

    const closedStagesNumCapturesGraphObj = {
      circleGraphTrueTimeGraphFalse: true,
      sort: 3,
      filter_preset_id: -1,
      categories_field_id: stageFieldID,
      all_categories_01: 0,
      categories_option_ids_comma: closedStageIDsComma,
      categories_include_not_set_01: 0,
      graph_type_p0_vb1_hb2_f3: 0,
      legend_01: 1,
      legend_hide_zero_categories_01: 0,
      num_captures_m2_or_money_field_id: -2,
      graph_title: "Closed Stages (# Captures)"
    };

    const closedStagesTcvGraphObj = {
      circleGraphTrueTimeGraphFalse: true,
      sort: 4,
      filter_preset_id: -1,
      categories_field_id: stageFieldID,
      all_categories_01: 0,
      categories_option_ids_comma: closedStageIDsComma,
      categories_include_not_set_01: 0,
      graph_type_p0_vb1_hb2_f3: 0,
      legend_01: 1,
      legend_hide_zero_categories_01: 0,
      num_captures_m2_or_money_field_id: performanceMoneyFieldID,
      graph_title: "Closed Stages ($ " + performanceUserSelectedMoneyFieldDisplayName + ")"
    };

    const reasonsWonNumCapturesTimeGraphObj = {
      sort: 5,
      filter_preset_id: -1,
      date_field_id: awardDateFieldID,
      use_categories_field_01: 1,
      categories_field_id: reasonsWonLostFieldID,
      all_categories_01: 0,
      categories_option_ids_comma: reasonsWonIDsComma,
      categories_include_not_set_01: 0,
      line0_bar1: 0,
      legend_01: 1,
      legend_hide_zero_categories_01: 0,
      num_captures_m2_or_money_field_id: -2,
      total1_cumutotal2_ratio3:1,
      time_bins:"yearly",
      graph_title: "Wins by Reason (# Captures)"
    };

    const reasonsWonTcvTimeGraphObj = {
      sort: 6,
      filter_preset_id: -1,
      date_field_id: awardDateFieldID,
      use_categories_field_01: 1,
      categories_field_id: reasonsWonLostFieldID,
      all_categories_01: 0,
      categories_option_ids_comma: reasonsWonIDsComma,
      categories_include_not_set_01: 0,
      line0_bar1: 0,
      legend_01: 1,
      legend_hide_zero_categories_01: 0,
      num_captures_m2_or_money_field_id: performanceMoneyFieldID,
      total1_cumutotal2_ratio3:1,
      time_bins:"yearly",
      graph_title: "Wins by Reason ($ " + performanceUserSelectedMoneyFieldDisplayName + ")"
    };

    const reasonsLostNumCapturesTimeGraphObj = {
      sort: 7,
      filter_preset_id: -1,
      date_field_id: awardDateFieldID,
      use_categories_field_01: 1,
      categories_field_id: reasonsWonLostFieldID,
      all_categories_01: 0,
      categories_option_ids_comma: reasonsLostIDsComma,
      categories_include_not_set_01: 1,
      line0_bar1: 0,
      legend_01: 1,
      legend_hide_zero_categories_01: 0,
      num_captures_m2_or_money_field_id: -2,
      total1_cumutotal2_ratio3:1,
      time_bins:"yearly",
      graph_title: "Losses by Reason (# Captures)"
    };

    const reasonsLostTcvTimeGraphObj = {
      sort: 8,
      filter_preset_id: -1,
      date_field_id: awardDateFieldID,
      use_categories_field_01: 1,
      categories_field_id: reasonsWonLostFieldID,
      all_categories_01: 0,
      categories_option_ids_comma: reasonsLostIDsComma,
      categories_include_not_set_01: 1,
      line0_bar1: 0,
      legend_01: 1,
      legend_hide_zero_categories_01: 0,
      num_captures_m2_or_money_field_id: performanceMoneyFieldID,
      total1_cumutotal2_ratio3:1,
      time_bins:"yearly",
      graph_title: "Losses by Reason ($ " + performanceUserSelectedMoneyFieldDisplayName + ")"
    };

    var performanceGraphsDataArrayOfObjs = [
      this.compute_graph_data_obj_from_graph_obj_and_matching_captures_filter_preset_obj(activePipelineNumCapturesGraphObj, true, ownerMatchingCapturesFilterPresetObj, ownerFilterName),
      this.compute_graph_data_obj_from_graph_obj_and_matching_captures_filter_preset_obj(activePipelineTcvGraphObj, true, ownerMatchingCapturesFilterPresetObj, ownerFilterName),
      this.compute_graph_data_obj_from_graph_obj_and_matching_captures_filter_preset_obj(closedStagesNumCapturesGraphObj, true, ownerMatchingCapturesFilterPresetObj, ownerFilterName),
      this.compute_graph_data_obj_from_graph_obj_and_matching_captures_filter_preset_obj(closedStagesTcvGraphObj, true, ownerMatchingCapturesFilterPresetObj, ownerFilterName),
      this.compute_graph_data_obj_from_graph_obj_and_matching_captures_filter_preset_obj(reasonsWonNumCapturesTimeGraphObj, false, closedWonStagesMatchingCapturesFilterPresetObj, closedWonStagesFilterName),
      this.compute_graph_data_obj_from_graph_obj_and_matching_captures_filter_preset_obj(reasonsWonTcvTimeGraphObj, false, closedWonStagesMatchingCapturesFilterPresetObj, closedWonStagesFilterName),
      this.compute_graph_data_obj_from_graph_obj_and_matching_captures_filter_preset_obj(reasonsLostNumCapturesTimeGraphObj, false, closedLostStagesMatchingCapturesFilterPresetObj, closedLostStagesFilterName),
      this.compute_graph_data_obj_from_graph_obj_and_matching_captures_filter_preset_obj(reasonsLostTcvTimeGraphObj, false, closedLostStagesMatchingCapturesFilterPresetObj, closedLostStagesFilterName)
    ];

    return(performanceGraphsDataArrayOfObjs);
  }

  get c_myPerformanceMoneyFieldIDsToNotIncludeArray() {
    const o_performanceSelectedUserTrueDivisionFalse = this.o_performanceSelectedUserTrueDivisionFalse;
    const o_performanceSelectedUserOrDivisionID = this.o_performanceSelectedUserOrDivisionID;
    const selectCaptureRevenueCardMoneyFieldFieldTypeObj = DatabaseMobx.c_selectCaptureRevenueCardMoneyFieldFieldTypeObj;

    var myPerformanceMoneyFieldIDsToNotIncludeArray = [];

    const quotaUser0Division1 = ((o_performanceSelectedUserTrueDivisionFalse) ? (0) : (1));

    if(selectCaptureRevenueCardMoneyFieldFieldTypeObj.selectWithSearchDataObj !== undefined) {
      const allRevenueCardMoneyFieldIDsArray = selectCaptureRevenueCardMoneyFieldFieldTypeObj.selectWithSearchDataObj.valueArray;
      if(JSFUNC.is_array(allRevenueCardMoneyFieldIDsArray)) {
        myPerformanceMoneyFieldIDsToNotIncludeArray = allRevenueCardMoneyFieldIDsArray; //start with all of the money fields, remove each one that has at least one quota for it
        for(let quotaMap of DatabaseMobx.o_tbl_d_quota.values()) {
          if((quotaMap.get("user0_division1") === quotaUser0Division1) && (quotaMap.get("userdiv_id") === o_performanceSelectedUserOrDivisionID)) {
            var quotaMoneyFieldID = quotaMap.get("money_field_id");
            if(JSFUNC.in_array(quotaMoneyFieldID, myPerformanceMoneyFieldIDsToNotIncludeArray)) {
              myPerformanceMoneyFieldIDsToNotIncludeArray = JSFUNC.remove_all_values_from_array(quotaMoneyFieldID, myPerformanceMoneyFieldIDsToNotIncludeArray);
            }
          }
        }
      }

      //if every money field is still present in the array of values to not include (most obvious example is user has 0 quotas set for any money field), remove the fieldID for contract revenue value so that at least that field shows up in the spinner
      if(myPerformanceMoneyFieldIDsToNotIncludeArray.length === allRevenueCardMoneyFieldIDsArray.length) {
        myPerformanceMoneyFieldIDsToNotIncludeArray = JSFUNC.remove_all_values_from_array(DatabaseMobx.c_fieldMapOfContractRevenueValue.get("id"), myPerformanceMoneyFieldIDsToNotIncludeArray);
      }
    }

    return(myPerformanceMoneyFieldIDsToNotIncludeArray);
  }





  //graphs
  get c_tabDailySnapshotTrueTrendAnalyzerFalse() {
    return(CaptureExecMobx.o_leftNavTabNameSelected === "Daily Snapshot");
  }

  get c_dailySnapshotPagesArrayOfObjs() {
    const pageFieldNamesArray = this.graphs_daily_snapshot_page_field_names_array();
    return(this.get_pages_arrayOfObjs_from_tbl_mapOfMaps_also_replacing_replica_rows("tbl_d_daily_snapshot_pages", UserMobx.o_userID, pageFieldNamesArray));
  }

  get c_trendAnalyzerPagesArrayOfObjs() {
    const pageFieldNamesArray = this.graphs_trend_analyzer_page_field_names_array();
    return(this.get_pages_arrayOfObjs_from_tbl_mapOfMaps_also_replacing_replica_rows("tbl_d_trend_analyzer_pages", UserMobx.o_userID, pageFieldNamesArray));
  }

  get_pages_arrayOfObjs_from_tbl_mapOfMaps_also_replacing_replica_rows(i_pagesTblName, i_filterUserID, i_pageFieldNamesArray) {
    //get the tblRef from the tblName
    const tblRefMapOfMaps = DatabaseMobx.tbl_ref_from_tbl_name(i_pagesTblName);

    //get all of the graphs on this user's currently open page
    var pagesArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(tblRefMapOfMaps, "user_id", i_filterUserID, "sort", true);

    //loop through each graph and replace values for replicas of public graphs
    for(let pageObj of pagesArrayOfObjs) {
      if(pageObj.replica_of_page_id > 0) { //if this page/graph is a replica of another public page/graph, replace all pageObj values with the referenced graph's values
        var referencedPageMap = tblRefMapOfMaps.get(pageObj.replica_of_page_id);
        if(referencedPageMap !== undefined) {
          for(let fieldName of i_pageFieldNamesArray) {
            if(!JSFUNC.in_array(fieldName, ["user_id", "sort", "public_01", "replica_of_page_id"])) { //these page/graph fields keep their original value from the replica row
              pageObj[fieldName] = referencedPageMap.get(fieldName); //all other graph fields have their values copied from the referenced graph
            }
          }
        }
        else { //reference graph doesn't exist, change the graph title so that it shows up with a message
          pageObj.name = "--Public Page (ID: " + pageObj.replica_of_page_id + ") Does Not Exist--";
        }
      }
    }
    return(pagesArrayOfObjs);
  }

  get c_dailySnapshotGraphsArrayOfObjs() {
    const dailySnapshotFieldNamesArray = this.graphs_daily_snapshot_graphs_field_names_array();
    return(this.get_graphs_arrayOfObjs_from_tbl_mapOfMaps_also_replacing_replica_rows("tbl_d_daily_snapshot_pages", "tbl_d_daily_snapshot_graphs", this.o_dailySnapshotSelectedPageID, dailySnapshotFieldNamesArray));
  }

  get c_trendAnalyzerGraphsArrayOfObjs() {
    const trendAnalyzerFieldNamesArray = this.graphs_trend_analyzer_graphs_field_names_array();
    return(this.get_graphs_arrayOfObjs_from_tbl_mapOfMaps_also_replacing_replica_rows("tbl_d_trend_analyzer_pages", "tbl_d_trend_analyzer_graphs", this.o_trendAnalyzerSelectedPageID, trendAnalyzerFieldNamesArray));
  }

  get_graphs_arrayOfObjs_from_tbl_mapOfMaps_also_replacing_replica_rows(i_pagesTblName, i_graphsTblName, i_filterPageID, i_tblFieldNamesArray) {
    //if loading graphs for a page_id filter value, check if the corresponding page row is a replica, if so, use the replica_of_page_id as the filter page_id value
    var filterPageID = i_filterPageID;
    const pagesTblRefMapOfMaps = DatabaseMobx.tbl_ref_from_tbl_name(i_pagesTblName);
    const referencedPageMap = pagesTblRefMapOfMaps.get(i_filterPageID);
    if(referencedPageMap !== undefined) {
      if(referencedPageMap.get("replica_of_page_id") > 0) {
        filterPageID = referencedPageMap.get("replica_of_page_id");
      }
    }

    //get the tblRef from the tblName
    const tblRefMapOfMaps = DatabaseMobx.tbl_ref_from_tbl_name(i_graphsTblName);

    //get all of the graphs on this user's currently open page
    var graphsArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(tblRefMapOfMaps, "page_id", filterPageID, "sort", true);

    //loop through each graph and replace values for replicas of public graphs
    for(let graphObj of graphsArrayOfObjs) {
      if(graphObj.replica_of_graph_id > 0) { //if this page/graph is a replica of another public page/graph, replace all graphObj values with the referenced graph's values
        var referencedGraphMap = tblRefMapOfMaps.get(graphObj.replica_of_graph_id);
        if(referencedGraphMap !== undefined) {
          for(let fieldName of i_tblFieldNamesArray) {
            if(!JSFUNC.in_array(fieldName, ["page_id", "sort", "public_01", "replica_of_graph_id"])) { //these page/graph fields keep their original value from the replica row
              graphObj[fieldName] = referencedGraphMap.get(fieldName); //all other graph fields have their values copied from the referenced graph
            }
          }
        }
        else { //reference graph doesn't exist, change the graph title so that it shows up with a message
          graphObj.graph_title = "--Public Graph (ID: " + graphObj.replica_of_graph_id + ") Does Not Exist--";
        }
      }
    }
    return(graphsArrayOfObjs);
  }

  get c_graphsSelectedTabName() {
    return((this.c_tabDailySnapshotTrueTrendAnalyzerFalse) ? ("Daily Snapshot") : ("Trend Analyzer"));
  }
  get c_graphsSelectedTabPagesTblName() {
    return((this.c_tabDailySnapshotTrueTrendAnalyzerFalse) ? ("tbl_d_daily_snapshot_pages") : ("tbl_d_trend_analyzer_pages"));
  }
  get c_graphsSelectedTabGraphsTblName() {
    return((this.c_tabDailySnapshotTrueTrendAnalyzerFalse) ? ("tbl_d_daily_snapshot_graphs") : ("tbl_d_trend_analyzer_graphs"));
  }

  get c_graphsSelectedTabAllPagesArrayOfObjs() {
    return((this.c_tabDailySnapshotTrueTrendAnalyzerFalse) ? (this.c_dailySnapshotPagesArrayOfObjs) : (this.c_trendAnalyzerPagesArrayOfObjs));
  }

  get c_graphsSelectedTabAllGraphsArrayOfObjs() {
    return((this.c_tabDailySnapshotTrueTrendAnalyzerFalse) ? (this.c_dailySnapshotGraphsArrayOfObjs) : (this.c_trendAnalyzerGraphsArrayOfObjs));
  }

  get c_graphsSelectedTabUserAllAddedPageIDsArray() {
    var graphsSelectedTabUserAllAddedPageIDsArray = [];
    for(let pageObj of this.c_graphsSelectedTabAllPagesArrayOfObjs) {
      if(pageObj.replica_of_page_id > 0) {
        graphsSelectedTabUserAllAddedPageIDsArray.push(pageObj.replica_of_page_id);
      }
      else {
        graphsSelectedTabUserAllAddedPageIDsArray.push(pageObj.id);
      }
    }
    return(graphsSelectedTabUserAllAddedPageIDsArray);
  }

  get c_graphsSelectedTabUserAllAddedGraphIDsObjOfArrays() {
    var graphIDsArray = [];
    var pageNamesCommaArray = [];
    for(let graphObj of this.c_graphsSelectedTabAllGraphsArrayOfObjs) {
      if(graphObj.replica_of_graph_id > 0) {
        if(!JSFUNC.in_array(graphObj.replica_of_graph_id, graphIDsArray)) {
          graphIDsArray.push(graphObj.replica_of_graph_id);

          var pageNamesArray = [];
          for(let pageObj of this.c_graphsSelectedTabAllPagesArrayOfObjs) {
            if((pageObj.user_id === UserMobx.o_userID) && (pageObj.id === graphObj.page_id)) {
              pageNamesArray.push(pageObj.name);
            }
          }
          pageNamesCommaArray.push(pageNamesArray.join(", "));
        }
      }
    }
    return({
      graphIDsArray: graphIDsArray,
      pageNamesCommaArray: pageNamesCommaArray
    });
  }

  get c_graphsSelectedTabAllPublicPagesPerUserCreatorArrayOfObjs() {
    //only take public pages from this tab (daily or trend), record all unique user creators for later categorization and sorting
    const tblRefMapOfMaps = DatabaseMobx.tbl_ref_from_tbl_name(this.c_graphsSelectedTabPagesTblName);
    var allPublicPagesArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(tblRefMapOfMaps, "public_01", 1);

    var graphsSelectedTabAllPublicPagesArrayOfObjs = [];
    var uniqueCreatorUserIDsArray = [];
    for(let pageObj of allPublicPagesArrayOfObjs) {
      var copyPublicPageObj = JSFUNC.copy_obj(pageObj);
      graphsSelectedTabAllPublicPagesArrayOfObjs.push(copyPublicPageObj);

      if(pageObj.created_by_user_id !== UserMobx.o_userID) { //do not show public pages that the logged in user has created themselves in this list
        if(!JSFUNC.in_array(pageObj.created_by_user_id, uniqueCreatorUserIDsArray)) { //only store 1 copy of each userID
          uniqueCreatorUserIDsArray.push(pageObj.created_by_user_id);
        }
      }
    }

    //loop through each unique user
    var graphsSelectedTabAllPublicPagesPerUserCreatorArrayOfObjs = [];
    for(let uniqueCreatorUserID of uniqueCreatorUserIDsArray) {
      var userPagesArrayOfObjs = [];
      for(let pageObj of graphsSelectedTabAllPublicPagesArrayOfObjs) {
        if(pageObj.created_by_user_id === uniqueCreatorUserID) {
          userPagesArrayOfObjs.push(pageObj);
        }
      }

      //within each user creator, sort all graphs by title alphabetically
      JSFUNC.sort_arrayOfObjs(userPagesArrayOfObjs, "name", true);

      var userNameMaskSortIfoObj = DatabaseMobx.user_name_mask_sort_ifo_obj_from_user_id(uniqueCreatorUserID);
      graphsSelectedTabAllPublicPagesPerUserCreatorArrayOfObjs.push({
        id: uniqueCreatorUserID,
        userNameMaskPlainText: userNameMaskSortIfoObj.valueMaskPlainText,
        userNameSort: userNameMaskSortIfoObj.valueSort,
        userPagesArrayOfObjs: userPagesArrayOfObjs
      });

      //sort the creators by their name alphabetically
      JSFUNC.sort_arrayOfObjs(graphsSelectedTabAllPublicPagesPerUserCreatorArrayOfObjs, "userNameSort", true);
    }

    return(graphsSelectedTabAllPublicPagesPerUserCreatorArrayOfObjs);
  }

  get c_graphsSelectedTabAllPublicGraphsDataPerUserCreatorArrayOfObjs() {
    //only take public graphs from this tab (daily or trend), record all unique user creators for later categorization and sorting
    const tblRefMapOfMaps = DatabaseMobx.tbl_ref_from_tbl_name(this.c_graphsSelectedTabGraphsTblName);
    var allPublicGraphsArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(tblRefMapOfMaps, "public_01", 1);

    var graphsSelectedTabAllPublicGraphsArrayOfObjs = [];
    var uniqueCreatorUserIDsArray = [];
    for(let graphObj of allPublicGraphsArrayOfObjs) {
      var copyPublicGraphObj = JSFUNC.copy_obj(graphObj);
      var userNameMaskSortIfoObj = DatabaseMobx.user_name_mask_sort_ifo_obj_from_user_id(graphObj.created_by_user_id);
      copyPublicGraphObj.userNameMaskPlainText = userNameMaskSortIfoObj.valueMaskPlainText;
      copyPublicGraphObj.userNameSort = userNameMaskSortIfoObj.valueSort;
      graphsSelectedTabAllPublicGraphsArrayOfObjs.push(copyPublicGraphObj);

      if(!JSFUNC.in_array(graphObj.created_by_user_id, uniqueCreatorUserIDsArray)) {
        uniqueCreatorUserIDsArray.push(graphObj.created_by_user_id);
      }
    }

    //compute all graphDataObjs for every public graph
    const allPublicGraphsDataArrayOfObjs = this.compute_graphs_data_arrayOfObjs_from_graphs_arrayOfObjs(graphsSelectedTabAllPublicGraphsArrayOfObjs);

    var graphsSelectedTabAllPublicGraphsDataPerUserCreatorArrayOfObjs = [];
    for(let uniqueCreatorUserID of uniqueCreatorUserIDsArray) {
      var userGraphsDataArrayOfObjs = [];
      var uniqueCreatorUserNameMaskPlainText = undefined;
      var uniqueCreatorUserNameSort = undefined;
      for(let graphDataObj of allPublicGraphsDataArrayOfObjs) {
        if(graphDataObj.graphObj.created_by_user_id === uniqueCreatorUserID) {
          graphDataObj.graphTitleSort = graphDataObj.graphObj.graph_title; //bring the graph title to the top layer of the data obj so that it can be sorted
          userGraphsDataArrayOfObjs.push(graphDataObj);
          uniqueCreatorUserNameMaskPlainText = graphDataObj.graphObj.userNameMaskPlainText;
          uniqueCreatorUserNameSort = graphDataObj.graphObj.userNameSort;
        }
      }

      //within each user creator, sort all graphs by title alphabetically
      JSFUNC.sort_arrayOfObjs(userGraphsDataArrayOfObjs, "graphTitleSort", true);

      graphsSelectedTabAllPublicGraphsDataPerUserCreatorArrayOfObjs.push({
        id: uniqueCreatorUserID,
        userNameMaskPlainText: uniqueCreatorUserNameMaskPlainText,
        userNameSort: uniqueCreatorUserNameSort,
        userGraphsDataArrayOfObjs: userGraphsDataArrayOfObjs
      });

      //sort the creators by their name alphabetically
      JSFUNC.sort_arrayOfObjs(graphsSelectedTabAllPublicGraphsDataPerUserCreatorArrayOfObjs, "userNameSort", true);
    }

    return(graphsSelectedTabAllPublicGraphsDataPerUserCreatorArrayOfObjs);
  }

  get c_graphsSelectedPageID() {
    return((this.c_tabDailySnapshotTrueTrendAnalyzerFalse) ? (this.o_dailySnapshotSelectedPageID) : (this.o_trendAnalyzerSelectedPageID));
  }
  get c_graphsSelectedPageObj() {
    if(this.c_graphsSelectedPageID !== undefined) {
      for(let pageObj of this.c_graphsSelectedTabAllPagesArrayOfObjs) {
        if(pageObj.id === this.c_graphsSelectedPageID) {
          return(pageObj);
        }
      }
    }
    return(undefined);
  }
  get c_graphsSelectedPageIsReplicaOfPublicPageTF() {
    if(this.c_graphsSelectedPageObj !== undefined) {
      return(this.c_graphsSelectedPageObj.replica_of_page_id > 0);
    }
    return(false);
  }
  get c_graphsSelectedPageNumGraphsPerRow() {
    if(this.c_graphsSelectedPageObj !== undefined) {
      return(this.c_graphsSelectedPageObj.num_graphs_per_row);
    }
    return(1);
  }
  get c_graphsSelectedPageGraphHeightEm() {
    if(this.c_graphsSelectedPageObj !== undefined) {
      return(this.c_graphsSelectedPageObj.graph_height_em);
    }
    return(28);
  }

  get c_graphsSelectedPageGraphsDataArrayOfObjs() {
    //if a graph is being edited, remove all graphs from the page in the background behind edit floating box so that all the changes don't redraw every graph on the page which could be very slow
    if(this.o_graphsEditingGraphID !== undefined) {
      return([]);
    }

    //otherwise compute all the graph data
    return(this.compute_graphs_data_arrayOfObjs_from_graphs_arrayOfObjs(this.c_graphsSelectedTabAllGraphsArrayOfObjs));
  }

  get c_graphsEditingGraphDataObj() {
    if(this.o_graphsEditingGraphID === undefined) {
      return(undefined);
    }

    for(let graphObj of this.c_graphsSelectedTabAllGraphsArrayOfObjs) {
      if(graphObj.id === this.o_graphsEditingGraphID) {
        var graphsDataArrayOfObjs = this.compute_graphs_data_arrayOfObjs_from_graphs_arrayOfObjs([graphObj]);
        if(graphsDataArrayOfObjs.length === 1) {
          return(graphsDataArrayOfObjs[0]);
        }
        return(undefined);
      }
    }
    return(undefined);
  }

  compute_graphs_data_arrayOfObjs_from_graphs_arrayOfObjs(i_graphsArrayOfObjs) {
    if(i_graphsArrayOfObjs.length === 0) {
      return([]);
    }

    //get all of the unique filter presetIDs from all of the graphs on this page
    var allGraphsUniqueFilterPresetIDsArray = [];
    for(let graphObj of i_graphsArrayOfObjs) {
      allGraphsUniqueFilterPresetIDsArray.push(graphObj.filter_preset_id);
    }
    allGraphsUniqueFilterPresetIDsArray = JSFUNC.unique(allGraphsUniqueFilterPresetIDsArray);

    //for each filterPresetID, load the filters and create an expandedFiltersObj, filter all of the captures using it and append the resulting captures
    var matchingCapturesPerFilterPresetIDMapOfObjs = new Map();
    for(let filterPresetID of allGraphsUniqueFilterPresetIDsArray) {
      //check that the selected filterPresetID exists
      var filterPresetMap = DatabaseMobx.o_tbl_f_filter_presets.get(filterPresetID);
      if(filterPresetMap !== undefined) {
        var captureFilterPresetFiltersArrayOfObjs = JSFUNC.filtered_sorted_arrayOfObjs_from_mapOfMaps_matching_field_value(DatabaseMobx.o_tbl_f_filters, "filter_preset_id", filterPresetID); //load the filters from the filter preset
        var matchingCapturesFilterPresetObj = this.create_matching_captures_filter_preset_obj_from_captures_mapOfMaps_and_filters_arrayOfObjs_and_filter_name(DatabaseMobx.o_tbl_captures, captureFilterPresetFiltersArrayOfObjs);
        matchingCapturesPerFilterPresetIDMapOfObjs.set(filterPresetID, matchingCapturesFilterPresetObj);
      }
    }

    //loop through each graph to collect, convert, and create data needed for each graph type
    var graphsSelectedPageGraphsDataArrayOfObjs = [];
    for(let graphObj of i_graphsArrayOfObjs) {
      //get the filtered captures from the prestored filterPreset capture lists
      var filterPresetName = undefined;
      var matchingCapturesFilterPresetObj = matchingCapturesPerFilterPresetIDMapOfObjs.get(graphObj.filter_preset_id);
      if(!JSFUNC.select_int_is_filled_out_tf(graphObj.filter_preset_id)) {
        filterPresetName = "--No Capture Filter Preset Selected--";
      }
      else if(matchingCapturesFilterPresetObj === undefined) {
        filterPresetName = "--Selected Filter Preset Does Not Exist (ID: " + graphObj.filter_preset_id + ")--";
      }
      else {
        var filterPresetMap = DatabaseMobx.tbl_row_map_from_id("tbl_f_filter_presets", graphObj.filter_preset_id);
        filterPresetName = filterPresetMap.get("name");
      }

      //final resulting graphDataObj to be added to the array for this page of graphs
      var graphDataObj = this.compute_graph_data_obj_from_graph_obj_and_matching_captures_filter_preset_obj(graphObj, this.c_tabDailySnapshotTrueTrendAnalyzerFalse, matchingCapturesFilterPresetObj, filterPresetName);
      graphsSelectedPageGraphsDataArrayOfObjs.push(graphDataObj);
    }
    return(graphsSelectedPageGraphsDataArrayOfObjs);
  }

  create_matching_captures_filter_preset_obj_from_captures_mapOfMaps_and_filters_arrayOfObjs_and_filter_name(i_capturesMapOfMaps, i_filtersArrayOfObjs) {
    const expandedFiltersArrayOfObjs = CapturesMobx.create_expanded_filters_arrayOfObjs_from_filters_arrayOfObjs(i_filtersArrayOfObjs);
    const matchingCapturesMapOfMaps = CapturesMobx.get_filtered_captures_mapOfMaps_from_captures_mapOfMaps_and_expanded_filters_arrayOfObjs(i_capturesMapOfMaps, expandedFiltersArrayOfObjs);
    return({
      filtersArrayOfObjs: i_filtersArrayOfObjs,
      expandedFiltersArrayOfObjs: expandedFiltersArrayOfObjs,
      matchingCapturesMapOfMaps: matchingCapturesMapOfMaps
    });
  }

  compute_graph_data_obj_from_graph_obj_and_matching_captures_filter_preset_obj(i_graphObj, i_circleGraphTrueTimeGraphFalse, i_matchingCapturesFilterPresetObj, i_filterPresetName) {
    var graphDateFieldID = i_graphObj.date_field_id; //only used in time graph Trend Analyzer graphs
    var graphUseCategoriesField01 = i_graphObj.use_categories_field_01; //only used in time graph Trend Analyzer graphs
    var graphCategoriesFieldID = i_graphObj.categories_field_id;
    var graphAllCategories01 = i_graphObj.all_categories_01;
    var graphCategoriesOptionIDsComma = i_graphObj.categories_option_ids_comma;
    var graphCategoriesIncludeNotSet01 = i_graphObj.categories_include_not_set_01;
    var graphNumCapturesM2OrMoneyFieldID = i_graphObj.num_captures_m2_or_money_field_id;

    var dateFieldName = undefined;
    var dateFieldDbName = undefined;
    var countValueNumCapturesOrMoneyFieldName = undefined;
    var valueFormatString = undefined;
    var categoriesFieldName = undefined;
    var categoriesFieldAllOptionIDsArray = undefined;
    var categoriesFieldAllOptionDisplaysArray = undefined;
    var categoriesFieldAllOptionColorsArray = undefined;
    var categoriesOptionIDsArray = undefined;
    var categoriesExpandedFiltersArrayOfObjs = undefined;
    var categoriesArrayOfObjs = [];
    var totalNumCapturesOrMoneyFieldValueLabel = undefined;

    //----------------------------------------------------------------------------
    //get the selected capture date field name for trend graphs
    if(!i_circleGraphTrueTimeGraphFalse) {
      if(!JSFUNC.select_int_is_filled_out_tf(graphDateFieldID)) {
        dateFieldName = "--No Capture Date Field Selected--";
      }
      else {
        const expandedCaptureDateFieldMap = DatabaseMobx.c_tbl_captures_fields.get(graphDateFieldID);
        if(expandedCaptureDateFieldMap === undefined) {
          dateFieldName = "--Selected Capture Date Field Does Not Exist (ID: " + graphDateFieldID + ")--";
        }
        else {
          dateFieldDbName = expandedCaptureDateFieldMap.get("db_name");
          const dateFieldTypeObj = expandedCaptureDateFieldMap.get("fieldTypeObj");
          if(!(dateFieldTypeObj.dateTF || dateFieldTypeObj.dateTimeTF)) {
            dateFieldName = "--Selected Capture Date Field is not a 'Date' Field Type--";
          }
          else {
            dateFieldName = expandedCaptureDateFieldMap.get("display_name");
          }
        }
      }
    }

    //----------------------------------------------------------------------------
    //get the selected capture Y-axis value (either # Captures or a selected money capture field)
    var numCapturesTrueMoneyFieldFalse = (graphNumCapturesM2OrMoneyFieldID === -2);
    var moneyExpandedCaptureFieldMap = undefined;
    if(numCapturesTrueMoneyFieldFalse) {
      countValueNumCapturesOrMoneyFieldName = "# Captures";
      valueFormatString = "number";
    }
    else {
      if(JSFUNC.select_int_is_filled_out_tf(graphNumCapturesM2OrMoneyFieldID)) { //a capture field (valid or invalid) has been selected for this graph
        moneyExpandedCaptureFieldMap = DatabaseMobx.fetch_expanded_capture_field_map_from_field_id(graphNumCapturesM2OrMoneyFieldID);
        countValueNumCapturesOrMoneyFieldName = moneyExpandedCaptureFieldMap.get("display_name");
      }
      else { //the option for graph capture value has been set to 'money capture field' but a field has not yet been selected (fieldID is -1 or 0)
        countValueNumCapturesOrMoneyFieldName = "--No Capture Money Field Selected--";
      }
      valueFormatString = "moneyShort";
    }

    //----------------------------------------------------------------------------
    //get the selected categories capture field name
    if(!JSFUNC.select_int_is_filled_out_tf(graphCategoriesFieldID)) {
      categoriesFieldName = "--No Capture Field Selected--";
    }
    else {
      const expandedCaptureFieldMap = DatabaseMobx.c_tbl_captures_fields.get(graphCategoriesFieldID); //get the expanded field map for this capture field from the fieldID
      if(expandedCaptureFieldMap === undefined) {
        categoriesFieldName = "--Selected Capture Field Does Not Exist (ID: " + graphCategoriesFieldID + ")--";
      }
      else {
        const fieldDisplayName = expandedCaptureFieldMap.get("display_name");
        const fieldTypeObj = expandedCaptureFieldMap.get("fieldTypeObj");

        //load the select option value/display/color arrays from the swsDataObj in the fieldTypeObj
        if(fieldTypeObj.selectWithSearchDataObj === undefined || !JSFUNC.is_array(fieldTypeObj.selectWithSearchDataObj.valueArray) || !JSFUNC.is_array(fieldTypeObj.selectWithSearchDataObj.displayArray)) {
          categoriesFieldName = "--Selected Capture Field '" + fieldDisplayName + "' Has Invalid SWS Data--";
        }
        else {
          categoriesFieldAllOptionIDsArray = fieldTypeObj.selectWithSearchDataObj.valueArray;
          categoriesFieldAllOptionDisplaysArray = fieldTypeObj.selectWithSearchDataObj.displayArray;
          categoriesFieldAllOptionColorsArray = fieldTypeObj.selectWithSearchDataObj.colorArray;

          //get all of the category select optionIDs into an array using a combination of the graphObj fields all_categories_01, categories_option_ids_comma, categories_include_not_set_01
          if(graphAllCategories01 === 1) { //use all options as the categories
            categoriesOptionIDsArray = JSFUNC.copy_array(categoriesFieldAllOptionIDsArray);
          }
          else { //use on the specified options from categories_option_ids_comma as the categories
            categoriesOptionIDsArray = JSFUNC.convert_comma_list_to_int_array(graphCategoriesOptionIDsComma);
          }

          //add the not set -1 category if specified
          if(graphCategoriesIncludeNotSet01 === 1) {
            categoriesOptionIDsArray.push(-1);
          }

          //error if there are 0 categories resulting from how the 3 graphObj fields are set
          const numCategories = categoriesOptionIDsArray.length;
          if(numCategories === 0) {
            categoriesFieldName = "--No Categories selected from Capture Field '" + fieldDisplayName + "'--";
          }
          else { //there are no errors with the selected category capture field and the name can be assigned
            categoriesFieldName = fieldDisplayName;

            if(i_matchingCapturesFilterPresetObj !== undefined) {
              //create a single filterObj (wrapped in an array to make a preset) for each category option
              categoriesExpandedFiltersArrayOfObjs = [];
              for(let c = 0; c < numCategories; c++) {
                var categoryOptionID = categoriesOptionIDsArray[c];

                //match the optionID to find the option name and color from the value/display/color arrays within the selectWithSearchDataObj of the fieldTypeObj
                var categoryName = undefined;
                var categoryColor = undefined;
                var matchIndex = categoriesFieldAllOptionIDsArray.indexOf(categoryOptionID);
                if(matchIndex >= 0) { //optionID is a valid option in the select list of options, get the display name and color if available
                  categoryName = categoriesFieldAllOptionDisplaysArray[matchIndex];
                  if(JSFUNC.is_array(categoriesFieldAllOptionColorsArray)) {
                    categoryColor = categoriesFieldAllOptionColorsArray[matchIndex];
                  }
                  else {
                    categoryColor = JSFUNC.unique_color(c)
                  }
                }
                else {
                  categoryName = "--Not Set--";
                  categoryColor = "999999";
                }

                //create the single category filter, then merge it into the main filters for partial TCV calculations
                var categoryFilterObj = undefined;
                if(categoryOptionID > 0) { //select options used as categories
                  categoryFilterObj = {capture_field_id:graphCategoriesFieldID, operator:"e", value:JSFUNC.num2str(categoryOptionID)};
                }
                else { //the not set category
                  const categoriesFieldAllOptionIDsComma = JSFUNC.convert_array_to_comma_list(categoriesFieldAllOptionIDsArray);
                  categoryFilterObj = {capture_field_id:graphCategoriesFieldID, operator:"ne", value:categoriesFieldAllOptionIDsComma}; //create a filterObj for a 'not set' if specified (filter is essentially not equal to every possible option, which catches not set and does not exist entries)
                }

                var combinedMainAndCategoryFiltersArrayOfObjs = [];
                for(let mainFilterObj of i_matchingCapturesFilterPresetObj.filtersArrayOfObjs) {
                  combinedMainAndCategoryFiltersArrayOfObjs.push(mainFilterObj);
                }
                combinedMainAndCategoryFiltersArrayOfObjs.push(categoryFilterObj);

                //put together the category data obj for this single category
                categoriesExpandedFiltersArrayOfObjs.push({
                  name: categoryName,
                  color: categoryColor,
                  combinedMainAndCategoryExpandedFilterArrayOfObjs: CapturesMobx.create_expanded_filters_arrayOfObjs_from_filters_arrayOfObjs(combinedMainAndCategoryFiltersArrayOfObjs)
                });
              }
            }
          }
        }
      }
    }

    //----------------------------------------------------------------------------
    //compute the categoriesArrayOfObjs for both types of graphs
    if(i_matchingCapturesFilterPresetObj !== undefined) {
      var totalCaptureIDsArray = undefined;
      if(i_circleGraphTrueTimeGraphFalse) { //daily snapshot circle/bar graphs
        //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
        //first loop gets the single filter and computes the total TCV/# captures for this category
        var numItemsPerCategoryArray = [];
        var totalTcvOrNumCapturesPerCategoryArray = [];
        var totalTcvOrNumCapturesAllCategories = 0;
        var captureIDsPerCategoryArrayOfArrays = [];
        totalCaptureIDsArray = [];
        if(JSFUNC.is_array(categoriesExpandedFiltersArrayOfObjs)) {
          for(let categoriesExpandedFilterObj of categoriesExpandedFiltersArrayOfObjs) {
            var categoryNumItems = 0;
            var categoryTotalNumCapturesOrMoneyFieldValue = 0;
            var captureIDsInCategoryArray = [];
            for(let [captureID, captureMap] of i_matchingCapturesFilterPresetObj.matchingCapturesMapOfMaps) {
              //partial tcv or # captures added to total
              var partialMultiplier0to1 = CapturesMobx.capture_matches_all_filters_partialMultiplier0to1_or_false_from_capture_map_and_expanded_filters_arrayOfObjs(captureMap, categoriesExpandedFilterObj.combinedMainAndCategoryExpandedFilterArrayOfObjs);

              //captureID appended for circle segment hyperlink to captures that make up category
              if(partialMultiplier0to1 !== false) {
                categoryNumItems++;
                captureIDsInCategoryArray.push(captureID);
                totalCaptureIDsArray.push(captureID);
              }

              if(partialMultiplier0to1 > 0) {
                var singleCaptureNumCapturesOrMoneyFieldValue = this.compute_graph_single_capture_num_captures_or_money_field_value_from_partial_multiplier(partialMultiplier0to1, captureMap, numCapturesTrueMoneyFieldFalse, moneyExpandedCaptureFieldMap);
                if(JSFUNC.is_number_not_nan(singleCaptureNumCapturesOrMoneyFieldValue)) {
                  categoryTotalNumCapturesOrMoneyFieldValue += singleCaptureNumCapturesOrMoneyFieldValue;
                }
              }
            }
            numItemsPerCategoryArray.push(categoryNumItems);
            totalTcvOrNumCapturesPerCategoryArray.push(categoryTotalNumCapturesOrMoneyFieldValue);
            if(JSFUNC.is_number_not_nan(categoryTotalNumCapturesOrMoneyFieldValue)) {
              totalTcvOrNumCapturesAllCategories += categoryTotalNumCapturesOrMoneyFieldValue;
            }
            captureIDsPerCategoryArrayOfArrays.push(captureIDsInCategoryArray);
          }

          //2nd loop uses the total value to calculate percents
          for(let i = 0; i < totalTcvOrNumCapturesPerCategoryArray.length; i++) {
            var categoriesExpandedFilterObj = categoriesExpandedFiltersArrayOfObjs[i];
            var categoryNumItems = numItemsPerCategoryArray[i];
            var categoryTotalNumCapturesOrMoneyFieldValue = totalTcvOrNumCapturesPerCategoryArray[i];

            var valueMaskPlainText = this.compute_graph_num_captures_or_money_field_value_mask_plain_text(numCapturesTrueMoneyFieldFalse, categoryTotalNumCapturesOrMoneyFieldValue);

            categoriesArrayOfObjs.push({
              numItems: categoryNumItems,
              valueRaw: categoryTotalNumCapturesOrMoneyFieldValue,
              valueMaskPlainText: valueMaskPlainText,
              color: categoriesExpandedFilterObj.color,
              label: categoriesExpandedFilterObj.name,
              clickReturnValue: captureIDsPerCategoryArrayOfArrays[i]
            });
          }
        }

        //format the total value label for the legend
        totalNumCapturesOrMoneyFieldValueLabel = this.compute_graph_num_captures_or_money_field_value_mask_plain_text(numCapturesTrueMoneyFieldFalse, totalTcvOrNumCapturesAllCategories);
        totalCaptureIDsArray = JSFUNC.unique(totalCaptureIDsArray);
        //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
      }
      else { //trend analyzer time graphs
        //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
        if(graphUseCategoriesField01 === 0) { //use all captures from filter preset combined into a single data series
          categoriesFieldName = "All Captures from selected Filter Preset";
          categoriesArrayOfObjs.push({
            idDateValueArrayOfObjs: this.compute_time_graph_id_date_value_arrayOfObjs_from_captures_mapOfMaps_and_expanded_filters_arrayOfObjs_and_date_field_db_name(i_matchingCapturesFilterPresetObj.matchingCapturesMapOfMaps, i_matchingCapturesFilterPresetObj.expandedFiltersArrayOfObjs, dateFieldDbName, numCapturesTrueMoneyFieldFalse, moneyExpandedCaptureFieldMap),
            color: JSFUNC.unique_color(0),
            label: "All Captures from selected Filter Preset"
          });
        }
        else { //use categories capture field
          if(JSFUNC.is_array(categoriesExpandedFiltersArrayOfObjs)) {
            for(let categoriesExpandedFilterObj of categoriesExpandedFiltersArrayOfObjs) {
              categoriesArrayOfObjs.push({
                idDateValueArrayOfObjs: this.compute_time_graph_id_date_value_arrayOfObjs_from_captures_mapOfMaps_and_expanded_filters_arrayOfObjs_and_date_field_db_name(i_matchingCapturesFilterPresetObj.matchingCapturesMapOfMaps, categoriesExpandedFilterObj.combinedMainAndCategoryExpandedFilterArrayOfObjs, dateFieldDbName, numCapturesTrueMoneyFieldFalse, moneyExpandedCaptureFieldMap),
                color: categoriesExpandedFilterObj.color,
                label: categoriesExpandedFilterObj.name
              });
            }
          }
        }
        //&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&
      }
    }

    //final resulting graphDataObj to be added to the array for this page of graphs
    return({
      circleGraphTrueTimeGraphFalse: i_circleGraphTrueTimeGraphFalse,
      graphObj: i_graphObj,
      filterPresetName: i_filterPresetName,
      dateFieldName: dateFieldName,
      countValueNumCapturesOrMoneyFieldName: countValueNumCapturesOrMoneyFieldName,
      valueFormatString: valueFormatString,
      categoriesFieldName: categoriesFieldName,
      categoriesArrayOfObjs: categoriesArrayOfObjs,
      totalNumCapturesOrMoneyFieldValueLabel: totalNumCapturesOrMoneyFieldValueLabel,
      totalCaptureIDsArray: totalCaptureIDsArray
    });
  }

  compute_graph_single_capture_num_captures_or_money_field_value_from_partial_multiplier(i_partialMultiplier0to1, i_captureMap, i_numCapturesTrueMoneyFieldFalse, i_moneyExpandedCaptureFieldMap) {
    //# captures returns the partial multiplier for this capture
    if(i_numCapturesTrueMoneyFieldFalse) {
      return(i_partialMultiplier0to1);
    }

    //selected money field returns partial multiplier times the capture money field value
    if(i_moneyExpandedCaptureFieldMap !== undefined) {
      var fieldTypeObj = i_moneyExpandedCaptureFieldMap.get("fieldTypeObj");
      if(fieldTypeObj !== undefined) {
        if(fieldTypeObj.moneyTF) {
          var captureMoneyFieldValue = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(i_captureMap, i_moneyExpandedCaptureFieldMap);
          return(i_partialMultiplier0to1 * captureMoneyFieldValue);
        }
      }
    }

    //selected capture value money field not valid returns a capture value of 0
    return(0);
  }

  compute_graph_num_captures_or_money_field_value_mask_plain_text(i_numCapturesTrueMoneyFieldFalse, i_numCapturesOrMoneyFieldValue) {
    //# captures (with either 2 decimals or 1)
    if(i_numCapturesTrueMoneyFieldFalse) {
      var numDecimals = ((i_numCapturesOrMoneyFieldValue < 1) ? (2) : (1));
      var numCapturesDecimal = JSFUNC.round_number_to_num_decimals_if_needed(i_numCapturesOrMoneyFieldValue, numDecimals);
      return(JSFUNC.num2str(numCapturesDecimal));
    }

    //money field
    return(JSFUNC.money_short(i_numCapturesOrMoneyFieldValue));
  }

  compute_time_graph_id_date_value_arrayOfObjs_from_captures_mapOfMaps_and_expanded_filters_arrayOfObjs_and_date_field_db_name(i_capturesMapOfMaps, i_expandedFiltersArrayOfObjs, i_dateFieldDbName, i_numCapturesTrueMoneyFieldFalse, i_moneyExpandedCaptureFieldMap) {
    var idDateValueArrayOfObjs = [];
    for(let [captureID, captureMap] of i_capturesMapOfMaps) {
      var partialMultiplier0to1 = CapturesMobx.capture_matches_all_filters_partialMultiplier0to1_or_false_from_capture_map_and_expanded_filters_arrayOfObjs(captureMap, i_expandedFiltersArrayOfObjs); //partial tcv or # captures added to total
      if(partialMultiplier0to1 !== false) {
        idDateValueArrayOfObjs.push({
          id: captureMap.get("id"),
          date: captureMap.get(i_dateFieldDbName),
          value: this.compute_graph_single_capture_num_captures_or_money_field_value_from_partial_multiplier(partialMultiplier0to1, captureMap, i_numCapturesTrueMoneyFieldFalse, i_moneyExpandedCaptureFieldMap)
        });
      }
    }
    return(idDateValueArrayOfObjs);
  }




  get c_graphsSelectNumGraphsPerRowFieldTypeObj() {
    const numGraphsPerRowArray = JSFUNC.array_fill_incrementing_x_to_y(1, 15);
    const swsOptionsObj = undefined;
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("# Graphs Per Row", numGraphsPerRowArray, false, numGraphsPerRowArray, swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }
  get c_graphsSelectGraphHeightsFieldTypeObj() {
    const graphHeightsArray = JSFUNC.concat_arrays_or_values_into_new_array(JSFUNC.array_fill_incrementing_x_to_y(8, 38, 2), JSFUNC.array_fill_incrementing_x_to_y(40, 100, 5));
    const swsOptionsObj = undefined;
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Graphs Height", graphHeightsArray, false, graphHeightsArray, swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_graphsSelectFilterPresetFieldTypeObj() {
    var userOrPublicFilterPresetsArrayOfObjs = [];
    for(let filterPresetMap of DatabaseMobx.o_tbl_f_filter_presets.values()) {
      if((filterPresetMap.get("public_01") === 1) || UserMobx.user_id_is_one_of_logged_in_user_per_email_multilogin_tf(filterPresetMap.get("user_id"))) {
        userOrPublicFilterPresetsArrayOfObjs.push({
          filterPresetID: filterPresetMap.get("id"),
          filterPresetName: filterPresetMap.get("name")
        });
      }
    }
    JSFUNC.sort_arrayOfObjs(userOrPublicFilterPresetsArrayOfObjs, "filterPresetName", true);

    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_arrayOfObjs_and_vd_column_names("Filter Preset", userOrPublicFilterPresetsArrayOfObjs, "filterPresetID", false, "filterPresetName", swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_graphsSelectFromAllFilterPresetsFieldTypeObj() {
    const valueDisplayArraysObjFilterPresets = DatabaseMobx.create_value_display_arrays_obj_from_mapOfMaps("Filter Preset", DatabaseMobx.o_tbl_f_filter_presets, "id", "name", "name", true, true);
    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_value_display_arrays_obj(valueDisplayArraysObjFilterPresets, swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }

  get c_selectCategoryCaptureFieldFieldTypeObj() {
    var categoryFieldsArrayOfObjs = [];
    for(let expandedCaptureMap of DatabaseMobx.c_tbl_captures_fields.values()) {
      var fieldTypeObj = expandedCaptureMap.get("fieldTypeObj");
      if(fieldTypeObj.selectTF || fieldTypeObj.sharedPercentTF) {
        categoryFieldsArrayOfObjs.push({
          fieldID: expandedCaptureMap.get("id"),
          fieldDisplayName: expandedCaptureMap.get("display_name")
        });
      }
    }
    JSFUNC.sort_arrayOfObjs(categoryFieldsArrayOfObjs, "fieldDisplayName", true);

    const swsOptionsObj = {hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_arrayOfObjs_and_vd_column_names("Capture Field for Categories", categoryFieldsArrayOfObjs, "fieldID", false, "fieldDisplayName", swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }
  get c_graphsSelectAllCategoriesTFFieldTypeObj() {
    const valueArray = [1, 0];
    const displayArray = ["Use all options in selected Field as Categories", "Choose a subset of the Field's options below"];
    const swsOptionsObj = undefined;
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("All Field Options/Choose Subset", valueArray, false, displayArray, swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }
  get c_graphsSelectMultiCategoriesOptionsFieldTypeObj() {
    const editingGraphDataObj = this.c_graphsEditingGraphDataObj;
    var optionIDsArray = [];
    var valuesAreStringsTF = false;
    var optionNamesArray = [];
    if(editingGraphDataObj !== undefined && editingGraphDataObj.graphObj !== undefined) {
      const captureFieldID = editingGraphDataObj.graphObj.categories_field_id;
      const expandedFieldMap = DatabaseMobx.c_tbl_captures_fields.get(captureFieldID);
      if(expandedFieldMap !== undefined) {
        const fieldTypeObj = expandedFieldMap.get("fieldTypeObj");
        if((fieldTypeObj.selectWithSearchDataObj !== undefined) && JSFUNC.is_array(fieldTypeObj.selectWithSearchDataObj.valueArray) && JSFUNC.is_array(fieldTypeObj.selectWithSearchDataObj.displayArray)) {
          optionIDsArray = fieldTypeObj.selectWithSearchDataObj.valueArray;
          valuesAreStringsTF = fieldTypeObj.selectWithSearchDataObj.valuesAreStringsTF;
          optionNamesArray = fieldTypeObj.selectWithSearchDataObj.displayArray;
        }
      }
    }

    const swsOptionsObj = {isMultiSelectTF:true, hasSearchTF:true, hasClearSelectionTF:true};
    const selectWithSearchDataObj = DatabaseMobx.create_sws_data_obj_from_value_array_and_display_array("Capture Field Option", optionIDsArray, valuesAreStringsTF, optionNamesArray, swsOptionsObj);
    return(DatabaseMobx.create_field_type_obj("select", selectWithSearchDataObj));
  }








  //financial projections
  get c_finProjObj() {
    const finprojMoneyFieldID = UserMobx.c_combinedUserObj.divexec_finproj_money_field_id;
    const finprojMoneyExpandedFieldMapOrUndefined = DatabaseMobx.c_tbl_captures_fields.get(finprojMoneyFieldID);
    const divexecFilteredCapturesObj = this.c_divexecFilteredCapturesObj;

    const filterName = divexecFilteredCapturesObj.filterName;
    const excelFilterName = divexecFilteredCapturesObj.excelFilterName; //name displayed in the exported xml excel sheet is more verbose for the manual divisions/stages filter
    const filteredCaptureMapsAndTcvMultipliersArrayOfObjs = divexecFilteredCapturesObj.filteredCaptureMapsAndTcvMultipliersArrayOfObjs;

    return(this.compute_finproj_obj(filterName, excelFilterName, filteredCaptureMapsAndTcvMultipliersArrayOfObjs, finprojMoneyExpandedFieldMapOrUndefined));
  }

  compute_finproj_obj(i_filterName, i_excelFilterName, i_filteredCaptureMapsAndTcvMultipliersArrayOfObjs, i_finprojMoneyExpandedFieldMapOrUndefined, i_manualLowestFyr=undefined, i_manualHighestFyr=undefined) {
    const c_fieldMapOfOpportunityName = DatabaseMobx.c_fieldMapOfOpportunityName;
    const c_fieldMapOfCaptureManagers = DatabaseMobx.c_fieldMapOfCaptureManagers;
    const c_fieldMapOfDivisionOwners = DatabaseMobx.c_fieldMapOfDivisionOwners;
    const c_fieldMapOfStage = DatabaseMobx.c_fieldMapOfStage;
    const c_fieldMapOfContractStartDate = DatabaseMobx.c_fieldMapOfContractStartDate;
    const c_fieldMapOfContractOverallValue = DatabaseMobx.c_fieldMapOfContractOverallValue;
    const c_fieldMapOfPeriodOfPerformance = DatabaseMobx.c_fieldMapOfPeriodOfPerformance;
    const c_fieldMapOfPwin = DatabaseMobx.c_fieldMapOfPwin;

    //get the names for the table column fields
    const captureManagersFieldDisplayName = c_fieldMapOfCaptureManagers.get("display_name");
    const divisionOwnersFieldDisplayName = c_fieldMapOfDivisionOwners.get("display_name");
    const stageFieldDisplayName = c_fieldMapOfStage.get("display_name");
    const contractStartDateFieldDisplayName = c_fieldMapOfContractStartDate.get("display_name");
    const periodOfPerformanceFieldDisplayName = c_fieldMapOfPeriodOfPerformance.get("display_name");
    const pwinFieldDisplayName = c_fieldMapOfPwin.get("display_name");

    //field map and info for selected finproj capture money field (if one is selected)
    var validCaptureMoneyFieldSelectedTF = false;
    var finprojMoneyIsPwinTF = false;
    var finprojMoneyExpandedFieldMap = c_fieldMapOfContractOverallValue;
    var finprojMoneyFieldDisplayName = "--No Capture Money Field Selected--";
    if(i_finprojMoneyExpandedFieldMapOrUndefined !== undefined) {
      validCaptureMoneyFieldSelectedTF = true;
      finprojMoneyIsPwinTF = (i_finprojMoneyExpandedFieldMapOrUndefined.get("rawPwinAdjustedContractOverallValueTF") || i_finprojMoneyExpandedFieldMapOrUndefined.get("rawPwinAdjustedContractRevenueValueTF") || i_finprojMoneyExpandedFieldMapOrUndefined.get("rawPwinAdjustedAllocatedRevenueValueTF") || i_finprojMoneyExpandedFieldMapOrUndefined.get("rawPwinAdjustedAllocatedNetValueTF"));
      finprojMoneyExpandedFieldMap = i_finprojMoneyExpandedFieldMapOrUndefined;
      finprojMoneyFieldDisplayName = i_finprojMoneyExpandedFieldMapOrUndefined.get("display_name");
    }

    //if pwin column will be included
    var fieldMapOfPwinOrUndefined = undefined;
    if(finprojMoneyIsPwinTF) {
      fieldMapOfPwinOrUndefined = c_fieldMapOfPwin;
    }

    //get the fields for the columns of the capture table
    var tableColumnFieldsArrayOfObjs = [{displayName:"Capture Name", flexBasis:"200em"}];
    tableColumnFieldsArrayOfObjs.push({displayName:captureManagersFieldDisplayName, flexBasis:"100em"});
    tableColumnFieldsArrayOfObjs.push({displayName:divisionOwnersFieldDisplayName, flexBasis:"100em"});
    tableColumnFieldsArrayOfObjs.push({displayName:stageFieldDisplayName, flexBasis:"100em"});
    tableColumnFieldsArrayOfObjs.push({displayName:contractStartDateFieldDisplayName, flexBasis:"100em"});
    tableColumnFieldsArrayOfObjs.push({displayName:finprojMoneyFieldDisplayName, flexBasis:"100em"});
    tableColumnFieldsArrayOfObjs.push({displayName:periodOfPerformanceFieldDisplayName, flexBasis:"50em"});
    if(finprojMoneyIsPwinTF) {
      tableColumnFieldsArrayOfObjs.push({displayName:pwinFieldDisplayName, flexBasis:"100em"});
    }
    tableColumnFieldsArrayOfObjs.push({displayName:"$ Value per Month", flexBasis:"100em"});

    //count the number of table column fields
    const numTableColumnFields = tableColumnFieldsArrayOfObjs.length;

    //determine the current fyr year and month based on today's date
    const nowJsDateObj = new Date();
    const nowYear = nowJsDateObj.getFullYear();
    const nowMonthNum0to11 = nowJsDateObj.getMonth();
    const nowFyrObj = DatabaseMobx.convert_year_month0to11_to_fyrYear_fyrMonth0to11_obj(nowYear, nowMonthNum0to11);
    const nowFyrYear = nowFyrObj.fyrYear;
    const nowFyrMonth0to11 = nowFyrObj.fyrMonth0to11;

    //first loop to copy capture fields, compute contract end date, determine earliest and latest dates
    var lowestFyr = undefined;
    var highestFyr = undefined;
    var finProjCapturesArrayOfObjs = [];
    for(let catureMapAndTcvMultiplierObj of  i_filteredCaptureMapsAndTcvMultipliersArrayOfObjs) {
      var captureMap = catureMapAndTcvMultiplierObj.captureMap;
      var partialMultiplier0to1 = catureMapAndTcvMultiplierObj.partialMultiplier0to1;

      //get info about this capture
      var captureID = captureMap.get("id");
      var captureFullName = DatabaseMobx.capture_name_plaintext_from_capture_map(captureMap);

      var captureStageIsActiveTF = false;
      var captureStageIsClosedWonTF = false;
      if(finprojMoneyIsPwinTF) { //only need to know stage active/closed in the PWin column
        captureStageIsActiveTF = DatabaseMobx.capture_stage_is_active_tf_from_capture_map(captureMap);
        if(!captureStageIsActiveTF) {
          captureStageIsClosedWonTF = DatabaseMobx.capture_stage_is_closed_won_tf_from_capture_map(captureMap);
        }
      }

      var contractStartDateValueMaskSortIfoCanEditObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(captureMap, c_fieldMapOfContractStartDate);
      var csdValueRaw = contractStartDateValueMaskSortIfoCanEditObj.valueRaw;
      var csdSort = contractStartDateValueMaskSortIfoCanEditObj.valueSort;
      var csdValidTF = (contractStartDateValueMaskSortIfoCanEditObj.isFilledOutTF && (csdValueRaw > "1950-01-01") && (csdValueRaw < "3001-01-01"));

      var popValueRaw = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(captureMap, c_fieldMapOfPeriodOfPerformance);
      var popValidTF = JSFUNC.is_number_not_nan_gt_0(popValueRaw);

      //selected finproj money field (or COV if none selected)
      var captureMoneyFieldValueRaw = 0;
      var capturePartialMoneyFieldValueRaw = 0;
      if(validCaptureMoneyFieldSelectedTF) {
        captureMoneyFieldValueRaw = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(captureMap, i_finprojMoneyExpandedFieldMapOrUndefined);
        capturePartialMoneyFieldValueRaw = ((partialMultiplier0to1 < 1) ? (captureMoneyFieldValueRaw * partialMultiplier0to1) : (captureMoneyFieldValueRaw)); //compute the partial TCV from the provided expanded filters in the filter preset
      }

      //PWin special masking
      var capturePWinValueRawOverwrite = undefined;
      var capturePWinValueMaskPlainTextOverwrite = undefined;
      if(finprojMoneyIsPwinTF && !captureStageIsActiveTF) { //active stages use the undefined values above to compute the real capture PWin value, closed stages get the special overwrite for finproj presentation
        capturePWinValueRawOverwrite = ((captureStageIsClosedWonTF) ? (100) : (0));
        capturePWinValueMaskPlainTextOverwrite = ((captureStageIsClosedWonTF) ? ("--Closed " + stageFieldDisplayName + " (Won)--") : ("--Closed " + stageFieldDisplayName + " (Lost/No Bid/Cancelled)--"));
      }

      //valuePerMonth special masking initialization
      var captureValuePerMonthRawOverwrite = undefined;
      var captureValuePerMonthPlainTextOverwrite = undefined;

      //initialize date calculations collected in output obj
      var contractEndDate = undefined; //flag that the contract start date and pop were both valid if this is not undefined
      var startFyrYear = undefined;
      var startFyrMonthNum0to11 = undefined;
      var endFyrYear = undefined;
      var endFyrMonthNum0to11 = undefined;
      var valuePerMonth = undefined;
      var firstMonthValue = undefined;
      var lastMonthValue = undefined;
      if(!(csdValidTF && popValidTF)) { //either CSD or PoP is invalid
        captureValuePerMonthRawOverwrite = 0;
        captureValuePerMonthPlainTextOverwrite = "--" + ((!csdValidTF) ? (contractStartDateFieldDisplayName) : (periodOfPerformanceFieldDisplayName)) + " is invalid--";
      }
      else { //both CSD and PoP are valid
        var startJsDateObj = JSFUNC.convert_mysqldate_to_jsdateobj(csdValueRaw);
        var startYear = startJsDateObj.getFullYear();
        var startMonthNum0to11 = startJsDateObj.getMonth();
        var startDate1to31 = startJsDateObj.getDate();

        //compute the contract end date by adding the number of pop months to the start date, then moving back one day
        startJsDateObj.setMonth(startMonthNum0to11 + popValueRaw);
        startJsDateObj.setDate(startDate1to31 - 1);
        var endYear = startJsDateObj.getFullYear();
        var endMonthNum0to11 = startJsDateObj.getMonth();
        var endDate1to31 = startJsDateObj.getDate();
        contractEndDate = endYear + "-" + (endMonthNum0to11 + 1) + "-" + endDate1to31;

        //calculate the COV per month (COV/pop)
        valuePerMonth = (capturePartialMoneyFieldValueRaw / popValueRaw); //ok for popValueRaw to be in denominator as it was checked above to be a number and >0
        captureValuePerMonthRawOverwrite = valuePerMonth; //specify the raw overwrite, but not the plaintext to mask this value with the COV field type styling

        //calculate the COV in the first month (could be a fraction of the valuePerMonth if the start date is not on the first)
        if(startDate1to31 === 1) {
          firstMonthValue = valuePerMonth;
          lastMonthValue = valuePerMonth;
        }
        else {
          var thirtyMinusDate = (30 - startDate1to31);
          if(thirtyMinusDate <= 0) {
            thirtyMinusDate = 1;
          }
          firstMonthValue = ((thirtyMinusDate / 30) * valuePerMonth);
          lastMonthValue = (valuePerMonth - firstMonthValue);
        }

        //determine the lowest/highest fyr year and month
        var startFyrObj = DatabaseMobx.convert_year_month0to11_to_fyrYear_fyrMonth0to11_obj(startYear, startMonthNum0to11);
        startFyrYear = startFyrObj.fyrYear;
        startFyrMonthNum0to11 = startFyrObj.fyrMonth0to11;

        if((lowestFyr === undefined) || (startFyrYear < lowestFyr)) {
          lowestFyr = startFyrYear;
        }

        var endFyrObj = DatabaseMobx.convert_year_month0to11_to_fyrYear_fyrMonth0to11_obj(endYear, endMonthNum0to11);
        endFyrYear = endFyrObj.fyrYear;
        endFyrMonthNum0to11 = endFyrObj.fyrMonth0to11;

        if((highestFyr === undefined) || (endFyrYear > highestFyr)) {
          highestFyr = endFyrYear;
        }
      }

      var tableColumnsExpandedCaptureFieldMapOrUndefinedArray = [c_fieldMapOfOpportunityName, c_fieldMapOfCaptureManagers, c_fieldMapOfDivisionOwners, c_fieldMapOfStage, c_fieldMapOfContractStartDate, finprojMoneyExpandedFieldMap, c_fieldMapOfPeriodOfPerformance, fieldMapOfPwinOrUndefined, c_fieldMapOfContractOverallValue];
      var tableColumnsValueRawOverwriteOrUndefinedArray = [captureFullName, undefined, undefined, undefined, undefined, captureMoneyFieldValueRaw, undefined, capturePWinValueRawOverwrite, captureValuePerMonthRawOverwrite];
      var tableColumnsValueMaskPlainTextOverwriteOrUndefinedArray = [undefined, undefined, undefined, undefined, undefined, undefined, undefined, capturePWinValueMaskPlainTextOverwrite, captureValuePerMonthPlainTextOverwrite];
      var tableColumnsValueMaskPlainTextOverwriteRedTextTFArray = [undefined, undefined, undefined, undefined, undefined, undefined, undefined, false, true];

      var tableColumnCaptureFieldValueArrayOfObjs = [];
      for(let c = 0; c < tableColumnsExpandedCaptureFieldMapOrUndefinedArray.length; c++) {
        var expandedCaptureFieldMapOrUndefined = tableColumnsExpandedCaptureFieldMapOrUndefinedArray[c];
        var valueRawOverwriteOrUndefined = tableColumnsValueRawOverwriteOrUndefinedArray[c];
        var valueMaskPlainTextOverwriteOrUndefined = tableColumnsValueMaskPlainTextOverwriteOrUndefinedArray[c];
        var valueMaskPlainTextOverwriteRedTextTF = tableColumnsValueMaskPlainTextOverwriteRedTextTFArray[c];

        if(expandedCaptureFieldMapOrUndefined !== undefined) { //if the fieldMap is undefined, completely skip this column (happens for PWin)
          var fieldTypeObj = expandedCaptureFieldMapOrUndefined.get("fieldTypeObj");

          var moneyTF = false;
          var percentTF = false;
          var valueDisplayIsNumericTF = false;
          if(fieldTypeObj !== undefined) {
            moneyTF = fieldTypeObj.moneyTF;
            percentTF = fieldTypeObj.percentTF;
            valueDisplayIsNumericTF = fieldTypeObj.valueDisplayIsNumericTF;
          }

          var valueMaskPlainText = undefined;
          var valueMaskCsvXmlPlainText = undefined;
          var valueMask = undefined;
          if(valueMaskPlainTextOverwriteOrUndefined !== undefined) { //if a mask overwrite is provided, no need to fetch (the valueMask is assumed to be a not filled out font version of the plaintext)
            valueMaskPlainText = valueMaskPlainTextOverwriteOrUndefined;
            valueMaskCsvXmlPlainText = valueMaskPlainTextOverwriteOrUndefined;
            if(moneyTF || percentTF || valueDisplayIsNumericTF) {
              valueMaskCsvXmlPlainText = 0; //fix xml value to be the raw value (or 0) for invalid values in numeric overwrite columns
              if(valueRawOverwriteOrUndefined !== undefined) {
                valueMaskSortIfoCanEditObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(valueRawOverwriteOrUndefined, fieldTypeObj);
                valueMaskCsvXmlPlainText = valueMaskSortIfoCanEditObj.valueMaskCsvXmlPlainText;
              }
            }

            if(valueMaskPlainTextOverwriteRedTextTF) {
              valueMask = DatabaseMobx.error_font_html(valueMaskPlainText);
            }
            else {
              valueMask = DatabaseMobx.not_filled_out_font_html(valueMaskPlainText);
            }
          }
          else {
            var valueMaskSortIfoCanEditObj = undefined;
            if(valueRawOverwriteOrUndefined !== undefined) { //if a raw overwrite is provided, get the masking for that provided raw value
              valueMaskSortIfoCanEditObj = DatabaseMobx.value_mask_sort_ifo_obj_from_value_raw_and_field_type_obj(valueRawOverwriteOrUndefined, fieldTypeObj);
            }
            else { //normal fetch of raw value and masking from capture/field
              valueMaskSortIfoCanEditObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(captureMap, expandedCaptureFieldMapOrUndefined);
            }
            valueMaskPlainText = valueMaskSortIfoCanEditObj.valueMaskPlainText;
            valueMaskCsvXmlPlainText = valueMaskSortIfoCanEditObj.valueMaskCsvXmlPlainText;
            valueMask = valueMaskSortIfoCanEditObj.valueMask;
          }

          tableColumnCaptureFieldValueArrayOfObjs.push({
            valueMaskPlainText: valueMaskPlainText,
            valueMaskCsvXmlPlainText: valueMaskCsvXmlPlainText, //used in building finproj xml excel data
            valueMask: valueMask,
            moneyTF: moneyTF, //used in building finproj xml excel cell style type
            percentTF: percentTF, //used in building finproj xml excel cell style type
            valueDisplayIsNumericTF: valueDisplayIsNumericTF, //used in building finproj xml excel cell style type
            cstCellAlignClass: expandedCaptureFieldMapOrUndefined.get("cstCellAlignClass"),
          });
        }
      }

      //push the final finProjCaptureObj into the accumulating array
      finProjCapturesArrayOfObjs.push({
        id: captureID,
        csdSort: csdSort,
        contractEndDate: contractEndDate,
        startFyrYear: startFyrYear,
        startFyrMonthNum0to11: startFyrMonthNum0to11,
        startDate1to31: startDate1to31,
        endFyrYear: endFyrYear,
        endFyrMonthNum0to11: endFyrMonthNum0to11,
        valuePerMonth: valuePerMonth,
        firstMonthValue: firstMonthValue,
        lastMonthValue: lastMonthValue,
        tableColumnCaptureFieldValueArrayOfObjs: tableColumnCaptureFieldValueArrayOfObjs
      });
    }

    //if a manual lowest/highest fyr was provided as input, use those values
    if(JSFUNC.is_number(i_manualLowestFyr) && JSFUNC.is_number(i_manualHighestFyr) && (i_manualHighestFyr >= i_manualLowestFyr)) {
      lowestFyr = i_manualLowestFyr;
      highestFyr = i_manualHighestFyr;
    }

    //if the lowest/highest fyr values are still undefined, that means there are 0 captures that have a valid contract start date and pop for this capture filter, set the low/high fyr as the current year
    if((lowestFyr === undefined) || (highestFyr === undefined)) {
      lowestFyr = nowFyrYear;
      highestFyr = nowFyrYear;
    }

    //create the data matrix fyr row labels array
    var fyrArray = [];
    for(let fyr = lowestFyr; fyr <= highestFyr; fyr++) {
      fyrArray.push(fyr);
    }
    const numYears = fyrArray.length;

    //create the data matrix month column labels array (accounting for the shifted FYR start month)
    var fyrMthLabelsArray = [];
    for(let m = DatabaseMobx.c_companyFyrStartMonth1to12; m < (DatabaseMobx.c_companyFyrStartMonth1to12 + 12); m++) { //Jan would be 1-12, Mar would be 3-14
      var monthIndex0to11 = undefined;
      if(m > 12) {
        monthIndex0to11 = (m - 13);
      }
      else {
        monthIndex0to11 = (m - 1);
      }
      fyrMthLabelsArray.push(JSFUNC.date_mth_from_index(monthIndex0to11));
    }

    //initialize a data matrix with rows for fyr from lowest to highest (indexed 0 to Y-1), and 12 columns for each month (indexed 0 to 11)
    var moneyValueMatrix = JSFUNC.zeros_arrayOfArrays(numYears, 12); //initialize each fyr/month cell to $0

    //loop through every finProj capture obj and add their split tcv across the data matrix fyr/months
    for(let finProjCaptureObj of finProjCapturesArrayOfObjs) {
      //initialize a TCV per FYR matrix for each capture
      var captureMoneyValuePerFyrArray = JSFUNC.array_fill(numYears, 0);

      if(finProjCaptureObj.contractEndDate !== undefined) { //only compute this capture if it has a valid contract end date
        var endReachedTF = false; //turns true when the last data for the contract end date has been filled in, no other future fyr or months need to be checked after this point and while loops stop
        var y = 0;
        while(!endReachedTF && y < numYears) {
          var fyr = fyrArray[y];
          var m = 0;
          while(!endReachedTF && m < 12) {
            if(fyr >= finProjCaptureObj.endFyrYear && m >= finProjCaptureObj.endFyrMonthNum0to11) { //end condition of last month of last fyr
              moneyValueMatrix[y][m] += finProjCaptureObj.lastMonthValue;
              captureMoneyValuePerFyrArray[y] += finProjCaptureObj.lastMonthValue;
              endReachedTF = true;
            }
            else if(fyr === finProjCaptureObj.startFyrYear) { //first fyr
              if(m === finProjCaptureObj.startFyrMonthNum0to11) { //first month of first fyr, could be split based on if start date is 1st of month or later in the month
                moneyValueMatrix[y][m] += finProjCaptureObj.firstMonthValue;
                captureMoneyValuePerFyrArray[y] += finProjCaptureObj.firstMonthValue;
              }
              else if(m > finProjCaptureObj.startFyrMonthNum0to11) { //other months within the first year
                moneyValueMatrix[y][m] += finProjCaptureObj.valuePerMonth;
                captureMoneyValuePerFyrArray[y] += finProjCaptureObj.valuePerMonth;
              }
            }
            else if(fyr > finProjCaptureObj.startFyrYear) {
              moneyValueMatrix[y][m] += finProjCaptureObj.valuePerMonth;
              captureMoneyValuePerFyrArray[y] += finProjCaptureObj.valuePerMonth;
            }
            m++;
          }
          y++;
        }
      }

      //place the calculated TCV/FYR array for this capture with the captureObj
      finProjCaptureObj.captureMoneyValuePerFyrArray = captureMoneyValuePerFyrArray;
    }

    //compute a total TCV per fyr array for the last column of the finProj table
    var totalMoneyValuePerFyrArray = [];
    for(let y = 0; y < numYears; y++) {
      totalMoneyValuePerFyrArray[y] = 0; //initialize the fyr total to 0 to sum the months together in this fyr
      for(let m = 0; m < 12; m++) {
        totalMoneyValuePerFyrArray[y] += moneyValueMatrix[y][m];
      }
    }

    //compute a total TCV for all fyr
    var totalTcv = 0;
    for(let y = 0; y < numYears; y++) {
      totalTcv += totalMoneyValuePerFyrArray[y];
    }

    //sort the captures table by the contract start date
    JSFUNC.sort_arrayOfObjs(finProjCapturesArrayOfObjs, "csdSort", true);
    const numCaptures = finProjCapturesArrayOfObjs.length;

    //return the finProjObj
    return({
      fyrArray: fyrArray,
      numYears: numYears,
      fyrMthLabelsArray: fyrMthLabelsArray,
      moneyValueMatrix: moneyValueMatrix,
      totalMoneyValuePerFyrArray: totalMoneyValuePerFyrArray,
      totalTcv: totalTcv,
      finProjCapturesArrayOfObjs: finProjCapturesArrayOfObjs,
      numCaptures: numCaptures,
      filterName: i_filterName,
      excelFilterName: i_excelFilterName,
      includePwinTF: finprojMoneyIsPwinTF,
      tableColumnFieldsArrayOfObjs: tableColumnFieldsArrayOfObjs,
      numTableColumnFields: numTableColumnFields
    });
  }

  get c_finProjGraphStartEndDatesObj() {
    const finProjObj = this.c_finProjObj;

    const fyrArray = finProjObj.fyrArray;
    const numYears = finProjObj.numYears;

    const todayJsDateObj = new Date();
    const currentYear = todayJsDateObj.getFullYear();
    var startDate = currentYear + "-01-01";
    var endDate = currentYear + "-12-31";
    if(numYears > 0) {
      startDate = fyrArray[0] + "-01-01";
      endDate = fyrArray[numYears - 1] + "-12-31";
    }

    return({
      startDate: startDate,
      endDate: endDate
    });
  }

  get c_finProjYearlyGraphObj() {
    const finProjObj = this.c_finProjObj;

    const fyrArray = finProjObj.fyrArray;
    const numYears = finProjObj.numYears;
    const totalMoneyValuePerFyrArray = finProjObj.totalMoneyValuePerFyrArray;

    var idDateValueArrayOfObjs = [];
    for(let y = 0; y < numYears; y++) {
      var fyr = fyrArray[y];
      var totalMoneyValue = totalMoneyValuePerFyrArray[y];
      idDateValueArrayOfObjs.push({
        id: fyr,
        date: fyr + "-01-01",
        value: totalMoneyValue
      });
    }

    const categoryObj = {
      idDateValueArrayOfObjs: idDateValueArrayOfObjs,
      color: "005da3",
      label: "Financial Projections"
    };
    const categoriesArrayOfObjs = [categoryObj];

    return({
      categoriesArrayOfObjs: categoriesArrayOfObjs
    });
  }

  get c_finProjMonthlyGraphObj() {
    const finProjObj = this.c_finProjObj;

    const fyrArray = finProjObj.fyrArray;
    const numYears = finProjObj.numYears;
    const moneyValueMatrix = finProjObj.moneyValueMatrix;

    var idDateValueArrayOfObjs = [];
    for(let y = 0; y < numYears; y++) {
      var fyr = fyrArray[y];
      var moneyValueYearArray = moneyValueMatrix[y];
      for(let m = 0; m < 12; m++) {
        idDateValueArrayOfObjs.push({
          id: fyr,
          date: fyr + "-" + JSFUNC.zero_pad_integer_from_left(m + 1, 2) + "-01",
          value: moneyValueYearArray[m]
        });
      }
    }

    const categoryObj = {
      idDateValueArrayOfObjs: idDateValueArrayOfObjs,
      color: "005da3",
      label: "Financial Projections"
    };
    const categoriesArrayOfObjs = [categoryObj];

    return({
      categoriesArrayOfObjs: categoriesArrayOfObjs
    });
  }


  generate_finproj_excel_xml_string(i_finProjObj) {
    const fyrArray = i_finProjObj.fyrArray;
    const numYears = i_finProjObj.numYears;
    const fyrMthLabelsArray = i_finProjObj.fyrMthLabelsArray;
    const moneyValueMatrix = i_finProjObj.moneyValueMatrix;
    const totalMoneyValuePerFyrArray = i_finProjObj.totalMoneyValuePerFyrArray;
    const totalTcv = i_finProjObj.totalTcv;
    const finProjCapturesArrayOfObjs = i_finProjObj.finProjCapturesArrayOfObjs;
    const numCaptures = i_finProjObj.numCaptures;
    const filterName = i_finProjObj.filterName;
    const excelFilterName = i_finProjObj.excelFilterName;
    const includePwinTF = i_finProjObj.includePwinTF;
    const tableColumnFieldsArrayOfObjs = i_finProjObj.tableColumnFieldsArrayOfObjs;
    const numTableColumnFields = i_finProjObj.numTableColumnFields;

    const includePwinYesNoString = ((includePwinTF) ? ("Yes") : ("No"));

  	var xmlOut = '<?xml version="1.0"?><?mso-application progid="Excel.Sheet"?>';
  	xmlOut += '<Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet" xmlns:html="http://www.w3.org/TR/REC-html40">';
  	xmlOut += '<DocumentProperties xmlns="urn:schemas-microsoft-com:office:office"><Author>CaptureExec</Author><LastAuthor>CaptureExec</LastAuthor><Created>2017-09-30T10:20:40Z</Created><LastSaved>2017-09-30T10:58:22Z</LastSaved><Version>12.00</Version></DocumentProperties>';
  	xmlOut += '<ExcelWorkbook xmlns="urn:schemas-microsoft-com:office:excel"><WindowHeight>7995</WindowHeight><WindowWidth>15315</WindowWidth><WindowTopX>0</WindowTopX><WindowTopY>75</WindowTopY><ProtectStructure>False</ProtectStructure><ProtectWindows>False</ProtectWindows></ExcelWorkbook>';

  	xmlOut += '<Styles>';
  	xmlOut += '<Style ss:ID="Default" ss:Name="Normal"><Alignment ss:Vertical="Bottom"/><Borders/><Font ss:FontName="Calibri" x:Family="Swiss" ss:Size="11" ss:Color="#000000"/><Interior/><NumberFormat/><Protection/></Style>';
  	xmlOut += '<Style ss:ID="s18" ss:Name="Currency"><NumberFormat ss:Format="_(&quot;$&quot;* #,##0.00_);_(&quot;$&quot;* \(#,##0.00\);_(&quot;$&quot;* &quot;-&quot;??_);_(@_)"/></Style>';
  	xmlOut += '<Style ss:ID="s20" ss:Name="Percent"><NumberFormat ss:Format="0%"/></Style>';
  	xmlOut += '<Style ss:ID="s62"><Alignment ss:Horizontal="Center" ss:Vertical="Bottom"/><Font ss:FontName="Calibri" x:Family="Swiss" ss:Size="11" ss:Color="#000000" ss:Bold="1"/></Style>';
  	xmlOut += '<Style ss:ID="s65"><Alignment ss:Horizontal="Left" ss:Vertical="Bottom"/></Style>';
  	xmlOut += '<Style ss:ID="s66"><Font ss:FontName="Calibri" x:Family="Swiss" ss:Size="11" ss:Color="#000000" ss:Bold="1"/></Style>';
  	xmlOut += '<Style ss:ID="s68" ss:Parent="s18"><Font ss:FontName="Calibri" x:Family="Swiss" ss:Size="11" ss:Color="#000000"/><NumberFormat ss:Format="&quot;$&quot;#,##0"/></Style>';
  	xmlOut += '<Style ss:ID="s69"><Font ss:FontName="Calibri" x:Family="Swiss" ss:Size="11" ss:Color="#000000" ss:Bold="1"/><NumberFormat ss:Format="&quot;$&quot;#,##0"/></Style>';
  	xmlOut += '<Style ss:ID="s71" ss:Parent="s20"><Font ss:FontName="Calibri" x:Family="Swiss" ss:Size="11" ss:Color="#000000"/></Style>';
  	xmlOut += '<Style ss:ID="s72"><NumberFormat ss:Format="Short Date"/></Style>';
  	xmlOut += '</Styles>';

  	xmlOut += '<Worksheet ss:Name="Summary">';
  	xmlOut += '<Table ss:ExpandedColumnCount="14" ss:ExpandedRowCount="' + (11 + numYears) + '" x:FullColumns="1" x:FullRows="1" ss:DefaultRowHeight="15">';
  	xmlOut += '<Column ss:StyleID="s62" ss:AutoFitWidth="0" ss:Width="35.25"/>';
  	xmlOut += '<Column ss:AutoFitWidth="0" ss:Width="100" ss:Span="11"/>';
  	xmlOut += '<Column ss:Index="14" ss:AutoFitWidth="0" ss:Width="150"/>';
  	xmlOut += '<Row ss:AutoFitHeight="0"><Cell ss:MergeAcross="13"><Data ss:Type="String">CaptureExec Financial Projections</Data></Cell></Row>';
  	xmlOut += '<Row ss:AutoFitHeight="0"><Cell ss:MergeAcross="13" ss:StyleID="s65"><Data ss:Type="String">$ Value Field Used: ' + tableColumnFieldsArrayOfObjs[5].displayName + '</Data></Cell></Row>';
  	xmlOut += '<Row ss:AutoFitHeight="0"><Cell ss:MergeAcross="13" ss:StyleID="s65"><Data ss:Type="String">Capture Filter: ' + JSFUNC.htmlspecialchars(excelFilterName) + '</Data></Cell></Row>';
  	xmlOut += '<Row ss:AutoFitHeight="0"><Cell ss:MergeAcross="13" ss:StyleID="s65"><Data ss:Type="String">Multiply PWin into TCV: ' + includePwinYesNoString + '</Data></Cell></Row>';
  	xmlOut += '<Row ss:AutoFitHeight="0" ss:StyleID="s66">';
  	xmlOut += '<Cell ss:StyleID="s62"/>';
  	xmlOut += '<Cell ss:MergeAcross="2" ss:StyleID="s62"><Data ss:Type="String">Q1</Data></Cell>';
  	xmlOut += '<Cell ss:MergeAcross="2" ss:StyleID="s62"><Data ss:Type="String">Q2</Data></Cell>';
  	xmlOut += '<Cell ss:MergeAcross="2" ss:StyleID="s62"><Data ss:Type="String">Q3</Data></Cell>';
  	xmlOut += '<Cell ss:MergeAcross="2" ss:StyleID="s62"><Data ss:Type="String">Q4</Data></Cell>';
  	xmlOut += '</Row>';
  	xmlOut += '<Row ss:AutoFitHeight="0" ss:StyleID="s62">';
  	xmlOut += '<Cell><Data ss:Type="String">FYR</Data></Cell>';
    for(let m = 0; m < 12; m++) {
      xmlOut += '<Cell><Data ss:Type="String">' + fyrMthLabelsArray[m] + '</Data></Cell>';
    }
  	xmlOut += '<Cell><Data ss:Type="String">Total</Data></Cell>';
  	xmlOut += '</Row>';

  	//summary data rows
  	for(let y = 0; y < numYears; y++) {
  		xmlOut += '<Row ss:AutoFitHeight="0">';
  		xmlOut += '<Cell><Data ss:Type="Number">' + fyrArray[y] + '</Data></Cell>';
      for(let m = 0; m < 12; m++) {
        xmlOut += '<Cell ss:StyleID="s68"><Data ss:Type="Number">' + moneyValueMatrix[y][m] + '</Data></Cell>';
      }
    	xmlOut += '<Cell ss:StyleID="s68"><Data ss:Type="Number">' + totalMoneyValuePerFyrArray[y] + '</Data></Cell>';
  		xmlOut += '</Row>';
  	}

  	//summary total
  	xmlOut += '<Row ss:AutoFitHeight="0"><Cell ss:MergeAcross="12"/><Cell ss:StyleID="s69"><Data ss:Type="Number">' + totalTcv + '</Data></Cell></Row>';

  	//summary end of worksheet
  	xmlOut += '</Table>';
  	xmlOut += '<WorksheetOptions xmlns="urn:schemas-microsoft-com:office:excel">';
  	xmlOut += '<PageSetup><Header x:Margin="0.3"/><Footer x:Margin="0.3"/><PageMargins x:Bottom="0.75" x:Left="0.7" x:Right="0.7" x:Top="0.75"/></PageSetup>';
  	xmlOut += '<Unsynced/>';
  	xmlOut += '<Print><ValidPrinterInfo/><HorizontalResolution>-3</HorizontalResolution><VerticalResolution>0</VerticalResolution></Print>';
  	xmlOut += '<Selected/>';
  	xmlOut += '<Panes><Pane><Number>3</Number><ActiveRow>6</ActiveRow></Pane></Panes>';
  	xmlOut += '<ProtectObjects>False</ProtectObjects>';
  	xmlOut += '<ProtectScenarios>False</ProtectScenarios>';
  	xmlOut += '</WorksheetOptions>';
  	xmlOut += '</Worksheet>';

  	//capture detail worksheet
  	xmlOut += '<Worksheet ss:Name="Capture Detail">';
  	xmlOut += '<Table ss:ExpandedColumnCount="' + (numTableColumnFields + numYears) + '" ss:ExpandedRowCount="' + (2 + numCaptures) + '" x:FullColumns="1" x:FullRows="1" ss:DefaultRowHeight="15">';
    for(let f = 0; f < numTableColumnFields; f++) {
      xmlOut += '<Column ss:AutoFitWidth="0" ss:Width="' + ((f === 0) ? (200) : (125)) + '"/>';
    }
  	for(let fyr of fyrArray) {
  		xmlOut += '<Column ss:AutoFitWidth="0" ss:Width="100"/>';
  	}

  	xmlOut += '<Row ss:AutoFitHeight="0">';
  	xmlOut += '<Cell ss:MergeAcross="' + (numTableColumnFields - 1) + '" ss:StyleID="s62"><Data ss:Type="String">Capture Info</Data></Cell>';
  	xmlOut += '<Cell' + ((numYears > 1) ? (' ss:MergeAcross="' + (numYears - 1) + '"') : ('')) + ' ss:StyleID="s62"><Data ss:Type="String">TCV Contribution Per Year</Data></Cell>';
  	xmlOut += '</Row>';

  	xmlOut += '<Row ss:AutoFitHeight="0" ss:StyleID="s62">';
    for(let tableColumnFieldObj of tableColumnFieldsArrayOfObjs) {
      xmlOut += '<Cell><Data ss:Type="String">' + JSFUNC.htmlspecialchars(tableColumnFieldObj.displayName) + '</Data></Cell>';
    }
  	for(let fyr of fyrArray) {
  		xmlOut += '<Cell><Data ss:Type="Number">' + fyr + '</Data></Cell>';
  	}
  	xmlOut += '</Row>';

  	for(let finProjCaptureObj of finProjCapturesArrayOfObjs) {
			xmlOut += '<Row ss:AutoFitHeight="0">';
      for(let tableColumnCaptureFieldValueObj of finProjCaptureObj.tableColumnCaptureFieldValueArrayOfObjs) {
        if(tableColumnCaptureFieldValueObj.moneyTF) {
          xmlOut += '<Cell ss:StyleID="s68"><Data ss:Type="Number">' + tableColumnCaptureFieldValueObj.valueMaskCsvXmlPlainText + '</Data></Cell>';
        }
        else if(tableColumnCaptureFieldValueObj.percentTF) {
          xmlOut += '<Cell ss:StyleID="s71"><Data ss:Type="Number">' + tableColumnCaptureFieldValueObj.valueMaskCsvXmlPlainText + '</Data></Cell>';
        }
        else if(tableColumnCaptureFieldValueObj.valueDisplayIsNumericTF) {
          xmlOut += '<Cell><Data ss:Type="Number">' + tableColumnCaptureFieldValueObj.valueMaskCsvXmlPlainText + '</Data></Cell>';
        }
        else {
          xmlOut += '<Cell><Data ss:Type="String">' + JSFUNC.htmlspecialchars(tableColumnCaptureFieldValueObj.valueMaskCsvXmlPlainText) + '</Data></Cell>';
        }
      }

			for(let captureCovPerFyr of finProjCaptureObj.captureMoneyValuePerFyrArray) {
				xmlOut += '<Cell ss:StyleID="s68"><Data ss:Type="Number">' + JSFUNC.htmlspecialchars(captureCovPerFyr) + '</Data></Cell>';
			}
			xmlOut += '</Row>';
  	}

  	xmlOut += '</Table>';
  	xmlOut += '<WorksheetOptions xmlns="urn:schemas-microsoft-com:office:excel">';
  	xmlOut += '<PageSetup><Header x:Margin="0.3"/><Footer x:Margin="0.3"/><PageMargins x:Bottom="0.75" x:Left="0.7" x:Right="0.7" x:Top="0.75"/></PageSetup>';
  	xmlOut += '<Unsynced/>';
  	xmlOut += '<Panes><Pane><Number>3</Number><ActiveRow>9</ActiveRow><ActiveCol>2</ActiveCol></Pane></Panes>';
  	xmlOut += '<ProtectObjects>False</ProtectObjects><ProtectScenarios>False</ProtectScenarios>';
  	xmlOut += '</WorksheetOptions>';
  	xmlOut += '</Worksheet>';
  	xmlOut += '</Workbook>';

  	return(xmlOut);
  }






  //hot bits
  get c_hotBitsObj() {
    const o_nowDate = CaptureExecMobx.o_nowDate;
    const c_allDivisionIDsArray = DatabaseMobx.c_allDivisionIDsArray;
    const c_fieldMapOfAddedDate = DatabaseMobx.c_fieldMapOfAddedDate;
    const c_fieldMapOfLastChangedDate = DatabaseMobx.c_fieldMapOfLastChangedDate;
    const c_fieldMapOfLastStageDate = DatabaseMobx.c_fieldMapOfLastStageDate;
    const c_fieldMapOfLastProgressDate = DatabaseMobx.c_fieldMapOfLastProgressDate;
    const c_fieldMapOfLastPWinDate = DatabaseMobx.c_fieldMapOfLastPWinDate;
    const c_fieldMapOfLastRFPDate = DatabaseMobx.c_fieldMapOfLastRFPDate;
    const c_combinedUserObj = UserMobx.c_combinedUserObj;

    const userDivexecFilterDivisionIDsArray = c_combinedUserObj.divexecFilterDivisionIDsArray;
    const userDivexecHotBitsCalendarTF = c_combinedUserObj.divexecHotBitsCalendarTF;
    const userDivexecHotBitsFixedTF= c_combinedUserObj.divexecHotBitsFixedTF;

    const zeroDivisionIDsSelectedTF = (userDivexecFilterDivisionIDsArray.length === 0);
    var allDivisionIDsSelectedTF = false;
    if(!zeroDivisionIDsSelectedTF) {
      allDivisionIDsSelectedTF = JSFUNC.all_of_array1_is_in_array2(c_allDivisionIDsArray, userDivexecFilterDivisionIDsArray);
    }

    const expandedCaptureDateFieldsArrayOfMaps = [c_fieldMapOfAddedDate, c_fieldMapOfLastChangedDate, c_fieldMapOfLastStageDate, c_fieldMapOfLastProgressDate, c_fieldMapOfLastPWinDate, c_fieldMapOfLastRFPDate];
    const fieldLabelsArray = ["Newly Added Captures", "Captures Recently Updated", "Recent Stage Changes", "Shaping Progress Updated", "PWin Updated", "Recently Changed RFP Date"];
    const fieldActionLabelsArray = ["Created", "Edited", "Stage Changed", "Question(s) Answered", "PWin Changed", "RFP Date Changed"];
    const numCaptureDateFields = expandedCaptureDateFieldsArrayOfMaps.length;

    var dateCategoriesArrayOfObjs = [];

    dateCategoriesArrayOfObjs.push({
      name: "today",
      actionName: "Today",
      startDate: o_nowDate,
      endDate: o_nowDate
    });

    if(userDivexecHotBitsCalendarTF) {
      const todayJsDateObj = JSFUNC.convert_mysqldate_to_jsdateobj(o_nowDate);
      const todayDayOfWeek0to6 = JSFUNC.date_w(todayJsDateObj);
      const todayYearInt = JSFUNC.date_yyyy(todayJsDateObj);
      const todayMM2DigitString = JSFUNC.date_mm(todayJsDateObj);
      dateCategoriesArrayOfObjs.push({
        name: "this week",
        actionName: "this Week",
        startDate: JSFUNC.date_add_days(o_nowDate, (0 - todayDayOfWeek0to6)), //sunday's date of this current week (starting from sunday)
        endDate: o_nowDate
      });

      dateCategoriesArrayOfObjs.push({
        name: "this month",
        actionName: "this Month",
        startDate: todayYearInt + "-" + todayMM2DigitString + "-01", //first of this current month
        endDate: o_nowDate
      });

      /*dateCategoriesArrayOfObjs.push({
        name: "this year",
        actionName: "this Year",
        startDate: todayYearInt + "-01-01", //first of jan of this current year
        endDate: o_nowDate
      });*/
    }
    else if(userDivexecHotBitsFixedTF) {
      dateCategoriesArrayOfObjs.push({
        name: "in past 7 days",
        actionName: "in the past 7 Days",
        startDate: JSFUNC.date_add_days(o_nowDate, -7), //last week
        endDate: JSFUNC.date_add_days(o_nowDate, -1) //yesterday
      });

      dateCategoriesArrayOfObjs.push({
        name: "in past 30 days",
        actionName: "in the past 30 Days",
        startDate: JSFUNC.date_add_days(o_nowDate, -30), //1 month ago
        endDate: JSFUNC.date_add_days(o_nowDate, -8) //1 week minus 1 day ago
      });

      /*dateCategoriesArrayOfObjs.push({
        name: "in past 365 days",
        actionName: "in the past 365 Days",
        startDate: JSFUNC.date_add_days(o_nowDate, -365), //1 year ago
        endDate: JSFUNC.date_add_days(o_nowDate, -31) //1 month minus 1 day ago
      });*/
    }
    const numDateCategories = dateCategoriesArrayOfObjs.length;

    //loop through all date categories to compute start/end date display masks
    for(let dateCategoryObj of dateCategoriesArrayOfObjs) {
      var startDateMask = DatabaseMobx.get_company_date_format_from_Ymd_date(dateCategoryObj.startDate);
      var endDateMask = DatabaseMobx.get_company_date_format_from_Ymd_date(dateCategoryObj.endDate);
      var capturesSectionHeaderSubLabelBetweenDates = "";
      if(dateCategoryObj.startDate === dateCategoryObj.endDate) { //date category is a single day (like 'today')
        capturesSectionHeaderSubLabelBetweenDates = " of " + startDateMask;
      }
      else { //date category is a span of multiple days
        capturesSectionHeaderSubLabelBetweenDates = " between " + startDateMask + " and " + endDateMask;
      }

      dateCategoryObj.startDateMask = startDateMask;
      dateCategoryObj.endDateMask = endDateMask;
      dateCategoryObj.capturesSectionHeaderSubLabelBetweenDates = capturesSectionHeaderSubLabelBetweenDates;
    }

    //initialize each hot bits date field with initialize date category arrays of captureIDs
    var hotBitsCaptureFieldsArrayOfObjs = [];
    for(let f = 0; f < numCaptureDateFields; f++) {
      var expandedCaptureDateFieldMap = expandedCaptureDateFieldsArrayOfMaps[f];
      var fieldLabel = fieldLabelsArray[f];
      var fieldActionLabel = fieldActionLabelsArray[f];

      var fieldID = expandedCaptureDateFieldMap.get("id");
      var fieldDisplayName = expandedCaptureDateFieldMap.get("display_name");

      var capturesPerDateArrayOfObjs = [];
      for(let d = 0; d < numDateCategories; d++) {
        capturesPerDateArrayOfObjs.push({
          captureIDsArray: []
        });
      }

      hotBitsCaptureFieldsArrayOfObjs.push({
        id: fieldID,
        displayName: fieldDisplayName,
        expandedCaptureFieldMap: expandedCaptureDateFieldMap,
        label: fieldLabel,
        actionLabel: fieldActionLabel,
        capturesPerDateArrayOfObjs: capturesPerDateArrayOfObjs
      });
    }

    //loop through all captures once, determine if it matches division filter, capture date field, and date category
    if(!zeroDivisionIDsSelectedTF) { //if no divisions are selected, no captures match
      for(let captureMap of DatabaseMobx.o_tbl_captures.values()) {
        var captureID = captureMap.get("id");

        //determine if this capture is within the division filter (which applies to all capture date fields and date categories)
        var captureIsInFilterDivisionIDsTF = true; //all captures match if every division is selected
        if(!allDivisionIDsSelectedTF) {
          var captureDivisionIDsArray = DatabaseMobx.division_owner_division_ids_array_from_capture_map(captureMap);
          captureIsInFilterDivisionIDsTF = JSFUNC.any_of_array1_is_in_array2(userDivexecFilterDivisionIDsArray, captureDivisionIDsArray);
        }

        if(captureIsInFilterDivisionIDsTF) {
          //loop through each capture date field
          for(let hotBitsCaptureFieldObj of hotBitsCaptureFieldsArrayOfObjs) {
            var captureDateValueYmd = DatabaseMobx.capture_value_raw_or_undefined_from_capture_map_and_expanded_capture_field_map(captureMap, hotBitsCaptureFieldObj.expandedCaptureFieldMap);
            //loop through each date category to determine if the capture date falls within the category date start/end range
            for(let d = 0; d < numDateCategories; d++) {
              var dateCategoryObj = dateCategoriesArrayOfObjs[d];
              if((captureDateValueYmd >= dateCategoryObj.startDate) && (captureDateValueYmd <= dateCategoryObj.endDate)) {
                hotBitsCaptureFieldObj.capturesPerDateArrayOfObjs[d].captureIDsArray.push(captureID);
              }
            }
          }
        }
      }
    }

    //after all captureIDs are sorted into category arrays, fetch the search result captures for those IDs
    for(let hotBitsCaptureFieldObj of hotBitsCaptureFieldsArrayOfObjs) {
      for(let capturesPerDateObj of hotBitsCaptureFieldObj.capturesPerDateArrayOfObjs) {
        var numCaptures = capturesPerDateObj.captureIDsArray.length;
        capturesPerDateObj.numCaptures = numCaptures;
        capturesPerDateObj.atLeast1CaptureTF = (numCaptures > 0);
        capturesPerDateObj.searchResultsCapturesArrayOfObjs = CapturesMobx.filtered_search_results_captures_from_capture_ids_array(capturesPerDateObj.captureIDsArray);
      }
    }

    return({
      dateCategoriesArrayOfObjs: dateCategoriesArrayOfObjs,
      hotBitsCaptureFieldsArrayOfObjs: hotBitsCaptureFieldsArrayOfObjs
    });
  }






  //critical thresholds graphs
  get c_criticalThresholdsFilteredCaptureMapsAndTcvMultipliersArrayOfObjs() {
    const c_combinedUserObj = UserMobx.c_combinedUserObj;

    const filtersArrayOfObjs = [
      {capture_field_id:DatabaseMobx.c_fieldMapOfCaptureType.get("id"), operator:"e", value:this.o_criticalThresholdsSelectedFilterCaptureTypeIDOrUndefned},
      {capture_field_id:DatabaseMobx.c_fieldMapOfDivisionOwners.get("id"), operator:"e", value:c_combinedUserObj.divexec_filter_division_ids_comma}
    ];

    const criticalThresholdsExpandedFiltersArrayOfObjs = CapturesMobx.create_expanded_filters_arrayOfObjs_from_filters_arrayOfObjs(filtersArrayOfObjs);

    var criticalThresholdsFilteredCaptureMapsAndTcvMultipliersArrayOfObjs = [];
    for(let [captureID, captureMap] of DatabaseMobx.o_tbl_captures) {
      var partialMultiplier0to1 = CapturesMobx.capture_matches_all_filters_partialMultiplier0to1_or_false_from_capture_map_and_expanded_filters_arrayOfObjs(captureMap, criticalThresholdsExpandedFiltersArrayOfObjs);
      if(partialMultiplier0to1 > 0) {
        criticalThresholdsFilteredCaptureMapsAndTcvMultipliersArrayOfObjs.push({
          captureMap: captureMap,
          partialMultiplier0to1: partialMultiplier0to1
        });
      }
    }
    return(criticalThresholdsFilteredCaptureMapsAndTcvMultipliersArrayOfObjs);
  }

  get c_criticalProgressThresholdScatterPlotDataArrayOfObjs() {
    const critthreshMoneyFieldID = UserMobx.c_combinedUserObj.divexec_critthresh_money_field_id;
    const critthreshMoneyExpandedFieldMap = DatabaseMobx.c_tbl_captures_fields.get(critthreshMoneyFieldID);

    const stageFieldDisplayName = DatabaseMobx.c_fieldMapOfStage.get("display_name");
    const progressFieldDisplayName = DatabaseMobx.c_fieldMapOfTotalShapingProgress.get("display_name");
    const rfpDateFieldDisplayName = DatabaseMobx.c_fieldMapOfRFPDate.get("display_name");

    var criticalProgressThresholdScatterPlotDataArrayOfObjs = [];

    const stageTypeOrdersArrayOfArrays = [[4] ,[5], [6], [7,8]]; //cancelled/no bid on bottom, then lost, then wins on top of the scatterplot pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7
    for(let stageTypeOrdersArray of stageTypeOrdersArrayOfArrays) {
      for(let stageObj of DatabaseMobx.c_allStagesArrayOfObjs) {
        if(JSFUNC.in_array(stageObj.pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7, stageTypeOrdersArray)) {
          var xyPointsArrayOfObjs = [];
          for(let catureMapAndTcvMultiplierObj of this.c_criticalThresholdsFilteredCaptureMapsAndTcvMultipliersArrayOfObjs) {
            var captureMap = catureMapAndTcvMultiplierObj.captureMap;
            var partialMultiplier0to1 = catureMapAndTcvMultiplierObj.partialMultiplier0to1;
            if(captureMap.get("stage_id") === stageObj.id) {
              var captureName = DatabaseMobx.capture_name_plaintext_from_capture_map(captureMap);
              var progressValueMaskPlainText = DatabaseMobx.value_mask_plaintext_from_capture_map_and_expanded_capture_field_map(captureMap, DatabaseMobx.c_fieldMapOfTotalShapingProgress);
              var rfpDateValueMaskPlainText = DatabaseMobx.value_mask_plaintext_from_capture_map_and_expanded_capture_field_map(captureMap, DatabaseMobx.c_fieldMapOfRFPDate);

              var critthreshMoneyValueRaw = 0;
              var title = "Capture: " + captureName + "\n" + stageFieldDisplayName + ": " + stageObj.name;
              if(critthreshMoneyExpandedFieldMap !== undefined) {
                var critthreshMoneyValueMaskSortIfoCanEditObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(captureMap, critthreshMoneyExpandedFieldMap);
                critthreshMoneyValueRaw = critthreshMoneyValueMaskSortIfoCanEditObj.valueRaw;
                title += "\n" + critthreshMoneyExpandedFieldMap.get("display_name") + ": " + critthreshMoneyValueMaskSortIfoCanEditObj.valueMaskPlainText;
              }
              title += "\n" + progressFieldDisplayName + ": " + progressValueMaskPlainText;
              title += "\n" + rfpDateFieldDisplayName + ": " + rfpDateValueMaskPlainText;

              xyPointsArrayOfObjs.push({
                x: captureMap.get("shaping_total_progress"),
                y: critthreshMoneyValueRaw,
                clickReturnValue: captureMap.get("id"),
                title: title
              });
            }
          }

          criticalProgressThresholdScatterPlotDataArrayOfObjs.push({
            xyPointsArrayOfObjs: xyPointsArrayOfObjs,
            label: stageObj.name,
            color: stageObj.color
          });
        }
      }
    }

    return(criticalProgressThresholdScatterPlotDataArrayOfObjs);
  }

  get c_criticalPwinThresholdScatterPlotDataArrayOfObjs() {
    const critthreshMoneyFieldID = UserMobx.c_combinedUserObj.divexec_critthresh_money_field_id;
    const critthreshMoneyExpandedFieldMap = DatabaseMobx.c_tbl_captures_fields.get(critthreshMoneyFieldID);

    const stageFieldDisplayName = DatabaseMobx.c_fieldMapOfStage.get("display_name");
    const pwinFieldDisplayName = DatabaseMobx.c_fieldMapOfPwin.get("display_name");
    const rfpDateFieldDisplayName = DatabaseMobx.c_fieldMapOfRFPDate.get("display_name");

    var criticalPwinThresholdScatterPlotDataArrayOfObjs = [];

    const stageTypeOrdersArrayOfArrays = [[4] ,[5], [6], [7,8]]; //cancelled/no bid on bottom, then lost, then wins on top of the scatterplot pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7
    for(let stageTypeOrdersArray of stageTypeOrdersArrayOfArrays) {
      for(let stageObj of DatabaseMobx.c_allStagesArrayOfObjs) {
        if(JSFUNC.in_array(stageObj.pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7, stageTypeOrdersArray)) {
          var xyPointsArrayOfObjs = [];
          for(let catureMapAndTcvMultiplierObj of this.c_criticalThresholdsFilteredCaptureMapsAndTcvMultipliersArrayOfObjs) {
            var captureMap = catureMapAndTcvMultiplierObj.captureMap;
            var partialMultiplier0to1 = catureMapAndTcvMultiplierObj.partialMultiplier0to1;
            if(captureMap.get("stage_id") === stageObj.id) {
              var captureName = DatabaseMobx.capture_name_plaintext_from_capture_map(captureMap);
              var pwinValueMaskPlainText = DatabaseMobx.value_mask_plaintext_from_capture_map_and_expanded_capture_field_map(captureMap, DatabaseMobx.c_fieldMapOfPwin);
              var rfpDateValueMaskPlainText = DatabaseMobx.value_mask_plaintext_from_capture_map_and_expanded_capture_field_map(captureMap, DatabaseMobx.c_fieldMapOfRFPDate);

              var critthreshMoneyValueRaw = 0;
              var title = "Capture: " + captureName + "\n" + stageFieldDisplayName + ": " + stageObj.name;
              if(critthreshMoneyExpandedFieldMap !== undefined) {
                var critthreshMoneyValueMaskSortIfoCanEditObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(captureMap, critthreshMoneyExpandedFieldMap);
                critthreshMoneyValueRaw = critthreshMoneyValueMaskSortIfoCanEditObj.valueRaw;
                title += "\n" + critthreshMoneyExpandedFieldMap.get("display_name") + ": " + critthreshMoneyValueMaskSortIfoCanEditObj.valueMaskPlainText;
              }
              title += "\n" + pwinFieldDisplayName + ": " + pwinValueMaskPlainText;
              title += "\n" + rfpDateFieldDisplayName + ": " + rfpDateValueMaskPlainText;

              xyPointsArrayOfObjs.push({
                x: captureMap.get("pwin"),
                y: critthreshMoneyValueRaw,
                clickReturnValue: captureMap.get("id"),
                title: title
              });
            }
          }

          criticalPwinThresholdScatterPlotDataArrayOfObjs.push({
            xyPointsArrayOfObjs: xyPointsArrayOfObjs,
            label: stageObj.name,
            color: stageObj.color
          });
        }
      }
    }

    return(criticalPwinThresholdScatterPlotDataArrayOfObjs);
  }

  get c_pwinVsProgressScatterPlotDataArrayOfObjs() {
    const critthreshMoneyFieldID = UserMobx.c_combinedUserObj.divexec_critthresh_money_field_id;
    const critthreshMoneyExpandedFieldMap = DatabaseMobx.c_tbl_captures_fields.get(critthreshMoneyFieldID);

    const stageFieldDisplayName = DatabaseMobx.c_fieldMapOfStage.get("display_name");
    const pwinFieldDisplayName = DatabaseMobx.c_fieldMapOfPwin.get("display_name");
    const progressFieldDisplayName = DatabaseMobx.c_fieldMapOfTotalShapingProgress.get("display_name");
    const rfpDateFieldDisplayName = DatabaseMobx.c_fieldMapOfRFPDate.get("display_name");

    var pwinVsProgressScatterPlotDataArrayOfObjs = [];

    const stageTypeOrdersArrayOfArrays = [[4] ,[5], [6], [7,8]]; //cancelled/no bid on bottom, then lost, then wins on top of the scatterplot pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7
    for(let stageTypeOrdersArray of stageTypeOrdersArrayOfArrays) {
      for(let stageObj of DatabaseMobx.c_allStagesArrayOfObjs) {
        if(JSFUNC.in_array(stageObj.pa1_a2_as3_csw4_csl5_cnsnb6_cnsgc7, stageTypeOrdersArray)) {
          var xyPointsArrayOfObjs = [];
          for(let catureMapAndTcvMultiplierObj of this.c_criticalThresholdsFilteredCaptureMapsAndTcvMultipliersArrayOfObjs) {
            var captureMap = catureMapAndTcvMultiplierObj.captureMap;
            var partialMultiplier0to1 = catureMapAndTcvMultiplierObj.partialMultiplier0to1;
            if(captureMap.get("stage_id") === stageObj.id) {
              var captureName = DatabaseMobx.capture_name_plaintext_from_capture_map(captureMap);
              var pwinValueMaskPlainText = DatabaseMobx.value_mask_plaintext_from_capture_map_and_expanded_capture_field_map(captureMap, DatabaseMobx.c_fieldMapOfPwin);
              var progressValueMaskPlainText = DatabaseMobx.value_mask_plaintext_from_capture_map_and_expanded_capture_field_map(captureMap, DatabaseMobx.c_fieldMapOfTotalShapingProgress);
              var rfpDateValueMaskPlainText = DatabaseMobx.value_mask_plaintext_from_capture_map_and_expanded_capture_field_map(captureMap, DatabaseMobx.c_fieldMapOfRFPDate);

              var critthreshMoneyValueRaw = 0;
              var title = "Capture: " + captureName + "\n" + stageFieldDisplayName + ": " + stageObj.name;
              if(critthreshMoneyExpandedFieldMap !== undefined) {
                var critthreshMoneyValueMaskSortIfoCanEditObj = DatabaseMobx.value_mask_sort_ifo_canedit_obj_from_capture_map_and_expanded_capture_field_map(captureMap, critthreshMoneyExpandedFieldMap);
                title += "\n" + critthreshMoneyExpandedFieldMap.get("display_name") + ": " + critthreshMoneyValueMaskSortIfoCanEditObj.valueMaskPlainText;
              }
              title += "\n" + pwinFieldDisplayName + ": " + pwinValueMaskPlainText + "\n" + progressFieldDisplayName + ": " + progressValueMaskPlainText;
              title += "\n" + rfpDateFieldDisplayName + ": " + rfpDateValueMaskPlainText;

              xyPointsArrayOfObjs.push({
                x: captureMap.get("shaping_total_progress"),
                y: captureMap.get("pwin"),
                clickReturnValue: captureMap.get("id"),
                title: title
              });
            }
          }

          pwinVsProgressScatterPlotDataArrayOfObjs.push({
            xyPointsArrayOfObjs: xyPointsArrayOfObjs,
            label: stageObj.name,
            color: stageObj.color
          });
        }
      }
    }

    return(pwinVsProgressScatterPlotDataArrayOfObjs);
  }







  //stage flow
  get c_stageFlowCaptureIDsWithinSelectedFilterCaptureTypeArray() {
    const c_combinedUserObj = UserMobx.c_combinedUserObj;

    var filtersArrayOfObjs = [
      {capture_field_id:DatabaseMobx.c_fieldMapOfCaptureType.get("id"), operator:"e", value:this.o_stageFlowSelectedFilterCaptureTypeIDOrUndefined},
      {capture_field_id:DatabaseMobx.c_fieldMapOfDivisionOwners.get("id"), operator:"e", value:c_combinedUserObj.divexec_filter_division_ids_comma}
    ];

    //determine which stageIDs are in the selected stage type button
    if(JSFUNC.in_array(this.o_stageFlowSelectedFilterStageTypeString, ["closed", "active"])) {
      var selectedStageIDsComma = "";
      if(this.o_stageFlowSelectedFilterStageTypeString === "closed") { selectedStageIDsComma = DatabaseMobx.c_closedStageIDsArray; }
      else if(this.o_stageFlowSelectedFilterStageTypeString === "active") { selectedStageIDsComma = DatabaseMobx.c_activeStageIDsArray; }

      filtersArrayOfObjs.push({capture_field_id:DatabaseMobx.c_fieldMapOfStage.get("id"), operator:"e", value:selectedStageIDsComma});
    }

    const expandedFiltersArrayOfObjs = CapturesMobx.create_expanded_filters_arrayOfObjs_from_filters_arrayOfObjs(filtersArrayOfObjs);

    var stageFlowCaptureIDsWithinSelectedFilterCaptureTypeArray = [];
    var partialNumMatchingCaptures = 0;
    for(let [captureID, captureMap] of DatabaseMobx.o_tbl_captures) {
      var partialMultiplier0to1 = CapturesMobx.capture_matches_all_filters_partialMultiplier0to1_or_false_from_capture_map_and_expanded_filters_arrayOfObjs(captureMap, expandedFiltersArrayOfObjs);
      if(partialMultiplier0to1 !== false) {
        stageFlowCaptureIDsWithinSelectedFilterCaptureTypeArray.push(captureID);
      }
    }
    return(stageFlowCaptureIDsWithinSelectedFilterCaptureTypeArray);
  }

  get c_stageFlowStageIDsPerCaptureIDMapOfArrays() {
    //loop through all raw stage changelog data and organize it by captureID
    var stageFlowStageIDsPerCaptureIDMapOfArrays = new Map();

    //ensure a captureTypeID is selected on the stage flow page
    if(this.o_stageFlowSelectedFilterCaptureTypeIDOrUndefined !== undefined) {
      //ensure that the thin stage changelog is loaded before creating this map, otherwise return an empty map
      if(JSFUNC.is_array(this.o_stageFlowThinTblCLogStagesArrayOfObjs)) {
        for(let thinLogStageObj of this.o_stageFlowThinTblCLogStagesArrayOfObjs) {
          //test if this stageID is > 0 (usually this happens in import when new captures with no stage specified have a value of -1)
          if(thinLogStageObj.si > 0) {
            //test whether this captureID is within the divexec filter
            if(JSFUNC.in_array(thinLogStageObj.ci, this.c_stageFlowCaptureIDsWithinSelectedFilterCaptureTypeArray)) {
              //find out the capture type of this capture and only include this log record if the capture matches the selected captureTypeID
              var captureMap = DatabaseMobx.o_tbl_captures.get(thinLogStageObj.ci);
              if(captureMap !== undefined) {
                if(captureMap.get("capture_type_id") === this.o_stageFlowSelectedFilterCaptureTypeIDOrUndefined) {
                  //see if there's an existing record in the map for this captureID and append it or create a new captureID record in the Map
                  var stageIDsArray = stageFlowStageIDsPerCaptureIDMapOfArrays.get(thinLogStageObj.ci);
                  if(stageIDsArray !== undefined) {
                    stageIDsArray.push(thinLogStageObj.si); //append this log stageID to this captureID record
                  }
                  else { //create a new captureID record in the map with this stageID as its first stage entry
                    stageFlowStageIDsPerCaptureIDMapOfArrays.set(thinLogStageObj.ci, [thinLogStageObj.si]);
                  }
                }
              }
            }
          }
        }

        //loop through the completed Map, for each capture get the current real stageID, if that does not match the last changelog stageID entry, append the real stageID to make it the last entry
        for(let [captureID, stageIDsArray] of stageFlowStageIDsPerCaptureIDMapOfArrays) {
          if(JSFUNC.is_array(stageIDsArray)) {
            var numStageIDs = stageIDsArray.length;
            if(numStageIDs > 0) {
              var captureMap = DatabaseMobx.o_tbl_captures.get(captureID);
              if(captureMap !== undefined) {
                var realStageID = captureMap.get("stage_id");
                var lastLogStageID = stageIDsArray[numStageIDs - 1];
                if(realStageID !== lastLogStageID) {
                  stageIDsArray.push(realStageID);
                }
              }
            }
          }
        }
      }
    }

    return(stageFlowStageIDsPerCaptureIDMapOfArrays);
  }

  get c_stageFlowDataPerStageArrayOfObjs() {
    //undefined is a flag that the data for the stage flow graph is being fetched and loaded from the database
    if(!JSFUNC.is_array(this.o_stageFlowThinTblCLogStagesArrayOfObjs)) {
      return(undefined);
    }

    //error message for 0 available capture types
    if(DatabaseMobx.c_captureTypesArrayOfObjs.length === 0) {
      return("The Stage Flow graph cannot be drawn - there is not a valid " + DatabaseMobx.c_fieldMapOfCaptureType.get("display_name") + " created in the system to select for the stages");
    }

    //error message if the selected capture type does not exist
    const captureTypeMap = DatabaseMobx.o_tbl_a_capture_types.get(this.o_stageFlowSelectedFilterCaptureTypeIDOrUndefined);
    if(captureTypeMap === undefined) {
      return("The Stage Flow graph cannot be drawn - the selected " + DatabaseMobx.c_fieldMapOfCaptureType.get("display_name") + " (ID: " + this.o_stageFlowSelectedFilterCaptureTypeIDOrUndefined + ") is not valid");
    }

    //set up array of active/closed stageIDs for the selected capture type, if there are 0 active stages or 0 closed stages, an error message is returned instead of an arrayOfObjs
    const captureTypeStageIDsComma = captureTypeMap.get("stage_ids_comma");
    const captureTypeStageIDsArray = JSFUNC.convert_comma_list_to_int_array(captureTypeStageIDsComma);
    var captureTypeActiveStageIDsArray = [];
    var captureTypeClosedStageIDsArray = [];
    for(let stageID of captureTypeStageIDsArray) {
      if(JSFUNC.in_array(stageID, DatabaseMobx.c_activeStageIDsArray)) {
        captureTypeActiveStageIDsArray.push(stageID);
      }
      else if(JSFUNC.in_array(stageID, DatabaseMobx.c_closedStageIDsArray)) {
        captureTypeClosedStageIDsArray.push(stageID);
      }
    }
    const numCaptureTypeActiveStageIDs = captureTypeActiveStageIDsArray.length;
    const numCaptureTypeClosedStageIDs = captureTypeClosedStageIDsArray.length;

    if(numCaptureTypeActiveStageIDs === 0) {
      return("The Stage Flow graph cannot be drawn - there are no Active Stages in the selected Capture Type");
    }

    if(numCaptureTypeClosedStageIDs === 0) {
      return("The Stage Flow graph cannot be drawn - there are no Closed Stages in the selected Capture Type");
    }

    //if there are 0 stage log entries for this capture type, display an error message
    if(this.c_stageFlowStageIDsPerCaptureIDMapOfArrays.size === 0) {
      return("The Stage Flow graph cannot be drawn - there are no Stage Changelog entries matching the selected Capture Type to plot");
    }

    //build the output for the graph for each active stage and one at the end for all closed stages together (the first active stage is always 100%)
    var stageFlowActiveStagesArrayOfObjs = [];
    for(let activeStageID of captureTypeActiveStageIDsArray) {
      var stageFlowActiveStageObj = this.stage_flow_initialize_graph_stage_obj(activeStageID, captureTypeClosedStageIDsArray);
      stageFlowActiveStagesArrayOfObjs.push(stageFlowActiveStageObj);
    }

    //created the combined closed stages obj that represents the last portion of the stage flow graph
    var stageFlowAllClosedStagesObj = this.stage_flow_initialize_graph_stage_obj(-99, captureTypeClosedStageIDsArray); //this part of the graph is all closed stages as specified in the closed stages arrayOfObjs within this obj, so this active stageObj can be undefined and the transited counts will be 0

    //loop through all organized stage changelog data per capture and add counts into each part of the active closed data for the graph
    for(let [captureID, stageIDsArray] of this.c_stageFlowStageIDsPerCaptureIDMapOfArrays) {
      if(JSFUNC.is_array(stageIDsArray)) {
        var numStageLogs = stageIDsArray.length;
        if(numStageLogs >= 1) {
          var currentStageID = stageIDsArray[numStageLogs - 1]; //last changelog entry is the current stage for this captureID
          if(JSFUNC.in_array(currentStageID, DatabaseMobx.c_closedWonStageIDsArray) || JSFUNC.in_array(currentStageID, DatabaseMobx.c_closedLostStageIDsArray)) { //won/lost capture
            //for a won/lost capture, every active stage gets a transit count
            for(let stageFlowActiveStageObj of stageFlowActiveStagesArrayOfObjs) {
              stageFlowActiveStageObj.transitedFromPrevStageCounts++;
              stageFlowActiveStageObj.transitedFromPrevStageCaptureIDsArray.push(captureID);
            }

            //then the final closed stage gets a count
            for(let stageFlowClosedStageObj of stageFlowAllClosedStagesObj.countsForClosedStagesArrayOfObjs) {
              if(stageFlowClosedStageObj.stageObj.id === currentStageID) {
                stageFlowClosedStageObj.transitedFromPrevStageCounts++;
                stageFlowClosedStageObj.transitedFromPrevStageCaptureIDsArray.push(captureID);
              }
            }
          }
          else if(JSFUNC.in_array(currentStageID, DatabaseMobx.c_activeStageIDsArray)) { //active capture
            //find the stage index within the capture type of this stageID
            var currentActiveStageIndex = captureTypeActiveStageIDsArray.indexOf(currentStageID);
            if(currentActiveStageIndex >= 0) { //found the matching stageID
              //for an active capture, every stage prior to this one (in order of the stages from the capture type) is given a transit count
              for(let s = 0; s < currentActiveStageIndex; s++) {
                stageFlowActiveStagesArrayOfObjs[s].transitedFromPrevStageCounts++;
                stageFlowActiveStagesArrayOfObjs[s].transitedFromPrevStageCaptureIDsArray.push(captureID);
              }

              //then, for the current active stage, increment the current active count
              stageFlowActiveStagesArrayOfObjs[currentActiveStageIndex].currentlyActiveInPrevStageCounts++;
              stageFlowActiveStagesArrayOfObjs[currentActiveStageIndex].currentlyActiveInPrevStageCaptureIDsArray.push(captureID);
            }
          }
          else { //no bid/cancelled capture
            //must find the most recent stage (prior to the current nb/c stage) that is active for this to count (otherwise can't trace where it came from, like if the history was only "11" (nb) or was only "12,11" (won, then nb))
            var mostRecentActiveStageIndex = undefined;
            var logIndex = (numStageLogs - 2);
            while((mostRecentActiveStageIndex === undefined) && (logIndex >= 0)) { //loop in reverse order starting with the 2nd entry from the end
              var stageID = stageIDsArray[logIndex]; //testing this log stageID
              var activeStageIndex = captureTypeActiveStageIDsArray.indexOf(stageID); //try to find the index of this stageID among the capture type active stageIDs
              if(activeStageIndex >= 0) { //this is a valid capture type active stageID
                mostRecentActiveStageIndex = activeStageIndex;
              }
              logIndex--;
            }

            if(mostRecentActiveStageIndex !== undefined) {
              //every stage from the beginning up to and including the most recent active stage have their transit count incremented
              for(let s = 0; s <= mostRecentActiveStageIndex; s++) {
                stageFlowActiveStagesArrayOfObjs[s].transitedFromPrevStageCounts++;
                stageFlowActiveStagesArrayOfObjs[s].transitedFromPrevStageCaptureIDsArray.push(captureID);
              }

              //then the closed nb/c stage from the active stage after the most recent active stage gets a count
              if(mostRecentActiveStageIndex < (numCaptureTypeActiveStageIDs - 1)) { //if this is not the last active stage, increment the closed stage counter within that active
                var nextActiveStageIndexAfterMostRecent = (mostRecentActiveStageIndex + 1);
                for(let countsForClosedStageObj of stageFlowActiveStagesArrayOfObjs[nextActiveStageIndexAfterMostRecent].countsForClosedStagesArrayOfObjs) {
                  if(countsForClosedStageObj.stageObj.id === currentStageID) {
                    countsForClosedStageObj.transitedFromPrevStageCounts++;
                    countsForClosedStageObj.transitedFromPrevStageCaptureIDsArray.push(captureID);
                  }
                }
              }
              else { //the most recent active stage was the last active stage, increment the counter within the last closed stage obj
                for(let stageFlowClosedStageObj of stageFlowAllClosedStagesObj.countsForClosedStagesArrayOfObjs) {
                  if(stageFlowClosedStageObj.stageObj.id === currentStageID) {
                    stageFlowClosedStageObj.transitedFromPrevStageCounts++;
                    stageFlowClosedStageObj.transitedFromPrevStageCaptureIDsArray.push(captureID);
                  }
                }
              }
            }
          }
        }
      }
    }

    //build the final stage flow data arrayOfObjs by combining the active stage objs with the single 'all closed stages' obj
    var stageFlowDataPerStageArrayOfObjs = [];
    for(let stageFlowActiveStageObj of stageFlowActiveStagesArrayOfObjs) {
      stageFlowDataPerStageArrayOfObjs.push(stageFlowActiveStageObj);
    }
    stageFlowDataPerStageArrayOfObjs.push(stageFlowAllClosedStagesObj);

    //compute the total number of counts that each part of the graph will be computed against using the first section (first active stage)
    var stageFlowFirstActiveStageObj = stageFlowDataPerStageArrayOfObjs[0];
    var firstActiveStageTotalCounts = (stageFlowFirstActiveStageObj.transitedFromPrevStageCounts + stageFlowFirstActiveStageObj.currentlyActiveInPrevStageCounts);

    //get the name of this customer's stage field to use in titles
    const stageFieldDisplayName = DatabaseMobx.c_fieldMapOfStage.get("display_name");

    //loop through each stage flow column (each active and single 'all closed') to compute percents and titles of each block
    for(let s = 0; s < stageFlowDataPerStageArrayOfObjs.length; s++) {
      var stageFlowStageObj = stageFlowDataPerStageArrayOfObjs[s];

      var stageObj = stageFlowStageObj.stageObj;
      var isLastColumnTF = (stageObj.id === -99); //the "All Closed Stages" column on thr far right of the graph
      var transitedCounts = stageFlowStageObj.transitedFromPrevStageCounts;
      var currentlyActiveCounts = stageFlowStageObj.currentlyActiveInPrevStageCounts;

      //keep track of how much vertical space is remaining at the bottom of each column to draw a blank area
      var remainingSpacePerc = 100;

      //draw transited through blocks first at top
      stageFlowStageObj.graphTransitedPerc = ((transitedCounts / firstActiveStageTotalCounts) * 100);
      stageFlowStageObj.graphTransitedStartPerc = 0; //transited perc starts at top (0% on svg y-axis)
      stageFlowStageObj.graphTransitedEndPerc = stageFlowStageObj.graphTransitedPerc;
      stageFlowStageObj.graphTransitedColor = "f2f2e0";
      stageFlowStageObj.graphTransitedTitle = transitedCounts + " " + JSFUNC.plural(transitedCounts, "Capture has", "Captures have") + " advanced through and beyond the '" + stageObj.name + "' " + stageFieldDisplayName;

      remainingSpacePerc -= stageFlowStageObj.graphTransitedPerc;

      //then draw any closed stages (half width unless in last -99 column)
      var numClosedStages = stageFlowStageObj.countsForClosedStagesArrayOfObjs.length;
      for(let c = 0; c < numClosedStages; c++) {
        var stageFlowClosedStageObj = stageFlowStageObj.countsForClosedStagesArrayOfObjs[c];
        var closedStageObj = stageFlowClosedStageObj.stageObj;
        var closedCounts = stageFlowClosedStageObj.transitedFromPrevStageCounts;

        stageFlowClosedStageObj.graphClosedPerc = ((closedCounts / firstActiveStageTotalCounts) * 100);
        stageFlowClosedStageObj.graphClosedStartPerc = ((c === 0) ? (stageFlowStageObj.graphTransitedEndPerc) : (stageFlowStageObj.countsForClosedStagesArrayOfObjs[c-1].graphClosedEndPerc)); //use end value from previous graph segment for this one's start
        stageFlowClosedStageObj.graphClosedEndPerc = (stageFlowClosedStageObj.graphClosedStartPerc + stageFlowClosedStageObj.graphClosedPerc);
        stageFlowClosedStageObj.graphClosedColor = closedStageObj.color;
        if(isLastColumnTF) {
          stageFlowClosedStageObj.graphClosedTitle = closedCounts + " " + JSFUNC.plural(closedCounts, "Capture is", "Captures are") + " currently Closed in the '" + closedStageObj.name + "' " + stageFieldDisplayName;
        }
        else {
          if(s > 0) {
            var previousActiveStageFlowStageObj = stageFlowDataPerStageArrayOfObjs[s-1];
            stageFlowClosedStageObj.graphClosedTitle = closedCounts + " " + JSFUNC.plural(closedCounts, "Capture", "Captures") + " passed from the '" + previousActiveStageFlowStageObj.stageObj.name + "' " + stageFieldDisplayName + " directly into the Closed '" + closedStageObj.name + "' " + stageFieldDisplayName;
          }
          else {
            stageFlowClosedStageObj.graphClosedTitle = undefined;
          }
        }

        remainingSpacePerc -= stageFlowClosedStageObj.graphClosedPerc;
      }

      //last, draw any currently active stages at the bottom (quarter width)
      stageFlowStageObj.graphActivePerc = ((currentlyActiveCounts / firstActiveStageTotalCounts) * 100);
      stageFlowStageObj.graphActiveStartPerc = stageFlowStageObj.countsForClosedStagesArrayOfObjs[numClosedStages-1].graphClosedEndPerc;
      stageFlowStageObj.graphActiveEndPerc = (stageFlowStageObj.graphActiveStartPerc + stageFlowStageObj.graphActivePerc);
      stageFlowStageObj.graphActiveColor = stageObj.color;
      stageFlowStageObj.graphActiveTitle = currentlyActiveCounts + " " + JSFUNC.plural(currentlyActiveCounts, "Capture is", "Captures are") + " currently Active in the '" + stageObj.name + "' " + stageFieldDisplayName;

      remainingSpacePerc -= stageFlowStageObj.graphActivePerc;

      stageFlowStageObj.remainingSpacePerc = remainingSpacePerc;
    }

    return(stageFlowDataPerStageArrayOfObjs);
  }

  stage_flow_initialize_graph_stage_obj(i_activeStageID, i_captureTypeClosedStageIDsArray) {
    //initialize counts for all closed stages (holds data on transitioning to no bid/cancelled closed stages from active stages, or to all closed stages for the last part of the graph)
    var countsForClosedStagesArrayOfObjs = [];
    for(let closedStageID of i_captureTypeClosedStageIDsArray) {
      var closedStageMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_stages_pool", closedStageID);
      countsForClosedStagesArrayOfObjs.push({
        stageObj: JSFUNC.obj_from_map(closedStageMap),
        transitedFromPrevStageCounts: 0,
        transitedFromPrevStageCaptureIDsArray: []
      });
    }

    var activeStageMap = DatabaseMobx.tbl_row_map_from_id("tbl_a_stages_pool", i_activeStageID);
    return({
      stageObj: JSFUNC.obj_from_map(activeStageMap),
      transitedFromPrevStageCounts: 0,
      transitedFromPrevStageCaptureIDsArray: [],
      currentlyActiveInPrevStageCounts: 0,
      currentlyActiveInPrevStageCaptureIDsArray: [],
      countsForClosedStagesArrayOfObjs: countsForClosedStagesArrayOfObjs
    });
  }




  //excel report writer
  get c_excelReportTemplatesWithinFoldersObj() {
    return(DatabaseMobx.generate_templates_within_folders_obj_from_admin_templates_ffs_tbl_name("tbl_a_excel_report_templates_filefoldersystem", "Excel Reports"));
  }




  //========================================================================================
  //actions
  //performance
  a_performance_set_selected_user_or_division_id(i_userTrueDivisionFalse, i_userOrDivisionID) {
    this.o_performanceSelectedUserTrueDivisionFalse = i_userTrueDivisionFalse;
    this.o_performanceSelectedUserOrDivisionID = i_userOrDivisionID;
    this.a_performance_set_mobile_screen("graphs");
  }

  a_performance_set_mobile_screen(i_screen) {
    this.o_performanceMobileScreen = i_screen;
  }

  a_performance_set_editing_fyr(i_fyr) {
    this.o_performanceEditingFyr = i_fyr;
  }

  a_performance_update_or_insert_fyr_quota(i_fyr, i_quotaFieldDbName, i_quotaValue) {
    const o_performanceSelectedUserOrDivisionID = this.o_performanceSelectedUserOrDivisionID;
    const c_performanceUserSelectedMoneyExpandedCaptureFieldMapOrUndefined = this.c_performanceUserSelectedMoneyExpandedCaptureFieldMapOrUndefined;

    if(c_performanceUserSelectedMoneyExpandedCaptureFieldMapOrUndefined !== undefined) { //if a valid money field is selected
      const jsDescription = JSFUNC.js_description_from_action("DivexecMobx", "a_performance_update_or_insert_fyr_quota", ["i_fyr", "i_quotaFieldDbName", "i_quotaValue"], [i_fyr, i_quotaFieldDbName, i_quotaValue]);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      const quotaUser0Division1 = ((this.o_performanceSelectedUserTrueDivisionFalse) ? (0) : (1));
      const performanceMoneyFieldID = c_performanceUserSelectedMoneyExpandedCaptureFieldMapOrUndefined.get("id");

      const existingQuotaMap = JSFUNC.get_first_map_matching_field_value(DatabaseMobx.o_tbl_d_quota, ["user0_division1", "userdiv_id", "money_field_id", "fyr"], [quotaUser0Division1, o_performanceSelectedUserOrDivisionID, performanceMoneyFieldID, i_fyr]);

      if(existingQuotaMap !== undefined) {
        C_CallPhpTblUID.add_update("tbl_d_quota", existingQuotaMap.get("id"), i_quotaFieldDbName, i_quotaValue, "i");
      }
      else { //if no record for this fyr exists yet, create a new entry
        const submittedQuota = ((i_quotaFieldDbName === "submitted_quota") ? (i_quotaValue) : (0));
        const wonQuota = ((i_quotaFieldDbName === "won_quota") ? (i_quotaValue) : (0));
        const fieldNamesArray = ["user0_division1", "userdiv_id", "money_field_id", "fyr", "submitted_quota", "won_quota"];
        const valuesArray = [quotaUser0Division1, o_performanceSelectedUserOrDivisionID, performanceMoneyFieldID, i_fyr, submittedQuota, wonQuota];
        const idsbArray = ["i", "i", "i", "i", "i", "i"];
        C_CallPhpTblUID.add_insert("tbl_d_quota", fieldNamesArray, valuesArray, idsbArray);
      }

      C_CallPhpTblUID.execute();
    }
  }



  //graphs
  a_graphs_set_selected_page_id(i_pageID) {
    if(this.c_tabDailySnapshotTrueTrendAnalyzerFalse) {
      this.o_dailySnapshotSelectedPageID = i_pageID;
    }
    else {
      this.o_trendAnalyzerSelectedPageID = i_pageID;
    }
  }

  a_graphs_initialize_selected_page_id_to_first_option() {
    var newSelectedPageID = -1;
    if(this.c_graphsSelectedTabAllPagesArrayOfObjs.length > 0) {
      newSelectedPageID = this.c_graphsSelectedTabAllPagesArrayOfObjs[0].id; //id of first sorted graph page button
    }
    this.a_graphs_set_selected_page_id(newSelectedPageID);
  }

  a_graphs_create_new_page(i_newPageName) {
    const userID = UserMobx.o_userID;
    const newPageSort = (this.c_graphsSelectedTabAllPagesArrayOfObjs.length + 1);

    const jsDescription = JSFUNC.js_description_from_action("DivexecMobx", "a_graphs_create_new_page", ["i_newPageName"], [i_newPageName]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    var fieldNamesArray = [];
    var valuesArray = [];
    var idsbArray = [];
    if(this.c_tabDailySnapshotTrueTrendAnalyzerFalse) {
      fieldNamesArray = this.graphs_daily_snapshot_page_field_names_array();
      valuesArray = [userID, newPageSort, userID, 0, -1, i_newPageName, 3, 28];
      idsbArray = this.graphs_daily_snapshot_page_idsb_array();
    }
    else {
      fieldNamesArray = this.graphs_trend_analyzer_page_field_names_array();
      valuesArray = [userID, newPageSort, userID, 0, -1, i_newPageName, 3, 28, "8004-01-04", "9001-01-04"]; //-4 years start to +1 year end
      idsbArray = this.graphs_trend_analyzer_page_idsb_array();
    }
    C_CallPhpTblUID.add_insert(this.c_graphsSelectedTabPagesTblName, fieldNamesArray, valuesArray, idsbArray);

    const functionOnSuccess = (i_parseResponse) => {
      const newlyInsertedPageID = i_parseResponse.outputObj.i0;
      this.a_graphs_set_selected_page_id(newlyInsertedPageID);
    }
    C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

    C_CallPhpTblUID.execute();
  }

  a_graphs_delete_page_and_graphs_from_page_id(i_deletedPageID) {
    const jsDescription = JSFUNC.js_description_from_action("DivexecMobx", "a_graphs_delete_page_and_graphs_from_page_id", ["i_deletedPageID"], [i_deletedPageID]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    //delete this page
    C_CallPhpTblUID.add_delete(this.c_graphsSelectedTabPagesTblName, i_deletedPageID, "sort", "user_id", UserMobx.o_userID); //resort tbl after delete filtered by this users userID

    //delete all graphs that were on this page
    const selectedTabGraphsTblRefMapOfMaps = DatabaseMobx.tbl_ref_from_tbl_name(this.c_graphsSelectedTabGraphsTblName);
    const graphIDsToDeleteArray = JSFUNC.get_column_vector_from_mapOfMaps_matching_field_value(selectedTabGraphsTblRefMapOfMaps, "page_id", i_deletedPageID, "id");
    C_CallPhpTblUID.add_delete(this.c_graphsSelectedTabGraphsTblName, graphIDsToDeleteArray); //no need to resort as every graph on the page is deleted

    //delete any pages that are replicas using this page as a reference (replica pages do not have graphs attached to their ids, no need to delete graphs from replica pages)
    const selectedTabPagesTblRefMapOfMaps = DatabaseMobx.tbl_ref_from_tbl_name(this.c_graphsSelectedTabPagesTblName);
    for(let pageMap of selectedTabPagesTblRefMapOfMaps.values()) {
      if(pageMap.get("replica_of_page_id") === i_deletedPageID) {
        var resortSortColumnName = "sort";
        var resortFilterFieldNameOrFieldNamesArray = "user_id";
        var resortFilterValueOrValuesArray = pageMap.get("user_id");
        C_CallPhpTblUID.add_delete(this.c_graphsSelectedTabPagesTblName, pageMap.get("id"), resortSortColumnName, resortFilterFieldNameOrFieldNamesArray, resortFilterValueOrValuesArray);
      }
    }

    //reinitialize to select the first of all available pages
    const functionOnFinish = () => {
      this.a_graphs_initialize_selected_page_id_to_first_option();
    }
    C_CallPhpTblUID.add_function("onFinish", functionOnFinish);

    C_CallPhpTblUID.execute();
  }

  a_graphs_add_public_page_as_replica(i_pageObj) {
    const jsDescription = JSFUNC.js_description_from_action("DivexecMobx", "a_graphs_add_public_graph_as_replica", [], []);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    var fieldNamesArray = [];
    var valuesArray = [];
    var idsbArray = [];
    if(this.c_tabDailySnapshotTrueTrendAnalyzerFalse) {
      fieldNamesArray = this.graphs_daily_snapshot_page_field_names_array();
      valuesArray = [UserMobx.o_userID, (this.c_graphsSelectedTabAllPagesArrayOfObjs.length + 1), -1, -1, i_pageObj.id, "", -1, -1];
      idsbArray = this.graphs_daily_snapshot_page_idsb_array();
    }
    else {
      fieldNamesArray = this.graphs_trend_analyzer_page_field_names_array();
      valuesArray = [UserMobx.o_userID, (this.c_graphsSelectedTabAllPagesArrayOfObjs.length + 1), -1, -1, i_pageObj.id, "", -1, -1, JSFUNC.blank_date(), JSFUNC.blank_date()];
      idsbArray = this.graphs_trend_analyzer_page_idsb_array();
    }
    C_CallPhpTblUID.add_insert(this.c_graphsSelectedTabPagesTblName, fieldNamesArray, valuesArray, idsbArray);

    const functionOnSuccess = (i_parseResponse) => {
      const newlyInsertedPageID = i_parseResponse.outputObj.i0;
      this.a_graphs_set_selected_page_id(newlyInsertedPageID);
    }
    C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

    C_CallPhpTblUID.execute();
  }

  a_graphs_copy_page(i_pageObj, i_newPageName) {
    const jsDescription = JSFUNC.js_description_from_action("DivexecMobx", "a_graphs_copy_page", ["i_pageObj", "i_newPageName"], [i_pageObj, i_newPageName]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    //insert the new copied page record
    const pagesTblName = this.c_graphsSelectedTabPagesTblName;

    var fieldNamesArray = [];
    var idsbArray = [];
    if(this.c_tabDailySnapshotTrueTrendAnalyzerFalse) {
      fieldNamesArray = this.graphs_daily_snapshot_page_field_names_array();
      idsbArray = this.graphs_daily_snapshot_page_idsb_array();
    }
    else {
      fieldNamesArray = this.graphs_trend_analyzer_page_field_names_array();
      idsbArray = this.graphs_trend_analyzer_page_idsb_array();
    }

    //if the page being copied is a replica of a different public page, try to load that page so that this copy is editable by this user and is no longer a replica
    var pageIDOrReferencedPageID = i_pageObj.id;
    var referencedPageMap = undefined; //undefined for non-replica page
    if(i_pageObj.replica_of_page_id > 0) { //this page is a replica
      const tblRefMapOfMaps = DatabaseMobx.tbl_ref_from_tbl_name(this.c_graphsSelectedTabPagesTblName);
      referencedPageMap = JSFUNC.get_first_map_matching_field_value(tblRefMapOfMaps, "id", i_pageObj.replica_of_page_id);
      if(referencedPageMap !== undefined) {
        pageIDOrReferencedPageID = referencedPageMap.get("id");
      }
    }

    //get all values to copy from i_graphDataObj looping over all page tbl fields
    var valuesArray = [];
    for(let fieldName of fieldNamesArray) {
      var copyValue = i_pageObj[fieldName];
      if(fieldName === "name") {
        copyValue = i_newPageName; //use the new page name
      }
      else if(fieldName === "sort") {
        copyValue = i_pageObj.sort; //make the new page sort the previous sort, which resorts to be 1 space after the original
      }
      else if(fieldName === "user_id") {
        copyValue = UserMobx.o_userID; //change that this page is on your divexec tab
      }
      else if(fieldName === "created_by_user_id") {
        copyValue = UserMobx.o_userID; //change creator of this page to this user
      }
      else if(fieldName === "public_01") {
        copyValue = 0; //set this copied page to private
      }
      else if(fieldName === "replica_of_page_id") {
        copyValue = -1; //set this as a new page, not a replica
      }
      else {
        if(referencedPageMap !== undefined) { //this is a replica page referencing a public page that exists
          copyValue = referencedPageMap.get(fieldName);
        }
      }
      valuesArray.push(copyValue);
    }

    const resortSortColumnName = "sort";
    const resortFilterFieldNameOrFieldNamesArray = ["user_id"];
    const resortFilterValueOrValuesArray = [i_pageObj.user_id];
    C_CallPhpTblUID.add_insert(pagesTblName, fieldNamesArray, valuesArray, idsbArray, resortSortColumnName, resortFilterFieldNameOrFieldNamesArray, resortFilterValueOrValuesArray);

    //copy all graph records that were on that page
    const functionOnSuccess = (i_parseResponse) => {
      const newlyInsertedPageID = i_parseResponse.outputObj.i0;

      const C_CallPhpTblUIDCopiedPageGraphs = new JSPHP.ClassCallPhpTblUID(jsDescription);

      const graphsTblName = this.c_graphsSelectedTabGraphsTblName;
      const graphFieldNamesArray = ((this.c_tabDailySnapshotTrueTrendAnalyzerFalse) ? (this.graphs_daily_snapshot_graphs_field_names_array()) : (this.graphs_trend_analyzer_graphs_field_names_array()));
      const graphIdsbArray = ((this.c_tabDailySnapshotTrueTrendAnalyzerFalse) ? (this.graphs_daily_snapshot_graphs_idsb_array()) : (this.graphs_trend_analyzer_graphs_idsb_array()));
      const tblRefMapOfMaps = DatabaseMobx.tbl_ref_from_tbl_name(graphsTblName);
      for(let graphMap of tblRefMapOfMaps.values()) {
        if(graphMap.get("page_id") === pageIDOrReferencedPageID) {
          //determine if the graph to copy onto this page is itself a replica or a real graph
          var graphMapOrReferencedGraphMap = undefined;

          var graphReplicaOfGraphID = graphMap.get("replica_of_graph_id");
          if(graphReplicaOfGraphID > 0) { //this graph is a replica, the real graph row needs to be copied instead
            graphMapOrReferencedGraphMap = tblRefMapOfMaps.get(graphReplicaOfGraphID); //undefned if the reference row graph does not exist
          }
          else { //this graph is a real graph, copy it exactly (changing the pageID and the created by user fields)
            graphMapOrReferencedGraphMap = graphMap;
          }

          if(graphMapOrReferencedGraphMap !== undefined) {
            var graphValuesArray = [];
            for(let graphFieldName of graphFieldNamesArray) {
              var copiedGraphValue = graphMapOrReferencedGraphMap.get(graphFieldName);
              if(graphFieldName === "page_id") {
                copiedGraphValue = newlyInsertedPageID;
              }
              else if(graphFieldName === "created_by_user_id") {
                copiedGraphValue = UserMobx.o_userID;
              }
              else if(graphFieldName === "public_01") {
                copiedGraphValue = 0;
              }
              graphValuesArray.push(copiedGraphValue);
            }

            //insert this new graph row into the graphs tbl for this newly copied page
            C_CallPhpTblUIDCopiedPageGraphs.add_insert(graphsTblName, graphFieldNamesArray, graphValuesArray, graphIdsbArray);
          }
        }
      }

      C_CallPhpTblUIDCopiedPageGraphs.execute();

      this.a_graphs_set_selected_page_id(newlyInsertedPageID);
    }
    C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

    C_CallPhpTblUID.execute();
  }

  a_graphs_create_new_graph() {
    if(JSFUNC.is_number(this.c_graphsSelectedPageID) && this.c_graphsSelectedPageID > 0) {
      this.a_graphs_set_creating_new_graph_tf(true);

      const newGraphSort = (this.c_graphsSelectedTabAllGraphsArrayOfObjs.length + 1);

      var fieldNamesArray = undefined;
      var valuesArray = undefined;
      var idsbArray = undefined;
      if(this.c_tabDailySnapshotTrueTrendAnalyzerFalse) {
        fieldNamesArray = this.graphs_daily_snapshot_graphs_field_names_array();
        valuesArray = [
          this.c_graphsSelectedPageID, newGraphSort, UserMobx.o_userID, 0, -1,
          -1, -1, 1, "", 1,
          0, 1, 0, -2, 0, 
          ""
        ];
        idsbArray = this.graphs_daily_snapshot_graphs_idsb_array();
      }
      else {
        fieldNamesArray = this.graphs_trend_analyzer_graphs_field_names_array();
        valuesArray = [
          this.c_graphsSelectedPageID, newGraphSort, UserMobx.o_userID, 0, -1,
          -1, -1, 0, -1, 1,
          "", 1, 0, 1, 0, 
          -2, 1, "yearly", 0, ""
        ];
        idsbArray = this.graphs_trend_analyzer_graphs_idsb_array();
      }

      const jsDescription = JSFUNC.js_description_from_action("DivexecMobx", "a_graphs_create_new_graph", [], []);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      C_CallPhpTblUID.add_insert(this.c_graphsSelectedTabGraphsTblName, fieldNamesArray, valuesArray, idsbArray);

      const functionOnSuccess = (i_parseResponse) => {
        const newlyInsertedGraphID = i_parseResponse.outputObj.i0;
        this.a_graphs_set_editing_graph_id(newlyInsertedGraphID);
      }
      C_CallPhpTblUID.add_function("onSuccess", functionOnSuccess);

      const functionOnFinish = (i_parseResponse) => {
        this.a_graphs_set_creating_new_graph_tf(false);
      }
      C_CallPhpTblUID.add_function("onFinish", functionOnFinish);

      C_CallPhpTblUID.execute();
    }
  }

  a_graphs_add_public_graph_as_replica(i_selectedPublicGraphDataObj) {
    if(JSFUNC.is_number(this.c_graphsSelectedPageID) && this.c_graphsSelectedPageID > 0) {
      this.a_graphs_set_creating_new_graph_tf(true);

      const newGraphSort = (this.c_graphsSelectedTabAllGraphsArrayOfObjs.length + 1);
      const publicGraphOriginalRowID = i_selectedPublicGraphDataObj.graphObj.id;

      var fieldNamesArray = undefined;
      var valuesArray = undefined;
      var idsbArray = undefined;
      if(this.c_tabDailySnapshotTrueTrendAnalyzerFalse) {
        fieldNamesArray = this.graphs_daily_snapshot_graphs_field_names_array();
        valuesArray = [
          this.c_graphsSelectedPageID, newGraphSort, -1, -1, publicGraphOriginalRowID,
          -1, -1, -1, "", -1,
          -1, -1, -2, -1, ""
        ];
        idsbArray = this.graphs_daily_snapshot_graphs_idsb_array();
      }
      else {
        fieldNamesArray = this.graphs_trend_analyzer_graphs_field_names_array();
        valuesArray = [
          this.c_graphsSelectedPageID, newGraphSort, -1, -1, publicGraphOriginalRowID,
          -1, -1, -1, -1, -1,
          "", 1, -1, -1, -2, 
          -1, "", -1, ""
        ];
        idsbArray = this.graphs_trend_analyzer_graphs_idsb_array();
      }

      const jsDescription = JSFUNC.js_description_from_action("DivexecMobx", "a_graphs_add_public_graph_as_replica", [], []);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

      C_CallPhpTblUID.add_insert(this.c_graphsSelectedTabGraphsTblName, fieldNamesArray, valuesArray, idsbArray);

      const functionOnFinish = (i_parseResponse) => {
        this.a_graphs_set_creating_new_graph_tf(false);
      }
      C_CallPhpTblUID.add_function("onFinish", functionOnFinish);

      C_CallPhpTblUID.execute();
    }
  }

  a_graphs_set_creating_new_graph_tf(i_newValueTF) {
    this.o_graphsCreatingNewGraphTF = i_newValueTF;
  }

  a_graphs_set_editing_graph_id(i_editingGraphID) {
    this.o_graphsEditingGraphID = i_editingGraphID;
  }

  a_graphs_update_page_field(i_pageID, i_fieldDbName, i_newValue, i_fieldIdsb) {
    const jsDescription = JSFUNC.js_description_from_action("DivexecMobx", "a_graphs_update_page_field", ["i_pageID", "i_fieldDbName", "i_newValue", "i_fieldIdsb"], [i_pageID, i_fieldDbName, i_newValue, i_fieldIdsb]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update(this.c_graphsSelectedTabPagesTblName, i_pageID, i_fieldDbName, i_newValue, i_fieldIdsb);
    C_CallPhpTblUID.execute();
  }

  a_graphs_update_graph_field(i_editingGraphID, i_fieldDbName, i_newValue, i_fieldIdsb) {
    const jsDescription = JSFUNC.js_description_from_action("DivexecMobx", "a_graphs_update_graph_field", ["i_editingGraphID", "i_fieldDbName", "i_newValue", "i_fieldIdsb"], [i_editingGraphID, i_fieldDbName, i_newValue, i_fieldIdsb]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_update(this.c_graphsSelectedTabGraphsTblName, i_editingGraphID, i_fieldDbName, i_newValue, i_fieldIdsb);
    C_CallPhpTblUID.execute();
  }

  a_graphs_copy_graph(i_graphDataObj, i_newCopiedGraphTitle) {
    var graphsTblName = undefined;
    var fieldNamesArray = undefined;
    var idsbArray = undefined;
    if(i_graphDataObj.circleGraphTrueTimeGraphFalse) {
      graphsTblName = "tbl_d_daily_snapshot_graphs";
      fieldNamesArray = this.graphs_daily_snapshot_graphs_field_names_array();
      idsbArray = this.graphs_daily_snapshot_graphs_idsb_array();
    }
    else {
      graphsTblName = "tbl_d_trend_analyzer_graphs";
      fieldNamesArray = this.graphs_trend_analyzer_graphs_field_names_array();
      idsbArray = this.graphs_trend_analyzer_graphs_idsb_array();
    }

    //if the graph being copied is a replica of a different public graph, try to load that graph so that this copy is editable by this user and is no longer a replica
    var referencedGraphMap = undefined; //undefined for non-replica graphs
    if(i_graphDataObj.graphObj.replica_of_graph_id > 0) { //this graph is a replica
      if(i_graphDataObj.circleGraphTrueTimeGraphFalse) {
        referencedGraphMap = DatabaseMobx.o_tbl_d_daily_snapshot_graphs.get(i_graphDataObj.graphObj.replica_of_graph_id);
      }
      else {
        referencedGraphMap = DatabaseMobx.o_tbl_d_trend_analyzer_graphs.get(i_graphDataObj.graphObj.replica_of_graph_id);
      }
    }

    //get all values to copy from i_graphDataObj looping over all graph tbl fields
    var valuesArray = [];
    for(let fieldName of fieldNamesArray) {
      var copyValue = i_graphDataObj.graphObj[fieldName];
      if(fieldName === "graph_title") {
        copyValue = i_newCopiedGraphTitle; //use the new graph title
      }
      else if(fieldName === "sort") {
        copyValue = i_graphDataObj.graphObj.sort; //make the new graph sort the previous sort, which resorts to be 1 space after the original
      }
      else if(fieldName === "page_id") {
        copyValue = i_graphDataObj.graphObj.page_id; //use the same pageID
      }
      else if(fieldName === "created_by_user_id") {
        copyValue = UserMobx.o_userID; //change creator of this graph to this user
      }
      else if(fieldName === "public_01") {
        copyValue = 0; //set this copied graph to private
      }
      else if(fieldName === "replica_of_graph_id") {
        copyValue = -1; //set this as a new graph, not a replica
      }
      else {
        if(referencedGraphMap !== undefined) { //this is a replica graph referencing a public graph that exists
          copyValue = referencedGraphMap.get(fieldName);
        }
      }
      valuesArray.push(copyValue);
    }

    const resortSortColumnName = "sort";
    const resortFilterFieldNameOrFieldNamesArray = "page_id";
    const resortFilterValueOrValuesArray = i_graphDataObj.graphObj.page_id;

    const jsDescription = JSFUNC.js_description_from_action("DivexecMobx", "a_graphs_copy_graph", ["i_graphDataObj", "i_newCopiedGraphTitle"], [i_graphDataObj, i_newCopiedGraphTitle]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
    C_CallPhpTblUID.add_insert(graphsTblName, fieldNamesArray, valuesArray, idsbArray, resortSortColumnName, resortFilterFieldNameOrFieldNamesArray, resortFilterValueOrValuesArray);
    C_CallPhpTblUID.execute();
  }

  a_graphs_delete_graph(i_graphDataObj) {
    const jsDescription = JSFUNC.js_description_from_action("DivexecMobx", "a_graphs_delete_graph", ["i_graphDataObj"], [i_graphDataObj]);
    const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);

    //delete this graph
    const graphsTblName = this.c_graphsSelectedTabGraphsTblName;
    const graphRowID = i_graphDataObj.graphObj.id;
    var resortSortColumnName = "sort";
    var resortFilterFieldNameOrFieldNamesArray = "page_id";
    var resortFilterValueOrValuesArray = i_graphDataObj.graphObj.page_id;
    C_CallPhpTblUID.add_delete(graphsTblName, graphRowID, resortSortColumnName, resortFilterFieldNameOrFieldNamesArray, resortFilterValueOrValuesArray);

    //delete any graphs that are replicas using this graph as a reference
    const tblRefMapOfMaps = DatabaseMobx.tbl_ref_from_tbl_name(graphsTblName);
    for(let graphMap of tblRefMapOfMaps.values()) {
      if(graphMap.get("replica_of_graph_id") === graphRowID) {
        var resortSortColumnName = "sort";
        var resortFilterFieldNameOrFieldNamesArray = "page_id";
        var resortFilterValueOrValuesArray = graphMap.get("page_id");
        C_CallPhpTblUID.add_delete(graphsTblName, graphMap.get("id"), resortSortColumnName, resortFilterFieldNameOrFieldNamesArray, resortFilterValueOrValuesArray);
      }
    }

    C_CallPhpTblUID.execute();
  }

  a_graphs_move_graph_to_end_of_different_page_resort_original_page(i_draggedGraphID, i_droppedOnPageObj) {
    //find the dragged graph's original pageID
    const graphsTblName = this.c_graphsSelectedTabGraphsTblName;
    const tblRefMapOfMaps = DatabaseMobx.tbl_ref_from_tbl_name(graphsTblName);
    const draggedGraphMap = tblRefMapOfMaps.get(i_draggedGraphID);
    if(draggedGraphMap !== undefined) {
      const draggedGraphPageID = draggedGraphMap.get("page_id");

      //compute the new sort position of this graph on it's new page
      var newGraphSortNum = 1;
      for(let graphMap of tblRefMapOfMaps.values()) {
        if(graphMap.get("page_id") === i_droppedOnPageObj.id) {
          newGraphSortNum++;
        }
      }

      //change the page and sort of this graph row, resort the graphs from the original page
      const jsDescription = JSFUNC.js_description_from_action("DivexecMobx", "a_graphs_move_graph_to_end_of_different_page_resort_original_page", ["i_draggedGraphID", "i_droppedOnPageObj"], [i_draggedGraphID, i_droppedOnPageObj]);
      const C_CallPhpTblUID = new JSPHP.ClassCallPhpTblUID(jsDescription);
      C_CallPhpTblUID.add_update(graphsTblName, i_draggedGraphID, ["page_id", "sort"], [i_droppedOnPageObj.id, newGraphSortNum], ["i", "i"]);

      C_CallPhpTblUID.add_resort(graphsTblName, "sort", ["page_id"], [draggedGraphPageID]);

      C_CallPhpTblUID.execute();
    }
  }

  graphs_daily_snapshot_page_field_names_array() {
    return(["user_id", "sort", "created_by_user_id", "public_01", "replica_of_page_id", "name", "num_graphs_per_row", "graph_height_em"]);
  }

  graphs_daily_snapshot_page_idsb_array() {
    return(["i", "i", "i", "i", "i", "s", "i", "i"]);
  }

  graphs_trend_analyzer_page_field_names_array() {
    return(["user_id", "sort", "created_by_user_id", "public_01", "replica_of_page_id", "name", "num_graphs_per_row", "graph_height_em", "start_date", "end_date"]);
  }

  graphs_trend_analyzer_page_idsb_array() {
    return(["i", "i", "i", "i", "i", "s", "i", "i", "s", "s"]);
  }

  graphs_daily_snapshot_graphs_field_names_array() {
    return([
      "page_id", "sort", "created_by_user_id", "public_01", "replica_of_graph_id",
      "filter_preset_id", "categories_field_id", "all_categories_01", "categories_option_ids_comma", "categories_include_not_set_01",
      "graph_type_p0_vb1_hb2_f3", "legend_01", "legend_hide_zero_categories_01", "num_captures_m2_or_money_field_id", "y_axis_log_01", 
      "graph_title"
    ]);
  }

  graphs_daily_snapshot_graphs_idsb_array() {
    return([
      "i", "i", "i", "i", "i",
      "i", "i", "i", "s", "i",
      "i", "i", "i", "i", "i", 
      "s"
    ]);
  }

  graphs_trend_analyzer_graphs_field_names_array() {
    return([
      "page_id", "sort", "created_by_user_id", "public_01", "replica_of_graph_id",
      "filter_preset_id", "date_field_id", "use_categories_field_01", "categories_field_id", "all_categories_01",
      "categories_option_ids_comma", "categories_include_not_set_01", "line0_bar1", "legend_01", "legend_hide_zero_categories_01", 
      "num_captures_m2_or_money_field_id", "total1_cumutotal2_ratio3", "time_bins", "y_axis_log_01", "graph_title"
    ]);
  }

  graphs_trend_analyzer_graphs_idsb_array() {
    return([
      "i", "i", "i", "i", "i",
      "i", "i", "i", "i", "i",
      "s", "i", "i", "i", "i", 
      "i", "i", "s", "i", "s"
    ]);
  }






  //financial projections
  a_finproj_set_max_num_captures(i_maxNumCaptures) {
    this.o_finProjMaxNumCaptures = i_maxNumCaptures;
  }






  //hot bits
  a_hot_bits_set_selected_capture_field_id(i_captureFieldID) {
    this.o_hotBitsSelectedCaptureFieldIDOrUndefned = i_captureFieldID;
  }






  //critical thresholds
  a_critical_thresholds_set_selected_filter_capture_type_id(i_captureTypeID) {
    this.o_criticalThresholdsSelectedFilterCaptureTypeIDOrUndefned = i_captureTypeID;
  }

  a_critical_thresholds_set_selected_graph_string(i_graphString) {
    this.o_criticalThresholdsSelectedGraphString = i_graphString;
  }





  //stage flow
  a_stage_flow_load_thin_log_stages() {
    const jsDescription = JSFUNC.js_description_from_action("DivexecMobx", "a_stage_flow_load_thin_log_stages", [], []);
    const C_CallPhpScript = new JSPHP.ClassCallPhpScript("loadStageFlowThinTblCLogStages", jsDescription);
    C_CallPhpScript.add_return_vars("thinTblCLogStagesArrayOfObjs");

    const functionOnSuccess = (i_parseResponse) => {
      this.a_stage_flow_set_thin_tbl_c_log_stages_arrayOfObjs(i_parseResponse.thinTblCLogStagesArrayOfObjs);
    }
    C_CallPhpScript.add_function("onSuccess", functionOnSuccess);

    C_CallPhpScript.execute();
  }

  a_stage_flow_set_thin_tbl_c_log_stages_arrayOfObjs(i_thinTblCLogStagesArrayOfObjs) {
    this.o_stageFlowThinTblCLogStagesArrayOfObjs = i_thinTblCLogStagesArrayOfObjs;
  }

  a_stage_flow_set_selected_filter_capture_type_id(i_captureTypeID) {
    this.o_stageFlowSelectedFilterCaptureTypeIDOrUndefined = i_captureTypeID;
  }

  a_stage_flow_set_selected_filter_stage_type_string(i_stageTypeString) {
    this.o_stageFlowSelectedFilterStageTypeString = i_stageTypeString;
  }


}
export default new DivexecMobx();
