//js code packages
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

//generic components and js functions
import * as LibraryReact from "../../Library/LibraryReact.js";
import * as JSFUNC from "../../Library/JSFUNC.js";

import * as CEGeneralReact from "../../CaptureExecGeneral/CEGeneralReact.js";

import * as JSPHP from "../../CaptureExecLocalDatabaseMobx/JSPHP.js";

import * as CapturesReact from "../Captures/CapturesReact.js";


//Divexec Capture Filtering
export const DivexecCaptureFilteringPanel = inject("DivexecMobx", "DatabaseMobx", "UserMobx")(observer(
class DivexecCaptureFilteringPanel extends Component {
  onclick_finproj_custom_filters = () => {
    this.props.UserMobx.a_update_user_field("divexec_finproj_custom0_manual1", 0, "i");
  }

  onclick_finproj_manual_fitler = () => {
    this.props.UserMobx.a_update_user_field("divexec_finproj_custom0_manual1", 1, "i");
  }

  onchange_custom_filter_preset = (i_newValue) => {
    this.props.UserMobx.a_update_user_field("divexec_finproj_filter_preset_id", i_newValue, "i");
  }

  onchange_manual_filter_divisions = (i_newValue) => {
    this.props.UserMobx.a_update_user_field("divexec_finproj_division_ids_comma", i_newValue, "s");
  }

  onchange_manual_filter_stages = (i_newValue) => {
    this.props.UserMobx.a_update_user_field("divexec_finproj_stage_ids_comma", i_newValue, "s");
  }

  render() {
    const finProjCustom0Manual1 = this.props.UserMobx.c_combinedUserObj.divexec_finproj_custom0_manual1;
    const finProjFilterPresetID = this.props.UserMobx.c_combinedUserObj.divexec_finproj_filter_preset_id;
    const finProjDivisionIDsComma = this.props.UserMobx.c_combinedUserObj.divexec_finproj_division_ids_comma;
    const finProjStageIDsComma = this.props.UserMobx.c_combinedUserObj.divexec_finproj_stage_ids_comma;

    const filterButtonWidth = "16em";

    return(
      <div className="flex11a displayFlexRow">
        <div className="flex00a displayFlexColumnHcVc lrMedPad borderR1ddd">
          <DivExecChangeViewButton p_label="Custom Filter Preset" p_width={filterButtonWidth} p_isSelectedTF={(finProjCustom0Manual1 === 0)} f_onClick={this.onclick_finproj_custom_filters} />
          <DivExecChangeViewButton p_label="Manual Filter Divisions/Stages" p_width={filterButtonWidth} p_isSelectedTF={(finProjCustom0Manual1 === 1)} f_onClick={this.onclick_finproj_manual_fitler} />
        </div>
        {(finProjCustom0Manual1 === 0) ? (
          <>
            <div className="flex00a displayFlexRowVc lrMedPad">
              <CapturesReact.MasterPresetsEditorButton
                p_selectedPresetType="filter"
                p_selectedPresetID={finProjFilterPresetID}
              />
            </div>
            <div className="flex11a displayFlexRowVc rMargin" style={{minWidth:"35em"}}>
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString="divexecFinProjFilter"
                p_fieldDisplayName="Capture Filter Preset"
                p_fieldTypeObj={this.props.DivexecMobx.c_graphsSelectFilterPresetFieldTypeObj}
                p_valueRaw={finProjFilterPresetID}
                p_valueIsEditableTFU={true}
                p_containerClass="width100"
                p_fieldClass="fontBlue"
                p_fieldWidth="10em"
                f_onSaveChanged={this.onchange_custom_filter_preset}
              />
            </div>
          </>
        ) : (
          <div className="flex11a displayFlexColumn lrMedPad" style={{minWidth:"35em"}}>
            <div className="flex11a displayFlexColumnVc" style={{flexBasis:"100em"}}>
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString="divexecFinProjManualFilterDivisions"
                p_fieldDisplayName={"Filtered Division(s) Selected:"}
                p_fieldTypeObj={this.props.DatabaseMobx.c_selectMultiDivisionsFieldTypeObj}
                p_valueRaw={finProjDivisionIDsComma}
                p_valueIsEditableTFU={true}
                p_valuesToNotIncludeArray={undefined}
                p_containerClass="tbMicroMargin"
                p_fieldClass="fontBlue"
                p_fieldWidth="13em"
                p_valueMaxHeight="2.6em"
                p_fieldValueVerticallyAlignedTF={false}
                f_onSaveChanged={this.onchange_manual_filter_divisions}
              />
            </div>
            <div className="flex11a displayFlexColumnVc" style={{flexBasis:"100em"}}>
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString="divexecFinProjManualFilterStages"
                p_fieldDisplayName="Filtered Stage(s) Selected:"
                p_fieldTypeObj={this.props.DatabaseMobx.c_selectMultiStagesFieldTypeObj}
                p_valueRaw={finProjStageIDsComma}
                p_valueIsEditableTFU={true}
                p_valuesToNotIncludeArray={undefined}
                p_containerClass="tbMicroMargin"
                p_fieldClass="fontBlue"
                p_fieldWidth="13em"
                p_valueMaxHeight="2.6em"
                p_fieldValueVerticallyAlignedTF={false}
                f_onSaveChanged={this.onchange_manual_filter_stages}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}));


const DivExecChangeViewButton = inject("CaptureExecMobx")(observer(
class DivExecChangeViewButton extends Component { //props: p_label, p_width, p_isSelectedTF, f_onClick
  render() {
    const p_label = this.props.p_label;
    const p_width = this.props.p_width
    const p_isSelectedTF = this.props.p_isSelectedTF;

    const c_productStylingObj = this.props.CaptureExecMobx.c_productStylingObj;

    return(
      <div
        className={"displayFlexColumnHcVc tbMicroMargin textCenter " + ((p_isSelectedTF) ? (c_productStylingObj.capturesTabSubTabButtonSelectedBgClass) : (c_productStylingObj.capturesTabSubTabButtonUnselectedBgClass + " cursorPointer"))}
        style={{width:p_width, height:"1.4em"}}
        onClick={((p_isSelectedTF) ? (undefined) : (this.props.f_onClick))}>
        <font className={((p_isSelectedTF) ? (c_productStylingObj.capturesTabSubTabButtonSelectedFontClass) : (c_productStylingObj.capturesTabSubTabButtonUnselectedFontClass))}>
          {p_label}
        </font>
      </div>
    );
  }
}));


const DivexecEditUserMoneyField = inject("DivexecMobx", "DatabaseMobx", "UserMobx")(observer(
class DivexecEditUserMoneyField extends Component { //props: p_userFieldDbName, p_valuesToNotIncludeArray
  componentDidMount() {
    const userFieldDbName = this.props.p_userFieldDbName;
    const valuesToNotIncludeArray = this.props.p_valuesToNotIncludeArray;

    //if the user has an option selected that is not available in their pull down list of fields for their goals, assign them the first available one
    if(JSFUNC.is_array(valuesToNotIncludeArray) && (valuesToNotIncludeArray.length > 0)) {
      const performanceUserSelectedMoneyExpandedCaptureFieldMapOrUndefined = this.props.DivexecMobx.c_performanceUserSelectedMoneyExpandedCaptureFieldMapOrUndefined;
      if(performanceUserSelectedMoneyExpandedCaptureFieldMapOrUndefined !== undefined) { //if a field is selected
        if(JSFUNC.in_array(performanceUserSelectedMoneyExpandedCaptureFieldMapOrUndefined.get("id"), valuesToNotIncludeArray)) { //if the selected field is one of the fields to not include
          const selectCaptureRevenueCardMoneyFieldFieldTypeObj = this.props.DatabaseMobx.c_selectCaptureRevenueCardMoneyFieldFieldTypeObj;
          if(selectCaptureRevenueCardMoneyFieldFieldTypeObj.selectWithSearchDataObj !== undefined) {
            if(JSFUNC.is_array(selectCaptureRevenueCardMoneyFieldFieldTypeObj.selectWithSearchDataObj.valueArray)) {
              for(let revenueCardMoneyFieldID of selectCaptureRevenueCardMoneyFieldFieldTypeObj.selectWithSearchDataObj.valueArray) {
                if(!JSFUNC.in_array(revenueCardMoneyFieldID, valuesToNotIncludeArray)) { //if this money field is  one of the valid ones listed as a choice for the user to select
                  this.props.UserMobx.a_update_user_field(userFieldDbName, revenueCardMoneyFieldID, "i");
                  break;
                }
              }
            }
          }
        }
      }
    }
  }

  onselect_finproj_money_field = (i_newValue) => {
    const userFieldDbName = this.props.p_userFieldDbName;
    this.props.UserMobx.a_update_user_field(userFieldDbName, i_newValue, "i");
  }

  render() {
    const userFieldDbName = this.props.p_userFieldDbName;
    const valuesToNotIncludeArray = this.props.p_valuesToNotIncludeArray;

    const moneyFieldID = this.props.UserMobx.c_combinedUserObj[userFieldDbName];

    return(
      <div style={{width:"25em"}}>
        <div className="microBottomMargin">
          <font className="fontItalic fontTextLight">
            {"Select Capture $ Value Field to use"}
          </font>
        </div>
        <CEGeneralReact.CaptureExecFieldEditSaveCancel
          p_ceEditItemString={"divexecUserMoneyField_" + userFieldDbName}
          p_fieldTypeObj={this.props.DatabaseMobx.c_selectCaptureRevenueCardMoneyFieldFieldTypeObj}
          p_valueRaw={moneyFieldID}
          p_valueIsEditableTFU="true"
          p_valuesToNotIncludeArray={valuesToNotIncludeArray}
          f_onSaveChanged={this.onselect_finproj_money_field}
        />
      </div>
    );
  }
}));




//=============================================================================================================================================================================
//=============================================================================================================================================================================

//Performance
export const Performance = inject("CaptureExecMobx", "DivexecMobx", "UserMobx")(observer(
class Performance extends Component {
  componentDidMount() {
    this.props.DivexecMobx.a_performance_set_selected_user_or_division_id(false, this.props.UserMobx.c_combinedUserObj.division_id);
  }

  render() {
    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const performanceMobileScreen = this.props.DivexecMobx.o_performanceMobileScreen;

    if(c_isMobileOrTabletTF) {
      if(performanceMobileScreen === "graphs") {
        return(
          <PerformanceAllGraphs p_divexecPerformanceTrueUserMyPerformanceFalse={true} />
        );
      }
      else {
        return(
          <PerformanceDivisionsAndUsersList />
        );
      }
    }

    return(
      <div className="flex11a displayFlexRow">
        <div className="flex00a displayFlexColumn" style={{flexBasis:"25em", borderRight:"solid 1px #9b9"}}>
          <PerformanceDivisionsAndUsersList />
        </div>
        <div className="flex11a displayFlexColumn">
          <PerformanceAllGraphs p_divexecPerformanceTrueUserMyPerformanceFalse={true} />
        </div>
      </div>
    );
  }
}));

const PerformanceDivisionsAndUsersList = inject("DivexecMobx")(observer(
class PerformanceDivisionsAndUsersList extends Component {
  render() {
    const performanceDivisionsArrayOfObjs = this.props.DivexecMobx.c_performanceDivisionsArrayOfObjs;
    return(
      <>
        <div className="flex11a displayFlexColumn bgDarkGreen" style={{flexBasis:"100em"}}>
          <div className="flex00a smallFullPad bgDarkGreenGradient" style={{borderBottom:"solid 1px #ddd"}}>
            <LibraryReact.Nowrap p_fontClass="font13 fontItalic fontWhite">
              {"Divisions/Users"}
            </LibraryReact.Nowrap>
          </div>
          <div className="flex11a yScroll yScrollBottomPad">
            {performanceDivisionsArrayOfObjs.map((m_divisionObj) =>
              <PerformanceDivisionRow
                key={m_divisionObj.id}
                p_divisionObj={m_divisionObj}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}));

const PerformanceDivisionRow = inject("DivexecMobx")(observer(
class PerformanceDivisionRow extends Component { //props: p_divisionObj
  onclick_division = () => {
    this.props.DivexecMobx.a_performance_set_selected_user_or_division_id(false, this.props.p_divisionObj.id);
  }

  render() {
    const divisionObj = this.props.p_divisionObj;

    const selectedUserTrueDivisionFalse = this.props.DivexecMobx.o_performanceSelectedUserTrueDivisionFalse;
    const selectedUserOrDivisionID = this.props.DivexecMobx.o_performanceSelectedUserOrDivisionID;

    const isSelectedTF = (!selectedUserTrueDivisionFalse && (divisionObj.id === selectedUserOrDivisionID));

    return(
      <div className="displayFlexRow">
        {divisionObj.indentsArray.map((m_indent) =>
          <div key={m_indent} className="flex00a" style={{flexBasis:"1em", borderLeft:"solid 1px #ddd", marginLeft:"1em"}} />
        )}
        <div className="flex11a bgLightGray tbMicroPad lrPad border bevelBorderColors">
          <div
            className={"flex11a displayFlexRow border bevelBorderColors " + ((isSelectedTF) ? ("bgBlueGradient") : ("bgGrayGradient hoverLightBlueGradient cursorPointer"))}
            style={{height:"2.5em", borderRadius:"0.5em"}}
            onClick={this.onclick_division}>
            <div className="flex00a smallFullMargin border bevelBorderColors" style={{flexBasis:"1em", background:"#" + divisionObj.color}} />
            <div className="flex11a displayFlexRowVc lrPad">
              <LibraryReact.Nowrap p_fontClass={((isSelectedTF) ? ("fontWhite") : (""))}>
                {divisionObj.name}
              </LibraryReact.Nowrap>
            </div>
          </div>
          <div style={{marginLeft:"1.5em", marginRight:"0.5em"}}>
            {divisionObj.usersArrayOfObjs.map((m_userObj) =>
              <PerformanceUserRow
                key={m_userObj.id}
                p_userObj={m_userObj}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}));

const PerformanceUserRow = inject("DivexecMobx")(observer(
class PerformanceUserRow extends Component { //props: p_userObj
  onclick_user = () => {
    this.props.DivexecMobx.a_performance_set_selected_user_or_division_id(true, this.props.p_userObj.user_id);
  }

  render() {
    const userObj = this.props.p_userObj;

    const selectedUserTrueDivisionFalse = this.props.DivexecMobx.o_performanceSelectedUserTrueDivisionFalse;
    const selectedUserOrDivisionID = this.props.DivexecMobx.o_performanceSelectedUserOrDivisionID;

    const isSelectedTF = (selectedUserTrueDivisionFalse && (userObj.user_id === selectedUserOrDivisionID));

    return(
      <div
        className={"displayFlexRow border bevelBorderColors " + ((isSelectedTF) ? ("bgBlueGradient") : ("bgUserTan hoverLightBlueGradient cursorPointer"))}
        style={{height:"1.6em", borderRadius:"0.8em"}}
        onClick={this.onclick_user}>
        <div className="flex11a displayFlexRowVc lrPad">
          <LibraryReact.Nowrap p_fontClass={((isSelectedTF) ? ("fontWhite") : ("fontTextLight"))}>
            {userObj.fullName}
          </LibraryReact.Nowrap>
        </div>
      </div>
    );
  }
}));

export const PerformanceAllGraphs = inject("DivexecMobx", "UserMobx")(observer(
class PerformanceAllGraphs extends Component { //props: p_divexecPerformanceTrueUserMyPerformanceFalse
  render() {
    const p_divexecPerformanceTrueUserMyPerformanceFalse = this.props.p_divexecPerformanceTrueUserMyPerformanceFalse;

    const selectedUserTrueDivisionFalse = this.props.DivexecMobx.o_performanceSelectedUserTrueDivisionFalse;
    const selectedUserOrDivisionID = this.props.DivexecMobx.o_performanceSelectedUserOrDivisionID;
    const userDivisionFirewalledDivisionIDsCommaOrAllm1 = this.props.UserMobx.c_userDivisionFirewalledDivisionIDsCommaOrAllm1;
    const userDivisionFirewalledDivisionIDsArray = this.props.UserMobx.c_userDivisionFirewalledDivisionIDsArray;

    if((selectedUserTrueDivisionFalse === undefined) || (selectedUserOrDivisionID === undefined)) {
      return(
        <CEGeneralReact.EmptyScreenGray>
          {"Select a User or Division from the green listing on the left ('Divisions/Users') to view their performance and quotas"}
        </CEGeneralReact.EmptyScreenGray>
      );
    }

    if(!selectedUserTrueDivisionFalse && (userDivisionFirewalledDivisionIDsCommaOrAllm1 !== "-1") && !JSFUNC.in_array(selectedUserOrDivisionID, userDivisionFirewalledDivisionIDsArray)) {
      return(
        <CEGeneralReact.EmptyScreenGray>
          {"Your admin has not given you firewall permissions to view Captures in this Division"}
        </CEGeneralReact.EmptyScreenGray>
      );
    }

    return(
      <div className="flex11a displayFlexColumn yScroll yScrollBottomPad">
        <div className="flex00a" style={{borderBottom:"solid 2px #bbb"}}>
          <PerformanceQuotaGraphAndControls p_divexecPerformanceTrueUserMyPerformanceFalse={p_divexecPerformanceTrueUserMyPerformanceFalse} />
        </div>
        <div className="flex11a displayFlexColumn">
          <PerformanceTiledGraphs />
        </div>
        <PerformanceQuotaEditingFyrFloatingBox p_divexecPerformanceTrueUserMyPerformanceFalse={p_divexecPerformanceTrueUserMyPerformanceFalse} />
      </div>
    );
  }
}));

const PerformanceQuotaGraphAndControls = inject("CaptureExecMobx", "DivexecMobx", "DatabaseMobx", "UserMobx")(observer(
class PerformanceQuotaGraphAndControls extends Component { //props: p_divexecPerformanceTrueUserMyPerformanceFalse
  onclick_mobile_go_to_user_division_screen = () => {
    this.props.DivexecMobx.a_performance_set_mobile_screen("selectUserOrDivision");
  }

  onclick_add_edit_goal_select_year = () => {
    this.props.DivexecMobx.a_performance_set_editing_fyr(-1); //-1 flag to first open a floating box to pick which year to edit the goals for
  }

  render() {
    const p_divexecPerformanceTrueUserMyPerformanceFalse = this.props.p_divexecPerformanceTrueUserMyPerformanceFalse;

    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const c_performanceUserSelectedMoneyExpandedCaptureFieldMapOrUndefined = this.props.DivexecMobx.c_performanceUserSelectedMoneyExpandedCaptureFieldMapOrUndefined;
    const c_performanceQuotaObj = this.props.DivexecMobx.c_performanceQuotaObj;
    const c_myPerformanceMoneyFieldIDsToNotIncludeArray = this.props.DivexecMobx.c_myPerformanceMoneyFieldIDsToNotIncludeArray;
    const c_userCanEditDivexecPerformanceGoalsTF = this.props.UserMobx.c_userCanEditDivexecPerformanceGoalsTF;

    var myPerformanceMoneyFieldIDsToNotIncludeArray = c_myPerformanceMoneyFieldIDsToNotIncludeArray;
    if(p_divexecPerformanceTrueUserMyPerformanceFalse) {
      myPerformanceMoneyFieldIDsToNotIncludeArray = [];
    }

    const userOrDivisionString = c_performanceQuotaObj.userOrDivisionString;
    const userOrDivisionName = c_performanceQuotaObj.userOrDivisionName;
    const quotaYearsArrayOfObjs = c_performanceQuotaObj.quotaYearsArrayOfObjs;
    const numYears = c_performanceQuotaObj.numYears;
    const graphHeightPx = c_performanceQuotaObj.graphHeightPx;
    const svgWidth = c_performanceQuotaObj.svgWidth;
    const yAxisTicksArrayOfObjs = c_performanceQuotaObj.yAxisTicksArrayOfObjs;

    const xAxisHeight = "3em";
    const yAxisWidthEm = 4;

    const userOrDivisionAndNameString = userOrDivisionString + " '" + userOrDivisionName + "'";

    var goalsBarGraphComponent = null;
    if(c_performanceUserSelectedMoneyExpandedCaptureFieldMapOrUndefined === undefined) {
      goalsBarGraphComponent = (
        <div className="displayFlexColumn" style={{height:graphHeightPx + "px"}}>
          <CEGeneralReact.EmptyScreenGray>
            {"You must select a Capture $ Value Field to use for setting and measuring goals"}
          </CEGeneralReact.EmptyScreenGray>
        </div>
      );
    }
    else if(numYears === 0) {
      goalsBarGraphComponent = (
        <div className="displayFlexColumn" style={{height:graphHeightPx + "px"}}>
          <CEGeneralReact.EmptyScreenGray>
            {"For " + userOrDivisionAndNameString + ", no goals have been created and no captures have been submitted, won, or lost"}
          </CEGeneralReact.EmptyScreenGray>
        </div>
      );
    }
    else {
      goalsBarGraphComponent = (
        <div className="displayFlexRow">
          <div className="flex00a displayFlexColumn xScrollAlways" style={{flexBasis:yAxisWidthEm + "em"}}>
            <LibraryReact.YAxisWithLabelsLeftColumn p_yAxisTicksArrayOfObjs={yAxisTicksArrayOfObjs} p_yAxisWidthEm={yAxisWidthEm} />
            <div className="flex00a" style={{flexBasis:xAxisHeight}} />
          </div>
          <div className="flex11a displayFlexColumn xScrollAlways" style={{height:graphHeightPx + "px"}}>
            <div className="flex11a displayFlexRow" style={{width:svgWidth}}>
              {quotaYearsArrayOfObjs.map((m_quotaYearObj) =>
                <PerformanceQuotaGraphYearBarsGroup
                  key={m_quotaYearObj.fyr}
                  p_quotaYearObj={m_quotaYearObj}
                  p_performanceQuotaObj={c_performanceQuotaObj}
                />
              )}
            </div>
            <div className="flex00a displayFlexRow" style={{flexBasis:xAxisHeight, width:svgWidth, borderTop:"solid 1px #aaa"}}>
              {quotaYearsArrayOfObjs.map((m_quotaYearObj) =>
                <div key={m_quotaYearObj.fyr} className="flex11a displayFlexColumnVc textCenter" style={{flexBasis:"100em"}}>
                  <div className="displayFlexRow">
                    <div className="flex11a displayFlexRow" style={{flexBasis:"100em"}}>
                      <LibraryReact.Nowrap p_fontClass="font11 fontItalic fontTextLighter">
                        {"Goals"}
                      </LibraryReact.Nowrap>
                    </div>
                    <div className="flex11a displayFlexRow" style={{flexBasis:"100em"}}>
                      <LibraryReact.Nowrap p_fontClass="font11 fontItalic fontTextLighter">
                        {"Actual"}
                      </LibraryReact.Nowrap>
                    </div>
                  </div>
                  <LibraryReact.Nowrap p_fontClass="font13 fontTextLight">
                    {"FY" + m_quotaYearObj.fyr}
                  </LibraryReact.Nowrap>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }

    return(
      <>
        {(p_divexecPerformanceTrueUserMyPerformanceFalse && c_isMobileOrTabletTF) &&
          <div className="displayFlexColumnHcVc smallTopPad bgLightestGray">
            <LibraryReact.ButtonNowrap
              p_value="Select Different Division/User"
              p_class="tbMicroPad lrMedPad borderRadius05 bgDarkGreenGradient hoverLightGreenGradient cursorPointer"
              p_fontClass="fontWhite"
              f_onClick={this.onclick_mobile_go_to_user_division_screen}
            />
          </div>
        }
        <div className="displayFlexRow borderB1bbb tbPad bgLightestGray">
          <div className="flex11a displayFlexRowVc lrMedPad">
            <font className="font13 fontBold fontItalic fontTextLighter">
              {"Annual Goals for " + userOrDivisionAndNameString}
            </font>
          </div>
          <div className="flex00a" style={{flexBasis:"25em"}}>
            <DivexecEditUserMoneyField
              p_userFieldDbName="divexec_performance_money_field_id"
              p_valuesToNotIncludeArray={myPerformanceMoneyFieldIDsToNotIncludeArray}
            />
          </div>
          {(c_userCanEditDivexecPerformanceGoalsTF && p_divexecPerformanceTrueUserMyPerformanceFalse) &&
            <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"12em"}}>
              {(c_performanceUserSelectedMoneyExpandedCaptureFieldMapOrUndefined !== undefined) &&
                <CEGeneralReact.CEButton
                  p_type="blue"
                  p_text="Add/Edit a Goal"
                  f_onClick={this.onclick_add_edit_goal_select_year}
                />
              }
            </div>
          }
        </div>
        <DivexecGoalsControlsRow />
        {goalsBarGraphComponent}
      </>
    );
  }
}));


const DivexecGoalsControlsRow = inject("DatabaseMobx", "UserMobx")(observer(
class DivexecGoalsControlsRow extends Component { //props: 
  onswitch_specify_years = () => {
    const c_combinedUserObj = this.props.UserMobx.c_combinedUserObj;

    var updatedGoalsLatestYear = 0;
    if(!JSFUNC.is_number_not_nan_gt_0(c_combinedUserObj.divexec_goals_latest_year)) {
      updatedGoalsLatestYear = (JSFUNC.now_year_int() + 1);
    }
    this.props.UserMobx.a_update_user_field("divexec_goals_latest_year", updatedGoalsLatestYear, "i");
  }

  onchange_divexec_goals_latest_year = (i_newValue) => {
    this.props.UserMobx.a_update_user_field("divexec_goals_latest_year", i_newValue, "i");
  }

  onchange_divexec_goals_num_years = (i_newValue) => {
    this.props.UserMobx.a_update_user_field("divexec_goals_num_years", i_newValue, "i");
  }

  render() {
    const c_combinedUserObj = this.props.UserMobx.c_combinedUserObj;

    const fieldClass = "fontItalic";
    const goalsSpecifyYearsTrueShowAllYearsFalse = JSFUNC.is_number_not_nan_gt_0(c_combinedUserObj.divexec_goals_latest_year);

    return(
      <div className="flex00a displayFlexRow flexWrap borderB1bbb bgLighterGreen" style={{minHeight:"2.2em"}}>
        <div className="flex00a displayFlexColumnVc lrMedPad" style={{flexBasis:"10em"}}>
          <font className="font11 fontBold fontTextLight">
            {"Goals Controls"}
          </font>
        </div>
        <div className="flex00a displayFlexColumnVc lrPad" style={{flexBasis:"16em"}}>
          <CEGeneralReact.SwitchWithTextAndConfirmBox
            p_isOnTF={goalsSpecifyYearsTrueShowAllYearsFalse}
            p_sizeEm={2.2}
            p_onColor="464"
            p_offColor="777"
            p_onText="Specify Years Shown"
            p_offText="Show All Years [default]"
            p_onTextFontClass="fontBold"
            p_offTextFontClass="fontBold"
            p_title="Off [default] - all years are shown in the Goals graph which include any year a Won Capture has an award date set plus any year that has a goal set\nOn - ability to show a specific set of years to reduce the graph"
            f_onSwitch={this.onswitch_specify_years}
          />
        </div>
        {(goalsSpecifyYearsTrueShowAllYearsFalse) &&
          <>
            <div className="flex00a displayFlexColumnVc lrPad" style={{flexBasis:"18em"}}>
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString="divexecGraph_divexec_goals_latest_year"
                p_fieldDisplayName="Latest Year:"
                p_fieldTypeObj={this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj}
                p_valueRaw={c_combinedUserObj.divexec_goals_latest_year}
                p_valueIsEditableTFU={true}
                p_containerClass="tbMicroMargin"
                p_fieldClass={fieldClass}
                p_fieldWidth="6em"
                p_valueClass=""
                f_onSaveChanged={this.onchange_divexec_goals_latest_year}
              />
            </div>
            <div className="flex00a displayFlexColumnVc lrPad" style={{flexBasis:"18em"}}>
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString="divexecGraph_divexec_goals_num_years"
                p_fieldDisplayName="# Years Shown:"
                p_fieldTypeObj={this.props.DatabaseMobx.c_genericIntPositiveFieldTypeObj}
                p_valueRaw={c_combinedUserObj.divexec_goals_num_years}
                p_valueIsEditableTFU={true}
                p_containerClass="tbMicroMargin"
                p_fieldClass={fieldClass}
                p_fieldWidth="7.5em"
                p_valueClass=""
                f_onSaveChanged={this.onchange_divexec_goals_num_years}
              />
            </div>
          </>
        }
        <div className="flex11a" />
      </div>
    );
  }
}));
  
  

const PerformanceQuotaGraphYearBarsGroup = inject("DivexecMobx")(observer(
class PerformanceQuotaGraphYearBarsGroup extends Component { //props: p_quotaYearObj, p_performanceQuotaObj
  onclick_bars_group = () => {
    this.props.DivexecMobx.a_performance_set_editing_fyr(this.props.p_quotaYearObj.fyr);
  }

  render() {
    const p_quotaYearObj = this.props.p_quotaYearObj;
    const p_performanceQuotaObj = this.props.p_performanceQuotaObj;

    const graphHeightPx = p_performanceQuotaObj.graphHeightPx;
    const graphMaxValue = p_performanceQuotaObj.graphMaxValue;
    const yAxisTicksArrayOfObjs = p_performanceQuotaObj.yAxisTicksArrayOfObjs;

    return(
      <div
        className="flex11a hoverLighterBlueGradient cursorPointer"
        style={{flexBasis:"100em"}}
        title={p_quotaYearObj.hoverTitle}
        onClick={this.onclick_bars_group}>
        <svg width="100%" height="100%">
          <LibraryReact.YAxisTicksHorizontalLines p_yAxisTicksArrayOfObjs={yAxisTicksArrayOfObjs} p_gridLineWidthPx={1} p_gridLineColor="#ccc" />
          {(p_quotaYearObj.quotaSubmitted > 0) &&
            <rect x="15%" y={(100 - ((p_quotaYearObj.quotaSubmitted / graphMaxValue) * 100)) + "%"} width="32.5%" height="100%" fill="#ccc" />
          }
          {(p_quotaYearObj.quotaWon > 0) &&
            <rect x="22.5%" y={(100 - ((p_quotaYearObj.quotaWon / graphMaxValue) * 100)) + "%"} width="25%" height="100%" fill="#888" />
          }
          {(p_quotaYearObj.actualSubmitted > 0) &&
            <rect x="52.5%" y={(100 - ((p_quotaYearObj.actualSubmitted / graphMaxValue) * 100)) + "%"} width="32.5%" height="100%" fill={((p_quotaYearObj.quotaSubmitted > 0 && p_quotaYearObj.quotaSubmitted > p_quotaYearObj.actualSubmitted) ? ("#d88") : ("#8d8"))} />
          }
          {(p_quotaYearObj.actualWon > 0) &&
            <rect x="60%" y={(100 - ((p_quotaYearObj.actualWon / graphMaxValue) * 100)) + "%"} width="25%" height="100%" fill={((p_quotaYearObj.quotaWon > 0 && p_quotaYearObj.quotaWon > p_quotaYearObj.actualWon) ? ("#a44") : ("#4a4"))} />
          }
        </svg>
      </div>
    );
  }
}));

const PerformanceQuotaEditingFyrFloatingBox = inject("CaptureExecMobx", "CapturesMobx", "OpenCaptureMobx", "DivexecMobx", "DatabaseMobx", "UserMobx")(observer(
class PerformanceQuotaEditingFyrFloatingBox extends Component { //props: p_divexecPerformanceTrueUserMyPerformanceFalse
  constructor(props) {
    super(props);
    this.state = {
      s_chooseFyrInt: this.props.CaptureExecMobx.c_nowYearInt
    };
  }

  input_fyr_is_valid_tf = (i_fyr) => {
    return(i_fyr >= 1000 && i_fyr <= 9999);
  }

  decremement_fyr = () => {
    this.setState({s_chooseFyrInt:(this.state.s_chooseFyrInt - 1)});
  }

  increment_fyr = () => {
    this.setState({s_chooseFyrInt:(this.state.s_chooseFyrInt + 1)});
  }

  onclick_choose_fyr = () => {
    this.props.DivexecMobx.a_performance_set_editing_fyr(this.state.s_chooseFyrInt);
  }

  onclick_close_editing_quota_floating_box = () => {
    this.props.DivexecMobx.a_performance_set_editing_fyr(undefined);
  }

  onclick_capture_open_capture = (i_captureID) => {
    this.props.OpenCaptureMobx.a_open_single_capture(i_captureID);
  }

  render() {
    const p_divexecPerformanceTrueUserMyPerformanceFalse = this.props.p_divexecPerformanceTrueUserMyPerformanceFalse;

    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const o_performanceEditingFyr = this.props.DivexecMobx.o_performanceEditingFyr;
    const c_performanceUserSelectedMoneyFieldDisplayName = this.props.DivexecMobx.c_performanceUserSelectedMoneyFieldDisplayName;
    const c_performanceQuotaObj = this.props.DivexecMobx.c_performanceQuotaObj;
    const c_fieldMapOfProposalDueDateTimeUtc = this.props.DatabaseMobx.c_fieldMapOfProposalDueDateTimeUtc;
    const c_fieldMapOfAwardDate = this.props.DatabaseMobx.c_fieldMapOfAwardDate;
    const c_userCanEditDivexecPerformanceGoalsTF = this.props.UserMobx.c_userCanEditDivexecPerformanceGoalsTF;

    if(o_performanceEditingFyr === undefined) {
      return(null);
    }

    if(o_performanceEditingFyr <= 0) {
      const fyrErrorTF = this.input_fyr_is_valid_tf(this.state.s_chooseFyrInt);
      return(
        <CEGeneralReact.FloatingBoxWithSaveCancel
          p_trblFlag="smallVertical"
          p_title="Choose a Fiscal Year to Add/Edit Goals"
          f_onClickSave={undefined}
          f_onClickCancel={this.onclick_close_editing_quota_floating_box}>
          <div className="flex11a displayFlexColumnHc medFullPad yScroll">
            <div className="displayFlexRowVc">
              <div className="flex00a">
                <CEGeneralReact.ButtonExpandCollapse
                  p_isExpandedTF={false}
                  p_sizeEm={2}
                  p_title={undefined}
                  f_onClick={this.decremement_fyr}
                />
              </div>
              <div className="flex00a lrMedMargin noSelect">
                <font className="font16 fontBold fontTextLight">
                  {this.state.s_chooseFyrInt}
                </font>
              </div>
              <div className="flex00a">
                <CEGeneralReact.ButtonExpandCollapse
                  p_isExpandedTF={true}
                  p_sizeEm={2}
                  p_title={undefined}
                  f_onClick={this.increment_fyr}
                />
              </div>
              <div className="flex11a" />
            </div>
            <div className="displayFlexRowVc medTopMargin">
              <div className="flex00a">
                <CEGeneralReact.CEButton
                  p_type="blue"
                  p_text={"Choose Year " + this.state.s_chooseFyrInt}
                  f_onClick={this.onclick_choose_fyr}
                />
              </div>
              <div className="flex00a lrMedMargin">
                <CEGeneralReact.CEButton
                  p_type="gray"
                  p_text="Cancel"
                  f_onClick={this.onclick_close_editing_quota_floating_box}
                />
              </div>
            </div>
          </div>
        </CEGeneralReact.FloatingBoxWithSaveCancel>
      );
    }

    const userOrDivisionString = c_performanceQuotaObj.userOrDivisionString;
    const userOrDivisionName = c_performanceQuotaObj.userOrDivisionName;
    const quotaYearsArrayOfObjs = c_performanceQuotaObj.quotaYearsArrayOfObjs;

    const userOrDivisionAndNameString = userOrDivisionString + " '" + userOrDivisionName + "'";

    var quotaSubmitted = 0;
    var quotaWon = 0;
    var actualSubmitted = 0;
    var actualWon = 0;
    var submittedPartialNumCaptures = 0;
    var wonPartialNumCaptures = 0;
    var submittedCaptureIDsArray = [];
    var wonCaptureIDsArray = [];
    var submittedPercentOfGoal0to100 = 100;
    var wonPercentOfGoal0to100 = 100;
    for(let quotaYearObj of quotaYearsArrayOfObjs) {
      if(quotaYearObj.fyr === o_performanceEditingFyr) {
        quotaSubmitted = quotaYearObj.quotaSubmitted;
        quotaWon = quotaYearObj.quotaWon;
        actualSubmitted = quotaYearObj.actualSubmitted;
        actualWon = quotaYearObj.actualWon;
        submittedPartialNumCaptures = quotaYearObj.submittedPartialNumCaptures;
        wonPartialNumCaptures = quotaYearObj.wonPartialNumCaptures;
        submittedCaptureIDsArray = quotaYearObj.submittedCaptureIDsArray;
        wonCaptureIDsArray = quotaYearObj.wonCaptureIDsArray;
        break;
      }
    }

    var submittedSearchResultCapturesArrayOfObjs = this.props.CapturesMobx.filtered_search_results_captures_from_capture_ids_array(submittedCaptureIDsArray);
    var proposalDueDateTimeUtcSearchFieldName = "search_fID" + c_fieldMapOfProposalDueDateTimeUtc.get("id");
    JSFUNC.sort_arrayOfObjs(submittedSearchResultCapturesArrayOfObjs, proposalDueDateTimeUtcSearchFieldName, true);
    var wonSearchResultCapturesArrayOfObjs = this.props.CapturesMobx.filtered_search_results_captures_from_capture_ids_array(wonCaptureIDsArray);
    var awardDateSearchFieldName = "search_fID" + c_fieldMapOfAwardDate.get("id");
    JSFUNC.sort_arrayOfObjs(wonSearchResultCapturesArrayOfObjs, awardDateSearchFieldName, true);

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="divexecPerformanceEditQuota"
        p_zIndex={2}
        p_title={"Edit FY" + o_performanceEditingFyr + " Goals for " + userOrDivisionAndNameString}
        f_onClickSave={((c_userCanEditDivexecPerformanceGoalsTF) ? (this.onclick_close_editing_quota_floating_box) : (undefined))}
        f_onClickCancel={((c_userCanEditDivexecPerformanceGoalsTF) ? (undefined) : (this.onclick_close_editing_quota_floating_box))}>
        <div className="flex00a displayFlexRow">
          <div className="flex00a displayFlexColumnHcVc textCenter" style={{flexBasis:"14em"}}>
            <font className="font18 fontBold fontTextLighter">
              {"Goals FY" + o_performanceEditingFyr}
            </font>
          </div>
          <div className="flex11a displayFlexColumnVc">
            <div className="microBottomMargin">
              <font className="font12 fontBold fontItalic fontTextLighter">
                {userOrDivisionAndNameString}
              </font>
            </div>
            <div className="">
              <font className="font11 fontItalic fontTextLight">
                {"Capture $ Value Field: " + c_performanceUserSelectedMoneyFieldDisplayName}
              </font>
            </div>
          </div>
        </div>
        <div className="displayFlexColumnHcVc lrMedPad">
          <div className={"flex00a " + ((c_isMobileOrTabletTF) ? ("") : ("displayFlexRow"))} style={{maxWidth:"70em"}}>
            <PerformanceEditSingleGoal
              p_canEditTF={(c_userCanEditDivexecPerformanceGoalsTF && p_divexecPerformanceTrueUserMyPerformanceFalse)}
              p_quotaFieldDbName="submitted_quota"
              p_goalLabel="Submitted"
              p_dateFieldName={c_fieldMapOfProposalDueDateTimeUtc.get("display_name")}
              p_goalStagesString="either Submitted, Won, or Lost"
              p_quota={quotaSubmitted}
              p_actual={actualSubmitted}
              p_partialNumCaptures={submittedPartialNumCaptures}
            />
            <PerformanceEditSingleGoal
              p_canEditTF={(c_userCanEditDivexecPerformanceGoalsTF && p_divexecPerformanceTrueUserMyPerformanceFalse)}
              p_quotaFieldDbName="won_quota"
              p_goalLabel="Won"
              p_dateFieldName={c_fieldMapOfAwardDate.get("display_name")}
              p_goalStagesString="Won"
              p_quota={quotaWon}
              p_actual={actualWon}
              p_partialNumCaptures={wonPartialNumCaptures}
            />
          </div>
        </div>
        <div className="flex11a xyScroll lrMedPad hugeBottomPad">
          <div className="smallTopMargin microBottomMargin">
            <font className="font13 fontBold fontTextLighter">
              {"Submitted Captures (" + submittedSearchResultCapturesArrayOfObjs.length + ")"}
            </font>
          </div>
          <CapturesReact.SearchResultsCapturesTable
            p_searchResultCapturesArrayOfObjs={submittedSearchResultCapturesArrayOfObjs}
            f_onClickCapture={this.onclick_capture_open_capture}
          />
          <div className="hugeTopMargin microBottomMargin">
            <font className="font13 fontBold fontTextLighter">
              {"Won Captures (" + wonSearchResultCapturesArrayOfObjs.length + ")"}
            </font>
          </div>
          <CapturesReact.SearchResultsCapturesTable
            p_searchResultCapturesArrayOfObjs={wonSearchResultCapturesArrayOfObjs}
            f_onClickCapture={this.onclick_capture_open_capture}
          />
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));




const PerformanceEditSingleGoal = inject("CaptureExecMobx", "DivexecMobx", "DatabaseMobx")(observer(
class PerformanceEditSingleGoal extends Component { //props: p_canEditTF, p_quotaFieldDbName, p_goalLabel, p_dateFieldName, p_goalStagesString, p_quota, p_actual, p_partialNumCaptures
  onsave_goal = (i_newValue) => {
    const p_quotaFieldDbName = this.props.p_quotaFieldDbName;
    const o_performanceEditingFyr = this.props.DivexecMobx.o_performanceEditingFyr;
    this.props.DivexecMobx.a_performance_update_or_insert_fyr_quota(o_performanceEditingFyr, p_quotaFieldDbName, i_newValue);
  }

  render() {
    const p_canEditTF = this.props.p_canEditTF;
    const p_quotaFieldDbName = this.props.p_quotaFieldDbName;
    const p_goalLabel = this.props.p_goalLabel;
    const p_dateFieldName = this.props.p_dateFieldName;
    const p_goalStagesString = this.props.p_goalStagesString;
    const p_quota = this.props.p_quota;
    const p_actual = this.props.p_actual;
    const p_partialNumCaptures = this.props.p_partialNumCaptures;

    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const c_fieldMapOfStage = this.props.DatabaseMobx.c_fieldMapOfStage;

    var percentOfGoal0to100 = 100;
    if(JSFUNC.is_number_not_nan_gt_0(p_quota)) {
      percentOfGoal0to100 = Math.floor((p_actual / p_quota) * 100);
    }

    const percentOfGoalIs100OrGreaterTF = (percentOfGoal0to100 >= 100);
    const achievedFontColorClass = ((percentOfGoalIs100OrGreaterTF) ? ("fontGreen") : ("fontLightRed"));

    return(
      <div
        className={((c_isMobileOrTabletTF) ? ("") : ("flex11a")) + " smallFullMargin tbPad lrMedPad"}
        style={{flexBasis:((c_isMobileOrTabletTF) ? (undefined) : ("100em")), border:"solid 1px #ccc"}}>
        <div className="microBottomMargin textCenter">
          <font className="font12 fontBold fontTextLighter">
            {p_goalLabel + " Goal"}
          </font>
        </div>
        <div className="microBottomMargin textCenter">
          <font className="fontItalic fontTextLighter">
            {"A Capture is considered '" + p_goalLabel + "' if the " + c_fieldMapOfStage.get("display_name") + " is " + p_goalStagesString + ". The " + p_dateFieldName + " must be filled out."}
          </font>
        </div>
        <CEGeneralReact.CaptureExecFieldEditSaveCancel
          p_ceEditItemString={"divexec" + p_quotaFieldDbName}
          p_fieldDisplayName="Goal"
          p_fieldTypeObj={this.props.DatabaseMobx.c_genericMoneyFieldTypeObj}
          p_valueRaw={p_quota}
          p_valueIsEditableTFU={p_canEditTF}
          p_containerClass="tbMargin"
          p_fieldClass="font11 fontBlue"
          p_fieldWidth="5em"
          p_valueClass="font11"
          f_onSaveChanged={this.onsave_goal}
        />
        <div className="displayFlexRowVc smallTopMargin">
          <div className="flex00a" style={{flexBasis:"7em"}}>
            <font className="font11 fontItalic fontTextLighter">
              {"Achieved:"}
            </font>
          </div>
          <div className="flex11a">
            <font className={"font11 fontBold " + achievedFontColorClass}>
              {JSFUNC.money(p_actual, 0, true)}
            </font>
          </div>
        </div>
        <div className="microTopMargin">
          <font className={"fontItalic " + achievedFontColorClass}>
            {"Achieved " + percentOfGoal0to100 + "% of Goal (" + p_partialNumCaptures + " " + JSFUNC.plural(p_partialNumCaptures, "capture", "captures") + ")"}
          </font>
        </div>
      </div>
    );
  }
}));



const PerformanceTiledGraphs = inject("CaptureExecMobx", "DivexecMobx", "UserMobx")(observer(
class PerformanceTiledGraphs extends Component {
  onselect_divexec_graph_start_date = (i_newValue) => {
    this.props.UserMobx.a_update_user_field("divexec_graphs_start_date", i_newValue, "s");
  }

  onselect_divexec_graph_end_date = (i_newValue) => {
    this.props.UserMobx.a_update_user_field("divexec_graphs_end_date", i_newValue, "s");
  }

  render() {
    const mqf = this.props.CaptureExecMobx.o_mediaQueryFlag;

    const selectedPageGraphsDataArrayOfObjs = this.props.DivexecMobx.c_performanceGraphsDataArrayOfObjs;

    const performanceStartDateRelative = this.props.UserMobx.c_combinedUserObj.divexec_graphs_start_date;
    const performanceEndDateRelative = this.props.UserMobx.c_combinedUserObj.divexec_graphs_end_date;
    const performanceStartDateFixed = JSFUNC.convert_relative_ymd_date_to_fixed_date_ymd(performanceStartDateRelative);
    const performanceEndDateFixed = JSFUNC.convert_relative_ymd_date_to_fixed_date_ymd(performanceEndDateRelative);

    var numGraphsPerRow = 1;
    var graphHeightEm = 20;
    if(mqf >= 6) {
      numGraphsPerRow = 4;
      graphHeightEm = 30;
    }
    else if(mqf >= 5) {
      numGraphsPerRow = 2;
      graphHeightEm = 30;
    }
    else if(mqf >= 3) {
      numGraphsPerRow = 2;
    }

    const pageNumGraphs = selectedPageGraphsDataArrayOfObjs.length;
    var graphsComponentsArray = [];
    for(let g = 0; g < pageNumGraphs; g++) {
      graphsComponentsArray.push(
        <DivexecGraph
          p_graphDataObj={selectedPageGraphsDataArrayOfObjs[g]}
          p_startDate={performanceStartDateFixed}
          p_endDate={performanceEndDateFixed}
          p_canEditDeleteReorderTF={false}
          p_canClickSegmentsTF={true}
          p_graphHeightEm={graphHeightEm}
        />
      );
    }

    return(
      <>
        <DivexecStartEndDateEditorRow
          p_startDate={performanceStartDateRelative}
          p_endDate={performanceEndDateRelative}
          p_canEditTF={true}
          f_onChangeStartDate={this.onselect_divexec_graph_start_date}
          f_onChangeEndDate={this.onselect_divexec_graph_end_date}
        />
        <LibraryReact.TileComponents
          p_componentsArray={graphsComponentsArray}
          p_numTilesPerRow={numGraphsPerRow}
          p_tileMarginClass="medFullMargin"
          p_tileClass="displayFlexColumn overflowHidden border bevelBorderColors boxShadowGreenDarkGreenHover"
          p_tileStyleObj={{height:graphHeightEm + "em"}}
        />
      </>
    );
  }
}));


const DivexecStartEndDateEditorRow = inject("DatabaseMobx")(observer(
class DivexecStartEndDateEditorRow extends Component { //props: p_startDate, p_endDate, p_canEditTF, f_onChangeStartDate, f_onChangeEndDate
  render() {
    const p_startDate = this.props.p_startDate;
    const p_endDate = this.props.p_endDate;
    const p_canEditTF = this.props.p_canEditTF;

    const containerWidthEm = 30;
    const fieldClass = "fontItalic";
    const fieldWidthEm = 5.5;

    return(
      <div className="flex00a displayFlexRow flexWrap borderB1bbb bgLighterGreen" style={{minHeight:"2.2em"}}>
        <div className="flex00a displayFlexColumnVc lrMedPad" style={{flexBasis:"12em"}}>
          <font className="font11 fontBold fontTextLight">
            {"Graph Controls"}
          </font>
        </div>
        <div className="flex00a displayFlexColumnVc lrPad" style={{flexBasis:containerWidthEm + "em"}}>
          <CEGeneralReact.CaptureExecFieldEditSaveCancel
            p_ceEditItemString="divexecGraph_start_date"
            p_fieldDisplayName="Start Date:"
            p_fieldTypeObj={this.props.DatabaseMobx.c_genericDateWithRelativeDateFieldTypeObj}
            p_valueRaw={p_startDate}
            p_valueIsEditableTFU={p_canEditTF}
            p_containerClass="tbMicroMargin"
            p_fieldClass={fieldClass}
            p_fieldWidth={fieldWidthEm + "em"}
            p_valueClass=""
            f_onSaveChanged={this.props.f_onChangeStartDate}
          />
        </div>
        <div className="flex00a displayFlexColumnVc lrPad" style={{flexBasis:containerWidthEm + "em"}}>
          <CEGeneralReact.CaptureExecFieldEditSaveCancel
            p_ceEditItemString="divexecGraph_end_date"
            p_fieldDisplayName="End Date:"
            p_fieldTypeObj={this.props.DatabaseMobx.c_genericDateWithRelativeDateFieldTypeObj}
            p_valueRaw={p_endDate}
            p_valueIsEditableTFU={p_canEditTF}
            p_containerClass="tbMicroMargin"
            p_fieldClass={fieldClass}
            p_fieldWidth={fieldWidthEm + "em"}
            p_valueClass=""
            f_onSaveChanged={this.props.f_onChangeEndDate}
          />
        </div>
        <div className="flex11a" />
      </div>
    );
  }
}));




//=============================================================================================================================================================================
//=============================================================================================================================================================================






export class WaterfallAnalysis extends Component {
  render() {
    return(
      <div className="flex11a medFullPad yScroll">
        <WaterfallForecastGraph />
      </div>
    );
  }
}

class WaterfallForecastGraph extends Component {
  render() {
    var stackedCAOO = [
      {valuesPerBinArray:[10,20,10,20,10,20,10], clickIDsPerBinArrayOfArrays:[[1,2],[3],[4,5,6],[7],[8],[9,10],[11,12,13]], label:"Stack One", color:"b22"},
      {valuesPerBinArray:[30,25,20,7.5,0,50,23], clickIDsPerBinArrayOfArrays:undefined, label:"Stack Two", color:"2b2"}
    ];
    var linesCAOO = [
      {valuesPerBinArray:[80,80,80,80,90,90,90], clickIDsPerBinArrayOfArrays:undefined, label:"Line Overlay One", color:"22b"}
    ];
    var binLabelsA = ["Q1","Q2","Q3","Q4","Q1","Q2","Q3"];
    var binGroupLabelsA = ["2025","2025","2025","2025","2026","2026","2026"];
    var graphTitle = "Waterfall Analysis Forecast";
    var graphSubtitle = "Division Sample Test"

    return(
      <div className="flex00a displayFlexColumn" style={{width:"80em", border:"solid 1px #777"}}>
        <LibraryReact.StackedAreaGraph
          p_stackedAreaCategoriesArrayOfObjs={stackedCAOO}
          p_overlayLinesCategoriesArrayOfObjs={linesCAOO}
          p_binLabelsArray={binLabelsA}
          p_binGroupLabelsArray={binGroupLabelsA}
          p_graphTitle={graphTitle}
          p_graphSubtitle={graphSubtitle}
          p_valueFormat="moneyShort"
          p_yLogScaleTF={false}
          p_yAxisWidthEm={5}
          p_heightPx={500}
          p_fontSizePx={12}
        />
      </div>
    );
  }
}




//=============================================================================================================================================================================
//=============================================================================================================================================================================






export class DailySnapshot extends Component {
  render() {
    return(
      <DivexecGraphsTab />
    );
  }
}

export class TrendAnalyzer extends Component {
  render() {
    return(
      <DivexecGraphsTab />
    );
  }
}


const DivexecGraphsTab = inject("CaptureExecMobx", "DivexecMobx")(observer(
class DivexecGraphsTab extends Component { //props:
  componentDidMount() {
    if(this.props.DivexecMobx.c_graphsSelectedPageID === undefined) {
      this.props.DivexecMobx.a_graphs_initialize_selected_page_id_to_first_option();
    }
  }

  onselect_divexec_num_graphs_per_row = (i_newValue) => {
    const selectedPageObj = this.props.DivexecMobx.c_graphsSelectedPageObj;
    if(selectedPageObj !== undefined) {
      this.props.DivexecMobx.a_graphs_update_page_field(selectedPageObj.id, "num_graphs_per_row", i_newValue, "i");
    }
  }

  onselect_divexec_graph_height = (i_newValue) => {
    const selectedPageObj = this.props.DivexecMobx.c_graphsSelectedPageObj;
    if(selectedPageObj !== undefined) {
      this.props.DivexecMobx.a_graphs_update_page_field(selectedPageObj.id, "graph_height_em", i_newValue, "i");
    }
  }

  onchange_page_start_date = (i_newValue) => {
    const selectedPageObj = this.props.DivexecMobx.c_graphsSelectedPageObj;
    if(selectedPageObj !== undefined) {
      this.props.DivexecMobx.a_graphs_update_page_field(selectedPageObj.id, "start_date", i_newValue, "s");
    }
  }

  onchange_page_end_date = (i_newValue) => {
    const selectedPageObj = this.props.DivexecMobx.c_graphsSelectedPageObj;
    if(selectedPageObj !== undefined) {
      this.props.DivexecMobx.a_graphs_update_page_field(selectedPageObj.id, "end_date", i_newValue, "s");
    }
  }

  render() {
    const isMobileTF = this.props.CaptureExecMobx.c_isMobileTF;
    const tabDailySnapshotTrueTrendAnalyzerFalse = this.props.DivexecMobx.c_tabDailySnapshotTrueTrendAnalyzerFalse;
    const pagesArrayOfObjs = this.props.DivexecMobx.c_graphsSelectedTabAllPagesArrayOfObjs;
    const selectedPageObj = this.props.DivexecMobx.c_graphsSelectedPageObj;
    const selectedPageIsReplicaOfPublicPageTF = this.props.DivexecMobx.c_graphsSelectedPageIsReplicaOfPublicPageTF;
    const selectedPageNumGraphsPerRow = this.props.DivexecMobx.c_graphsSelectedPageNumGraphsPerRow;
    const selectedPageGraphHeightEm = this.props.DivexecMobx.c_graphsSelectedPageGraphHeightEm;
    const selectedPageGraphsDataArrayOfObjs = this.props.DivexecMobx.c_graphsSelectedPageGraphsDataArrayOfObjs;

    const numPages = pagesArrayOfObjs.length;
    const tabErrorMessage = ((numPages === 0) ? ("To get started, push the '[+] Create New Graphs Page' button to create your first page of graphs") : (undefined));
    const numGraphsPerRow = ((isMobileTF) ? (1) : (selectedPageNumGraphsPerRow));

    var selectedPageStartDateRelative = JSFUNC.blank_date();
    var selectedPageEndDateRelative = JSFUNC.blank_date();

    const pageNumGraphs = selectedPageGraphsDataArrayOfObjs.length;
    var graphsComponentsArray = [];
    if(selectedPageObj !== undefined) {
      selectedPageStartDateRelative = selectedPageObj.start_date;
      selectedPageEndDateRelative = selectedPageObj.end_date;
      var selectedPageStartDateFixed = JSFUNC.convert_relative_ymd_date_to_fixed_date_ymd(selectedPageStartDateRelative);
      var selectedPageEndDateFixed = JSFUNC.convert_relative_ymd_date_to_fixed_date_ymd(selectedPageEndDateRelative);

      for(let g = 0; g < pageNumGraphs; g++) {
        graphsComponentsArray.push(
          <DivexecGraph
            p_graphDataObj={selectedPageGraphsDataArrayOfObjs[g]}
            p_startDate={selectedPageStartDateFixed}
            p_endDate={selectedPageEndDateFixed}
            p_canEditDeleteReorderTF={(!selectedPageIsReplicaOfPublicPageTF)}
            p_canClickSegmentsTF={true}
            p_isOnlyItemTF={(pageNumGraphs === 1)}
            p_isLastItemTF={(g === (pageNumGraphs - 1))}
            p_graphHeightEm={selectedPageGraphHeightEm}
            p_selectedPageID={selectedPageObj.id}
          />
        );
      }

      if(!selectedPageIsReplicaOfPublicPageTF) {
        graphsComponentsArray.push(
          <DivexecAddNewGraph />
        );
      }
    }

    return(
      <>
        <div className="flex00a displayFlexRow tbMicroPad borderB1bbb bgLightestGray">
          <div className="flex00a displayFlexColumnHcVc textCenter" style={{flexBasis:"5em"}}>
            <font className="font12 fontBold fontItalic fontTextLightester">
              {"Graph Pages:"}
            </font>
          </div>
          <div className="flex11a displayFlexRowVc flexWrap lrPad">
            {pagesArrayOfObjs.map((m_pageObj, m_index) =>
              <GraphPageButton
                key={m_pageObj.id}
                p_pageObj={m_pageObj}
                p_sortNumber={(m_index + 1)}
                p_isOnlyItemTF={(numPages === 1)}
                p_isLastItemTF={(m_index === (numPages - 1))}
              />
            )}
            <CreateNewGraphPageButton />
          </div>
          <div className="flex00a displayFlexColumnHcVc borderL1ddd" style={{flexBasis:"10em"}}>
            <div className="microBottomPad">
              <font className="fontBold fontItalic fontTextLight">
                {"# Graphs Per Row:"}
              </font>
            </div>
            {(isMobileTF || selectedPageIsReplicaOfPublicPageTF) ? (
              <div>
                <font className="fontItalic fontTextLight">
                  {numGraphsPerRow}
                </font>
              </div>
            ) : (
              <div style={{width:"5em"}}>
                {(selectedPageObj !== undefined) &&
                  <CEGeneralReact.GenericInputOrSelectFromInputType
                    p_fieldTypeObj={this.props.DivexecMobx.c_graphsSelectNumGraphsPerRowFieldTypeObj}
                    p_valueRaw={numGraphsPerRow}
                    f_onChangeOrOnSelect={this.onselect_divexec_num_graphs_per_row}
                  />
                }
              </div>
            )}
          </div>
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"8em"}}>
            <div className="microBottomPad">
              <font className="fontBold fontItalic fontTextLight">
                {"Graph Height:"}
              </font>
            </div>
            {(selectedPageIsReplicaOfPublicPageTF) ? (
              <div>
                <font className="fontItalic fontTextLight">
                  {selectedPageGraphHeightEm}
                </font>
              </div>
            ) : (
              <div style={{width:"5em"}}>
                {(selectedPageObj !== undefined) &&
                  <CEGeneralReact.GenericInputOrSelectFromInputType
                    p_fieldTypeObj={this.props.DivexecMobx.c_graphsSelectGraphHeightsFieldTypeObj}
                    p_valueRaw={selectedPageGraphHeightEm}
                    f_onChangeOrOnSelect={this.onselect_divexec_graph_height}
                  />
                }
              </div>
            )}
          </div>
        </div>
        {((selectedPageObj !== undefined) && (!tabDailySnapshotTrueTrendAnalyzerFalse)) &&
          <DivexecStartEndDateEditorRow
            p_startDate={selectedPageStartDateRelative}
            p_endDate={selectedPageEndDateRelative}
            p_canEditTF={(!selectedPageIsReplicaOfPublicPageTF)}
            f_onChangeStartDate={this.onchange_page_start_date}
            f_onChangeEndDate={this.onchange_page_end_date}
          />
        }
        {(tabErrorMessage === undefined) ? (
          <div className="flex11a displayFlexColumn yScroll yScrollBottomPad">
            <LibraryReact.TileComponents
              p_componentsArray={graphsComponentsArray}
              p_numTilesPerRow={numGraphsPerRow}
              p_tileMarginClass="medFullMargin"
              p_tileClass="displayFlexColumn overflowHidden border bevelBorderColors boxShadowGreenDarkGreenHover"
              p_tileStyleObj={{height:selectedPageGraphHeightEm + "em"}}
            />
          </div>
        ) : (
          <CEGeneralReact.EmptyScreenGray>
            <font className="font16 fontTextLighter">
              {tabErrorMessage}
            </font>
          </CEGeneralReact.EmptyScreenGray>
        )}
        <EditGraphFloatingBox />
      </>
    );
  }
}));


const GraphPageButton = inject("DivexecMobx", "DatabaseMobx", "UserMobx")(observer(
class GraphPageButton extends Component { //props: p_pageObj, p_sortNumber, p_isOnlyItemTF, p_isLastItemTF
  constructor(props) {
    super(props);
    this.state = {
      s_editingPageTF: false
    }
  }

  onclick_page_button = () => {
    this.props.DivexecMobx.a_graphs_set_selected_page_id(this.props.p_pageObj.id);
  }

  onselect_edit_page = () => {
    this.setState({s_editingPageTF:true});
  }

  onclick_close_editing_page = () => {
    this.setState({s_editingPageTF:false});
  }

  onchange_page_name = (i_changedGraphName) => {
    this.props.DivexecMobx.a_graphs_update_page_field(this.props.p_pageObj.id, "name", i_changedGraphName, "s");
  }

  onswitch_page_is_public = () => {
    const newValue01 = ((this.props.p_pageObj.public_01 === 1) ? (0) : (1));
    this.props.DivexecMobx.a_graphs_update_page_field(this.props.p_pageObj.id, "public_01", newValue01, "i");
  }

  onsave_copy_page_with_new_name = (i_newCopiedPageName) => {
    this.props.DivexecMobx.a_graphs_copy_page(this.props.p_pageObj, i_newCopiedPageName);
  }

  ondelete_graph_page = () => {
    this.props.DivexecMobx.a_graphs_delete_page_and_graphs_from_page_id(this.props.p_pageObj.id);
  }

  ondrop_foreign_graph_onto_page_button = (i_foreignUniqueString, i_foreignDraggedItemID, i_droppedOnItemID) => {
    if(i_foreignUniqueString === "divexecGraphs") { //a uniqueString of divexecGraphs means a graph is being dragged by the resort handle and was dropped onto this page button
      //figure out if the dropped on page is a replica, if so, don't let this movement happen as replica pages do not have real graphs on them
      const isReplicaOfPublicPageTF = (this.props.p_pageObj.replica_of_page_id > 0);
      if(!isReplicaOfPublicPageTF) {
        const draggedGraphID = i_foreignDraggedItemID;
        this.props.DivexecMobx.a_graphs_move_graph_to_end_of_different_page_resort_original_page(draggedGraphID, this.props.p_pageObj);
      }
      else {
        alert("Cannot move Graphs onto replica Pages");
      }
    }
  }

  render() {
    const s_editingPageTF = this.state.s_editingPageTF;

    const p_pageObj = this.props.p_pageObj;
    const p_sortNumber = this.props.p_sortNumber;
    const p_isOnlyItemTF = this.props.p_isOnlyItemTF;
    const p_isLastItemTF = this.props.p_isLastItemTF;

    const c_graphsSelectedTabPagesTblName = this.props.DivexecMobx.c_graphsSelectedTabPagesTblName;
    const c_graphsSelectedPageID = this.props.DivexecMobx.c_graphsSelectedPageID;
    const o_userID = this.props.UserMobx.o_userID;
    const c_userCanCreatePublicItemsTF = this.props.UserMobx.c_userCanCreatePublicItemsTF;

    const isSelectedTF = (p_pageObj.id === c_graphsSelectedPageID);
    const isReplicaOfPublicPageTF = (p_pageObj.replica_of_page_id > 0);
    const userCanEditTF = (!isReplicaOfPublicPageTF);

    return(
      <>
        <CEGeneralReact.CEDragToResortItemWithinDbTbl
          p_canResortTF={true}
          p_uniqueString="divexecGraphPageButtons"
          p_itemID={p_pageObj.id}
          p_itemSort={p_sortNumber}
          p_itemSortColumnWidth="2em"
          p_itemSortNumberFontClass={undefined}
          p_isOnlyItemTF={p_isOnlyItemTF}
          p_isLastItemTF={p_isLastItemTF}
          p_outerPadClass="tbMicroPad lrPad"
          p_itemClass={"border bevelBorderColors borderRadius05 " + ((isSelectedTF) ? ("bgGreenGradient") : ("bgLightGrayGradient hoverLightGreenGradient fontTextLight"))}
          p_itemStyleObj={{width:"14em", height:"3.3em"}}
          p_lastItemExtraDropZoneHeight={undefined}
          p_tblName={c_graphsSelectedTabPagesTblName}
          p_tblSortFieldDbName="sort"
          p_filterFieldNamesArray={["user_id"]}
          p_filterValuesArray={[o_userID]}
          f_onDropForeignItem={this.ondrop_foreign_graph_onto_page_button}>
          <div
            className={"flex11a displayFlexRowVc lrMedPad textCenter " + ((isSelectedTF) ? ("fontWhite") : ("cursorPointer"))}
            title={p_pageObj.name}
            onClick={this.onclick_page_button}>
            <LibraryReact.MaxHeightWrap p_maxHeight="2.3em" p_fontClass="fontBold">
              {p_pageObj.name}
            </LibraryReact.MaxHeightWrap>
          </div>
          {(isReplicaOfPublicPageTF) &&
            <div className="flex00a displayFlexColumnHcVc">
              <DivexecGraphsPagesReplicaIcon
                p_title={"This is a replica of a Public Page created by " + this.props.DatabaseMobx.user_name_mask_plaintext_from_user_id(p_pageObj.created_by_user_id)}
              />
            </div>
          }
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"1.5em"}}>
            <CEGeneralReact.VerticalDotsMenu
              p_menuItemsArrayOfObjs={[
                {
                  displayName: "Edit Page",
                  functionOnClickMenuItem: this.onselect_edit_page},
                {
                  displayName: "Copy Page (with graphs)",
                  confirmType: "inputText",
                  confirmTitle: "Copy Page (with graphs)",
                  confirmTextInitialValue: "Copy of " + p_pageObj.name,
                  confirmButton1Name: "Create Copy of This Page",
                  confirmTextMustBeChangedForSaveTFU: false,
                  confirmMessage: "An exact copy of this Page '" + p_pageObj.name + "' will be created next to this Page. If the Page being copied is a replica of someone else's Public Page, you will be able to edit the new private copy.\n\nNew Page Name:",
                  functionOnClickConfirmButton: this.onsave_copy_page_with_new_name
                },
                {
                  displayName: "Delete",
                  confirmType: "confirmDelete",
                  confirmMessage: "Are you sure you want to delete Graphs Page '" + p_pageObj.name + "' and all graphs on that page?",
                  functionOnClickConfirmButton: this.ondelete_graph_page
                }
              ]}
            />
          </div>
        </CEGeneralReact.CEDragToResortItemWithinDbTbl>
        {(s_editingPageTF) &&
          <CEGeneralReact.FloatingBoxWithSaveCancel
            p_trblFlag="smallVertical"
            p_title={"Editing Graph Page '" + p_pageObj.name + "'"}
            f_onClickSave={this.onclick_close_editing_page}>
            <div className="flex11a medFullPad yScroll">
              <CEGeneralReact.CaptureExecFieldEditSaveCancel
                p_ceEditItemString="divexecPageName"
                p_fieldDisplayName="Page Name"
                p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
                p_valueRaw={p_pageObj.name}
                p_valueIsEditableTFU={userCanEditTF}
                p_containerClass="medBottomMargin"
                p_fieldClass="fontBlue"
                p_fieldWidth="9em"
                f_onSaveChanged={this.onchange_page_name}
              />
              {(isReplicaOfPublicPageTF) &&
                <div>
                  <font className="fontItalic fontTextLighter">
                    {"This is a replica of a Public Page created by " + this.props.DatabaseMobx.user_name_mask_plaintext_from_user_id(p_pageObj.created_by_user_id)}
                  </font>
                </div>
              }
              {(!isReplicaOfPublicPageTF && c_userCanCreatePublicItemsTF) &&
                <div className="displayFlexRowVc">
                  <div className="flex00a" style={{flexBasis:"9em"}}>
                    <font className="fontBlue">
                      {"Page is Public?"}
                    </font>
                  </div>
                  <div className="flex11a">
                    <CEGeneralReact.SwitchWithTextAndConfirmBox
                      p_isOnTF={(p_pageObj.public_01 === 1)}
                      p_sizeEm={3}
                      p_onText="Public to everyone"
                      p_offText="Private to me"
                      p_title="Public page can be added to other users' graph tabs while not allowing them to edit it"
                      f_onSwitch={this.onswitch_page_is_public}
                    />
                  </div>
                </div>
              }
            </div>
          </CEGeneralReact.FloatingBoxWithSaveCancel>
        }
      </>
    );
  }
}));


const CreateNewGraphPageButton = inject("DivexecMobx")(observer(
class CreateNewGraphPageButton extends Component { //props:
  constructor(props) {
    super(props);
    this.state = {
      s_creatingNewPageTF: false,
      s_addingPublicPageTF: false
    }
  }

  onclick_create_new_graphs_page_button = () => {
    this.setState({s_creatingNewPageTF:true});
  }

  onclick_confirm_create_new_page = (i_newPageName) => {
    this.props.DivexecMobx.a_graphs_create_new_page(i_newPageName);
    this.onclick_cancel_create_new_page();
  }

  onclick_cancel_create_new_page = () => {
    this.setState({s_creatingNewPageTF:false});
  }

  onclick_add_public_page_button = () => {
    this.setState({s_addingPublicPageTF:true});
  }

  onclick_cancel_add_public_page = () => {
    this.setState({s_addingPublicPageTF:false});
  }

  render() {
    const creatingNewPageTF = this.state.s_creatingNewPageTF;
    const addingPublicPageTF = this.state.s_addingPublicPageTF;

    const selectedTabName = this.props.DivexecMobx.c_graphsSelectedTabName;
    const selectedTabAllPublicPagesPerUserCreatorArrayOfObjs = this.props.DivexecMobx.c_graphsSelectedTabAllPublicPagesPerUserCreatorArrayOfObjs;

    const createNewPageButtonClass = "flex11a displayFlexColumnHcVc lrMargin lrMedPad border1ddd borderRadius05 bgLighterGreenGradient hoverLightestGrayGradient textCenter overflowHidden cursorPointer";

    return(
      <>
        <div className="flex00a displayFlexColumnVc tbMicroMargin lrMargin border1ddd borderRadius05 bgLightGrayGradient" style={{flexBasis:"15em", height:"3.3em"}}>
          <div
            className={createNewPageButtonClass}
            style={{flexBasis:"100em"}}
            onClick={this.onclick_create_new_graphs_page_button}>
            <font className="font11 fontBold fontBlueLighter">
              {"[+] Create New Page"}
            </font>
          </div>
          <div
            className={createNewPageButtonClass}
            style={{flexBasis:"100em"}}
            onClick={this.onclick_add_public_page_button}>
            <font className="font11 fontBold fontBlueLighter">
              {"[o] Add Public Page"}
            </font>
          </div>
        </div>
        {(creatingNewPageTF) &&
          <CEGeneralReact.ConfirmBox
            p_type="inputText"
            p_title={"Create New " + selectedTabName + " Graphs Page Button"}
            p_button1Name="Create New Page"
            p_button2Name="Cancel"
            p_textMustBeFilledOutTF={true}
            f_onClickConfirm={this.onclick_confirm_create_new_page}
            f_onClickCancel={this.onclick_cancel_create_new_page}>
            {"Enter a Name for the New " + selectedTabName + " Graphs Page"}
          </CEGeneralReact.ConfirmBox>
        }
        {(addingPublicPageTF) &&
          <CEGeneralReact.FloatingBoxWithSaveCancel
            p_trblFlag="smallVertical"
            p_title="Add a Public Page to your Graphs tab"
            f_onClickCancel={this.onclick_cancel_add_public_page}>
            {(selectedTabAllPublicPagesPerUserCreatorArrayOfObjs.length === 0) ? (
              <CEGeneralReact.EmptyScreenGray>
                {"No one has created any Public Pages yet"}
              </CEGeneralReact.EmptyScreenGray>
            ) : (
              <div className="flex11a yScroll bgLighterGray">
                {selectedTabAllPublicPagesPerUserCreatorArrayOfObjs.map((m_publicPagesPerUserCreatorObj) =>
                  <div className="hugeBottomPad borderB1bbb">
                    <div className="smallTopPad lrPad">
                      <font className="font13 fontBold fontTextLight">
                        {"Created by " + m_publicPagesPerUserCreatorObj.userNameMaskPlainText}
                      </font>
                    </div>
                    {m_publicPagesPerUserCreatorObj.userPagesArrayOfObjs.map((m_pageObj) =>
                      <AddNewPageSelectPublicPageSingleItem
                        p_pageObj={m_pageObj}
                        p_createdByUserNameMaskPlainText={m_publicPagesPerUserCreatorObj.userNameMaskPlainText}
                        f_onClick={this.onclick_cancel_add_public_page}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </CEGeneralReact.FloatingBoxWithSaveCancel>
        }
      </>
    );
  }
}));


const AddNewPageSelectPublicPageSingleItem = inject("DivexecMobx")(observer(
class AddNewPageSelectPublicPageSingleItem extends Component { //props: p_pageObj, p_createdByUserNameMaskPlainText, f_onClick
  onclick_public_page_item = () => {
    this.props.DivexecMobx.a_graphs_add_public_page_as_replica(this.props.p_pageObj);
    if(JSFUNC.is_function(this.props.f_onClick)) {
      this.props.f_onClick();
    }
  }

  render() {
    const p_pageObj = this.props.p_pageObj;
    const createdByUserNameMaskPlainText = this.props.p_createdByUserNameMaskPlainText;

    const graphsSelectedTabUserAllAddedPageIDsArray = this.props.DivexecMobx.c_graphsSelectedTabUserAllAddedPageIDsArray;

    const publicPageAlreadyAddedToUserPageTF = JSFUNC.in_array(p_pageObj.id, graphsSelectedTabUserAllAddedPageIDsArray);

    return(
      <div
        className="displayFlexColumnVc smallFullMargin lrMedPad border bevelBorderColors borderRadius10 bgLightGrayGradient hoverLightGreenGradient cursorPointer"
        style={{height:"5em", marginLeft:"2em"}}
        title={p_pageObj.name}
        onClick={this.onclick_public_page_item}>
        <div className="microBottomMargin textRight">
          <LibraryReact.Nowrap p_fontClass={((publicPageAlreadyAddedToUserPageTF) ? ("fontItalic fontTextLight") : ("fontBlue"))}>
            {((publicPageAlreadyAddedToUserPageTF) ? ("Already added to Graph Pages") : ("Not Yet Added"))}
          </LibraryReact.Nowrap>
        </div>
        <div className="microBottomMargin">
          <LibraryReact.Nowrap p_fontClass="font12 fontBold fontTextLighter">
            {p_pageObj.name}
          </LibraryReact.Nowrap>
        </div>
        <div>
          <font className="fontItalic fontTextLight">
            {"Created by " + createdByUserNameMaskPlainText}
          </font>
        </div>
      </div>
    );
  }
}));


const DivexecGraph = inject("CaptureExecMobx", "DivexecMobx", "DatabaseMobx", "UserMobx")(observer(
class DivexecGraph extends Component { //props: p_graphDataObj, p_startDate, p_endDate, p_canEditDeleteReorderTF, p_canClickSegmentsTF, p_isOnlyItemTF, p_isLastItemTF, p_graphHeightEm, p_selectedPageID
  onclick_edit_graph = () => {
    this.props.DivexecMobx.a_graphs_set_editing_graph_id(this.props.p_graphDataObj.graphObj.id);
  }

  onclick_circle_graph_segment = (i_categoryCaptureIDsArray, i_categoryLabel) => {
    const categoryCapturesFloatingBoxTitle = "Captures in category '" + i_categoryLabel + "'";
    this.onclick_graph_segment_open_search_results_captures(i_categoryCaptureIDsArray, categoryCapturesFloatingBoxTitle);
  }

  onclick_time_graph_segment = (i_categoryCaptureIDsArray, i_categoryLineDataObj, i_binObj) => {
    const p_graphDataObj = this.props.p_graphDataObj;

    var categoryAndBinDateRangeTitle = "Captures from category '" + i_categoryLineDataObj.label + "'";
    var leftDate = JSFUNC.blank_date();
    var rightDate = JSFUNC.blank_date();
    if(i_binObj !== undefined) { //segment from a single bin within a single category
      leftDate = i_binObj.leftDate;
      rightDate = i_binObj.rightDate;
    }
    const leftDateMask = this.props.DatabaseMobx.get_company_date_format_from_Ymd_date(leftDate);
    const rightDateMask = this.props.DatabaseMobx.get_company_date_format_from_Ymd_date(rightDate);
    categoryAndBinDateRangeTitle += " (" + p_graphDataObj.dateFieldName + " " + leftDateMask + " - " + rightDateMask + ")";  
    
    this.onclick_graph_segment_open_search_results_captures(i_categoryCaptureIDsArray, categoryAndBinDateRangeTitle);
  }

  onclick_graph_segment_open_search_results_captures = (i_captureIDsArray, i_floatingBoxTitle) => {
    const floatingBoxTitle = i_floatingBoxTitle + " for graph '" + this.props.p_graphDataObj.graphObj.graph_title + "'";
    this.props.CaptureExecMobx.a_set_view_specified_search_results_captures_in_floating_box_capture_ids_array_and_floating_box_title(i_captureIDsArray, floatingBoxTitle);
  }

  onsave_copy_graph_with_new_name = (i_newCopiedGraphTitle) => {
    this.props.DivexecMobx.a_graphs_copy_graph(this.props.p_graphDataObj, i_newCopiedGraphTitle);
  }

  ondelete_graph = () => {
    this.props.DivexecMobx.a_graphs_delete_graph(this.props.p_graphDataObj);
  }

  render() {
    const p_graphDataObj = this.props.p_graphDataObj;
    const p_startDate = this.props.p_startDate;
    const p_endDate = this.props.p_endDate;
    const p_canEditDeleteReorderTF = JSFUNC.prop_value(this.props.p_canEditDeleteReorderTF, true);
    const p_canClickSegmentsTF = JSFUNC.prop_value(this.props.p_canClickSegmentsTF, true);
    const p_isOnlyItemTF = this.props.p_isOnlyItemTF;
    const p_isLastItemTF = this.props.p_isLastItemTF;
    const p_graphHeightEm = JSFUNC.prop_value(this.props.p_graphHeightEm, 28);
    const p_selectedPageID = this.props.p_selectedPageID;

    const c_userFontSizePx = this.props.UserMobx.c_userFontSizePx;

    const graphObj = p_graphDataObj.graphObj;
    const tabDailySnapshotTrueTrendAnalyzerFalse = p_graphDataObj.circleGraphTrueTimeGraphFalse;
    const isReplicaOfPublicGraphTF = (graphObj.replica_of_graph_id > 0);

    const graphTitleHeightEm = 1.9;
    const graphFooterHeightEm = ((tabDailySnapshotTrueTrendAnalyzerFalse) ? (1.6 * graphTitleHeightEm) : (2.2 * graphTitleHeightEm));
    const graphTbMarginEm = 0.6;
    const adjustedGraphHeightEm = (p_graphHeightEm - (graphTbMarginEm * 2) - graphTitleHeightEm - graphFooterHeightEm);
    const adjustedGraphHeightPx = (adjustedGraphHeightEm * c_userFontSizePx);

    return(
      <div className="flex11a displayFlexColumn">
        <CEGeneralReact.CEDragToResortItemWithinDbTbl
          p_canResortTF={p_canEditDeleteReorderTF}
          p_uniqueString="divexecGraphs"
          p_itemID={graphObj.id}
          p_itemSort={graphObj.sort}
          p_itemSortColumnWidth="2.2em"
          p_itemSortNumberFontClass={undefined}
          p_isOnlyItemTF={(p_isOnlyItemTF && false)} //make this always false so that it can always be grabbed to drag the graph onto a page button (even when there's only 1 graph)
          p_isLastItemTF={p_isLastItemTF}
          p_outerPadClass={undefined}
          p_itemClass="flex00a displayFlexRow borderB1ddd"
          p_itemStyleObj={{height:graphTitleHeightEm + "em"}}
          p_lastItemExtraDropZoneHeight={undefined}
          p_tblName={this.props.DivexecMobx.c_graphsSelectedTabGraphsTblName}
          p_tblSortFieldDbName="sort"
          p_filterFieldNamesArray={["page_id"]}
          p_filterValuesArray={[p_selectedPageID]}>
          <div className="flex11a displayFlexRowVc textCenter borderR1ddd">
            <LibraryReact.Nowrap p_fontClass="font12 fontBold fontTextLighter">
              {graphObj.graph_title}
            </LibraryReact.Nowrap>
          </div>
          {(isReplicaOfPublicGraphTF) &&
            <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"1.7em"}}>
              <DivexecGraphsPagesReplicaIcon
                p_title={"This is a replica of a Public Graph created by " + this.props.DatabaseMobx.user_name_mask_plaintext_from_user_id(graphObj.created_by_user_id)}
              />
            </div>
          }
          <CEGeneralReact.EditSaveCancelIcon
            p_iconType={((p_canEditDeleteReorderTF) ? ("edit") : ("blank"))}
            p_title="Edit the properties, filters, categories, and style of this graph"
            f_onClick={this.onclick_edit_graph}
          />
          <div className="flex00a displayFlexColumnHcVc" style={{flexBasis:"1.5em"}}>
            {(p_canEditDeleteReorderTF) &&
              <CEGeneralReact.VerticalDotsMenu
                p_menuItemsArrayOfObjs={[
                  {
                    displayName: "Copy Graph",
                    confirmType: "inputText",
                    confirmTitle: "Copy Graph",
                    confirmTextInitialValue: "Copy of " + graphObj.graph_title,
                    confirmButton1Name: "Create Copy of This Graph",
                    confirmTextMustBeChangedForSaveTFU: false,
                    confirmMessage: "An exact copy of this Graph '" + graphObj.graph_title + "' will be created next to this Graph on this Page. If this graph is someone else's public graph, you will be able to edit the new private copy.\n\nNew Graph Title:",
                    functionOnClickConfirmButton: this.onsave_copy_graph_with_new_name
                  },
                  {
                    displayName: "Delete",
                    confirmType: "confirmDelete",
                    confirmMessage: "Are you sure you want to delete Graph '" + graphObj.graph_title + "' from this page?",
                    functionOnClickConfirmButton: this.ondelete_graph
                  }
                ]}
              />
            }
          </div>
        </CEGeneralReact.CEDragToResortItemWithinDbTbl>
        <div className="flex11a displayFlexColumnVc lrPad">
          <SnapshotGraphOrTrendGraphFromGraphObj
            p_graphDataObj={p_graphDataObj}
            p_startDate={p_startDate}
            p_endDate={p_endDate}
            p_heightPx={adjustedGraphHeightPx}
            p_fontSizePx={Math.round(c_userFontSizePx * 1.1)}
            f_onClickSegment={((p_canClickSegmentsTF) ? ((p_graphDataObj.circleGraphTrueTimeGraphFalse) ? (this.onclick_circle_graph_segment) : (this.onclick_time_graph_segment)) : (undefined))}
          />
        </div>
        <div className="flex00a displayFlexColumnVc borderT1ddd" style={{flexBasis:graphFooterHeightEm + "em"}}>
          {(!tabDailySnapshotTrueTrendAnalyzerFalse) &&
            <div className="microBottomMargin" title={"Date Field: " + p_graphDataObj.dateFieldName}>
              <LibraryReact.Nowrap p_fontClass="">
                {"Date Field: "}<font className="fontBold fontTextLight">{p_graphDataObj.dateFieldName}</font>
              </LibraryReact.Nowrap>
            </div>
          }
          <div title={"Capture Filter: " + p_graphDataObj.filterPresetName}>
            <LibraryReact.Nowrap p_fontClass="">
              {"Capture Filter: "}<font className="fontBold fontTextLight">{p_graphDataObj.filterPresetName}</font>
            </LibraryReact.Nowrap>
          </div>
          <div title={"Count Field: " + p_graphDataObj.countValueNumCapturesOrMoneyFieldName}>
            <LibraryReact.Nowrap p_fontClass="">
              {"Count Field: "}<font className="fontBold fontTextLight">{p_graphDataObj.countValueNumCapturesOrMoneyFieldName}</font>
            </LibraryReact.Nowrap>
          </div>
        </div>
      </div>
    );
  }
}));

class SnapshotGraphOrTrendGraphFromGraphObj extends Component { //props: p_graphDataObj, p_startDate, p_endDate, p_heightPx, p_fontSizePx, f_onClickSegment
  render() {
    const p_graphDataObj = this.props.p_graphDataObj;
    const p_startDate = this.props.p_startDate;
    const p_endDate = this.props.p_endDate;
    const heightPx = this.props.p_heightPx;
    const fontSizePx = this.props.p_fontSizePx;

    const graphObj = p_graphDataObj.graphObj;

    if(p_graphDataObj.errorMessage !== undefined) {
      return(
        <div className="flex11a displayFlexColumnHcVc">
          <font className="fontItalic fontTextLighter">
            {p_graphDataObj.errorMessage}
          </font>
        </div>
      );
    }

    const yAxisLogTF = (graphObj.y_axis_log_01 === 1);
    const legendTF = (graphObj.legend_01 === 1);
    const legendHideZeroCategoriesTF = (graphObj.legend_hide_zero_categories_01 === 1);

    //daily snapshot circle graph
    if(p_graphDataObj.circleGraphTrueTimeGraphFalse) {
      const titlesTF = true;
      const legendTitle = "Categories Field: '" + p_graphDataObj.categoriesFieldName + "'";
      const maxNumDecimals = 1;
      if(JSFUNC.in_array(graphObj.graph_type_p0_vb1_hb2_f3, [1,2,3])) {
        const horizontalTF = ((graphObj.graph_type_p0_vb1_hb2_f3 === 2) || (graphObj.graph_type_p0_vb1_hb2_f3 === 3));
        const mirroredTF = (graphObj.graph_type_p0_vb1_hb2_f3 === 3);
        return(
          <LibraryReact.BarGraph
            p_categoriesArrayOfObjs={p_graphDataObj.categoriesArrayOfObjs}
            p_yLogScaleTF={yAxisLogTF}
            p_horizontalTF={horizontalTF}
            p_mirroredTF={mirroredTF}
            p_valueFormat={p_graphDataObj.valueFormatString}
            p_heightPx={heightPx}
            p_titlesTF={titlesTF}
            p_legendTF={legendTF}
            p_legendTitle={legendTitle}
            p_legendTotalValueLabel={p_graphDataObj.totalNumCapturesOrMoneyFieldValueLabel}
            p_legendTotalClickReturnValue={p_graphDataObj.totalCaptureIDsArray}
            p_legendHideZeroCategoriesTF={legendHideZeroCategoriesTF}
            p_fontSizePx={fontSizePx}
            p_maxNumDecimals={maxNumDecimals}
            f_onClickSegment={this.props.f_onClickSegment}
          />
        );
      }

      return(
        <LibraryReact.CircleGraph
          p_categoriesArrayOfObjs={p_graphDataObj.categoriesArrayOfObjs}
          p_heightPx={heightPx}
          p_titlesTF={titlesTF}
          p_legendTF={legendTF}
          p_legendTitle={legendTitle}
          p_legendTotalValueLabel={p_graphDataObj.totalNumCapturesOrMoneyFieldValueLabel}
          p_legendTotalClickReturnValue={p_graphDataObj.totalCaptureIDsArray}
          p_legendHideZeroCategoriesTF={legendHideZeroCategoriesTF}
          p_fontSizePx={fontSizePx}
          p_vacantLabel="--Vacant--"
          p_vacantColor="333"
          p_maxNumDecimals={maxNumDecimals}
          p_innerCircleSize0to1={undefined}
          p_innerCircleColor="ddd"
          p_innerCircleTotalLabelTF={false}
          f_onClickSegment={this.props.f_onClickSegment}
        />
      );
    }

    //trend analyzer line graph
    var timeGraphType = "line"; //line0_bar1 is 0
    if(graphObj.line0_bar1 === 1) { timeGraphType = "lineCumulative"; }
    else if(graphObj.line0_bar1 === 2) { timeGraphType = "bar"; }
    else if(graphObj.line0_bar1 === 3) { timeGraphType = "barPercentOf100"; }

    return(
      <LibraryReact.TimeGraph
        p_categoriesArrayOfObjs={p_graphDataObj.categoriesArrayOfObjs}
        p_timeGraphType={timeGraphType}
        p_startDate={p_startDate}
        p_endDate={p_endDate}
        p_timeBins={graphObj.time_bins}
        p_yLogScaleTF={yAxisLogTF}
        p_valueFormat={p_graphDataObj.valueFormatString}
        p_heightPx={heightPx}
        p_yAxisWidthEm={undefined}
        p_fontSizePx={fontSizePx}
        p_legendTF={legendTF}
        p_legendHideZeroCategoriesTF={legendHideZeroCategoriesTF}
        f_onClickSegment={this.props.f_onClickSegment}
      />
    );
  }
}

const DivexecAddNewGraph = inject("DivexecMobx")(observer(
class DivexecAddNewGraph extends Component { //props:
  constructor(props) {
    super(props);
    this.state = {
      s_selectPublicGraphFloatingBoxIsOpenTF: false
    };
  }

  onclick_create_new_graph = () => {
    this.props.DivexecMobx.a_graphs_create_new_graph();
  }

  onclick_add_public_graph_open_floating_box = () => {
    this.setState({s_selectPublicGraphFloatingBoxIsOpenTF:true});
  }

  onclick_select_public_graph_to_add = (i_selectedPublicGraphDataObj) => {
    this.props.DivexecMobx.a_graphs_add_public_graph_as_replica(i_selectedPublicGraphDataObj);
    this.setState({s_selectPublicGraphFloatingBoxIsOpenTF:false});
  }

  onclick_close_public_graph_floating_box = () => {
    this.setState({s_selectPublicGraphFloatingBoxIsOpenTF:false});
  }

  render() {
    const selectPublicGraphFloatingBoxIsOpenTF = this.state.s_selectPublicGraphFloatingBoxIsOpenTF;

    const tabDailySnapshotTrueTrendAnalyzerFalse = this.props.DivexecMobx.c_tabDailySnapshotTrueTrendAnalyzerFalse;
    const creatingNewGraphTF = this.props.DivexecMobx.o_graphsCreatingNewGraphTF; //cannot click to create a 2nd graph while a 1st one is being created in the database (edit opens after successful insertion)
    const graphsSelectedTabAllPublicGraphsDataPerUserCreatorArrayOfObjs = this.props.DivexecMobx.c_graphsSelectedTabAllPublicGraphsDataPerUserCreatorArrayOfObjs;

    const graphTypeString = ((tabDailySnapshotTrueTrendAnalyzerFalse) ? ("Snapshot") : ("Trend")) + " Graph";

    if(creatingNewGraphTF) {
      return(
        <div className="flex11a displayFlexColumnHcVc bgLighterGray">
          <font className="font12 fontItalic fontBlueLighter">
            {"Creating New " + graphTypeString + "..."}
          </font>
        </div>
      );
    }

    const createNewGraphButtonClass = "flex11a displayFlexColumnHcVc tbPad lrMedPad border bevelBorderColors bgLighterGreenGradient hoverLightestGrayGradient cursorPointer overflowHidden textCenter";

    return(
      <>
        <div className="flex11a displayFlexColumn bgLighterGray">
          <div className="flex11a displayFlexColumn medFullPad borderB1bbb" style={{flexBasis:"100em"}}>
            <div
              className={createNewGraphButtonClass}
              title="Create a new custom graph from scratch on this Page"
              onClick={this.onclick_create_new_graph}>
              <font className="font15 fontItalic fontBlueLighter">
                {"[+] Create a New " + graphTypeString}
              </font>
            </div>
          </div>
          <div className="flex11a displayFlexColumn medFullPad" style={{flexBasis:"100em"}}>
            <div
              className={createNewGraphButtonClass}
              title="Select from a list of already built graphs that others have made Public. This will place a 'replica' of that graph which cannot be edited on this Page"
              onClick={this.onclick_add_public_graph_open_floating_box}>
              <font className="font15 fontItalic fontBlueLighter">
                {"[o] Add a Public " + graphTypeString}
              </font>
            </div>
          </div>
        </div>
        {(selectPublicGraphFloatingBoxIsOpenTF) &&
          <CEGeneralReact.FloatingBoxWithSaveCancel
            p_trblFlag="divexecSelectPublicGraph"
            p_title={"Select a Public " + graphTypeString + " to put on this Page"}
            f_onClickCancel={this.onclick_close_public_graph_floating_box}>
            {(graphsSelectedTabAllPublicGraphsDataPerUserCreatorArrayOfObjs.length === 0) ? (
              <CEGeneralReact.EmptyScreenGray>
                {"No one has created any Public " + graphTypeString + "s yet"}
              </CEGeneralReact.EmptyScreenGray>
            ) : (
              <div className="flex11a yScroll bgLighterGray">
                {graphsSelectedTabAllPublicGraphsDataPerUserCreatorArrayOfObjs.map((m_publicGraphDataPerUserCreatorObj) =>
                  <div className="hugeBottomPad borderB1bbb">
                    <div className="smallTopPad lrPad">
                      <font className="font13 fontBold fontTextLight">
                        {"Created by " + m_publicGraphDataPerUserCreatorObj.userNameMaskPlainText}
                      </font>
                    </div>
                    {m_publicGraphDataPerUserCreatorObj.userGraphsDataArrayOfObjs.map((m_graphDataObj) =>
                      <AddNewGraphSelectPublicGraphSingleItem
                        key={m_graphDataObj.id}
                        p_graphDataObj={m_graphDataObj}
                        f_onClick={this.onclick_select_public_graph_to_add}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </CEGeneralReact.FloatingBoxWithSaveCancel>
        }
      </>
    );
  }
}));

const AddNewGraphSelectPublicGraphSingleItem = inject("DivexecMobx", "UserMobx")(observer(
class AddNewGraphSelectPublicGraphSingleItem extends Component { //props: p_graphDataObj, f_onClick
  onclick_public_graph_item = () => {
    if(JSFUNC.is_function(this.props.f_onClick)) {
      this.props.f_onClick(this.props.p_graphDataObj);
    }
  }

  render() {
    const p_graphDataObj = this.props.p_graphDataObj;

    const c_userFontSizePx = this.props.UserMobx.c_userFontSizePx;

    const c_graphsSelectedTabUserAllAddedGraphIDsObjOfArrays = this.props.DivexecMobx.c_graphsSelectedTabUserAllAddedGraphIDsObjOfArrays;
    const c_graphsSelectedPageObj = this.props.DivexecMobx.c_graphsSelectedPageObj;

    const graphObj = p_graphDataObj.graphObj;
    const rowHeightEm = 15;
    const graphHeightPx = Math.round(rowHeightEm * c_userFontSizePx * 0.9);

    const pageNamesCommaWherePublicGraphAlreadyAdded = JSFUNC.get_first_array2_value_or_undefined_where_array1_matches_input(graphObj.id, c_graphsSelectedTabUserAllAddedGraphIDsObjOfArrays.graphIDsArray, c_graphsSelectedTabUserAllAddedGraphIDsObjOfArrays.pageNamesCommaArray);
    const publicGraphAlreadyAddedToAnyUserPageTF = (pageNamesCommaWherePublicGraphAlreadyAdded !== undefined);
    const publicGraphAlreadyAddedMessage = ((publicGraphAlreadyAddedToAnyUserPageTF) ? ("Already added to " + pageNamesCommaWherePublicGraphAlreadyAdded) : ("Not Yet Added"));

    return(
      <div
        className="displayFlexRow smallFullMargin smallFullPad border bevelBorderColors bgWhite hoverLighterBlueGradient cursorPointer"
        style={{height:rowHeightEm + "em", marginLeft:"2em"}}
        onClick={this.onclick_public_graph_item}>
        <div className="flex11a displayFlexColumn lrMedPad borderR1ddd" style={{flexBasis:"100em"}}>
          <div className="flex11a displayFlexColumnVc">
            <div className="microBottomMargin">
              <font className="font12 fontTextLight">
                {graphObj.graph_title}
              </font>
            </div>
            <div>
              <font className="fontItalic fontTextLighter">
                {"Created by " + graphObj.userNameMaskPlainText}
              </font>
            </div>
          </div>
          <div className="flex00a" title={publicGraphAlreadyAddedMessage}>
            <LibraryReact.Nowrap p_fontClass={((publicGraphAlreadyAddedToAnyUserPageTF) ? ("fontItalic fontTextLight") : ("fontBlue"))}>
              {publicGraphAlreadyAddedMessage}
            </LibraryReact.Nowrap>
          </div>
        </div>
        <div className="flex11a displayFlexRowVc lrMedMargin overflowHidden" style={{flexBasis:"150em"}}>
          <SnapshotGraphOrTrendGraphFromGraphObj
            p_graphDataObj={p_graphDataObj}
            p_startDate={JSFUNC.convert_relative_ymd_date_to_fixed_date_ymd(c_graphsSelectedPageObj.start_date)}
            p_endDate={JSFUNC.convert_relative_ymd_date_to_fixed_date_ymd(c_graphsSelectedPageObj.end_date)}
            p_heightPx={graphHeightPx}
            p_fontSizePx={c_userFontSizePx}
            f_onClickSegment={undefined}
          />
        </div>
      </div>
    );
  }
}));



const EditGraphFloatingBox = inject("CaptureExecMobx", "DivexecMobx", "DatabaseMobx", "UserMobx")(observer(
class EditGraphFloatingBox extends Component { //props:
  onswitch_graph_is_public = () => {
    const o_graphsEditingGraphID = this.props.DivexecMobx.o_graphsEditingGraphID;
    const editingGraphDataObj = this.props.DivexecMobx.c_graphsEditingGraphDataObj;
    const newValue01 = ((editingGraphDataObj.graphObj.public_01 === 1) ? (0) : (1));
    this.props.DivexecMobx.a_graphs_update_graph_field(o_graphsEditingGraphID, "public_01", newValue01, "i");
  }

  onclick_use_categories_button = (i_newValue01) => {
    const o_graphsEditingGraphID = this.props.DivexecMobx.o_graphsEditingGraphID;
    const fieldNamesArray = ["use_categories_field_01", "categories_field_id", "all_categories_01", "categories_option_ids_comma", "categories_include_not_set_01"];
    const valuesArray = [i_newValue01, -1, 1, "", 1];
    const idsbArray = ["i", "i", "i", "s", "i"];
    this.props.DivexecMobx.a_graphs_update_graph_field(o_graphsEditingGraphID, fieldNamesArray, valuesArray, idsbArray);
  }

  onswitch_categories_include_not_set = () => {
    const o_graphsEditingGraphID = this.props.DivexecMobx.o_graphsEditingGraphID;
    const c_graphsEditingGraphDataObj = this.props.DivexecMobx.c_graphsEditingGraphDataObj;
    const newValue01 = ((c_graphsEditingGraphDataObj.graphObj.categories_include_not_set_01 === 1) ? (0) : (1));
    this.props.DivexecMobx.a_graphs_update_graph_field(o_graphsEditingGraphID, "categories_include_not_set_01", newValue01, "i");
  }

  onswitch_use_all_categories = () => {
    const o_graphsEditingGraphID = this.props.DivexecMobx.o_graphsEditingGraphID;
    const c_graphsEditingGraphDataObj = this.props.DivexecMobx.c_graphsEditingGraphDataObj;
    const newValue01 = ((c_graphsEditingGraphDataObj.graphObj.all_categories_01 === 1) ? (0) : (1));
    this.props.DivexecMobx.a_graphs_update_graph_field(o_graphsEditingGraphID, "all_categories_01", newValue01, "i");
  }

  onswitch_include_legend = () => {
    const o_graphsEditingGraphID = this.props.DivexecMobx.o_graphsEditingGraphID;
    const c_graphsEditingGraphDataObj = this.props.DivexecMobx.c_graphsEditingGraphDataObj;
    const newValue01 = ((c_graphsEditingGraphDataObj.graphObj.legend_01 === 1) ? (0) : (1));
    this.props.DivexecMobx.a_graphs_update_graph_field(o_graphsEditingGraphID, "legend_01", newValue01, "i");
  }

  onswitch_legend_hide_zero_categories = () => {
    const o_graphsEditingGraphID = this.props.DivexecMobx.o_graphsEditingGraphID;
    const c_graphsEditingGraphDataObj = this.props.DivexecMobx.c_graphsEditingGraphDataObj;
    const newValue01 = ((c_graphsEditingGraphDataObj.graphObj.legend_hide_zero_categories_01 === 1) ? (0) : (1));
    this.props.DivexecMobx.a_graphs_update_graph_field(o_graphsEditingGraphID, "legend_hide_zero_categories_01", newValue01, "i");
  }

  onclick_time_bins_button = (i_newValue) => {
    const o_graphsEditingGraphID = this.props.DivexecMobx.o_graphsEditingGraphID;
    this.props.DivexecMobx.a_graphs_update_graph_field(o_graphsEditingGraphID, "time_bins", i_newValue, "s");
  }

  onclick_num_captures_or_capture_money_field_button = (i_newValue) => {
    const o_graphsEditingGraphID = this.props.DivexecMobx.o_graphsEditingGraphID;
    this.props.DivexecMobx.a_graphs_update_graph_field(o_graphsEditingGraphID, "num_captures_m2_or_money_field_id", i_newValue, "i");
  }

  onclick_y_axis_linear_log_button = (i_newValue01) => {
    const o_graphsEditingGraphID = this.props.DivexecMobx.o_graphsEditingGraphID;
    this.props.DivexecMobx.a_graphs_update_graph_field(o_graphsEditingGraphID, "y_axis_log_01", i_newValue01, "i");
  }

  onclick_close_editing_graph = () => {
    this.props.DivexecMobx.a_graphs_set_editing_graph_id(undefined);
  }

  render() {
    const c_isMobileOrTabletTF = this.props.CaptureExecMobx.c_isMobileOrTabletTF;
    const tabDailySnapshotTrueTrendAnalyzerFalse = this.props.DivexecMobx.c_tabDailySnapshotTrueTrendAnalyzerFalse;
    const editingGraphDataObj = this.props.DivexecMobx.c_graphsEditingGraphDataObj;
    const selectedPageObj = this.props.DivexecMobx.c_graphsSelectedPageObj;
    const c_userFontSizePx = this.props.UserMobx.c_userFontSizePx;
    const c_userCanCreatePublicItemsTF = this.props.UserMobx.c_userCanCreatePublicItemsTF;

    if(editingGraphDataObj === undefined) {
      return(null);
    }

    const graphObj = editingGraphDataObj.graphObj;

    const isReplicaOfPublicGraphTF = (graphObj.replica_of_graph_id > 0);
    const userCanEditTF = (!isReplicaOfPublicGraphTF);
    const useCategoriesFieldTF = (graphObj.use_categories_field_01 !== 0);
    const chooseSpecificCategoriesSelectedTF = (graphObj.all_categories_01 === 0);

    var selectedGraphTypePieTF = false;
    var selectedGraphTypeVerticalBarTF = false;
    var selectedGraphTypeHorizontalBarTF = false;
    var selectedGraphTypeFunnelTF = false;
    if(graphObj.graph_type_p0_vb1_hb2_f3 === 0) { selectedGraphTypePieTF = true; }
    else if(graphObj.graph_type_p0_vb1_hb2_f3 === 1) { selectedGraphTypeVerticalBarTF = true; }
    else if(graphObj.graph_type_p0_vb1_hb2_f3 === 2) { selectedGraphTypeHorizontalBarTF = true; }
    else if(graphObj.graph_type_p0_vb1_hb2_f3 === 3) { selectedGraphTypeFunnelTF = true; }

    const selectedGraphTypeHasYAxisTF = (!tabDailySnapshotTrueTrendAnalyzerFalse || selectedGraphTypeVerticalBarTF || selectedGraphTypeHorizontalBarTF || selectedGraphTypeFunnelTF);
    
    //daily snapshot always selects field for categories, trend analyzer selects field for categories if 'multiple' option is selected (all hidden when 'single' is selected)
    const showCategoriesOptionsTF = (tabDailySnapshotTrueTrendAnalyzerFalse || useCategoriesFieldTF);

    //custom mask the selected filter preset name because a public graph pulled down may be using a user's filter that this user does not have access to (thus it would show up as does not exist, this ensures every filter is masked with its name)
    const selectedCaptureFilterPresetMask = this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(graphObj.filter_preset_id, this.props.DivexecMobx.c_graphsSelectFromAllFilterPresetsFieldTypeObj);

    //floating box title
    var editingGraphFloatingBoxTitle = "Editing Graph #" + graphObj.sort + " on Page '" + selectedPageObj.name + "'";
    if(JSFUNC.string_is_filled_out_tf(graphObj.graph_title)) {
      editingGraphFloatingBoxTitle += " - " + graphObj.graph_title;
    }
    else {
      editingGraphFloatingBoxTitle += " [New Unnamed Graph]";
    }

    //compute the fixed start/end date values for trend analyzer graphs
    var selectedPageStartDateFixed = undefined;
    var selectedPageEndDateFixed = undefined;
    if(!tabDailySnapshotTrueTrendAnalyzerFalse) {
      selectedPageStartDateFixed = JSFUNC.convert_relative_ymd_date_to_fixed_date_ymd(selectedPageObj.start_date);
      selectedPageEndDateFixed = JSFUNC.convert_relative_ymd_date_to_fixed_date_ymd(selectedPageObj.end_date);
    }

    //graph title
    const graphTitleComponent = (
      <EditGraphFloatingBoxSingleField
        p_fieldDbName="graph_title"
        p_fieldIdsb="s"
        p_fieldDisplayName="Graph Title"
        p_fieldTypeObj={this.props.DatabaseMobx.c_genericTextFieldTypeObj}
        p_valueRaw={graphObj.graph_title}
        p_fieldWidth="6em"
        p_valueClass="fontBold font11 fontTextLight"
        p_valueIsEditableTFU={userCanEditTF}
      />
    );

    //select graph type for daily snapshots
    const graphTypeIconHeightPx = (5 * c_userFontSizePx);
    var selectGraphTypeButtonsComponent = null;
    if(tabDailySnapshotTrueTrendAnalyzerFalse) {
      const dailySnapshotIconCategoriesArrayOfObjs = [
        {valueRaw:70, color:JSFUNC.unique_color(0)},
        {valueRaw:30, color:JSFUNC.unique_color(1)},
        {valueRaw:40, color:JSFUNC.unique_color(2)},
        {valueRaw:10, color:JSFUNC.unique_color(3)}
      ];
      
      selectGraphTypeButtonsComponent = (
        <div className="displayFlexRow flexWrap">
          <EditGraphFloatingBoxGraphTypeIconShell p_graphType0123={0} p_label="Pie Graph" p_selectedTF={selectedGraphTypePieTF} p_canEditTF={(!isReplicaOfPublicGraphTF)}>
            <LibraryReact.CircleGraph
              p_categoriesArrayOfObjs={dailySnapshotIconCategoriesArrayOfObjs}
              p_heightPx={graphTypeIconHeightPx}
              p_titlesTF={false}
              p_legendTF={false}
              p_vacantLabel="--Vacant--"
              p_vacantColor="333"
              p_maxNumDecimals={1}
            />
          </EditGraphFloatingBoxGraphTypeIconShell>
          <EditGraphFloatingBoxGraphTypeIconShell p_graphType0123={1} p_label="Vertical Bar" p_selectedTF={selectedGraphTypeVerticalBarTF} p_canEditTF={(!isReplicaOfPublicGraphTF)}>
            <LibraryReact.BarGraph
              p_categoriesArrayOfObjs={dailySnapshotIconCategoriesArrayOfObjs}
              p_yAxisWidthEm={2}
              p_horizontalTF={false}
              p_mirroredTF={false}
              p_heightPx={graphTypeIconHeightPx}
              p_titlesTF={false}
              p_legendTF={false}
              p_maxNumDecimals={1}
            />
          </EditGraphFloatingBoxGraphTypeIconShell>
          <EditGraphFloatingBoxGraphTypeIconShell p_graphType0123={2} p_label="Horizontal Bar" p_selectedTF={selectedGraphTypeHorizontalBarTF} p_canEditTF={(!isReplicaOfPublicGraphTF)}>
            <LibraryReact.BarGraph
              p_categoriesArrayOfObjs={dailySnapshotIconCategoriesArrayOfObjs}
              p_horizontalTF={true}
              p_mirroredTF={false}
              p_heightPx={graphTypeIconHeightPx}
              p_titlesTF={false}
              p_legendTF={false}
              p_maxNumDecimals={1}
            />
          </EditGraphFloatingBoxGraphTypeIconShell>
          <EditGraphFloatingBoxGraphTypeIconShell p_graphType0123={3} p_label="Funnel" p_selectedTF={selectedGraphTypeFunnelTF} p_canEditTF={(!isReplicaOfPublicGraphTF)}>
            <LibraryReact.BarGraph
              p_categoriesArrayOfObjs={dailySnapshotIconCategoriesArrayOfObjs}
              p_horizontalTF={true}
              p_mirroredTF={true}
              p_heightPx={graphTypeIconHeightPx}
              p_titlesTF={false}
              p_legendTF={false}
              p_maxNumDecimals={1}
            />
          </EditGraphFloatingBoxGraphTypeIconShell>
        </div>
      );
    }
    else {
      const nowYearInt = JSFUNC.now_year_int();

      const trendAnalyzerIconCategoriesArrayOfObjs = [
        {
          idDateValueArrayOfObjs:[
            {id:1, date:(nowYearInt - 2) + "-06-06", value:150},
            {id:2, date:(nowYearInt - 1) + "-06-06", value:50},
            {id:3, date:nowYearInt + "-06-06", value:480}
          ],
          color:JSFUNC.unique_color(0),
          label:"Category One"
        },
        {
          idDateValueArrayOfObjs:[
            {id:1, date:(nowYearInt - 2) + "-06-06", value:40},
            {id:2, date:(nowYearInt - 1) + "-06-06", value:200},
            {id:3, date:nowYearInt + "-06-06", value:170}
          ],
          color:JSFUNC.unique_color(1),
          label:"Category Two"
        }
      ];

      const timeGraphStartDate = (nowYearInt - 2) + "-01-01";
      const timeGraphEndDate = nowYearInt + "-12-31";
      const timeGraphYAxisWidthEm = 3;
      const timeGraphFontSizePx = (c_userFontSizePx - 1);
      
      selectGraphTypeButtonsComponent = (
        <div className="displayFlexRow flexWrap">
          <EditGraphFloatingBoxGraphTypeIconShell p_timeGraphType0123={0} p_label="Line" p_selectedTF={(graphObj.line0_bar1 === 0)} p_canEditTF={(!isReplicaOfPublicGraphTF)}>
            <LibraryReact.TimeGraph
              p_categoriesArrayOfObjs={trendAnalyzerIconCategoriesArrayOfObjs}
              p_timeGraphType="line"
              p_startDate={timeGraphStartDate}
              p_endDate={timeGraphEndDate}
              p_timeBins="yearly"
              p_yLogScaleTF={false}
              p_valueFormat="number"
              p_heightPx={graphTypeIconHeightPx}
              p_yAxisWidthEm={timeGraphYAxisWidthEm}
              p_fontSizePx={timeGraphFontSizePx}
              p_legendTF={false}
              f_onClickSegment={undefined}
            />
          </EditGraphFloatingBoxGraphTypeIconShell>
          <EditGraphFloatingBoxGraphTypeIconShell p_timeGraphType0123={1} p_label="Line (Cumulative)" p_selectedTF={(graphObj.line0_bar1 === 1)} p_canEditTF={(!isReplicaOfPublicGraphTF)}>
            <LibraryReact.TimeGraph
              p_categoriesArrayOfObjs={trendAnalyzerIconCategoriesArrayOfObjs}
              p_timeGraphType="lineCumulative"
              p_startDate={timeGraphStartDate}
              p_endDate={timeGraphEndDate}
              p_timeBins="yearly"
              p_yLogScaleTF={false}
              p_valueFormat="number"
              p_heightPx={graphTypeIconHeightPx}
              p_yAxisWidthEm={timeGraphYAxisWidthEm}
              p_fontSizePx={timeGraphFontSizePx}
              p_legendTF={false}
              f_onClickSegment={undefined}
            />
          </EditGraphFloatingBoxGraphTypeIconShell>
          <EditGraphFloatingBoxGraphTypeIconShell p_timeGraphType0123={2} p_label="Bar" p_selectedTF={(graphObj.line0_bar1 === 2)} p_canEditTF={(!isReplicaOfPublicGraphTF)}>
            <LibraryReact.TimeGraph
              p_categoriesArrayOfObjs={trendAnalyzerIconCategoriesArrayOfObjs}
              p_timeGraphType="bar"
              p_startDate={timeGraphStartDate}
              p_endDate={timeGraphEndDate}
              p_timeBins="yearly"
              p_yLogScaleTF={false}
              p_valueFormat="number"
              p_heightPx={graphTypeIconHeightPx}
              p_yAxisWidthEm={timeGraphYAxisWidthEm}
              p_fontSizePx={timeGraphFontSizePx}
              p_legendTF={false}
              f_onClickSegment={undefined}
            />
          </EditGraphFloatingBoxGraphTypeIconShell>
          <EditGraphFloatingBoxGraphTypeIconShell p_timeGraphType0123={3} p_label="Bar (Relative %)" p_selectedTF={(graphObj.line0_bar1 === 3)} p_canEditTF={(!isReplicaOfPublicGraphTF)}>
            <LibraryReact.TimeGraph
              p_categoriesArrayOfObjs={trendAnalyzerIconCategoriesArrayOfObjs}
              p_timeGraphType="barPercentOf100"
              p_startDate={timeGraphStartDate}
              p_endDate={timeGraphEndDate}
              p_timeBins="yearly"
              p_yLogScaleTF={false}
              p_valueFormat="number"
              p_heightPx={graphTypeIconHeightPx}
              p_yAxisWidthEm={timeGraphYAxisWidthEm}
              p_fontSizePx={timeGraphFontSizePx}
              p_legendTF={false}
              f_onClickSegment={undefined}
            />
          </EditGraphFloatingBoxGraphTypeIconShell>
        </div>
      );
    }

    const captureFilterPresetComponent = (
      <div className="displayFlexRow">
        <div className="flex11a" style={{flexBasis:"100em", maxWidth:"50em"}}>
          <EditGraphFloatingBoxSectionLabel p_label="Capture Filter Applied" />
          <div>
            <EditGraphFloatingBoxSingleField
              p_fieldDbName="filter_preset_id"
              p_fieldIdsb="i"
              p_fieldDisplayName="Capture Filter Preset"
              p_fieldTypeObj={this.props.DivexecMobx.c_graphsSelectFilterPresetFieldTypeObj}
              p_valueRaw={graphObj.filter_preset_id}
              p_valueMask={selectedCaptureFilterPresetMask}
              p_fieldWidth="10em"
              p_valueIsEditableTFU={userCanEditTF}
            />
          </div>
        </div>
        {(userCanEditTF) &&
          <div className="flex00a displayFlexColumnHcVc lrMedMargin">
            <CapturesReact.MasterPresetsEditorButton
              p_selectedPresetType="filter"
              p_selectedPresetID={graphObj.filter_preset_id}
            />
          </div>
        }
        <div className="flex11a" />
      </div>
    );

    var dateFieldComponent = null;
    if(!tabDailySnapshotTrueTrendAnalyzerFalse) { //select a date field only for trend graphs
      dateFieldComponent = (
        <>
          <EditGraphFloatingBoxHorizDividerLine />
          <EditGraphFloatingBoxSectionLabel p_label="Date Field used from each Capture" />
          <div style={{maxWidth:"50em"}}>
            <EditGraphFloatingBoxSingleField
              p_fieldDbName="date_field_id"
              p_fieldIdsb="i"
              p_fieldDisplayName="Date Field"
              p_fieldTypeObj={this.props.DatabaseMobx.c_selectCaptureDateFieldFieldTypeObj}
              p_valueRaw={graphObj.date_field_id}
              p_valueMask={undefined}
              p_fieldWidth="10em"
              p_valueIsEditableTFU={userCanEditTF}
            />
          </div>
        </>
      );
    }

    const allCategorySelectsComponent = (
      <>
        <EditGraphFloatingBoxSectionLabel p_label="Selected Field for Categories" />
        {(!tabDailySnapshotTrueTrendAnalyzerFalse) &&
          <>
            <CEGeneralReact.TabsList
              p_tabDbNamesArray={[0, 1]}
              p_tabDisplayNamesArray={["Single Data Series", "Multiple Data Series\n(Using Category Field Below)"]}
              p_selectedTabDbName={graphObj.use_categories_field_01}
              p_tabHeight="3em"
              p_textMaxHeight="3em"
              p_tabWidth="15em"
              p_unselectedFontClass=""
              p_unselectedBgClass="bgLighterGrayGradient"
              p_selectedBgClass="bgBlueGradient fontWhite"
              p_canEditTF={userCanEditTF}
              f_onSelect={this.onclick_use_categories_button}
            />
            <div className="smallTopMargin" />
          </>
        }
        {(showCategoriesOptionsTF) &&
          <>
            <EditGraphFloatingBoxSingleField
              p_fieldDbName="categories_field_id"
              p_fieldIdsb="i"
              p_fieldDisplayName="Capture Field used for Categories"
              p_fieldTypeObj={this.props.DivexecMobx.c_selectCategoryCaptureFieldFieldTypeObj}
              p_valueRaw={graphObj.categories_field_id}
              p_valueMask={undefined}
              p_fieldWidth="16em"
              p_valueIsEditableTFU={userCanEditTF}
            />
            <div className="smallTopMargin border1ddd bgLightestGray tbPad lrMedPad">
              <div className="tbPad" style={{maxWidth:"25em"}}>
                <CEGeneralReact.VerticalSwitch
                  p_valueArray={[1, 0]}
                  p_displayArray={["Use All Categories", "Choose Specific Categories Below"]}
                  p_selectedValue={graphObj.all_categories_01}
                  p_rowHeightEm={1.7}
                  p_sizeMultiplier={undefined}
                  f_onSelect={((userCanEditTF) ? (this.onswitch_use_all_categories) : (undefined))}
                />
              </div>
              {(chooseSpecificCategoriesSelectedTF) &&
                <EditGraphFloatingBoxSingleField
                  p_fieldDbName="categories_option_ids_comma"
                  p_fieldIdsb="s"
                  p_fieldDisplayName="Specified Categories"
                  p_fieldTypeObj={this.props.DivexecMobx.c_graphsSelectMultiCategoriesOptionsFieldTypeObj}
                  p_valueRaw={graphObj.categories_option_ids_comma}
                  p_fieldWidth="10.9em"
                  p_valueIsEditableTFU={userCanEditTF}
                />
              }
              <div className="displayFlexRowVc tbMicroPad">
                <div className="flex00a" style={{flexBasis:"11.2em"}}>
                  <font className="fontBlue">
                    {"'--Not Set--' Category"}
                  </font>
                </div>
                <div className="flex11a">
                  <font className="font09">
                    <CEGeneralReact.SwitchWithTextAndConfirmBox
                      p_isOnTF={(graphObj.categories_include_not_set_01 === 1)}
                      p_onText="[default] Include '--Not Set--' Category"
                      p_offText="Don't Include '--Not Set--'"
                      p_sizeEm={2.8}
                      p_title="Use this switch to either include or not include '--Not Set--' as the last category, counting all Captures that have the selected Capture Field not set as any of the options"
                      f_onSwitch={((userCanEditTF) ? (this.onswitch_categories_include_not_set) : (undefined))}
                    />
                  </font>
                </div>
              </div>
            </div>
          </>
        }
      </>
    );

    const allGraphOptionSelectsComponent = (
      <>
        <EditGraphFloatingBoxSectionLabel p_label="Graph Options" />
        <div className="displayFlexRowVc tbMicroPad" title="Choose whether to include a Legend for all of the Categories to the right of the graph">
          <div className="flex00a" style={{flexBasis:"10em"}}>
            <font className="fontBlue">
              {"Legend"}
            </font>
          </div>
          <div className="flex11a">
            <CEGeneralReact.SwitchWithTextAndConfirmBox
              p_isOnTF={(graphObj.legend_01 === 1)}
              p_onText="Include Legend"
              p_offText="No Legend"
              p_sizeEm={3}
              f_onSwitch={((userCanEditTF) ? (this.onswitch_include_legend) : (undefined))}
            />
          </div>
        </div>
        <div className="displayFlexRowVc smallTopMargin microBottomMargin" title="If switched on, hide all Categories in the Legend that have 0 count or $0 to simplify viewing Categories with data">
          <div className="flex00a" style={{flexBasis:"10em"}}>
            <font className="fontBlue">
              {"Legend Categories"}
            </font>
          </div>
          <div className="flex11a">
            {(graphObj.legend_01 === 1) ? (
              <CEGeneralReact.SwitchWithTextAndConfirmBox
                p_isOnTF={(graphObj.legend_hide_zero_categories_01 === 1)}
                p_onText="Hide Categories with 0 Value"
                p_offText="[default] Show all Categories"
                p_sizeEm={3}
                f_onSwitch={((userCanEditTF) ? (this.onswitch_legend_hide_zero_categories) : (undefined))}
              />
            ) : (
              <font className="fontItalic fontTextLighter">
                {"N/A"}
              </font>
            )}
          </div>
        </div>
        {(!tabDailySnapshotTrueTrendAnalyzerFalse) &&
          <>
            <div className="displayFlexRowVc">
              <div className="flex00a" style={{flexBasis:"10em"}}>
                <font className="fontBlue">
                  {"Timeline Bin Size"}
                </font>
              </div>
              <div className="flex11a tbPad">
                <CEGeneralReact.TabsList
                  p_tabDbNamesArray={["yearly", "monthly", "weekly"]}
                  p_tabDisplayNamesArray={["Yearly", "Monthly", "Weekly"]}
                  p_selectedTabDbName={graphObj.time_bins}
                  p_tabHeight="1.6em"
                  p_textMaxHeight="1.5em"
                  p_tabWidth="4.8em"
                  p_unselectedFontClass=""
                  p_unselectedBgClass="bgLighterGrayGradient"
                  p_selectedBgClass="bgBlueGradient fontWhite"
                  p_canEditTF={userCanEditTF}
                  p_tabSpacing="0.3em"
                  f_onSelect={this.onclick_time_bins_button}
                />
              </div>
            </div>
          </>
        }
        <div className="displayFlexRowVc">
          <div className="flex00a" style={{flexBasis:"10em"}}>
            <font className="fontBlue">
              {"Count Field"}
            </font>
          </div>
          <div className="flex11a tbPad">
            <CEGeneralReact.TabsList
              p_tabDbNamesArray={[-2, -1]}
              p_tabDisplayNamesArray={["# Captures", "Select Money Field"]}
              p_selectedTabDbName={((graphObj.num_captures_m2_or_money_field_id === -2) ? (-2) : (-1))}
              p_tabHeight="1.6em"
              p_textMaxHeight="1.5em"
              p_tabWidth="11em"
              p_unselectedFontClass=""
              p_unselectedBgClass="bgLighterGrayGradient"
              p_selectedBgClass="bgBlueGradient fontWhite"
              p_canEditTF={userCanEditTF}
              p_tabSpacing="0.4em"
              f_onSelect={this.onclick_num_captures_or_capture_money_field_button}
            />
            {(graphObj.num_captures_m2_or_money_field_id !== -2) &&
              <div className="smallTopPad">
                {(userCanEditTF) ? (
                  <CEGeneralReact.GenericInputOrSelectFromInputType
                    p_fieldTypeObj={this.props.DatabaseMobx.c_selectCaptureRevenueCardMoneyFieldFieldTypeObj}
                    p_valueRaw={graphObj.num_captures_m2_or_money_field_id}
                    f_onChangeOrOnSelect={this.onclick_num_captures_or_capture_money_field_button}
                  />
                ) : (
                  <font className="fontItalic fontTextLight">
                    {this.props.DatabaseMobx.value_mask_from_value_raw_and_field_type_obj(graphObj.num_captures_m2_or_money_field_id, this.props.DatabaseMobx.c_selectCaptureRevenueCardMoneyFieldFieldTypeObj)}
                  </font>
                )}
              </div>
            }
          </div>
        </div>
        {(selectedGraphTypeHasYAxisTF) &&
          <div className="displayFlexRowVc">
            <div className="flex00a" style={{flexBasis:"10em"}}>
              <font className="fontBlue">
                {"Y-axis"}
              </font>
            </div>
            <div className="flex11a tbPad">
              <CEGeneralReact.TabsList
                p_tabDbNamesArray={[0, 1]}
                p_tabDisplayNamesArray={[<font>{"Linear"}<font className="font09">{"\n0,20,40,60,80"}</font></font>, <font>{"Logarithmic"}<font className="font09">{"\n1,10,100,1k,10k"}</font></font>]}
                p_selectedTabDbName={graphObj.y_axis_log_01}
                p_tabHeight="2.6em"
                p_textMaxHeight="2.5em"
                p_tabWidth="8em"
                p_unselectedFontClass=""
                p_unselectedBgClass="bgLighterGrayGradient"
                p_selectedBgClass="bgBlueGradient fontWhite"
                p_canEditTF={userCanEditTF}
                f_onSelect={this.onclick_y_axis_linear_log_button}
              />
            </div>
          </div>
        }
      </>
    );

    var graphPreviewMaxHeightEm = selectedPageObj.graph_height_em;
    if(graphPreviewMaxHeightEm > 35) { //limit height of the preview graph in case creating one that is 80em tall, hard to see within editor
      graphPreviewMaxHeightEm = 35;
    }

    return(
      <CEGeneralReact.FloatingBoxWithSaveCancel
        p_trblFlag="divexecGraphsEdit"
        p_title={editingGraphFloatingBoxTitle}
        f_onClickSave={this.onclick_close_editing_graph}>
        <div className="flex11a yScroll medFullPad">
          {(isReplicaOfPublicGraphTF) &&
            <div className="medBottomMargin displayFlexColumnHcVc">
              <div className="border1ddd border bevelBorderColorBlue borderRadius05 bgLightestBlue tbPad lrMedPad textCenter">
                <font className="fontItalic fontBlue">
                  {"This is a replica of a Public Graph created by " + this.props.DatabaseMobx.user_name_mask_plaintext_from_user_id(graphObj.created_by_user_id) + " and cannot be edited"}
                </font>
              </div>
            </div>
          }
          <div className="displayFlexRow">
            <div className="flex11a displayFlexColumnVc" style={{flexBasis:"150em"}}>
              {graphTitleComponent}
            </div>
            {(c_userCanCreatePublicItemsTF) &&
              <>
                <div className="borderR1ddd lrMargin" />
                  <div className="flex11a displayFlexColumnVc" style={{flexBasis:"100em"}}>
                    <div className="displayFlexRowVc">
                      <div className="flex00a" style={{flexBasis:"9em"}}>
                        <font className="fontBlue">
                          {"Graph is Public?"}
                        </font>
                      </div>
                      <div className="flex11a">
                        <CEGeneralReact.SwitchWithTextAndConfirmBox
                          p_isOnTF={(graphObj.public_01 === 1)}
                          p_sizeEm={3}
                          p_onText="Public to everyone"
                          p_offText="Private to me"
                          p_title="Public graphs can be added to other users' pages while not allowing them to edit it"
                          f_onSwitch={((isReplicaOfPublicGraphTF) ? (undefined) : (this.onswitch_graph_is_public))}
                        />
                      </div>
                    </div>
                  </div>
              </>
            }
          </div>
          <EditGraphFloatingBoxHorizDividerLine />
          <EditGraphFloatingBoxSectionLabel p_label={((tabDailySnapshotTrueTrendAnalyzerFalse) ? ("Graph Type") : ("Time Graph Type"))} />
          {selectGraphTypeButtonsComponent}
          <EditGraphFloatingBoxHorizDividerLine />
          {captureFilterPresetComponent}
          {dateFieldComponent}
          <EditGraphFloatingBoxHorizDividerLine />
          {(c_isMobileOrTabletTF) ? (
            <>
              {allCategorySelectsComponent}
              <EditGraphFloatingBoxHorizDividerLine />
              {allGraphOptionSelectsComponent}
            </>
          ) : (
            <div className="displayFlexRow">
              <div className="flex11a" style={{flexBasis:"100em"}}>
                {allCategorySelectsComponent}
              </div>
              <div className="flex00a borderR1ddd lrMedMargin" />
              <div className="flex11a" style={{flexBasis:"100em"}}>
                {allGraphOptionSelectsComponent}
              </div>
            </div>
          )}
          <EditGraphFloatingBoxHorizDividerLine />
          <div className="displayFlexColumn overflowHidden border bevelBorderColors boxShadowGreenDarkGreenHover" style={{height:graphPreviewMaxHeightEm + "em", margin:"2em 20%"}}>
            <DivexecGraph
              p_graphDataObj={editingGraphDataObj}
              p_startDate={selectedPageStartDateFixed}
              p_endDate={selectedPageEndDateFixed}
              p_canEditDeleteReorderTF={false}
              p_canClickSegmentsTF={false}
              p_isOnlyItemTF={true}
              p_isLastItemTF={true}
              p_graphHeightEm={graphPreviewMaxHeightEm}
              p_selectedPageID={selectedPageObj.id}
            />
          </div>
        </div>
      </CEGeneralReact.FloatingBoxWithSaveCancel>
    );
  }
}));

const EditGraphFloatingBoxGraphTypeIconShell = inject("DivexecMobx")(observer(
class EditGraphFloatingBoxGraphTypeIconShell extends Component { //props: p_graphType0123, p_timeGraphType0123, p_label, p_selectedTF, p_canEditTF, children
  onclick_graph_type_icon = () => {
    const p_graphType0123 = this.props.p_graphType0123;
    const p_timeGraphType0123 = this.props.p_timeGraphType0123;

    const o_graphsEditingGraphID = this.props.DivexecMobx.o_graphsEditingGraphID;

    var fieldDbName = "graph_type_p0_vb1_hb2_f3";
    var selectedValue0123 = p_graphType0123;
    if(p_timeGraphType0123 !== undefined) {
      fieldDbName = "line0_bar1";
      selectedValue0123 = p_timeGraphType0123;
    }
    this.props.DivexecMobx.a_graphs_update_graph_field(o_graphsEditingGraphID, fieldDbName, selectedValue0123, "i");
  }

  render() {
    const p_graphType0123 = this.props.p_graphType0123;
    const p_timeGraphType0123 = this.props.p_timeGraphType0123;
    const p_label = this.props.p_label;
    const p_selectedTF = this.props.p_selectedTF;
    const p_canEditTF = this.props.p_canEditTF;

    const canClickTF = (p_canEditTF && !p_selectedTF);

    return(
      <div
        className={"flex00a displayFlexColumn tbMargin lrMedMargin border bevelBorderColors borderRadius10 " + ((p_selectedTF) ? ("bgLightBlueGradient") : ("bgLighterGrayGradient")) + " " + ((canClickTF) ? ("hoverLighterBlueGradient cursorPointer") : (""))}
        style={{flexBasis:"12em", height:"8.5em"}}
        onClick={((canClickTF) ? (this.onclick_graph_type_icon) : (undefined))}>
        <div className="flex00a textCenter tbMicroPad borderB1bbb">
          <font className="font12 fontBold fontTextLighter">
            {p_label}
          </font>
        </div>
        <div className={"flex11a displayFlexColumnHcVc lrMedPad " + ((p_selectedTF) ? ("") : (""))}>
          {this.props.children}
        </div>
      </div>
    );
  }
}));


function EditGraphFloatingBoxHorizDividerLine(props) { //props: 
  return(
    <div className="borderT1ddd tbMargin" />
  );
}

function EditGraphFloatingBoxSectionLabel(props) { //props: p_label
  return(
    <div className="microBottomMargin">
      <font className="font12 fontItalic fontTextLighter">
        {props.p_label}
      </font>
    </div>
  );
}

const EditGraphFloatingBoxSingleField = inject("DivexecMobx")(observer(
class EditGraphFloatingBoxSingleField extends Component { //props: p_fieldDbName, p_fieldIdsb, p_fieldDisplayName, p_fieldTypeObj, p_valueRaw, p_valueMask, p_fieldWidth, p_valueClass, p_valueIsEditableTFU
  onsave_changed_graph_value = (i_newValue) => {
    const fieldName = this.props.p_fieldDbName;
    const fieldIdsb = this.props.p_fieldIdsb;

    var fieldNamesArray = fieldName;
    var valuesArray = i_newValue;
    var idsbArray = fieldIdsb;

    if(fieldName === "categories_field_id") {
      fieldNamesArray = ["categories_field_id", "categories_option_ids_comma"];
      valuesArray = [i_newValue, ""];
      idsbArray = ["i", "s"];
    }

    this.props.DivexecMobx.a_graphs_update_graph_field(this.props.DivexecMobx.o_graphsEditingGraphID, fieldNamesArray, valuesArray, idsbArray);
  }

  render() {
    const fieldWidth = JSFUNC.prop_value(this.props.p_fieldWidth, "17em");

    const valueIsEditableTFU = ((this.props.p_valueIsEditableTFU === true) ? (true) : (undefined));

    return(
      <CEGeneralReact.CaptureExecFieldEditSaveCancel
        p_ceEditItemString={"divexecGraph_" + this.props.p_fieldDbName}
        p_fieldDisplayName={this.props.p_fieldDisplayName}
        p_fieldTypeObj={this.props.p_fieldTypeObj}
        p_valueRaw={this.props.p_valueRaw}
        p_valueMask={this.props.p_valueMask}
        p_valueIsEditableTFU={valueIsEditableTFU}
        p_containerClass="tbMicroPad"
        p_fieldClass="fontBlue"
        p_fieldWidth={fieldWidth}
        p_valueClass={this.props.p_valueClass}
        f_onSaveChanged={this.onsave_changed_graph_value}
      />
    );
  }
}));


function DivexecGraphsPagesReplicaIcon(props) { //props: p_title
  return(
    <div
      className="displayFlexColumnHcVc border1ddd bgBlueGradient"
      style={{height:"1.3em", width:"1.3em"}}
      title={props.p_title}>
      <font className="font08 fontWhite">
        {"R"}
      </font>
    </div>
  );
}






//=============================================================================================================================================================================
//=============================================================================================================================================================================


//Financial Projections
export const FinancialProjections = inject("CaptureExecMobx", "DivexecMobx", "DatabaseMobx", "UserMobx")(observer(
class FinancialProjections extends Component {
  onclick_export_finproj_to_excel_xml = () => {
    const finProjObj = this.props.DivexecMobx.c_finProjObj;
    const finProjXmlString = this.props.DivexecMobx.generate_finproj_excel_xml_string(finProjObj);
    const downloadFileNameAndExt = "Finanacial Projections " + JSFUNC.now_date() + " - filter " + finProjObj.filterName + ".xml";
    JSFUNC.browser_offer_file_download_from_file_data_string(finProjXmlString, downloadFileNameAndExt);
  }

  render() {
    const mqf = this.props.CaptureExecMobx.o_mediaQueryFlag;
    const finProjObj = this.props.DivexecMobx.c_finProjObj;
    const finProjGraphStartEndDatesObj = this.props.DivexecMobx.c_finProjGraphStartEndDatesObj;
    const finProjYearlyGraphObj = this.props.DivexecMobx.c_finProjYearlyGraphObj;
    const finProjMonthlyGraphObj = this.props.DivexecMobx.c_finProjMonthlyGraphObj;
    const c_userFontSizePx = this.props.UserMobx.c_userFontSizePx;

    const fyrArray = finProjObj.fyrArray;
    const fyrMthLabelsArray = finProjObj.fyrMthLabelsArray;
    const moneyValueMatrix = finProjObj.moneyValueMatrix;
    const totalMoneyValuePerFyrArray = finProjObj.totalMoneyValuePerFyrArray;
    const totalTcv = finProjObj.totalTcv;
    const includePwinTF = finProjObj.includePwinTF;
    const tableColumnFieldsArrayOfObjs = finProjObj.tableColumnFieldsArrayOfObjs;

    const moneyTrueMoneyShortFalse = (mqf > 4);

    const fyrColumnWidth = "4em";
    const totalColumnFlexBasis = "125em";

    const finprojMoneyValueDisplayName = tableColumnFieldsArrayOfObjs[5].displayName;
    const graphHeightPx = 150;

    return(
      <>
        <div className="flex00a displayFlexRow borderB1ddd" style={{flexBasis:"6em"}}>
          <DivexecCaptureFilteringPanel />
          <div className="flex00a displayFlexColumnVc lrPad borderL1ddd">
            <DivexecEditUserMoneyField p_userFieldDbName="divexec_finproj_money_field_id" />
          </div>
          <div className="flex00a displayFlexColumnHcVc lrPad borderL1ddd textCenter" style={{flexBasis:"13em"}}>
            <CEGeneralReact.CEButton
              p_type="add"
              p_text="Export to Excel"
              f_onClick={this.onclick_export_finproj_to_excel_xml}
            />
          </div>
        </div>
        <div className="flex11a yScroll medFullPad">
          <FinProjMonthLabelsRow p_fyrMthLabelsArray={fyrMthLabelsArray} p_fyrColumnWidth={fyrColumnWidth} p_totalColumnFlexBasis={totalColumnFlexBasis} p_totalTcv={undefined} p_moneyTrueMoneyShortFalse={moneyTrueMoneyShortFalse} />
          {fyrArray.map((m_fyr, m_fyrIndex) =>
            <div key={m_fyr} className="displayFlexRow tbMicroPad borderT1ddd hoverLighterBlueGradient">
              <div className="flex00a lrPad borderR1ddd" style={{flexBasis:fyrColumnWidth}}>
                <LibraryReact.Nowrap p_fontClass="fontBold fontTextLight">
                  {m_fyr}
                </LibraryReact.Nowrap>
              </div>
              {fyrMthLabelsArray.map((m_fyrMthLabel, m_monthIndex) =>
                <div key={m_fyr + "_" + m_fyrMthLabel} className="flex11a lrPad borderR1ddd textRight" style={{flexBasis:"100em"}}>
                  <LibraryReact.Nowrap p_fontClass="">
                    {((moneyTrueMoneyShortFalse) ? (JSFUNC.money(moneyValueMatrix[m_fyrIndex][m_monthIndex], 0, true)) : (JSFUNC.money_short(moneyValueMatrix[m_fyrIndex][m_monthIndex])))}
                  </LibraryReact.Nowrap>
                </div>
              )}
              <div className="flex11a lrPad textRight" style={{flexBasis:totalColumnFlexBasis}}>
                <LibraryReact.Nowrap p_fontClass="fontBold fontTextLight">
                  {((moneyTrueMoneyShortFalse) ? (JSFUNC.money(totalMoneyValuePerFyrArray[m_fyrIndex], 0, true)) : (JSFUNC.money_short(totalMoneyValuePerFyrArray[m_fyrIndex])))}
                </LibraryReact.Nowrap>
              </div>
            </div>
          )}
          <FinProjMonthLabelsRow p_fyrMthLabelsArray={fyrMthLabelsArray} p_fyrColumnWidth={fyrColumnWidth} p_totalColumnFlexBasis={totalColumnFlexBasis} p_totalTcv={totalTcv} p_moneyTrueMoneyShortFalse={moneyTrueMoneyShortFalse} />
          <div className="medTopMargin" />
          <div className="displayFlexRow">
            <div className="flex11a borderR1bbb medFullPad" style={{flexBasis:"100em"}}>
              <div className="smallBottomMargin textCenter">
                <LibraryReact.Nowrap p_fontClass="font12 fontBold fontTextLightester">
                  {"Projections using " + finprojMoneyValueDisplayName + " (Yearly)"}
                </LibraryReact.Nowrap>
              </div>
              <LibraryReact.TimeGraph
                p_categoriesArrayOfObjs={finProjYearlyGraphObj.categoriesArrayOfObjs}
                p_timeGraphType="line"
                p_startDate={finProjGraphStartEndDatesObj.startDate}
                p_endDate={finProjGraphStartEndDatesObj.endDate}
                p_timeBins="yearly"
                p_yLogScaleTF={false}
                p_valueFormat="moneyShort"
                p_heightPx={graphHeightPx}
                p_yAxisWidthEm={undefined}
                p_fontSizePx={c_userFontSizePx}
                p_legendTF={false}
                f_onClickSegment={undefined}
              />
            </div>
            <div className="flex11a medFullPad" style={{flexBasis:"100em"}}>
              <div className="smallBottomMargin textCenter">
                <LibraryReact.Nowrap p_fontClass="font12 fontBold fontTextLightester">
                  {"Projections using " + finprojMoneyValueDisplayName + " (Monthly)"}
                </LibraryReact.Nowrap>
              </div>
              <LibraryReact.TimeGraph
                p_categoriesArrayOfObjs={finProjMonthlyGraphObj.categoriesArrayOfObjs}
                p_timeGraphType="line"
                p_startDate={finProjGraphStartEndDatesObj.startDate}
                p_endDate={finProjGraphStartEndDatesObj.endDate}
                p_timeBins="monthly"
                p_yLogScaleTF={false}
                p_valueFormat="moneyShort"
                p_heightPx={graphHeightPx}
                p_yAxisWidthEm={undefined}
                p_fontSizePx={c_userFontSizePx}
                p_legendTF={false}
                f_onClickSegment={undefined}
              />
            </div>
          </div>
          <div className="medTopMargin" />
          <div className="smallFullPad">
            <FinProjCapturesTable />
          </div>
        </div>
      </>
    );
  }
}));

function FinProjMonthLabelsRow(props) { //props: p_fyrMthLabelsArray, p_fyrColumnWidth, p_totalColumnFlexBasis, p_totalTcv, p_moneyTrueMoneyShortFalse
  const fyrMthLabelsArray = props.p_fyrMthLabelsArray;
  const fyrColumnWidth = props.p_fyrColumnWidth
  const totalColumnFlexBasis = props.p_totalColumnFlexBasis;
  const totalTcv = props.p_totalTcv;
  const moneyTrueMoneyShortFalse = props.p_moneyTrueMoneyShortFalse;

  return(
    <div className="displayFlexRow tbMicroPad borderB1bbb" style={{borderTop:"solid 1px #ccc"}}>
      <div className="flex00a lrPad borderR1ddd" style={{flexBasis:fyrColumnWidth}}>
        <LibraryReact.Nowrap p_fontClass="fontBold fontItalic fontTextLight">
          {"FYR"}
        </LibraryReact.Nowrap>
      </div>
      {fyrMthLabelsArray.map((m_fyrMthLabel) =>
        <div key={m_fyrMthLabel} className="flex11a lrPad borderR1ddd" style={{flexBasis:"100em"}}>
          <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLight">
            {m_fyrMthLabel}
          </LibraryReact.Nowrap>
        </div>
      )}
      {(totalTcv !== undefined) ? (
        <div className="flex11a lrPad textRight" style={{flexBasis:totalColumnFlexBasis}}>
          <LibraryReact.Nowrap p_fontClass="fontBold fontBlue">
            {((moneyTrueMoneyShortFalse) ? (JSFUNC.money(totalTcv, 0, true)) : (JSFUNC.money_short(totalTcv)))}
          </LibraryReact.Nowrap>
        </div>
      ) : (
        <div className="flex11a lrPad" style={{flexBasis:totalColumnFlexBasis}}>
          <LibraryReact.Nowrap p_fontClass="fontBold fontItalic fontTextLight">
            {"Total"}
          </LibraryReact.Nowrap>
        </div>
      )}
    </div>
  );
}

const FinProjCapturesTable = inject("DivexecMobx", "DatabaseMobx")(observer(
class FinProjCapturesTable extends Component {
  onclick_show_all_captures = () => {
    this.props.DivexecMobx.a_finproj_set_max_num_captures(JSFUNC.sort_max_mysqli_int());
  }

  render() {
    const finProjObj = this.props.DivexecMobx.c_finProjObj;
    const maxNumCaptures = this.props.DivexecMobx.o_finProjMaxNumCaptures;

    const finProjCapturesArrayOfObjs = finProjObj.finProjCapturesArrayOfObjs;
    const fyrArray = finProjObj.fyrArray;
    const numYears = finProjObj.numYears;
    const numCaptures = finProjObj.numCaptures;
    const filterName = finProjObj.filterName;
    const tableColumnFieldsArrayOfObjs = finProjObj.tableColumnFieldsArrayOfObjs;

    const tableLeftFlexBasis = "175em";
    const tableRightFlexBasis = "100em";
    const captureRowHeight = "2.6em";

    const headerRowHeight = "2.8em";
    const headerCellMaxHeight = "2.4em";
    const headerFontClass = "font11 fontWhite";
    const headerLeftBorder = "solid 1px #bbb";

    const tableRightFyrColumnWidthEm = 10;
    const tableRightFyrColumnWidth = tableRightFyrColumnWidthEm + "em";
    const totalTableRightWidth = (tableRightFyrColumnWidthEm * numYears) + "em";

    return(
      <>
        <div className="microBottomMargin">
          <font className="font13 fontItalic">
            {numCaptures + " " + JSFUNC.plural(numCaptures, "Capture matches", "Captures match") + " filter '" + filterName + "'"}
          </font>
        </div>
        <div style={{border:"solid 1px #ccc"}}>
          <div className="displayFlexRow bgGreenGradient" style={{height:"2.5em", borderBottom:"bbb"}}>
            <div className="flex11a displayFlexRowVc textCenter" style={{flexBasis:tableLeftFlexBasis}}>
              <LibraryReact.Nowrap p_fontClass="font12 fontBold fontWhite">
                {"Capture Info"}
              </LibraryReact.Nowrap>
            </div>
            <div className="flex11a displayFlexRowVc textCenter" style={{flexBasis:tableRightFlexBasis}}>
              <LibraryReact.Nowrap p_fontClass="font12 fontBold fontWhite">
                {"FYR $ Value Per Year"}
              </LibraryReact.Nowrap>
            </div>
          </div>
          <div className="displayFlexRow" style={{borderBottom:"bbb"}}>
            <div className="flex11a xScrollAlways" style={{flexBasis:tableLeftFlexBasis, borderRight:"solid 1px #aca"}}>
              <div className="displayFlexRow bgBlueGradient borderB1ddd" style={{height:headerRowHeight}}>
                {tableColumnFieldsArrayOfObjs.map((m_tableColumnFieldObj, m_index) =>
                  <div
                    key={m_tableColumnFieldObj.displayName}
                    className="flex11a displayFlexRowVc lrPad"
                    style={{flexBasis:m_tableColumnFieldObj.flexBasis, borderLeft:((m_index > 0) ? (headerLeftBorder) : (undefined))}}
                    title={m_tableColumnFieldObj.displayName}>
                    <LibraryReact.MaxHeightWrap p_maxHeight={headerCellMaxHeight} p_fontClass={headerFontClass}>
                      {m_tableColumnFieldObj.displayName}
                    </LibraryReact.MaxHeightWrap>
                  </div>
                )}
              </div>
              {finProjCapturesArrayOfObjs.map((m_finProjCaptureObj, m_index) =>
                (m_index < maxNumCaptures) &&
                <FinProjSingleCaptureRowLeft
                  key={m_finProjCaptureObj.id}
                  p_finProjCaptureObj={m_finProjCaptureObj}
                  p_tableColumnFieldsArrayOfObjs={tableColumnFieldsArrayOfObjs}
                  p_captureRowHeight={captureRowHeight}
                />
              )}
            </div>
            <div className="flex11a xScrollAlways" style={{flexBasis:tableRightFlexBasis}}>
              <div className="displayFlexRow bgBlueGradient borderB1ddd" style={{width:totalTableRightWidth, height:headerRowHeight}}>
                {fyrArray.map((m_fyr, m_index) =>
                  <div key={m_fyr} className="flex00a displayFlexColumnHcVc" style={{flexBasis:tableRightFyrColumnWidth, borderLeft:((m_index > 0) ? (headerLeftBorder) : (undefined))}}>
                    <font className={headerFontClass}>
                      {m_fyr}
                    </font>
                  </div>
                )}
              </div>
              {finProjCapturesArrayOfObjs.map((m_finProjCaptureObj, m_index) =>
                (m_index < maxNumCaptures) &&
                <FinProjSingleCaptureRowRight
                  key={m_finProjCaptureObj.id}
                  p_finProjCaptureObj={m_finProjCaptureObj}
                  p_fyrArray={fyrArray}
                  p_captureRowHeight={captureRowHeight}
                  p_totalTableRightWidth={totalTableRightWidth}
                  p_tableRightFyrColumnWidth={tableRightFyrColumnWidth}
                />
              )}
            </div>
          </div>
        </div>
        {(numCaptures > maxNumCaptures) &&
          <div className="smallTopMargin">
            <CEGeneralReact.CEButton
              p_type="blue"
              p_text={"Show all " + numCaptures + " Captures"}
              f_onClick={this.onclick_show_all_captures}
            />
          </div>
        }
      </>
    );
  }
}));

const FinProjSingleCaptureRowLeft = inject("DivexecMobx", "OpenCaptureMobx", "DatabaseMobx")(observer(
class FinProjSingleCaptureRowLeft extends Component { //props: p_finProjCaptureObj, p_tableColumnFieldsArrayOfObjs, p_captureRowHeight
  onclick_open_capture = () => {
    this.props.OpenCaptureMobx.a_open_single_capture(this.props.p_finProjCaptureObj.id);
  }

  render() {
    const p_finProjCaptureObj = this.props.p_finProjCaptureObj;
    const p_tableColumnFieldsArrayOfObjs = this.props.p_tableColumnFieldsArrayOfObjs;
    const p_captureRowHeight = this.props.p_captureRowHeight;

    const tableColumnCaptureFieldValueArrayOfObjs = p_finProjCaptureObj.tableColumnCaptureFieldValueArrayOfObjs;

    return(
      <div
        className="displayFlexRow borderB1ddd hoverLighterBlueGradient cursorPointer"
        style={{height:p_captureRowHeight}}
        onClick={this.onclick_open_capture}>
        {p_tableColumnFieldsArrayOfObjs.map((m_tableColumnFieldObj, m_index) =>
          <FinProjSingleCaptureRowLeftCell
            p_tableColumnFieldObj={m_tableColumnFieldObj}
            p_tableColumnCaptureFieldValueObj={tableColumnCaptureFieldValueArrayOfObjs[m_index]}
          />
        )}
      </div>
    );
  }
}));

function FinProjSingleCaptureRowLeftCell(props) { //props: p_tableColumnFieldObj, p_tableColumnCaptureFieldValueObj
  const p_tableColumnFieldObj = props.p_tableColumnFieldObj;
  const p_tableColumnCaptureFieldValueObj = props.p_tableColumnCaptureFieldValueObj;

  const columnDisplayName = p_tableColumnFieldObj.displayName;
  const columnFlexBasis = p_tableColumnFieldObj.flexBasis;

  return(
    <div
      key={columnDisplayName}
      className={"flex11a displayFlexRowVc lrPad " + p_tableColumnCaptureFieldValueObj.cstCellAlignClass}
      style={{flexBasis:columnFlexBasis}}
      title={p_tableColumnCaptureFieldValueObj.valueMaskPlainText}>
      <LibraryReact.MaxHeightWrap p_maxHeight="2.4em">
        {p_tableColumnCaptureFieldValueObj.valueMask}
      </LibraryReact.MaxHeightWrap>
    </div>
  );
}

const FinProjSingleCaptureRowRight = inject("DivexecMobx")(observer(
class FinProjSingleCaptureRowRight extends Component { //props: p_finProjCaptureObj, p_fyrArray, p_captureRowHeight, p_totalTableRightWidth, p_tableRightFyrColumnWidth
  render() {
    const finProjCaptureObj = this.props.p_finProjCaptureObj;
    const fyrArray = this.props.p_fyrArray;
    const captureRowHeight = this.props.p_captureRowHeight;
    const totalTableRightWidth = this.props.p_totalTableRightWidth;
    const tableRightFyrColumnWidth = this.props.p_tableRightFyrColumnWidth;
    return(
      <div className="displayFlexRow borderB1ddd" style={{width:totalTableRightWidth, height:captureRowHeight}}>
        {finProjCaptureObj.captureMoneyValuePerFyrArray.map((m_fyrTcv, m_index) =>
          <div
            key={fyrArray[m_index]}
            className={"flex00a displayFlexRowVc lrPad textRight " + ((m_fyrTcv > 0) ? ("") : ("bgLighterGray"))}
            style={{flexBasis:tableRightFyrColumnWidth, borderLeft:((m_index > 0) ? ("solid 1px #eee") : (undefined))}}>
            <LibraryReact.Nowrap p_fontClass="">
              {((m_fyrTcv > 0) ? (JSFUNC.money(m_fyrTcv, 0, true)) : (""))}
            </LibraryReact.Nowrap>
          </div>
        )}
      </div>
    );
  }
}));





//=============================================================================================================================================================================
//=============================================================================================================================================================================
//Hot Bits
export const HotBits = inject("DivexecMobx", "UserMobx")(observer(
class HotBits extends Component {
  componentDidMount() {
    const o_hotBitsSelectedCaptureFieldIDOrUndefned = this.props.DivexecMobx.o_hotBitsSelectedCaptureFieldIDOrUndefned;
    const c_hotBitsObj = this.props.DivexecMobx.c_hotBitsObj;

    //if a Hot BITs selected capture date field is not picked yet, pick the first one
    if(o_hotBitsSelectedCaptureFieldIDOrUndefned === undefined) {
      if(JSFUNC.is_array_not_empty(c_hotBitsObj.hotBitsCaptureFieldsArrayOfObjs)) {
        this.props.DivexecMobx.a_hot_bits_set_selected_capture_field_id(c_hotBitsObj.hotBitsCaptureFieldsArrayOfObjs[0].id);
      }
    }
  }

  onchange_hotbits_fixed_calendar = (i_newValue) => {
    this.props.UserMobx.a_update_user_field("divexec_hotbits_fixed0_calendar1", i_newValue, "i");
  }

  render() {
    const o_hotBitsSelectedCaptureFieldIDOrUndefned = this.props.DivexecMobx.o_hotBitsSelectedCaptureFieldIDOrUndefned;
    const c_hotBitsObj = this.props.DivexecMobx.c_hotBitsObj;
    const c_combinedUserObj = this.props.UserMobx.c_combinedUserObj;

    const dateCategoriesArrayOfObjs = c_hotBitsObj.dateCategoriesArrayOfObjs;
    const hotBitsCaptureFieldsArrayOfObjs = c_hotBitsObj.hotBitsCaptureFieldsArrayOfObjs;

    return(
      <>
        <div className="displayFlexRow borderB1bbb bgLighterGreen tbMicroPad" style={{minHeight:"3.3em"}}>
          <div className="flex11a displayFlexColumnVc lrMedPad" style={{flexBasis:"100em"}}>
            <div className="microBottomMargin"><font className="font13 fontBold fontItalic fontUnderline fontDarkGreen">{"Hot BITs"}</font></div>
            <div><font className="font11 fontBold">{"Cards of Recent System-Wide Activity"}</font></div>
          </div>
          <div className="flex00a displayFlexColumnVc" style={{flexBasis:"4em"}}>
            <font className="fontBold fontItalic fontTextLight">
              {"Date Options"}
            </font>
          </div>
          <div className="flex00a displayFlexColumnVc rMargin lrPad" style={{flexBasis:"9em"}}>
            <CEGeneralReact.VerticalSwitch
              p_valueArray={[0, 1]}
              p_displayArray={["Fixed", "Calendar"]}
              p_selectedValue={c_combinedUserObj.divexec_hotbits_fixed0_calendar1}
              p_rowHeightEm={1.3}
              p_sizeMultiplier={0.8}
              f_onSelect={this.onchange_hotbits_fixed_calendar}
            />
          </div>
          <div className="flex11a displayFlexColumnVc lrMedPad" style={{flexBasis:"200em"}}>
            <DivexecSelectFilterDivisions />
          </div>
        </div>
        <div className="flex00a displayFlexRow borderB1bbb bgLighterGreen">
          {hotBitsCaptureFieldsArrayOfObjs.map((m_hotBitsCaptureFieldObj) =>
            <HotBitsSingleCaptureFieldCard
              key={m_hotBitsCaptureFieldObj.id}
              p_dateCategoriesArrayOfObjs={dateCategoriesArrayOfObjs}
              p_hotBitsCaptureFieldObj={m_hotBitsCaptureFieldObj}
              p_captureFieldIsSelectedTF={(m_hotBitsCaptureFieldObj.id === o_hotBitsSelectedCaptureFieldIDOrUndefned)}
            />
          )}
        </div>
        <div className="flex11a xyScroll yScrollBottomPad bgLighterGray">
          {hotBitsCaptureFieldsArrayOfObjs.map((m_hotBitsCaptureFieldObj) =>
            (o_hotBitsSelectedCaptureFieldIDOrUndefned === m_hotBitsCaptureFieldObj.id) &&
            <HotBitsSelectedFieldListOfCaptures
              key={m_hotBitsCaptureFieldObj.id}
              p_dateCategoriesArrayOfObjs={dateCategoriesArrayOfObjs}
              p_hotBitsCaptureFieldObj={m_hotBitsCaptureFieldObj}
            />
          )}
        </div>
      </>
    );
  }
}));

const HotBitsSingleCaptureFieldCard = inject("DivexecMobx")(observer(
class HotBitsSingleCaptureFieldCard extends Component { //props: p_dateCategoriesArrayOfObjs, p_hotBitsCaptureFieldObj, p_captureFieldIsSelectedTF
  onclick_hot_bits_capture_field_card = () => {
    this.props.DivexecMobx.a_hot_bits_set_selected_capture_field_id(this.props.p_hotBitsCaptureFieldObj.id);
  }

  render() {
    const p_dateCategoriesArrayOfObjs = this.props.p_dateCategoriesArrayOfObjs;
    const p_hotBitsCaptureFieldObj = this.props.p_hotBitsCaptureFieldObj;
    const p_captureFieldIsSelectedTF = this.props.p_captureFieldIsSelectedTF;

    return(
      <div
        className={"flex11a medFullMargin border bevelBorderColors " + ((p_captureFieldIsSelectedTF) ? ("bgDarkGreenGradient") : ("bgLightGray hoverLightGreenGradient cursorPointer"))}
        style={{flexBasis:"100em"}}
        onClick={((p_captureFieldIsSelectedTF) ? (undefined) : (this.onclick_hot_bits_capture_field_card))}>
        <div className="smallFullPad textCenter">
          <LibraryReact.Nowrap p_fontClass={"font11 fontBold " + ((p_captureFieldIsSelectedTF) ? ("fontWhite") : ("fontTextLight"))}>
            {p_hotBitsCaptureFieldObj.label}
          </LibraryReact.Nowrap>
        </div>
        <div className="lrMargin borderT1ddd" />
        <div className="tbPad lrMedPad">
          {p_dateCategoriesArrayOfObjs.map((m_dateCategoryObj, m_index) =>
            <HotBitsFieldCardCaptureCountTextLine
              key={m_dateCategoryObj.name}
              p_fieldIsSelectedTF={p_captureFieldIsSelectedTF}
              p_numCaptures={p_hotBitsCaptureFieldObj.capturesPerDateArrayOfObjs[m_index].numCaptures}
              p_todayWeekMonthYearLabel={m_dateCategoryObj.name}
            />
          )}
        </div>
      </div>
    );
  }
}));

function HotBitsFieldCardCaptureCountTextLine(props) { //props: p_fieldIsSelectedTF, p_numCaptures, p_todayWeekMonthYearLabel
  const p_fieldIsSelectedTF = props.p_fieldIsSelectedTF;
  const p_numCaptures = props.p_numCaptures;
  const p_todayWeekMonthYearLabel = props.p_todayWeekMonthYearLabel;

  var fontClass = ((p_fieldIsSelectedTF) ? ("fontAlmostWhite") : (""));
  if(p_numCaptures === 0) {
    fontClass = ((p_fieldIsSelectedTF) ? ("fontLightRed") : ("fontDarkerRed"));
  }

  return(
    <div className="">
      <LibraryReact.Nowrap p_fontClass={fontClass}>
        {p_numCaptures + " " + JSFUNC.plural(p_numCaptures, "capture", "captures") + " " + p_todayWeekMonthYearLabel}
      </LibraryReact.Nowrap>
    </div>
  );
}

const HotBitsSelectedFieldListOfCaptures = inject("OpenCaptureMobx")(observer(
class HotBitsSelectedFieldListOfCaptures extends Component { //props: p_hotBitsCaptureFieldObj
  onclick_capture_open_capture = (i_captureID) => {
    this.props.OpenCaptureMobx.a_open_single_capture(i_captureID);
  }

  render() {
    const p_dateCategoriesArrayOfObjs = this.props.p_dateCategoriesArrayOfObjs;
    const p_hotBitsCaptureFieldObj = this.props.p_hotBitsCaptureFieldObj;

    return(
      p_dateCategoriesArrayOfObjs.map((m_dateCategoryObj, m_index) =>
        <>
          {(m_index > 0) &&
            <div className="hugeTopMargin" />
          }
          <HotBitsListOfCapturesSectionHeader
            p_numCaptures={p_hotBitsCaptureFieldObj.capturesPerDateArrayOfObjs[m_index].numCaptures}
            p_label={p_hotBitsCaptureFieldObj.actionLabel + " " + m_dateCategoryObj.actionName}
            p_subLabel={"Captures with '" + p_hotBitsCaptureFieldObj.displayName + "'" + m_dateCategoryObj.capturesSectionHeaderSubLabelBetweenDates}
          />
          {(p_hotBitsCaptureFieldObj.capturesPerDateArrayOfObjs[m_index].atLeast1CaptureTF) &&
            <CapturesReact.SearchResultsCapturesTable
              p_searchResultCapturesArrayOfObjs={p_hotBitsCaptureFieldObj.capturesPerDateArrayOfObjs[m_index].searchResultsCapturesArrayOfObjs}
              f_onClickCapture={this.onclick_capture_open_capture}
            />
          }
        </>
      )
    );
  }
}));

function HotBitsListOfCapturesSectionHeader(props) { //props: p_numCaptures, p_label, p_subLabel
  const p_numCaptures = props.p_numCaptures;
  const p_label = props.p_label;
  const p_subLabel = props.p_subLabel;

  return(
    <div className="displayFlexRowVc lrMedPad bgLighterBlueGradient" style={{height:"3.3em", borderTop:"solid 1px #aaa", borderBottom:"solid 1px #aaa"}}>
      <div className="flex00a textCenter" style={{flexBasis:"4em"}}>
        <font className={"font14 fontBold fontItalic " + ((p_numCaptures === 0) ? ("fontTextLight") : ("fontDarkBlue"))}>
          {p_numCaptures}
        </font>
      </div>
      <div className="flex00a" style={{flexBasis:"23em"}}>
        <font className="font11 fontBold fontItalic fontTextLight">
          {p_label}
        </font>
      </div>
      <div className="flex11a">
        <LibraryReact.Nowrap p_fontClass="fontItalic fontTextLight">
          {p_subLabel}
        </LibraryReact.Nowrap>
      </div>
    </div>
  );
}






//=============================================================================================================================================================================
//=============================================================================================================================================================================
//Critical Thresholds
export const CriticalThresholds = inject("CaptureExecMobx", "DivexecMobx", "DatabaseMobx", "UserMobx")(observer(
class CriticalThresholds extends Component {
  componentDidMount() {
    if(this.props.DivexecMobx.o_criticalThresholdsSelectedFilterCaptureTypeIDOrUndefned === undefined) {
      this.props.DivexecMobx.a_critical_thresholds_set_selected_filter_capture_type_id(this.props.DatabaseMobx.c_firstCaptureTypeIDOrUndefined);
    }
  }

  onclick_critical_thresholds_filter_capture_type_button = (i_selectedCaptureTypeID) => {
    this.props.DivexecMobx.a_critical_thresholds_set_selected_filter_capture_type_id(i_selectedCaptureTypeID);
  }

  onclick_critical_thresholds_graph_button = (i_selectedGraphString) => {
    this.props.DivexecMobx.a_critical_thresholds_set_selected_graph_string(i_selectedGraphString);
  }

  onclick_critical_thresholds_scatter_dot = (i_clickedCaptureID) => {
    const captureIDsArray = [i_clickedCaptureID];
    const floatingBoxTitle = "Critical Thresholds - Selected Capture '" + this.props.DatabaseMobx.capture_name_plaintext_from_capture_id(i_clickedCaptureID) + "'";
    this.props.CaptureExecMobx.a_set_view_specified_search_results_captures_in_floating_box_capture_ids_array_and_floating_box_title(captureIDsArray, floatingBoxTitle);
  }

  render() {
    const criticalThresholdsSelectedFilterCaptureTypeID = this.props.DivexecMobx.o_criticalThresholdsSelectedFilterCaptureTypeIDOrUndefned;
    const criticalThresholdsSelectedGraphString = this.props.DivexecMobx.o_criticalThresholdsSelectedGraphString;
    const c_captureTypeIDsArray = this.props.DatabaseMobx.c_captureTypeIDsArray;
    const c_captureTypeNamesArray = this.props.DatabaseMobx.c_captureTypeNamesArray;
    const c_combinedUserObj = this.props.UserMobx.c_combinedUserObj;

    const userCritThreshMoneyFieldID = c_combinedUserObj.divexec_critthresh_money_field_id;

    const cpgtDbName = "criticalProgressThreshold";
    const cpgtDisplayName = "Critical Progress % Threshold for Wins/Losses";
    const cpgtDisplayNameShort = "$ vs % Progress";
    const cpwtDbName = "criticalPwinThreshold";
    const cpwtDisplayName = "Critical PWin % Threshold for Wins/Losses";
    const cpwtDisplayNameShort = "$ vs % PWin";
    const pwvpgDbName = "pwinVsProgress";
    const pwvpgDisplayName = "PWin vs Progress for Wins/Losses";
    const pwvpgDisplayNameShort = "PWin vs Progress";

    const tabDbNamesArray = [cpgtDbName, cpwtDbName, pwvpgDbName];
    const tabDisplayNamesArray = [cpgtDisplayName, cpwtDisplayName, pwvpgDisplayName];
    const tabDisplayNamesShortArray = [cpgtDisplayNameShort, cpwtDisplayNameShort, pwvpgDisplayNameShort];

    var moneyFieldDisplayName = "--No Capture Money Field Selected--";
    if(userCritThreshMoneyFieldID > 0) {
      const critthreshMoneyExpandedFieldMap = this.props.DatabaseMobx.fetch_expanded_capture_field_map_from_field_id(userCritThreshMoneyFieldID);
      moneyFieldDisplayName = critthreshMoneyExpandedFieldMap.get("display_name");
    }

    var graphComponent = null;
    if(criticalThresholdsSelectedGraphString === cpgtDbName) {
      graphComponent = (
        <LibraryReact.ScatterPlot
          p_dataObjOrDataArrayOfObjs={this.props.DivexecMobx.c_criticalProgressThresholdScatterPlotDataArrayOfObjs}
          p_xMin={0}
          p_xMax={100}
          p_xValueFormat="percent"
          p_xLabel={this.props.DatabaseMobx.c_fieldMapOfTotalShapingProgress.get("display_name")}
          p_yMin={0}
          p_yMax={undefined}
          p_yValueFormat="moneyShort"
          p_yLabel={moneyFieldDisplayName}
          p_yLogScaleTF={true}
          p_zSizePercentOfHeight={2}
          p_zMin={undefined}
          p_zMax={undefined}
          p_dataAverageVerticalLinesTF={true}
          p_title={cpgtDisplayName}
          p_heightPx={700}
          p_fontSizePx={12}
          f_onClickDot={this.onclick_critical_thresholds_scatter_dot}
        />
      );
    }
    else if(criticalThresholdsSelectedGraphString === cpwtDbName) {
      graphComponent = (
        <LibraryReact.ScatterPlot
          p_dataObjOrDataArrayOfObjs={this.props.DivexecMobx.c_criticalPwinThresholdScatterPlotDataArrayOfObjs}
          p_xMin={0}
          p_xMax={100}
          p_xValueFormat="percent"
          p_xLabel={this.props.DatabaseMobx.c_fieldMapOfPwin.get("display_name")}
          p_yMin={0}
          p_yMax={undefined}
          p_yValueFormat="moneyShort"
          p_yLabel={moneyFieldDisplayName}
          p_yLogScaleTF={true}
          p_zSizePercentOfHeight={2}
          p_zMin={undefined}
          p_zMax={undefined}
          p_dataAverageVerticalLinesTF={true}
          p_title={cpwtDisplayName}
          p_heightPx={700}
          p_fontSizePx={12}
          f_onClickDot={this.onclick_critical_thresholds_scatter_dot}
        />
      );
    }
    else if(criticalThresholdsSelectedGraphString === pwvpgDbName) {
      graphComponent = (
        <LibraryReact.ScatterPlot
          p_dataObjOrDataArrayOfObjs={this.props.DivexecMobx.c_pwinVsProgressScatterPlotDataArrayOfObjs}
          p_xMin={0}
          p_xMax={100}
          p_xValueFormat="percent"
          p_xLabel={this.props.DatabaseMobx.c_fieldMapOfTotalShapingProgress.get("display_name")}
          p_yMin={0}
          p_yMax={100}
          p_yValueFormat="percent"
          p_yLabel={this.props.DatabaseMobx.c_fieldMapOfPwin.get("display_name")}
          p_yLogScaleTF={false}
          p_zSizePercentOfHeight={2}
          p_zMin={undefined}
          p_zMax={undefined}
          p_dataAverageVerticalLinesTF={true}
          p_title={pwvpgDisplayName}
          p_heightPx={700}
          p_fontSizePx={12}
          f_onClickDot={this.onclick_critical_thresholds_scatter_dot}
        />
      );
    }

    return(
      <>
        <div className="flex00a displayFlexRow borderB1ddd" style={{flexBasis:"6em"}}>
          <div className="flex00a displayFlexRowVc lrMedPad">
            <font className="font11 fontBold fontItalic fontTextLighter">
              {this.props.DatabaseMobx.c_fieldMapOfCaptureType.get("display_name")}
            </font>
          </div>
          <div className="flex11a displayFlexColumnVc">
            <CEGeneralReact.TabsList
              p_tabDbNamesArray={c_captureTypeIDsArray}
              p_tabDisplayNamesArray={c_captureTypeNamesArray}
              p_selectedTabDbName={criticalThresholdsSelectedFilterCaptureTypeID}
              p_tabHeight="2.7em"
              p_textMaxHeight="2.4em"
              p_tabWidth="10em"
              p_unselectedFontClass="fontBold"
              p_unselectedBgClass="bgLighterGrayGradient hoverLighterBlueGradient fontTextLight"
              p_selectedBgClass="bgOrangeGradient"
              f_onSelect={this.onclick_critical_thresholds_filter_capture_type_button}
            />
          </div>
          <div className="flex00a displayFlexColumnVc lrPad borderL1ddd">
            <DivexecEditUserMoneyField p_userFieldDbName="divexec_critthresh_money_field_id" />
          </div>
        </div>
        <div className="flex00a displayFlexRow" style={{flexBasis:"6em"}}>
          <div className="flex11a displayFlexRowVc borderR1ddd" style={{flexBasis:"100em"}}>
            <div className="flex00a lrMedPad">
              <font className="font11 fontBold fontItalic fontTextLighter">
                {"Graph"}
              </font>
            </div>
            <div className="flex11a">
              <CEGeneralReact.TabsList
                p_tabDbNamesArray={tabDbNamesArray}
                p_tabDisplayNamesArray={tabDisplayNamesShortArray}
                p_selectedTabDbName={criticalThresholdsSelectedGraphString}
                p_tabHeight="2.7em"
                p_textMaxHeight="2.4em"
                p_tabWidth="6.5em"
                p_unselectedFontClass="fontBold"
                p_unselectedBgClass="bgLighterGrayGradient hoverLighterBlueGradient fontTextLight"
                p_selectedBgClass="bgGoldGradient"
                f_onSelect={this.onclick_critical_thresholds_graph_button}
              />
            </div>
          </div>
          <div className="flex11a displayFlexColumnVc lrMedPad" style={{flexBasis:"100em"}}>
            <DivexecSelectFilterDivisions />
          </div>
        </div>
        <div className="flex11a yScroll yScrollBottomPad medFullPad borderT1ddd">
          {graphComponent}
        </div>
      </>
    );
  }
}));






//=============================================================================================================================================================================
//=============================================================================================================================================================================
//Stage Flow
export const StageFlow = inject("DivexecMobx", "DatabaseMobx")(observer(
class StageFlow extends Component {
  componentDidMount() {
    this.props.DivexecMobx.a_stage_flow_load_thin_log_stages();

    if(this.props.DivexecMobx.o_stageFlowSelectedFilterCaptureTypeIDOrUndefined === undefined) {
      this.props.DivexecMobx.a_stage_flow_set_selected_filter_capture_type_id(this.props.DatabaseMobx.c_firstCaptureTypeIDOrUndefined);
    }
  }

  onclick_filter_capture_type_button = (i_selectedCaptureTypeID) => {
    this.props.DivexecMobx.a_stage_flow_set_selected_filter_capture_type_id(i_selectedCaptureTypeID);
  }

  onclick_filter_stage_type_button = (i_selectedStageTypeString) => {
    this.props.DivexecMobx.a_stage_flow_set_selected_filter_stage_type_string(i_selectedStageTypeString);
  }

  render() {
    const o_stageFlowSelectedFilterCaptureTypeIDOrUndefined = this.props.DivexecMobx.o_stageFlowSelectedFilterCaptureTypeIDOrUndefined;
    const o_stageFlowSelectedFilterStageTypeString = this.props.DivexecMobx.o_stageFlowSelectedFilterStageTypeString;
    const c_stageFlowDataPerStageArrayOfObjs = this.props.DivexecMobx.c_stageFlowDataPerStageArrayOfObjs;
    const c_captureTypeIDsArray = this.props.DatabaseMobx.c_captureTypeIDsArray;
    const c_captureTypeNamesArray = this.props.DatabaseMobx.c_captureTypeNamesArray;
    const c_fieldMapOfCaptureType = this.props.DatabaseMobx.c_fieldMapOfCaptureType;

    return(
      <>
        <div className="flex00a displayFlexRow borderB1ddd" style={{flexBasis:"11em"}}>
          <div className="flex11a displayFlexColumn borderR1bbb" style={{flexBasis:"700em"}}>
            <div className="flex11a displayFlexRowVc borderB1ddd" style={{flexBasis:"100em"}}>
              <div className="flex00a lrMedPad">
                <font className="font11 fontBold fontItalic fontTextLighter">
                  {c_fieldMapOfCaptureType.get("display_name")}
                </font>
              </div>
              <div className="flex11a">
                <CEGeneralReact.TabsList
                  p_tabDbNamesArray={c_captureTypeIDsArray}
                  p_tabDisplayNamesArray={c_captureTypeNamesArray}
                  p_selectedTabDbName={o_stageFlowSelectedFilterCaptureTypeIDOrUndefined}
                  p_tabHeight="2.7em"
                  p_textMaxHeight="2.4em"
                  p_tabWidth="10em"
                  p_unselectedFontClass="fontBold"
                  p_unselectedBgClass="bgLighterGrayGradient hoverLighterBlueGradient fontTextLight"
                  p_selectedBgClass="bgOrangeGradient"
                  f_onSelect={this.onclick_filter_capture_type_button}
                />
              </div>
            </div>
            <div className="flex11a displayFlexRow" style={{flexBasis:"100em"}}>
              <div className="flex11a displayFlexRowVc borderR1ddd" style={{flexBasis:"100em"}}>
                <div className="flex00a lrMedPad">
                  <font className="font11 fontBold fontItalic fontTextLighter">
                    {this.props.DatabaseMobx.c_fieldMapOfStage.get("display_name")}
                  </font>
                </div>
                <div className="flex11a">
                  <CEGeneralReact.TabsList
                    p_tabDbNamesArray={["closed", "active", "all"]}
                    p_tabDisplayNamesArray={["Closed", "Active", "All"]}
                    p_selectedTabDbName={o_stageFlowSelectedFilterStageTypeString}
                    p_tabHeight="1.8em"
                    p_textMaxHeight="1.8em"
                    p_tabWidth="6em"
                    p_unselectedFontClass="fontBold"
                    p_unselectedBgClass="bgLighterGrayGradient hoverLighterBlueGradient fontTextLight"
                    p_selectedBgClass="bgLightGreenGradient"
                    p_tabSpacing="0.4em"
                    f_onSelect={this.onclick_filter_stage_type_button}
                  />
                </div>
              </div>
              <div className="flex11a displayFlexColumnVc lrMedPad" style={{flexBasis:"100em"}}>
                <DivexecSelectFilterDivisions />
              </div>
            </div>
          </div>
          <div className="flex11a yScroll lrPad microTopPad medBottomPad" style={{flexBasis:"100em"}}>
            {(JSFUNC.is_array(c_stageFlowDataPerStageArrayOfObjs)) &&
              <>
                <div className="microBottomPad">
                  <LibraryReact.Nowrap p_fontClass="font11 fontItalic fontTextLighter">
                    {"All Closed Stages:"}
                  </LibraryReact.Nowrap>
                </div>
                {c_stageFlowDataPerStageArrayOfObjs.map((m_stageFlowStageObj) =>
                  (m_stageFlowStageObj.stageObj.id === -99) &&
                  (m_stageFlowStageObj.countsForClosedStagesArrayOfObjs.map((m_stageFlowClosedStageObj) =>
                    <div
                      className="displayFlexRow microBottomPad"
                      title={m_stageFlowClosedStageObj.stageObj.name}>
                      <div className="flex00a lrMargin border1bbb" style={{flexBasis:"2em", background:"#" + m_stageFlowClosedStageObj.stageObj.color}} />
                      <div className="flex11a displayFlexRowVc">
                        <LibraryReact.Nowrap p_fontClass="">
                          {m_stageFlowClosedStageObj.stageObj.name}
                        </LibraryReact.Nowrap>
                      </div>
                    </div>
                  ))
                )}
              </>
            }
          </div>
        </div>
        <div className="flex11a displayFlexColumn medFullMargin border1bbb">
          <StageFlowGraph />
        </div>
      </>
    );
  }
}));

const StageFlowGraph = inject("DivexecMobx", "DatabaseMobx")(observer(
class StageFlowGraph extends Component {
  render() {
    const c_stageFlowDataPerStageArrayOfObjs = this.props.DivexecMobx.c_stageFlowDataPerStageArrayOfObjs;

    //c_stageFlowDataPerStageArrayOfObjs error messages and loading data message
    if(!JSFUNC.is_array(c_stageFlowDataPerStageArrayOfObjs)) {
      return(
        <CEGeneralReact.EmptyScreenGray>
          {(c_stageFlowDataPerStageArrayOfObjs === undefined) ? (
            <>
              <div className="microBottomMargin">
                <font className="font11">
                  {"Loading Stage Flow Historical Data"}
                </font>
              </div>
              <CEGeneralReact.LoadingAnimation />
            </>
          ) : (
            <font className="font11">
              {c_stageFlowDataPerStageArrayOfObjs}
            </font>
          )}
        </CEGeneralReact.EmptyScreenGray>
      );
    }

    return(
      <div className="flex11a displayFlexRow borderT1bbb">
        {c_stageFlowDataPerStageArrayOfObjs.map((m_stageFlowStageObj) =>
          <StageFlowGraphSingleStage
            key={m_stageFlowStageObj.stageObj.id}
            p_stageFlowStageObj={m_stageFlowStageObj}
          />
        )}
      </div>
    );
  }
}));

function StageFlowGraphSingleStage(props) { //props: p_stageFlowStageObj
  const stageFlowStageObj = props.p_stageFlowStageObj;

  const stageObj = stageFlowStageObj.stageObj;

  return(
    <div className="flex11a displayFlexColumn" style={{flexBasis:"100em", borderRight:"solid 1px #aaa"}}>
      <div className="flex00a borderB1bbb" style={{flexBasis:"1em", background:"#" + stageFlowStageObj.stageObj.color}} />
      <div className="flex00a displayFlexRowHcVc borderB1bbb textCenter" style={{flexBasis:"4em"}}>
        <LibraryReact.MaxHeightWrap p_maxHeight="3.8em" p_fontClass={"font12 fontBold fontTextLighter " + ((stageObj.id === -99) ? ("fontItalic") : (""))}>
          {((stageObj.id === -99) ? ("All Closed Stages") : (stageObj.name))}
        </LibraryReact.MaxHeightWrap>
      </div>
      <div className="flex11a displayFlexColumn">
        {(stageFlowStageObj.graphTransitedPerc > 0) &&
          <StageFlowGraphSingleSvgRect
            p_widthPercent={100}
            p_heightPercent={stageFlowStageObj.graphTransitedPerc}
            p_color={stageFlowStageObj.graphTransitedColor}
            p_title={stageFlowStageObj.graphTransitedTitle}
            p_numCaptures={undefined}
            p_captureIDsArray={undefined}
            p_borderTF={true}
          />
        }
        {stageFlowStageObj.countsForClosedStagesArrayOfObjs.map((m_stageFlowClosedStageObj) =>
          (m_stageFlowClosedStageObj.graphClosedPerc > 0) &&
          <StageFlowGraphSingleSvgRect
            p_widthPercent={((stageFlowStageObj.stageObj.id === -99) ? (100) : (50))}
            p_heightPercent={m_stageFlowClosedStageObj.graphClosedPerc}
            p_color={m_stageFlowClosedStageObj.graphClosedColor}
            p_title={m_stageFlowClosedStageObj.graphClosedTitle}
            p_numCaptures={m_stageFlowClosedStageObj.transitedFromPrevStageCounts}
            p_captureIDsArray={m_stageFlowClosedStageObj.transitedFromPrevStageCaptureIDsArray}
            p_borderTF={true}
          />
        )}
        {(stageFlowStageObj.graphActivePerc > 0) &&
          <StageFlowGraphSingleSvgRect
            p_widthPercent={10}
            p_heightPercent={stageFlowStageObj.graphActivePerc}
            p_color={stageFlowStageObj.graphActiveColor}
            p_title={stageFlowStageObj.graphActiveTitle}
            p_numCaptures={stageFlowStageObj.currentlyActiveInPrevStageCounts}
            p_captureIDsArray={stageFlowStageObj.currentlyActiveInPrevStageCaptureIDsArray}
            p_borderTF={true}
          />
        }
        {(stageFlowStageObj.remainingSpacePerc > 0) &&
          <StageFlowGraphSingleSvgRect
            p_widthPercent={100}
            p_heightPercent={stageFlowStageObj.remainingSpacePerc}
            p_color="fff"
            p_borderTF={false}
          />
        }
      </div>
    </div>
  );
}


const StageFlowGraphSingleSvgRect = inject("CaptureExecMobx", "DivexecMobx")(observer(
class StageFlowGraphSingleSvgRect extends Component { //props: p_yPercent, p_widthPercent, p_heightPercent, p_color, p_title, p_numCaptures, p_captureIDsArray, p_borderTF
  onclick_stage_flow_rect = () => {
    const captureIDsArray = this.props.p_captureIDsArray;
    const floatingBoxTitle = this.props.p_title;
    this.props.CaptureExecMobx.a_set_view_specified_search_results_captures_in_floating_box_capture_ids_array_and_floating_box_title(captureIDsArray, floatingBoxTitle);
  }

  render() {
    const widthPercent = this.props.p_widthPercent;
    const heightPercent = this.props.p_heightPercent;
    const color = this.props.p_color;
    const title = this.props.p_title;
    const numCaptures = this.props.p_numCaptures;
    const captureIDsArray = this.props.p_captureIDsArray;
    const borderTF = this.props.p_borderTF;

    const rectFlexBasisEm = (10 * heightPercent);
    const hasOnClickTF = JSFUNC.is_array(captureIDsArray);

    const rectZIndex = undefined; //((borderTF) ? ("2") : ("1"))
    const numCapturesBoxZIndex = undefined; //"3"

    const singleRectComponent = (
      <div
        className="flex11a displayFlexColumn"
        style={{zIndex:rectZIndex, flexBasis:rectFlexBasisEm + "em", background:"#" + color, cursor:((hasOnClickTF) ? ("pointer") : (undefined))}}
        title={title}
        onClick={((hasOnClickTF) ? (this.onclick_stage_flow_rect) : (undefined))}>
        <div className={"flex11a displayFlexColumnHcVc " + ((borderTF) ? ("border1bbb") : (""))}>
          {(numCaptures !== undefined) &&
            <div className="displayFlexColumnHcVc bgLighterGrayGradient" style={{zIndex:numCapturesBoxZIndex, height:"1.5em", width:"2.5em", border:"solid 1px #999"}}>
              <font className="">
                {numCaptures}
              </font>
            </div>
          }
        </div>
      </div>
    );

    if(widthPercent === 100) {
      return(singleRectComponent);
    }

    var internalFlexBasisEm = 100; //50%
    if(widthPercent === 10) {
      internalFlexBasisEm = 15;
    }

    return(
      <div className="flex11a displayFlexRow">
        <div className="flex11a displayFlexColumn" style={{flexBasis:internalFlexBasisEm + "em"}}>
          {singleRectComponent}
        </div>
        <div className="flex11a" style={{flexBasis:"100em"}} />
      </div>
    );
  }
}));





//=============================================================================================================================================================================
//=============================================================================================================================================================================


export const ExcelReportWriter = inject("DivexecMobx")(observer(
class ExcelReportWriter extends Component { //props:
  render() {
    const excelReportTemplatesWithinFoldersObj = this.props.DivexecMobx.c_excelReportTemplatesWithinFoldersObj;
    const numTemplates = excelReportTemplatesWithinFoldersObj.numTemplates;
    const foldersArrayOfObjs = excelReportTemplatesWithinFoldersObj.foldersArrayOfObjs;
    return(
      <div className="flex11a displayFlexColumn">
        <div className="flex00a smallFullMargin textCenter">
          <font className="font11 fontBold fontTextLight">
            {"Click the 'Generate' button next to an Excel Report Template below to download a copy populated with the latest data across all captures in the system"}
          </font>
        </div>
        {(numTemplates === 0) ? (
          <CEGeneralReact.EmptyScreenGray>
            {"Your CaptureExec Admin has not created any Excel Report Templates yet"}
          </CEGeneralReact.EmptyScreenGray>
        ) : (
          <div className="flex11a yScroll yScrollBottomPad">
            {foldersArrayOfObjs.map((m_folderObj, m_folderIndex) =>
              (m_folderObj.numFiles > 0) &&
              <div key={m_folderObj.id} className="" style={{marginBottom:"4em"}}>
                <div className="medFullPad" style={{background:"#f6f0ff", borderTop:"solid 1px #ccc", borderBottom:"solid 1px #758"}}>
                  <font className="font12 fontBold fontItalic fontTextLighter">
                    {m_folderObj.folderPathString}
                  </font>
                </div>
                {m_folderObj.filesArrayOfObjs.map((m_templateObj, m_fileIndex) =>
                  <ExcelReportTemplateItem key={m_templateObj.id} p_templateObj={m_templateObj} p_tabIndex={(m_fileIndex + 2)} />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}));

class ExcelReportTemplateItem extends Component { //props: p_templateObj, p_tabIndex
  render() {
    const templateObj = this.props.p_templateObj;
    const tabIndex = this.props.p_tabIndex;
    return(
      <div className="displayFlexRow tbPad hoverLighterBlueGradient" style={{height:"4.5em", borderBottom:"solid 1px #ccc"}}>
        <div className="flex00a displayFlexRowVc lrMedPad borderR1ddd">
          <CEGeneralReact.GenerateAndDownloadExcelReportButtonProcess
            p_excelReportTemplateID={templateObj.id}
            p_buttonText="Generate/Download Template"
            p_buttonTitle={"Generates an excel .xml file across all captures in the system using filters specified in the template file (" + templateObj.display_name + ").  The resulting file will be offered for download automatically."}
            p_tabIndex={tabIndex}
            p_canClickButtonTF={true}
          />
        </div>
        <div className="flex11a displayFlexRowVc lrMedPad borderR1ddd" style={{flexBasis:"100em"}}>
          <LibraryReact.MaxHeightWrap p_maxHeight="4em" p_fontClass="font13 fontBold fontBlueLighter">
            {templateObj.display_name}
          </LibraryReact.MaxHeightWrap>
        </div>
        <div className="flex11a displayFlexRowVc lrMedPad" style={{flexBasis:"100em"}}>
          <LibraryReact.MaxHeightWrap p_maxHeight="4em" p_fontClass="font11 fontItalic fontTextLighter">
            {templateObj.notes}
          </LibraryReact.MaxHeightWrap>
        </div>
      </div>
    );
  }
}







//=============================================================================================================================================================================
//=============================================================================================================================================================================
//Other functions
const DivexecSelectFilterDivisions = inject("DatabaseMobx", "UserMobx")(observer(
class DivexecSelectFilterDivisions extends Component {
  onchange_filter_divisions = (i_selectedDivisionIDsComma) => {
    this.props.UserMobx.a_update_user_field("divexec_filter_division_ids_comma", i_selectedDivisionIDsComma, "s");
  }

  render() {
    const c_combinedUserObj = this.props.UserMobx.c_combinedUserObj;

    const divexecFilterDivisionsIsFilledOutTF = JSFUNC.selectmulti_is_filled_out_tf(c_combinedUserObj.divexec_filter_division_ids_comma);

    return(
      <>
        <CEGeneralReact.CaptureExecFieldEditSaveCancel
          p_ceEditItemString="divexecHotBitsManualFilterDivisions"
          p_fieldDisplayName={this.props.DatabaseMobx.c_fieldMapOfDivisionOwners.get("display_name")}
          p_fieldTypeObj={this.props.DatabaseMobx.c_selectMultiDivisionsFieldTypeObj}
          p_valueRaw={c_combinedUserObj.divexec_filter_division_ids_comma}
          p_valueIsEditableTFU={true}
          p_containerClass="tbMicroPad"
          p_fieldClass="font11 fontBold fontItalic fontTextLighter"
          p_fieldWidth="10em"
          p_valueClass=""
          p_valueMaxHeight="4.4em"
          f_onSaveChanged={this.onchange_filter_divisions}
        />
        {(!divexecFilterDivisionsIsFilledOutTF) &&
          <CEGeneralReact.ErrorText p_text="No Divisions selected, select Division(s) here to view data" />
        }
      </>
    );
  }
}));